import React from "react";
import { EventFilters } from "./EventFilters";
import { IEventFilter } from "./EventFilter";
import { IPropertyFilter, PropertyFilters } from "./PropertyFilters";

interface IProps {
  eventFilters: IEventFilter[];
  eventInputId: string;
  propertyFilters: IPropertyFilter[];
  propertyInputId: string;
  shopClouds: string[];
}

export default ({
  eventFilters,
  eventInputId,
  propertyFilters,
  propertyInputId,
  shopClouds,
}: IProps) => {
  return (
    <>
      <h4>Event</h4>
      <EventFilters eventInputId={eventInputId} originalEventFilters={eventFilters} />

      <h4>Property</h4>
      <PropertyFilters
        originalPropertyFilters={propertyFilters}
        propertyInputId={propertyInputId}
        shopClouds={shopClouds}
      />
    </>
  );
};
