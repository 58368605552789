import React from "react";
import { Breakpoint } from "react-socks";
import gql from "graphql-tag";
import { Location, LocationContext } from "@reach/router";
import { BrandBlock } from "@organisms/brandBlockComponents/BrandBlock";
import {
  Box,
  Text,
  Image,
  H2,
  H3,
  Button,
  Row,
  Column,
  TouchableOpacity,
  FontAwesome,
} from "@atoms";
import { getPaginatedNodes } from "@utils";
import { RailsUrl } from "@services";
import { LinkType } from "@types";
import { LinkModalPostShopCollectionFragment } from "./__generated__/LinkModalPostShopCollectionFragment";
import { LinkModalPostShopLinkFragment } from "./__generated__/LinkModalPostShopLinkFragment";

// == Types ================================================================

interface IProps {
  link: LinkModalPostShopLinkFragment;
  collection: LinkModalPostShopCollectionFragment;
}

// == Constants ============================================================

const GIFTCARD_IMAGE = "https://flipgive.twic.pics/images/site/shop/brand-modal-giftcard-v2.png";
const EMPYR_IMAGE = "https://flipgive.twic.pics/images/site/shop/brand-modal-in-store.png";
const SHOP_IMAGE = "https://flipgive.twic.pics/images/site/shop/brand-modal-shop.png";

LinkModalPostShop.fragments = {
  collection: gql`
    fragment LinkModalPostShopCollectionFragment on Collection {
      id
      brands(first: 3) {
        edges {
          cursor
          node {
            id
            ...BrandBlockFragment
          }
        }
      }
    }
    ${BrandBlock.fragments.fields}
  `,
  link: gql`
    fragment LinkModalPostShopLinkFragment on Link {
      id
      type
      cashbackTiming
      merchant {
        id
        name
      }
    }
  `,
};
// == Component ============================================================

export default function LinkModalPostShop({ link, collection }: IProps) {
  const { merchant } = link;
  const brands = getPaginatedNodes(collection.brands.edges);

  const imageSrc = () => {
    switch (link.type) {
      case LinkType.GIFTCARDOFFER:
        return GIFTCARD_IMAGE;
      case LinkType.EMPYR:
        return EMPYR_IMAGE;
      default:
        return SHOP_IMAGE;
    }
  };

  return (
    <Location>
      {({ location, navigate }: LocationContext) => (
        <>
          <Box p={3}>
            <TouchableOpacity onTouch={() => navigate(location.pathname)}>
              <FontAwesome icon="times" />
            </TouchableOpacity>
          </Box>
          <Box pb={[3, 3, 4]} pt={0} px={[3, 3, 4]}>
            <Box mb={4} textAlign="center">
              <Image
                alt={merchant?.name || "merchant image"}
                display="inline"
                mb={3}
                src={imageSrc()}
                width="100px"
              />
              <H2 mb={3}>Thank you for shopping{merchant ? ` at ${merchant.name}` : ""}</H2>
              <Text as="p" mb={2}>
                If you made a purchase you&apos;ll receive cash back
                <strong>
                  {" "}
                  {link.type === LinkType.EMPYR ? "immediately" : link.cashbackTiming}.
                </strong>
                <br />
                You can track your earnings under your Shopping Trips page.
              </Text>
              <Button as="a" href={RailsUrl.myActivityUrl}>
                My Shopping Trips
              </Button>
            </Box>
            {!!brands?.length && (
              <Box>
                <H3 mb={1}>You may also like</H3>
                <Breakpoint xsmall>
                  <Row>
                    {brands.slice(0, 2).map((brand) => {
                      if (!brand) return null;
                      return (
                        <Column key={brand.id}>
                          <BrandBlock brand={brand} />
                        </Column>
                      );
                    })}
                  </Row>
                </Breakpoint>
                <Breakpoint small up>
                  <Row>
                    {brands.slice(0, 3).map((brand) => {
                      if (!brand) return null;
                      return (
                        <Column key={brand.id}>
                          <BrandBlock brand={brand} />
                        </Column>
                      );
                    })}
                  </Row>
                </Breakpoint>
              </Box>
            )}
          </Box>
        </>
      )}
    </Location>
  );
}

// == Styles ===============================================================
