import gql from "graphql-tag";
import addsDirectFundraiser from "./addsDirectFundraiser";
import { addsDirectFundraiserGroup as addsDirectFundraiserGroupTypes } from "./__generated__/addsDirectFundraiserGroup";

addsDirectFundraiserGroup.fragment = gql`
  fragment addsDirectFundraiserGroupFragment on Campaign {
    id
    name
    photoImage
    directAmountRaised {
      formatted
      dollarsAmount
    }
  }
`;

export default function addsDirectFundraiserGroup(campaign: addsDirectFundraiserGroupTypes) {
  const { name, photoImage, directAmountRaised } = campaign;
  const fundraiser = addsDirectFundraiser(campaign);

  return {
    id: name,
    name,
    avatarImage: photoImage,
    isGroup: true,
    isOwner: false,
    amountRaised: { ...directAmountRaised },
    isDirect: true,
    paginatedFundraisers: { edges: [{ node: fundraiser }] },
  };
}
