import gql from "graphql-tag";
import BrandFavoritesDialer from "@organisms/brandFavoritesDialerComponents/BrandFavoritesDialer/BrandFavoritesDialer";
// import { FavoritesModal } from "../components/common/organisms/favoritesModalComponents/FavoritesModal/FavoritesModal";
// import { FavoritesModal } from "@organisms/favoritesModalComponents/FavoritesModal/FavoritesModal";

// console.log("fav modal", FavoritesModal.fragments);
export const FAVORITE_BRANDS_QUERY = gql`
  query FavoriteBrands {
    Viewer {
      id
      favoriteBrandsCount
      favoriteBrands(first: 50) {
        id
        ...BrandFavoritesDialerFragment
        # ...FavoritesModalFragment
      }
    }
  }
  ${BrandFavoritesDialer.fragments.fields}
`;
