import React, { useState } from "react";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { useMutation } from "react-apollo";
import styled from "@emotion/styled";
import { useStoreAppValue } from "@hooks";
import { Button, Box, Text } from "@atoms";
import { RailsUrl, ErrorService, pluralize, analytics, spelling } from "@services";
import {
  OnboardingAddBrandsMutation,
  OnboardingAddBrandsMutationVariables,
} from "./__generated__/OnboardingAddBrandsMutation";

// == Types ================================================================

interface IProps {
  brandIds: string[];
  minBrands: number;
}

// == Constants ============================================================

const ADD_FAVORITE_BRANDS = gql`
  mutation OnboardingAddBrandsMutation($input: ViewerAddFavoriteBrandsInput!) {
    ViewerAddFavoriteBrands(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Viewer {
        id
        favoriteBrandsCount
        favoriteBrands(first: 20) {
          id
        }
      }
    }
  }
`;

// == Component ============================================================

export function OnboardingBrandsSubmit({ brandIds, minBrands }: IProps) {
  const { isOwner, campaignId, currency } = useStoreAppValue();
  const [showError, setShowError] = useState(false);

  const [addFavoriteBrands, { loading }] = useMutation<
    OnboardingAddBrandsMutation,
    OnboardingAddBrandsMutationVariables
  >(ADD_FAVORITE_BRANDS, {
    variables: { input: { brandIds } },
    onError: (error) => {
      setShowError(true);
      ErrorService.error(error);
    },
    onCompleted: ({ ViewerAddFavoriteBrands }) => {
      if (ViewerAddFavoriteBrands.__typename === "MutationError") {
        setShowError(true);
        return;
      }

      analytics.track.onboardingFinishFavorites({ campaignId }, isOwner);
      if (isOwner) {
        navigate(RailsUrl.onboardingGoalUrl);
      } else if (campaignId) {
        // NOTE: to be revised where this goes. should be double checked if you see me here
        window.location.href = RailsUrl.setupTeamUrl(campaignId);
      } else {
        window.location.href = RailsUrl.activatePersonalAccountUrl;
      }
    },
  });

  const numBrands = brandIds.length;
  const enabled = numBrands >= minBrands;
  const isButtonDisabled = !enabled || loading;
  return (
    <>
      <EContainer>
        <EGradient />
        <EButton
          borderRadius={0}
          disabled={isButtonDisabled}
          py={[2, 3]}
          variant={isButtonDisabled ? "disabled" : "primary"}
          width={1}
          onClick={() => {
            setShowError(false);
            addFavoriteBrands();
          }}
        >
          {loading
            ? "Submitting..."
            : enabled
            ? "Continue"
            : `Add ${minBrands - numBrands} ${spelling("favorites", {
                country: `${currency === "USD" ? "USA" : "Canada"}`,
                capitalize: true,
                singular: true,
              })} ${pluralize("Brand", minBrands - numBrands, {
                showCount: false,
              })} To Continue`}
        </EButton>
        {showError && (
          <Text color="danger" display="block" py={2} textAlign="center">
            There was an issue saving your brands
          </Text>
        )}
      </EContainer>
    </>
  );
}

// == Styles ===============================================================

const EContainer = styled(Box)`
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
`;

const EButton = styled(Button)`
  &:disabled {
    opacity: 1;
  }
`;

const EGradient = styled(Box)`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.4) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 40px;
`;
