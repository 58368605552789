import React, { Fragment } from "react";
import { shape, string, bool } from "prop-types";
import SwitchTeamListItem from "./SwitchTeamListItem";
import SwitchTeamPersonalItem from "./SwitchTeamPersonalItem";
import SwitchTeamHelperButton from "./SwitchTeamHelperButton";
import SwitchTeamReason from "./SwitchTeamReason";

const SwitchTeamHeader = ({
  campaign,
  activeCampaign,
  firstName,
  lastName,
  personalAccount,
  activeStatus,
  photoImage,
  campaignMembersUrl,
  editCampaignUrl,
  userFundsUrl,
  rootUrl,
  showCheckmark,
  title,
}) => (
  <div className="switch-team__header-container">
    <SwitchTeamReason personalAccount={personalAccount} />
    {["TEAM", "PERSONAL"].includes(activeStatus) && (
      <>
        {campaign ? (
          <SwitchTeamListItem
            activeCampaign={activeCampaign}
            campaign={campaign}
            showCheckmark={showCheckmark}
            switchTeamUrl={rootUrl}
            title={title}
          />
        ) : (
          <SwitchTeamPersonalItem
            hideHeader
            activeCampaign={activeCampaign}
            firstName={firstName}
            lastName={lastName}
            personalAccount={personalAccount}
            personalUserUrl={rootUrl}
            photoImage={photoImage}
            showCheckmark={showCheckmark}
            title={title}
          />
        )}
      </>
    )}
  </div>
);

export default SwitchTeamHeader;

SwitchTeamHeader.propTypes = {
  campaign: shape({}),
  firstName: string.isRequired,
  lastName: string.isRequired,
  personalAccount: shape({}).isRequired,
  activeStatus: string.isRequired,
  activeCampaign: shape({}),
  photoImage: string,
  campaignMembersUrl: string.isRequired,
  editCampaignUrl: string.isRequired,
  userFundsUrl: string.isRequired,
  rootUrl: string.isRequired,
  title: string.isRequired,
  showCheckmark: bool.isRequired,
};

SwitchTeamHeader.defaultProps = {
  activeCampaign: null,
  photoImage: null,
  campaign: null,
};
