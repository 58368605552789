import React, { Dispatch, SetStateAction } from "react";
import capitalize from "lodash/capitalize";
import { Flex, Text, TouchableOpacity } from "@atoms";
import { IntentCategoryEnum } from "@types";
import { theme } from "@styles";

// == Types ================================================================

interface IProps {
  activeFundraiserTab: IntentCategoryEnum;
  setFundraiserTab: Dispatch<SetStateAction<IntentCategoryEnum>>;
}

// == Constants ============================================================

// == Component ============================================================

export default function FundsPageFundraiserTabs({ activeFundraiserTab, setFundraiserTab }: IProps) {
  const {
    colors: { darkerPrimary },
  } = theme;

  const { ALL, SHOPPING, DONATION, BONUS, TEAM, TRANSFER } = IntentCategoryEnum;

  const renderTab = (categoryEnum: IntentCategoryEnum) => (
    <TouchableOpacity
      borderBottom={activeFundraiserTab === categoryEnum ? "fundsTabActive" : "fundsTabInActive"}
      containerProps={{ style: { width: "100%" } }}
      py={3}
      textAlign="center"
      width={1}
      onTouch={() => setFundraiserTab(categoryEnum)}
    >
      <Text color={activeFundraiserTab === categoryEnum ? darkerPrimary : ""}>
        {capitalize(categoryEnum)}
      </Text>
    </TouchableOpacity>
  );

  return (
    <Flex width="100%">
      {renderTab(ALL)}
      {renderTab(SHOPPING)}
      {renderTab(DONATION)}
      {renderTab(BONUS)}
      {renderTab(TEAM)}
      {renderTab(TRANSFER)}
    </Flex>
  );
}

// == Styles ===============================================================
