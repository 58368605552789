import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import { useLoadingDelay, useStoreAppValue } from "@hooks";
import { NearbySearchQuery } from "../pages/nearbyComponents/NearbySearchResult/__generated__/NearbySearchQuery";
import { useLocationValue } from "@hooks/context/LocationProvider/LocationProvider";
import { getDefaultLat, getDefaultLong } from "../utils";

// == Types ================================================================

// == Constants ============================================================

const COLLECTION_NEARBY_BRANDS_QUERY = gql`
  query CollectionNearbyBrandsQueryLocationSearch(
    $coordinates: CoordinatesInput
    $currency: Currency
  ) {
    LocationSearch(coordinates: $coordinates, first: 15, distance: 50, currency: $currency) {
      edges {
        cursor
        node {
          id
          sponsored
          brand {
            id
            name
            website
            logoImage
            logoBackgroundColor
            invertedLogoImage
            maxGiveLink {
              id
              promoLabel
              giveSentence
              promoSecondaryLabel
              positiveGiveAmount
              baselineGiveAmount
            }
          }
        }
      }
    }
  }
`;

function formatLocationSearchData(locationSearchData: NearbySearchQuery) {
  const addedIds: string[] = [];

  const locations = locationSearchData?.LocationSearch?.edges ?? [];

  const { sponsored, normal } = locations.reduce(
    (acc, { node: place }) => {
      if (acc.sponsored.length >= 2 || !place.sponsored) {
        return {
          ...acc,
          normal: [...acc.normal, place],
        };
      }
      if (acc.sponsored.length >= 1 && acc.sponsored[0].brand.id === place.brand.id) {
        return {
          ...acc,
          normal: [...acc.normal, place],
        };
      }
      return {
        ...acc,
        sponsored: [...acc.sponsored, place],
      };
    },
    { sponsored: [], normal: [] }
  );

  const formattedBrands = [...sponsored, ...normal]
    ?.map((location) => ({
      ...location,
      node: { ...location, ...location?.brand, locationId: location?.id },
    }))
    .filter((location) => {
      const locationBrandId = location?.brand?.id;
      if (locationBrandId && !addedIds.includes(locationBrandId)) {
        addedIds.push(locationBrandId);
        return true;
      }
      return false;
    });

  return formattedBrands;
}

// == Component ============================================================

export function useNearbyBrands() {
  const { forceSetGeo, isAllowed, requestGeo, location, teamLocation } = useLocationValue();
  const { currency } = useStoreAppValue();
  const loadingDelayReady = useLoadingDelay();

  const { data, loading, error } = useQuery(COLLECTION_NEARBY_BRANDS_QUERY, {
    variables: {
      coordinates: {
        latitude: location?.latitude ? +location.latitude : getDefaultLat(currency),
        longitude: location?.longitude ? +location.longitude : getDefaultLong(currency),
        source: "CURRENT",
      },
      currency,
    },
    onCompleted: (data) => {
      if (data?.LocationSearch?.edges?.length === 0) {
        forceSetGeo(teamLocation);
      }
    },
  });

  const nearbyBrands = data ? formatLocationSearchData(data) : [];

  return {
    brands: nearbyBrands,
    isLoading: (isEmpty(data) && loading) || !loadingDelayReady,
    onShareGeo: requestGeo,
    isAllowed,
    error,
  };
}

// == Styles ===============================================================
