import React from "react";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// == Component ============================================================

const BeneficiaryTitle = () => {
  return (
    <div className="centered mb-3">
      <h2 className="mb-2">Beneficiary Information</h2>
      <p>
        The check beneficiary name will vary based on the type of team selected. If you wish to make
        payments out to an individual, please select "Family/Individual".
      </p>
    </div>
  );
};

export default BeneficiaryTitle;

// == Styles ===============================================================
