import React, { useMemo } from "react";
import gql from "graphql-tag";
import { Box } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { MemberList } from "../MemberList";
import { GroupSubHeader } from "../GroupSubHeader";
import { MemberListGroupedFragment } from "./__generated__/MemberListGroupedFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  campaign: MemberListGroupedFragment;
  activeReassignmentMemberId: string | null;
  refetch: () => void;
  setActiveReassignmentMemberId: (value: string | null) => void;
  setInviteModalData: () => void;
}

// == Constants ============================================================

MemberListGrouped.fragment = gql`
  fragment MemberListGroupedFragment on Campaign {
    id
    isViewerOwner
    fundsDistributionMethod
    goal {
      formatted
      dollarsAmount
    }
    noGroupFundraisers {
      id
      ...MemberListFragmentNoGroup
      ...GroupSubHeaderNoGroupFragment
    }
    groups {
      id
      ...MemberListFragment
      ...GroupSubHeaderGroupFragment
    }
    ...GroupSubHeaderCampaignFragment
  }
  ${MemberList.fragments}
  ${GroupSubHeader.fragments}
`;

MemberListGrouped.defaultProps = {};

// == Functions ============================================================

const isNoGroupHasUser = (group) => {
  // the length check is greater than 1 because there is a default
  // team fundraiser called 'Others'

  // deactivatedAt  === false filters out removed members
  // joinedAt       === true filters out pending members
  const {
    paginatedFundraisers: { edges },
  } = group;
  return (
    edges.filter((fundraiser) => {
      return !fundraiser.node.deactivatedAt && !!fundraiser.node.joinedAt;
    }).length > 1
  );
};

export function groupedFundraisersMaker(sortedGroups = [], noGroupFundraisers = []) {
  return noGroupFundraisers.paginatedFundraisers.edges.length > 0
    ? [noGroupFundraisers, ...sortedGroups]
    : sortedGroups;
}

// not being used anymore, but just in case?
// this usually change whenever people feel like to
// const groupedFundraisersSorter = (groupedFundraisers) => {
//   const { currentUser } = useStoreAppValue();
//   const [currentUserGroup] = groupedFundraisers?.filter((group) =>
//     group.paginatedFundraisers.edges
//       .map((edge) => parseInt(edge.node.userId, 10))
//       .includes(currentUser.id)
//   );
//   const otherGroups = groupedFundraisers
//     ?.filter((group) => group.id !== currentUserGroup?.id)
//     .sort((a, b) => b.amountRaised.centsAmount - a.amountRaised.centsAmount);
//   return [currentUserGroup, ...otherGroups].filter(Boolean);
// };

// == Component ============================================================

export function MemberListGrouped({
  activeReassignmentMemberId,
  campaign,
  refetch,
  setActiveReassignmentMemberId,
  setInviteModalData,
}: IProps) {
  const groups = campaign?.groups;
  const noGroupFundraisers = campaign?.noGroupFundraisers;
  const sortedGroups = useMemo(
    () => groups?.sort((a, b) => b.amountRaised.dollarsAmount - a.amountRaised.dollarsAmount),
    [groups]
  );

  const groupedFundraisers = groupedFundraisersMaker(sortedGroups, noGroupFundraisers);
  return groupedFundraisers.map((group) => {
    return (
      <Box key={group.id}>
        <GroupSubHeader
          campaign={campaign}
          group={group}
          isNoGroupEmpty={!isNoGroupHasUser(noGroupFundraisers)}
        />
        <MemberList
          activeReassignmentMemberId={activeReassignmentMemberId}
          campaign={campaign}
          group={group}
          refetch={refetch}
          setActiveReassignmentMemberId={setActiveReassignmentMemberId}
          setInviteModalData={setInviteModalData}
        />
      </Box>
    );
  });
}

// == Styles ===============================================================
