import React from "react";
import gql from "graphql-tag";
// import UserTransferList from "bundles/UserFundsApp/components/UserTransferList";
import { getPaginatedNodes } from "@components/common/utils";
// import { useLoadingDelay } from "@hooks";
import { Flex, Text, TouchableOpacity, Box } from "@atoms";
import { IntentCategoryEnum } from "@types";
import { FundsPageFundraiserListItem } from "../FundsPageFundraiserListItem";
import { FundsPageFundraiserListReferralItem } from "../FundsPageFundraiserListReferralItem";
import { FundsPageFundraiserListFragment } from "./__generated__/FundsPageFundraiserListFragment";

// == Types ================================================================

interface IProps {
  isDirectFundraiser: boolean;
  campaign: FundsPageFundraiserListFragment;
  activeFundraiserTab: IntentCategoryEnum;
  setOpen: (value: boolean) => void;
  setTransferOpen: (value: boolean) => void;
  setSelectedListItem: (value: $FixMe) => void;
  queryVariables: $FixMe;
  onFetchMore: () => void;
  hasNextPage: boolean;
}

// == Constants ============================================================

FundsPageFundraiserList.fragments = gql`
  fragment FundsPageFundraiserListFragment on Campaign {
    id
    isViewerOwner @include(if: $hasCurrentUser)
    directAmountRaised {
      dollarsAmount
      formatted
    }
    viewerFundraiser {
      id
      ...FundsPageFundraiserListItemViewerFundraiserFragment
    }
    amountRaisedBreakdown(fundraiserId: $fundraiserId, onlyTeamSupports: $onlyTeamSupports) {
      bonuses {
        centsAmount
        dollarsAmount
        formatted
      }
      donations {
        centsAmount
        dollarsAmount
        formatted
      }
      shopping {
        centsAmount
        dollarsAmount
        formatted
      }
      team {
        centsAmount
        dollarsAmount
        formatted
      }
      transfers {
        centsAmount
        dollarsAmount
        formatted
      }
    }
    supportedIntentsPaginated(
      first: 25
      after: $after
      fundraiserUserId: $fundraiserUserId
      onlyTeamSupports: $onlyTeamSupports
      filterByIntentCategory: $filterByIntentCategory
    ) {
      edges {
        cursor
        node {
          id
          ...FundsPageFundraiserListItemIntentFragment
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
  ${FundsPageFundraiserListItem.fragments.viewerFundraiser}
  ${FundsPageFundraiserListItem.fragments.intent}
`;

// == Functions ============================================================

const categoryAmountRaisedTotalMaker = (
  amountRaisedBreakdown: FundsPageFundraiserListFragment["amountRaisedBreakdown"],
  activeTab: IntentCategoryEnum = IntentCategoryEnum.ALL
) => {
  const { bonuses, shopping, donations, team, transfers } = amountRaisedBreakdown;

  // next time revisiting, i don't think the || 0 is necessary
  const amountRaisedAll = `$${(
    (bonuses.dollarsAmount || 0) +
    (shopping.dollarsAmount || 0) +
    (donations.dollarsAmount || 0) +
    (team.dollarsAmount || 0) +
    (transfers.dollarsAmount || 0)
  ).toFixed(2)}`;

  switch (activeTab) {
    case IntentCategoryEnum.ALL:
      return amountRaisedAll;
    case IntentCategoryEnum.SHOPPING:
      return shopping.formatted;
    case IntentCategoryEnum.BONUS:
      return bonuses.formatted;
    case IntentCategoryEnum.DONATION:
      return donations.formatted;
    case IntentCategoryEnum.TEAM:
      return team.formatted;
    case IntentCategoryEnum.TRANSFER:
      return transfers.formatted;
    default:
      return "";
  }
};

const directFundraiserTotalMaker = ({ directAmountRaised }) => {
  return directAmountRaised.formatted;
};

// == Component ============================================================

export default function FundsPageFundraiserList({
  isDirectFundraiser,
  campaign,
  activeFundraiserTab,
  setOpen,
  setTransferOpen,
  setSelectedListItem,
  onFetchMore,
  hasNextPage,
}: IProps) {
  const {
    id,
    isViewerOwner,
    viewerFundraiser,
    supportedIntentsPaginated: { edges },
  } = campaign;

  const onClickLoadMore = () => {
    // FETCH MORE HERE, check params
    onFetchMore();
  };
  return (
    <Flex flexDirection="column" justifyContent="flex-start">
      <Flex
        bg="lightBg"
        borderBottom="lightSilver"
        flexDirection="row"
        justifyContent="space-between"
        padding={[2, 2, 3]}
        width={1}
      >
        <Text fontSize={[1, 2, 3]}>Total</Text>
        <Text fontSize={[1, 2, 3]}>
          {isDirectFundraiser && activeFundraiserTab === IntentCategoryEnum.ALL
            ? directFundraiserTotalMaker(campaign)
            : categoryAmountRaisedTotalMaker(campaign.amountRaisedBreakdown, activeFundraiserTab)}
        </Text>
      </Flex>
      {getPaginatedNodes(edges).map((listItem) => {
        if (!listItem) return null;

        return (
          <FundsPageFundraiserListItem
            isViewerOwner={isViewerOwner}
            key={listItem.id}
            listItem={listItem}
            setOpen={setOpen}
            setSelectedListItem={setSelectedListItem}
            setTransferOpen={setTransferOpen}
            viewerFundraiser={viewerFundraiser}
          />
        );
      })}
      {hasNextPage && (
        <Box color="primary" padding={[1, 2, 3]} textAlign="center">
          <TouchableOpacity onTouch={onClickLoadMore}>Load More</TouchableOpacity>
        </Box>
      )}
      {activeFundraiserTab === IntentCategoryEnum.BONUS && (
        <FundsPageFundraiserListReferralItem id={id} />
      )}
    </Flex>
  );
}

// == Styles ===============================================================
