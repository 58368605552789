import React, { useCallback, useEffect, useMemo, useState } from "react";
import currency from "currency.js";
import Switch from "react-switch";
import styled from "@emotion/styled";
import queryString from "query-string";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import Color from "color";
import { useImmerReducer } from "use-immer";
import { Box, Text, H2, H3, CircleLoader, Fade, Flex } from "@atoms";
import { ToggleButton, Spinner } from "@molecules";
import {
  ErrorService,
  constants,
  formatMoney,
  getThresholdDenominations,
  RailsUrl,
} from "@services";
import { ErrorLoading } from "@organisms";
import { useRecaptcha, useStoreAppValue } from "@hooks";
import {
  GIFTCARD_RELOADABLE_SAVE_BUTTON_MUTATION,
  GiftcardReloadSaveButton,
} from "./GiftcardReloadSaveButton";
import { GiftcardReloadPaymentFields } from "./GiftcardReloadPaymentFields";
import { ViewerCampaignDropdown } from "./ViewerCampaignDropdown";
import { GiftcardReloadRefill } from "./GiftcardReloadRefill";
import { IGiftcardReloadState, TGiftcardReloadActions } from "./types";
import {
  GiftcardReloadSettingsQuery,
  GiftcardReloadSettingsQueryVariables,
  GiftcardReloadSettingsQuery_Viewer_giftcardsPaginated_edges_node,
} from "./__generated__/GiftcardReloadSettingsQuery";
import { useFeatureFlag } from "@hooks/useFeatureFlag";
import { AddNewCardButton, UpdateCardButton } from "./UpdateCardButton";
import { getPaginatedNodes } from "@components/common/utils";
import { GiftcardReloadInputType, ReloadIntentCreateInput } from "@types";
import produce from "immer";
import {
  ReloadIntentCreateMutation,
  ReloadIntentCreateMutationVariables,
} from "./__generated__/ReloadIntentCreateMutation";
import { RELOAD_INTENT_CREATE_MUTATION } from "./GiftcardRefillButton";
import { theme } from "@styles";
// import { theme } from "@styles";

// == Types ================================================================

interface IProps {
  primaryColor: string;
  giftcardId: number;
  isPartnerSite: boolean;
}

type TAlertType = "success" | "error";

// == Constants ============================================================

const GIFTCARD_RELOAD_SETTINGS_QUERY = gql`
  query GiftcardReloadSettingsQuery($ids: [ID!]) {
    Viewer {
      id
      giftcardsPaginated(first: 1, ids: $ids) {
        edges {
          cursor
          node {
            id
            isReloadable
            currency
            value {
              formatted
              dollarsAmount
            }
            balance {
              formatted
              dollarsAmount
            }
            reloadInfo {
              isReloadingEnabled
              reloadPercentage
              isAutoReloadable
              isManualReloadable
              manualReloadDenominations
              isNotificationAllowed
              reloadThreshold {
                centsAmount
                dollarsAmount
              }
              stripeCardId
            }
            campaign {
              id
              name
              ...ViewerCampaignDropdownCampaignFragment
            }
            link {
              id
              name
              givePercentage
              reloadableGiftcardInfo {
                reloadThresholdDenominations {
                  dollarsAmount
                }
              }
            }
            linkedCard {
              brand
              expiryMonth
              expiryYear
              last4
            }
          }
        }
      }
      ...ViewerCampaignDropdownFragment
    }
  }
  ${ViewerCampaignDropdown.fragment}
`;

const STATE_REDUCER = (draft: IGiftcardReloadState, action: TGiftcardReloadActions) => {
  switch (action.type) {
    case "INITIAL_LOAD":
      draft.loaded = true;
      draft.giftcard = action.giftcard;
      draft.balanceFormatted = action.giftcard?.balance.formatted || null;
      draft.reloadView = action.giftcard?.reloadInfo?.isAutoReloadable ? "SETTINGS" : "RELOAD";
      draft.value = action.giftcard.value.dollarsAmount;
      draft.isEnabled = action.giftcard.reloadInfo?.isReloadingEnabled || false;
      draft.stripeCardId = action.giftcard.reloadInfo?.stripeCardId || "";
      draft.reloadThresholdCents = action.giftcard.reloadInfo?.reloadThreshold?.centsAmount || 0;
      draft.selectedCampaignId = action.campaignId;
      draft.isNotificationAllowed = action.giftcard.reloadInfo?.isNotificationAllowed || false;
      return;
    case "SET_ACTIVE_CARD":
      draft.stripeCardId = action.cardId;
      draft.stripeTokenId = null;
      draft.disabled = false;
      return;
    case "SET_NEW_CARD":
      draft.stripeTokenId = action.newCardId;
      draft.stripeCardId = null;
      draft.disabled = false;
      return;
    case "CLICK_ADD_NEW_CARD":
      draft.disabled = true;
      return;
    case "SET_CAMPAIGN_ID":
      draft.selectedCampaignId = action.campaignId;
      return;
    case "TOGGLE_RELOAD":
      draft.isEnabled = action.isEnabled;
      return;
    case "SUCCESSFUL_SAVE":
      draft.submitted = true;
      draft.error = null;
      return;
    case "FAILED_SAVE":
      draft.submitted = false;
      draft.error = "There was an issue saving";
      return;
    case "RESET_FORM":
      draft.submitted = false;
      draft.reloadView = "SETTINGS";
      return;
    case "UPDATE_PERCENT":
      draft.reloadThresholdCents = action.reloadThresholdCents;
      break;
    case "DISABLE_FORM":
      draft.disabled = true;
      break;
    case "ENABLE_FORM":
      draft.disabled = false;
      break;
    case "TOGGLE_NOTIFICATION":
      draft.isNotificationAllowed = !draft.isNotificationAllowed;
      break;
    case "SET_MANUAL_RELOAD_AMOUNT":
      draft.manualReloadDollarsAmount = action.dollarsAmount;
      break;
    case "UPDATE_BALANCE":
      draft.balanceFormatted = action.balanceFormatted;
      break;
    case "SET_ACTIVE_TAB":
      draft.reloadView = action.tab;
      break;
    default:
      break;
  }
};

const INITIAL_STATE: IGiftcardReloadState = {
  selectedCampaignId: "",
  loaded: false,
  giftcard: {} as GiftcardReloadSettingsQuery_Viewer_giftcardsPaginated_edges_node,
  submitted: false,
  isEnabled: true,
  stripeCardId: null,
  stripeTokenId: null,
  value: 0,
  reloadThresholdCents: 0,
  disabled: true,
  error: null,
  reloadView: "SETTINGS",
  isNotificationAllowed: false,
  manualReloadDollarsAmount: 0,
  balanceFormatted: null,
};

const GIFTCARD_RELOAD_DENOMINATION = 25;

const MESSAGE_DURATION = 4000;

// == Component ============================================================

export function GiftcardReloadSettings({ primaryColor, giftcardId }: IProps) {
  const [state, dispatch] = useImmerReducer(STATE_REDUCER, INITIAL_STATE);
  const [doUpdate, setDoUpdate] = useState(false);
  const { featureFlags, currentUser } = useStoreAppValue();

  // useEffect that listens for when the modal is closed to reset some state
  useEffect(() => {
    const clickHandler = () => {
      dispatch({ type: "RESET_FORM" });
    };

    const delayedClick = () => {
      setTimeout(() => {
        clickHandler();
      }, 500);
    };
    const link = document.getElementById("closeReloadLink");
    const openModal = document.getElementsByClassName("order-summary__giftcard-balance")[0];
    if (link) {
      link.addEventListener("click", delayedClick);
    }
    if (openModal) {
      openModal.addEventListener("click", clickHandler);
    }

    return () => {
      link?.removeEventListener("click", delayedClick);
      openModal?.removeEventListener("click", clickHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    // open modal when update balance in url
    if (parsed.update_balance) document.getElementById("update-balance-button--js").click();
  }, []);

  const reloadThresholds = useMemo(() => {
    const denoms = state.giftcard?.link?.reloadableGiftcardInfo.reloadThresholdDenominations.map(
      (denom) => denom.dollarsAmount
    );
    return getThresholdDenominations(state.value, denoms);
  }, [state.giftcard, state.value]);

  useEffect(() => {
    if (state.balanceFormatted) {
      const balanceSelector = document.getElementsByClassName("gc-wallet-card__balance--js");
      if (balanceSelector[0]) balanceSelector[0].innerHTML = state.balanceFormatted;
    }
  }, [state.balanceFormatted]);

  const { data, error } = useQuery<
    GiftcardReloadSettingsQuery,
    GiftcardReloadSettingsQueryVariables
  >(GIFTCARD_RELOAD_SETTINGS_QUERY, {
    variables: {
      ids: [String(giftcardId)],
    },
    onCompleted: (data) => {
      if (data.Viewer?.giftcardsPaginated?.edges?.length > 0) {
        const edge = data.Viewer.giftcardsPaginated.edges[0];
        const campaignId = data.Viewer.giftcardsPaginated?.edges[0].node?.campaign?.id || "";
        dispatch({ type: "INITIAL_LOAD", giftcard: edge?.node, campaignId });
      }
    },
  });

  const linkedCard = useMemo(() => {
    const cards = getPaginatedNodes(data?.Viewer.giftcardsPaginated.edges);
    return cards?.[0]?.linkedCard ?? null;
  }, [data?.Viewer.giftcardsPaginated]);

  const { isEnabled: isAutoReloadEnabled } = useFeatureFlag("autoreload");

  const onEnableChange = () => {
    dispatch({ type: "TOGGLE_RELOAD", isEnabled: !state.isEnabled });
    // if auto load for card was disabled the reload percent will be 0
    // set a default
    if (!state.isEnabled && state.giftcard.reloadInfo?.reloadPercentage === 0) {
      onUpdatePercent(GIFTCARD_RELOAD_DENOMINATION);
    }
    isAutoReloadEnabled && setDoUpdate(true);
  };

  const onCampaignSelect = (id: string) => {
    dispatch({ type: "SET_CAMPAIGN_ID", campaignId: id });
    setDoUpdate(true);
  };

  const onUpdatePercent = (thresholdDollars: number) => {
    dispatch({
      type: "UPDATE_PERCENT",
      reloadThresholdCents: thresholdDollars * 100,
    });
    isAutoReloadEnabled && setDoUpdate(true);
  };

  const recaptchaAction = currentUser
    ? "CREATE_GIFTCARD_INTENT"
    : "CREATE_ANONYMOUS_GIFTCARD_INTENT";

  const getRecaptchaToken = useRecaptcha({ action: recaptchaAction });

  const recaptchaToken = useMemo(() => {
    let recaptchaToken = "";
    getRecaptchaToken()
      .then((token) => {
        recaptchaToken = token as string;
      })
      .catch((error) => ErrorService.warn(error));

    return recaptchaToken;
  }, [getRecaptchaToken]);

  const [reloadIntentCreate, { loading: isReloading }] = useMutation<
    ReloadIntentCreateMutation,
    ReloadIntentCreateMutationVariables
  >(RELOAD_INTENT_CREATE_MUTATION);

  const reloadWithFeatureFlag = async () => {
    try {
      const input = produce<{}, ReloadIntentCreateInput, void>({}, (draft) => {
        draft.giftcardId = state.giftcard.id;
        draft.devicePlatform = constants.PLATFORM;
        draft.campaignId = state.selectedCampaignId;
        if (recaptchaToken) draft.recaptchaToken = recaptchaToken as string;
        draft.reloadAmountCents = 100;
      });

      const res = await reloadIntentCreate({
        variables: {
          input: input as ReloadIntentCreateInput,
        },
      });
      if (res.data?.ReloadIntentCreate.__typename === "MutationError") return;
      const token = res.data?.ReloadIntentCreate.token;
      if (token) window.location.href = RailsUrl.newCheckoutUrl(token);
    } catch (e) {
      ErrorService.error(e);
    }
  };

  const onLinkCard = () => {
    reloadWithFeatureFlag();
  };

  // from button start

  const [updateGiftcardMutation, { loading: savingSettings }] = useMutation(
    GIFTCARD_RELOADABLE_SAVE_BUTTON_MUTATION,
    {
      onCompleted(resp) {
        if (resp?.ViewerReloadableGiftcardUpdate.__typename !== "MutationError") {
          dispatch({ type: "SUCCESSFUL_SAVE" });
          showDisappearingSuccessMessage("Settings Saved!", "success");
        } else {
          dispatch({ type: "FAILED_SAVE" });
          showDisappearingSuccessMessage("Error, try again later", "error");
        }
      },
    }
  );

  const onSubmit = useCallback(async () => {
    try {
      const input = produce({}, (draft) => {
        draft.giftcardId = state.giftcard.id;
        draft.isReloadingEnabled = state.isEnabled;
        draft.reloadThresholdCents = state.reloadThresholdCents;
        draft.campaignId = state.selectedCampaignId;
        if (state.stripeCardId) draft.stripeCardId = state.stripeCardId;
        if (state.stripeTokenId) draft.stripeTokenId = state.stripeTokenId;
      });

      await updateGiftcardMutation({
        variables: {
          input,
        },
      });
    } catch (e) {
      ErrorService.error(e);
    }
  }, [produce, state, updateGiftcardMutation, ErrorService]);

  useEffect(() => {
    if (doUpdate) {
      onSubmit();
      setDoUpdate(false);
    }
  }, [doUpdate]);

  //  from button end

  const [updateMessage, setUpdateMessage] = useState<{ message: string; type: TAlertType | null }>({
    message: "",
    type: null,
  });

  // doing this because Im having issues installing react-toastify and other npm packages
  const showDisappearingSuccessMessage = useCallback((message: string, type: TAlertType) => {
    setUpdateMessage({ message, type });
    setTimeout(() => {
      setUpdateMessage({ message: "", type: null });
    }, MESSAGE_DURATION);
  }, []);

  if (!isAutoReloadEnabled && state.submitted) {
    return (
      <Fade show>
        <Box p={5} textAlign="center">
          <CircleLoader delay={50} />
          <H2 mb={2} textAlign="center">
            Success!
          </H2>
          {state.reloadView === "SETTINGS" && (
            <Text>Your card reload settings have been saved.</Text>
          )}
          {state.reloadView === "RELOAD" && <Text>Your card has been reloaded.</Text>}
        </Box>
      </Fade>
    );
  }

  if (error) {
    return <ErrorLoading />;
  }

  if (!state.loaded) {
    return (
      <div className="centered py-5">
        <Spinner />
      </div>
    );
  }

  // This view is for when you are on auto reload, and click to refill an auto reloaded card
  // or when the card is not auto reloadable
  if (
    !state.giftcard.reloadInfo?.isAutoReloadable &&
    state.giftcard.reloadInfo?.isManualReloadable
  ) {
    return (
      <Fade show>
        <Box p={[3, 3, 5]}>
          <H2 mb={2} textAlign="center">
            {state.giftcard.link.name}
          </H2>
          <Box mb={3} textAlign="center">
            <H3>Current Balance: {state.giftcard.balance?.formatted}</H3>
          </Box>
          <GiftcardReloadRefill dispatch={dispatch} state={state} viewer={data?.Viewer} />
        </Box>
      </Fade>
    );
  }

  // default view, settings for auto reload
  return (
    <Box p={[3, 3, 5]}>
      <H2 mb={2} textAlign="center">
        {state.giftcard.link.name}
      </H2>
      <Box mb={3} textAlign="center">
        <H3>Current Balance: {state.giftcard.balance.formatted}</H3>
      </Box>
      {state.giftcard.reloadInfo.isAutoReloadable &&
        state.giftcard.reloadInfo.isManualReloadable &&
        !!featureFlags.manualReload && (
          <Box mb={4} textAlign="center">
            <ToggleButton
              borderRadius="4px 0 0 4px"
              isActive={state.reloadView !== "RELOAD"}
              text="Auto Reload"
              width={[1 / 2, "200px"]}
              onClick={() => dispatch({ type: "SET_ACTIVE_TAB", tab: "SETTINGS" })}
            />
            <ToggleButton
              borderRadius="0 4px 4px 0"
              isActive={state.reloadView === "RELOAD"}
              text="Manual Reload"
              width={[1 / 2, "200px"]}
              onClick={() => dispatch({ type: "SET_ACTIVE_TAB", tab: "RELOAD" })}
            />
          </Box>
        )}
      {state.reloadView === "RELOAD" ? (
        <GiftcardReloadRefill dispatch={dispatch} state={state} viewer={data?.Viewer} />
      ) : (
        <>
          <Box mb={3}>
            <Flex mb={3} width="100%">
              <Box>
                <Text display="block">Automatically reload your card</Text>
              </Box>
              <Box flex="1" textAlign="right">
                <Switch
                  aria-label="Enabled or Disabled"
                  checked={state.isEnabled}
                  checkedIcon={false}
                  handleDiameter={24}
                  height={20}
                  id="enabled"
                  offColor={Color("#999")
                    .lighten(0.5)
                    .hex()}
                  offHandleColor="#999"
                  uncheckedIcon={false}
                  width={50}
                  onChange={onEnableChange}
                  onColor={Color(primaryColor)
                    .lighten(0.3)
                    .hex()}
                  onHandleColor={primaryColor}
                />
              </Box>
            </Flex>
          </Box>
          <EWhiteOverlay isEnabled={state.isEnabled}>
            {state.giftcard && (
              <Box mb={3}>
                <Text display="block" mb={1}>
                  Reload When Balance Drops Below
                </Text>
                {reloadThresholds.map((thresholdDollars) => {
                  return (
                    <ToggleButton
                      isActive={thresholdDollars * 100 === state.reloadThresholdCents}
                      key={String(thresholdDollars)}
                      mb={2}
                      mr={2}
                      text={currency(thresholdDollars).format()}
                      onClick={() => state.isEnabled && onUpdatePercent(thresholdDollars)}
                    />
                  );
                })}
                <Text display="block" fontSize={0}>
                  Reload to {state.giftcard.value.formatted} when balance drops below{" "}
                  {formatMoney(state.reloadThresholdCents / 100)}. You will earn cashback for each
                  reload based on the most up-to-date cashback rate. Card balance are checked for
                  reload on a nightly basis.
                </Text>
              </Box>
            )}

            <Box mb={3}>
              <Text display="block" mb={1} mt={4}>
                Cashback Will Be Sent To
              </Text>
              {data?.Viewer && (
                <ViewerCampaignDropdown
                  currency={state.giftcard?.currency}
                  selectedCampaignId={state.selectedCampaignId}
                  viewer={data.Viewer}
                  onCampaignSelect={onCampaignSelect}
                  extraCampaign={state.giftcard?.campaign}
                />
              )}
            </Box>

            {isAutoReloadEnabled ? (
              <>
                {linkedCard && (
                  <Text display="block" mb={1}>
                    Reloads will be charged to this payment card
                  </Text>
                )}
                {linkedCard ? (
                  <>
                    <UpdateCardButton
                      brand={linkedCard?.brand}
                      last4={linkedCard?.last4}
                      expiryMonth={linkedCard?.expiryMonth}
                      expiryYear={linkedCard?.expiryYear}
                      onClick={onLinkCard}
                      isLoading={isReloading}
                    />
                  </>
                ) : (
                  <AddNewCardButton onClick={onLinkCard} isLoading={isReloading} />
                )}
              </>
            ) : (
              <Box mb={3}>
                <Text display="block" mb={1} mt={4}>
                  Payment Method
                </Text>
                <GiftcardReloadPaymentFields
                  cardId={state.stripeCardId || state.stripeTokenId}
                  dispatch={dispatch}
                />
              </Box>
            )}
          </EWhiteOverlay>

          {/*Only show when AutoReload is not enabled */}
          {!isAutoReloadEnabled && <GiftcardReloadSaveButton dispatch={dispatch} state={state} />}
        </>
      )}

      {updateMessage.message && (
        <AlertWrapper>
          <Text
            display="block"
            color={updateMessage.type === "success" ? theme.colors.primary : theme.colors.danger}
          >
            {updateMessage.message}
          </Text>
        </AlertWrapper>
      )}
    </Box>
  );
}

// == Styles ===============================================================
const EWhiteOverlay = styled(Box)`
  position: relative;
  opacity: ${({ isEnabled }) => (isEnabled ? "1" : ".3")};
  transition: opacity 0.3s linear;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    display: ${({ isEnabled }) => (isEnabled ? "none" : "block")};
  }
`;

const AlertWrapper = styled(Box)`
  display: block;
  text-align: center;
  font-weight: bold;
`;
