import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import { useStoreAppValue, useLoadingDelay } from "@hooks";
import { ErrorLoading } from "@organisms";
import { Loading } from "@components";
import { OrganizePlayersView } from "./OrganizePlayersView";
import {
  OrganizePlayerViewQuery,
  OrganizePlayerViewQueryVariables,
} from "./__generated__/OrganizePlayerViewQuery";

// == Constants ============================================================

const ORGANIZE_PLAYER_VIEW_QUERY = gql`
  query OrganizePlayerViewQuery($id: ID) {
    Viewer {
      id
      ...OrganizePlayersViewViewerFragment
    }

    Campaign(id: $id) {
      id
      ...OrganizePlayersViewCampaignFragment
    }
  }
  ${OrganizePlayersView.fragments}
`;

// == Component ============================================================

export function OrganizePlayersViewQuery() {
  const { campaignId } = useStoreAppValue();
  const loadingDelayReady = useLoadingDelay();

  const { data, error, loading, refetch } = useQuery<
    OrganizePlayerViewQuery,
    OrganizePlayerViewQueryVariables
  >(ORGANIZE_PLAYER_VIEW_QUERY, {
    variables: { id: campaignId },
  });

  const campaign = data?.Campaign;
  const viewer = data?.Viewer;

  if (loading) return <Loading />;
  if (!loadingDelayReady) return null;
  if (error || isEmpty(data) || !campaign || !viewer) return <ErrorLoading />;

  return <OrganizePlayersView campaign={campaign} viewer={viewer} refetch={refetch} />;
}
