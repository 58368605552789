import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import Comment from "./Comment";

@inject("TimelineStore")
@observer
export default class ActivityComments extends React.Component {
  static propTypes = {
    TimelineStore: PropTypes.shape({
      singleActivity: PropTypes.bool.isRequired,
    }).isRequired,
  };

  state = {
    isExpanded: false,
    maxComments: 3,
  };

  viewMoreClick = (e) => {
    e.preventDefault();
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  renderViewMoreLink = () => {
    const { comments } = this.props;
    if (comments.length <= this.state.maxComments || this.state.isExpanded) {
      return false;
    }

    const commentCount = comments.length - this.state.maxComments;
    const commentsPluralized = commentCount === 1 ? "comment" : "comments";
    return (
      <a
        className="timeline-comments__more"
        href="#"
        key="view-more"
        onClick={(e) => this.viewMoreClick(e)}
      >
        {this.state.isExpanded
          ? "Hide Comments"
          : `View ${commentCount} more ${commentsPluralized}`}
      </a>
    );
  };

  renderComments() {
    const { comments } = this.props;
    const { isExpanded, maxComments } = this.state;
    let displayComments;
    if (isExpanded) {
      displayComments = comments;
    } else {
      displayComments = comments.slice(-maxComments);
    }

    return displayComments
      .reverse()
      .map((comment) => (
        <Comment {...comment} key={`${comment.id}-${comment.userId}-${comment.createdAt}`} />
      ));
  }

  render() {
    const { TimelineStore, comments } = this.props;

    return (
      <div className="timeline-comments__list">
        {TimelineStore.singleActivity
          ? comments.map((comment, i) => (
              <Comment {...comment} key={`${comment.userId}-${comment.createdAt}`} />
            ))
          : [this.renderViewMoreLink(), this.renderComments()]}
      </div>
    );
  }
}
