import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import gql from "graphql-tag";
import { Flex, Text, Image, Circle } from "@atoms";
import { UserListItem, ImageListItem } from "@molecules";
import { useStoreAppValue } from "@hooks";
import { LinkModalFundraiserListFragment } from "./__generated__/LinkModalFundraiserListFragment";

// == Types ================================================================

interface IProps {
  campaign: LinkModalFundraiserListFragment;
  onFundraiserSelect: (fundraiserId: string) => void;
}

// == Constants ============================================================

LinkModalFundraiserList.fragment = gql`
  fragment LinkModalFundraiserListFragment on Campaign {
    id
    name
    photoImage
    joinedFundraisers {
      id
      avatarImage
      name
    }
  }
`;

const CampaignCircleImage = (alt: string, src: string | null) => {
  if (!src) return null;
  return (
    <Flex alignItems="center">
      <Circle>
        <Image alt={alt} src={`${src}&ar=1&fit=crop`} />
      </Circle>
    </Flex>
  );
};

// == Component ============================================================

function LinkModalFundraiserList({ campaign, onFundraiserSelect }: IProps) {
  const [selectedFundraiserName, setSelectedFundraiserName] = useState(null);
  const { fundraiserId } = useStoreAppValue();

  const { joinedFundraisers, name, photoImage } = campaign;
  useEffect(() => {
    if (!fundraiserId) return;

    const selectedFundraiser = joinedFundraisers.find(
      (fundraiser) => fundraiser && fundraiser.id === fundraiserId.toString()
    );
    if (!selectedFundraiser) return;

    onFundraiserSelect(selectedFundraiser.id);
    setSelectedFundraiserName(selectedFundraiser.name);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Menu>
      <Flex justifyContent="center" pb={1}>
        <EMenuButton>
          <EInput disabled value={selectedFundraiserName ?? `${name} (Team)`} />
          <ESpan aria-hidden>▾</ESpan>
          <EText color="placeholderColor" fontSize={0} pt={1}>
            Give cash back to
          </EText>
        </EMenuButton>
      </Flex>
      <EMenuList className="slide-down">
        <MenuItem
          onSelect={() => {
            setSelectedFundraiserName(`${name} (Team)`);
            onFundraiserSelect("");
          }}
        >
          <ImageListItem
            leftComponent={CampaignCircleImage(name, photoImage)}
            text={`${name} (Team)`}
          />
        </MenuItem>
        {joinedFundraisers.map((fundraiser) => {
          if (!fundraiser) return null;
          return (
            <MenuItem
              key={`${fundraiser.id}-item`}
              onSelect={() => {
                setSelectedFundraiserName(fundraiser.name);
                onFundraiserSelect(fundraiser.id);
              }}
            >
              <UserListItem imageSrc={fundraiser.avatarImage} name={fundraiser.name} />
            </MenuItem>
          );
        })}
      </EMenuList>
    </Menu>
  );
}

export default LinkModalFundraiserList;

// == Styles ===============================================================

const EMenuList = styled(MenuList)`
  z-index: 1000;
  position: relative;
  overflow-y: auto;
  max-height: 200px;
  > [data-reach-menu-item]:hover {
    background: ${({ theme }) => theme.colors.primary};
  }
  > [data-reach-menu-item][data-selected] {
    background: ${({ theme }) => theme.colors.primary};
  }
  > [data-reach-menu-item][data-selected] span {
    color: white !important;
  }
  > [data-reach-menu-item]:hover span {
    color: white !important;
  }
`;

const EMenuButton = styled(MenuButton)`
  padding: 0;
  width: 300px;
  display: block;
  background: none;
  border: none;
  position: relative;
`;

const EInput = styled.input`
  margin-bottom: 0 !important;
  padding-top: 10px !important;
  border-color: ${({ theme }) => theme.colors.gray}!important;
  color: ${({ theme }) => theme.colors.default}!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 24px;
`;

const ESpan = styled.span`
  position: absolute;
  right: 0;
  top: 33%;
  margin-right: 10px;
`;

const EText = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 10px;
`;
