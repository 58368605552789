import React from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { BrandBlock, LinkBlockGives, BrandCircle } from "@organisms";
import { Flex, Text, CheckIcon, Box } from "@atoms";
import { OnboardingBrandItemFragment } from "./__generated__/OnboardingBrandItemFragment";

// == Types ================================================================

interface IProps {
  brand: OnboardingBrandItemFragment;
  onBrandClick: (id: string, brand: $FixMe) => void;
  isSelected: boolean;
}

// == Constants ============================================================

OnboardingBrandItem.fragments = gql`
  fragment OnboardingBrandItemFragment on Brand {
    id
    name
    ...BrandCircleFragment
    ...BrandBlockFragment
  }
  ${BrandCircle.fragments.fields}
  ${BrandBlock.fragments.fields}
`;

// == Component ============================================================

export function OnboardingBrandItem({ brand, onBrandClick, isSelected }: IProps) {
  const onClick = () => {
    onBrandClick(brand.id, brand);
  };
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      minWidth="0"
      position="relative"
    >
      {isSelected && (
        <ECheckContainer bg="white" border="primaryCircle" borderRadius="circle" p={1}>
          <Text color="primary">
            <CheckIcon />
          </Text>
        </ECheckContainer>
      )}

      <EBrandCircle brand={brand} mb={2} onClick={onClick} />
      <Text ellipsis fontSize={0} textAlign="center" width="100%">
        {brand.name}
      </Text>
      {brand?.maxGiveLink?.giveSentence && (
        <LinkBlockGives giveSentence={brand.maxGiveLink.giveSentence} />
      )}
    </Flex>
  );
}

// == Styles ===============================================================

const ECheckContainer = styled(Box)`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
`;

const EBrandCircle = styled(BrandCircle)`
  transition: transform 200ms ease-in-out;
  &:hover {
    transform: scale(1.05);
    border: ${({ theme }) => theme.borders.primaryCircle};
    cursor: pointer;
  }
`;
