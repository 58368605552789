import React, { useState, useRef } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import { ApolloError } from "apollo-client";
import { useToggle } from "@hooks";
import styled from "@emotion/styled";
import { theme } from "@styles";
import { RouteComponentProps } from "@reach/router";
import { OnboardingContainer } from "../components";
import { Box, Text, Flex, Button, FontAwesome, TouchableOpacity } from "@atoms";
import { ImageCircle, NoAvatar } from "@molecules";
import { ErrorService, RailsUrl } from "@services";

// == Constants ============================================================

const CREATE_NEW_PLAYER_MUTATION = gql`
  mutation CreateNewPlayerMutation($name: String!, $image: Upload, $isPlayerContact: Boolean) {
    CampaignGroupCreate(
      name: $name
      image: $image
      isPlayerContact: $isPlayerContact
      setFundraiserGroup: true
    ) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        groups {
          id
          name
          imageUrl
        }
      }
    }
  }
`;

const SET_GROUP_MUTATION = gql`
  mutation FundraiserSetGroup($id: ID!, $groupId: ID, $isPlayerContact: Boolean) {
    FundraiserSetGroup(id: $id, groupId: $groupId, isPlayerContact: $isPlayerContact) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Fundraiser {
        id
      }
    }
  }
`;

const CAMPAIGN_PLAYERS_QUERY = gql`
  query CampaignPlayersQuery($id: ID) {
    Campaign(id: $id) {
      id
      name
      photoImage
      groups {
        id
        name
        imageUrl
        paginatedFundraisers {
          edges {
            node {
              id
              name
              isOwner
            }
          }
        }
      }
      viewerFundraiser {
        id
      }
    }
  }
`;

// == Functions ============================================================

const AltTextField = ({ type, label, name, disabled = false, ...rest }) => {
  return (
    <Box display={type === "hidden" ? "none" : "block"}>
      {label && (
        <ELabel className="active" htmlFor={name}>
          {label}
        </ELabel>
      )}
      <Box className={`input-field ${disabled ? "disabled" : ""}`}>
        <EInput disabled={disabled} name={name} {...rest} />
      </Box>
    </Box>
  );
};

const renderCheckbox = (isSelected: boolean) => {
  const {
    colors: { primary },
  } = theme;

  return (
    <>
      <Box paddingRight={2}>
        {isSelected ? (
          <Text color={primary} fontSize={[2, 3]}>
            <FontAwesome icon="check-square" />
          </Text>
        ) : (
          <Text color={primary} fontSize={[2, 3]}>
            <FontAwesome icon={["far", "square"]} />
          </Text>
        )}
      </Box>
      <Box pl={2}>
        <Text fontSize={[0, 1]}>
          I am the key contact (i.e. parent or guardian) for this player.
        </Text>
      </Box>
    </>
  );
};

const renderPlayerImage = (image = "", name = "") => {
  const nameArr = name.split(" ");
  if (!image) return <NoAvatar circleDiameter={60} firstName={nameArr[0]} lastName={nameArr[1]} />;
  return <ImageCircle src={image} alt={name || "player"} height={60} width={60} />;
};

// == Component ============================================================

export function OnboardingPlayer(_props: RouteComponentProps) {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [showAddPlayer, toggleShowAddPlayer] = useToggle(false);
  const { data: campaignData, loading } = useQuery<$FixMe, $FixMe>(CAMPAIGN_PLAYERS_QUERY);

  const isCaptainInSelectedPlayer = selectedPlayer?.paginatedFundraisers?.edges
    ?.map((fundraiser) => fundraiser.node.isOwner)
    .includes(true);

  const showKeyContactCheckbox = !isCaptainInSelectedPlayer;

  return (
    <OnboardingContainer hasError={false} loading={loading}>
      {campaignData ? (
        showAddPlayer ? (
          <AddPlayer
            campaignData={campaignData.Campaign}
            toggleShowAddPlayer={toggleShowAddPlayer}
          />
        ) : (
          <ChoosePlayer
            showKeyContactCheckbox={showKeyContactCheckbox}
            campaignData={campaignData.Campaign}
            toggleShowAddPlayer={toggleShowAddPlayer}
            selectedPlayer={selectedPlayer}
            setSelectedPlayer={setSelectedPlayer}
          />
        )
      ) : null}
    </OnboardingContainer>
  );
}

function ChoosePlayer({
  campaignData,
  toggleShowAddPlayer,
  selectedPlayer,
  setSelectedPlayer,
  showKeyContactCheckbox,
}) {
  const [isPlayerContact, setIsPlayerContact] = useToggle(false);
  const [setGroupMutation, { loading }] = useMutation<$FixMe, $FixMe>(SET_GROUP_MUTATION, {
    onCompleted: () => {
      window.location.href = RailsUrl.onboardingIntroUrl;
    },
  });

  return (
    <Flex padding={5} flexDirection="column" justifyContent="center" alignItems="center">
      <Box>
        <Text fontSize={[2, 3]} fontWeight="bold">
          Select Player
        </Text>
      </Box>

      <Box padding={2} textAlign="center" width={0.8}>
        <Text fontSize={[0, 1]}>Select the player you'll be earning money for.</Text>
      </Box>

      <Box py={4}>
        {!!campaignData && (
          <>
            <select
              style={{ display: "block" }}
              placeholder="Select Player"
              value={selectedPlayer ? selectedPlayer.id : ""}
              onChange={(event) => {
                const value = event.target.value;
                if (value === "SKIP") {
                  window.location.href = RailsUrl.onboardingIntroUrl;
                } else if (value === "ADD") {
                  setSelectedPlayer(null);
                  setIsPlayerContact(false);
                  toggleShowAddPlayer();
                } else {
                  setSelectedPlayer(campaignData.groups.find((group) => group.id === value));
                }
              }}
            >
              <option value="">Select Player</option>
              {[
                ...campaignData.groups.map((group) => ({ label: group.name, value: group.id })),
                { label: "Not Listed - Add New Player", value: "ADD" },
                { label: "No Player - Support Team", value: "SKIP" },
              ].map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </>
        )}
      </Box>

      {!!selectedPlayer && showKeyContactCheckbox ? (
        <TouchableOpacity onTouch={() => setIsPlayerContact(!isPlayerContact)}>
          <Flex
            pb={3}
            px={5}
            height="60px"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width={1}
          >
            {renderCheckbox(isPlayerContact)}
          </Flex>
        </TouchableOpacity>
      ) : (
        <Box height="60px" />
      )}
      <Flex width={0.8} pt={2} justifyContent="center" alignItems="center">
        <Button
          variant="clear"
          disabled={!selectedPlayer || loading}
          onClick={() => {
            setGroupMutation({
              variables: {
                id: campaignData.viewerFundraiser.id,
                groupId: selectedPlayer.id,
                isPlayerContact,
              },
            });
          }}
        >
          Select Player
        </Button>
      </Flex>
    </Flex>
  );
}

function AddPlayer({ campaignData, toggleShowAddPlayer }) {
  const [pendingPlayerName, setPendingPlayerName] = useState<String>("");
  const [pendingPlayerImage, setPendingPlayerImage] = useState<File | null>(null);
  const [previewPlayerImage, setPreviewPlayerImage] = useState<string | null>(null);
  const [isPlayerContact, toggleIsPlayerContact] = useToggle(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onErrorMutation = (errors?: ApolloError) => {
    if (errors) ErrorService.error(errors);
    Materialize?.toast?.(
      `There was an ERROR creating player. Please try again.`,
      4000,
      "rounded toast-danger"
    );
    setPendingPlayerName("");
    setPendingPlayerImage(null);
    setPreviewPlayerImage(null);
  };

  const [createCreateNewPlayerMutation, { loading }] = useMutation<$FixMe, $FixMe>(
    CREATE_NEW_PLAYER_MUTATION,
    {
      onErrorMutation,
      onCompleted: async ({ CampaignGroupCreate }) => {
        if (CampaignGroupCreate.__typename === "MutationError") {
          onErrorMutation(CampaignGroupCreate?.errors);
          return;
        }

        Materialize?.toast?.(
          `Player ${pendingPlayerName} has been created`,
          4000,
          "rounded toast-success"
        );

        window.location.href = RailsUrl.onboardingIntroUrl;
      },
      context: {
        hasUploads: true,
      },
    }
  );

  const onChangeImageUpload = (event) => {
    const file = event.target.files[0];

    if (file.type.substr(0, 5) === "image") {
      setPendingPlayerImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewPlayerImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCancelAddPlayer = () => {
    setPendingPlayerName("");
    setPendingPlayerImage(null);
    setPreviewPlayerImage(null);
    toggleShowAddPlayer(false);
  };

  const onClickSavePlayer = () => {
    createCreateNewPlayerMutation({
      variables: {
        name: pendingPlayerName,
        image: pendingPlayerImage,
        isPlayerContact,
      },
    });
  };

  const onTouchClearPendingPlayerImage = () => {
    setPendingPlayerImage(null);
    setPreviewPlayerImage(null);
  };

  return (
    <Flex padding={5} flexDirection="column" justifyContent="center" alignItems="center">
      <Box>
        <Text fontSize={[2, 3]} fontWeight="bold">
          Add Player
        </Text>
      </Box>

      <Box padding={2} textAlign="center" width={0.8}>
        <Text fontSize={[0, 1]}>Add the Player who will benefit from the funds you earn.</Text>
      </Box>

      {!!previewPlayerImage && !!pendingPlayerImage ? (
        <TouchableOpacity onTouch={() => onTouchClearPendingPlayerImage()}>
          <Box textAlign="center" py={2}>
            <ImageCircle
              src={previewPlayerImage}
              alt="player"
              height={100}
              width={100}
              borderRadius={50}
            />
          </Box>
        </TouchableOpacity>
      ) : (
        <>
          <TouchableOpacity onTouch={() => fileInputRef.current?.click()}>
            <Flex
              justifyContent="center"
              alignItems="center"
              height="150px"
              width="150px"
              border="2px dashed #AAA"
              borderRadius="75px"
              bg="#EFEFEF"
              p={4}
              my={3}
            >
              <Text textAlign="center">Upload Image</Text>
            </Flex>
          </TouchableOpacity>
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={(event) => onChangeImageUpload(event)}
            ref={fileInputRef}
          />
        </>
      )}

      <Box>
        <AltTextField
          type="text"
          name="name"
          label="Player Name"
          placeholder="Enter player name"
          value={pendingPlayerName}
          onChange={(e) => setPendingPlayerName(e.target.value)}
        />
      </Box>

      <TouchableOpacity onTouch={() => toggleIsPlayerContact()}>
        <Flex
          pb={3}
          px={5}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          width={1}
        >
          {renderCheckbox(isPlayerContact)}
        </Flex>
      </TouchableOpacity>

      <Flex pb={3} width={0.4} justifyContent="center">
        <Button disabled={loading} variant="clear" onClick={onClickSavePlayer}>
          Save
        </Button>
      </Flex>

      <Flex justifyContent="center">
        <Text fontSize={[1]}>
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              onCancelAddPlayer();
            }}
          >
            Never mind, I'll select an existing player
          </a>
        </Text>
      </Flex>
    </Flex>
  );
}

// == Styles ===============================================================

const EInput = styled.input`
  height: 40px !important;
  padding-top: 0 !important;
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;
