import gql from "graphql-tag";
// import { BrandModalFavoriteButton } from "@components/common/organisms/brandModalComponents/BrandModalFavoriteButton";

export const TOGGLE_FAVORITE_BRAND_MUTATION = gql`
  mutation ToggleFavoriteBrandMutation($brandId: ID!) {
    ViewerToggleFavoriteBrand(brandId: $brandId) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Brand {
        id
        viewerHasFavorited
        name
        logoImage
        # ...BrandModalFavoriteButtonFragment
        # ...FavoriteModaListRemoveButtonFragment
      }
    }
  }
`;
