import * as Sentry from "@sentry/browser";

// == Types ================================================================

// == Constants ============================================================

// == Functions ==============================================================

const capture = (error: any, level: string, context: string = "", details: any = null) => {
  if (level) {
    Sentry.setTag("level", level);
  }
  if (context) {
    Sentry.setTag("context", context);
  }
  if (details) {
    Sentry.setContext("details", details);
  }
  if (level == "error" && error.message) {
    Sentry.captureException(error);
  } else if (level == "error") {
    Sentry.captureException(new Error(error));
  } else if (error.message) {
    Sentry.captureMessage(error.message, level);
  } else {
    Sentry.captureMessage(JSON.stringify(error), level);
  }
};

const info = (error: any, context: string = "", details: any = null) => {
  capture(error, "info", context, details);
};

const warn = (error: any, context: string = "", details: any = null) => {
  capture(error, "warning", context, details);
};

const error = (error: any, context: string = "", details: any = null) => {
  capture(error, "error", context, details);
};

// == Exports ==============================================================

export const ErrorService = {
  info,
  warn,
  error,
};
