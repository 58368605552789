import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const ActivityHeading = ({ time, actor: { name, avatarImage }, visibility }) => (
  <div className="timeline-heading">
    <div className="media">
      <img alt="User avatar" className="timeline-heading__image" src={avatarImage} />
      <div className="media-body align-self-center">
        <span className="timeline-heading__name">{name}</span>
        <span className="timeline-heading__time block-text">
          {moment.utc(time).fromNow()} &bull;{" "}
          {visibility === "TEAM" ? (
            <span className="timeline-heading__visibility">
              <i className="material-icons">group</i> Team
            </span>
          ) : (
            <span className="timeline-heading__visibility">
              <i className="material-icons">public</i> Public
            </span>
          )}
        </span>
      </div>
    </div>
  </div>
);

ActivityHeading.propTypes = {
  time: PropTypes.string.isRequired,
  actor: PropTypes.shape({ name: PropTypes.string, avatarImage: PropTypes.string }).isRequired,
  visibility: PropTypes.oneOf(["PUBLIC", "TEAM"]).isRequired,
};

export default ActivityHeading;
