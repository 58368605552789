/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { inject, observer } from "mobx-react";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import JoinTeam from "./JoinTeam";
import ActivityComments from "./ActivityComments";
import NewComment from "./NewComment";
import { ActivityFragment } from "@queries";

const LIKE_ACTIVITY_MUTATION = gql`
  mutation LikeActivityMutation($activityId: ID!, $publicView: Boolean = false) {
    ActivityReactionCreate(activityId: $activityId, reactionType: LIKE) {
      ... on Activity {
        ...ActivityFragment
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
  ${ActivityFragment}
`;

const UNLIKE_ACTIVITY_MUTATION = gql`
  mutation UnLikeActivityMutation($activityId: ID!, $publicView: Boolean = false) {
    ActivityReactionDelete(activityId: $activityId) {
      ... on Activity {
        ...ActivityFragment
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
  ${ActivityFragment}
`;

const COMMENT_MUTATION = gql`
  mutation ActivityCommentCreate(
    $activityId: ID!
    $comment: String!
    $publicView: Boolean = false
  ) {
    ActivityCommentCreate(activityId: $activityId, comment: $comment) {
      ... on Activity {
        ...ActivityFragment
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
  ${ActivityFragment}
`;

@inject("TimelineStore")
@observer
@graphql(LIKE_ACTIVITY_MUTATION, { name: "likeActivityMutation" })
@graphql(UNLIKE_ACTIVITY_MUTATION, { name: "unlikeActivityMutation" })
@graphql(COMMENT_MUTATION, { name: "commentMutation" })
export default class ActivityReactions extends React.Component {
  static propTypes = {
    comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    likesCount: PropTypes.number.isRequired,
    commentsCount: PropTypes.number.isRequired,
    viewerHasLiked: PropTypes.bool.isRequired,
    activityId: PropTypes.string.isRequired,
    visibility: PropTypes.oneOf(["PUBLIC", "TEAM"]).isRequired,
    TimelineStore: PropTypes.shape({}).isRequired,
    likeActivityMutation: PropTypes.func.isRequired,
    unlikeActivityMutation: PropTypes.func.isRequired,
    commentMutation: PropTypes.func.isRequired,
  };

  state = { commentValue: "" };

  componentDidMount() {
    Materialize.updateTextFields();
  }

  handleReactionClick = (e, reaction_type, action) => {
    e.preventDefault();
    const { activityId, likeActivityMutation, unlikeActivityMutation } = this.props;

    if (action === "add") {
      likeActivityMutation({ variables: { activityId, publicView: false } });
    } else {
      unlikeActivityMutation({ variables: { activityId, publicView: false } });
    }
  };

  handleCommentCountClick = (e) => {
    e.preventDefault();
    this.comment.focus();
  };

  handleCommentSubmit = (e) => {
    e.preventDefault();
    const { activityId, commentMutation } = this.props;

    commentMutation({
      variables: { activityId, comment: this.state.commentValue, publicView: false },
    });

    this.setState({ commentValue: "" });
  };

  commentPlaceholder() {
    const { visibility } = this.props;
    const messageTo = visibility === "PUBLIC" ? "everyone" : "your team";
    return `Message ${messageTo}. Use @ to notify your teammates.`;
  }

  renderReaction = () => {
    const { TimelineStore, likesCount, viewerHasLiked } = this.props;

    if (!TimelineStore.teamView) {
      return (
        <div className="timeline-reactions__counts">
          <span className="timeline-reactions__like is-public">
            <i className="material-icons">&#xE8DC;</i>
            {likesCount}
          </span>
        </div>
      );
    }
    if (viewerHasLiked) {
      return (
        <div className="timeline-reactions__counts">
          <a
            className="timeline-reactions__like color-link"
            href="#"
            onClick={(e) => this.handleReactionClick(e, "like", "remove")}
          >
            <i className="material-icons">&#xE8DC;</i>
            {likesCount}
          </a>
        </div>
      );
    }

    return (
      <div className="timeline-reactions__counts">
        <a
          className="timeline-reactions__like default-color-link--only-hover"
          href="#"
          onClick={(e) => this.handleReactionClick(e, "like", "add")}
        >
          <i className="material-icons">&#xE8DC;</i>
          {likesCount}
        </a>
      </div>
    );
  };

  render() {
    const { TimelineStore, comments, commentsCount } = this.props;

    return (
      <div>
        <div className="timeline-reactions">
          {this.renderReaction()}
          {TimelineStore.teamView ? (
            <div className="timeline-reactions__comment-counts">
              <a
                className="timeline-reactions__comment-counts-link default-color-link"
                href="#"
                onClick={(e) => this.handleCommentCountClick(e)}
              >
                <i className="fa fa-comment" />
                {commentsCount}
              </a>
            </div>
          ) : (
            <div className="timeline-reactions__comment-counts">
              <span className="timeline-reactions__comment-counts-link is-public">
                <i className="fa fa-comment" />
                {commentsCount}
              </span>
            </div>
          )}
        </div>

        {TimelineStore.teamView ? (
          <div className="timeline-comments">
            <ActivityComments comments={comments} />

            <form
              className="timeline-comments__comment-form"
              onSubmit={(e) => this.handleCommentSubmit(e)}
            >
              <div className="new-comment-container">
                <NewComment
                  handleChange={(value) => {
                    this.setState({ commentValue: value });
                  }}
                  placeholder={this.commentPlaceholder()}
                  value={this.state.commentValue}
                />
                <div className="input-with-button__btn">
                  <button
                    className="btn-flat new-comment__submit default-color-link--only-hover"
                    type="submit"
                  >
                    <i className="material-icons">&#xE163;</i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="timeline-comments">
            <JoinTeam />
          </div>
        )}
      </div>
    );
  }
}
