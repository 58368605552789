import React, { useState } from "react";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ApolloError } from "apollo-client";
import { ErrorService } from "@services";
import { Flex, Text, Box, Button } from "@atoms";
import { PlayersListItemGroupsFragment } from "../PlayersListItem/__generated__/PlayersListItemGroupsFragment";
import { PlayersListItemNoGroupFragment } from "../PlayersListItem/__generated__/PlayersListItemNoGroupFragment";
import { AddPlayerInputFormViewerFragment } from "./__generated__/AddPlayerInputFormViewerFragment";
import {
  CreatePlayerMutation as CreatePlayerMutationGeneric,
  CreatePlayerMutationVariables,
} from "./__generated__/CreatePlayerMutation";
import {
  UpdatePlayerMutation as UpdatePlayerMutationGeneric,
  UpdatePlayerMutationVariables,
} from "./__generated__/UpdatePlayerMutation";

interface IProps {
  viewer: AddPlayerInputFormViewerFragment;
  player?: PlayersListItemGroupsFragment | PlayersListItemNoGroupFragment;
  refetch: () => void;
  setSelectedPlayerId: (val: string) => void;
}

AddPlayerInputForm.fragments = gql`
  fragment AddPlayerInputFormViewerFragment on Viewer {
    id
    name
    onboarding {
      earningsEstimate {
        dollarsAmount
      }
    }
  }
`;

const CREATE_PLAYER_MUTATION = gql`
  mutation CreatePlayerMutation($name: String!, $goal: Int) {
    CampaignGroupCreate(name: $name, goal: $goal) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        groups {
          id
          name
          imageUrl
          fundraisers {
            id
            name
            amountRaised {
              dollarsAmount
              formatted
            }
          }
        }
        fundraisers {
          id
          name
          group {
            id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_PLAYER_MUTATION = gql`
  mutation UpdatePlayerMutation($id: ID!, $name: String!, $goal: Int) {
    CampaignGroupUpdate(id: $id, name: $name, goal: $goal) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        groups {
          id
          name
          imageUrl
          fundraisers {
            id
            name
            amountRaised {
              dollarsAmount
              formatted
            }
          }
        }
        fundraisers {
          id
          name
          group {
            id
            name
          }
        }
      }
    }
  }
`;

// == Functions ============================================================

const AltTextField = ({ type, label, name, disabled = false, ...rest }) => {
  return (
    <Box display={type === "hidden" ? "none" : "block"}>
      {label && (
        <ELabel className="active" htmlFor={name}>
          {label}
        </ELabel>
      )}
      <Box className={`input-field ${disabled ? "disabled" : ""}`}>
        <EInput disabled={disabled} name={name} {...rest} />
      </Box>
    </Box>
  );
};

// == Component ============================================================

export function AddPlayerInputForm({ viewer, refetch, player, setSelectedPlayerId }: IProps) {
  const viewerGoal = viewer?.onboarding?.earningsEstimate?.dollarsAmount || 250;
  const [pendingPlayerName, setPendingPlayerName] = useState<string>(player?.name || "");
  const [pendingPlayerGoal, setPendingPlayerGoal] = useState<number>(
    player?.goal?.dollarsAmount || viewerGoal
  );
  const isAddButtonReady = !!pendingPlayerName && !!pendingPlayerGoal;

  // === Mutation Handlers ===
  const onError = (errors: ApolloError) => {
    if (errors) ErrorService.error(errors);
    Materialize?.toast?.(
      `There was an ERROR adding ${pendingPlayerName} to this Campaign. Please try again.`
    );
    setPendingPlayerName("");
  };

  const [createPlayerMutation] = useMutation<
    CreatePlayerMutationGeneric,
    CreatePlayerMutationVariables
  >(CREATE_PLAYER_MUTATION, {
    onError,
    onCompleted: ({ CampaignGroupCreate }) => {
      if (CampaignGroupCreate.__typename === "MutationError") {
        onError(CampaignGroupCreate?.errors);
        return;
      }
      Materialize?.toast(
        `Player ${pendingPlayerName} has been added.`,
        4000,
        "rounded toast-success"
      );
      setSelectedPlayerId("");
      setPendingPlayerName("");
      setPendingPlayerGoal(viewerGoal);
      refetch();
    },
    context: {
      hasUploads: true,
    },
  });

  const [updatePlayerMutation] = useMutation<
    UpdatePlayerMutationGeneric,
    UpdatePlayerMutationVariables
  >(UPDATE_PLAYER_MUTATION, {
    onError,
    onCompleted: ({ CampaignGroupUpdate }) => {
      if (CampaignGroupUpdate.__typename === "MutationError") {
        onError(CampaignGroupUpdate?.errors);
        return;
      }
      Materialize?.toast(
        `Player ${pendingPlayerName} has been updated.`,
        4000,
        "rounded toast-success"
      );
      setSelectedPlayerId("");
      refetch();
    },
    context: {
      hasUploads: true,
    },
  });

  const onChangeName = (e) => {
    e.preventDefault();
    setPendingPlayerName(e.target.value);
  };

  const onChangeGoal = (e) => {
    e.preventDefault();
    setPendingPlayerGoal(e.target.value);
  };

  const onClear = () => {
    setSelectedPlayerId("");
    if (!player) {
      setPendingPlayerName("");
      setPendingPlayerGoal(viewerGoal);
    }
  };

  const onClickCreatePlayer = () => {
    if (player) {
      updatePlayerMutation({
        variables: {
          id: player.id,
          name: pendingPlayerName,
          goal: parseInt(pendingPlayerGoal, 10),
        },
      });
    } else {
      createPlayerMutation({
        variables: {
          name: pendingPlayerName,
          goal: parseInt(pendingPlayerGoal, 10),
        },
      });
    }
  };

  return (
    <Flex
      alignItems="center"
      border="1px dashed #DDD"
      borderRadius="5px"
      flexDirection="column"
      justifyContent="flex-start"
      mt={2}
      py={4}
      width={1}
    >
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <Box>
          <AltTextField
            label="Player Name"
            name="name"
            placeholder="Enter player name"
            type="text"
            value={pendingPlayerName}
            onChange={(e) => onChangeName(e)}
          />
        </Box>
        <Box>
          <AltTextField
            label="Funding Goal"
            name="goal"
            placeholder="Enter funding goal"
            type="number"
            value={pendingPlayerGoal}
            onChange={(e) => onChangeGoal(e)}
          />
        </Box>
        <Flex>
          <Box px={2}>
            <Button disabled={!isAddButtonReady} variant="clear" onClick={onClickCreatePlayer}>
              <Text color="primary">{player ? "Save" : "Add Player"}</Text>
            </Button>
          </Box>

          <Box px={2}>
            <Button disabled={false} variant="clearDanger" onClick={onClear}>
              <Text color="danger">Cancel</Text>
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

// == Styles ===============================================================

const EInput = styled.input`
  height: 40px !important;
  padding-top: 0 !important;
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;
