import React, { useMemo } from "react";
// SWIPER
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import "./Swiper.css";
// SWIPER ENDS
import gql from "graphql-tag";
import { oc } from "ts-optchain";
import { Link } from "@reach/router";
import { Breakpoint } from "react-socks";
import styled from "@emotion/styled";
import { OccasionHero } from "@pages/occasionComponents/OccasionHero";
import { CenteredCarousel, ArrowCarousel } from "@molecules";
import { Box } from "@atoms";
import { OccasionPreview } from "../OccasionPreview";
import { OccasionsListFragment } from "./__generated__/OccasionsListFragment";

// == Types ================================================================

interface IProps {
  occasions: OccasionsListFragment;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment OccasionsListFragment on OccasionConnection {
      edges {
        cursor
        node {
          id
          urlSlug
          destination {
            url
          }
          ...OccasionHeroFragment
          ...OccasionPreviewFragment
        }
      }
    }
    ${OccasionHero.fragments.fields}
    ${OccasionPreview.fragments.fields}
  `,
};

const SWIPER_SETTINGS = {
  spaceBetween: 5,
  centeredSlides: true,
  slidesPerView: 1.2,
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

const DestinationLink = (props) => {
  const { to, children, ...rest } = props;

  // external link
  if (to.startsWith("http")) {
    return (
      <a {...rest} href={to}>
        {children}
      </a>
    );
  }

  // internal link with router
  return (
    <Link {...rest} to={to}>
      {children}
    </Link>
  );
};

// == Component ============================================================

export function OccasionsList({ occasions }: IProps) {
  const reduceOccasions = useMemo(() => {
    const reducedOccasions = oc(occasions).edges([]);
    if (reducedOccasions.length === 0) return [];

    const nodeReducer = (acc, occasion) => {
      if (!occasion.node) return acc;
      return [...acc, occasion.node];
    };

    return reducedOccasions.reduce(nodeReducer, []);
  }, [occasions]);

  return (
    <>
      <Breakpoint down small>
        <EMobileSlickSliderContainer mb={3} mx="-8px" position="relative">
          <Swiper {...SWIPER_SETTINGS}>
            {reduceOccasions.map((occasion) => (
              <Box key={occasion.id} px="10px">
                <DestinationLink style={{ display: "block" }} to={occasion.destination.url}>
                  <OccasionPreview imageWidth={750} key={occasion.id} occasion={occasion} />
                </DestinationLink>
              </Box>
            ))}
          </Swiper>
        </EMobileSlickSliderContainer>
      </Breakpoint>

      <Breakpoint medium up>
        <Box mx={-2} position="relative">
          <CenteredCarousel
            // centered
            draggable
            stopAutoPlayOnHover
            animationSpeed={2000}
            arrowLeft={
              <ECarouselArrow arrowDirection="left" color="white" iconSize="2x" indent={[0]} />
            }
            arrowRight={
              <ECarouselArrow arrowDirection="right" color="white" iconSize="2x" indent={[0]} />
            }
            autoPlay={8000}
            numItems={reduceOccasions.length}
            slidesPerPage={1.2}
            slidesPerScroll={1}
          >
            {reduceOccasions.map((occasion) => (
              <Box className="centered-carousel-mask" key={occasion.id} mx={2} width={1}>
                <DestinationLink style={{ display: "block" }} to={occasion.destination.url}>
                  <OccasionHero imageWidth={750} key={occasion.id} occasion={occasion} />
                </DestinationLink>
              </Box>
            ))}
          </CenteredCarousel>
        </Box>
      </Breakpoint>
    </>
  );
}

OccasionsList.fragments = FRAGMENTS;

// == Styles ===============================================================

const ECarouselArrow = styled(ArrowCarousel)`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    top: 0;
    bottom: 9%;
    right: 0;
    width: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const EMobileSlickSliderContainer = styled(Box)`
  overflow: hidden;
`;
