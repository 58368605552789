import React, { ReactNode } from "react";
import { useSpring, animated } from "react-spring";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  children: ReactNode;
  delay?: number;
  duration?: number;
}

// == Constants ============================================================

// == Component ============================================================

const FadeOut = ({ duration = 1000, delay = 0, children }: IProps) => {
  const fadeOut = useSpring({
    to: { opacity: 0 },
    from: { opacity: 1 },
    config: { duration },
    delay,
  });
  return <animated.div style={fadeOut}>{children}</animated.div>;
};

export default FadeOut;

// == Styles ===============================================================
