import React, { useEffect } from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import { oc } from "ts-optchain";
import { useScrollTopOnMount, useLoadingDelay, useStoreAppValue } from "@hooks";
import { ErrorLoading } from "@organisms";
import { intercom } from "@services";
import CheckoutView from "./CheckoutView";

// == Types ================================================================

interface IRouteProps {
  // id?: string;
  isLoading?: boolean;
  // isHome: boolean;
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================
export const CHECKOUT_VIEW_QUERY = gql`
  query CheckoutViewQuery($token: String!, $hasUser: Boolean!, $currency: Currency!) {
    GiftcardIntent(token: $token) {
      id
      ...CheckoutFragment
    }
    Viewer @include(if: $hasUser) {
      id
      ...CheckoutViewFragment
    }
    GiftcardTemplate(intentToken: $token) {
      edges {
        cursor
        node {
          id
          backgroundImage
          giftcardImage
          isPhysical
          name
          previewImage
          providerCode
        }
      }
    }
  }
  ${CheckoutView.fragments.viewerFields}
  ${CheckoutView.fragments.fields}
`;

CheckoutViewQuery.defaultProps = {
  isHome: false,
  isLoading: false,
  id: null,
};

// == Component ============================================================

export function CheckoutViewQuery({ location, isLoading }: TProps) {
  const { currentUser, currency } = useStoreAppValue();
  useScrollTopOnMount();

  useEffect(() => {
    intercom.stop();
  }, []);

  const {
    state: { transaction },
  } = location;
  const urlToken = location.pathname.substr(location.pathname.lastIndexOf("/") + 1);
  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery(CHECKOUT_VIEW_QUERY, {
    variables: {
      token: transaction ? transaction.token : urlToken,
      hasUser: !!currentUser,
      currency,
    },
  });

  if (error) {
    return <ErrorLoading />;
  }

  const hasNotLoaded = isEmpty(data) || loading || !loadingDelayReady || isLoading;

  if (hasNotLoaded) return null;
  const stripeCards = oc(data).Viewer.stripeCards([]);
  const giftcardIntent = oc(data).GiftcardIntent();
  const giftcardTemplates = oc(data).GiftcardTemplate.edges([]);
  const stubbedTransaction = {
    ...transaction,
    ...giftcardIntent,
  };

  if (isEmpty(stubbedTransaction) || !stubbedTransaction) return <ErrorLoading />;
  return (
    <CheckoutView
      showMainNav
      giftcardTemplates={giftcardTemplates}
      stripeCards={stripeCards}
      transaction={stubbedTransaction}
    />
  );
}

// == Styles ===============================================================
