// *** takes a campaign and returns a flat list of fundraisers

import flattenDeep from "lodash/flattenDeep";
import { getPaginatedNodes } from "@utils";

export default function combinedFundraisersMaker(campaign) {
  const groups = campaign?.groups;
  const noGroupFundraisers = campaign?.noGroupFundraisers;
  const combinedGroups = [...groups, noGroupFundraisers];
  return flattenDeep(
    combinedGroups.map(({ paginatedFundraisers: { edges } }) => [getPaginatedNodes(edges)])
  );
}
