import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ApolloError } from "apollo-client";
import { Box, Text, Flex, Button, TouchableOpacity, Modal, ChevronLeft, TimesIcon } from "@atoms";
import { NoAvatar, ImageCircle } from "@molecules";
import { useToggle, useStoreAppValue } from "@hooks";
import { formatMoney, ErrorService } from "@services";
import { getPaginatedNodes } from "@utils";
import {
  RemoveEmptyGroup as RemoveEmptyGroupGeneric,
  RemoveEmptyGroupVariables,
} from "./__generated__/RemoveEmptyGroup";
import { PlayersListItemGroupsFragment } from "./__generated__/PlayersListItemGroupsFragment";
import { PlayersListItemNoGroupFragment } from "./__generated__/PlayersListItemNoGroupFragment";

interface IProps {
  player: PlayersListItemGroupsFragment | PlayersListItemNoGroupFragment;
  setSelectedPlayerId: (id: string) => void;
  refetch: () => void;
}

PlayersListItem.fragments = gql`
  fragment PlayersListItemNoGroupFragment on CampaignNoGroupFundraisers {
    id
    name
    amountRaised {
      dollarsAmount
      formatted
    }
    paginatedFundraisers {
      edges {
        cursor
        node {
          id
          userId
          joinedAt
          deactivatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }

  fragment PlayersListItemGroupsFragment on Group {
    id
    name
    imageUrl
    isRemovable
    amountRaised {
      centsAmount
      dollarsAmount
      formatted
    }
    goal {
      dollarsAmount
      centsAmount
    }
    paginatedFundraisers {
      edges {
        cursor
        node {
          id
          userId
          joinedAt
          deactivatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

const REMOVE_PLAYER_MUTATION = gql`
  mutation RemoveEmptyGroup($id: ID!) {
    CampaignGroupDelete(id: $id) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        groups {
          id
          name
        }
      }
    }
  }
`;

// == Functions ============================================================

function AvatarCircle({ name, imageUrl }) {
  const initials = name
    .split(" ")
    .map((str) => str.substr(0, 1))
    .slice(0, 2);
  if (imageUrl)
    return <ImageCircle alt={name} borderRadius={25} height={50} src={imageUrl} width={50} />;
  return <NoAvatar circleDiameter={50} firstName={initials[0]} lastName={initials[1] ?? ""} />;
}

// == Component ============================================================

export function PlayersListItem({ player, setSelectedPlayerId, refetch }: IProps) {
  const [isRemovePlayerOpen, setIsRemovePlayerOpen] = useToggle(false);
  const isNoGroup = player?.id === "NO_GROUP";
  const name = isNoGroup ? "Unassigned" : player?.name;
  const imageUrl = player?.imageUrl;
  const goal = formatMoney(player?.goal?.centsAmount / 100);

  const playerSupporters = getPaginatedNodes(player?.paginatedFundraisers?.edges);
  // const isActive = playerSupporters
  //   .map((supporter) => !supporter?.deactivatedAt && !!supporter?.joinedAt)
  //   .includes(true);

  const primary = useStoreAppValue()?.theme?.color?.primary ?? "#27B7BA";

  const onErrorRemove = (error?: ApolloError) => {
    if (error) ErrorService.error(error);
    Materialize?.toast?.(
      `There was an ERROR removing this player from this team. Please try again.`,
      4000,
      "rounded toast-danger"
    );
  };

  const onCannotRemove = () => {
    Materialize?.toast?.(
      `There are funds currently allocated to this player. Please reassign supporters and any team budget income before removing this player.`,
      4000,
      "rounded toast-danger"
    );
  };

  const [createRemovePlayer] = useMutation<RemoveEmptyGroupGeneric, RemoveEmptyGroupVariables>(
    REMOVE_PLAYER_MUTATION,
    {
      onError: () => onErrorRemove(),
      onCompleted: ({ CampaignGroupDelete }) => {
        if (CampaignGroupDelete.__typename === "MutationError") {
          onErrorRemove();
          return;
        }
        Materialize?.toast?.(
          `This player has been successfully removed from this team`,
          4000,
          "rounded toast-success"
        );
        refetch();
      },
    }
  );

  const onTouchSelectPlayer = (id: string) => {
    if (id === "NO_GROUP") return setSelectedPlayerId("");
    setSelectedPlayerId(id);
  };

  const onDelete = (id: string) => {
    createRemovePlayer({ variables: { id } });
  };

  return (
    <>
      <TouchableOpacity
        containerProps={{ style: { width: "100%" } }}
        onTouch={() => onTouchSelectPlayer(player.id)}
      >
        <Flex alignItems="center" justifyContent="space-between" key={player.id} py={2} width={1}>
          <Flex alignItems="center" paddingRight={10} width={1}>
            <AvatarCircle imageUrl={imageUrl} name={name} />
            <Flex flexDirection="column">
              <Text fontWeight="bold" paddingLeft={10}>
                {name}
              </Text>
              {!isNoGroup && (
                <Flex alignItems="center">
                  <Text fontSize={12} paddingLeft={10}>
                    Funding Goal:
                  </Text>
                  <Text fontSize={12} fontWeight="bold" paddingLeft={2}>
                    {goal}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end">
            <Flex width="80px">
              {isNoGroup || !player?.isRemovable ? (
                <TouchableOpacity onTouch={onCannotRemove}>
                  <TimesIcon containerProps={{ color: "grey", fontSize: 3 }} />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity onTouch={() => setIsRemovePlayerOpen(true)}>
                  <TimesIcon containerProps={{ color: "primary", fontSize: 3 }} />
                </TouchableOpacity>
              )}
            </Flex>
          </Flex>
        </Flex>
      </TouchableOpacity>
      {!!isRemovePlayerOpen && (
        <Modal isOpen={isRemovePlayerOpen} onDismiss={() => setIsRemovePlayerOpen(false)}>
          <Flex alignItems="center" flexDirection="column" justifyContent="center" padding={3}>
            <Flex alignSelf="flex-start" paddingBottom={[2, 3]}>
              <TouchableOpacity onTouch={() => setIsRemovePlayerOpen(false)}>
                <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
              </TouchableOpacity>
              <Box paddingLeft={[2, 3]}>
                <Text fontWeight="bold">Delete Player</Text>
              </Box>
            </Flex>
            <Flex alignItems="center" flexDirection="column" py={2} width={1}>
              <Box display="inline" textAlign="center">
                <Text>This will delete</Text>
                <Text color="primary" fontWeight="bold">
                  {" "}
                  {name}
                </Text>
                <Text> from this team.</Text>
              </Box>
            </Flex>
            <Flex alignItems="center" justifyContent="center" py={2}>
              <Button
                height={50}
                minWidth={250}
                variant="danger"
                onClick={() => onDelete(player.id)}
              >
                Delete
              </Button>
            </Flex>
            <Box py={2} textAlign="center" width={1}>
              <Button
                height={50}
                minWidth={250}
                variant="clear"
                onClick={() => setIsRemovePlayerOpen(false)}
              >
                <Text color="primary" fontSize={[1, 2]}>
                  Cancel
                </Text>
              </Button>
            </Box>
          </Flex>
        </Modal>
      )}
    </>
  );
}
