import { useCallback, useEffect, useState } from "react";
import { ILocation } from "./types";

const LOCATION_TIMEOUT = 10000000; // ~3h

const errorToast = () => {
  // @ts-expect-error Materialize is a global variable, todo: need to be defined
  Materialize?.toast?.(
    `Please enable your web browser's location sharing`,
    3000,
    "rounded toast-danger"
  );
};

export function useDeviceLocation(defaultLocation?: ILocation) {
  const [isAllowed, setIsAllowed] = useState(false);
  const [location, setLocation] = useState<ILocation | undefined>(defaultLocation);

  const setLocationIfAllowed = useCallback(() => {
    navigator?.permissions?.query({ name: "geolocation" }).then(({ state }) => {
      const isGranted = state === "granted";
      setIsAllowed(isGranted);
      if (isGranted) requestGeo();
    });
  }, []);

  useEffect(setLocationIfAllowed, []);

  const requestGeo = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (geo) => {
        setIsAllowed(true);
        setLocation({
          latitude: geo.coords.latitude,
          longitude: geo.coords.longitude,
        });
      },
      () => {
        setIsAllowed(false);
        errorToast();
      },
      { timeout: LOCATION_TIMEOUT }
    );
  }, []);

  return {
    isAllowed,
    location,
    forceSetGeo: setLocation,
    requestGeo,
  };
}
