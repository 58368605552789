import React, { useState, useEffect, useRef } from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import styled from "@emotion/styled";
import Confetti from "react-confetti";
import get from "lodash/get";
import { CircleLoader } from "@components/common/atoms/CircleLoader";
import { RailsUrl, intercom } from "@services";
import { Box, Flex, Text, H3, H2, Button, Fade } from "@atoms";
import { LinkType } from "@types";
import { useStoreAppValue, useNotifyPurchaseAnalytics } from "@hooks";
import { CheckoutThanksViewQuery } from "./__generated__/CheckoutThanksViewQuery";

// == Types ================================================================
interface IProps {
  buyIntent: CheckoutThanksViewQuery;
  pendingTimeoutFinished: boolean;
}

type TProps = RouteComponentProps<IProps>;

// == Constants ============================================================

CheckoutThanksView.fragments = gql`
  fragment CheckoutThanksViewFragment on GiftcardIntent {
    id
    buyerName
    buyerEmail
    isPhysical
    isGift
    isBeingReviewed
    isPending
    shipping {
      city
      country
      postalCode
      province
      street1
      street2
    }
    campaign {
      id
      name
    }
    link {
      id
      name
      type
    }
    gifting {
      name
      email
      deliverOn
    }
    giveAmount {
      formatted
    }
    shippingOption {
      id
      name
      shippingTime
    }
    quantity
    unitPrice {
      formatted
    }
    ...useNotifyPurchaseAnalyticsFragment
  }
  ${useNotifyPurchaseAnalytics.fragment}
`;

// == Component ============================================================

export default function CheckoutThanksView({ buyIntent, pendingTimeoutFinished }: TProps) {
  const { currentUser } = useStoreAppValue();
  const ref = useRef(null);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(700);
  const { isPhysical, isGift, isBeingReviewed, isPending } = buyIntent;
  const giftcardOrVoucherLabel = buyIntent.link.type === LinkType.VOUCHER ? "voucher" : "gift card";
  const stillPending = isPending && pendingTimeoutFinished;
  const isDigital = !isPhysical;
  const isSelf = !isGift;
  useEffect(() => {
    intercom.stop();
    return () => {
      intercom.start();
    };
  }, []);

  useNotifyPurchaseAnalytics(buyIntent, currentUser?.id);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 2000);
    const refHeight = ref.current ? ref.current.offsetHeight : 0;
    const refWidth = ref.current ? ref.current.offsetWidth : 0;
    setHeight(refHeight);
    setWidth(refWidth);
  }, []);

  const continueShoppingLink = () => {
    const campaign = get(buyIntent, "campaign");
    if (campaign) {
      return RailsUrl.campaignsUrl(campaign.id);
    }
    return RailsUrl.rootUrl;
  };

  const renderOrderSummary = () => {
    if (isPhysical) {
      if (isGift) {
        return (
          <Text as="p">
            You have purchased {buyIntent.quantity} x {buyIntent.unitPrice.formatted}{" "}
            {buyIntent.link.name} which will be mailed to:
          </Text>
        );
      }
      return (
        <Text as="p">
          You have purchased {buyIntent.quantity} x {buyIntent.unitPrice.formatted}{" "}
          {buyIntent.link.name} which will be mailed to:
        </Text>
      );
    }

    if (isDigital) {
      if (isGift) {
        return (
          <Box mb={3}>
            <Text as="p">
              You have purchased {buyIntent.quantity} x {buyIntent.unitPrice.formatted}{" "}
              {buyIntent.link.name} which will be emailed to {buyIntent.gifting.name} at{" "}
              {buyIntent.gifting.email} on {buyIntent.gifting.deliverOn}
            </Text>
          </Box>
        );
      }
      return (
        <Text as="p" mb={3}>
          You have purchased {buyIntent.quantity} x {buyIntent.unitPrice.formatted}{" "}
          {buyIntent.link.name} which will be {currentUser && "sent to your FlipGive Wallet and"}{" "}
          emailed to {buyIntent.buyerEmail}
        </Text>
      );
    }
    return null;
  };

  const renderReceiptDetails = () => {
    const reviewText = () => {
      return (
        <Text as="p" fontWeight="bold" mb={3} mt={2}>
          Your order has been placed and your payment is being processed.{" "}
          {isPhysical || !currentUser
            ? `You will receive a
            confirmation email within 24 hours to
            ${buyIntent.buyerEmail} when the order is
            completed.`
            : `You will recieve an email to ${
                buyIntent.buyerEmail
              } when the order is completed. Please allow for up to 24 hours for your ${giftcardOrVoucherLabel} to appear in ${
                isSelf ? "your FlipGive Wallet" : "their email"
              }.`}
        </Text>
      );
    };
    if (isBeingReviewed) {
      return (
        <Box borderTop="1px dashed #ccc" px={[3, 4]} width="100%">
          <Text as="p" fontWeight="bold" mt={3}>
            Payment Review
          </Text>
          <Text as="p" mb={2} mt={0}>
            Order ID {buyIntent.id.substr(0, 8)}
          </Text>
          {reviewText()}
        </Box>
      );
    }
    return (
      <Box borderTop="1px dashed #ccc" pt={3} px={[3, 4]} width="100%">
        {isDigital && stillPending && (
          <Text as="p" fontWeight="bold">
            Payment Review
          </Text>
        )}
        <Text as="p" mb={2}>
          Order ID {buyIntent.id.substr(0, 8)}
        </Text>
        {!isBeingReviewed && renderOrderSummary()}
        {/* {isDigital && stillPending && reviewText()} */}
        {isPhysical && (
          <>
            <Box mt={2}>
              <Text as="p" color="primary">
                {buyIntent.gifting ? buyIntent.gifting.name : buyIntent.buyerName}
              </Text>
              <Text as="p" color="primary">
                {buyIntent.shipping.street1}
                {buyIntent.shipping.street2 && `, ${buyIntent.shipping.street2}`}
              </Text>
              <Text as="p" color="primary">
                {buyIntent.shipping.city}, {buyIntent.shipping.province}
              </Text>
              <Text as="p" color="primary">
                {buyIntent.shipping.postalCode}
              </Text>
              <Text as="p" color="primary">
                {buyIntent.shipping.country}
              </Text>
            </Box>
            <Text as="p" mb={3} mt={2}>
              Please allow 3-5 days for your order to be processed, it will then take{" "}
              {buyIntent.shippingOption.shippingTime} to arrive.
            </Text>
          </>
        )}
      </Box>
    );
  };

  const renderConfetti = () => {
    return (
      loading && (
        <Confetti
          run
          gravity={0.1}
          height={height}
          initialVelocityX={10}
          initialVelocityY={10}
          numberOfPieces={250}
          recycle={false}
          style={{ zIndex: 0 }}
          width={width}
          wind={0}
        />
      )
    );
  };

  const renderButton = () => {
    if (isPhysical || isBeingReviewed || isGift || !currentUser) {
      return (
        <Button as="a" href={continueShoppingLink()} mb={3}>
          Continue Shopping
        </Button>
      );
    }

    if (isSelf && currentUser && isDigital) {
      return (
        <Button
          as="a"
          href={`${RailsUrl.giftcardWalletUrl()}?showGiftcardWarning=true&return=home`}
          mb={3}
        >
          Take Me To My FlipGive Wallet
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
      #intercom-container { display: none!important }
    `,
        }}
      />
      <nav className="navbar navbar--secondary" role="navigation">
        <div className="nav-wrapper">
          <div className="container centered">
            <EBrandLogo className="brand-logo">
              <img
                alt="FlipGive"
                src="https://flipgive.twic.pics/images/sites/site_logo_campaigns/000/000/031/original/flipgive_logo_black.png?twic=v1/resize=125"
              />
            </EBrandLogo>
          </div>
        </div>
      </nav>
      <EFlex alignItems="center" bg="white" justifyContent="center" px={3}>
        <Flex
          alignItems="center"
          border="1px solid #ccc"
          flexDirection="column"
          justifyContent="center"
          position="relative"
          ref={ref}
          width={width}
        >
          {!isBeingReviewed &&
            (!isPending || isPhysical || pendingTimeoutFinished) &&
            renderConfetti()}
          <Box mt={5} mx={2} textAlign="center" zIndex={1}>
            {!isPending || isPhysical || pendingTimeoutFinished ? (
              <CircleLoader delay={1000} />
            ) : (
              <CircleLoader delay={6000} />
            )}
            {!isBeingReviewed && (
              <>
                <H3 fontWeight="bold" mb={2}>
                  Congratulations!
                </H3>
                <H2 color="primary" mb={3}>
                  {get(buyIntent, "campaign")
                    ? `${buyIntent.campaign.name} has earned ${buyIntent.giveAmount.formatted}!`
                    : `You have earned ${buyIntent.giveAmount.formatted}!`}
                </H2>
              </>
            )}
            <Box />
            {loading && (!isPending || isPhysical || pendingTimeoutFinished) && renderButton()}
          </Box>
          <Fade show={!isPending || isPhysical || isBeingReviewed || pendingTimeoutFinished}>
            {renderReceiptDetails()}
          </Fade>
        </Flex>
      </EFlex>
    </>
  );
}

// == Styles ===============================================================

const EFlex = styled(Flex)`
  min-height: 85vh;
`;

const EBrandLogo = styled.span`
  display: inline-block !important;
  margin: 0 !important;
  float: none !important;
`;
