import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { Button, Box } from "@atoms";
import { ErrorService } from "@services";
import {
  TransferIntentMutation,
  TransferIntentMutationVariables,
} from "./__generated__/TransferIntentMutation";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  selectedListItem: $FixMe;
  selectedFundraiserId: $FixMe;
  transferState: string;
  setTransferState: (value: string) => void;
}

// == Constants ============================================================

const TRANSFER_INTENT_MUTATION = gql`
  mutation TransferIntentMutation($fundraiserId: ID, $intentId: ID!) {
    TransferIntent(fundraiserId: $fundraiserId, intentId: $intentId) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        directAmountRaised {
          dollarsAmount
          formatted
        }
        groups {
          id
          name
          amountRaised {
            dollarsAmount
            formatted
          }
        }
        fundraisers {
          id
          name
          userId
          avatarImage
          isOwner
          joinedAt
          deactivatedAt
          group {
            id
          }
          amountRaised {
            formatted
            dollarsAmount
          }
        }
      }
    }
  }
`;

FundsPageFundraiserTransferButton.defaultProps = {};

// == Component ============================================================

export default function FundsPageFundraiserTransferButton({
  selectedListItem,
  selectedFundraiserId,
  transferState,
  setTransferState,
}: IProps) {
  const [createFundraiserTransferMutation, { loading }] = useMutation<
    TransferIntentMutation,
    TransferIntentMutationVariables
  >(TRANSFER_INTENT_MUTATION);

  const intentId = selectedListItem.id;

  const onClick = () => {
    createFundraiserTransferMutation({
      variables: { fundraiserId: selectedFundraiserId, intentId },
    })
      .then((response) => {
        const {
          data: { TransferIntent },
        } = response;

        if (TransferIntent.errors) {
          const errorMessages = [];
          TransferIntent.errors.map((error: $FixMe) => {
            error.inputErrors.map((inputError) => {
              errorMessages.push(`${error.input} ${inputError}`);
            });
          });
          transferState !== "ERROR" ? setTransferState("ERROR") : null;
        } else {
          transferState !== "SUCCESS" ? setTransferState("SUCCESS") : null;
        }
      })
      .catch(ErrorService.error);
  };

  return (
    <Box padding={[2, 2, 3]}>
      <Button
        variant={!selectedFundraiserId || loading ? "disabledSolid" : "primary"}
        onClick={() => onClick()}
      >
        {selectedFundraiserId && !loading ? `Transfer Funds` : `Transfer`}
        {loading && `Processing...`}
      </Button>
    </Box>
  );
}

// == Styles ===============================================================
