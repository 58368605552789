import React from "react";
import gql from "graphql-tag";
import striptags from "striptags";
import styled from "@emotion/styled";
import { Box, IBoxProps, Text, H3 } from "@atoms";
import { InStorePromoFragment } from "./__generated__/InStorePromoFragment";

// == Types ================================================================

interface IProps extends IBoxProps {
  link: InStorePromoFragment;
}
// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment InStorePromoFragment on Link {
      id
      promoTitle
      promoLabel
      promoDescription
    }
  `,
};
// == Component ============================================================

const InStorePromo = ({ link, ...rest }: IProps) => {
  return (
    <Box bg="notification" {...rest}>
      <H3 color="notificationColor" display="block" mb={1}>
        {link.promoTitle}
      </H3>
      <EText
        as="div"
        color="default"
        dangerouslySetInnerHTML={{ __html: striptags(link.promoDescription, ["s", "b", "p", "u"]) }}
      />
    </Box>
  );
};

InStorePromo.fragments = FRAGMENTS;
export default InStorePromo;

// == Styles ===============================================================
const EText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  color: ${(props) => props.theme.colors.em};
  * {
    font-size: ${(props) => props.theme.fontSizes[0]}px;
    color: ${(props) => props.theme.colors.em};
  }
`;
