import React from "react";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {

// }

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export const DeliveryTitle = () => {
  return (
    <div className="centered mb-3">
      <h2 className="mb-2">Delivery Information</h2>
      <p>Tell us how and where you would like the funds delivered.</p>
    </div>
  );
};

DeliveryTitle.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
