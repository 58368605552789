import React from "react";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { constants, RailsUrl } from "@services";
import { Box, Text } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { BrandModalGiftcardLink } from "../BrandModalGiftcardLink";
import { BrandModalShopLink } from "../BrandModalShopLink";
import { BrandModalInStoreLink } from "../BrandModalInStoreLink";
import {
  BrandModalLinksFragment,
  BrandModalLinksFragment_linkedCardLink,
} from "./__generated__/BrandModalLinksFragment";

// == Types ================================================================

type TLinkCardLink = BrandModalLinksFragment_linkedCardLink;

interface IProps {
  brand: BrandModalLinksFragment;
  location?: $FixMe;
  tinyLinkKey?: string;
  locationId?: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment BrandModalLinksFragment on Brand {
      id
      name
      nearestLocation {
        id
        hasLinkedCard
      }
      shopLink {
        id
        ...BrandModalShopLinkFragment
      }
      giftcardLink {
        id
        ...BrandModalGiftcardLinkFragment
      }
      linkedCardLink {
        id
        ...BrandModalInStoreLinkFragment
      }
    }
    ${BrandModalShopLink.fragments.fields}
    ${BrandModalGiftcardLink.fragments.fields}
    ${BrandModalInStoreLink.fragments.fields}
  `,
};

// == Component ============================================================

const BrandModalLinks = ({ brand, location, tinyLinkKey, locationId: locationIdProp }: IProps) => {
  const {
    featureFlags: { newCollection },
    campaignId,
  } = useStoreAppValue();

  const { shopLink, giftcardLink, linkedCardLink } = brand;
  const navigateToLink = (linkId: string, locationId?: string) => {
    const brandRoute = `?${constants.brandParam}=${brand.id}`;
    let navigationRoute = `?${constants.linkParam}=${linkId}`;
    if (locationId) {
      navigationRoute += `&location_id=${locationId}`;
      navigate(navigationRoute, {
        state: { brandRoute, tinyLinkKey, brandId: brand.id, canReturn: true },
      });
    }
    navigate(navigationRoute, {
      state: { brandRoute, tinyLinkKey, brandId: brand.id, canReturn: true },
    });
  };

  const navigateToLinkedCardLink = (link: TLinkCardLink, locationId?: string) => {
    if (locationIdProp) {
      navigateToLink(link.id, locationId);
    } else {
      const route = RailsUrl.nearbyUrl({
        campaignId,
        brandId: brand.id,
        brandName: brand.name,
        allLocations: true,
      });
      navigate(route);
    }
  };

  const hasLinkedCardLink =
    !!linkedCardLink && (!locationIdProp || (!!location && location.hasLinkedCard));
  if (!brand) return null;
  return (
    <>
      {!!shopLink && (
        <Box key={shopLink.id}>
          <BrandModalShopLink isFirst link={shopLink} onClick={navigateToLink} />
        </Box>
      )}
      {!!giftcardLink && (
        <Box key={giftcardLink.id}>
          <BrandModalGiftcardLink
            isFirst={!shopLink}
            link={giftcardLink}
            onClick={navigateToLink}
          />
        </Box>
      )}
      {!!newCollection && !!hasLinkedCardLink && (
        <Box key={linkedCardLink?.id}>
          <BrandModalInStoreLink
            isFirst={!!(!shopLink && !giftcardLink)}
            link={linkedCardLink}
            locationId={locationIdProp}
            onClick={navigateToLinkedCardLink}
          />
        </Box>
      )}
      {!shopLink && !giftcardLink && !hasLinkedCardLink && !!newCollection && (
        <Box textAlign="center">
          <Text as="p">There are no offers available at this time.</Text>
        </Box>
      )}
    </>
  );
};

BrandModalLinks.fragments = FRAGMENTS;
export default BrandModalLinks;

// == Styles ===============================================================
