import React, { ReactNode } from "react";
import gql from "graphql-tag";
import capitalize from "lodash/capitalize";
import times from "lodash/times";
import { Ratings } from "@components/common/molecules";
import { Text, H3, Box, Flex, ClearButton, Link } from "@atoms";
import { BrandModalLocationAddress } from "../BrandModalLocationAddress";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  brand: $FixMe;
  location: $FixMe;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment BrandModalAboutFragment on Brand {
      id
      name
      description
    }
  `,
}; // == Component ============================================================

const renderLocationInformation = (brand, location) => {
  // LOCATION HOURS & LOCATIONS COUNT ARE MISSING
  if (!location) return null;
  const { metaInfo } = location;
  const reduceMetaInfoArray = (metaInfoArray) => {
    const capitalizedArray = metaInfoArray.map((category) => capitalize(category));
    return capitalizedArray.join(", ");
  };
  const website = () => {
    const { website: url } = location;
    const protomatch = /^(https?|ftp):\/\//;
    return url.replace(protomatch, "");
  };

  return (
    <>
      {!!location && (
        <Box borderTop={brand.description && "1px solid silver"} p={3}>
          <BrandModalLocationAddress brand={brand} location={location} />
        </Box>
      )}
      {!!location.phoneNumber && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Phone Number</H3>
          <Text> {location.phoneNumber}</Text>
        </Box>
      )}
      {!!metaInfo.rating && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Rating</H3>
          <Ratings color="primary" pb={1} rating={parseFloat(metaInfo.rating)} total={5} />
          <Text>Based on {metaInfo.ratingsCount} Reviews on Yelp</Text>
        </Box>
      )}

      {!!metaInfo.priceRange && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Price Range</H3>
          <Box>
            {times(4, (i) => (
              <Text
                color={i < parseInt(metaInfo.priceRange, 10) ? "primary" : "default"}
                key={`price-range-${i}`}
              >
                $
              </Text>
            ))}
          </Box>
        </Box>
      )}

      {!!metaInfo.primaryCategory && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Type</H3>
          <Text>{metaInfo.primaryCategory}</Text>
        </Box>
      )}
      {!!metaInfo.categories && metaInfo.categories.length > 0 && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Category</H3>
          <Text>{reduceMetaInfoArray(metaInfo.categories)}</Text>
        </Box>
      )}
      {!!metaInfo.ambiance && metaInfo.ambiance.length > 0 && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Category</H3>
          <Text>{reduceMetaInfoArray(metaInfo.ambiance)}</Text>
        </Box>
      )}
      {!!metaInfo.menuUrl && metaInfo.menuUrl.includes("https") && (
        <Flex alignItems="center" borderTop="1px solid silver" justifyContent="space-between" p={3}>
          <H3>Menu</H3>
          <ClearButton as="a" href={metaInfo.menuUrl} target="_blank">
            View Menu
          </ClearButton>
        </Flex>
      )}
      {!!metaInfo.mealTypesServed && metaInfo.mealTypesServed.length > 0 && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Serves</H3>
          <Text>{reduceMetaInfoArray(metaInfo.mealTypesServed)}</Text>
        </Box>
      )}
      {!!metaInfo.knownFor && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Known For</H3>
          <Text>{metaInfo.knownFor}</Text>
        </Box>
      )}
      {!!metaInfo.bestNightsToVisit && metaInfo.bestNightsToVisit.length > 0 && (
        <Box borderTop="1px solid silver" p={3}>
          <H3 pb={2}>Best Nights</H3>
          <Text>{reduceMetaInfoArray(metaInfo.bestNightsToVisit)}</Text>
        </Box>
      )}
      {!!location.website && (
        <Flex borderTop="1px solid silver" p={3}>
          <Link as="a" href={location.website} target="_blank">
            {website()}
          </Link>
        </Flex>
      )}
    </>
  );
};

const BrandModalAbout = ({ brand, location }: IProps) => {
  // const locationsCount = get(brand, "locationsCount", 0);
  return (
    <>
      {!!brand.description && (
        <Box p={3}>
          <Text>{brand.description}</Text>
        </Box>
      )}
      {renderLocationInformation(brand, location)}
    </>
  );
};

BrandModalAbout.fragments = FRAGMENTS;
export default BrandModalAbout;

// == Styles ===============================================================
