import React from "react";
import { Provider } from "mobx-react";
import { ApolloProvider } from "react-apollo";
import UserFundsQuery from "./components/UserFundsQuery";
import { Settings, apolloClient } from "@services";
import { ErrorBoundary } from "@utils";
import { FlipgiveStore } from "@stores";
import { useFullStory } from "@hooks";

const ActivityApp = (props) => {
  Settings.setDefaultsFromProps(props);
  FlipgiveStore.setCurrentUser(props.currentUser);
  useFullStory(props.currentUser);

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient(Settings.apiUrl)}>
        <Provider FlipgiveStore={FlipgiveStore}>
          <UserFundsQuery />
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default ActivityApp;
