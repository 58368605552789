import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { ErrorService } from "@services";
import { Box, Text, Image, Button } from "@atoms";
import { AnnouncementModalFragment } from "./__generated__/AnnouncementModalFragment";
import {
  DismissAnnouncementMutation,
  DismissAnnouncementMutationVariables,
} from "./__generated__/DismissAnnouncementMutation";
import { TimesIcon } from "../../../atoms/Icon/TimesIcon";
import { Slider } from "./Slider";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  announcement: AnnouncementModalFragment;
}

// == Constants ============================================================

// variables not fully utilized yet.
// ctaDestination
// youtubeId
AnnouncementModal.fragments = gql`
  fragment AnnouncementModalFragment on Announcement {
    id
    ctaText
    ctaUrl
    description
    image {
      large
    }
    image_1 {
      large
    }
    image_2 {
      large
    }
    image_3 {
      large
    }
    image_4 {
      large
    }
    image_5 {
      large
    }
    description_1
    description_2
    description_3
    description_4
    description_5
    title
  }
`;

const DISMISS_ANNOUNCEMENT_MUTATION = gql`
  mutation DismissAnnouncementMutation($id: ID!) {
    DismissAnnouncement(id: $id) {
      ... on Viewer {
        id
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;

// AnnouncementModal.defaultProps = {};

// == Component ============================================================

export default function AnnouncementModal({ announcement }: IProps) {
  const { ctaText, ctaUrl, description, image, title } = announcement;
  const [dismissAnnouncementMutation] = useMutation<
    DismissAnnouncementMutation,
    DismissAnnouncementMutationVariables
  >(DISMISS_ANNOUNCEMENT_MUTATION);

  useEffect(() => {
    if (typeof $ !== "undefined") {
      $("#materialize-modal__announcement").modal({ dismissible: false });
      $("#materialize-modal__announcement").modal("open");
    }
  }, []);

  const onDismissClick = () => {
    dismissAnnouncementMutation({ variables: { id: announcement.id } }).catch(ErrorService.error);
    $("#materialize-modal__announcement").modal("close");
  };
  const [isAutoPlay, setIsAutoPlay] = React.useState(true);

  return (
    <div className="modal modal--small" id="materialize-modal__announcement">
      <div onClick={onDismissClick}>
        <TimesIcon
          containerProps={{
            style: {
              position: "absolute",
              left: 8,
              top: 8,
              padding: 5,
            },
            fontSize: 3,
          }}
        />
      </div>
      <Box p={3} mt={4} textAlign="center">
        <Slider
          autoplay={isAutoPlay}
          autoplaySpeed={3000}
          onSwipe={() => {
            setIsAutoPlay(false);
          }}
        >
          {announcement?.slides?.map(({ description, image }) => (
            <Box mb={3}>
              {!!image && <Image alt={title} display="block" mb={3} src={image} />}
              {!!description && (
                <Text display="block" lineHeight="copy" mt={1}>
                  {description}
                </Text>
              )}
            </Box>
          ))}
        </Slider>
        {!!ctaText && (
          <Button
            as="a"
            display="block"
            href={ctaUrl}
            mb={2}
            target="_blank"
            width="100%"
            onClick={onDismissClick}
          >
            {ctaText}
          </Button>
        )}
      </Box>
    </div>
  );
}

// == Styles ===============================================================
