import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ApolloError } from "apollo-client";
import { ErrorService } from "@services";
import { useToggle } from "@hooks";
import { Box, TouchableOpacity, Text } from "@atoms";
import {
  WithdrawalCsvReportMutation,
  WithdrawalCsvReportMutationVariables,
} from "./__generated__/WithdrawalCsvReportMutation";

// == Types ================================================================

interface IProps {
  payment: {
    id: string;
  };
}
// == Constants ============================================================

const WITHDRAWAL_CSV_REPORT_MUTATION = gql`
  mutation WithdrawalCsvReportMutation($input: ViewerEmailPaymentBreakdownInput!) {
    ViewerEmailPaymentBreakdown(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Payment {
        id
        recipients {
          edges {
            cursor
            node {
              id
              fundraiser {
                id
                email
              }
            }
          }
        }
      }
    }
  }
`;
// == Functions ============================================================

const buttonTextMaker = (isReportSent, loading) => {
  if (loading) return `Sending`;
  if (isReportSent) return `Report Sent`;
  return `Email Full Report`;
};

// == Component ============================================================
export default function FundsPagePaymentCsvButton({ payment }: IProps) {
  const [isReportSent, toggleIsReportSent] = useToggle(false);

  const onError = (error?: ApolloError) => {
    if (error) ErrorService.error(error);
    Materialize?.toast?.(
      "There was an ERROR in sending your CSV report. Please try again.",
      4000,
      "rounded toast-danger"
    );
  };
  const [createWithdrawalCsvReportMutation, { loading }] = useMutation<
    WithdrawalCsvReportMutation,
    WithdrawalCsvReportMutationVariables
  >(WITHDRAWAL_CSV_REPORT_MUTATION, {
    variables: { input: { paymentId: payment?.id } },
    onError,
    onCompleted: ({ ViewerEmailPaymentBreakdown }) => {
      if (ViewerEmailPaymentBreakdown.__typename === "MutationError") {
        onError();
        return;
      }

      const email = ViewerEmailPaymentBreakdown.recipients.edges?.[0]?.node?.fundraiser?.email;

      Materialize?.toast?.(
        `Your CSV report has been sent${email ? ` to ${email}` : ""}`,
        4000,
        "rounded toast-success"
      );
      toggleIsReportSent(true);
    },
  });

  const onTouch = () => {
    createWithdrawalCsvReportMutation({ variables: { input: { paymentId: payment.id } } });
  };

  return (
    <Box paddingRight={[1, 2, 3]}>
      {isReportSent || loading ? (
        <Text>{buttonTextMaker(isReportSent, loading)}</Text>
      ) : (
        <TouchableOpacity color="primary" onTouch={onTouch}>
          {buttonTextMaker(isReportSent, loading)}
        </TouchableOpacity>
      )}
    </Box>
  );
}
// == Styles ===============================================================
