import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { oc } from "ts-optchain";
import { Text } from "@atoms";
import { useLoadingDelay } from "@hooks";
import BrandOfferSingle from "./BrandOfferSingle";

// == Types ================================================================

interface IProps {
  campaignId: string;
  isHome?: boolean;
}

// == Constants ============================================================

const BRAND_OFFER_SINGLE_QUERY = gql`
  query BrandOfferSingleQuery($id: ID!) {
    Campaign(id: $id) {
      id
      brand {
        id
        ...BrandOfferSingleFragment
      }
    }
  }
  ${BrandOfferSingle.fragments.fields}
`;

// == Component ============================================================
// TODO refactor this component to be in StoreFrontFeaturedOffer, should work the same
export const BrandOfferSingleQuery = ({ campaignId, isHome, ...rest }: IProps) => {
  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery(BRAND_OFFER_SINGLE_QUERY, {
    variables: {
      id: campaignId,
    },
  });

  if (error) {
    return <Text>{error.message}</Text>;
  }

  if (!data || loading || !loadingDelayReady) return null;

  const { brand } = oc(data).Campaign();
  if (!brand) return null; // return error

  return <BrandOfferSingle brand={brand} isHome={isHome} {...rest} />;
};

// == Styles ===============================================================
