import { useState, useEffect } from "react";
import throttle from "lodash/throttle";

const getDeviceConfig = (width: number) => {
  if (width < 576) {
    return "xs";
  }
  if (width >= 576 && width < 768) {
    return "sm";
  }
  if (width >= 768 && width < 992) {
    return "md";
  }
  if (width >= 992) {
    return "lg";
  }
  return null;
};

export const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};
