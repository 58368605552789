import React from "react";
import { Dialog, DialogProps, DialogOverlay } from "@reach/dialog";
import shouldForwardProp from "@styled-system/should-forward-prop";

import "@reach/dialog/styles.css";
import "./Modal.css";
import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends DialogProps {
  size: "default" | "small";
  fullScreenMobile?: boolean;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  isOpen: true,
  onDismiss: null,
  size: "default",
  fullScreenMobile: true,
};

// == Component ============================================================

export const Modal = ({ isOpen, onDismiss, fullScreenMobile, ...rest }: IProps) => {
  return (
    <DialogOverlay isOpen={isOpen} onDismiss={onDismiss}>
      <EDialog
        aria-label="modal"
        fullScreenMobile={fullScreenMobile}
        isOpen={isOpen}
        onDismiss={onDismiss}
        {...rest}
      />
    </DialogOverlay>
  );
};

Modal.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================

// need to do more investigation on the 80vw
const EDialog = styled(Dialog, {
  shouldForwardProp,
})`
  padding: 0;
  width: ${({ fullScreenMobile }) => (fullScreenMobile ? "100vw" : "80vw")};
  height: ${({ fullScreenMobile }) => (fullScreenMobile ? "100vh" : "auto")};
  margin: ${({ fullScreenMobile }) => (fullScreenMobile ? 0 : "none")};
  overflow-y: auto;
  z-index: 1000;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    border-radius: ${({ theme }) => `${theme.radii.modal}px`};
    height: auto;
    margin: 10vh auto;
    max-width: ${({ theme, size }) =>
      size === "default" ? theme.sizes.modalWidth : theme.sizes.modalSmallWidth}px;
  }
`;
