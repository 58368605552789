import React, { createContext, useContext, useReducer, Reducer, Dispatch } from "react";
import { TTeamType, IDeliveryFormikValues } from "../components";

interface IBeneficiaryValues {
  legalName: string;
  taxId?: string;
  teamName?: string;
  teamType?: TTeamType;
  website?: string;
}

interface IDeliveryValues {
  checkType?: "EMAIL" | "POSTAGE" | "";
  city?: string;
  country: string;
  phoneNumber?: string;
  postalCode: string;
  province: string;
  street1: string;
  street2: string;
  email: string;
}

export interface IReducerState {
  campaignId: string;
  currentStep: number;
  redirectUrl: string;
  beneficiaryValues: IBeneficiaryValues;
  deliveryValues: IDeliveryValues;
  errors?: string[];
}

type TReducerActions =
  | { type: "updateBeneficiaryForm"; beneficiaryValues: IBeneficiaryValues }
  | { type: "startBeneficiaryForm" }
  | { type: "onFormBack" }
  | { type: "updateErrors"; errors?: string[] }
  | { type: "confirmBeneficiary"; deliveryValues: IDeliveryFormikValues };

const reducer: Reducer<IReducerState, TReducerActions> = (state, action) => {
  switch (action.type) {
    case "onFormBack":
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case "startBeneficiaryForm":
      return {
        ...state,
        currentStep: 2,
      };
    case "updateBeneficiaryForm":
      return {
        ...state,
        currentStep: 3,
        beneficiaryValues: action.beneficiaryValues,
      };
    case "confirmBeneficiary":
      return {
        ...state,
        deliveryValues: action.deliveryValues,
        currentStep: 4,
      };
    case "updateErrors":
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
  }
};

const StateContext = createContext<IReducerState | undefined>(undefined);
const StateDispatchContext = createContext<Dispatch<TReducerActions> | undefined>(undefined);

export function BeneficiaryProvider({
  initialState,
  children,
}: {
  initialState: IReducerState;
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateDispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </StateDispatchContext.Provider>
  );
}

export function useBeneficiaryValue() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useBeneficiaryValue must be used within a BeneficiaryProvider");
  }
  return context;
}

export function useBeneficiaryDispatch() {
  const context = useContext(StateDispatchContext);
  if (context === undefined) {
    throw new Error("useBeneficiaryDispatch must be used within a BeneficiaryProvider");
  }
  return context;
}

export const useBeneficiaryContext = () => {
  return [useBeneficiaryValue(), useBeneficiaryDispatch()] as [
    IReducerState,
    Dispatch<TReducerActions>
  ];
};
