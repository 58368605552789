import React from "react";
import { Query } from "react-apollo";
import { Loading, Error } from "@components";
import { FUNDS_QUERY } from "../queries";
import UserFunds from "./UserFunds";

const FundsQuery = () => (
  <Query query={FUNDS_QUERY} variables={{}}>
    {({ data, loading }) => {
      if (loading) {
        return <Loading />;
      }
      if (data.Viewer) {
        return <UserFunds transferrableFunds={data.Viewer.personalAccount.transferrableFunds} />;
      }
      return <Error msg="Unable to load funds." />;
    }}
  </Query>
);

export default FundsQuery;
