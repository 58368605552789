import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Container, Row, Column } from "@atoms";
import { BrandCircle } from "@organisms";
import { analytics } from "@services";
import { useStoreAppValue, useBreakpoint } from "@hooks";
import {
  CloseTeamHeader,
  CloseTeamGoalMet,
  CloseTeamTopMonth,
  CloseTeamTopMembers,
  CloseTeamRaised,
  CloseTeamReferrals,
  CloseTeamTopCategories,
  CloseTeamActions,
  CloseTeamTopBrands,
  CloseTeamGiftcards,
  CloseTeamTestimonial,
  CloseTeamSurvey,
  CloseTeamFG,
  CloseTeamLoading,
  CloseTeamPlaceholder,
} from "./CloseTeamReportComponents";

// == Types ================================================================

interface IProps {}

// == Constants ============================================================

CloseTeamReportPage.defaultProps = {};

const CLOSE_REPORT_QUERY = gql`
  query CampaignCloseReportQuery {
    Campaign {
      id
      testimonial
      closeReport {
        didMeetGoal
        topMonth
        topMembers {
          id
          name
          avatarImage
        }
        amountRaised {
          dollarsAmount
          formatted
        }
        referralsCount
        referralsAmountRaised {
          formatted
        }
        topCategories
        teamCaptainName
        teamEndDate
        showTestimonial
        showSurvey
        topBrands {
          id
          name
          ...BrandCircleFragment
        }
        numGiftcards
      }
    }
  }
  ${BrandCircle.fragments.fields}
`;

const CONTAINER_MAX_WIDTH = 900;

// == Component ===========================================================

type TElement = ({ theme, campaign }: { them: string; campaign: $FixMe }) => Element;

interface IUpdateModules {
  closeReport: $FixMe;
  updateState: (payload: $FixMe) => void;
}
// const SET_LEFT_MODULES = ({ closeReport, updateState }: IUpdateModules) => {
//   const leftModules = [];
//   if (closeReport.amountRaised.dollarsAmount > 0) {
//     if (closeReport.didMeetGoal) {
//       leftModules.push(CloseTeamGoalMet);
//     }

//     if (closeReport.topMembers && closeReport.topMembers.length > 1) {
//       leftModules.push(CloseTeamTopMembers);
//     }

//     if (closeReport.topBrands && closeReport.topBrands.length > 0) {
//       leftModules.push(CloseTeamTopBrands);
//     }

//     if (closeReport.showTestimonial) {
//       leftModules.push(CloseTeamTestimonial);
//     }

//     if (closeReport.showSurvey || true) {
//       leftModules.push(CloseTeamSurvey);
//     }
//   } else {
//     leftModules.push(CloseTeamSurvey);
//   }
//   leftModules.push(CloseTeamPlaceholder);
//   updateState(leftModules);
// };

// const SET_RIGHT_MODULES = ({ closeReport, updateState }: IUpdateModules) => {
//   const rightModules = [];
//   if (closeReport.referralsCount > 0 && closeReport.referralsAmountRaised.dollarsAmount > 0) {
//     rightModules.push(CloseTeamReferrals);
//   }

//   if (closeReport.amountRaised.dollarsAmount > 0) {
//     if (closeReport.topCategories.length >= 2) {
//       rightModules.push(CloseTeamTopCategories);
//     }

//     if (closeReport.numGiftcards > 0) {
//       rightModules.push(CloseTeamGiftcards);
//     }
//   }
//   rightModules.push(CloseTeamFG);

//   updateState(rightModules);
// };

const SET_MODULES = ({ closeReport, setLeftBigModules, setRightBigModules, setMobileModules }) => {
  const debug = false;
  let count = 0;
  const leftModules: TElement[] = [];
  const rightModules: TElement[] = [];
  const mobileModules: TElement[] = [];
  const pushModule = (module: TElement) => {
    const moduleArray = count % 2 === 0 ? leftModules : rightModules;
    count += 1;
    moduleArray.push(module);
    mobileModules.push(module);
  };

  if (closeReport.amountRaised.dollarsAmount > 0 || debug) {
    if (closeReport.didMeetGoal || debug) {
      pushModule(CloseTeamGoalMet);
    }

    if ((closeReport.topMembers && closeReport.topMembers.length > 1) || debug) {
      pushModule(CloseTeamTopMembers);
    }

    if ((closeReport.topBrands && closeReport.topBrands.length > 0) || debug) {
      pushModule(CloseTeamTopBrands);
    }

    if (closeReport.showTestimonial || debug) {
      pushModule(CloseTeamTestimonial);
    }

    if (closeReport.showSurvey || debug) {
      pushModule(CloseTeamSurvey);
    }
  } else {
    pushModule(CloseTeamSurvey);
  }

  if (
    (closeReport.referralsCount > 0 && closeReport.referralsAmountRaised.dollarsAmount > 0) ||
    debug
  ) {
    pushModule(CloseTeamReferrals);
  }

  if (closeReport.amountRaised.dollarsAmount > 0 || debug) {
    if (closeReport.topCategories.length >= 2) {
      pushModule(CloseTeamTopCategories);
    }

    if (closeReport.numGiftcards > 0 || debug) {
      pushModule(CloseTeamGiftcards);
    }
  }
  if (count % 2 !== 0 || debug) {
    // pushModule(CloseTeamPlaceholder);
    pushModule(CloseTeamFG);
  }
  setLeftBigModules(leftModules);
  setRightBigModules(rightModules);
  setMobileModules(mobileModules);
  // updateState(leftModules);
};

export function CloseTeamReportPage(props: IProps) {
  const [leftBigModules, setLeftBigModules] = useState<[TElement]>([]);
  const [rightBigModules, setRightBigModules] = useState<[TElement]>([]);
  const [mobileModules, setMobileModules] = useState<[TElement]>([]);
  const breakpoint = useBreakpoint();
  const { campaignId, isOwner, isMember, currentUser } = useStoreAppValue();
  const { data, loading } = useQuery(CLOSE_REPORT_QUERY, {
    onCompleted: ({ Campaign }) => {
      const { closeReport } = Campaign;
      SET_MODULES({ closeReport, setLeftBigModules, setRightBigModules, setMobileModules });

      const agent = isOwner ? "owner" : isMember ? "member" : "";
      const totalRaised =
        closeReport.amountRaised.dollarsAmount >= 100
          ? ">=100"
          : closeReport.amountRaised.dollarsAmount > 0
          ? "<100"
          : "0";
      analytics.track.trackCloseReportEvent("Viewed Close Report", {
        campaignId,
        agent,
        totalRaised,
      });
    },
  });

  if (loading || !data?.Campaign) return <CloseTeamLoading />;

  if (breakpoint === "xs") {
    const FirstModule = mobileModules.shift();
    return (
      <Container>
        <CloseTeamHeader campaign={data.Campaign} />
        <CloseTeamRaised campaign={data.Campaign} />
        {FirstModule && <FirstModule campaign={data.Campaign} theme="light" />}
        {!!data?.Campaign.closeReport.topMonth && (
          <CloseTeamTopMonth month={data?.Campaign.closeReport.topMonth} />
        )}
        {mobileModules.map((Module, index) => {
          const firstColor = data?.Campaign.closeReport.topMonth ? "light" : "dark";
          const alternateColor = firstColor === "light" ? "dark" : "light";
          const theme = index % 2 === 0 ? firstColor : alternateColor;
          return (
            <>
              <Module campaign={data.Campaign} theme={theme} />
            </>
          );
        })}
        {!!currentUser && <CloseTeamActions campaign={data.Campaign} />}
      </Container>
    );
  }

  const LeftComponent1 = leftBigModules[0];
  const LeftComponent2 = leftBigModules[1];
  const LeftComponent3 = leftBigModules[2];
  const LeftComponent4 = leftBigModules[3];

  const RightComponent1 = rightBigModules[0];
  const RightComponent2 = rightBigModules[1];
  const RightComponent3 = rightBigModules[2];
  const RightComponent4 = rightBigModules[3];
  return (
    <>
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <CloseTeamHeader campaign={data.Campaign} />
        <Row>
          <Column autoColumn={false} width={[1, 1 / 2, 1 / 2]}>
            {!!LeftComponent1 && <LeftComponent1 campaign={data.Campaign} theme="light" />}
            {!!data?.Campaign.closeReport.topMonth && (
              <CloseTeamTopMonth month={data?.Campaign.closeReport.topMonth} />
            )}
            {!!LeftComponent2 && <LeftComponent2 campaign={data.Campaign} theme="light" />}
          </Column>
          <Column autoColumn={false} width={[1, 1 / 2, 1 / 2]}>
            <CloseTeamRaised campaign={data.Campaign} />
            {!!RightComponent1 && <RightComponent1 campaign={data.Campaign} theme="light" />}
            {!!RightComponent2 && <RightComponent2 campaign={data.Campaign} theme="dark" />}
          </Column>
        </Row>
        <Row>
          <Column autoColumn={false} width={[1, 1 / 2, 1 / 2]}>
            {!!LeftComponent3 && <LeftComponent3 campaign={data.Campaign} theme="dark" />}
            {!!LeftComponent4 && <LeftComponent4 campaign={data.Campaign} theme="light" />}
          </Column>
          <Column autoColumn={false} width={[1, 1 / 2, 1 / 2]}>
            {!!RightComponent3 && <RightComponent3 campaign={data.Campaign} theme="light" />}
            {!!RightComponent4 && <RightComponent4 campaign={data.Campaign} theme="dark" />}
          </Column>
        </Row>
        {!!currentUser && <CloseTeamActions campaign={data.Campaign} />}
      </Container>
    </>
  );
}

// == Styles ===============================================================
