import sanitizeHtml from "sanitize-html";
import Parser from "html-react-parser";

function findMatches(string) {
  // Hey @[My Friend](123) let's go get food!
  const regex = /@\[([^[\]]+)\]\((\d+|(team)?)\)/g;
  let currentMatch;
  const matches = [];

  currentMatch = regex.exec(string);
  while (currentMatch) {
    matches.push(currentMatch);
    currentMatch = regex.exec(string);
  }

  return matches;
}

function replaceMatches(matches, string) {
  let newString = string;
  matches.forEach((match) => {
    newString = newString.replace(match[0], `<span class="comment-mention">@${match[1]}</span>`);
  });
  return newString;
}

function sanitize(string) {
  return sanitizeHtml(string, {
    allowedTags: ["span"],
    allowedAttributes: {
      span: ["class"],
    },
  });
}

export default function mentionify(comment) {
  const matches = findMatches(comment);
  const newComment = replaceMatches(matches, comment);

  return Parser(sanitize(newComment));
}
