import React from "react";
import { string } from "prop-types";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { inject } from "mobx-react";
import { Activity, Loading, Error } from "@components";
import { ActivityFragment } from "@queries";

export const ACTIVITY_QUERY = gql`
  query ActivitiesQuery($feed: FeedType, $id: ID!, $publicView: Boolean = false) {
    Activity(feed: $feed, id: $id) {
      ...ActivityFragment
    }
  }
  ${ActivityFragment}
`;

@inject("TimelineStore")
export default class ActivityData extends React.Component {
  static propTypes = {
    feed: string.isRequired,
    activityId: string.isRequired,
  };

  render() {
    const { feed, activityId, TimelineStore } = this.props;

    return (
      <Query
        query={ACTIVITY_QUERY}
        variables={{ feed, id: String(activityId), publicView: TimelineStore.publicView }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (data?.Activity) {
            return <Activity activity={data.Activity} />;
          }
          return (
            <p style={{ marginTop: "20px" }}>
              {error?.graphQLErrors?.[0]?.message || "Unable to load activity"}
            </p>
          );
        }}
      </Query>
    );
  }
}
