import React from "react";
import { TouchableOpacity, Flex, H2, Text, FontAwesome, ClearButton, Box } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  onBackClick: () => void;
  isEditing: boolean;
  isSearchBarOpen: boolean;
  toggleEditing: () => void;
}

// == Constants ============================================================

// == Component ============================================================

const FavoritesModalHeader = ({
  onBackClick,
  isEditing,
  toggleEditing,
  isSearchBarOpen,
}: IProps) => {
  return (
    <Flex alignItems="center" borderBottom="silver" p={3}>
      <TouchableOpacity onTouch={() => onBackClick()}>
        <Text color="primary">
          <FontAwesome icon="arrow-left" />
        </Text>
      </TouchableOpacity>
      <Box flex={1} ml={3} pr={2}>
        <H2>Favorites</H2>
      </Box>
      {!isSearchBarOpen && (
        <ClearButton onClick={toggleEditing}>{isEditing ? "Save" : "Edit"}</ClearButton>
      )}
    </Flex>
  );
};

export default FavoritesModalHeader;

// == Styles ===============================================================
