import React from "react";
import gql from "graphql-tag";
import groupBy from "lodash/groupBy";
import { IBoxProps } from "@atoms";
import { InStoreLinkedCardsList } from "../InStoreLinkedCardsList";
import { InStoreLinkedCardsAdd } from "../InStoreLinkedCardsAdd";
import { InStoreLinkedCardsFragment } from "./__generated__/InStoreLinkedCardsFragment";

// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends IBoxProps {
  link: InStoreLinkedCardsFragment;
  linkedCards: [];
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment InStoreLinkedCardsFragment on Link {
      id
      name
      acceptedLinkedCardTypes
    }
  `,
};

// == Component ============================================================

const InStoreLinkedCards = ({ linkedCards, link, ...rest }: IProps) => {
  const { acceptedLinkedCardTypes } = link;
  if (linkedCards && linkedCards.length === 0) {
    return <InStoreLinkedCardsAdd link={link} />;
  }

  return (
    <InStoreLinkedCardsList
      acceptedLinkedCardTypes={acceptedLinkedCardTypes}
      groupedViewerCards={groupBy(linkedCards, "cardType")}
      {...rest}
      // disabled={disabled}
    />
  );
};

InStoreLinkedCards.fragments = FRAGMENTS;
export default InStoreLinkedCards;

// == Styles ===============================================================
