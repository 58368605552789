import React from "react";
import { Box, H3, Button } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  onResetForm: () => void;
}

// == Constants ============================================================

NearbyListEmpty.defaultProps = {};

// == Component ============================================================

export default function NearbyListEmpty({ onResetForm }: IProps) {
  return (
    <Box mt={4} textAlign="center">
      <H3 mb={3}>There are no results</H3>
      <Button onClick={onResetForm}>View stores near me</Button>
    </Box>
  );
}

// == Styles ===============================================================
