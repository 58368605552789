import React from "react";
import { ApolloProvider } from "react-apollo";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MaterialThemeProvider } from "@material-ui/styles";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";
import LeaveCampaignTab from "./components/LeaveCampaignTab";
import { useFullStory } from "@hooks";

const theme = createMuiTheme({
  palette: {
    primary: { 500: "#2c9895" },
    secondary: { main: "#fff" },
  },
  typography: {
    fontFamily: "inherit",
  },
  overrides: {
    MuiInput: {
      root: {
        position: "initial",
      },
    },
    MuiInputBase: {
      root: {
        paddingRight: "1rem",
      },
    },
  },
});

const LeaveCampaignApp = (props) => {
  Settings.setDefaultsFromProps(props);
  useFullStory(props.currentUser);
  const { campaignId, isOwner, userId, variant } = props;

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient(Settings.apiUrl)}>
        <MaterialThemeProvider theme={theme}>
          <LeaveCampaignTab
            campaignId={campaignId}
            isOwner={isOwner}
            userId={userId}
            variant={variant}
          />
        </MaterialThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default LeaveCampaignApp;
