import React from "react";
import { Box, Flex } from "@atoms";
import ReactPlaceholder from "react-placeholder";
import { RectShape, RoundShape, TextBlock, TextRow } from "react-placeholder/lib/placeholders";
import { theme } from "@styles";

const placeholderColor = theme.colors.placeholderLightGrey;
const textRowStyles = { margin: 5, height: 40, width: "90%" };

const FundsPagePlaceholder = () => (
  <ReactPlaceholder
    showLoadingAnimation
    color={placeholderColor}
    customPlaceholder={
      <Flex style={{ flexDirection: "column", alignItems: "center", marginBottom: 50 }}>
        <RectShape color={placeholderColor} style={{ width: "100%", height: 50, marginRight: 0 }} />
        <Flex
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 400,
          }}
        >
          <RoundShape
            color={placeholderColor}
            style={{ marginTop: 30, marginBottom: 30, width: 300, height: 300 }}
          />
          <RectShape color={placeholderColor} style={{ marginLeft: 40, width: 180, height: 120 }} />
        </Flex>
        <TextRow color={placeholderColor} style={textRowStyles} />
        <TextRow color={placeholderColor} style={textRowStyles} />
        <TextRow color={placeholderColor} style={textRowStyles} />
        <TextRow color={placeholderColor} style={textRowStyles} />
        <TextRow color={placeholderColor} style={textRowStyles} />
        <TextRow color={placeholderColor} style={textRowStyles} />
      </Flex>
    }
    ready={false}
  />
);

export default function FundsPageLoading() {
  return (
    <Box>
      <FundsPagePlaceholder />
    </Box>
  );
}
