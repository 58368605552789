import { useMemo } from "react";

export const useUserAgent = () => {
  const { userAgent } = window.navigator ?? {};
  const {
    isAndroid,
    isIOS,
    isIpad,
    isChrome,
    isSafari,
    isFirefox,
    isEdge,
    isMobile,
    isMacOs,
    isWindows,
    isLinux,
    isRobot,
    isTablet,
  } = useMemo(
    () => ({
      isAndroid: userAgent.includes("Android"),
      isIOS: userAgent.includes("iPhone"),
      isIpad: userAgent.includes("iPad"),
      isChrome: userAgent.includes("Chrome") && !userAgent.includes("Edg"),
      isSafari: userAgent.includes("Safari") && !userAgent.includes("Chrome"),
      isFirefox: userAgent.includes("Firefox"),
      isEdge: userAgent.includes("Edg"),
      isMobile:
        userAgent.includes("Android") || userAgent.includes("iPhone") || userAgent.includes("iPad"),
      isMacOs: userAgent.includes("Macintosh"),
      isWindows: userAgent.includes("Windows"),
      isLinux: userAgent.includes("Linux"),
      isTablet: /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      ),
      isRobot: /(bot|googlebot|crawler|spider|robot|crawling)/i.test(userAgent),
    }),
    []
  );

  const platform = useMemo(() => {
    return isAndroid
      ? "Android"
      : isIOS
      ? "iOS"
      : isIpad
      ? "iPad"
      : isMacOs
      ? "OS X"
      : isWindows
      ? "Windows"
      : isLinux
      ? "Linux"
      : "unknown";
  }, [isAndroid, isIOS, isIpad, isMacOs, isWindows, isLinux]);

  const browser = useMemo(() => {
    return isChrome
      ? "Chrome"
      : isSafari
      ? "Safari"
      : isFirefox
      ? "Firefox"
      : isEdge
      ? "Microsoft Edge"
      : "unknown";
  }, [isChrome, isSafari, isFirefox, isEdge]);

  const device = useMemo(() => {
    return isMobile ? "Mobile" : isTablet ? "Tablet" : isRobot ? "Robot" : "Desktop";
  }, [isMobile, isTablet, isRobot]);

  return {
    isAndroid,
    isIOS,
    isIpad,
    isChrome,
    isSafari,
    isFirefox,
    isEdge,
    isMobile,
    isMacOs,
    isWindows,
    isLinux,
    isTablet,
    platform,
    browser,
    device,
  };
};
