import React, { useState } from "react";
import { ApolloProvider } from "react-apollo";
import { ThemeProvider } from "emotion-theming";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import queryString from "query-string";
import { REACT_SOCKS_BREAKPOINTS, generateTheme } from "@styles";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";
import InviteModal, {
  InviteTemplate,
} from "../../components/common/organisms/inviteComponents/InviteModal/InviteModal";
import { useFullStory } from "@hooks";

setDefaultBreakpoints(REACT_SOCKS_BREAKPOINTS);

const InviteModalApp = (props) => {
  Settings.setDefaultsFromProps(props);
  const theme = generateTheme({ primary: props.primaryColor });
  useFullStory(props.currentUser);

  return (
    <ErrorBoundary>
      <BreakpointProvider>
        <ApolloProvider client={apolloClient(Settings.apiUrl)}>
          <ThemeProvider theme={theme}>
            <RenderInviteModal />
          </ThemeProvider>
        </ApolloProvider>
      </BreakpointProvider>
    </ErrorBoundary>
  );
};

function RenderInviteModal() {
  const urlParams = queryString.parse(window.location.search);
  const [isOpen, setIsOpen] = useState(!!urlParams.invite);

  return (
    <InviteModal
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      template={InviteTemplate.SUPPORTER}
    />
  );
}

export default InviteModalApp;
