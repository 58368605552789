import React, { useState } from "react";
import styled from "@emotion/styled";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { Box } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { theme } from "@styles";
import { constants, ErrorService } from "@services";
import { Currency } from "@types";
import {
  UpdateViewerCurrencyMutation,
  UpdateViewerCurrencyMutationVariables,
} from "./__generated__/UpdateViewerCurrencyMutation";
// == Types ================================================================

interface IProps {
  setCurrency: (currency: Currency) => void;
  setCanProceed: (arg: boolean) => void;
}

// == Constants ============================================================

const UPDATE_VIEWER_CURRENCY = gql`
  mutation UpdateViewerCurrencyMutation($currency: Currency) {
    ViewerSettingsUpdate(currency: $currency) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Viewer {
        id
        currency
      }
    }
  }
`;

const googleMapsLibraries = ["places"];
OnboardingCitySearch.defaultProps = {};

// == Component ============================================================

export default function OnboardingCitySearch({ setCurrency, setCanProceed }: IProps) {
  const [address, setAddress] = useState("");
  const { gmapsKey } = useStoreAppValue();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: gmapsKey,
    libraries: googleMapsLibraries,
  });

  const [userCurrencySelectMutation, { loading }] = useMutation<
    UpdateViewerCurrencyMutation,
    UpdateViewerCurrencyMutationVariables
  >(UPDATE_VIEWER_CURRENCY);

  const handleChange = (addressInput: string) => {
    setAddress(addressInput);
    setCanProceed(false);
  };

  const changeViewerCurrency = (country: string) => {
    const currency = country === "US" ? Currency.USD : Currency.CAD;
    userCurrencySelectMutation({
      variables: { currency },
    })
      .then((response) => {
        const { data } = response;
        if (data?.ViewerSettingsUpdate.__typename === "User") {
          setCanProceed(true);
          setCurrency(currency);
        }
      })
      .catch(ErrorService.error);
  };
  const handleSelect = (addressInput: string) => {
    geocodeByAddress(addressInput)
      .then((results) => {
        setAddress(results[0].formatted_address);
        let country;
        results[0].address_components.map((component) => {
          return component.types.find((type) => {
            if (type === "country") country = component.short_name;
          });
        });
        return country;
      })
      .then((country) => {
        changeViewerCurrency(country);
      })
      .catch((error) => ErrorService.error(error));
  };

  if (!isLoaded) return null;

  return (
    <>
      <PlacesAutocomplete
        searchOptions={{
          types: ["(cities)"],
          componentRestrictions: { country: ["us", "ca"] },
        }}
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <EPlacesContainer position="relative" width={[1]}>
            <ELabel className="active">Select your city</ELabel>
            <EInput
              aria-label="Select your city"
              {...getInputProps({
                placeholder: "Your city",
                className: "location-search-input form-control input-field",
              })}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  Materialize?.toast?.(
                    "Please select from the dropdown.",
                    4000,
                    "rounded toast-error"
                  );
                  e.preventDefault();
                }
              }}
            />

            <EPlacesAutoComplete border={suggestions.length > 0 ? "silver" : "none"}>
              {loading && (
                <ELoadingView bg="white" key="loading" pl={2}>
                  Loading...
                </ELoadingView>
              )}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                const style = suggestion.active
                  ? {
                      backgroundColor: `${theme.colors.lightGreyBg}`,
                      cursor: "pointer",
                    }
                  : { backgroundColor: `${theme.colors.white}`, cursor: "pointer" };
                return (
                  <Box
                    py={1}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.placeId}
                  >
                    <Box pl={2}>{suggestion.description}</Box>
                  </Box>
                );
              })}
            </EPlacesAutoComplete>
          </EPlacesContainer>
        )}
      </PlacesAutocomplete>
    </>
  );
}

// == Styles ===============================================================

const EPlacesContainer = styled(Box)`
  position: relative;
  margin-bottom: 2.5rem;
`;

const EPlacesAutoComplete = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
`;

const ELoadingView = styled(Box)`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: ${({ theme }) => theme.colors.gray} !important;
  }
`;

const EInput = styled.input`
  margin-bottom: 0 !important;
  height: 40px !important;
  padding-top: 0 !important;
  width: 50%;
  padding-left: 10px !important;
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;

// const EBox = styled(Box)`
//   position: absolute;
//   top: 100%;
//   left: 0;
//   right: 0;
// `;
