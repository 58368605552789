import React from "react";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Breakpoint } from "react-socks";
import { LinkBlockFeatured } from "@organisms/linkBlockComponents";
import { Column, Row } from "@atoms";
import { CollectionFeaturedFragment } from "./__generated__/CollectionFeaturedFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionFeaturedFragment;
  storefrontId?: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment CollectionFeaturedFragment on Collection {
      id
      links(first: 5) {
        id
        ...LinkBlockFeaturedFragment
      }
    }
    ${LinkBlockFeatured.fragments.fields}
  `,
};

// == Component ============================================================

const renderFeaturedBlock = (link, index, collectionId, storefrontId) => {
  return (
    <EColumn autoColumn={false} index={index} key={link.id} mb={3} width={[1 / 2, 1 / 3, 1 / 5]}>
      <LinkBlockFeatured link={link} collectionId={collectionId} storefrontId={storefrontId} />
    </EColumn>
  );
};

const CollectionFeatured = ({ collection, storefrontId }: IProps) => {
  return (
    <>
      <Breakpoint xsmall>
        <Row flexWrap="wrap" justifyContent="center">
          {collection.links.slice(0, 4).map((link, index) => {
            return renderFeaturedBlock(link, index, collection.id, storefrontId);
          })}
        </Row>
      </Breakpoint>
      <Breakpoint small up>
        <Row flexWrap="wrap" justifyContent="center">
          {collection.links.slice(0, 5).map((link, index) => {
            return renderFeaturedBlock(link, index, collection.id, storefrontId);
          })}
        </Row>
      </Breakpoint>
    </>
  );
};

export default CollectionFeatured;

CollectionFeatured.fragments = FRAGMENTS;

// == Styles ===============================================================

const EColumn = styled(Column)`
  @media only screen and (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding-left: ${(props) =>
      props.index % 2 === 0 ? `${props.theme.space[4]}px` : `${props.theme.space[3]}px`};
    padding-right: ${(props) =>
      props.index % 2 === 0 ? `${props.theme.space[3]}px` : `${props.theme.space[4]}px`};
  }
`;
