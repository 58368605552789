import React from "react";
import { Circle, Text } from "@atoms";
import { constants } from "@services";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  firstName: string;
  lastName?: string | null;
  isHighlighted?: boolean;
  circleDiameter?: number;
}

// == Constants ============================================================

NoAvatar.defaultProps = {
  lastName: "",
  isHighlighted: false,
  circleDiameter: 50,
};

const firstLetterChecker = (name: string) => {
  if (!name || !constants.ALPHA_NUMBER_ARRAY.includes(name.charAt(0).toUpperCase())) return "";
  return name.charAt(0).toUpperCase();
};

// == Component ============================================================

export default function NoAvatar({
  firstName,
  lastName,
  isHighlighted,
  circleDiameter,
  ...rest
}: IProps) {
  const firstInitial = firstLetterChecker(firstName);
  const lastInitial = lastName ? firstLetterChecker(lastName) : "";
  const colorIndex = constants.ALPHA_NUMBER_ARRAY.indexOf(firstInitial) % 20;
  const avatarColor = constants.NO_AVATAR_COLORS[colorIndex];

  return (
    <Circle
      bg={avatarColor}
      border={isHighlighted ? "avatarHighlight" : ""}
      height={circleDiameter}
      width={circleDiameter}
      {...rest}
    >
      <Text color="white">{firstInitial + lastInitial}</Text>
    </Circle>
  );
}

// == Styles ===============================================================
