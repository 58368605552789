import React from "react";
import gql from "graphql-tag";
import { analytics } from "@services";
import { useToggle, useStoreAppValue } from "@hooks";
import { Box, Flex, H2, Image, Modal, Text, Button } from "@atoms";
import { OnboardingWelcomeModalFragment } from "./__generated__/OnboardingWelcomeModalFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  viewer: OnboardingWelcomeModalFragment;
}

// == Constants ============================================================

OnboardingWelcomeModal.defaultProps = {};

OnboardingWelcomeModal.fragment = gql`
  fragment OnboardingWelcomeModalFragment on Viewer {
    id
    activeCampaign {
      id
      isReferred
      referralInfo {
        title
        ctaLabel
        message
      }
    }
    onboarding {
      id
      announcement {
        title
        description
        ctaUrl
        ctaText
        ctaDestination
        image {
          small
          large
        }
        youtubeId
      }
    }
  }
`;

// == Component ============================================================

// https://flipgive.twic.pics/images/site/home/BONUS%20-%20referral%20card%20header.jpg
// https://flipgive.twic.pics/images/site/home/BONUS%20-%20White%20background.png

export default function OnboardingWelcomeModal({ viewer }: IProps) {
  const { campaignId, siteName, isOwner } = useStoreAppValue();
  const [modalVisible, toggleModalVisible] = useToggle(true);
  const announcement = viewer.onboarding?.announcement;
  if (!announcement && !viewer?.activeCampaign.isReferred) return null;
  const { title, description, ctaUrl, ctaText, image } = announcement;
  const trackFirstViewShop = () => {
    toggleModalVisible();
    analytics.track.onboardingFirstViewShop(
      {
        campaignId,
        isReferred: viewer?.activeCampaign.isReferred,
      },
      isOwner
    );
  };

  const trackFirstViewExplore = () => {
    toggleModalVisible();
    analytics.track.onboardingFirstViewExplore(
      {
        campaignId,
      },
      isOwner
    );
  };

  if (viewer?.activeCampaign.isReferred) {
    const { referralInfo } = viewer?.activeCampaign;
    return (
      <Modal fullScreenMobile={false} isOpen={modalVisible} size="small">
        <Box p={4}>
          <Image
            alt={title}
            borderRadius="modal"
            mx="auto"
            src="https://flipgive.twic.pics/images/site/home/BONUS%20-%20referral%20card%20header.jpg"
          />
          <Box>
            <H2 py={3}>{referralInfo.title}</H2>
            <Text>{referralInfo.message} </Text>
          </Box>
          <Flex alignItems="center" flexDirection="column" justifyContent="center">
            <Button mt={3} width="100%" onClick={trackFirstViewShop}>
              {referralInfo.ctaLabel}
            </Button>
          </Flex>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal fullScreenMobile={false} isOpen={modalVisible} size="small">
      <Box p={4}>
        <Image alt={title} borderRadius="modal" src={image.large} />
        <Box>
          <H2 pb={2} pt={3}>
            {title}
          </H2>
          <Text>{description}</Text>
        </Box>
        <Flex alignItems="center" flexDirection="column" justifyContent="center">
          <Button as="a" href={ctaUrl} mb={2} mt={3} width="100%" onClick={trackFirstViewShop}>
            {ctaText}
          </Button>
          <Button variant="clear" width="100%" onClick={trackFirstViewExplore}>
            Not Now, Thank You
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}

// == Styles ===============================================================
