import React from "react";
import { Container } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// == Component ============================================================

const CollectionViewLoading = () => {
  return <Container isMainContainer>Loading...</Container>;
};

export default CollectionViewLoading;

// == Styles ===============================================================
