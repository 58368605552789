import { LinkType } from "@types";

export function isShopLink(type: LinkType) {
  return ![
    LinkType.GIFTCARDOFFER,
    LinkType.AUTORELOADGIFTCARD,
    LinkType.VOUCHER,
    LinkType.EMPYR,
  ].includes(type);
}
