import { HTMLAttributes, useEffect, useMemo } from "react";
import { EMobilePlatform, TAppName, TAppNameData } from "./ISmartBanner";
import { FontAwesome } from "@components/common";
import { useCookies } from "react-cookie";

// == Types ================================================================

interface IProps {
  appName: TAppName;
}

// == Constants ============================================================

const bannerData: Record<EMobilePlatform, TAppNameData> = {
  android: {
    shop: {
      imageSrc: "https://flipgive.twic.pics/images/android-shop-logo.png",
      url:
        "https://play.google.com/store/apps/details?id=com.flipgive.app&referrer=utm_source%3Dsmart_banner",
      title: "FlipGive Shop - Fund Sports",
    },
    banking: {
      imageSrc: "https://flipgive.twic.pics/images/android-banking-logo.png",
      url:
        "https://play.google.com/store/apps/details?id=com.flipgive.bank&referrer=utm_source%3Dsmart_banner",
      title: "FlipGive Banking - Team Spend",
    },
    imgSize: "50px",
  },
  iPhone: {
    shop: {
      imageSrc: "https://flipgive.twic.pics/images/ios-shop-logo.png",
      url: "https://apps.apple.com/app/flipgive-fund-youth-sports/id1382434604",
      title: "FlipGive Shop - Fund Sports",
    },
    banking: {
      imageSrc: "https://flipgive.twic.pics/images/ios-banking-logo.png",
      url: "https://apps.apple.com/us/app/flipgive-banking-team-spend/id1612671694",
      title: "FlipGive Banking - Team Spend",
    },
    imgSize: "70px",
  },
};

// == Component ============================================================

export function SmartBanner({ appName }: IProps) {
  const [cookies, setCookie] = useCookies();
  const { smartBannerClosed } = cookies;

  const { navElement, body } = useMemo(() => {
    if (!window) return {};
    return {
      navElement: document.querySelector("div.new-main-nav-container") as HTMLDivElement,
      body: document.querySelector("body"),
    };
  }, []);

  const { isClosed, toggleIsClosed } = useMemo(() => {
    const isClosedValue = JSON.parse(smartBannerClosed ?? "false");
    return {
      isClosed: isClosedValue,
      toggleIsClosed: (value: boolean) => {
        setCookie("smartBannerClosed", JSON.stringify(value), { path: "/" });

        if (navElement && body) {
          navElement.style.marginTop = "0";
          body.style.marginTop = "0";
        }
      },
    };
  }, [smartBannerClosed]);

  const { isIPhone, isAndroid } = useMemo(() => {
    const platform = window ? window.navigator.userAgent : "";
    return {
      isIPhone: platform.includes("iPhone"),
      isAndroid: platform.includes("Android"),
    };
  }, []);

  const data = bannerData[isAndroid ? "android" : "iPhone"];
  const isMobile = isAndroid || isIPhone;

  const goToDownloadLink = () => {
    window.open(data[appName].url, "_blank");
  };

  const bannerStyles = styles(isAndroid);

  const isHidden = !isMobile || isClosed;

  useEffect(() => {
    if (navElement && body && !isHidden) {
      navElement.style.marginTop = "100px";
      body.style.marginTop = "100px";
    }
  }, [navElement, body]);

  if (isHidden) return null;
  return (
    <div style={bannerStyles.wrapper}>
      <div style={bannerStyles.innerWrapper}>
        <span style={bannerStyles.xIcon}>
          <FontAwesome icon={["fas", "times"]} onClick={() => toggleIsClosed(true)} />
        </span>
        <div style={{ marginTop: isAndroid ? "-2rem" : undefined, marginLeft: 12 }}>
          <img
            alt="app logo"
            height={data.imgSize}
            src={data[appName].imageSrc}
            width={data.imgSize}
            onClick={goToDownloadLink}
          />
        </div>
        {/* text column */}
        <div aria-hidden="true" style={bannerStyles.textColumn} onClick={goToDownloadLink}>
          <p style={bannerStyles.appName}>{data[appName].title}</p>
          <p style={bannerStyles.flipgive}>FlipGive Inc.</p>
          <div style={bannerStyles.stars}>
            {Array.from({ length: 5 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <FontAwesome
                icon="star"
                containerProps={{
                  height: "16px",
                  width: "16px",
                  color: isIPhone ? "#FE9809" : "#0F9D58",
                }}
                key={index}
              />
            ))}
          </div>
          {isIPhone && <p style={bannerStyles.iphoneText}>FREE - On the App Store</p>}
          {isAndroid && (
            <div>
              <img
                alt="play store logo"
                style={{ marginBottom: "-0.5rem" }}
                height="26px"
                src="https://flipgive.twic.pics/images/playstore-logo1.png"
                width="106px"
              />
            </div>
          )}
        </div>
        <div style={bannerStyles.ctaWrapper}>
          {isIPhone && (
            <button style={bannerStyles.iphoneButton} type="button" onClick={goToDownloadLink}>
              Get
            </button>
          )}
          {isAndroid && (
            <button style={bannerStyles.androidButton} type="button" onClick={goToDownloadLink}>
              Install
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

// == Styles ===============================================================
const styles = (isAndroid?: boolean): Record<string, HTMLAttributes<HTMLDivElement>["style"]> => ({
  xIcon: {
    position: isAndroid ? "absolute" : undefined,
    right: isAndroid ? "0" : undefined,
    top: isAndroid ? "0" : undefined,
  },
  androidButton: {
    backgroundColor: "#0F9D58",
    padding: "8px 24px",
    color: "white",
    borderRadius: "0px",
    border: "none",
    outline: "none",
  },
  wrapper: {
    padding: "0.75rem",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
    zIndex: 50,
    borderBottom: "1px solid #E5E7EB",
    position: "fixed",
    top: 0,
    minHeight: 80,
  },
  innerWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    position: "relative",
  },
  textColumn: {
    marginLeft: "0.5rem",
    display: "flex",
    flexDirection: "column",
    marginBottom: 0,
  },
  ctaWrapper: {
    display: isAndroid ? "flex" : undefined,
    alignSelf: isAndroid ? "end" : undefined,
    justifyContent: isAndroid ? "center" : undefined,
    marginBottom: isAndroid ? "0.75rem" : undefined,
    height: "100%",
    flex: 1,
  },
  appName: {
    fontWeight: "bold",
    color: "#111827",
    fontSize: "1rem",
    marginBottom: 0,
  },
  flipgive: {
    fontSize: "0.75rem",
    color: "#6B7280",
    marginBottom: 0,
  },
  stars: {
    display: "flex",
    marginTop: "0.25rem",
  },
  iphoneText: {
    fontSize: "0.75rem",
    color: "#111827",
    marginBottom: 0,
  },
  // "text-xl text-blue-500 font-normal text-center w-full"
  iphoneButton: {
    fontSize: "1.5rem",
    color: "#1078FE",
    textAlign: "center",
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
  },
});
