import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Flex, Box, Text, IBoxProps, TouchableOpacity, Link } from "@atoms";
import { theme } from "@styles";
import { renderIf } from "@services";

// == Types ================================================================

interface IProps extends IBoxProps {
  text: string;
  href?: string;
  subText?: string;
  leftComponent: ReactNode;
  onTouch?: () => void | null;
  leftComponentProps?: IBoxProps;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  onTouch: null,
  subText: null,
};

// == Component ============================================================

const ImageListItem = ({
  text,
  href,
  subText,
  leftComponent,
  onTouch,
  leftComponentProps,
  ...rest
}: IProps) => {
  const component = (
    <EFlex alignItems="center" {...rest}>
      <ELeftComponent
        height={theme.sizes.profileImageSize}
        mr={2}
        width={theme.sizes.profileImageSize}
        {...leftComponentProps}
      >
        {leftComponent}
      </ELeftComponent>
      <Box minWidth="0px">
        <Text
          ellipsis
          className="image-list-item-react__title"
          color="default"
          display="block"
          fontSize={2}
          lineHeight="solid"
        >
          {text}
        </Text>
        {renderIf(!!subText)(
          <Text ellipsis color="subText" fontSize={1} className="image-list-item__subtext">
            {subText}
          </Text>
        )}
      </Box>
    </EFlex>
  );

  if (href) return <Link href={href}>{component}</Link>;
  if (!onTouch) return component;
  return <TouchableOpacity onTouch={onTouch}>{component}</TouchableOpacity>;
};

ImageListItem.defaultProps = DEFAULT_PROPS;
export default ImageListItem;

// == Styles ===============================================================

const EFlex = styled(Flex)`
  &:hover {
    .circle {
      border-color: ${theme.colors.primary};
    }
  }
`;

const ELeftComponent = styled(Box)`
  flex-shrink: 0;
`;
