import React from "react";
import { Box, Text, Flex, UniversityIcon } from "@atoms";
import styled from "@emotion/styled";
import { theme } from "@styles";

// == Types ================================================================

interface IProps {
  name: string;
}

// == Constants ============================================================

BeneficiaryCheque.defaultProps = {};

// == Component ============================================================

export default function BeneficiaryCheque({ name }: IProps) {
  return (
    <Box mb={2}>
      <Box border="silver" bg="lightPrimaryBg" p={3}>
        <Box mt={4} mb={3} borderBottom="silver">
          <Text mr={2}>To:</Text>
          <Text fontSize={4}>{name}</Text>
        </Box>
        <Flex mb={3}>
          <ECheckDetails></ECheckDetails>
          <EChequeDollars></EChequeDollars>
        </Flex>
        <Flex justifyContent="space-between" alignItems="flex-end">
          <UniversityIcon
            containerProps={{ fontSize: theme.fontSizes[4], color: theme.colors.grey }}
          ></UniversityIcon>
          <Text fontSize={0} color="grey">
            0123456789
          </Text>
          <Text fontSize={0} color="grey">
            0001234567890
          </Text>
          <Text fontSize={0} color="grey">
            01234
          </Text>
        </Flex>
      </Box>
    </Box>
  );
}

// == Styles ===============================================================

const ECheckDetails = styled(Box)`
  flex: 1 1 0;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 20px;
    top: 0;
    border-top: 3px dashed #ccc;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 40px;
    top: 16px;
    border-top: 3px dashed #ccc;
  }
`;

const EChequeDollars = styled(Box)`
  width: 100px;
  height: 35px;
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  position: relative;
  &:before {
    position: absolute;
    top: 5px;
    left: 5px;
    content: "$";
  }
  &:after {
    position: absolute;
    right: 5px;
    top: 16px;
    content: "";
    border-top: 1px solid #ccc;
    width: 70px;
  }
`;
