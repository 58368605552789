import React from "react";
import { Box, Heading, Text, Link } from "@atoms";
// import styled from "@emotion/styled";
import { RailsUrl } from "@services";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

const MyActivityHeader = () => {
  return (
    <Box textAlign="center">
      <Heading className="sr-only" display="block" mb={3}>
        Shopping Trips
      </Heading>
      <Text as="p" mb={4}>
        Brands will automatically post cash back to your account when you make a purchase - but
        sometimes this doesn&apos;t happen due to{" "}
        <Link href={RailsUrl.cashbackPolicyUrl}>tracking errors.</Link> If you&apos;re missing
        funds, click &quot;All Shopping Trips&quot;, find the trip where you made a purchase and
        submit a claim - we&apos;ll make sure you get paid.
      </Text>
    </Box>
  );
};

export default MyActivityHeader;
// == Styles ===============================================================
