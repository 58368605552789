import React from "react";
import ReactPlaceholder from "react-placeholder";
import { TextBlock, RoundShape } from "react-placeholder/lib/placeholders";
import { Flex, Box } from "@atoms";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

function Loading() {
  return (
    <Flex alignItems="flex-start" justifyContent="center" p={3}>
      <Box height={[50, 75]} mt={2} width={[50, 75]}>
        <RoundShape color="#E0E0E0" />
      </Box>
      <Flex flex="1" flexDirection="column" ml={3}>
        <TextBlock color="#E0E0E0" rows={4} />
      </Flex>
    </Flex>
  );
}

export function NearbySearchResultLoading() {
  const LoadingComponent = (
    <Box>
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
    </Box>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent}
      delay={1000}
      ready={false}
    />
  );
}

// == Styles ===============================================================
