import React, { useState, useMemo, useEffect } from "react";
import currency from "currency.js";
import gql from "graphql-tag";
import { LocationContext } from "@reach/router";
import isEmpty from "lodash/isEmpty";
import { Box, Text, H3 } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { constants, getThresholdDenominations } from "@services";
import { ToggleButton } from "@molecules";
import { getPaginatedNodes } from "@utils";
import { GiftcardDenominationDeliveryMethod } from "@types";
import GiftcardBuyButton from "../linkModalGiftCardComponents/LinkModalGiftcardContainer/components/GiftcardBuyButton";
import { LinkModalReloadableGiftcardContainerFragment } from "./__generated__/LinkModalReloadableGiftcardContainerFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  link: LinkModalReloadableGiftcardContainerFragment;
  locationContext: LocationContext;
  onLinkClick: () => void;
  selectedFundraisersIds: string[];
  fundraiserId?: string;
  campaignId?: string;
  queryPromotionId: string | null | undefined;
}

// == Constants ============================================================

LinkModalReloadableGiftcardContainer.defaultProps = {};
LinkModalReloadableGiftcardContainer.fragment = gql`
  fragment LinkModalReloadableGiftcardContainerFragment on Link {
    id
    isBulkShop
    reloadableGiftcardInfo {
      reloadThresholdDenominations {
        dollarsAmount
      }
    }
    giftcardDenominations {
      edges {
        cursor
        node {
          id
          default
          deliveryMethod
          value {
            centsAmount
            dollarsAmount
            formatted(round: 0)
          }
        }
      }
    }
  }
`;

// == Component ============================================================

export function LinkModalReloadableGiftcardContainer({
  link,
  onLinkClick,
  locationContext,
  campaignId,
  fundraiserId,
  selectedFundraisersIds,
  queryPromotionId,
}: IProps) {
  // const [selectedDenom, setSelectedDenom] = useState(FAKE_DENOMINATIONS[0]);
  const { isContinuity } = useStoreAppValue();
  const denominations = useMemo(() => {
    const { edges } = link.giftcardDenominations;
    const allDenominations = getPaginatedNodes(edges);
    return {
      all: allDenominations,
      [GiftcardDenominationDeliveryMethod.DIGITAL]: allDenominations.filter(
        (denom) => denom?.deliveryMethod === GiftcardDenominationDeliveryMethod.DIGITAL
      ),
      [GiftcardDenominationDeliveryMethod.PHYSICAL]: allDenominations.filter(
        (denom) => denom?.deliveryMethod === GiftcardDenominationDeliveryMethod.PHYSICAL
      ),
    };
  }, [link.giftcardDenominations]);

  const [selectedDenom, setSelectedDenom] = useState(
    () => denominations.all.find((denomination) => !!denomination?.default) || denominations.all[0]
  );

  const reloadThresholds = useMemo(() => {
    const denoms = link.reloadableGiftcardInfo.reloadThresholdDenominations.map(
      (denom) => denom.dollarsAmount
    );
    return getThresholdDenominations(selectedDenom.value.dollarsAmount, denoms);
  }, [link, selectedDenom.value.dollarsAmount]);

  const [reloadThreshold, setReloadThreshold] = useState<number | null>(reloadThresholds[0]);

  return (
    <Box p={3}>
      <Box mb={3}>
        <H3 fontSize={[2, 3]} mb={[1, 2]}>
          Choose an Amount
        </H3>
        {denominations[selectedDenom.deliveryMethod].map((denomination) => {
          if (!denomination) return null;
          return (
            <ToggleButton
              disabled={denomination.maximumCards === 0}
              isActive={denomination === selectedDenom}
              key={denomination.id}
              mb={3}
              mr={3}
              text={denomination.value.formatted}
              onClick={() => setSelectedDenom(denomination)}
            />
          );
        })}
      </Box>
      <Box>
        <H3 fontSize={[2, 3]} mb={[1, 2]}>
          Reload When Balance Drops Below
        </H3>
        {reloadThresholds.map((thresholdDollars) => {
          return (
            <ToggleButton
              isActive={thresholdDollars === reloadThreshold}
              key={String(thresholdDollars)}
              mb={2}
              mr={2}
              text={currency(thresholdDollars).format()}
              onClick={() => setReloadThreshold(thresholdDollars)}
            />
          );
        })}
        {selectedDenom && (
          <Box>
            <Text>
              Reload to {selectedDenom.value.formatted} when the balance drops below{" "}
              {currency(reloadThreshold).format()}
            </Text>
          </Box>
        )}
      </Box>
      {/* ONLY FOR LINK MODAL RELOADABLE */}
      <GiftcardBuyButton
        campaignId={campaignId}
        createIntentInput={{
          linkId: link.id,
          fundraiserId,
          amountCents: selectedDenom.value.centsAmount,
          isGift: false,
          isPhysical: false,
          quantity: 1,
          reloadThresholdCents: reloadThreshold * 100,
          promotionId: queryPromotionId,
          deviceAttributes: { devicePlatform: constants.PLATFORM },
        }}
        disabled={link.isBulkShop && (isEmpty(selectedFundraisersIds) || isContinuity)}
        link={link}
        locationContext={locationContext}
        onLinkClick={onLinkClick}
      />
    </Box>
  );
}

// == Styles ===============================================================
