import React, { ErrorInfo } from "react";
import { ErrorService } from "@services";

// == Types ================================================================

interface IProps {
  children: React.ReactNode;
  FallbackComponent?: React.ComponentType<IState>;
}

interface IState {
  error?: Error | null;
  errorInfo?: ErrorInfo | null;
}

// == Constants ============================================================

// == Functions ============================================================

const formatComponentStack = (string: string) => {
  const lines = string.split(/\s*\n\s*/g);
  let formattedStack = "";
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) formattedStack += `${formattedStack.length ? "\n" : ""}${lines[line]}`;
  }
  return formattedStack;
};

// == Component ============================================================

export class ErrorBoundary extends React.Component<IProps, IState> {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (errorInfo && errorInfo.componentStack) {
      errorInfo.componentStack = formatComponentStack(errorInfo.componentStack);
    }
    ErrorService.error(error, "error-boundary", errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    const { error } = this.state;
    const { children, FallbackComponent } = this.props;

    if (error) {
      if (FallbackComponent) {
        return React.createElement<IState>(FallbackComponent, this.state);
      }

      return null;
    }
    return children;
  }
}

// == Styles ===============================================================
