import React from "react";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import styled from "@emotion/styled";
import { renderIf } from "@services";
import { ChevronDown, Text, CheckIcon, Box, Flex, Button, IBoxProps, ITextProps } from "@atoms";
import "./DropDown.css";

// == Types ================================================================

interface IItem {
  label: string;
  slug: string;
}

interface IProps {
  label: string;
  onSelect: (slug: string) => void;
  items: IItem[];
  selected?: string;
  buttonVariant?: string;
  customButton?: boolean;
  customButtonProps?: IBoxProps;
  labelProps?: ITextProps;
  chevronColor?: string;
}

// == Constants ============================================================

DropDown.defaultProps = {
  customButtonProps: {},
  labelProps: {},
  chevronColor: "black",
};

// == Component ============================================================

export default function DropDown({
  label,
  items,
  onSelect,
  selected,
  buttonVariant,
  customButton,
  customButtonProps,
  labelProps,
  chevronColor,
}: IProps) {
  return (
    <Menu>
      <EMenuButton>
        {customButton ? (
          <Button as="div" variant={buttonVariant} {...customButtonProps}>
            <Text mr={2} {...labelProps}>
              {label}
            </Text>
            <ChevronDown containerProps={{ style: { display: "inline", color: chevronColor } }} />
          </Button>
        ) : (
          <>
            <Text mr={2} {...labelProps}>
              {label}
            </Text>
            <ChevronDown containerProps={{ style: { display: "inline", color: chevronColor } }} />
          </>
        )}
      </EMenuButton>
      <EMenuList className="slide-down absolute">
        {items.map((item) => {
          return (
            <MenuItem key={`${item.label}-${item.slug}`} onSelect={() => onSelect(item.slug)}>
              <Flex alignItems="center">
                <Box flex={1}>{item.label}</Box>
                {renderIf(selected === item.slug)(
                  <Text color="primary" ml={2}>
                    <CheckIcon />
                  </Text>
                )}
              </Flex>
            </MenuItem>
          );
        })}
      </EMenuList>
    </Menu>
  );
}

// == Styles ===============================================================

const EMenuButton = styled(MenuButton)`
  background: none;
  border: 0;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  cursor: pointer;
  padding: 0;
`;

const EMenuList = styled(MenuList)`
  border: 0;
  right: 0;
  left: auto;
  padding: ${({ theme }) => theme.space[3]} 0;
  box-shadow: ${({ theme }) => theme.shadows.lightShadow};
  position: relative;
  z-index: 1000;
  > [data-reach-menu-item] {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.default};
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
    &:hover {
      background: none;
    }
    &[data-selected] {
      background: none;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;
