import React, { useEffect, useState, useMemo } from "react";
import gql from "graphql-tag";
import queryString from "query-string";
import { useCookies } from "react-cookie";
import { LocationContext } from "@reach/router";
import isEmpty from "lodash/isEmpty";
import styled from "@emotion/styled";
import { FadeIn, getPaginatedNodes } from "@utils";
import { useToggle, useStoreAppValue } from "@hooks";
import { Box, H3, Text, TouchableOpacity } from "@atoms";
import { renderIf, constants } from "@services";
import { GiftcardDenominationDeliveryMethod, LinkType } from "@types";
import { LinkModalTerms } from "../LinkModalTerms";
import { LinkModalPromo } from "../LinkModalPromo";
import { LinkModalHero } from "../LinkModalHero";
import { LinkModalPostShopQuery } from "../LinkModalPostShop";
import { LinkModalFundraiserListQuery } from "../LinkModalFundraiserList/LinkModalFundraiserListQuery";
import { InStoreModalQuery } from "../../inStoreComponents";
import { LinkModalGiftcardContainer } from "../linkModalGiftCardComponents";
import { LinkModalReloadableGiftcardContainer } from "../LinkModalReloadableGiftcardContainer";
import { LinkModalBorder } from "../LinkModalBorder";
import { LinkModalBulkShop } from "../LinkModalBulkShop";
import { LinkModalBuyButton } from "../LinkModalBuyButton";
import { LinkModalRelatedLinks } from "../LinkModalRelatedLinks";
import { LinkModalWaysToEarn, LinkModalHtmlSection } from "../LinkModalWaysToEarn";
import { LinkModalPromotionItem } from "../LinkModalPromotionItem";
import { LinkModalPromotions } from "../LinkModalPromotions/LinkModalPromotions";
import { LinkModalFragment } from "./__generated__/LinkModalFragment";
import { LinkModalCampaignFragment } from "./__generated__/LinkModalCampaignFragment";

// == Types ================================================================

interface IProps {
  link: LinkModalFragment;
  locationContext: LocationContext;
  campaign?: LinkModalCampaignFragment | null;
  campaignId?: string;
  locationId?: string;
}

// == Constants ============================================================

LinkModal.fragments = {
  link: gql`
    fragment LinkModalFragment on Link {
      id
      isBulkShop
      type
      buttonCTA
      description
      defaultBrand {
        id
      }
      previewInfo {
        promotionsCount
      }
      promotionInfo {
        promotion(id: $promotionId) @include(if: $hasPromotionId) {
          id
          ...LinkModalPromotionItemFragment
        }
        promotions(first: 2) @skip(if: $hasPromotionId) {
          edges {
            cursor
            node {
              id
              ...LinkModalPromotionItemFragment
            }
          }
        }
      }
      ...LinkModalWaysToEarnFragment
      ...LinkModalHeroFragment
      ...LinkModalTermsFragment
      ...LinkModalPromoFragment
      ...LinkModalPostShopQueryFragment
      ...LinkModalGiftcardContainerFragment
      ...LinkModalReloadableGiftcardContainerFragment
      ...LinkModalRelatedLinksFragment
    }
    ${LinkModalHero.fragment}
    ${LinkModalTerms.fragments.fields}
    ${LinkModalPromo.fragments.fields}
    ${LinkModalPostShopQuery.fragment}
    ${LinkModalGiftcardContainer.fragment}
    ${LinkModalPromotionItem.fragment}
    ${LinkModalReloadableGiftcardContainer.fragment}
    ${LinkModalWaysToEarn.fragment}
    ${LinkModalRelatedLinks.fragment}
  `,
  campaign: gql`
    fragment LinkModalCampaignFragment on Campaign {
      id
      viewerFundraiser @include(if: $hasUser) {
        id
        userId
      }
    }
  `,
};

// == Component ============================================================

export default function LinkModal({
  locationId,
  locationContext,
  campaign,
  campaignId,
  link,
}: IProps) {
  const {
    currentUser,
    featureFlags: { newCollection },
    isMember,
    isContinuity,
  } = useStoreAppValue();

  const [fundraiserId, setFundraiserId] = useState(campaign?.viewerFundraiser?.id);
  const [isFundraiserSelectorOpen, setIsFundraiserSelectorOpen] = useState(false);
  const [selectedFundraisers, setSelectedFundraisers] = useState([]);
  const [showPostShopModal, togglePostShopModal] = useToggle(false);
  const { location } = locationContext;
  const [, setCookie] = useCookies([]);
  const [showPromos, setShowPromos] = useState(false);
  const promotions = useMemo(() => {
    return link?.promotionInfo?.promotion
      ? [link?.promotionInfo?.promotion]
      : getPaginatedNodes(link?.promotionInfo?.promotions?.edges);
  }, [link]);
  const queryParams = queryString.parse(location.search);
  const queryPromotionId = queryParams?.link_promotion_id;

  const { defaultBrand, isBulkShop, type: linkType } = link;
  const selectedFundraisersIds = selectedFundraisers.map((fundraiser) => fundraiser.id);

  // stores most current selectedFundraisers in localStorage
  window.localStorage.setItem("selectedFundraisers", JSON.stringify(selectedFundraisersIds));

  const { brandId } = locationContext.location.state;

  const showBuyButton = ![
    LinkType.GIFTCARDOFFER,
    LinkType.AUTORELOADGIFTCARD,
    LinkType.VOUCHER,
  ].includes(link.type);

  useEffect(() => {
    setCookie("brand_id", brandId || defaultBrand.id, { path: "/" });
  }, [brandId, defaultBrand.id, setCookie]);

  if (newCollection && linkType === LinkType.EMPYR) {
    return <InStoreModalQuery campaignId={campaignId} linkId={link.id} locationId={locationId} />;
  }

  const showTeamSelectorScreen = isFundraiserSelectorOpen && isBulkShop && !!currentUser;

  const onViewAll = () => {
    setShowPromos(true);
  };

  const denominations = useMemo(() => {
    const { edges } = link.giftcardDenominations;
    const allDenominations = getPaginatedNodes(edges);
    return {
      all: allDenominations,
      [GiftcardDenominationDeliveryMethod.DIGITAL]: allDenominations.filter(
        (denom) => denom?.deliveryMethod === GiftcardDenominationDeliveryMethod.DIGITAL
      ),
      [GiftcardDenominationDeliveryMethod.PHYSICAL]: allDenominations.filter(
        (denom) => denom?.deliveryMethod === GiftcardDenominationDeliveryMethod.PHYSICAL
      ),
    };
  }, [link.giftcardDenominations]);

  const [selectedDenom, setSelectedDenom] = useState(
    () => denominations.all.find((denomination) => !!denomination?.default) || denominations.all[0]
  );

  if (showPromos) {
    return (
      <LinkModalPromotions
        createIntentInput={{
          brandId,
          linkId: link.id,
          fundraiserId,
          isBulkShop,
          deviceAttributes: { devicePlatform: constants.PLATFORM },
        }}
        fundraiserId={fundraiserId}
        linkId={link.id}
        linkType={link.type}
        locationContext={locationContext}
        onBackClick={() => setShowPromos(false)}
      />
    );
  }
  // link modal
  if (!showPostShopModal) {
    return (
      <FadeIn>
        {isBulkShop && !!currentUser && !isContinuity && (
          <ELinkModalTeamSelectorContainer display={showTeamSelectorScreen ? "block" : "none"}>
            <LinkModalBulkShop
              campaignId={campaignId}
              isFundraiserSelectorOpen={isFundraiserSelectorOpen}
              linkId={link.id}
              selectedFundraisers={selectedFundraisers}
              setIsFundraiserSelectorOpen={setIsFundraiserSelectorOpen}
              setSelectedFundraisers={setSelectedFundraisers}
            />
          </ELinkModalTeamSelectorContainer>
        )}
        <ELinkModalContainer display={!showTeamSelectorScreen ? "block" : "none"}>
          {/* share logic here 1. */}
          <LinkModalHero
            campaignId={campaignId}
            fundraiserId={fundraiserId}
            link={link}
            locationContext={locationContext}
            onLinkClick={togglePostShopModal}
            selectedDenom={selectedDenom}
            setSelectedDenom={setSelectedDenom}
          >
            {renderIf(!!campaignId && !isMember)(
              <LinkModalFundraiserListQuery onFundraiserSelect={setFundraiserId} />
            )}
          </LinkModalHero>
          <LinkModalBorder />

          {link.type === LinkType.VOUCHER && (
            <>
              <LinkModalHtmlSection html={link.description} title="What you get:" />
              <LinkModalBorder />
            </>
          )}

          <LinkModalWaysToEarn link={link} />
          <LinkModalBorder />

          {promotions.length > 0 && (
            <>
              <Box m={3}>
                <H3>Deals</H3>
                <Text display="block" mt={1} textStyle="legal">
                  Restrictions and exclusions may apply. See {link.name} website for full details.
                </Text>
              </Box>
              {promotions.map((promotion) => {
                return (
                  <Box key={promotion.id} my={3}>
                    <LinkModalPromotionItem
                      createIntentInput={{
                        brandId,
                        linkId: link.id,
                        fundraiserId,
                        isBulkShop,
                        deviceAttributes: { devicePlatform: constants.PLATFORM },
                      }}
                      fundraiserId={fundraiserId}
                      linkId={link.id}
                      linkType={link.type}
                      locationContext={locationContext}
                      promotion={promotion}
                      showShopButton={
                        ![
                          LinkType.GIFTCARDOFFER,
                          LinkType.VOUCHER,
                          LinkType.AUTORELOADGIFTCARD,
                        ].includes(link.type)
                      }
                    />
                  </Box>
                );
              })}
              {link?.previewInfo?.promotionsCount > 2 && (
                <Box mb={3} textAlign="center">
                  <TouchableOpacity onTouch={onViewAll}>
                    <Text color="primary">View All</Text>
                  </TouchableOpacity>
                </Box>
              )}
              <LinkModalBorder />
            </>
          )}

          {isBulkShop && !!currentUser && !isContinuity && (
            <LinkModalBulkShop
              campaignId={campaignId}
              isFundraiserSelectorOpen={isFundraiserSelectorOpen}
              linkId={link.id}
              selectedFundraisers={selectedFundraisers}
              setIsFundraiserSelectorOpen={setIsFundraiserSelectorOpen}
              setSelectedFundraisers={setSelectedFundraisers}
            />
          )}

          {renderIf(link.type === LinkType.AUTORELOADGIFTCARD)(
            <>
              <LinkModalReloadableGiftcardContainer
                campaignId={campaignId}
                fundraiserId={fundraiserId}
                link={link}
                locationContext={locationContext}
                queryPromotionId={queryPromotionId}
                selectedFundraisersIds={selectedFundraisersIds}
                onLinkClick={togglePostShopModal}
              />
              <LinkModalBorder />
            </>
          )}
          {/* share logic here 2.2 */}
          {renderIf([LinkType.GIFTCARDOFFER, LinkType.VOUCHER].includes(link.type))(
            <>
              <LinkModalGiftcardContainer
                campaignId={campaignId}
                fundraiserId={fundraiserId}
                isBulkShop={isBulkShop}
                link={link}
                locationContext={locationContext}
                queryPromotionId={queryPromotionId}
                selectedFundraisersIds={selectedFundraisersIds}
                onLinkClick={togglePostShopModal}
                denominations={denominations}
                selectedDenom={selectedDenom}
                setSelectedDenom={setSelectedDenom}
              />
              <LinkModalBorder />
            </>
          )}

          {[LinkType.BRAND, LinkType.TRAVELSHOP].includes(link.type) && (
            <LinkModalRelatedLinks link={link} p={3} />
          )}

          <LinkModalTerms link={link} p={3} />

          {showBuyButton && (
            // REALISTICALLY THIS IS JUST THE SHOP NOW BUTTON
            // please see: LinkModalReloadableGiftcardContainer
            // LinkModalGiftcardContainer for other buttons
            <LinkModalBuyButton
              campaignId={campaignId}
              createIntentInput={{
                brandId,
                linkId: link.id,
                fundraiserId,
                isBulkShop,
                promotionId: queryPromotionId,
                deviceAttributes: { devicePlatform: constants.PLATFORM },
              }}
              disabled={link.isBulkShop && (isContinuity || isEmpty(selectedFundraisersIds))}
              fundraiserId={fundraiserId}
              link={link}
              locationContext={locationContext}
              onLinkClick={togglePostShopModal}
            />
          )}
        </ELinkModalContainer>
      </FadeIn>
    );
  }

  // post shop modal
  return <LinkModalPostShopQuery link={link} />;
}

// == Styles ===============================================================

export const ELinkModalContainer = styled(Box)`
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 90px;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 80vh;
  }
`;

export const ELinkModalTeamSelectorContainer = styled(Box)`
  height: 100vh;
  overflow-y: scroll;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 80vh;
  }
`;
