import React from "react";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Box, Text, Card, TouchableOpacity } from "@atoms";
import { OnboardingAnswerButtonFragment } from "./__generated__/OnboardingAnswerButtonFragment";

// == Types ================================================================

interface IProps {
  answer: OnboardingAnswerButtonFragment;
  isSelected: boolean;
  onPress: (id: string) => void;
}

// == Constants ============================================================

OnboardingAnswerButton.fragment = gql`
  fragment OnboardingAnswerButtonFragment on UserOnboardingQuestionAnswer {
    id
    text
  }
`;

// == Component ============================================================

export function OnboardingAnswerButton({ answer, isSelected, onPress }: IProps) {
  return (
    <Box width="100%">
      <TouchableOpacity
        containerProps={{ style: { width: "100%" } }}
        onTouch={() => onPress(answer.id)}
      >
        <ESelectCard
          borderColor={isSelected ? "primary" : "gray"}
          boxShadow={isSelected ? "lightShadow" : "regularShadow"}
          mx={2}
          my="0.5rem"
          textAlign="center"
        >
          <Text color={isSelected ? "primary" : "em"} fontSize="1">
            {answer.text}
          </Text>
        </ESelectCard>
      </TouchableOpacity>
    </Box>
  );
}

// == Styles ===============================================================

// TODO: add emotion
// TODO: add transition property to card for border change
const ESelectCard = styled(Card)`
  height: 90px;
  transition: 200ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;
