import React from "react";
import * as StyledSystem from "styled-system";
import styled from "@emotion/styled";
import { themed } from "@styles";
import { Box, IBoxProps } from "../Box";

// == Types ================================================================

interface ICardKnownProps extends IBoxProps, StyledSystem.HeightProps {
  variant?: StyledSystem.ResponsiveValue<string>;
}

export interface ICardProps
  extends ICardKnownProps,
    Omit<React.HTMLProps<HTMLDivElement>, keyof ICardKnownProps> {}

// == Constants ============================================================

const DEFAULT_PROPS = {
  m: 0,
  borderRadius: 4,
  p: [3, 4],
  border: `card`,
};

// == Component ============================================================

export const Card = styled(Box)(
  {
    maxWidth: "100%",
    height: "auto",
  },
  StyledSystem.height,
  StyledSystem.variant({ key: "cards" }),
  themed("Card")
) as React.FunctionComponent<ICardProps>;

Card.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
