import React from "react";
import gql from "graphql-tag";
import { Box, Flex, Text } from "@atoms";
import { getPaginatedNodes } from "@utils";
import { addsDirectFundraiserGroup } from "@services";
import { FundsPageEarningsFundraisersList } from "../FundsPageEarningsFundraisersList";
import { FundsPageEarningsGroupsListAnonymousFragment } from "./__generated__/FundsPageEarningsGroupsListAnonymousFragment";
import { useSortedPlayers } from "../useSortedPlayers";

// == Types ================================================================
interface IProps {
  campaign: FundsPageEarningsGroupsListAnonymousFragment;
  onNoGroupFetchMore: () => void;
  onGroupFetchMore: (groupId: string, endCursor: string) => void;
}

// == Constants ============================================================

FundsPageEarningsGroupsListAnonymous.fragments = gql`
  fragment FundsPageEarningsGroupsListAnonymousFragment on Campaign {
    id
    name
    privacyLevel
    isViewerMember @include(if: $hasCurrentUser)
    isViewerOwner @include(if: $hasCurrentUser)
    viewerFundraiser @include(if: $hasCurrentUser) {
      id
      userId
      name
      group {
        id
        name
      }
    }
    directAmountRaised {
      dollarsAmount
      formatted
    }
    group(id: $groupId) @include(if: $includeGroup) {
      id
      name
      activeFundraisersCount
      paginatedFundraisers(
        first: $paginationLimitGroup
        after: $groupAfter
        filterOnlyActive: $filterOnlyActive
      ) {
        edges {
          cursor
          node {
            id
            userId
            name
            firstName
            lastName
            group {
              id
              name
            }
            avatarImage
            isOwner
            joinedAt
            deactivatedAt
            amountRaised {
              dollarsAmount
              formatted
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
    filteredGroups {
      id
      name
      isDraft
      deletedAt
      activeFundraisersCount
      amountRaised {
        dollarsAmount
        formatted
      }
      paginatedFundraisers(
        first: $paginationLimitGroup
        after: $groupAfter
        filterOnlyActive: $filterOnlyActive
      ) {
        edges {
          cursor
          node {
            id
            userId
            ...FundsPageEarningsFundraisersListFundraisersFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    noGroupFundraisers {
      id
      name
      activeFundraisersCount
      amountRaised {
        dollarsAmount
        formatted
      }
      paginatedFundraisers(
        first: $paginationLimitNoGroup
        after: $noGroupAfter
        filterOnlyActive: $filterOnlyActive
      ) {
        edges {
          cursor
          node {
            id
            userId
            ...FundsPageEarningsFundraisersListFundraisersFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    ...FundsPageEarningsFundraisersListViewerFundraiserFragment
    ...addsDirectFundraiserGroupFragment
  }
  ${FundsPageEarningsFundraisersList.fragments.viewerFundraiser}
  ${FundsPageEarningsFundraisersList.fragments.fundraisers}
  ${addsDirectFundraiserGroup.fragment}
`;

// == Component ============================================================

export default function FundsPageEarningsGroupsListAnonymous({
  campaign,
  onGroupFetchMore,
  onNoGroupFetchMore,
}: IProps) {
  const { filteredGroups: groups, viewerFundraiser, noGroupFundraisers, privacyLevel } = campaign;
  const directFundraiserGroup = addsDirectFundraiserGroup(campaign);

  const sortedPlayers = useSortedPlayers(groups);
  const combinedGroups = [...sortedPlayers, noGroupFundraisers, directFundraiserGroup];

  return (
    <Box width={1}>
      {combinedGroups.map((group) => {
        const {
          id: groupId,
          name,
          amountRaised: { formatted },
          paginatedFundraisers: { edges },
          deletedAt,
        } = group;

        const fundraisers = getPaginatedNodes(edges);

        const isGroupDirect = group?.isDirect;
        const isViewerGroup = viewerFundraiser?.group?.id === groupId;
        const isViewerGroupNoGroup = !viewerFundraiser?.group && groupId === "NO_GROUP";
        const isUserGroup = !isGroupDirect && (isViewerGroup || isViewerGroupNoGroup);

        return (
          <Box bg="lightBg" key={`${groupId} ${name}`}>
            <Flex alignItems="row" justifyContent="space-between" padding={3}>
              <Box>
                <Text fontSize={[1, 1, 2]}>
                  {name} {deletedAt ? "(DELETED)" : ""}
                </Text>
              </Box>
              <Box>
                <Text fontSize={[1, 1, 2]}>{formatted || "$0.00"}</Text>
              </Box>
            </Flex>
            {isUserGroup && !!viewerFundraiser && (
              <FundsPageEarningsFundraisersList
                fundraisers={fundraisers}
                privacyLevel={privacyLevel}
                viewerFundraiser={viewerFundraiser}
                group={group}
                onGroupFetchMore={onGroupFetchMore}
                onNoGroupFetchMore={onNoGroupFetchMore}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
}

// == Styles ===============================================================
