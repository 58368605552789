import React from "react";
import gql from "graphql-tag";
import { BrandModalLinkListItem } from "../BrandModalLinkListItem";
import { BrandModalGiftcardLinkFragment } from "./__generated__/BrandModalGiftcardLinkFragment";
// import styled from "@emotion/styled";

// == Types ================================================================
type TLink = BrandModalGiftcardLinkFragment;

interface IProps {
  link: TLink;
  onClick: (linkId: string, locationId?: string) => void;
  isFirst?: boolean;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  isFirst: false,
};

const FRAGMENTS = {
  fields: gql`
    fragment BrandModalGiftcardLinkFragment on Link {
      id
      name
      giveSentence
      giftcardType
      merchant {
        id
        name
      }
    }
  `,
};

// == Component ============================================================

const BrandModalGiftcardLink = ({ link, onClick, isFirst }: IProps) => {
  const giftcardLinkDescription = () => {
    const { giftcardType } = link;
    switch (giftcardType) {
      case "Voucher":
        return "Get a one-time use voucher to instantly redeem for a product or service. See terms and conditions for details.";
      case "":
        return "Receive digital cards instantly to redeem online or in-store.";
      default:
        return "Receive digital gift cards instantly to spend online or in-store.";
    }
  };

  return (
    <BrandModalLinkListItem
      description={giftcardLinkDescription()}
      giveSentence={link.giveSentence}
      leftImage="https://flipgive.twic.pics/images/site/shop/brand-modal-giftcard-v2.png"
      linkId={link.id}
      showBorder={!isFirst}
      title={link.name}
      onClick={onClick}
    />
  );
};

BrandModalGiftcardLink.fragments = FRAGMENTS;
BrandModalGiftcardLink.defaultProps = DEFAULT_PROPS;
export default BrandModalGiftcardLink;

// == Styles ===============================================================
