import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { useLazyQuery } from "react-apollo";
import { Formik, Form } from "formik";
import { object, string } from "yup";
import { Text, Box, Card, Flex, H1, Button, Image, Link, TextField } from "@components";
import { theme } from "@styles";
import { RailsUrl } from "@services";
import {
  CampaignJoinCodeQueryVariables,
  CampaignJoinCodeQuery,
} from "./__generated__/CampaignJoinCodeQuery";

// == Types ================================================================

interface IProps {
  personalUserUrl: string;
}

// == Constants ============================================================

const VALIDATION_SCHEMA = object().shape({
  joinCode: string()
    .min(6, "Invalid Team Code.")
    .max(6, "Invalid Team Code.")
    .required("Invalid Team Code."),
});

const CAMPAIGN_QUERY = gql`
  query CampaignJoinCodeQuery($joinCode: String!) {
    CampaignJoinCodeSearch(joinCode: $joinCode) {
      id
      name
      joinedFundraisersCount
      photoImage
      goal {
        dollarsAmount
      }
      groups {
        id
        name
      }
    }
  }
`;

// == Component ============================================================

export const SwitchTeamIntro = ({ personalUserUrl }: IProps) => {
  const {
    borders: { silver },
    colors: { white, black },
    buttons: { primary, disabledSolid },
  } = theme;
  const [stateFormProps, setStateFormProps] = useState(null);
  const [stateJoinCode, setJoinCode] = useState(null);
  const [getCampaign, { data, loading }] = useLazyQuery<
    CampaignJoinCodeQuery,
    CampaignJoinCodeQueryVariables
  >(CAMPAIGN_QUERY, {
    onCompleted: (response) => {
      if (response?.CampaignJoinCodeSearch) {
        const { id } = response.CampaignJoinCodeSearch;
        if (id) {
          window.location.href = RailsUrl.joinTeamUrl(id, stateJoinCode);
        }
      } else {
        stateFormProps.setFieldError("joinCode", "Invalid Team Code.");
      }
    },
  });
  const onSubmit = (values, actions) => {
    const { joinCode } = values;
    setJoinCode(joinCode);
    getCampaign({
      variables: { joinCode: joinCode.toUpperCase() },
    });
  };

  return (
    <Box className="row justify-content-center">
      <Box className="col-sm-12 col-xl-10" textAlign="center">
        <H1 mb={3}>Welcome to FlipGive</H1>
        <Text color="black" display="block" mb={3}>
          There are 3 ways to get started. Choose an option below.
        </Text>
        <Flex
          alignItems={["center", "center", "stretch"]}
          flexDirection={["column", "column", "row"]}
        >
          <Card
            bg={white}
            border={silver}
            mx={[2, 2, 3]}
            textAlign="center"
            width={[1, 2 / 3, 1 / 3]}
          >
            <Flex flexDirection="column" height="100%">
              <Flex
                alignItems={["flex-start", "flex-start", "center"]}
                flexDirection={["row", "row", "column", "column"]}
              >
                <Box flex={["0 0 auto"]} mr={[1, 1, 0]} width={[90, 100, 170]}>
                  <Image
                    alt="intro-join"
                    margin="auto"
                    mb={3}
                    mt={0}
                    src="https://flipgive.twic.pics/images/onboarding/intro-join.png?twic=v1/resize=200"
                  />
                </Box>
                <Box textAlign={["left", "left", "center", "center"]}>
                  <Text color={black} display="block" fontWeight="bold" mb={3}>
                    Join an Existing Team
                  </Text>
                  <Text display="block" mb={[2, 3]}>
                    I have a join code from my Team Manager.
                  </Text>
                  <Box mb={[1, 2]}>
                    <Text fontSize={0}>How to join with a </Text>
                    <Link
                      fontSize={0}
                      href="https://help.flipgive.com/en/articles/2177391-joining-with-a-team-invite-code"
                      target="_blank"
                    >
                      team code.
                    </Link>
                  </Box>
                </Box>
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent="flex-end"
                marginTop="auto"
                position="relative"
                width="100%"
              >
                <Formik
                  initialValues={{ joinCode: "" }}
                  validationSchema={VALIDATION_SCHEMA}
                  onSubmit={onSubmit}
                >
                  {(formProps) => {
                    if (!stateFormProps) {
                      setStateFormProps(formProps);
                    }
                    return (
                      <Form>
                        <EBox position="relative">
                          <ETextField label="Team Invite Code" maxLength={6} name="joinCode" />
                          <Button
                            borderLeft="none"
                            borderRadius="0px 5px 5px 0px"
                            bottom={0}
                            color="white"
                            height="40px"
                            position="absolute"
                            right={0}
                            style={loading ? disabledSolid : primary}
                            type="submit"
                            width="80px"
                          >
                            {loading ? "Joining..." : "Join"}
                          </Button>
                        </EBox>
                      </Form>
                    );
                  }}
                </Formik>
              </Flex>
            </Flex>
          </Card>
          <Card
            bg={white}
            border={silver}
            mt={[3, 3, 0]}
            mx={[2, 2, 3]}
            textAlign="center"
            width={[1, 2 / 3, 1 / 3]}
          >
            <Flex flexDirection="column" height="100%">
              <Flex
                alignItems={["flex-start", "flex-start", "center"]}
                flexDirection={["row", "row", "column", "column"]}
              >
                <Box flex={["0 0 auto"]} mr={[1, 1, 0]} width={[90, 100, 170]}>
                  <Image
                    alt="intro-new"
                    margin="auto"
                    mb={3}
                    mt={0}
                    src="https://flipgive.twic.pics/images/onboarding/intro-new.png?twic=v1/resize=200"
                  />
                </Box>
                <Box textAlign={["left", "left", "center", "center"]}>
                  <Text color={black} display="block" fontWeight="bold" mb={[2, 3]}>
                    Create a New Team
                  </Text>
                  <Text display="block" mb={[2, 3]}>
                    I want to start a brand new team and invite my teammates.
                  </Text>
                </Box>
              </Flex>
              <Button
                as="a"
                href="/teams/new"
                marginTop="auto"
                style={loading ? disabledSolid : primary}
                width={1}
              >
                Create Team
              </Button>
            </Flex>
          </Card>
          <Card
            bg={white}
            border={silver}
            mt={[3, 3, 0]}
            mx={[2, 2, 3]}
            position="relative"
            textAlign="center"
            width={[1, 2 / 3, 1 / 3]}
          >
            <EPromoLabelBox
              border="1px dashed #000"
              borderRadius={10}
              className="bg-notification"
              mb={[2, 2, 0]}
              padding={["4px 8px"]}
              position={["absolute"]}
              px={[3]}
              py={[2]}
              right={[-20]}
              textAlign="center"
              top={[-8]}
            >
              <Text
                className="color-for-notification"
                display="block"
                fontSize={[12]}
                lineHeight="1.4"
              >
                New!
                <br />
                Pre-Season Account
              </Text>
            </EPromoLabelBox>
            <Flex flexDirection="column" height="100%" mt={[3, 3, 0]}>
              <Flex
                alignItems={["flex-start", "flex-start", "center"]}
                flexDirection={["row", "row", "column", "column"]}
              >
                <Box flex={["0 0 auto"]} mr={[1, 1, 0]} width={[90, 100, 170]}>
                  <Image
                    alt="intro-personal"
                    margin="auto"
                    mb={3}
                    src="https://flipgive.twic.pics/images/onboarding/intro-personal.png?twic=v1/resize=200"
                  />
                </Box>
                <Box textAlign={["left", "left", "center", "center"]}>
                  <Text color={black} display="block" fontWeight="bold" mb={[2, 3]}>
                    No Team Yet?
                  </Text>
                  <Text display="block" mb={[2, 3]}>
                    I don&apos;t have a team yet but want to start earning.
                  </Text>
                  <Text display="block" fontSize={0} mb={[2, 3]}>
                    (You can transfer your funds to a team at any time!)
                  </Text>
                </Box>
              </Flex>
              <Button
                as="a"
                href={personalUserUrl}
                marginTop="auto"
                style={loading ? disabledSolid : primary}
                width={1}
              >
                Start Earning
              </Button>
            </Flex>
          </Card>
        </Flex>
      </Box>
    </Box>
  );
};

// == Styles ===============================================================
const ETextField = styled(TextField)`
  height: 40px !important;
  margin-bottom: 0 !important;
  position: relative;
  text-transform: uppercase;
`;

const EBox = styled(Box)`
  position: relative;
  > div {
    > span.standalone-error {
      bottom: -20px;
      position: absolute;
      margin-bottom: 0;
    }
  }
`;

const EPromoLabelBox = styled(Box)`
  transform: rotate(5deg);
`;
