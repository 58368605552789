import gql from "graphql-tag";

export const CAMPAIGN_CHECK_QUERY = gql`
  query CampaignOnboardingCheckQuery {
    Viewer {
      id
      isCaptainable
      activeFundraiser {
        id
        amountRaised {
          formatted
          centsAmount
        }
      }
      activeCampaign {
        id
        fundsDistributionMethod
        filteredGroups(isDrafted: false) {
          id
        }
        joinedFundraisersCount
      }
    }
  }
`;

export const MOBILE_WIDTH = 768;
