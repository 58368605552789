import React, { useEffect, useState, useMemo } from "react";
import gql from "graphql-tag";
import { range, isEmpty } from "lodash";
import { LocationContext } from "@reach/router";
// import styled from "@emotion/styled";
import { isAfter } from "date-fns";
import { getPaginatedNodes } from "@components/common/utils";
import { Box, Text, H3, Flex } from "@atoms";
import { ToggleButton, DropDown } from "@molecules";
import { GiftcardDenominationDeliveryMethod, LinkType } from "@types";
import { constants } from "@services";
import { useToggle, useStoreAppValue } from "@hooks";
import GiftcardBuyButton from "./components/GiftcardBuyButton";
import {
  LinkModalGiftcardContainerFragment,
  LinkModalGiftcardContainerFragment_giftcardDenominations_edges_node,
} from "./__generated__/LinkModalGiftcardContainerFragment";
import { LinkModalFragment_giftcardDenominations_edges_node } from "../../LinkModal/__generated__/LinkModalFragment";
// == Types ================================================================

interface IProps {
  link: LinkModalGiftcardContainerFragment;
  locationContext: LocationContext;
  onLinkClick: () => void;
  fundraiserId?: string;
  selectedFundraisersIds: string[];
  campaignId?: string;
  isBulkShop: boolean;
  queryPromotionId: string | string[] | null | undefined;
  denominations: {
    all: LinkModalGiftcardContainerFragment_giftcardDenominations_edges_node[];
    DIGITAL: LinkModalGiftcardContainerFragment_giftcardDenominations_edges_node[];
    PHYSICAL: LinkModalGiftcardContainerFragment_giftcardDenominations_edges_node[];
  };
  selectedDenom: LinkModalFragment_giftcardDenominations_edges_node;
  setSelectedDenom: React.Dispatch<
    React.SetStateAction<LinkModalFragment_giftcardDenominations_edges_node>
  >;
}

// == Constants ============================================================

LinkModalGiftcardContainer.fragment = gql`
  fragment LinkModalGiftcardContainerFragment on Link {
    id
    isBulkShop
    type
    buttonCTA
    defaultBrand {
      id
      name
    }
    giftcardDenominations {
      edges {
        cursor
        node {
          id
          default
          deliveryMethod
          deliveryEstimate
          freeShipping
          minimumCards
          maximumCards
          value {
            centsAmount
            dollarsAmount
            formatted(round: 0)
          }
        }
      }
    }
  }
`;

// == Component ============================================================

export default function LinkModalGiftcardContainer({
  link,
  locationContext,
  onLinkClick,
  campaignId,
  fundraiserId,
  selectedFundraisersIds,
  isBulkShop,
  queryPromotionId,
  denominations,
  selectedDenom,
  setSelectedDenom,
}: IProps) {
  // const denominations = useMemo( () => {
  //   const {edges} = link.giftcardDenominations;
  //   const allDenominations = getPaginatedNodes( edges );
  //   return {
  //     all: allDenominations,
  //     [GiftcardDenominationDeliveryMethod.DIGITAL]: allDenominations.filter(
  //       ( denom ) => denom?.deliveryMethod === GiftcardDenominationDeliveryMethod.DIGITAL
  //     ),
  //     [GiftcardDenominationDeliveryMethod.PHYSICAL]: allDenominations.filter(
  //       ( denom ) => denom?.deliveryMethod === GiftcardDenominationDeliveryMethod.PHYSICAL
  //     ),
  //   };
  // }, [link.giftcardDenominations] );

  const { currentUser, isContinuity } = useStoreAppValue();

  const isPhysicalAvailable = useMemo(() => {
    return !!denominations.all.find(
      (denomination) => denomination?.deliveryMethod === GiftcardDenominationDeliveryMethod.PHYSICAL
    );
  }, [denominations.all]);

  const isDigitalAvailable = useMemo(() => {
    return !!denominations.all.find(
      (denomination) => denomination?.deliveryMethod === GiftcardDenominationDeliveryMethod.DIGITAL
    );
  }, [denominations.all]);

  const [isGift, toggleIsGift] = useToggle(false);
  const [quantity, setQuantity] = useState(selectedDenom?.minimumCards);

  useEffect(() => {
    if (selectedDenom?.minimumCards) setQuantity(selectedDenom.minimumCards);
  }, [selectedDenom]);

  const onDeliveryTypeSelect = (type: GiftcardDenominationDeliveryMethod) => {
    setSelectedDenom((previous) => {
      const newDenomination = denominations[type].reduce((newDenom, denomination) => {
        if (!denomination || !previous) return newDenom;
        if (!newDenom) return denomination;

        const { value } = denomination;
        if (
          Math.abs(value.centsAmount - previous.value.centsAmount) <=
          Math.abs(newDenom.value.centsAmount - previous.value.centsAmount)
        ) {
          return denomination;
        }
        return newDenom;
      });
      return newDenomination || previous;
    });
  };

  if (!selectedDenom || !quantity) return null;

  const minimumCards = selectedDenom.minimumCards || 1;
  const { maximumCards } = selectedDenom;
  const isPhysical = selectedDenom.deliveryMethod === GiftcardDenominationDeliveryMethod.PHYSICAL;
  const totalGiftcardAmount = selectedDenom.value.dollarsAmount * quantity;

  const activeVariant = "fadedButton";
  const isGiftcard = link.type === LinkType.GIFTCARDOFFER;

  return (
    <Box p={3}>
      <Flex flexDirection="column">
        {/* <Box mb={3}>
          <Flex
            flexDirection={selectedDenom.deliveryMethod === "PHYSICAL" ? ["column", "row"] : "row"}
          >
            <H3 fontSize={[2, 3]} mb={[1, 2]} pr={2}>
              Delivery Type
            </H3>
            {!!selectedDenom.deliveryEstimate && (
              <Text fontSize={[0, 2]}>{selectedDenom.deliveryEstimate}</Text>
            )}
          </Flex>
          {!!isDigitalAvailable && (
            <ToggleButton
              activeVariant={activeVariant}
              borderRadius={isPhysicalAvailable ? "4px 0 0 4px" : "giftcard"}
              isActive={!isPhysical}
              text={`Digital${isGiftcard ? " Card" : ""}`}
              width={[1 / 2, "200px"]}
              onClick={() => onDeliveryTypeSelect( GiftcardDenominationDeliveryMethod.DIGITAL )}
            />
          )}
          {!!isPhysicalAvailable && (
            <ToggleButton
              activeVariant={activeVariant}
              borderRadius={isDigitalAvailable ? "0 4px 4px 0" : "giftcard"}
              disabled={!isPhysicalAvailable}
              isActive={!!isPhysical}
              text={`Physical${isGiftcard ? " Card" : ""}`}
              width={[1 / 2, "200px"]}
              onClick={() =>
                isPhysicalAvailable &&
                onDeliveryTypeSelect( GiftcardDenominationDeliveryMethod.PHYSICAL )
              }
            />
          )}
          
          {selectedDenom.deliveryMethod === "PHYSICAL" &&
            isAfter( new Date( "2019-12-26" ), new Date() ) && (
              <Text as="p" color="default" fontSize={0} lineHeight="title" mt={1}>
                Due to holiday volumes and resulting delays, delivery cannot be guaranteed on or
                before December 25.
              </Text>
            )}
        </Box> */}
        <Box>
          <H3 fontSize={[2, 3]} mb={2}>
            Is This a Gift?
          </H3>
          <ToggleButton
            activeVariant={activeVariant}
            borderRadius="4px 0 0 4px"
            isActive={!isGift}
            text="Buy for myself"
            width={[1 / 2, "200px"]}
            onClick={() => toggleIsGift(false)}
          />
          <ToggleButton
            activeVariant={activeVariant}
            borderRadius="0 4px 4px 0"
            isActive={!!isGift}
            text="Send as a gift"
            width={[1 / 2, "200px"]}
            onClick={() => toggleIsGift(true)}
          />
        </Box>
        <Box mt={3}>
          <Flex flexDirection="row">
            <H3 fontSize={[2, 3]} mb={2} pr={2}>
              Choose an amount
            </H3>

            <Text color="danger" fontSize={[0, 2]}>
              {maximumCards === 0 && "Currently out of stock"}
            </Text>
          </Flex>
          {denominations[selectedDenom.deliveryMethod].map((denomination) => {
            if (!denomination) return null;
            return (
              <ToggleButton
                activeVariant={activeVariant}
                disabled={denomination.maximumCards === 0}
                isActive={denomination === selectedDenom}
                key={denomination.id}
                mb={3}
                mr={3}
                text={denomination.value.formatted}
                onClick={() => setSelectedDenom(denomination)}
              />
            );
          })}
          <Flex mb="16px">
            <Text color="" fontSize={[0, 1]}>
              {selectedDenom?.deliveryEstimate}
            </Text>
          </Flex>
        </Box>
        {maximumCards > 0 && (
          <Box>
            <Flex>
              <H3 fontSize={[2, 3]} mb={2}>
                Quantity
              </H3>
              {minimumCards !== 1 && (
                <Text fontSize={[0, 2]} pl={2}>
                  Minimum {minimumCards}
                </Text>
              )}
            </Flex>
            <DropDown
              customButton
              buttonVariant="clearGrey"
              customButtonProps={{
                style: {
                  width: "200px",
                  display: "flex",
                  justifyContent: "space-between",
                },
              }}
              items={range(minimumCards, maximumCards + 1).map((value) => ({
                label: String(value),
                slug: String(value),
              }))}
              label={String(quantity)}
              selected={String(quantity)}
              onSelect={(value) => setQuantity(parseInt(value, 10))}
            />
            {totalGiftcardAmount > constants.GIFTCARD_PURCHASE_DELAY_LIMIT && (
              <Flex>
                <Text fontSize={[0, 2]} mt={1}>
                  This amount can take up to 3 hours to be delivered.
                </Text>
              </Flex>
            )}
          </Box>
        )}
        {/* ONLY FOR BUY */}
        <GiftcardBuyButton
          campaignId={campaignId}
          createIntentInput={{
            linkId: link.id,
            fundraiserId,
            amountCents: selectedDenom.value.centsAmount,
            isGift,
            isPhysical,
            quantity,
            promotionId: queryPromotionId,
            deviceAttributes: { devicePlatform: constants.PLATFORM },
          }}
          disabled={
            maximumCards === 0 ||
            ((isEmpty(selectedFundraisersIds) || isContinuity) && link.isBulkShop)
          }
          link={link}
          locationContext={locationContext}
          onLinkClick={onLinkClick}
        />
      </Flex>
    </Box>
  );
}

// == Styles ===============================================================
