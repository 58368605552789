import React from "react";
import { Provider } from "mobx-react";
import { ApolloProvider } from "react-apollo";
import ActivityData from "./components/ActivityData";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";
import { FlipgiveStore, TimelineStore } from "@stores";
import { useFullStory } from "@hooks";

const ActivityApp = (props) => {
  Settings.setDefaultsFromProps(props);
  useFullStory(props.currentUser);
  FlipgiveStore.setCurrentUser(props.currentUser);
  FlipgiveStore.setCampaignId(props.campaignId);

  TimelineStore.publicView = props.publicView;
  TimelineStore.singleActivity = props.singleActivity || false;
  TimelineStore.isPartner = props.isPartner;
  TimelineStore.activityId = props.activityId;
  TimelineStore.feed = props.feed.split(":")[0].toUpperCase();
  TimelineStore.hideReactions = props.hideReactions || false;

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient(Settings.apiUrl)}>
        <Provider FlipgiveStore={FlipgiveStore} TimelineStore={TimelineStore}>
          <ActivityData activityId={TimelineStore.activityId} feed={TimelineStore.feed} />
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default ActivityApp;
