/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { shape, func, string } from "prop-types";

export default function TeamsnapTeamListItem({ team, onSelectTeam, selectedTeam }) {
  const onClick = (e) => {
    onSelectTeam(team);
    e.preventDefault();
  };

  return (
    <a className="default-color-link" href="#" onClick={onClick}>
      <div className="media teamsnap-team-listitem align-items-center mb-3">
        <div className="teamsnap-team-listitem__image-container mr-3">
          <img
            alt="Teamsnap"
            src="https://flipgive.twic.pics/images/uploads/temp/teamsnap/teamsnap-logo.png?twic=v1/resize=20"
          />
        </div>
        <div className="media-body">
          <span>{team.name}</span>
        </div>
        <div className={`${!!selectedTeam && selectedTeam.id === team.id ? null : "opacity-0"}`}>
          <i className="material-icons color-link">check</i>
        </div>
      </div>
    </a>
  );
}

TeamsnapTeamListItem.propTypes = {
  team: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  selectedTeam: shape({
    id: string.isRequired,
    name: string.isRequired,
  }),
  onSelectTeam: func.isRequired,
};

TeamsnapTeamListItem.defaultProps = {
  selectedTeam: null,
};
