import React from "react";
import { FontAwesome } from "../../FontAwesome";
import { IFlexProps } from "../../Flex";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  containerProps?: IFlexProps;
}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export function TimesIcon({ containerProps, ...rest }: IProps) {
  return <FontAwesome containerProps={containerProps} data-testid="times" icon="times" {...rest} />;
}

TimesIcon.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
