import React from "react";
import { Router } from "@reach/router";
import BeneficiaryIntro from "../components/BeneficiaryIntro/BeneficiaryIntro";
import BeneficiarySuccess from "../components/BeneficiarySuccess/BeneficiarySuccess";
import BeneficiaryFormQuery from "../components/BeneficiaryForm/BeneficiaryFormQuery";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {}

// == Constants ============================================================

BeneficiaryRouter.defaultProps = {};

// == Component ============================================================

export default function BeneficiaryRouter(props: IProps) {
  return (
    <Router>
      <BeneficiaryIntro path="intro" />
      <BeneficiaryFormQuery path="new" />
      <BeneficiarySuccess path="success" />
    </Router>
  );
}

// == Styles ===============================================================
