import React from "react";
import { shape, string, bool } from "prop-types";
import { AvatarImage } from "@components";

const SwitchTeamPersonalItem = ({
  firstName,
  lastName,
  personalAccount,
  photoImage,
  personalUserUrl,
  showCheckmark,
  title,
  hideHeader,
}) => (
  <div>
    {!hideHeader && <div className="switch-team__header">Personal Account</div>}
    <a href={personalUserUrl}>
      <div className={title === "Header" ? "switch-team__row-header" : "switch-team__row"}>
        <div className="switch-team__info-container">
          <AvatarImage
            alt={firstName}
            className="switch-team__image"
            name={firstName}
            src={photoImage}
          />
          <div className="switch-team__details">
            <div className="switch-team__name color-text">
              {firstName} {lastName}
            </div>
            <div className="switch-team__goal text-tiny">
              Personal Account - {personalAccount.transferrableFunds.outstandingAmount.formatted}{" "}
              earned
            </div>
          </div>
        </div>
        {showCheckmark ? <i className="material-icons d-flex align-items-center">check</i> : null}
      </div>
    </a>
  </div>
);

export default SwitchTeamPersonalItem;

SwitchTeamPersonalItem.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  personalAccount: shape({}).isRequired,
  showCheckmark: bool.isRequired,
  title: string.isRequired,
  photoImage: string,
  personalUserUrl: string.isRequired,
  hideHeader: bool,
};

SwitchTeamPersonalItem.defaultProps = {
  photoImage: null,
  hideHeader: false,
};
