import React from "react";
import gql from "graphql-tag";
import { getPaginatedNodes } from "@components/common/utils";
import { Box, Text, Flex } from "@atoms";
import { FundsPageWithdrawalsFragment } from "../FundsPageWithdrawals/__generated__/FundsPageWithdrawalsFragment";
import { FundsPageWithdrawalsListItem } from "../FundsPageWithdrawalsListItem";
import { FundsPageWithdrawalsListFragment_payments } from "./__generated__/FundsPageWithdrawalsListFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPageWithdrawalsFragment;
  withdrawalsList: FundsPageWithdrawalsListFragment_payments | null;
}

// == Constants ============================================================

FundsPageWithdrawalsList.fragments = gql`
  fragment FundsPageWithdrawalsListFragment on Campaign {
    id
    viewerFundraiser {
      id
      isCaptainable
    }
    payments @include(if: $isMember) {
      edges {
        cursor
        node {
          id
          ...FundsPageWithdrawalsListItemFragment
        }
      }
    }
  }
  ${FundsPageWithdrawalsListItem.fragments}
`;

// == Component ============================================================

export default function FundsPageWithdrawalsList({ campaign, withdrawalsList }: IProps) {
  const { viewerFundraiser } = campaign;
  return (
    <Flex bg="white" flexDirection="column">
      <Box bg="lightBg" padding={3}>
        <Text fontSize={[1, 1, 2]}>History</Text>
      </Box>
      {!!withdrawalsList?.edges &&
        getPaginatedNodes(withdrawalsList.edges).map((listItem) => {
          return listItem ? (
            <FundsPageWithdrawalsListItem
              key={listItem.id}
              listItem={listItem}
              isViewerOwner={viewerFundraiser.isCaptainable}
            />
          ) : null;
        })}
    </Flex>
  );
}
