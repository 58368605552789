// sourced from https://dev.to/michalczaplinski/super-easy-react-mount-unmount-animations-with-hooks-4foj
import React, { useState, ReactNode, useEffect } from "react";
import "./Fade.css";

// == Styles ===============================================================
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  show: boolean;
  children?: ReactNode;
  duration?: string;
}

// == Constants ============================================================

Fade.defaultProps = {
  duration: ".3s",
};

// == Component ============================================================

export function Fade({ show, children, duration }: IProps) {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    render && (
      <div
        style={{ animation: `${show ? "fadeIn" : "fadeOut"} ${duration}`, zIndex: 2 }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
}
