import React, { useState } from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { REACT_SOCKS_BREAKPOINTS } from "@styles";
import { Box, Column, Circle, H2, Flex, Row, Text } from "@atoms";
import { useWindowSize } from "@hooks";
import { OnboardingBrandItem } from "../OnboardingBrandItem/OnboardingBrandItem";

// == Types ================================================================

interface IProps {
  name: string;
  brands: $FixMe;
  activeBrands: string[];
  onBrandClick: (id: string, brand: $FixMe) => void;
  allowShowMore?: boolean;
}

// == Constants ============================================================

OnboardingBrandsGroup.defaultProps = {
  allowShowMore: false,
};

OnboardingBrandsGroup.fragments = gql`
  fragment OnboardingBrandsGroupFragment on Brand {
    id
    name
    ...OnboardingBrandItemFragment
  }
  ${OnboardingBrandItem.fragments}
`;

// == Component ============================================================

export default function OnboardingBrandsGroup({
  name,
  brands,
  activeBrands,
  onBrandClick,
  allowShowMore,
}: IProps) {
  const { width } = useWindowSize();
  const amountOfBrandsShown = width <= REACT_SOCKS_BREAKPOINTS[2].medium ? 5 : 7;
  const DEFAULT_FIRST = amountOfBrandsShown;
  const [first, setFirst] = useState(DEFAULT_FIRST);

  return (
    <Box>
      <H2 fontSize={["3", "4"]} py={3}>
        {name}
      </H2>
      <Row alignItems="flex-start">
        {brands.slice(0, first).map((brand) => {
          if (!brand) return null;
          return (
            <EColumn
              autoColumn={false}
              display="flex"
              flexShrink={0}
              key={brand.id}
              pb={3}
              px={[2, 2, 2, 1]}
              width={[1 / 3, 1 / 3, 1 / 4]}
            >
              <OnboardingBrandItem
                brand={brand}
                isSelected={activeBrands.includes(brand.id)}
                onBrandClick={onBrandClick}
              />
            </EColumn>
          );
        })}
        {allowShowMore && first < brands.length && (
          <Box
            alignSelf="flex-start"
            px={[2, 2, 2, 1]}
            width={[1 / 3, 1 / 3, 1 / 4]}
            onClick={() => setFirst((prevFirst) => prevFirst + first + 1)}
          >
            <ECircle border="primary" borderColor="link">
              <Flex justifyContent="center">
                <Text color="link" fontSize={["2", "3"]} lineHeight="1" textAlign="center">
                  More
                </Text>
              </Flex>
            </ECircle>
          </Box>
        )}
        {allowShowMore && first >= brands.length && (
          <Box alignSelf="flex-start" px={[2, 2, 2, 1]} width={[1 / 3, 1 / 3, 1 / 4]}>
            <Circle border="silver" borderColor="transparent">
              <Flex justifyContent="center">
                <Text fontSize={["2", "2"]} lineHeight="1.4" textAlign="center">
                  Plus
                  <br />
                  Hundreds
                  <br />
                  More!
                </Text>
              </Flex>
            </Circle>
          </Box>
        )}
      </Row>
    </Box>
  );
}

// == Styles ===============================================================

const EColumn = styled(Column)`
  justify-content: center;
  align-items: center;
`;

const ECircle = styled(Circle)`
  transition: transform 200ms ease-in-out;
  &:hover {
    transform: scale(1.05);
    border: ${({ theme }) => theme.borders.primaryCircle};
    cursor: pointer;
  }
`;
