// import constants from "./constants";
import { CustomCollectionsIds } from "@components/common/utils/customCollectionsIds";
import { CustomStorefrontsIds } from "@components/common/utils/customStorefrontsIds";
import constants, { BASE_APP_URL, BASE_SHOP_URL } from "./constants";

const membersUrl = (id, inviteTemplate = null) => {
  let url = `/teams/${id}/members`;
  if (inviteTemplate) {
    url += `?invite=${inviteTemplate}`;
  }
  return url;
};

const playersUrl = (id, inviteTemplate = null) => {
  let url = `/teams/${id}/players`;
  if (inviteTemplate) {
    url += `?invite=${inviteTemplate}`;
  }
  return url;
};

const setupTeamUrl = (id) => `/teams/${id}/setup`;
const campaignsUrl = (id) => `/teams/${id}`;
const signUpUrl = (encodedURI) => {
  if (!encodedURI) return `/signup`;
  return `/signup?r=${encodedURI}`;
};
const signInUrl = (encodedURI) => {
  if (!encodedURI) return `/login`;
  return `/login?r=${encodedURI}`;
};
const createTeamUrl = () => `/teams/new`;

const joinNewTeamUrl = () => `/teams/join`;
const joinTeamUrl = (id, code) => `/teams/${id}/joins/intro${code ? `?joincode=${code}` : ""}`;

const feedUrl = (id) => `/teams/${id}/activity`;

const sendThanksUrl = (id, supporter_id) =>
  `/teams/${id}/guide/supporters/${supporter_id}/new_thanks`;

const updateActivityUrl = (id, support_id) =>
  `/teams/${id}/guide/supporters/${support_id}/new_support_message`;

const paymentsUrl = (id) => `/teams/${id}/guide/payments`;

const payoutsUrl = () => `${BASE_APP_URL}/payouts`;

const newBeneficiaryUrl = (id) => `/teams/${id}/guide/beneficiary/new`;
const beneficiaryIntroUrl = (id) => `/teams/${id}/guide/beneficiary/intro`;
const beneficiaryTypeUrl = (id) => `/teams/${id}/guide/beneficiary/type`;
const beneficiaryTransferUrl = (id) => `/teams/${id}/guide/beneficiary/transfer`;
const beneficiarySuccessUrl = (id) => `/teams/${id}/guide/beneficiary/success`;
const beneficiaryChequeUrl = (id) => `/teams/${id}/guide/beneficiary/cheque`;
const occasionUrl = (id) => `/occasions/${id}`;

const storeUrl = ({ id, campaignId = null }) =>
  `${campaignId ? `/teams/${campaignId}` : ""}/stores/${id}`;
const collectionUrl = ({ id, campaignId = null, storefrontId }) => {
  const storefrontTrack = storefrontId ? `${constants.storefrontParam}=${storefrontId}&` : "";
  return `${campaignId ? `/teams/${campaignId}` : ""}/collections/${id}?${storefrontTrack}${
    constants.collectionParam
  }=${id}`;
};
const brandsUrl = ({ campaignId = null }) => `${campaignId ? `/teams/${campaignId}` : ""}/brands`;
const brandModalUrl = ({ id, locationId, collectionId, storefrontId }) => {
  const url = new URL(document.location.href);
  url.searchParams.delete("modal");
  url.searchParams.delete(constants.linkParam);
  url.searchParams.set(constants.brandParam, id);
  if (collectionId) url.searchParams.set(constants.collectionParam, collectionId);
  if (collectionId) url.searchParams.set(constants.storefrontParam, storefrontId);
  if (locationId) {
    url.searchParams.set("location_id", locationId);
    if (document.location.pathname.includes("/nearby")) {
      url.searchParams.set(constants.storefrontParam, CustomStorefrontsIds.nearby);
      url.searchParams.delete(constants.collectionParam);
    } else {
      url.searchParams.set(constants.collectionParam, CustomCollectionsIds.nearby);
    }
  }
  return url.search;
};
const linkModalUrl = ({ id }) => `?${constants.linkParam}=${id}`;
const favoritesUrl = () => `?modal=${constants.favoritesParam}`;
const searchUrl = () => `?modal=${constants.searchParam}`;

const referralsUrl = (id) => `/teams/${id}/referrals`;

const myActivityUrl = "/user/activity";
const cashbackPolicyUrl = "/pages/cashback-policy";
const termsOfUseUrl = "/pages/terms-of-use";
const privacyUrl = "/pages/privacy";
const beneficiaryGuidelinesUrl = "/pages/payment-recipient-guidelines";

const linkedCardsNewUrl = "/user/linked_cards/new";

const createShopUrl = ({ campaignId, linkId, fundraiserId }) => {
  if (!campaignId) return `/user/shop/${linkId}`;
  return `/teams/${campaignId}${fundraiserId ? `/members/${fundraiserId}` : ""}/shop/${linkId}`;
};
const createPurchaseUrl = ({ campaignId, linkId, fundraiserId }) => {
  if (!campaignId) return `/user/purchase/${linkId}`;
  return `/teams/${campaignId}${fundraiserId ? `/members/${fundraiserId}` : ""}/purchase/${linkId}`;
};

const createInStoreUrl = ({ campaignId, linkId, locationId, fundraiserId }) => {
  if (!campaignId) return `/user/in_store/${linkId}?location_id=${locationId}`;
  return `/teams/${campaignId}${
    fundraiserId !== "" ? `/members/${fundraiserId}` : ""
  }/in_store/${linkId}?location_id=${locationId}`;
};

const giftcardWalletUrl = () => {
  return `/user/purchases`;
};

const giftcardUrl = (id) => {
  return `/giftcards/${id}`;
};

const playbookUrl = "/playbook";

const newTeamsnapPasswordUrl = () => "/teamsnap/passwords/new";

const missingClaimUrl = (token) => `/intents/${token}/missing_claims/start`;

const intentUrl = ({ intentToken, linkId }) => {
  if (!linkId) return `/intents/${intentToken}`;
  return `/intents/${intentToken}?offer_id=${linkId}`;
};

const createIntentUrl = ({ params, campaignId }) =>
  `${campaignId ? `/teams/${campaignId}` : ""}/intents/new${params && `?${params}`}`;

const checkoutThanksUrl = (id) => `/checkout/thanks?t=${id}`;

const checkoutUrl = (token) => `/checkout/${token}`;

const newCheckoutUrl = (token) => `${BASE_APP_URL}/checkout/${token}`;

const onboardingQuestionUrl = (id) => `/onboarding/questions/${id}`;

const onboardingIntroUrl = "/onboarding/intro";
const onboardingSuccessUrl = "/onboarding/success";
const onboardingBrandsUrl = "/onboarding/brands";
const onboardingGoalUrl = "/onboarding/goal";

const nearbyUrl = ({ campaignId = "", brandId, brandName, allLocations = false }) => {
  const allLocationsParma = allLocations ? "&locationFilter=ALL" : "";
  if (brandId) {
    return `${
      campaignId ? `/teams/${campaignId}` : "/user"
    }/nearby?b=${brandId}&brand=${brandName}${allLocationsParma}`;
  }
  return `${campaignId ? `/teams/${campaignId}` : "/user"}/nearby${allLocationsParma}`;
};

const pendingPaymentInfoUrl =
  "http://help.flipgive.com/en/articles/501796-why-do-you-have-a-holding-period-on-funds";

const invitePlayersToTeamUrl =
  "http://help.flipgive.com/en/articles/4619639-how-do-i-invite-players-to-my-team";

const feesUrl = "http://help.flipgive.com/en/articles/3982846-fees";

const rootUrl = "/";

const eventsUrl = ({ eventId, campaignId }) => `/teams/${campaignId}/events/${eventId}`;

const personalAccountUrl = "/personal-account";

const campaignsOrPersonalUrl = (campaignId) => {
  if (!campaignId) return "";
  return campaignsUrl(campaignId);
};

const shopSubTabsUrls = {
  HOME: (id) => (id ? `${campaignsOrPersonalUrl(id)}` : "/"),
  STORE: (id) => (id ? `${campaignsOrPersonalUrl(id)}${constants.storeUrl}` : "/user/store"),
  GIFT_CARDS: (id) => `${campaignsOrPersonalUrl(id)}${constants.storeUrl}flipgive_giftcards`,
  NEARBY: (id) => (id ? `${campaignsOrPersonalUrl(id)}/nearby` : "/user/nearby"),
  TEAM_STORE: (id) => `${campaignsOrPersonalUrl(id)}/team-store`,
  DEALS: (id) => `${campaignsOrPersonalUrl(id)}/deals-store`,
  FAVORITES: (id) => `${campaignsOrPersonalUrl(id)}/?modal=${constants.favoritesParam}`,
  TEAM_BUY: (id) => "/stores/teambuy",
  ALL_BRANDS: (id) => `${campaignsOrPersonalUrl(id)}/brands`,
  CATEGORIES: (id) => (id ? `${campaignsOrPersonalUrl(id)}${constants.storeUrl}` : "/user/store"),
  HOLIDAY: () => "/stores/holiday-guide",
  TRAVEL: () => "/stores/flipgive_travel",
  BONUS_GIVES: () => `${BASE_SHOP_URL}/bonus-gives`,
};

const activatePersonalAccountUrl = "/user/personal";

const firstShopUrl = "/stores/firstshop";

const dealsUrl = (campaignId) => {
  return `${campaignsOrPersonalUrl(campaignId)}/deals`;
};

const rosterUrl = `${BASE_APP_URL}/teams/team-roster`;
const inviteUrl = `${BASE_APP_URL}/teams/invite`;

export default {
  rosterUrl,
  inviteUrl,
  firstShopUrl,
  joinNewTeamUrl,
  setupTeamUrl,
  membersUrl,
  playersUrl,
  joinTeamUrl,
  feedUrl,
  signUpUrl,
  signInUrl,
  createTeamUrl,
  sendThanksUrl,
  updateActivityUrl,
  paymentsUrl,
  newBeneficiaryUrl,
  myActivityUrl,
  createShopUrl,
  createPurchaseUrl,
  playbookUrl,
  cashbackPolicyUrl,
  createInStoreUrl,
  collectionUrl,
  occasionUrl,
  storeUrl,
  linkedCardsNewUrl,
  brandsUrl,
  brandModalUrl,
  linkModalUrl,
  favoritesUrl,
  searchUrl,
  campaignsUrl,
  newTeamsnapPasswordUrl,
  missingClaimUrl,
  rootUrl,
  giftcardWalletUrl,
  checkoutThanksUrl,
  checkoutUrl,
  newCheckoutUrl,
  intentUrl,
  onboardingIntroUrl,
  onboardingQuestionUrl,
  onboardingSuccessUrl,
  onboardingBrandsUrl,
  createIntentUrl,
  termsOfUseUrl,
  privacyUrl,
  onboardingGoalUrl,
  referralsUrl,
  nearbyUrl,
  pendingPaymentInfoUrl,
  invitePlayersToTeamUrl,
  eventsUrl,
  beneficiaryIntroUrl,
  beneficiaryTypeUrl,
  beneficiaryTransferUrl,
  beneficiarySuccessUrl,
  beneficiaryChequeUrl,
  beneficiaryGuidelinesUrl,
  personalAccountUrl,
  feesUrl,
  giftcardUrl,
  shopSubTabsUrls,
  activatePersonalAccountUrl,
  dealsUrl,
  payoutsUrl,
};
