import React from "react";
import { FontAwesome } from "../../FontAwesome";
import { IFlexProps } from "../../Flex";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  containerProps?: IFlexProps;
  // iconSize: "xs" | "sm" | "lg" | "2x" | "3x" | "5x" | "7x" | "10x";
}

// == Constants ============================================================

CircleInfo.defaultProps = {};

// == Component ============================================================

export function CircleInfo({ containerProps, ...rest }: IProps) {
  return (
    <FontAwesome
      containerProps={containerProps}
      data-testid="info-circle"
      icon="info-circle"
      {...rest}
    />
  );
}

// == Styles ===============================================================
