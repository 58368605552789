import React from "react";
import gql from "graphql-tag";
import { Flex, Text, Box, TouchableOpacity } from "@atoms";
import { ImageCircle, NoAvatar } from "@molecules";
import { useToggle } from "@hooks";
import { FundsDistributionMethod } from "@types";
import { RailsUrl } from "@services";
import { EditSubGroup } from "../Modals/EditSubGroup";
import { GroupSubHeaderCampaignFragment } from "./__generated__/GroupSubHeaderCampaignFragment";
import { GroupSubHeaderGroupFragment } from "./__generated__/GroupSubHeaderGroupFragment";
import { GroupSubHeaderNoGroupFragment } from "./__generated__/GroupSubHeaderNoGroupFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  campaign: GroupSubHeaderCampaignFragment;
  group: GroupSubHeaderGroupFragment | GroupSubHeaderNoGroupFragment;
  isNoGroupEmpty: boolean;
}

// == Constants ============================================================

GroupSubHeader.fragments = gql`
  fragment GroupSubHeaderCampaignFragment on Campaign {
    id
    name
    fundsDistributionMethod
    isViewerOwner
    goal {
      formatted
    }
  }
  fragment GroupSubHeaderGroupFragment on Group {
    id
    name
    goal {
      formatted
    }
  }
  fragment GroupSubHeaderNoGroupFragment on CampaignNoGroupFundraisers {
    id
    name
  }
`;

GroupSubHeader.defaultProps = {};

// == Functions ============================================================

const emptyGroupChecker = (group) => {
  const {
    paginatedFundraisers: { edges },
  } = group;
  return edges.filter((fundraiser) => !fundraiser.deactivatedAt).length === 0;
};

// == Component ============================================================

export default function GroupSubHeader({ campaign, group, isNoGroupEmpty }: IProps) {
  const [isEditSubGroupOpen, setIsEditSubGroupOpen] = useToggle(false);
  const id = group?.id;
  const isNoGroup = id === "NO_GROUP";
  const fundsDistributionMethod = campaign?.fundsDistributionMethod;
  const noGroupName =
    fundsDistributionMethod === FundsDistributionMethod.TEAM ? "Team Supporter" : "Unassigned";
  const name = isNoGroup ? noGroupName : group?.name;
  const goalFormatted = isNoGroup ? campaign?.goal?.formatted : group?.goal?.formatted;

  const avatarImage = group?.imageUrl;
  const circleDiameter = 40;
  const { PLAYER, TEAM } = FundsDistributionMethod;

  const initials = name
    .split(" ")
    .map((str) => str.substr(0, 1))
    .slice(0, 2);
  const firstInitial = initials[0] || "";
  const lastInitial = initials[1] || "";

  const goalMeter = () => {
    if (
      (fundsDistributionMethod === PLAYER && !isNoGroup) ||
      (fundsDistributionMethod === TEAM && isNoGroup)
    ) {
      return (
        <Text as="p" fontSize={1} paddingLeft={3} paddingRight={[1, 2, 3]}>
          {goalFormatted} goal
        </Text>
      );
    }
    return null;
  };

  if (group?.id === "__pending__") return null;

  const isGroupEmpty = emptyGroupChecker(group);

  if (isNoGroup && isNoGroupEmpty) return null;
  return (
    <>
      <Flex alignItems="center" bg="lightBg" justifyContent="space-between" padding={3}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex alignItems="center" justifyContent="flex-start">
            {avatarImage ? (
              <ImageCircle
                alt={name ?? "player"}
                faded={false}
                height={circleDiameter}
                src={avatarImage}
                width={circleDiameter}
              />
            ) : (
              <NoAvatar
                circleDiameter={circleDiameter}
                firstName={firstInitial ?? name}
                isHighlighted={false}
                lastName={lastInitial ?? ""}
              />
            )}
            <Box>
              <Text as="p" fontWeight="bold" paddingLeft={3} paddingRight={[1, 2, 3]}>
                {name}
              </Text>
              {goalMeter()}
            </Box>
          </Flex>
          {!isNoGroup && campaign.isViewerOwner && (
            <TouchableOpacity
              onTouch={() => {
                window.location.href = RailsUrl.playersUrl(campaign.id);
              }}
            >
              <Text color="primary" fontSize={1}>
                Edit
              </Text>
            </TouchableOpacity>
          )}
        </Flex>
      </Flex>
      {isGroupEmpty && (
        <Box>
          <Flex alignItems="center" justifyContent="flex-start" padding={[1, 2, 3]}>
            <Box paddingLeft={5}>
              <Text display="block" fontSize={[1, 2]}>
                This player does not have any family members yet!
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
      {isEditSubGroupOpen && (
        <EditSubGroup
          id={id}
          isEditSubGroupOpen={isEditSubGroupOpen}
          name={name}
          setIsEditSubGroupOpen={setIsEditSubGroupOpen}
        />
      )}
    </>
  );
}

// == Styles ===============================================================
