import React, { useEffect } from "react";
import { useField } from "formik";
import { Box } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  label: string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  template: {};
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  disabled: false,
};

// == Component ============================================================
GiftcardTemplateField.defaultProps = DEFAULT_PROPS;

export default function GiftcardTemplateField(props: IProps) {
  useEffect(() => {
    Materialize.updateTextFields();
  }, []);
  const { label, template } = props;
  const [field, meta] = useField(props);
  return (
    <Box
      className="checkout-gc-template-container"
      display="block"
      key={`${template.id}+${template.name}`}
    >
      <input
        checked={template.id === field.value}
        id={template.id}
        type="radio"
        {...field}
        {...props}
      />
      <label htmlFor={template.id}>
        <img alt={template.name} src={template.previewImage} />
        <p>{template.name}</p>
      </label>
    </Box>
  );
}

// == Styles ===============================================================
