import React, { Component, Fragment } from "react";
import { shape, string } from "prop-types";
import ActivityHeading from "../ActivityHeading";
import { Utils, Mentionify, constants } from "@services";
import { CustomStorefrontsIds } from "@components/common/utils/customStorefrontsIds";

const { addUTM } = Utils;

export default class ActivityLink extends Component {
  static propTypes = {
    activity: shape({
      photoImage: string,
      photoSize: string,
    }).isRequired,
  };

  renderImage = () => {
    const {
      activity: {
        data,
        details: { ctaUrl },
      },
    } = this.props;
    const { link } = data;
    if (!link.image) {
      return null;
    }

    let image = (
      <img
        alt={link.name}
        className="timeline-body__image timeline-body__image--link"
        src={link.image}
      />
    );

    if (ctaUrl) {
      image = (
        <a
          className="timeline-body__image-link"
          href={`${addUTM(ctaUrl, "feed", "timeline", "")}&${constants.storefrontParam}=${
            CustomStorefrontsIds.newsfeed
          }`}
          rel="nofollow"
        >
          {image}
          {!!link.promoLabel && (
            <div className="brand-block__promotion-banner">
              <span className="brand-block__promotion-label block-text block-text--small d-block">
                {link.promoLabel}
              </span>
            </div>
          )}
        </a>
      );
    }

    return <div className="timeline-body__link-image-container p-relative">{image}</div>;
  };

  renderDescription = () => {
    const { description } = this.props.activity.details;
    if (!description) {
      return null;
    }

    return (
      <p className="timeline-body__content headline-font">
        {description.split("\n").map((item, key) => (
          <Fragment key={key}>
            {Mentionify(item)}
            <br />
          </Fragment>
        ))}
      </p>
    );
  };

  renderGive = () => {
    const {
      activity: { data },
    } = this.props;
    const { link } = data;

    return (
      <>
        <span className="block-text brand-block__name d-block mt-2">{link.name}</span>
        <span className="brand-block__gives center">
          <h3 className="brand-block__gives-label">{link.giveSentence}</h3>
        </span>
        {!!link.promoSecondaryLabel && (
          <div className="link-promotion__promotion-details color-link link-promotion__tiny-text">
            <span dangerouslySetInnerHTML={{ __html: link.promoSecondaryLabel }} />
          </div>
        )}
      </>
    );
  };

  renderQuote = () => {
    const {
      activity: {
        details: { quote },
      },
    } = this.props;
    if (!quote) {
      return null;
    }

    return (
      <p className="timeline-body__content headline-font center mt-3">
        {quote.split("\n").map((item, key) => (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        ))}
      </p>
    );
  };

  renderCTA = () => {
    const { activity } = this.props;
    const { ctaText, ctaUrl } = activity.details;
    const hasCta = ctaText && ctaUrl;

    if (!hasCta) {
      return null;
    }

    return (
      <a
        className="timeline-body__cta btn btn-clear btn-sm"
        href={`${addUTM(ctaUrl, "feed", "timeline", "")}&${constants.storefrontParam}=${
          CustomStorefrontsIds.newsfeed
        }`}
        rel="nofollow"
      >
        {ctaText}
      </a>
    );
  };

  render() {
    const { actor, time, visibility } = this.props.activity;

    return (
      <div>
        <ActivityHeading actor={actor} time={time} visibility={visibility} />
        <div className="timeline-body">
          {this.renderCTA()}
          {this.renderDescription()}
          {this.renderImage()}
          {this.renderGive()}
        </div>
      </div>
    );
  }
}
