import React, { useState, ReactNode, MouseEventHandler } from "react";
import styled from "@emotion/styled";
import { Box } from "../Box";

// == Comments =============================================================

// == Constants ============================================================

export interface ITouchableOpacityOnTouch {
  e: MouseEventHandler;
}

interface ITouchableOpacityProps {
  onTouch?: (props: ITouchableOpacityOnTouch) => void;
  containerProps?: $FixMe;
  children: ReactNode;
}

// == Component ============================================================
export const TouchableOpacity = ({ onTouch, containerProps, ...rest }: ITouchableOpacityProps) => {
  const [touched, setTouched] = useState(false);

  const toggleTouched = (e: MouseEventHandler) => {
    if (onTouch) {
      onTouch({ e });
    }
    setTouched(!touched);
    e.stopPropagation();
  };

  // const handleMouseUp = () => {
  //   setTimeout(() => {
  //     setTouched(false);
  //   }, 150);
  // };

  return (
    <ETouchableOpacity
      touched={touched}
      type="button"
      onClick={toggleTouched}
      // onMouseDown={toggleTouched}
      // onMouseUp={handleMouseUp}
      {...containerProps}
    >
      <Box as="span" display="block" {...rest} />
    </ETouchableOpacity>
  );
};

TouchableOpacity.defaultProps = {
  containerProps: null,
  TouchableOpacity: null,
};

// == Styles ===============================================================
const ETouchableOpacity = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
  opacity: 1;
  transition: opacity 150ms ease;
  &:focus {
    background: inherit;
  }
`;

// opacity: ${(props) => (props.touched ? 0.5 : 1)};
