import React from "react";
import gql from "graphql-tag";
import { prettyUrl } from "@services";
import { BrandModalLinkListItem } from "../BrandModalLinkListItem";
import { BrandModalShopLinkFragment } from "./__generated__/BrandModalShopLinkFragment";
// import styled from "@emotion/styled";

// == Types ================================================================
type TLink = BrandModalShopLinkFragment;
interface IProps {
  link: TLink;
  isFirst?: boolean;
  onClick: (linkId: string, locationId?: string) => void;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment BrandModalShopLinkFragment on Link {
      id
      giveSentence
      merchant {
        id
        website
        name
      }
    }
  `,
};

const DEFAULT_PROPS = {
  isFirst: false,
};

// == Component ============================================================

const BrandModalShopLink = ({ link, onClick, isFirst }: IProps) => {
  const title = link.merchant?.website
    ? `Shop at ${prettyUrl(link.merchant.website).toLowerCase()}`
    : `Shop at ${link?.merchant.name}`;

  return (
    <BrandModalLinkListItem
      description="Shop online directly at the retailers website on your phone or desktop."
      giveSentence={link.giveSentence}
      leftImage="https://flipgive.twic.pics/images/site/shop/brand-modal-shop.png"
      linkId={link.id}
      showBorder={!isFirst}
      title="Shop Online"
      onClick={onClick}
    />
  );
};

BrandModalShopLink.fragments = FRAGMENTS;
BrandModalShopLink.defaultProps = DEFAULT_PROPS;

export default BrandModalShopLink;

// == Styles ===============================================================
