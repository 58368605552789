import React from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import { isEmpty } from "lodash";
import { oc } from "ts-optchain";
import { useScrollTopOnMount, useLoadingDelay } from "@hooks";
import { parseId } from "@services";
import { CollectionRender } from "@organisms";
import { Container, Text } from "@atoms";
import { FadeIn } from "@utils";
import { OccasionViewLoading } from "../OccasionViewLoading";
import { OccasionHeader } from "../OccasionHeader";
import {
  OccasionQuery,
  OccasionQuery_Occasion_collections_edges,
} from "./__generated__/OccasionQuery";

// import styled from "@emotion/styled";

// == Types ================================================================

interface IRouteProps {
  id: string;
  isLoading?: boolean;
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================

export const OCCASION_QUERY = gql`
  query OccasionQuery($id: ID!, $carouselItems: Int = 10) {
    Occasion(id: $id) {
      id
      ...OccasionHeaderFragment
      collections {
        edges {
          cursor
          node {
            id
            ...CollectionRenderFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${OccasionHeader.fragments.fields}
  ${CollectionRender.fragments.fields}
`;

const DEFAULT_PROPS = {
  isLoading: false,
};

// == Component ============================================================

export const OccasionView = ({ id, isLoading }: TProps) => {
  useScrollTopOnMount();

  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery<OccasionQuery>(OCCASION_QUERY, {
    variables: { id: parseId(id) },
  });

  if (error) {
    return <Text>{error.message}</Text>;
  }

  const hasNotLoaded = isEmpty(data) || loading || !loadingDelayReady || isLoading;

  if (hasNotLoaded) return <OccasionViewLoading />;

  const occasion = oc(data).Occasion();
  if (!occasion) return null;
  return (
    <>
      <FadeIn>
        <Container isMainContainer>
          <OccasionHeader occasion={occasion} />
          {oc(occasion)
            .collections.edges()!
            .map((edge: OccasionQuery_Occasion_collections_edges | null, index) => {
              return (
                !!edge &&
                !!edge.node && (
                  <CollectionRender
                    collection={edge.node}
                    key={edge.node.id}
                    showTopSpacing={index > 0}
                  />
                )
              );
            })}
        </Container>
      </FadeIn>
    </>
  );
};

OccasionView.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
