import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import { Box, Text } from "@atoms";
import { BrandFavoritesDialerQuery } from "@organisms";
import { useOutsideClick } from "@hooks";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

SearchBarFavorites.defaultProps = {};

// == Component ============================================================

export default function SearchBarFavorites({ isActive }) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();

  useOutsideClick(containerRef, () => {
    setIsOpen(false);
  });

  const onClick = (e) => {
    setIsOpen(!isOpen);
    e.preventDefault();
  };

  return (
    <Box position="relative" ref={containerRef}>
      <ESideLink href="#" onClick={onClick}>
        <Text color={isActive ? "primary" : ""}>Favorites</Text>
      </ESideLink>
      {isOpen && (
        <EBrandFavoritesContainer borderRadius={5} boxShadow="regularShadow">
          <BrandFavoritesDialerQuery />
        </EBrandFavoritesContainer>
      )}
    </Box>
  );
}

// == Styles ===============================================================

// same as the one on search bar so if you update this, please update there
const ESideLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const EBrandFavoritesContainer = styled(Box)`
  width: 350px;
  position: absolute;
  right: 0;
  top: 50px;
  background: ${({ theme }) => theme.colors.white};
  z-index: 2;
`;
