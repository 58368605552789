import { useEffect } from "react";

export const useScrollTopOnMount = () => {
  useEffect(() => {
    // *** the commented code handles Anon /teams/${campaignId} storeFront Views
    // *** forces an anchored scroll-down to make team card up and out of view -kjl
    // const content = document.getElementById("content");
    // if (content && !document.body.classList.contains("is-member")) {
    //   setTimeout(() => {
    //     window.scrollTo(0, content.offsetTop);
    //   }, 100);
    // } else {
    // window.scrollTo(0, 0);
    // }
    window.scrollTo(0, 0);
  }, []);
};
