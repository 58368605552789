import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { useLoadingDelay } from "@hooks";
import { LinkModalFundraiserListQuery as TLinkModalFundraiserListQuery } from "./__generated__/LinkModalFundraiserListQuery";
import LinkModalFundraiserList from "./LinkModalFundraiserList";

// == Types ================================================================

interface IProps {
  onFundraiserSelect: (id: string) => void;
}

// == Constants ============================================================

const LINK_MODAL_FURNDRAISER_LIST_QUERY = gql`
  query LinkModalFundraiserListQuery {
    Campaign {
      id
      name
      photoImage
      ...LinkModalFundraiserListFragment
    }
  }
  ${LinkModalFundraiserList.fragment}
`;

// == Component ============================================================

export const LinkModalFundraiserListQuery = ({ onFundraiserSelect }: IProps) => {
  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery<TLinkModalFundraiserListQuery>(
    LINK_MODAL_FURNDRAISER_LIST_QUERY
  );

  if (error) return null;
  if (!data?.Campaign || loading || !loadingDelayReady) return null;

  return (
    <LinkModalFundraiserList campaign={data.Campaign} onFundraiserSelect={onFundraiserSelect} />
  );
};

// == Styles ===============================================================
