import { action, observable, computed, configure } from "mobx";

configure({ enforceActions: 'observed' });

class TimelineStore {
  @observable activities = new Map();
  @observable current_user = {};
  @observable campaign_id = null;
  @observable addingPost = false;
  @observable writingPost = false;
  @observable publicView = true;
  @observable singleActivity = false;
  @observable isPartner = true;
  @observable feed = "";

  @action
  setActivitiesFromArray = (activities) => {
    activities.forEach((activity) => {
      this.activities.set(activity.id, activity);
    });
  };

  @action
  setCurrentUser = (currentUser) => {
    this.current_user = currentUser;
  };

  @action
  setCampaignId = (campaignId) => {
    this.campaign_id = campaignId;
  };

  @action
  setWritingPost = (writingPost) => {
    this.writingPost = writingPost;
  };

  @computed
  get user_id() {
    if (this.current_user) {
      return this.current_user.id;
    }
    return null;
  }

  @computed
  get teamView() {
    return !this.publicView;
  }
}

const store = new TimelineStore();
export default store;
