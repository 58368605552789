import React, { ReactNode } from "react";
import queryString from "query-string";
import styled from "@emotion/styled";
import { ErrorLoading } from "@components/common/organisms";
import { Spinner, CenteredBoxLayout } from "@molecules";
import { Text } from "@atoms";
import { useLoadingDelay } from "@hooks";

// == Types ================================================================

interface IProps {
  loading: boolean;
  hasError: boolean;
  children?: ReactNode;
}

// == Constants ============================================================

OnboardingContainer.defaultProps = {
  loading: false,
  hasError: false,
  children: null,
};

// == Component ============================================================

export function OnboardingContainer({ loading, hasError, children, ...rest }: IProps) {
  const loadingDelayReady = useLoadingDelay(500);
  const queryParams = queryString.parse(window.location.search);
  const params = Object.keys(queryParams);
  const childComponent = () => {
    if (loading || !loadingDelayReady) return <Spinner />;
    if (hasError) return <ErrorLoading />;
    return children;
  };
  return (
    <ECenteredLayout position="relative" {...rest}>
      {params.includes("resume") && (
        <Text color="success" display="block" mb={2} textAlign="center">
          This is where you left off your onboarding. Please continue.
        </Text>
      )}
      {childComponent()}
    </ECenteredLayout>
  );
}

// == Styles ===============================================================

// header height is 64
const ECenteredLayout = styled(CenteredBoxLayout)`
  min-height: calc(100vh - 64px);
  overflow-y: auto;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    min-height: 0;
    overflow-y: none;
  }
`;
