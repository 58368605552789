import React from "react";
import { ImageCircle } from "@molecules/ImageCircle";
import { ImageListItem } from "@molecules/ImageListItem";
import { IBoxProps } from "@atoms";
import { theme } from "@styles";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends IBoxProps {
  imageSrc: string;
  name: string;
}

// == Constants ============================================================

// == Component ============================================================

// this should be an organism
const UserListItem = ({ imageSrc, name, ...rest }: IProps) => {
  const isDefaultImage = imageSrc.slice(0, 7).includes("default");
  const src = isDefaultImage
    ? "https://d2wkegjj8g6j4.cloudfront.net/images/site/user/square_100.webp"
    : imageSrc;

  return (
    <ImageListItem
      leftComponent={
        <ImageCircle
          alt={name}
          border="silver"
          height={theme.sizes.profileImageSize}
          src={src}
          width={theme.sizes.profileImageSize}
        />
      }
      text={name}
      {...rest}
    />
  );
};

export default UserListItem;

// == Styles ===============================================================
