import React from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import styled from "@emotion/styled";
import { DefaultStoreFrontType } from "@types";
import { OnboardingContainer } from "../components";
import { OnboardingBrands } from "./OnboardingBrands";
import {
  OnboardingBrandsQuery as OnboardingBrandsQueryType,
  OnboardingBrandsQueryVariables,
} from "./__generated__/OnboardingBrandsQuery";

// == Types ================================================================

// == Constants ============================================================

const ONBOARDING_BRANDS_QUERY = gql`
  query OnboardingBrandsQuery($type: DefaultStoreFrontType!) {
    DefaultStoreFront(type: $type) {
      id
      collections {
        id
        ...OnboardingBrandsFragment
      }
    }
  }
  ${OnboardingBrands.fragments}
`;

// == Component ============================================================

export function OnboardingBrandsQuery(_props: RouteComponentProps) {
  const { data, error, loading } = useQuery<
    OnboardingBrandsQueryType,
    OnboardingBrandsQueryVariables
  >(ONBOARDING_BRANDS_QUERY, {
    variables: {
      type: DefaultStoreFrontType.ONBOARDING_DEFAULT,
    },
  });

  const collections = data?.DefaultStoreFront?.collections || [];
  return (
    <EOnboardingBrandsContainer hasError={!!error || !collections.length} loading={loading}>
      <OnboardingBrands collections={collections} />
    </EOnboardingBrandsContainer>
  );
}

// == Styles ===============================================================

// 130 is arbitrary size so that this view takes up most of the height
const EOnboardingBrandsContainer = styled(OnboardingContainer)`
  height: calc(100vh - 64px) !important;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    height: calc(100vh - 130px) !important;
    min-height: 0;
  }
`;
