import React, { Fragment, Component } from "react";
import { shape, string } from "prop-types";
import { Utils, Mentionify, destinationParser, constants } from "@services";
import ActivityHeading from "../ActivityHeading";
import YouTubeVideo from "./YouTubeVideo";
import { FlipgiveStore } from "@stores";
import { CustomStorefrontsIds } from "@components/common/utils/customStorefrontsIds";

const { addUTM } = Utils;

export default class Generic extends Component {
  static propTypes = {
    activity: shape({
      photoImage: string,
      photoSize: string,
    }).isRequired,
  };

  renderImage = () => {
    const { activity } = this.props;
    const { photoImage, photoSize, ctaUrl } = activity.details;
    if (!photoImage) {
      return null;
    }

    const img = (
      <img
        alt=""
        className={`timeline-body__image timeline-body__image--${photoSize}`}
        src={photoImage}
      />
    );

    if (ctaUrl) {
      return (
        <a
          href={`${addUTM(ctaUrl, "feed", "timeline", "")}&${constants.storefrontParam}=${
            CustomStorefrontsIds.newsfeed
          }`}
          rel="nofollow"
        >
          {img}
        </a>
      );
    }
    return img;
  };

  renderDescription = () => {
    const {
      activity: {
        details: { description },
      },
    } = this.props;
    if (!description) {
      return null;
    }

    return (
      <p className="timeline-body__content headline-font">
        {description.split("\n").map((item, key) => (
          <Fragment key={key}>
            {Mentionify(item)}
            <br />
          </Fragment>
        ))}
      </p>
    );
  };

  renderQuote = () => {
    const {
      activity: {
        details: { quote },
      },
    } = this.props;
    if (!quote) {
      return null;
    }

    return (
      <p className="timeline-body__content headline-font center mt-3">
        {quote.split("\n").map((item, key) => (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        ))}
      </p>
    );
  };

  renderYouTube = () => {
    const {
      activity: {
        details: { youtubeId },
      },
    } = this.props;
    if (!youtubeId) {
      return null;
    }

    return (
      <div className="timeline__embed-container">
        <YouTubeVideo youtubeId={youtubeId} />
      </div>
    );
  };

  renderCTA = () => {
    const {
      activity: {
        details: { destination, ctaText, ctaUrl },
      },
    } = this.props;

    let url = ctaUrl;

    if (destination?.includes("Screen::")) {
      const campaignId = FlipgiveStore.campaign_id;
      url = destinationParser({ destination, campaignId });
    }

    const hasCta = ctaText && ctaUrl;

    if (!hasCta) {
      return null;
    }

    return (
      <a
        className="timeline-body__cta btn btn-clear btn-sm"
        href={`${addUTM(ctaUrl, "feed", "timeline", "")}&${constants.storefrontParam}=${
          CustomStorefrontsIds.newsfeed
        }`}
        rel="nofollow"
      >
        {ctaText}
      </a>
    );
  };

  render() {
    const { actor, time, visibility } = this.props.activity;

    return (
      <div>
        <ActivityHeading actor={actor} time={time} visibility={visibility} />
        <div className="timeline-body">
          {this.renderCTA()}
          {this.renderDescription()}
          {this.renderImage()}
          {this.renderYouTube()}
          {this.renderQuote()}
        </div>
      </div>
    );
  }
}
