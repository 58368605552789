import React from "react";
import produce from "immer";
import { EventFilter, IEventFilter } from "./EventFilter";

interface IEventFiltersProps {
  originalEventFilters: IEventFilter[];
  eventInputId: string;
}

export function EventFilters({ originalEventFilters, eventInputId }: IEventFiltersProps) {
  const [eventFilters, setEventFilters] = React.useState(originalEventFilters);

  React.useEffect(() => {
    const input: HTMLInputElement | null = document.querySelector(eventInputId);
    if (input) {
      input.value = JSON.stringify(
        eventFilters.filter((filter: IEventFilter) => {
          return filter.event;
        })
      );
    }
  }, [eventFilters, eventInputId]);

  return (
    <>
      {eventFilters.map((filter, i) => (
        <EventFilter
          filter={filter}
          index={i}
          key={i}
          remove={() => {
            setEventFilters(
              produce((draft: IEventFilter[]) => {
                draft.splice(i, 1);
              })
            );
          }}
          update={(newFilter: IEventFilter) => {
            const newFilters = [...eventFilters];
            newFilters[i] = newFilter;
            setEventFilters(newFilters);
          }}
        />
      ))}

      <button
        className="btn btn-default"
        type="button"
        onClick={() => {
          setEventFilters([...eventFilters, { event: "" }]);
        }}
      >
        Add
      </button>
    </>
  );
}
