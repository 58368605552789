import React from "react";
import { LoadingBox, LoadingTextBlock, Column, Row, Box, Flex } from "@components";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// == Component ============================================================

export const SwitchTeamLoading = () => {
  return (
    <div className="row justify-content-center">
      {/* {hasCampaign ? ( */}
      <div className="col-md-8">
        Loading...
        {/* <LoadingBox /> */}
      </div>
      {/* can't tell if there are campaigns before the query */}
      {/* ) : (
        <div className="col-md-10">
          <Flex alignItems="center" flexDirection="column" justifyContent="center">
            <Box width="40%">
              <LoadingTextBlock rows={1} />
            </Box>
            <Box width="60%">
              <LoadingTextBlock rows={1} />
            </Box>
          </Flex>
          <Row flexDirection={["column", "column", "row"]}>
            <Column>
              <LoadingBox height={400} />
            </Column>
            <Column>
              <LoadingBox height={400} />
            </Column>
            <Column>
              <LoadingBox height={400} />
            </Column>
          </Row>
        </div>
      )} */}
    </div>
  );
};

// == Styles ===============================================================
