import React from "react";
import { FontAwesome } from "../../FontAwesome";
import { IFlexProps } from "../../Flex";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  containerProps?: IFlexProps;
  iconSize: "xs" | "sm" | "lg" | "2x" | "3x" | "5x" | "7x" | "10x";
}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export default function ExchangeAlt({ containerProps, ...rest }: IProps) {
  return (
    <FontAwesome
      containerProps={containerProps}
      data-testid="exchange-alt"
      icon="exchange-alt"
      {...rest}
    />
  );
}

ExchangeAlt.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
