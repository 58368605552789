import React, { ChangeEvent } from "react";
import { Field, ErrorMessage } from "formik";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  checked?: boolean;
  name: string;
  label: string;
  hint?: string;
  labelSize?: "small" | "normal";
  onChange?: (e: ChangeEvent) => void;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  name: null,
  label: "",
  checked: false,
  hint: null,
  labelSize: "normal",
};

// == Component ============================================================
CheckboxField.defaultProps = DEFAULT_PROPS;

export default function CheckboxField({ label, name, hint, labelSize, ...rest }: IProps) {
  return (
    <>
      <Field name={name}>
        {({ field, form }) => (
          <p>
            <input
              className="filled-in"
              id={name}
              type="checkbox"
              {...rest}
              checked={!!field.value}
              onChange={() => {
                form.setFieldValue(name, !field.value);
              }}
            />
            <label className={labelSize === "small" ? "label--small" : ""} htmlFor={name}>
              {label}
            </label>
          </p>
        )}
      </Field>
      <ErrorMessage className="standalone-error" component="span" name={name} />
      {!!hint && <span className="checkbox-hint">{hint}</span>}
    </>
  );
}

export const CheckboxComponent = ({ name, labelSize, label, hint, ...rest }: IProps) => {
  return (
    <p>
      <input className="filled-in" id={name} type="checkbox" {...rest} />
      <label className={labelSize === "small" ? "label--small" : ""} htmlFor={name}>
        {label}
      </label>
      {!!hint && <span className="checkbox-hint">{hint}</span>}
    </p>
  );
};

// == Styles ===============================================================
