import React, { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import { Formik, Form } from "formik";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { CheckoutViewStripeFields } from "@pages/checkoutComponents";
import { Text, Image, Box, TouchableOpacity } from "@atoms";
import { PrettyRadioOption } from "@molecules";
import { TGiftcardReloadDispatch } from "./types";

// == Types ================================================================

interface IProps {
  cardId: string | null;
  dispatch: TGiftcardReloadDispatch;
}

// == Constants ============================================================

const GIFTCARD_RELOAD_CARDS_QUERY = gql`
  query GiftcardReloadPaymentCardsQuery {
    Viewer {
      id
      stripeCards {
        id
        brand
        default
        last4
        expiryFormatted
        expiryMonth
        expiryYear
      }
    }
  }
`;

export const creditCardLogoURI = (creditCardType: string) => {
  switch (creditCardType) {
    case "Visa":
      return "visa";
    case "MasterCard":
      return "mastercard";
    case "American Express":
      return "american_express";
    default:
      return false;
  }
};

// == Component ============================================================

export function GiftcardReloadPaymentFields({ cardId, dispatch }: IProps) {
  const [addNewCard, setAddNewCard] = useState(false);
  const [newCards, setNewCards] = useState([]);
  const [stateFormProps, setFormProps] = useState();
  const { data } = useQuery(GIFTCARD_RELOAD_CARDS_QUERY);
  useEffect(() => {
    const savedCards = data?.Viewer.stripeCards || [];
    const totalCards = [...savedCards, ...newCards];
    const type = totalCards.length === 0 ? "DISABLE_FORM" : "ENABLE_FORM";
    dispatch({ type });
  }, [data, dispatch, newCards]);
  if (!data?.Viewer) return null;
  const savedCards = data.Viewer.stripeCards;

  const initialState = {
    cardId,
  };

  const onAddNewCardClick = () => {
    setAddNewCard(true);
    dispatch({ type: "CLICK_ADD_NEW_CARD" });
  };

  const onSaveNewCardClick = ({ token }) => {
    const newCard = {
      id: token.id,
      token: token.id,
      default: false,
      last4: token.card.last4,
      brand: token.card.brand,
      expiryFormatted: token.card.expiryFormatted,
      expiryMonth: token.card.exp_month,
      expiryYear: token.card.exp_year,
    };

    dispatch({ type: "SET_NEW_CARD", newCardId: token.id });
    setNewCards([...newCards, newCard]);
    stateFormProps.setFieldValue("cardId", token.id);
  };

  const onCardClick = (card) => {
    setAddNewCard(false);
    dispatch({ type: "SET_ACTIVE_CARD", cardId: card.id });
    stateFormProps.setFieldValue("cardId", card.id);
  };

  return (
    <>
      {/* formik is here, but it's use... is only for pretty radios */}
      <Formik
        initialValues={initialState}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          setSubmitting(false);
        }}
      >
        {(formProps) => {
          if (!stateFormProps) setFormProps(formProps);
          return (
            <Form className="ElementsApp">
              {savedCards.map((card) => {
                return (
                  <Box key={card.id} position="relative">
                    <PrettyRadioOption
                      label={`${card.brand} ending in ${card.last4}`}
                      leftContent={
                        <EImage
                          alt={card.brand}
                          src={`https://flipgive.twic.pics/images/site/linked-cards/${creditCardLogoURI(
                            card.brand
                          )}.png?twic=v1/resize=200x200/background=FFFFFF`}
                        />
                      }
                      name="cardId"
                      // value and value passed to the on click have to match
                      secondaryLabel={`${card.expiryMonth}/${card.expiryYear
                        .toString()
                        .substr(-2)}`}
                      value={card.id}
                      onClick={() => onCardClick(card)}
                      // onClick={() => onCardClick(card, setFieldValue, submitForm)}
                    />
                  </Box>
                );
              })}
              {newCards.map((card) => {
                return (
                  <Box key={card.id} position="relative">
                    <PrettyRadioOption
                      label={`${card.brand} ending in ${card.last4}`}
                      leftContent={
                        <EImage
                          alt={card.brand}
                          src={`https://flipgive.twic.pics/images/site/linked-cards/${creditCardLogoURI(
                            card.brand
                          )}.png?twic=v1/resize=200x200/background=FFFFFF`}
                        />
                      }
                      name="cardId"
                      // value and value passed to the on click have to match
                      secondaryLabel={`${card.expiryMonth}/${card.expiryYear
                        .toString()
                        .substr(-2)}`}
                      value={card.id}
                      onClick={() => onCardClick(card)}
                    />
                  </Box>
                );
              })}
            </Form>
          );
        }}
      </Formik>
      {savedCards.length > 0 && (
        <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={onAddNewCardClick}>
          <Box mb={2} textAlign="center">
            <Text color="primary">Add New Card</Text>
          </Box>
        </TouchableOpacity>
      )}

      {(addNewCard || savedCards.length === 0) && (
        <CheckoutViewStripeFields
          forceSave
          setAddNewCard={setAddNewCard}
          onSaveNewCardClick={onSaveNewCardClick}
        />
      )}
    </>
  );
}

// == Styles ===============================================================

const EImage = styled(Image)`
  height: 40px;
  width: 40px;
`;
