import React from "react";
import FlipGiveTeams from "./FlipGiveTeams";
import FlipGiveChoose from "./FlipGiveChoose";
import TeamsnapTeams from "./TeamsnapTeams";

export default function Connect({ campaignId, landing }) {
  const [externalTeam, setExternalTeam] = React.useState(null);
  const [activeView, setActiveView] = React.useState("teamsnap");

  if (activeView === "choose") {
    return (
      <FlipGiveChoose
        chooseCreate={() => {
          setActiveView("create");
        }}
        externalTeam={externalTeam}
      />
    );
  }

  if (activeView === "create") {
    return <FlipGiveTeams externalTeam={externalTeam} landing={landing} />;
  }

  return (
    <TeamsnapTeams
      campaignId={campaignId}
      setExternalTeam={(newExternalTeam) => {
        setExternalTeam(newExternalTeam);
        setActiveView("choose");
      }}
    />
  );
}
