import React from "react";
import styled from "@emotion/styled";
import { CardForm, Box, Image, Text, Link } from "@atoms";

// == Types ================================================================

interface IProps {}

// == Constants ============================================================

CheckoutHelp.defaultProps = {};

// == Component ============================================================

export default function CheckoutHelp(props: IProps) {
  return (
    <Box mt={3} pb={5} px={["24px", 0]}>
      <ELink href="mailto:support@flipgive.com" target="_blank">
        support@flipgive.com
      </ELink>
      <Text color="primary">1-855-583-2510</Text>
    </Box>
  );
}

// == Styles ===============================================================

const ELink = styled(Link)`
  display: block;
`;
