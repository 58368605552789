import { useMemo } from "react";
import { useUrlParams } from "./useUrlParams";
import { customCollectionsNames } from "@components/common/utils/customCollectionsIds";
import { customStorefrontsNames } from "@components/common/utils/customStorefrontsIds";

export function useFullStoryTrackingLocation() {
  const collectionId = useUrlParams("collectionId");
  const storefrontId = useUrlParams("storefrontId");

  const collection = useMemo(() => {
    if (collectionId && +collectionId < 0) return customCollectionsNames[collectionId];
    return collectionId;
  }, [collectionId]);

  const storefront = useMemo(() => {
    if (storefrontId && +storefrontId < 0) return customStorefrontsNames[storefrontId];
    return storefrontId;
  }, [storefrontId]);
  return {
    collection,
    storefront,
  };
}
