import React, { ReactChild, useEffect, useRef } from "react";
import { ErrorMessage, useField } from "formik";
import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  children: ReactChild[] | ReactChild;
  label?: string;
  name: string;
  handleChange?: (e) => $FixMe;
  handleBlur?: (e) => void;
  onChange?: (e) => void;
  setCountry?: (string) => void;
  disabled?: boolean;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  placeholder: "",
  onChange: undefined,
  disabled: false,
};

// == Component ============================================================
SelectField.defaultProps = DEFAULT_PROPS;

export function SelectField({
  label,
  children,
  handleChange,
  handleBlur,
  onChange,
  setCountry,
  disabled,
  ...props
}: IProps) {
  const selectRef = useRef(null);

  const [field, meta] = useField(props);
  const { name } = props;

  useEffect(() => {
    $(selectRef.current).material_select();
    $(selectRef.current).on("change", function(e) {
      field.onChange(e);
      if (onChange) {
        onChange(e);
      }
    });

    $(selectRef.current).on("blur", function(e) {
      field.onBlur(e);
    });
    return () => {
      $(selectRef.current).material_select("destroy");
      $(selectRef.current).off();
    };
  }, [field, onChange]);

  return (
    <>
      {label && <ELabel htmlFor={name}>{label}</ELabel>}
      <ESelect id={name} ref={selectRef} {...field} {...props} disabled={disabled}>
        {children}
      </ESelect>

      <ErrorMessage className="standalone-error" component="span" name={name} />
    </>
  );
}

// == Styles ===============================================================

const ESelect = styled.select`
  height: 40px !important;
  padding-top: 0 !important;
  > input {
    height: 40px !important;
    padding-top: 0 !important;
    padding-right: 25px !important;
  }
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;
