import React, { useState, useRef } from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { useMutation, useQuery } from "react-apollo";
import { ApolloError } from "apollo-client";
import { Modal, Box, Flex, Text, TouchableOpacity, ChevronLeft, Button, FontAwesome } from "@atoms";
import { CircleAddIcon, ImageListItem, NoAvatar, ImageCircle } from "@molecules";
import { useToggle, useStoreAppValue } from "@hooks";
import { ErrorService, RailsUrl, formatMoney } from "@services";
import { CreateSubGroupFragment } from "./__generated__/CreateSubGroupFragment";
import {
  CreateSubGroupMutation,
  CreateSubGroupMutationVariables,
} from "./__generated__/CreateSubGroupMutation";

// == Types ================================================================

interface IProps {
  groups: CreateSubGroupFragment[];
  refetch: () => void;
}

// == Constants ============================================================

CreateSubGroup.fragment = gql`
  fragment CreateSubGroupFragment on Group {
    id
    name
    imageUrl
  }
`;

const CREATE_SUB_GROUP_MUTATION = gql`
  mutation CreateSubGroupMutation($name: String!, $image: Upload, $goal: Int) {
    CampaignGroupCreate(name: $name, image: $image, goal: $goal) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        groups {
          id
          name
          imageUrl
          fundraisers {
            id
            name
            amountRaised {
              dollarsAmount
              formatted
            }
          }
        }
        fundraisers {
          id
          name
          group {
            id
            name
          }
        }
      }
    }
  }
`;

const REMOVE_EMPTY_GROUP_MUTATION = gql`
  mutation RemoveEmptyGroupMutation($id: ID!) {
    CampaignGroupDelete(id: $id) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        groups {
          id
          name
        }
      }
    }
  }
`;

const VIEWER_ESTIMATE_QUERY = gql`
  query ViewerEstimateQuery {
    Viewer {
      id
      name
      onboarding {
        earningsEstimate {
          dollarsAmount
        }
      }
    }
  }
`;

// == Functions ============================================================

const AltTextField = ({ type, label, name, disabled = false, ...rest }) => {
  return (
    <Box display={type === "hidden" ? "none" : "block"}>
      {label && (
        <ELabel className="active" htmlFor={name}>
          {label}
        </ELabel>
      )}
      <Box className={`input-field ${disabled ? "disabled" : ""}`}>
        <EInput disabled={disabled} name={name} {...rest} />
      </Box>
    </Box>
  );
};

const FontAwesomeUser = () => {
  return (
    <Box border="1px solid #DDD" borderRadius="50%" p={3}>
      <FontAwesome icon="user" />
    </Box>
  );
};

// == Component ============================================================

// *** this component was originally written to bundle fundraisers into subGroups
// *** it has subsequently been modified to infer that each subGroup would be affiliated
// *** with a Player.
// *** TODO: refactor code and variable_names to reflect the shift from subGroups to Players

export default function CreateSubGroup({ groups, refetch }: IProps) {
  const [isCreateSubGroupModalOpen, setIsCreateSubGroupModalOpen] = useToggle(false);
  const [isAddPlayerInputBoxOpen, setIsAddPlayerInputBoxOpen] = useToggle(false);
  const [pendingGroupName, setPendingGroupName] = useState("");
  const [pendingGroupGoal, setPendingGroupGoal] = useState("");
  const [pendingGroupImage, setPendingGroupImage] = useState<File | null>(null);
  const [previewGroupImage, setPreviewGroupImage] = useState<string | null>(null);
  const [pendingGroupRemovalName, setPendingGroupRemovalName] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isPendingGroupNameUsed = groups.map((group) => group.name).includes(pendingGroupName);

  const { inviteTeamUrl } = RailsUrl;
  const { campaignId } = useStoreAppValue();

  const redirectToInviteUrl = () => {
    window.location.href = inviteTeamUrl(campaignId);
  };

  const onErrorCreate = (errors?: ApolloError) => {
    if (errors) ErrorService.error(errors);
    Materialize?.toast?.(
      `There was an ERROR creating the subgroup. Please try again.`,
      4000,
      "rounded toast-danger"
    );
    setIsCreateSubGroupModalOpen(false);
    setPendingGroupName("");
  };

  const onErrorRemove = (error?: ApolloError) => {
    if (error) ErrorService.error(error);
    Materialize?.toast?.(
      `There was an ERROR removing ${pendingGroupRemovalName} from this campaign. Please try again.`,
      4000,
      "rounded toast-danger"
    );
    setPendingGroupRemovalName("");
  };

  const [createCreateSubGroupMutation, { loading }] = useMutation<
    CreateSubGroupMutation,
    CreateSubGroupMutationVariables
  >(CREATE_SUB_GROUP_MUTATION, {
    onError: () => onErrorCreate(),
    onCompleted: ({ CampaignGroupCreate }) => {
      if (CampaignGroupCreate.__typename === "MutationError") {
        onError(CampaignGroupCreate?.errors);
        return;
      }
      Materialize?.toast?.(
        `Player ${pendingGroupName} has been created`,
        4000,
        "rounded toast-success"
      );
      setIsCreateSubGroupModalOpen(false);
      setPendingGroupName("");
      setPendingGroupGoal("");
      setPendingGroupImage(null);
      setPreviewGroupImage(null);
      refetch();
    },
    context: {
      hasUploads: true,
    },
  });

  // Mutation to remove player
  const [createRemoveEmptyGroupMutation, { loading: loadingRemoval }] = useMutation<$FixMe, $FixMe>(
    REMOVE_EMPTY_GROUP_MUTATION,
    {
      onError: () => onErrorRemove(),
      onCompleted: ({ CampaignGroupDelete }) => {
        if (CampaignGroupDelete.__typename === "MutationError") {
          onErrorRemove();
          return;
        }
        Materialize?.toast?.(
          `${pendingGroupRemovalName} has been successfully removed from this campaign`,
          4000,
          "rounded toast-success"
        );
        setPendingGroupRemovalName("");
      },
    }
  );

  // To load initial value for pending group goal based on viewer's onboarding estimate
  useQuery(VIEWER_ESTIMATE_QUERY, {
    onCompleted: (data) => {
      const amount = data?.Viewer?.onboarding?.earningsEstimate?.dollarsAmount || 250;
      setPendingGroupGoal(amount);
    },
  });

  const primary = useStoreAppValue()?.theme?.color?.primary ?? "#27B7BA";

  const onTouchCreateSubgroup = () => {
    return setIsCreateSubGroupModalOpen(true);
  };

  const onTouchAddPlayer = () => {
    setIsAddPlayerInputBoxOpen(!isAddPlayerInputBoxOpen);
  };

  const onChangeName = (e) => {
    e.preventDefault();
    setPendingGroupName(e.target.value);
  };

  const onChangeGoal = (e) => {
    e.preventDefault();
    setPendingGroupGoal(e.target.value);
  };

  const onClickCreateSubgroup = () => {
    return isPendingGroupNameUsed
      ? null
      : createCreateSubGroupMutation({
          variables: {
            name: pendingGroupName,
            image: pendingGroupImage,
            goal: parseInt(pendingGroupGoal, 10),
          },
        });
  };

  const onChangeImageUpload = (event) => {
    const file = event.target.files[0];

    if (file.type.substr(0, 5) === "image") {
      setPendingGroupImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewGroupImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const onTouchClearPendingGroupImage = () => {
    setPendingGroupImage(null);
    setPreviewGroupImage(null);
  };

  const onDelete = (id: string, name: string) => {
    setPendingGroupRemovalName(name);
    createRemoveEmptyGroupMutation({ variables: { id } });
  };

  const onCancel = () => {
    setIsCreateSubGroupModalOpen(false);
    setPendingGroupName("");
    setPendingGroupGoal("");
    setPendingGroupImage(null);
    setPreviewGroupImage(null);
    setPendingGroupRemovalName("");
  };

  /*
    <NoAvatar
      circleDiameter={50}
      firstName={
        pendingGroupName
          .split(" ")
          .map((str: string) => str.substr(0, 1))
          .slice(0, 2)[0]
      }
      lastName={
        pendingGroupName
          .split(" ")
          .map((str: string) => str.substr(0, 1))
          .slice(0, 2)[1]
      }
      isHighlighted={false}
    />
  */

  return (
    <>
      <ImageListItem
        leftComponent={<CircleAddIcon />}
        padding={[1, 2]}
        text="Add/Edit Player"
        onTouch={onTouchCreateSubgroup}
      />
      <Modal isOpen={isCreateSubGroupModalOpen} onDismiss={() => onCancel()}>
        <Flex alignItems="center" flexDirection="column" justifyContent="center" padding={3}>
          <Flex alignSelf="flex-start">
            <TouchableOpacity onTouch={() => setIsCreateSubGroupModalOpen(false)}>
              <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
            </TouchableOpacity>
          </Flex>
          <Box>
            <Text fontSize={[2, 3]} fontWeight="bold">
              Add/Edit Player
            </Text>
          </Box>
          <Box padding={2} textAlign="center" width={0.6}>
            <Text fontSize={[0, 1]}>Add each player on your team.</Text>
          </Box>
          <Flex flexDirection="column" justifyContent="flex-start" px={[4, 5]} py={2} width={1}>
            <ImageListItem
              leftComponent={<CircleAddIcon />}
              paddingTop={10}
              text="Add/Edit Player"
              onTouch={onTouchAddPlayer}
            />
            {isAddPlayerInputBoxOpen && (
              <Flex
                alignItems="center"
                border="1px dashed #DDD"
                borderRadius="5px"
                flexDirection="column"
                justifyContent="flex-start"
                mt={2}
                py={4}
              >
                <Flex alignItems="center" flexDirection="column" justifyContent="center">
                  {!!previewGroupImage && !!pendingGroupImage ? (
                    <ImageListItem
                      leftComponent={
                        <ImageCircle
                          alt="player"
                          borderRadius={25}
                          height={50}
                          src={previewGroupImage}
                          width={50}
                        />
                      }
                      padding={2}
                      text={pendingGroupName}
                      onTouch={() => onTouchClearPendingGroupImage()}
                    />
                  ) : (
                    <>
                      <TouchableOpacity
                        onTouch={() => {
                          fileInputRef.current?.click();
                        }}
                      >
                        <ImageListItem
                          leftComponent={<FontAwesomeUser />}
                          padding={[1, 2]}
                          text="Add Image"
                        />
                      </TouchableOpacity>
                      <input
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        type="file"
                        onChange={(event) => onChangeImageUpload(event)}
                      />
                    </>
                  )}
                </Flex>
                <Box>
                  <AltTextField
                    label="Player Name"
                    name="name"
                    placeholder="Enter player name"
                    type="text"
                    value={pendingGroupName}
                    onChange={(e) => onChangeName(e)}
                  />
                </Box>
                <Box>
                  <AltTextField
                    label="Funding Goal"
                    name="goal"
                    placeholder="Enter funding goal"
                    type="number"
                    value={pendingGroupGoal}
                    onChange={(e) => onChangeGoal(e)}
                  />
                </Box>
                <Flex>
                  <Box px={2}>
                    <Button disabled={loading} variant="clear" onClick={onClickCreateSubgroup}>
                      <Text color="primary">Save</Text>
                    </Button>
                  </Box>
                  <Box px={2}>
                    <Button disabled={loading} variant="clearDanger">
                      <Text color="danger">Cancel</Text>
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            )}
          </Flex>

          {groups.map((group) => {
            return (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                key={group.id}
                px={[4, 5]}
                py={2}
                width={1}
              >
                <Flex alignItems="center">
                  <NoAvatar circleDiameter={50} firstName={group.name} lastName={group.name} />
                  <Flex flexDirection="column">
                    <Text fontWeight="bold" paddingLeft={10}>
                      {group.name}
                    </Text>
                    <Flex alignItems="center">
                      <Text fontSize={12} paddingLeft={10}>
                        Player Funding Goal:
                      </Text>
                      <Text fontSize={12} fontWeight="bold" paddingLeft={2}>
                        {formatMoney(group.goal.centsAmount / 100)}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex>
                  <Button variant="clearDanger" onClick={() => onDelete(group.id, group.name)}>
                    Delete
                  </Button>
                </Flex>
              </Flex>
            );
          })}

          <Flex alignItems="center" justifyContent="flex-end" px={[4, 5]} py={3} width={1}>
            <Button
              height={50}
              minWidth={200}
              variant={isPendingGroupNameUsed ? "disabled" : "primary"}
              // onClick={onClickCreateSubgroup}
              onClick={redirectToInviteUrl}
            >
              Send Player Invitations
            </Button>
          </Flex>
          {/* <Box py={2} textAlign="center">
            <Button height={50} minWidth={200} variant="clear" onClick={onCancel}>
              <Text color="primary" fontSize={[1, 2]}>
                Cancel
              </Text>
            </Button>
          </Box> */}
        </Flex>
      </Modal>
    </>
  );
}

// == Styles ===============================================================

const ESpan = styled.span`
  margin: 0 0 5px !important;
  display: block;
  line-height: 1.25;
`;

const EInput = styled.input`
  height: 40px !important;
  padding-top: 0 !important;
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;
