import React from "react";
import { ApolloProvider } from "react-apollo";
import PropTypes from "prop-types";
import Connect from "./components/Connect";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";

function TeamsnapConnectApp(props) {
  Settings.setDefaultsFromProps(props);
  const client = apolloClient(Settings.apiUrl);
  const { campaignId, landing } = props;

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <Connect campaignId={campaignId} landing={landing} />
      </ApolloProvider>
    </ErrorBoundary>
  );
}

TeamsnapConnectApp.defaultProps = {
  landing: "",
  campaignId: null,
};

TeamsnapConnectApp.propTypes = {
  landing: PropTypes.string,
  campaignId: PropTypes.string,
};

export default TeamsnapConnectApp;
