import React from "react";
import styled from "@emotion/styled";
import { H3, Box, Image, Text, Flex, Row, Column, Link } from "@atoms";
import { RailsUrl } from "@services";
import { CardTypeEnum } from "@types";

// == Types ================================================================

interface IProps {
  acceptedLinkedCardTypes: CardTypeEnum;
  groupedViewerCards: $FixMe;
}

// == Constants ============================================================

const creditCardLogoURI = (creditCardType) => {
  switch (creditCardType) {
    case "VISA":
      return "visa";
    case "MASTERCARD":
      return "mastercard";
    case "AMERICAN_EXPRESS":
      return "american_express";
    default:
      return "visa";
  }
};

const creditCardTypeDisplayName = (creditCardType) => {
  switch (creditCardType) {
    case "VISA":
      return "Visa";
    case "MASTERCARD":
      return "Mastercard";
    case "AMERICAN_EXPRESS":
      return "American Express";
    default:
      return "Credit Card";
  }
};

// == Component ============================================================

const InStoreLinkedCardsList = ({
  groupedViewerCards,
  acceptedLinkedCardTypes,
  ...rest
}: IProps) => {
  const href = RailsUrl.linkedCardsNewUrl;

  const renderCardInfo = (cardTypeSection) => {
    if (!acceptedLinkedCardTypes.includes(cardTypeSection)) {
      return (
        <Box textAlign="left">
          <Text as="p">Not Supported</Text>
        </Box>
      );
    }

    return (
      <Flex flexDirection="column" textAlign="left">
        {!!groupedViewerCards[cardTypeSection] &&
          groupedViewerCards[cardTypeSection].map((card) => (
            <Text key={`${cardTypeSection.title}-${card.last4}`} pb={2}>
              {creditCardTypeDisplayName(card.cardType)} ending in {card.last4}
            </Text>
          ))}
        <Link href={href} target="_blank">{`+ Link ${creditCardTypeDisplayName(
          cardTypeSection
        )}`}</Link>
      </Flex>
    );
  };
  return (
    <Box {...rest} bg="lightPrimary">
      <H3 mb={2}>Eligible Payment Cards</H3>
      {Object.values(CardTypeEnum).map((cardTypeSection) => (
        <Row key={`${creditCardLogoURI(cardTypeSection)}`} py={2}>
          <Column autoColumn={false}>
            <EImage
              alt={`${creditCardLogoURI(cardTypeSection)}`}
              src={`https://flipgive.twic.pics/images/site/linked-cards/${creditCardLogoURI(
                cardTypeSection
              )}.png?twic=v1/refit-inside=200x200@center/background=fff`}
            />
          </Column>
          <Column autoColumn={false} pt={2}>
            {renderCardInfo(cardTypeSection)}
          </Column>
        </Row>
      ))}
    </Box>
  );
};

export default InStoreLinkedCardsList;

// == Styles ===============================================================

const EImage = styled(Image)`
  height: 40px;
  width: 40px;
`;
