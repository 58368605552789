import React from "react";
import styled from "@emotion/styled";
import Downshift from "downshift";
import { Box, SearchIcon, Text } from "@atoms";
import { FavoritesModalSearchResultQuery } from "../FavoritesModalSearchResultQuery";
// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================
// TODO seems possible to abstract out to SearchInput
const FavoritesSearchBar = () => {
  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.mouseUp:
        return {
          ...changes,
          inputValue: state.inputValue,
        };
      default:
        return changes;
    }
  };
  return (
    <Downshift isOpen itemToString={(item) => (item ? item.value : "")} stateReducer={stateReducer}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => (
        <div style={{ position: "relative", flex: 1 }}>
          <Box position="relative">
            <EText color="primary">
              <SearchIcon />
            </EText>
            <EInput placeholder="Search for a brand" {...getInputProps()} />
          </Box>

          <EResultsContainer {...getMenuProps()}>
            <FavoritesModalSearchResultQuery
              {...{ inputValue, getItemProps, highlightedIndex, selectedItem }}
            />
          </EResultsContainer>
        </div>
      )}
    </Downshift>
  );
};

export default FavoritesSearchBar;

// == Styles ===============================================================
const EResultsContainer = styled(Box)`
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  overflow-y: auto;
  z-index: 100;
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    max-height: 400px;
  }
`;

const EText = styled(Text)`
  position: absolute;
  left: 16px;
  top: 22px;
`;

const EInput = styled.input`
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 45px !important;
  border-color: ${({ theme }) => theme.colors.gray}!important;
`;
