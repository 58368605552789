import styled from "@emotion/styled";
import { Box, Button } from "@atoms";

export const EInStoreBuyButton = styled(Button)`
  position: fixed;
  bottom: 5%;
  width: auto;
  padding-left: ${({ theme, userNeedsSignUp }) =>
    !userNeedsSignUp ? `${theme.space[5]}px` : `${theme.space[3]}px`};
  padding-right: ${({ theme, userNeedsSignUp }) =>
    !userNeedsSignUp ? `${theme.space[4]}px` : `${theme.space[3]}px`};
  border-radius: 100px;
  box-shadow: ${({ theme, userNeedsSignUp }) => theme.shadows.floatingButton};
  display: inline-block;
  display: block;
  left: 50%;
  color: ${({ theme }) => theme.colors.white};
  transform: perspective(1px) translate3d(-50%, 0, 0);
  border-width: 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    bottom: 15%;
  }
`;

export const EButtonIconBox = styled(Box)`
  overflow: hidden;
  position: absolute;
  top: 8px;
  left: 15px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  background: transparent;
`;
