import React from "react";
// import gql from "graphql-tag";
import { Location, LocationContext } from "@reach/router";
import { useToggle } from "@hooks";
import { FavoritesModalHeader } from "../FavoritesModalHeader";
import { FavoritesModalList } from "../FavoritesModalList";
import { FavoriteBrandsQuery_Viewer } from "./__generated__/FavoriteBrandsQuery";
// import styled from "@emotion/styled";

// == Types ================================================================
type TBrands = FavoriteBrandsQuery_Viewer["favoriteBrands"];
interface IProps {
  brands: TBrands;
}

// == Constants ============================================================

// TODO: this fragment causes a crash for some reason in storybook
// const FRAGMENTS = {
//   fields: gql`
//     fragment FavoritesModalFragment on Brand {
//       id
//       name
//       logoImage
//       logoBackgroundColor
//       invertedLogoImage
//       viewerHasFavorited
//     }
//   `,
// };

// == Component ============================================================

export function FavoritesModal({ brands }: IProps) {
  const [isEditing, toggleEditing] = useToggle(false);
  const [isSearchBarOpen, toggleSearchBar] = useToggle(false);

  const renderFavoritesModal = ({ location, navigate }: LocationContext) => {
    const onBackClick = () => {
      navigate(location.pathname);
    };
    return (
      <>
        <FavoritesModalHeader
          isEditing={isEditing}
          isSearchBarOpen={isSearchBarOpen}
          toggleEditing={toggleEditing}
          onBackClick={onBackClick}
        />
        <FavoritesModalList
          brands={brands}
          isEditing={isEditing}
          isSearchBarOpen={isSearchBarOpen}
          toggleSearchBar={toggleSearchBar}
        />
      </>
    );
  };

  return (
    <Location>
      {({ location, navigate }: LocationContext) => renderFavoritesModal({ location, navigate })}
    </Location>
  );
}

// FavoritesModal.fragments = FRAGMENTS;

// == Styles ===============================================================
