import { action, observable, computed, configure } from "mobx";

configure({ enforceActions: "observed" });

class FlipgiveStore {
  @observable current_user = {};

  @observable campaign_id = null;

  @action
  setCurrentUser = (currentUser) => {
    this.current_user = currentUser;
  };

  @action
  setCampaignId = (campaignId) => {
    this.campaign_id = campaignId;
  };

  @computed
  get user_id() {
    if (this.current_user) {
      return this.current_user.id;
    }
    return null;
  }
}

const store = new FlipgiveStore();
export default store;
