/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import gql from "graphql-tag";
import { uniq, get } from "lodash";
import { useQuery, useMutation } from "react-apollo";
import TeamsnapTeamListItem from "./TeamsnapTeamListItem";
import LoadingTeamsSpinner from "./LoadingTeamsSpinner";
import { RailsUrl } from "@services";

const EXTERNAL_TEAMS_QUERY = gql`
  query ExternalTeamsQuery {
    Viewer {
      id
      externalTeams(externalPartnerType: TEAMSNAP) {
        id
        type
        name
        currency
        country
        postalCode
        category {
          id
        }
        campaign {
          id
          name
        }
      }
    }
  }
`;

const CAMPAIGN_JOIN_MUTATION = gql`
  mutation CampaignJoinMutation($externalPartner: ExternalPartnerInput) {
    JoinCampaign(input: { externalPartner: $externalPartner }) {
      ... on Fundraiser {
        id
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;

export default function TeamsnapTeams({ setExternalTeam, campaignId }) {
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);

  const [joinCampaignMutation, { loading: joinLoading }] = useMutation(CAMPAIGN_JOIN_MUTATION);

  async function joinCampaign(team) {
    setSubmitting(true);

    await joinCampaignMutation({
      variables: {
        externalPartner: {
          id: team.id,
          type: team.type,
        },
      },
    });

    window.location.href = RailsUrl.newTeamsnapPasswordUrl();
  }

  function setOrJoinTeam(team) {
    if (team.campaign) {
      joinCampaign(team);
    } else {
      setExternalTeam(team);
    }
  }

  const { loading, data } = useQuery(EXTERNAL_TEAMS_QUERY, {
    onCompleted: () => {
      const { externalTeams } = data.Viewer;

      if (externalTeams.length === 1) {
        setOrJoinTeam(externalTeams[0]);
      } else if (campaignId) {
        const matchedTeam = externalTeams.find(
          (externalTeam) => externalTeam.campaign && externalTeam.campaign.id === campaignId
        );
        if (matchedTeam) {
          joinCampaign(matchedTeam);
        }
      }
    },
  });

  if (loading) {
    return <LoadingTeamsSpinner />;
  }
  if (submitting) {
    return <LoadingTeamsSpinner text="Joining..." />;
  }

  const teams = get(data, "Viewer.externalTeams", []);

  if (teams.length === 0) {
    return (
      <div>
        <h1 className="mb-3 centered">No Teams Found</h1>
        <a className="btn btn-block" href="/teams/new">
          Get Started Now
        </a>
      </div>
    );
  }

  return (
    <div>
      <h1 className="mb-3 centered">
        Select Your
        <br />
        TeamSnap Team
      </h1>

      <ul>
        {uniq(teams).map((externalTeam) => (
          <TeamsnapTeamListItem
            key={externalTeam.id}
            selectedTeam={selectedTeam}
            team={externalTeam}
            onSelectTeam={setSelectedTeam}
          />
        ))}
      </ul>

      <a
        className={`btn btn-block ${!selectedTeam ? "disabled" : null}`}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setOrJoinTeam(selectedTeam);
        }}
      >
        Link Team
      </a>
    </div>
  );
}
