const defaultFormatLink = (link) => {
  let markup;
  if (link.loading) {
    return link.text;
  }
  const imageUrl = link.image || "";
  if (imageUrl) {
    markup = `<div class='shopping-list-select2-result'>
      <img src='${imageUrl}' class='shopping-list-select2-result__image'/>
      <span class='shopping-list-select2-result__name block-text color-text'>
      ${link.name}
      </span>
      <h3 class='color-text shopping-list-select2-result__gives'>
      ${link.give_sentence}
      </h3>
    </div>
    `;
  } else {
    markup = link.name || link.text;
  }

  return markup;
};

const defaultFormatLinkSelection = (link) => {
  let markup;

  const imageUrl = link.image || $(link.element).data("image");
  const giveSentence = link.give_sentence || $(link.element).data("give-sentence");
  if (imageUrl) {
    markup = `<div class='shopping-list-select2-result'>
        <img src='${imageUrl}' class='shopping-list-select2-result__image'/>
        <span class='shopping-list-select2-result__name block-text color-text'>
          ${link.name || link.text}
        </span>
        <h3 class='color-text shopping-list-select2-result__gives'>
          ${giveSentence}
        </h3>
      </div>`;
  } else {
    markup = link.name || link.text;
  }
  return markup;
};

export default (
  input,
  campaignId,
  formatLink = defaultFormatLink,
  formatLinkSelection = defaultFormatLinkSelection,
) => {
  $(input).select2({
    placeholder: "Search",
    allowClear: true,
    ajax: {
      url: "/links",
      dataType: "json",
      delay: 250,
      data(params) {
        return {
          q: params.term,
          campaign_id: campaignId,
          page: params.page,
        };
      },
      processResults(data, params) {
        const page = params.page || 1;
        return {
          results: data.links,
          pagination: {
            more: page * 30 < data.total_count,
          },
        };
      },
      cache: true,
    },
    escapeMarkup(markup) {
      return markup;
    },
    minimumInputLength: 1,
    templateResult: formatLink,
    templateSelection: formatLinkSelection,
  });
};
