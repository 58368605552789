import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import gql from "graphql-tag";
import { Flex, Text, Image, Circle } from "@atoms";
import { ImageListItem } from "@molecules";
import { RailsUrl, possessive } from "@services";
import { useStoreAppValue } from "@hooks";
import {
  ViewerCampaignDropdownFragment,
  ViewerCampaignDropdownFragment_runningCampaigns,
} from "./__generated__/ViewerCampaignDropdownFragment";

// == Types ================================================================

type TSelectedCampaign = ViewerCampaignDropdownFragment_runningCampaigns | null;
interface IProps {
  viewer: ViewerCampaignDropdownFragment;
  selectedCampaignId: string | null;
  onCampaignSelect: (campaignId: string) => void;
  currency?: string | null;
  extraCampaign?: $FixMe;
}

// == Constants ============================================================

ViewerCampaignDropdown.fragment = gql`
  fragment ViewerCampaignDropdownFragment on Viewer {
    id
    name
    currency
    avatarImage
    activeCampaign {
      id
    }
    runningCampaigns {
      id
      name
      photoImage
      currency
    }
  }
  fragment ViewerCampaignDropdownCampaignFragment on Campaign {
    id
    name
    photoImage
    currency
  }
`;

const CampaignCircleImage = (alt: string, src: string | null) => {
  if (!src) return null;
  return (
    <Flex alignItems="center">
      <Circle>
        <Image alt={alt} src={`${src}&ar=1&fit=crop`} />
      </Circle>
    </Flex>
  );
};

// == Component ============================================================

export function ViewerCampaignDropdown({
  viewer,
  onCampaignSelect,
  selectedCampaignId,
  currency,
  extraCampaign,
}: IProps) {
  const { isPartnerSite } = useStoreAppValue();
  const [selectedCampaign, setselectedCampaign] = useState<TSelectedCampaign>(null);
  // const { fundraiserId } = useStoreAppValue();

  // const { joinedFundraisers, name, photoImage } = campaign;
  useEffect(() => {
    if (!selectedCampaignId) {
      setselectedCampaign(null);
      return;
    }
    if (!!extraCampaign && selectedCampaignId === extraCampaign.id) {
      setselectedCampaign(extraCampaign);
      return;
    }
    const foundCampaign = viewer.runningCampaigns.find(
      (campaign) => campaign.id === selectedCampaignId
    );
    if (!foundCampaign) return;
    setselectedCampaign(foundCampaign);
  }, [selectedCampaignId, setselectedCampaign]); // eslint-disable-line react-hooks/exhaustive-deps

  const campaignsByCurrency = useMemo(() => {
    if (!currency) return viewer.runningCampaigns;
    return viewer.runningCampaigns.filter(
      (campaign) => campaign.currency === currency && campaign.id !== extraCampaign?.id
    );
  }, [viewer, currency]);
  const emptyLabel = isPartnerSite
    ? `${possessive(viewer.name ?? "")} Personal Account`
    : "No Team Selected";
  return (
    <Menu>
      <Flex pb={1} position="relative">
        <EMenuButton>
          <EInput disabled value={selectedCampaign ? selectedCampaign.name : emptyLabel} />
          <ESpan aria-hidden>▾</ESpan>

          {selectedCampaign ? (
            <ESelectedCircleImage alignItems="center">
              <Circle>
                <Image
                  alt={selectedCampaign.name}
                  src={`${selectedCampaign.photoImage}&ar=1&fit=crop`}
                />
              </Circle>
            </ESelectedCircleImage>
          ) : (
            <ESelectedCircleImage alignItems="center">
              <Circle>
                <Image alt={viewer.name} src={`${viewer?.avatarImage}&ar=1&fit=crop`} />
              </Circle>
            </ESelectedCircleImage>
          )}
        </EMenuButton>
      </Flex>
      <EMenuList className="slide-down">
        {!!extraCampaign && (
          <MenuItem
            key={`${extraCampaign.id}-item`}
            onSelect={() => {
              onCampaignSelect(extraCampaign.id);
            }}
          >
            <ImageListItem
              leftComponent={CampaignCircleImage(extraCampaign.name, extraCampaign.photoImage)}
              text={extraCampaign.name}
            />
          </MenuItem>
        )}
        {campaignsByCurrency.map((campaign) => {
          return (
            <MenuItem
              key={`${campaign.id}-item`}
              onSelect={() => {
                onCampaignSelect(campaign.id);
              }}
            >
              <ImageListItem
                leftComponent={CampaignCircleImage(campaign.name, campaign.photoImage)}
                text={campaign.name}
              />
            </MenuItem>
          );
        })}

        {(!currency || currency === viewer.currency) && isPartnerSite && (
          <MenuItem
            onSelect={() => {
              onCampaignSelect("");
            }}
          >
            <ImageListItem
              leftComponent={CampaignCircleImage(viewer.name, viewer.avatarImage)}
              text={emptyLabel}
            />
          </MenuItem>
        )}
        {!!currency && currency !== viewer.currency && campaignsByCurrency.length === 0 && (
          <MenuItem
            onSelect={() => {
              window.location.href = RailsUrl.createTeamUrl();
            }}
          >
            <Text ellipsis color="default" display="block" fontSize={2} lineHeight="solid" py={2}>
              Create new team
            </Text>
          </MenuItem>
        )}
      </EMenuList>
    </Menu>
  );
}

// == Styles ===============================================================
const IMAGE_CIRCLE_WIDTH = 35;
const CIRCLE_LEFT_PADDING = 10;

const EMenuList = styled(MenuList)`
  z-index: 9000;
  position: relative;
  overflow-y: auto;
  max-height: 200px;
  > [data-reach-menu-item]:hover {
    background: ${({ theme }) => theme.colors.primary};
  }
  > [data-reach-menu-item][data-selected] {
    background: ${({ theme }) => theme.colors.primary};
  }
  > [data-reach-menu-item][data-selected] span {
    color: white !important;
  }
  > [data-reach-menu-item]:hover span {
    color: white !important;
  }
`;

const EMenuButton = styled(MenuButton)`
  padding: 0;
  width: 300px;
  display: block;
  background: none;
  border: none;
  position: relative;
`;

const EInput = styled.input`
  margin-bottom: 0 !important;
  padding-top: 0px !important;
  border-color: ${({ theme }) => theme.colors.gray}!important;
  color: ${({ theme }) => theme.colors.default}!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 24px;
  padding-left: ${CIRCLE_LEFT_PADDING + IMAGE_CIRCLE_WIDTH + 5}px!important;
`;

const ESpan = styled.span`
  position: absolute;
  right: 0;
  top: 33%;
  margin-right: 10px;
`;

const EText = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 10px;
`;

const ESelectedCircleImage = styled(Flex)`
  position: absolute;
  left: ${CIRCLE_LEFT_PADDING}px;
  top: ${CIRCLE_LEFT_PADDING + 2}px;
  width: ${IMAGE_CIRCLE_WIDTH}px;
`;
