import React from "react";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { Box } from "@atoms";
import { CollectionLinkPromotions } from "../CollectionLinkPromotions";
import { OccasionQuery_Occasion_collections_edges_node_links as ILink } from "../../../pages/occasionComponents/OccasionView/__generated__/OccasionQuery";
import { CollectionThreeUp } from "../CollectionThreeUp";
import { CollectionTwoUp } from "../CollectionTwoUp";
import { CollectionFourUp } from "../CollectionFourUp";
import { CollectionCollage } from "../CollectionCollage";
import { CollectionGrid } from "../CollectionGrid";
import { CollectionTitle } from "../CollectionTitle";
import { CollectionCarousel } from "../CollectionCarousel";
import { CollectionDepartmentGrid } from "../CollectionDepartmentGrid";
import { CollectionFeatured } from "../CollectionFeatured";
import { CollectionBanner } from "../CollectionBanner";
import { CollectionGridQuarterBanner } from "../CollectionGridQuarterBanner";
import { CollectionBrandCarousel } from "../CollectionBrandCarousel";
import { CollectionSingle } from "../CollectionSingle";
import CollectionNearbyBrandsQuery from "../CollectionNearbyBrands/CollectionNearbyBrandsQuery";
import { CollectionRenderFragment } from "./__generated__/CollectionRenderFragment";
import CollectionSportsCategory from "../CollectionSportsCategory/CollectionSportsCategory";

// == Types ================================================================

export interface IAssetsType {
  description: string | null;
  destination: string | null;
  image: string | null;
  title: string | null;
}

export interface ICollectionRenderProps {
  collection: {
    style: string | null;
    assets?: IAssetsType[];
    links: (ILink | null)[];
  };
}

interface IProps {
  collection: CollectionRenderFragment;
  showTopSpacing: boolean;
  storefrontId?: string;
}

// == Constants ============================================================

export const COLLECTION_TOP_SPACING = [4, 4, "40px"];

const FRAGMENTS = {
  fields: gql`
    fragment CollectionRenderFragment on Collection {
      id
      name
      style
      description
      headerDescription
      headerImage
      linksCount
      showTitle
      links(first: $carouselItems) {
        id
        type
        giftcardType
        name
        image
        logoImage
        giveSentence
        terms
        promoLabel
        promoDescription
        promoSecondaryLabel
        promoEndsAt
      }
      ...CollectionFourUpFragment
      ...CollectionThreeUpFragment
      ...CollectionBrandCarouselFragment
      ...CollectionCarouselFragment
    }
    ${CollectionFourUp.fragments.fields}
    ${CollectionThreeUp.fragments.fields}
    ${CollectionBrandCarousel.fragments.fields}
    ${CollectionCarousel.fragments.fields}
  `,
};

const ACCEPTED_COLLECTIONS = [
  "FEATURED",
  "GRID",
  "CAROUSEL",
  "COLLAGE",
  "TWO_UP",
  "THREE_UP",
  "FOUR_UP",
  "BANNER",
  "BANNER_CAROUSEL",
  "BRAND_CAROUSEL",
  "DEPARTMENT_GRID",
  "GRID_QUARTER_BANNER",
  "GRID_HALF_BANNER",
  "SINGLE",
  "NEARBY_BRANDS",
  "LINK_PROMOTIONS",
  "SPORTS_BRANDS",
];

// == Component ============================================================

const renderCollectionTitle = (collection: CollectionRenderFragment, storefrontId?: string) => {
  if (!collection.showTitle) return null;

  const onCollectionPress = () => {
    // *** use a reach-router NAVIGATE here instead?
    // window.location.href = `/collections/${collection.id}`;
    navigate(`/collections/${collection.id}`);
  };

  if (["NEARBY_BRANDS", "LINK_PROMOTIONS", "SPORTS_BRANDS"].includes(collection?.style ?? ""))
    return null;
  if (collection?.style === "BRAND_CAROUSEL" && collection?.brands?.edges?.length === 0)
    return null;
  if (["GRID", "CAROUSEL", "FEATURED"].includes(collection?.style ?? "")) {
    return (
      <CollectionTitle
        collectionId={collection.id}
        count={collection.linksCount}
        title={collection.name ?? undefined}
        onTitlePress={onCollectionPress}
        storefrontId={storefrontId}
      />
    );
  }

  if (collection?.style === "BRAND_CAROUSEL" && (collection?.brands?.edges?.length ?? 0) > 0) {
    return (
      <CollectionTitle
        collectionId={collection.id}
        count={collection?.brands?.edges?.length}
        title={collection.name ?? undefined}
        onTitlePress={onCollectionPress}
        storefrontId={storefrontId}
      />
    );
  }

  return (
    <CollectionTitle
      collectionId={collection.id}
      title={collection.name ?? undefined}
      onTitlePress={onCollectionPress}
      storefrontId={storefrontId}
    />
  );
};

const renderCollection = (collection: CollectionRenderFragment, storefrontId?: string) => {
  switch (collection?.style) {
    // *** <MultiItemCarousel /> then <Link Block />
    case "LINK_PROMOTIONS":
      return <CollectionLinkPromotions collection={collection} storefrontId={storefrontId} />;
    case "CAROUSEL":
      return <CollectionCarousel collection={collection} storefrontId={storefrontId} />;
    case "GRID":
      return <CollectionGrid collection={collection} storefrontId={storefrontId} />;

    // *** <Brand Block />
    case "NEARBY_BRANDS":
      return <CollectionNearbyBrandsQuery collection={collection} />;

    case "SPORTS_BRANDS":
      return <CollectionSportsCategory collectionId={collection.id} storefrontId={storefrontId} />;

    // *** <MultiItemCarousel /> then <BrandBlock />
    case "BRAND_CAROUSEL":
      return <CollectionBrandCarousel collection={collection} storefrontId={storefrontId} />;

    // *** <CollectionContentItem />
    case "COLLAGE":
      return <CollectionCollage collection={collection} storefrontId={storefrontId} />;
    case "TWO_UP":
      return <CollectionTwoUp collection={collection} storefrontId={storefrontId} />;
    case "THREE_UP":
      return <CollectionThreeUp collection={collection} storefrontId={storefrontId} />;
    case "FOUR_UP":
      return <CollectionFourUp collection={collection} storefrontId={storefrontId} />;

    // *** <DepartmentItem />
    case "DEPARTMENT_GRID":
      return <CollectionDepartmentGrid collection={collection} storefrontId={storefrontId} />;

    // *** <LinkBlockFeatured />
    case "FEATURED":
      return <CollectionFeatured collection={collection} storefrontId={storefrontId} />;

    // *** <Carousel /> then <CollectionContentItem />
    case "BANNER_CAROUSEL":
    case "BANNER":
      return <CollectionBanner collection={collection} storefrontId={storefrontId} />;

    // *** <CollectionContentItem /> then mapped <LinkBlock />
    case "GRID_QUARTER_BANNER":
      return <CollectionGridQuarterBanner collection={collection} storefrontId={storefrontId} />;

    // *** self contained render *** <CollectionSingle />
    case "SINGLE":
      return <CollectionSingle collection={collection} storefrontId={storefrontId} />;
    default:
      return null;
  }
};

const CollectionRender = ({ collection, showTopSpacing, storefrontId }: IProps) => {
  if (!ACCEPTED_COLLECTIONS.includes(collection?.style ?? "")) return null;

  return (
    <Box display="block" mt={showTopSpacing ? COLLECTION_TOP_SPACING : 0}>
      {renderCollectionTitle(collection, storefrontId)}
      {renderCollection(collection, storefrontId)}
    </Box>
  );
};

CollectionRender.fragments = FRAGMENTS;

export default CollectionRender;
