import React, { useState } from "react";
import { navigate } from "@reach/router";
// import styled from "@emotion/styled";
import { string, object, boolean } from "yup";
import { Formik, Form } from "formik";
import gql from "graphql-tag";
import { useCookies } from "react-cookie";
import isEmpty from "lodash/isEmpty";
import transform from "lodash/transform";
import {
  Button,
  Text,
  Box,
  Row,
  Column,
  H2,
  H3,
  ClearButton,
  Flex,
  Image,
} from "@components/common/atoms";
import {
  CenteredBoxLayout,
  SelectField,
  TextField,
  CheckboxField,
} from "@components/common/molecules";
import { useStoreAppValue } from "@hooks";
import { RailsUrl, constants, spelling } from "@services";
import { BeneficiaryTeamType, PaymentType, Country, Currency } from "@types";
import { BeneficiaryCheque } from "../BeneficiaryCheque";
import { BeneficiaryTransfer } from "../BeneficiaryTransfer";
import BeneficiaryAccountInfo from "../BeneficiaryAccountInfo/BeneficiaryAccountInfo";
import { CampaignBeneficiaryQuery_Campaign_beneficiary } from "./__generated__/CampaignBeneficiaryQuery";

// == Types ================================================================

interface IProps {
  beneficiary: CampaignBeneficiaryQuery_Campaign_beneficiary | null;
}

// == Constants ============================================================

BeneficiaryForm.defaultProps = {};

type TTeamType = "" | BeneficiaryTeamType;

export interface IDeliveryFormikValues {
  paymentType: PaymentType;
  city: string;
  country: string;
  phoneNumber: string;
  postalCode: string;
  province: string;
  street1: string;
  street2: string;
  email: string;
}

// == Constants ============================================================

export const BENEFICIARY_CREATE_MUTATION = gql`
  mutation BeneficiaryCreateMutation(
    $input: BeneficiaryCreateInput!
    $organization: BeneficiaryOrganizationInput
  ) {
    BeneficiaryCreate(input: $input, organization: $organization) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Beneficiary {
        id
        hwUserToken
        hwUserStatus
        hwUserVerificationStatus
        plaidAccountInfo
        paymentType
      }
    }
  }
`;

const INITIAL_VALUES = {
  street1: "",
  street2: "",
  city: "",
  province: "",
  postalCode: "",
  country: "",
  phoneNumber: "",
  email: "",
  legalName: "",
  firstName: "",
  lastName: "",
  contactName: "",
  confirm: false,
};

const VALIDATION_SCHEMA = object().shape({
  city: string()
    .max(
      constants.BENEFICIARY_VALIDATIONS.MAX_CITY,
      `Please do not enter more than ${constants.BENEFICIARY_VALIDATIONS.MAX_CITY} characters`
    )
    .matches(/^[A-Za-z\s&'()-]+$/, { message: "Please enter a valid first name" })
    .trim()
    .required("City is required"),
  country: string().required("Country is required"), // even tho this is sent from the page already
  postalCode: string()
    .trim()
    .when("country", {
      is: Country.USA,
      then: string().matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
        message: "Must be in format 12345",
        excludeEmptyString: true,
      }),
    })
    .when("country", {
      is: Country.CANADA,
      then: string().matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, {
        message: "Must be in format L1L 4L4",
        excludeEmptyString: true,
      }),
    })
    .required("Required"),
  province: string()
    .required("Required")
    .max(
      constants.BENEFICIARY_VALIDATIONS.MAX_PROVINCE,
      `Please do not enter more than ${constants.BENEFICIARY_VALIDATIONS.MAX_PROVINCE} characters`
    )
    .matches(/^[A-Za-z\s&'()-]+$/, { message: "Please enter a valid first name" }),
  street1: string()
    .required("Address is required")
    .max(
      constants.BENEFICIARY_VALIDATIONS.MAX_STREET_1,
      `Please do not enter more than ${constants.BENEFICIARY_VALIDATIONS.MAX_STREET_1} characters`
    )
    .matches(/^[a-zA-Z0-9\s#'(),-./:;]+$/, {
      message: "Please only include # ' ( ) , - . / : ; as special characters.",
    }),
  street2: string()
    .max(
      constants.BENEFICIARY_VALIDATIONS.MAX_STREET_1,
      `Please do not enter more than ${constants.BENEFICIARY_VALIDATIONS.MAX_STREET_1} characters`
    )
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: "Please do not include special characters.",
      excludeEmptyString: true,
    }),
  phoneNumber: string()
    .typeError("Phone number is required")
    .max(
      constants.BENEFICIARY_VALIDATIONS.MAX_PHONE_NUMBER,
      `Please do not enter more than ${constants.BENEFICIARY_VALIDATIONS.MAX_PHONE_NUMBER} characters`
    )
    .matches(/^[0-9 ().+\-)]+$/, { message: "Please enter a valid phone number" })
    .required("Phone number is required"),
  email: string()
    .typeError("Email is required")
    .required("Email is required")
    .email("Email is not formatted correctly")
    .nullable(false),
  contactName: string().required("Contact name is required"),
  confirm: boolean()
    .required("Please confirm")
    .oneOf([true], "Please confirm that all the fields are correct."),
  plaidPublicToken: string()
    .when("paymentType", {
      is: PaymentType.HW_BANK_TRANSFER,
      then: string().required("Required"),
    })
    .nullable(),
  plaidAccountId: string()
    .when("paymentType", {
      is: PaymentType.HW_BANK_TRANSFER,
      then: string().required("Required"),
    })
    .nullable(),
});

const payeeNameLabel = (teamType: TTeamType, currency: Currency) => {
  switch (teamType) {
    case BeneficiaryTeamType.CHARITY:
      return `${spelling("checks", {
        country: `${currency === Currency.USD ? "USA" : "Canada"}`,
        capitalize: true,
        singular: true,
      })} Legal Name`;
    case BeneficiaryTeamType.ORGANIZATION:
      return `${spelling("checks", {
        country: `${currency === Currency.USD ? "USA" : "Canada"}`,
        capitalize: true,
        singular: true,
      })} Legal Name`;
    default:
      return `${spelling("checks", {
        country: `${currency === Currency.USD ? "USA" : "Canada"}`,
        capitalize: true,
        singular: true,
      })} Legal Name`;
  }
};

// == Component ============================================================

export default function BeneficiaryForm({ beneficiary }: IProps) {
  const { campaignId, currency } = useStoreAppValue();
  const isCanada = currency === "CAD";
  const postalCodeLabel = isCanada ? "Postal Code" : "Zip Code";
  const [cookies] = useCookies(["beneficiaryType", "beneficiaryOrg"]);

  const [plaidValues, setPlaidValues] = useState({});

  const onSubmit = (values, actions) => {
    const newValues = transform(values, (result, value, key) => {
      if (typeof value === "string") {
        result[key] = value.trim();
      }
    });
    const { confirm, ...rest } = newValues;
    const input = {
      ...cookies.beneficiaryType,
      ...rest,
      legalName:
        values.paymentType === PaymentType.HW_BANK_TRANSFER
          ? plaidValues?.account?.name || values.contactName
          : values.legalName,
    };
    if (
      beneficiary?.id ||
      (values.hwUserToken && values.hwUserVerificationStatus === "NOT_REQUIRED")
    ) {
      const {
        __typename,
        hwUserToken,
        hwUserVerificationStatus,
        plaidAccountInfo,
        hwAccountInfo,
        hwAccountToken,
        ...updateInput
      } = input;
      navigate(RailsUrl.beneficiarySuccessUrl(campaignId), {
        replace: true,
        state: {
          variables: {
            input: { ...updateInput, id: values.id },
            organization:
              values.teamType === BeneficiaryTeamType.INDIVIDUAL ? null : cookies.beneficiaryOrg,
          },
        },
      });
    } else {
      navigate(RailsUrl.beneficiarySuccessUrl(campaignId), {
        replace: true,
        state: {
          variables: {
            input,
            organization:
              values.teamType === BeneficiaryTeamType.INDIVIDUAL ? null : cookies.beneficiaryOrg,
          },
        },
      });
    }
  };

  const initialInputValues = {
    ...INITIAL_VALUES,
    ...(beneficiary || null),
    ...(cookies.beneficiaryType || null),
    plaidAccountId:
      beneficiary?.plaidAccountId && beneficiary?.paymentType === PaymentType.HW_BANK_TRANSFER
        ? beneficiary.plaidAccountId
        : "",
  };

  const hwAccountInfo = JSON.parse(
    beneficiary?.hwAccountInfo && beneficiary?.paymentType === PaymentType.HW_BANK_TRANSFER
      ? beneficiary.hwAccountInfo
      : null
  );
  const plaidAccountInfo = JSON.parse(
    beneficiary?.plaidAccountInfo && beneficiary?.paymentType === PaymentType.HW_BANK_TRANSFER
      ? beneficiary.plaidAccountInfo
      : null
  );

  const initialValues = () => {
    const values = {
      ...initialInputValues,
      country: isCanada ? Country.CANADA : Country.USA,
    };
    Object.keys(values).map((key) => {
      if (values[key] === null) {
        values[key] = "";
      }
    });
    return values;
  };

  return (
    <CenteredBoxLayout p={[4, 4, 5]}>
      {initialInputValues.paymentType !== PaymentType.HW_BANK_TRANSFER ? (
        <>
          <H2 mb={4} textAlign="center">
            Payment By{" "}
            {spelling("checks", {
              country: `${currency === "USD" ? "USA" : "Canada"}`,
              capitalize: true,
              singular: true,
            })}
          </H2>
          <H3 as="p" color="black" fontWeight="bold" mb={2}>
            Recipient
          </H3>
          <Text as="p" mb={3}>
            Who should the{" "}
            {spelling("checks", {
              country: `${currency === "USD" ? "USA" : "Canada"}`,
              capitalize: false,
              singular: true,
            })}{" "}
            be made out to?
          </Text>
        </>
      ) : (
        <>
          <H2 mb={4} textAlign="center">
            Payment By Bank Transfer
          </H2>
          <H3 as="p" color="black" fontWeight="bold" mb={2}>
            Bank Account
          </H3>
          <Text as="p" mb={3}>
            {!isEmpty(plaidValues) || plaidAccountInfo || hwAccountInfo
              ? "Your funds will be deposited in the following bank account. "
              : "Sign in to your bank to instantly verify your bank account. "}
            FlipGive uses Plaid to verify your bank account information and send funds to the right
            account. FlipGive can’t withdraw money from your account in any shape or form.
          </Text>
        </>
      )}
      <Formik
        initialValues={initialValues()}
        validateOnBlur={false}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, actions) => {
          onSubmit(values, actions);
        }}
      >
        {({ values, isSubmitting, setFieldValue, errors }) => {
          return (
            <Form>
              {values.paymentType !== PaymentType.HW_BANK_TRANSFER ? (
                <>
                  <BeneficiaryCheque name={values.legalName} />
                  {initialInputValues.teamType === BeneficiaryTeamType.INDIVIDUAL ? (
                    <Text as="p" fontSize={0} fontStyle="italic" mb={3}>
                      Legal name that payments will be made out to.
                    </Text>
                  ) : (
                    <Text as="p" fontSize={0} fontStyle="italic" mb={3}>
                      Please note payments to club / league / school / non-profit teams cannot be
                      made out to an individual; if you want payment to be made to an individual go
                      back and choose a family / individual / other team.
                    </Text>
                  )}
                  <TextField
                    label={payeeNameLabel(values.teamType, currency)}
                    name="legalName"
                    placeholder={payeeNameLabel(values.teamType, currency)}
                  />
                </>
              ) : (
                <>
                  {isEmpty(plaidValues) && plaidAccountInfo && !beneficiary.hwAccountToken && (
                    <BeneficiaryAccountInfo plaidAccountInfo={plaidAccountInfo} />
                  )}
                  {isEmpty(plaidValues) && hwAccountInfo && beneficiary?.hwAccountToken && (
                    <BeneficiaryAccountInfo hwAccountInfo={hwAccountInfo} />
                  )}
                  <BeneficiaryTransfer
                    accountInfoExist={!isEmpty(plaidValues) || plaidAccountInfo || hwAccountInfo}
                    isSubmitting={isSubmitting}
                    loading={false}
                    plaidValues={plaidValues}
                    setFieldValue={setFieldValue}
                    setPlaidValues={setPlaidValues}
                  />
                  <Text as="p" fontSize={0} fontStyle="italic" mb={3}>
                    Your financial information is encrypted and stored securely on our servers. We
                    use trusted and highly secure partners to validate your information.{" "}
                    {initialInputValues.teamType !== BeneficiaryTeamType.INDIVIDUAL &&
                      "The legal name field below should be made to the team / club / league / school / non-profit you are raising funds for. If otherwise, payment should be made out to the team captain/owner."}
                  </Text>
                  <Flex justifyContent="center" mt={3}>
                    <Image alt="ssl" src="https://flipgive.twic.pics/beneficiary/ssl.png" />
                  </Flex>
                  <Text as="p" fontSize={0} px={5} textAlign="center">
                    Our 256-bit SSL/TLS certificate encrypts all information transmitted between
                    your browser and our web servers.
                  </Text>
                </>
              )}
              <TextField name="plaidPublicToken" placeholder="Plaid Public Token" type="hidden" />
              <TextField name="plaidAccountId" placeholder="Plaid Account ID" type="hidden" />

              <H3 as="p" color="black" fontWeight="bold" mb={2} mt={4}>
                {values.paymentType !== PaymentType.HW_BANK_TRANSFER
                  ? "Mailing Address"
                  : "Personal Info"}
              </H3>
              <Text as="p" mb={3}>
                {values.paymentType !== PaymentType.HW_BANK_TRANSFER
                  ? `Let us know where your ${spelling("checks", {
                      country: `${currency === "USD" ? "USA" : "Canada"}`,
                      capitalize: false,
                      singular: true,
                    })} should be delivered.`
                  : "Please provide some additional info so that we can verify your identity for the bank transfer."}
              </Text>
              <TextField label="Contact Name" name="contactName" placeholder="Contact Name" />
              <TextField
                label="Street Address"
                name="street1"
                placeholder="Street address, P.O. box, company name, c/o"
              />
              <TextField
                label="Apt., Suite, Bldg. (optional)"
                name="street2"
                placeholder="Apartment, suite, unit, building, floor, etc."
              />
              <Row>
                <Column>
                  <TextField label="City" name="city" placeholder="City" />
                </Column>
                <Column>
                  <TextField disabled label="Country" name="country" />
                </Column>
              </Row>
              <Row>
                <Column>
                  {!isCanada ? (
                    <SelectField label="State" name="province">
                      <>
                        <option value="">Please select a state</option>
                        {constants.STATES.map((state) => {
                          return (
                            <option key={state[0]} value={state[0]}>
                              {state[1]}
                            </option>
                          );
                        })}
                      </>
                    </SelectField>
                  ) : (
                    <SelectField label="Province" name="province">
                      <>
                        <option value="">Please select a province</option>
                        {constants.PROVINCES.map((province) => {
                          return (
                            <option key={province[0]} value={province[0]}>
                              {province[1]}
                            </option>
                          );
                        })}
                      </>
                    </SelectField>
                  )}
                </Column>
                <Column>
                  <TextField
                    label={postalCodeLabel}
                    name="postalCode"
                    placeholder={postalCodeLabel}
                  />
                </Column>
              </Row>
              <TextField label="Phone Number" name="phoneNumber" placeholder="Phone Number" />
              <TextField label="Email" name="email" placeholder="example@domain.com" />
              <CheckboxField
                label="I confirm the above information is accurate and correct."
                name="confirm"
              />
              <Box>
                <Button disabled={isSubmitting} mb={3} type="submit" width={1}>
                  {isSubmitting ? "Submitting..." : "Submit Payment Recipient Details"}
                </Button>
              </Box>
              {!isEmpty(errors) && (errors?.plaidAccountId || errors?.plaidAccountToken) && (
                <Text color="danger" display="block" fontSize="0.8rem" mb={3} mt="-12px">
                  Please link a bank account before submitting.
                </Text>
              )}
              <ClearButton
                disabled={isSubmitting}
                type="button"
                width={1}
                onClick={() => navigate(RailsUrl.beneficiaryIntroUrl(campaignId))}
              >
                Back
              </ClearButton>
            </Form>
          );
        }}
      </Formik>
    </CenteredBoxLayout>
  );
}

// == Styles ===============================================================
