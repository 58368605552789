import React, { useState, useRef } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { RouteComponentProps } from "@reach/router";
import styled from "@emotion/styled";
import MaskedInput from "react-text-mask";
import { addMonths, differenceInCalendarMonths, startOfMonth } from "date-fns";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { Box, H1, Flex, Text, RangeInput, MonthInput } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { analytics } from "@services";
import { OnboardingContainer } from "../components";
import { OnboardingGoalSubmit } from "./components";
import { OnboardingGoalQuery } from "./__generated__/OnboardingGoalQuery";

// == Types ================================================================

// type IProps = RouteComponentProps;

// == Constants ============================================================

OnboardingGoal.defaultProps = {};

// == Component ============================================================

const ONBOARDING_GOAL_QUERY = gql`
  query OnboardingGoalQuery {
    Viewer {
      id
      onboarding {
        id
        earningsEstimate {
          dollarsAmount
          centsAmount
          formatted
        }
      }
    }
  }
`;

const DEFAULT_TEAM_MEMBERS = 10;
const NUMBER_MASK = createNumberMask({
  prefix: "$",
  suffix: "",
});

const DEFAULT_DATE = addMonths(new Date(), 8);
const MIN_DATE = startOfMonth(addMonths(new Date(), 1));
const DEFAULT_EARNINGS_ESTIMATE = 500;

// == Component ============================================================

export function OnboardingGoal(_props: RouteComponentProps) {
  const goalInput = useRef(null);
  const [hasTrackedSetGoal, setHasTrackedGoal] = useState(false);
  const [hasTrackedSetTeamMembers, setHasTrackedSetTeamMembers] = useState(false);
  const { campaignId, isOwner } = useStoreAppValue();
  const [teamGoal, setTeamGoal] = useState(0);
  const [endDate, setEndDate] = useState(DEFAULT_DATE);
  const [numTeamMembers, setNumTeamMembers] = useState<number>(DEFAULT_TEAM_MEMBERS);

  const trackSetDeadline = () => {
    analytics.track.onboardingSetDeadline({ campaignId }, isOwner);
  };

  const { data, error, loading } = useQuery<OnboardingGoalQuery>(ONBOARDING_GOAL_QUERY, {
    onCompleted: (_onCompleteData) => {
      const earningsEstimate = data?.Viewer.onboarding?.earningsEstimate?.dollarsAmount
        ? Math.floor(data?.Viewer.onboarding?.earningsEstimate?.dollarsAmount / 12)
        : DEFAULT_EARNINGS_ESTIMATE;
      setTeamGoal(earningsEstimate * DEFAULT_TEAM_MEMBERS * diffMonths);
    },
  });

  const viewer = data?.Viewer;
  const earningsEstimate = data?.Viewer.onboarding?.earningsEstimate?.dollarsAmount
    ? Math.floor(data?.Viewer.onboarding?.earningsEstimate?.dollarsAmount / 12)
    : DEFAULT_EARNINGS_ESTIMATE;
  const diffMonths = differenceInCalendarMonths(endDate, new Date());

  const onRangeChange = (values: number[]) => {
    setNumTeamMembers(values[0]);
    setTeamGoal(values[0] * earningsEstimate * diffMonths);

    if (!hasTrackedSetTeamMembers) {
      setHasTrackedSetTeamMembers(true);
      analytics.track.onboardingSetTeamSize({ campaignId }, isOwner);
    }
  };

  const onInputChange = (event) => {
    const newValue = parseInt(event.target.value.replace(/\$|,/g, ""), 10) || "";
    setTeamGoal(newValue);
  };

  const trackSetCustomGoal = () => {
    if (!hasTrackedSetGoal) {
      setHasTrackedGoal(true);
      analytics.track.onboardingSetCustomGoal({ campaignId }, isOwner);
    }
  };

  const onEnterCustomGoal = (_event) => {
    setTeamGoal("");
    goalInput.current?.inputElement?.focus();
    trackSetCustomGoal();
  };

  const onBlurGoalInput = (_event) => {
    if (teamGoal === "") {
      setTeamGoal(numTeamMembers * earningsEstimate * diffMonths);
    }
    trackSetCustomGoal();
  };

  const onChangeEndDate = (event) => {
    const newDiffMonth = differenceInCalendarMonths(event, new Date());
    setEndDate(event);
    setTeamGoal(newDiffMonth * earningsEstimate * numTeamMembers);
    trackSetDeadline();
  };

  return (
    <OnboardingContainer hasError={!!error || !viewer} loading={loading}>
      {/* === Header section begins === */}
      <Box pb={4} textAlign="center">
        <H1 mx={4} pb={4}>
          Set Your Team Funding Goal
        </H1>
        {/* === Goal input begins === */}
        <Text color="em" fontSize={1} lineHeight="1">
          Your team can raise an estimated
        </Text>
        <Box
          border="lightGraySolid"
          borderRadius="5px"
          lineHeight="1"
          mx={4}
          my={1}
          py={2}
          textAlign="center"
        >
          <MaskedInput
            guide
            mask={NUMBER_MASK}
            maxLength={8}
            placeholder="Enter a goal"
            ref={goalInput}
            render={(ref, props) => <EGoalInput ref={ref} {...props} />}
            value={teamGoal}
            onBlur={onBlurGoalInput}
            onChange={onInputChange}
          />
          <EText fontSize={0} onClick={onEnterCustomGoal}>
            click to enter a custom goal
          </EText>
        </Box>
        <Text color="em" fontSize={1} lineHeight="1">
          in{" "}
          <Text color="primary" fontWeight="bold">
            {diffMonths} months
          </Text>{" "}
          with{" "}
          <Text color="primary" fontWeight="bold">
            {numTeamMembers} active
          </Text>{" "}
          teammates.
        </Text>
        {/* === Goal input ends === */}
      </Box>
      {/* === Header section ends=== */}
      {/* === Form section begins === */}
      <Box pb={4} px={4} textAlign="center">
        {/* === Month input begins === */}
        <Text color="em" fontSize={1} lineHeight="1">
          This team will be active until
        </Text>
        <Flex alignItems="center" flexDirection="column" justifyContent="center" pb={2} pt={1}>
          <EMonthInput minDate={MIN_DATE} selected={endDate} onChange={onChangeEndDate} />
        </Flex>
        {/* === Month input ends === */}
        {/* === TeamMember Range begins === */}
        <Text color="em" fontSize={1} lineHeight="1" textAlign="center">
          We will have <Text fontWeight="bold">{numTeamMembers}</Text> team members
        </Text>
        <RangeInput max={30} min={1} pt={1} values={[numTeamMembers]} onChange={onRangeChange} />
        {/* === TeamMember Range ends === */}
      </Box>
      {/* === Form section ends === */}
      <Box textAlign="center">
        <Text color="subText" fontSize={0}>
          Your goal is an estimation, not a guarantee. You can edit your goal in team settings.
        </Text>
        <OnboardingGoalSubmit
          endDate={endDate}
          numTeamMembers={numTeamMembers}
          teamGoal={teamGoal}
        />
      </Box>
    </OnboardingContainer>
  );
}

// == Styles ===============================================================

const EGoalInput = styled.input`
  padding: 0 !important;
  background-color: transparent !important;
  margin: 0 !important;
  height: 35px !important;
  line-height: 1;
  border: 0 !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes[5]}px!important;
  font-weight: bold;
  &:active,
  &:focus {
    border-bottom: 0 !important;
    box-shadow: none !important;
  }
`;

const EMonthInput = styled(MonthInput)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  padding-top: 0 !important;
  text-align: center;
  height: 40px !important;
  padding-left: 0 !important;
  width: 125px !important;
`;

const EText = styled(Text)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.subText};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;
