import React from "react";
import { Container, H2, TouchableOpacity, ChevronLeft, Flex } from "@atoms";
import { EventEditForm, EventEditInformation } from "@organisms";
import { RailsUrl } from "@services";
import { useStoreAppValue } from "@hooks";
import { Event_Event } from "./__generated__/Event";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  event: Event_Event;
}

// == Constants ============================================================

EventEditView.defaultProps = {};

// == Component ============================================================

export default function EventEditView({ event }: IProps) {
  const { campaignId } = useStoreAppValue();
  return (
    <Container maxWidth="600px" mt={4} mb={[5, 4]}>
      <Flex pl={3} mb={3}>
        <TouchableOpacity
          onTouch={() =>
            (window.location.href = RailsUrl.eventsUrl({ campaignId, eventId: event.id }))
          }
        >
          <ChevronLeft color="primary" />
        </TouchableOpacity>
        <H2 ml={3}>Update Event</H2>
      </Flex>
      <EventEditInformation event={event}></EventEditInformation>
      <EventEditForm event={event}></EventEditForm>
    </Container>
  );
}

// == Styles ===============================================================
