// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  positiveGiveAmount: boolean;
  baselineGiveAmount: string;
  giveSentence: string;
}

// == Constants ============================================================

// == Component ============================================================

const GiveSentence = ({ positiveGiveAmount, baselineGiveAmount, giveSentence }: IProps) => {
  return positiveGiveAmount ? giveSentence : `Gives ${baselineGiveAmount}`;
};

export default GiveSentence;

// == Styles ===============================================================
