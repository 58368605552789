import React, { useMemo } from "react";
import gql from "graphql-tag";
import { orderBy } from "lodash";
import { renderIf, RailsUrl } from "@services";
import { useStoreAppValue } from "@hooks";
import { ContentBlock, UserListItem, ImageListItem, CircleText, CircleAddIcon } from "@molecules";
import { MyTeamSidebarFragment } from "./__generated__/MyTeamSidebarFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  fundraisers: MyTeamSidebarFragment[];
}

// == Constants ============================================================

// IM NOT CURRENTLY BEING USED :(
const FRAGMENTS = gql`
  fragment MyTeamSidebarFragment on Fundraiser {
    id
    name
    avatarImage
    amountRaised {
      dollarsAmount
    }
    isOwner
  }
`;

const NUM_FUNDRAISERS_TO_DISPLAY = 6;

// == Component ============================================================

const MyTeamSidebar = ({ fundraisers }: IProps) => {
  const { campaignId } = useStoreAppValue();

  const onInviteTeamTouch = () => {
    window.location.href = RailsUrl.inviteTeamUrl(campaignId);
  };

  const onMoreTouch = () => {
    window.location.href = RailsUrl.membersUrl(campaignId);
  };

  const orderedFundraisers = useMemo(
    () => orderBy(fundraisers, "amountRaised.dollarsAmount", "desc"),
    [fundraisers]
  );
  const slicedFundraisers = orderedFundraisers.slice(0, NUM_FUNDRAISERS_TO_DISPLAY);
  const overflowFundraiserCount = fundraisers.length - NUM_FUNDRAISERS_TO_DISPLAY;

  return (
    <ContentBlock title="My Team">
      <ImageListItem
        leftComponent={<CircleAddIcon />}
        text="Invite Teammates"
        onTouch={onInviteTeamTouch}
      />
      {slicedFundraisers.map((fundraiser) => {
        return (
          <UserListItem
            imageSrc={fundraiser.avatarImage}
            key={fundraiser.id}
            mt={2}
            name={fundraiser.name}
            // isOwner={isOwner} // pretty sure we will need this
          />
        );
      })}
      {renderIf(overflowFundraiserCount > 0)(
        <ImageListItem
          leftComponent={<CircleText text={`+${overflowFundraiserCount}`} />}
          mt={2}
          text="More"
          onTouch={onMoreTouch}
        />
      )}
    </ContentBlock>
  );
};

MyTeamSidebar.fragments = { fields: FRAGMENTS };
export default MyTeamSidebar;

// == Styles ===============================================================
