import React from "react";
// import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Link } from "@reach/router";
import { destinationParser, canLinkWithRouter } from "@services";
import { useStoreAppValue } from "@hooks";
import { TextUppercase, Box, Image, Flex, TouchableOpacity } from "@atoms";
import { DepartmentItemFragment } from "./__generated__/DepartmentItemFragment";
// == Types ================================================================

interface IProps {
  asset: DepartmentItemFragment;
  onCollectionClick?: () => void;
  storefrontId?: string;
  collectionId?: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment DepartmentItemFragment on CollectionAsset {
      destination
      description
      title
      image
    }
  `,
};
// == Component ============================================================

const DepartmentItem = ({ asset, onCollectionClick, storefrontId, collectionId }: IProps) => {
  const { destination } = asset;
  const { campaignId, featureFlags } = useStoreAppValue();

  let href = "#";
  let containerProps = {};
  let canLinkRouter = false;

  if (destination) {
    const newHref = destinationParser({ destination, campaignId, storefrontId, collectionId });
    if (newHref) {
      href = newHref;
      canLinkRouter = canLinkWithRouter(href, featureFlags);
      if (!canLinkRouter) {
        containerProps = { as: "a", href, type: null };
      }
    }
  }

  const renderContent = () => {
    return (
      <Flex flexDirection="column" position="relative" onClick={onCollectionClick}>
        {asset.image && (
          <Box>
            <Image
              loading="lazy"
              alt={`${asset.image} department`}
              src={asset.image}
              onClick={onCollectionClick}
            />
          </Box>
        )}
        {!!asset.title && (
          <TextUppercase fontSize={[0]} pt={2} textAlign="center">
            {asset.title}
          </TextUppercase>
        )}
      </Flex>
    );
  };

  if (canLinkRouter) {
    return <Link to={href}>{renderContent()}</Link>;
  }
  return <TouchableOpacity containerProps={containerProps}>{renderContent()}</TouchableOpacity>;
};

export default DepartmentItem;

DepartmentItem.fragments = FRAGMENTS;

// == Styles ===============================================================
