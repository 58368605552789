import React from "react";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { oc } from "ts-optchain";
import { LinkBlockGives, LinkBlockPromoLabel } from "@organisms/linkBlockComponents";
import { GiveSentence } from "@components/common/utils";
import { Box, Image, Text, TouchableOpacity } from "@atoms";
import { RailsUrl } from "@services";
import { BrandBlockFragment } from "./__generated__/BrandBlockFragment";
import { ImageCircle } from "@components";

// == Types ================================================================

interface IProps {
  brand: BrandBlockFragment & { locationId: string };
  imageWidth: number;
  imagePadding: number;
  imageHeight: number;
  isImageCircle?: boolean;
  isOneLocation?: boolean;
  collectionId?: string;
  storefrontId?: string;
}

// == Constants ============================================================

BrandBlock.fragments = {
  fields: gql`
    fragment BrandBlockFragment on Brand {
      id
      name
      website
      logoImage
      logoBackgroundColor
      invertedLogoImage
      maxGiveLink {
        id
        promoLabel
        giveSentence
        promoSecondaryLabel
        positiveGiveAmount
        baselineGiveAmount
      }
    }
  `,
};

BrandBlock.defaultProps = {
  imagePadding: 15,
  imageWidth: 180,
  imageHeight: 135,
};

// == Component ============================================================

export default function BrandBlock({
  brand,
  imageWidth,
  imageHeight,
  imagePadding,
  isImageCircle,
  isOneLocation,
  collectionId,
  storefrontId,
}: IProps) {
  const { maxGiveLink: link } = brand;
  const onClick = () => {
    const brandRoute = RailsUrl.brandModalUrl({
      id: brand.id,
      locationId: isOneLocation ? brand.locationId : undefined,
      collectionId,
      storefrontId,
    });
    navigate(brandRoute, { state: { brandRoute } });
  };

  const brandLogo = brand.invertedLogoImage ? brand.invertedLogoImage : brand.logoImage;
  const brandLogoBg = brand.invertedLogoImage ? brand.logoBackgroundColor : "#FFFFFF";
  return (
    <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={onClick}>
      <Box position="relative">
        {!!brandLogo && (
          <Box mb={2}>
            {isImageCircle ? (
              <ImageCircle
                alt={brand.name}
                src={`${brandLogo}&twic=v1/resize=${imageWidth}x${imageHeight}(${imagePadding})/background=${brandLogoBg?.slice(
                  1
                )}`}
                border="silver"
                style={{ background: brandLogoBg ?? "#fff" }}
              />
            ) : (
              <Image
                alt={brand.name}
                boxShadow="lightShadow"
                loading="lazy"
                src={`${brandLogo}&twic=v1/resize=${imageWidth}x${imageHeight}(${imagePadding})/background=${brandLogoBg?.slice(
                  1
                )}`}
              />
            )}
          </Box>
        )}
        {!!link && !!link.promoLabel && (
          <LinkBlockPromoLabel bottom="30px" label={link.promoLabel} left="0" position="absolute" />
        )}
      </Box>
      <Text ellipsis uppercase display="block" fontSize={[0]} pt={1} textAlign="center">
        {brand.name}
      </Text>
      {!!link && (
        <LinkBlockGives
          giveSentence={GiveSentence({
            positiveGiveAmount: link.positiveGiveAmount,
            baselineGiveAmount: link.baselineGiveAmount,
            giveSentence: link.giveSentence,
          })}
          secondaryLabel={oc(link).promoSecondaryLabel("")}
        />
      )}
    </TouchableOpacity>
  );
}

// == Styles ===============================================================
