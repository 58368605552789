import React from "react";
import { LocationContext } from "@reach/router";
import queryString from "query-string";
import { IntentCreateInput } from "@types";
import { useStoreAppValue } from "@hooks";
import { RailsUrl } from "@services";
import { LinkModalFragment } from "../LinkModal/__generated__/LinkModalFragment";
import { ELinkModalBuyButton } from "./LinkModalBuyButtonEmotion";

// == Types ================================================================

interface IProps {
  campaignId?: string;
  createIntentInput: IntentCreateInput;
  disabled: boolean;
  fundraiserId?: string;
  link: LinkModalFragment;
  locationContext: LocationContext;
  onLinkClick: () => void;
}

// == Constants ============================================================

// == Component ============================================================

export default function LinkModalBuyButton({
  campaignId,
  createIntentInput,
  disabled,
  fundraiserId,
  link,
  locationContext,
  onLinkClick,
}: IProps) {
  const { currentUser } = useStoreAppValue();

  const userNeedsSignUp = !currentUser && !campaignId && !fundraiserId;
  const { type: linkType } = link;
  const { tinyLinkKey } = locationContext.location.state;

  const createIntentUrl = () => {
    const { deviceAttributes, ...rest } = createIntentInput;
    const {
      collectionId,
      storefrontId,
      labelPromotionId,
      commissionPromotionId,
    } = queryString.parse(location.search);
    const params = queryString.stringify({
      ...rest,
      linkType,
      collectionId,
      storefrontId,
      labelPromotionId,
      commissionPromotionId,
      deviceAttributes: JSON.stringify({ ...deviceAttributes }),
    });
    return RailsUrl.createIntentUrl({ params, campaignId });
  };

  const onClick = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      return currentUser && onLinkClick();
    }
    return null;
  };

  const checkoutHref = () => {
    if (disabled) return "";
    if (userNeedsSignUp) {
      return `${RailsUrl.createTeamUrl()}?s=${tinyLinkKey}`;
    }
    if (!currentUser) {
      return RailsUrl.createShopUrl({ campaignId, linkId: link.id, fundraiserId });
    }
    return createIntentUrl();
  };

  const variantPicker = () => (disabled ? "disabled" : "primary");

  return (
    <ELinkModalBuyButton
      as="a"
      disabled={disabled}
      href={checkoutHref()}
      target="_blank"
      userNeedsSignUp={userNeedsSignUp}
      variant={variantPicker()}
      onClick={(e) => onClick(e)}
    >
      {userNeedsSignUp ? "Sign Up And Register Your Team To Shop" : link.buttonCTA}
    </ELinkModalBuyButton>
  );
}

// == Styles ===============================================================
