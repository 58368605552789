import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Card, H3, Text } from "@atoms";

// == Types ================================================================

interface IProps {
  title: string;
  children: ReactNode;
  altLink?: string;
  altOnClick?: () => void;
  topRightLink?: string;
  topRightLinkOnClick?: () => void;
}

// == Constants ============================================================

ContentBlock.defaultProps = {
  altLink: null,
  altOnClick: null,
};

// == Component ============================================================

export default function ContentBlock({
  title,
  children,
  altLink,
  altOnClick,
  topRightLink,
  topRightLinkOnClick,
}: IProps) {
  return (
    <Card p={3}>
      <H3 mb={3} style={{ position: "relative" }}>
        {title}
        {altLink && (
          <EText as="p" display="inline-block" fontSize="1" ml={2} onClick={altOnClick}>
            {altLink}
          </EText>
        )}
        {topRightLink && (
          <ETextRight
            as="p"
            display="inline-block"
            fontSize="1"
            ml={2}
            onClick={topRightLinkOnClick}
          >
            {topRightLink}
          </ETextRight>
        )}
      </H3>
      {children}
    </Card>
  );
}

// == Styles ===============================================================

const EText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ETextRight = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  right: 1rem;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
