import React, { Fragment } from 'react';
import { arrayOf, shape, func, bool } from 'prop-types';
import Attendee from './Attendee';

function AttendanceList({
  groupedParticipants,
  handleCheck,
  viewerIsParticipant,
  userIdsToInvite,
}) {
  const DISPLAY_STATUSES = {
    INVITED: 'Pending',
    ACCEPTED: 'Going',
    DECLINED: 'Not Going',
  };
  return (
    <div className="event-invite-list">
      {Object.keys(DISPLAY_STATUSES).map((type) => {
        if (groupedParticipants[type]) {
          return (
            <Fragment key={`status-${type}`}>
              <div className="event-invite-list-item__status">{DISPLAY_STATUSES[type]}</div>
              {groupedParticipants[type].map(participant => (
                <Attendee
                  key={`attendace-${participant.id}`}
                  participant={participant}
                  userIdsToInvite={userIdsToInvite}
                  viewerIsParticipant={viewerIsParticipant}
                  handleCheck={handleCheck}
                />
              ))}
            </Fragment>
          );
        }
      })}
    </div>
  );
}

AttendanceList.propTypes = {
  groupedParticipants: shape({
    ACCEPTED: arrayOf(shape({})),
  }).isRequired,
  userIdsToInvite: shape({}).isRequired,
  handleCheck: func.isRequired,
  viewerIsParticipant: bool.isRequired,
};

export default AttendanceList;
