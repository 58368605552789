import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import styled from "@emotion/styled";
import { format, parse } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

// == Types ================================================================

interface IProps {
  label: string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  onChange?: () => void;
  showYearDropdown: boolean;
  onFieldChange?: (date: string) => void;
}

// == Constants ============================================================

DatePickerField.defaultProps = {
  disabled: false,
  placeholder: "11-1-2019",
  defaultValue: null,
  showYearDropdown: false,
};

// == Component ============================================================

export default function DatePickerField(props: IProps) {
  const { name, disabled, label, placeholder, onChange: onFieldChange } = props;
  useEffect(() => {
    Materialize.updateTextFields();
  }, []);
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const onDatePickerFieldChange = (val) => {
    const formattedDate = format(val, "yyyy-MM-dd", new Date());
    if (onFieldChange) {
      onFieldChange(formattedDate);
    }
    setFieldValue(field.name, formattedDate);
  };

  return (
    <>
      <ELabel className="active" htmlFor={name}>
        {label}
      </ELabel>
      <EView className={`input-field ${disabled ? "disabled" : ""}`}>
        <EDatePicker
          minDate={new Date()}
          {...field}
          {...props}
          dateFormat="yyyy-MM-dd"
          name={name}
          placeholderText={placeholder}
          selected={(field.value && parse(field.value, "yyyy-MM-dd", new Date())) || null}
          onChange={(val) => {
            onDatePickerFieldChange(val);
          }}
        />
        {meta.touched && meta.error ? <span className="standalone-error">{meta.error}</span> : null}
      </EView>
    </>
  );
}

// == Styles ===============================================================

const EDatePicker = styled(DatePicker)`
  padding: ${({ theme }) => theme.space[3]}px;
  border: ${({ theme }) => {
    return `1px solid ${theme.colors.gray}`;
  }};
  border-radius: 5px;
  height: 40px !important;
  padding-top: 0 !important;
`;

const EView = styled.div`
  > div.react-datepicker-wrapper,
  > div
    > div.react-datepicker__input-container
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }
  width: 100%;
  > div.react-datepicker-popper {
    margin-top: -15px;
  }
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;
