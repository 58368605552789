import React from "react";
import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  name: string;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  name: "",
};

// == Component ============================================================

export const BeneficiaryCheque = ({ name }: IProps) => {
  return (
    <div className="mb-3">
      <EBeneficiaryCheque className="p-3">
        <EChequeTo className="mt-4 mb-3">
          <EToLabel>To: </EToLabel>
          <EChequeName className="color-em">{name}</EChequeName>
        </EChequeTo>
        <EChequeDetailsRow>
          <ECheckDetails className="d-none d-md-block" />
          <EChequeDollars />
        </EChequeDetailsRow>
        <EFakeChequeNumRow>
          <EIcon className="material-icons">account_balance</EIcon>
          <EFakeChequeNum>0123456789</EFakeChequeNum>
          <EFakeChequeNum>0001234567890</EFakeChequeNum>
          <EFakeChequeNum className="d-none d-md-block">01234</EFakeChequeNum>
        </EFakeChequeNumRow>
      </EBeneficiaryCheque>
      <p className="legal centered mt-2">** Legal name that payments will be made out to.</p>
    </div>
  );
};

BeneficiaryCheque.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
const EBeneficiaryCheque = styled.div`
  background-color: #f1fbfb;
  border: 1px dashed #e7e7e7;
`;

const EChequeTo = styled.div`
  border-bottom: 1px solid #ccc;
`;

const EChequeDetailsRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const EToLabel = styled.span``;

const EChequeName = styled.span`
  font-size: 24px;
  font-weight: bold;
  word-break: break-word;
`;

const ECheckDetails = styled.div`
  flex: 1 1 0;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 20px;
    top: 0;
    border-top: 3px dashed #ccc;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 40px;
    top: 16px;
    border-top: 3px dashed #ccc;
  }
`;

const EChequeDollars = styled.div`
  width: 100px;
  height: 35px;
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  position: relative;
  &:before {
    position: absolute;
    top: 5px;
    left: 5px;
    content: "$";
  }
  &:after {
    position: absolute;
    right: 5px;
    top: 16px;
    content: "";
    border-top: 1px solid #ccc;
    width: 70px;
  }
`;

const EFakeChequeNumRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const EIcon = styled.i`
  color: #ccc;
  font-size: 30px;
`;

const EFakeChequeNum = styled.span`
  color: #ccc;
  font-size: 12px;
`;
