import React from "react";
import gql from "graphql-tag";
import { Column, Row } from "@atoms";
import { CollectionContentItem } from "../CollectionContentItem";
import { CollectionFourUpFragment } from "./__generated__/CollectionFourUpFragment";
// == Styles ===============================================================

// == Types ================================================================
interface IProps {
  collection: CollectionFourUpFragment;
  storefrontId?: string;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment CollectionFourUpFragment on Collection {
      id
      assets {
        ...CollectionContentItemFragment
      }
    }
    ${CollectionContentItem.fragments.fields}
  `,
};
// == Component ============================================================

const CollectionFourUp = ({ collection, storefrontId }: IProps) => {
  if (collection.assets.length < 4) return null;

  return (
    <Row flexWrap="wrap" justifyContent="center">
      {collection.assets.map((asset, index) => (
        <Column
          autoColumn={false}
          key={`${asset.image}`}
          mb={[3, 3, 0]}
          width={[1 / 2, 1 / 2, 1 / 4]}
        >
          <CollectionContentItem asset={asset} storefrontId={storefrontId} />
        </Column>
      ))}
    </Row>
  );
};

CollectionFourUp.fragments = FRAGMENTS;

export default CollectionFourUp;

// == Styles ===============================================================
