import React from "react";
import gql from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import { Box, Flex, Text } from "@atoms";
import { FundsPagePaymentListItemFragment } from "./__generated__/FundsPagePaymentListItemFragment";

// == Types ================================================================

interface IProps {
  paymentListItem: FundsPagePaymentListItemFragment;
  isGroups: boolean;
}

// == Constants ============================================================

FundsPagePaymentListItem.fragments = gql`
  fragment FundsPagePaymentListItemFragment on PaymentRecipient {
    id
    transactionsCount
    amount {
      formatted
      centsAmount
      dollarsAmount
    }
    fundraiser {
      id
      name
      group {
        id
        name
        amountRaised {
          formatted
          centsAmount
        }
      }
    }
  }
`;

// == Component ============================================================

export default function FundsPagePaymentListItem({ paymentListItem, isGroups = false }: IProps) {
  if (isEmpty(paymentListItem)) return null;

  const { transactionsCount, amount, fundraiser } = paymentListItem;

  const fontSizes = {
    small: { name: [0, 1], transactionCount: [0, 1], amount: [1], earned: [0, 1] },
    large: { name: [1, 2, 3], transactionCount: [0, 1, 2], amount: [2, 2, 3], earned: [0, 1, 2] },
  };

  const fontSizeHandler = isGroups ? fontSizes.small : fontSizes.large;

  return (
    <Flex justifyContent="space-between" padding={[2, 2, 3]}>
      <Flex flexDirection="column">
        <Box>
          <Text fontSize={fontSizeHandler.name}>
            {fundraiser?.name ? `${fundraiser.name}` : "Team"}
          </Text>
        </Box>
        <Box>
          <Text fontSize={fontSizeHandler.transactionCount}>
            {!!transactionsCount && transactionsCount > 1
              ? `${transactionsCount} transactions`
              : `${transactionsCount} transaction`}
          </Text>
        </Box>
      </Flex>
      <Flex alignItems="flex-end" flexDirection="column">
        <Box>
          <Text fontSize={fontSizeHandler.amount}>{amount?.formatted ?? ""}</Text>
        </Box>
        <Box>
          <Text fontSize={fontSizeHandler.earned}>Earned</Text>
        </Box>
      </Flex>
    </Flex>
  );
}

// == Styles ===============================================================
