import React from "react";
import { shape, func, string, arrayOf } from "prop-types";
import Autosuggest from "react-autosuggest";

const SearchCampaigns = ({
  value,
  suggestions,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  onSuggestionSelected,
  getSuggestionValue,
  renderSuggestion,
  onChange,
}) => (
  <div className="user-transfer__autosuggest mt-3">
    <Autosuggest
      getSuggestionValue={getSuggestionValue}
      inputProps={{
        placeholder: "Search all teams",
        value,
        onChange,
      }}
      renderSuggestion={renderSuggestion}
      suggestions={suggestions}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    />
  </div>
);

SearchCampaigns.propTypes = {
  value: string.isRequired,
  suggestions: arrayOf(shape({})).isRequired,
  onSuggestionsClearRequested: func.isRequired,
  onSuggestionsFetchRequested: func.isRequired,
  onSuggestionSelected: func.isRequired,
  getSuggestionValue: func.isRequired,
  renderSuggestion: func.isRequired,
  onChange: func.isRequired,
};

export default SearchCampaigns;
