import { FlexDirectionProperty, AlignItemsProperty, JustifyContentProperty } from "csstype";
import { theme } from "./theme";

export const spaceRangeOptions = {
  range: true,
  min: 0,
  max: theme.space.length - 1,
  step: 1,
};

export const percentRangeOptions = {
  range: true,
  min: 0,
  max: 1,
  step: 0.1,
};

export const fontSizeRangeOptions = {
  range: true,
  min: 0,
  max: theme.fontSizes.length - 1,
  step: 1,
};

export const flexDirectionOptions: FlexDirectionProperty[] = [
  "column",
  "column-reverse",
  "row",
  "row-reverse",
];
export const alignItemsOptions: AlignItemsProperty[] = [
  "start",
  "end",
  "self-end",
  "self-start",
  "flex-end",
  "flex-start",
  "center",
  "baseline",
  "normal",
  "stretch",
];
export const justifyContentOptions: JustifyContentProperty[] = [
  "left",
  "normal",
  "right",
  "center",
  "end",
  "flex-start",
  "flex-end",
  "stretch",
  "space-between",
  "space-around",
  "space-evenly",
];

export const fontFamilyOptions = theme.fonts;
