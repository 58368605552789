import React from "react";
import gql from "graphql-tag";
import "@reach/menu-button/styles.css";
import { useMutation } from "react-apollo";
import { useStoreAppValue } from "@hooks";
import { Modal, Box, Flex, Text, TouchableOpacity, ChevronLeft, Button } from "@atoms";

// == Types ================================================================

interface IProps {
  id: string;
  name: string;
  closeModal: () => void;
}

// == Constants ============================================================

const CAMPAIGN_UPDATE_CAPTAIN_MUTATION = gql`
  mutation CampaignUpdateCaptain($input: CampaignUpdateCaptainInput!) {
    CampaignUpdateCaptain(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
      }
    }
  }
`;

// == Component ============================================================

export default function ReassignMember({ id, name, closeModal }: IProps) {
  const [updateCampaignCaptain, { loading }] = useMutation(CAMPAIGN_UPDATE_CAPTAIN_MUTATION, {
    onCompleted: ({ CampaignUpdateCaptain }) => {
      if (CampaignUpdateCaptain.__typename === "MutationError") return;
      Materialize.toast(`${name} has been made captain`, 4000, "rounded toast-success");
      closeModal();
      window.location.reload();
    },
  });

  const handleButtonClick = () => {
    updateCampaignCaptain({
      variables: {
        input: {
          teamMemberId: id,
        },
      },
    });
  };

  const primary = useStoreAppValue()?.theme?.color?.primary ?? "#27B7BA";

  return (
    <Modal isOpen={true} onDismiss={closeModal}>
      <Flex alignItems="center" flexDirection="column" justifyContent="center" padding={3}>
        <Flex alignSelf="flex-start" paddingBottom={[2, 3]}>
          <TouchableOpacity onTouch={closeModal}>
            <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
          </TouchableOpacity>
          <Box paddingLeft={[2, 3]}>
            <Text fontWeight="bold">Update Team Captain</Text>
          </Box>
        </Flex>
        <Flex alignItems="center" flexDirection="column" py={2} width={1}>
          <Box display="inline" textAlign="center">
            <Text>Make</Text>
            <Text color="primary" fontWeight="bold">
              {" "}
              {name}{" "}
            </Text>
            <Text>the captain of this team</Text>
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" py={2}>
          <Button height={50} minWidth={250} variant="primary" onClick={handleButtonClick}>
            {loading ? "Processing..." : "Make Captain"}
          </Button>
        </Flex>
        <Box py={2} textAlign="center" width={1}>
          <Button height={50} minWidth={250} variant="clear" onClick={closeModal}>
            <Text color="primary" fontSize={[1, 2]}>
              Cancel
            </Text>
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
}

// == Styles ===============================================================
