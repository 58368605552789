import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Row, Column, Card } from "@atoms";

// == Types ================================================================

interface IProps {
  children?: ReactNode;
  hideOverflow: boolean;
}

// == Constants ============================================================

CenteredBoxLayout.defaultProps = {
  children: null,
  hideOverflow: true,
};

// == Component ============================================================

export default function CenteredBoxLayout({ hideOverflow, children, ...rest }: IProps) {
  return (
    <Row alignItems="center" justifyContent="center" mx="0">
      <EColumn
        autoColumn={false}
        bg="white"
        boxShadow={["none", "modalBoxShadow"]}
        my={[0, 4]}
        px={0}
        width={[1, "500px", "600px", "600px"]}
      >
        <ECard bg="white" border={[0, "card", "card"]} hideOverflow={hideOverflow} {...rest}>
          {children}
        </ECard>
      </EColumn>
    </Row>
  );
}

// == Styles ===============================================================

const ECard = styled(Card)`
  overflow: ${({ hideOverflow }) => (hideOverflow ? "hidden !important" : "visible")};
`;

const EColumn = styled(Column)`
  border-radius: 0;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    border-radius: ${({ theme }) => `${theme.radii.modal}px`};
  }
`;
