import { IntrospectionFragmentMatcher, InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { get, pickBy } from "lodash";
import { persistCache } from "apollo-cache-persist";
import { ErrorService } from "@services";
import Settings from "@services/Settings";
import introspectionQueryResultData from "../../graphQLFragmentTypes.json";

export default (apiUrl, options = {}) => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const cache = new InMemoryCache({ fragmentMatcher });

  if (options.cacheKey) {
    persistCache({
      cache,
      storage: window.sessionStorage,
      key: options.cacheKey,
      debug: Settings.environment === "development",
    });
  }

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, path }) =>
        ErrorService.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
      );
    }

    if (networkError) {
      ErrorService.error(`[Network error ${operation.operationName}]: ${networkError.message}`, {
        operationName: operation.operationName,
        queryString: get(operation, "query.loc.source.body", ""),
        variables: JSON.stringify(operation.variables),
        statusCode: networkError.statusCode,
      });
    }
  });

  const authLink = setContext(async (_, { headers, currency }) => {
    const linkOptions = {
      headers: pickBy({
        ...headers,
        "X-PLATFORM": "WEB",
        "CAMPAIGN-ID": Settings.campaignId,
        "X-SITE-ID": Settings.siteId,
        "X-CURRENCY": currency,
      }),
      credentials: "include",
    };
    if (Settings.currentUser) {
      linkOptions.headers.Authorization = `Bearer ${Settings.currentUser.token}`;
    }
    return linkOptions;
  });

  const httpOptions = { uri: `${apiUrl}/graphql`, credentials: "include" };
  const httpLink = ApolloLink.split(
    (operation) => operation.getContext().skipBatch || operation.getContext().hasUploads || false,
    createUploadLink(httpOptions),
    new BatchHttpLink(httpOptions)
  );

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache,
  });

  return client;
};
