import React, { useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import styled from "@emotion/styled";
import { Flex, Box, Button, H1, H2, Text } from "@atoms";
import { useStoreAppValue, useToggle } from "@hooks";
import { RailsUrl, analytics } from "@services";
import { Currency } from "@types";
import { BrandShowcase, OnboardingContainer } from "../components";
import OnboardingCitySearch from "../OnboardingCitySearch/OnboardingCitySearch";
import { OnboardingIntroQuery } from "./__generated__/OnboardingIntroQuery";

// == Types ================================================================

// == Constants ============================================================

const ONBOARDING_QUERY = gql`
  query OnboardingIntroQuery($skipCampaign: Boolean!) {
    Viewer {
      id
      currency
      onboarding {
        id
        questions {
          id
        }
      }
    }
    Campaign @skip(if: $skipCampaign) {
      id
      name
      isViewerOwner
      goal {
        formatted
      }
    }
  }
`;

// == Component ============================================================

export function OnboardingIntro(_props: RouteComponentProps) {
  const { campaignId, isOwner } = useStoreAppValue();
  const { data, loading, error } = useQuery<OnboardingIntroQuery>(ONBOARDING_QUERY, {
    variables: {
      skipCampaign: !campaignId,
    },
  });
  const [currency, setCurrency] = useState<Currency | null>(null);
  const [canProceed, setCanProceed] = useToggle(!!currency);
  const onSubmitClick = () => {
    analytics.track.onboardingStart({ campaignId }, isOwner);
    navigate(RailsUrl.onboardingQuestionUrl(questions?.[0].id));
  };
  const questions = data?.Viewer.onboarding?.questions;
  return (
    <OnboardingContainer
      hasError={!!error || !questions || questions.length === 0}
      loading={loading}
    >
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        {/* ===Header begins=== */}
        <Box pt={3} px={[2, 2, 5]} textAlign="center">
          {data?.Campaign?.isViewerOwner ? (
            <>
              <H1>How Much Can I Earn?</H1>
              <Text fontSize="1">
                Answer 5 questions to calculate how much cash you can earn back.
              </Text>
            </>
          ) : (
            <>
              {campaignId ? (
                <>
                  <H1 mb={1}>{data?.Campaign?.name}</H1>
                  <H2 color="primary" mb={2}>
                    {data?.Campaign?.goal?.formatted} Goal
                  </H2>
                  <Text fontSize="1">
                    Personalize your store and estimate how much cashback you can earn.
                  </Text>
                </>
              ) : (
                <>
                  <H1 mb={2}>Start Earning</H1>
                  <Text fontSize="1">Select your city so we can show you offers near you.</Text>
                  <OnboardingCitySearch setCanProceed={setCanProceed} setCurrency={setCurrency} />
                </>
              )}
            </>
          )}
        </Box>
        {/* ===Header ends=== */}
        {/* ===Brand Showcase begins=== */}
        <Box pb={3} pt={[0, 0, 5]}>
          <BrandShowcase
            currency={currency ?? data?.Viewer.currency}
            key={currency ?? data?.Viewer.currency}
          />
        </Box>

        {/* ===Brand Showcase ends=== */}
        {/* ===Button begins=== */}
        <EButton
          borderRadius="none"
          disabled={!canProceed && !campaignId}
          width="100%"
          onClick={onSubmitClick}
        >
          Calculate My Cashback
        </EButton>
        {/* ===Button ends=== */}
      </Flex>
    </OnboardingContainer>
  );
}

// == Styles ===============================================================

const EButton = styled(Button)`
  position: absolute;
  bottom: 0;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    position: inherit;
  }
`;
