import React from "react";
import gql from "graphql-tag";
import { Box, Flex, TouchableOpacity, Text } from "@atoms";
import { constants, RailsUrl } from "@services";
import { FundsPageEarningsChart } from "../FundsPageEarningsChart";
import { FundsPageEarningsLegend } from "../FundsPageEarningsLegend";
import { FundsPageEarningsFundraisersList } from "../FundsPageEarningsFundraisersList";
import { FundsPageEarningsGroupsListAnonymous } from "../FundsPageEarningsGroupsListAnonymous";
import { FundsPageEarningsGroupsList } from "../FundsPageEarningsGroupsList";
import { FundsPageEarningsFragment } from "./__generated__/FundsPageEarningsFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPageEarningsFragment;
  onGroupFetchMore: (groupId: string, endCursor: string) => void;
  onNoGroupFetchMore: () => void;
}

// == Constants ============================================================

FundsPageEarnings.fragments = gql`
  fragment FundsPageEarningsFragment on Campaign {
    id
    privacyLevel
    isViewerOwner @include(if: $hasCurrentUser)
    ...FundsPageEarningsChartFragment
    ...FundsPageEarningsLegendFragment
    ...FundsPageEarningsGroupsListFragment
    ...FundsPageEarningsGroupsListAnonymousFragment
    ...FundsPageEarningsFundraisersListViewerFundraiserFragment
  }
  ${FundsPageEarningsChart.fragments}
  ${FundsPageEarningsLegend.fragments}
  ${FundsPageEarningsGroupsList.fragments}
  ${FundsPageEarningsGroupsListAnonymous.fragments}
  ${FundsPageEarningsFundraisersList.fragments.viewerFundraiser}
  ${FundsPageEarningsFundraisersList.fragments.fundraisers}
`;

// == Component ============================================================

export default function FundsPageEarnings({
  campaign,
  onNoGroupFetchMore,
  onGroupFetchMore,
}: IProps) {
  const { id, privacyLevel, filteredGroups: groups, isViewerOwner } = campaign;
  const {
    PRIVACY_LEVEL_TYPES: { ANONYMOUS },
  } = constants;

  const isGroups = Object.keys(groups).length > 0;
  const isCampaignAnonymous = privacyLevel === ANONYMOUS;

  const renderGroups = () => {
    if (isGroups && isCampaignAnonymous && !isViewerOwner)
      return (
        <FundsPageEarningsGroupsListAnonymous
          campaign={campaign}
          onGroupFetchMore={onGroupFetchMore}
          onNoGroupFetchMore={onNoGroupFetchMore}
        />
      );
    return (
      <FundsPageEarningsGroupsList
        campaign={campaign}
        onGroupFetchMore={onGroupFetchMore}
        onNoGroupFetchMore={onNoGroupFetchMore}
      />
    );
  };

  const footerCTA = isViewerOwner
    ? `Make sure you invite everyone on your team to join`
    : `Earn more by inviting friends and family`;

  return (
    <Box>
      <Flex
        alignItems="center"
        bg="lightBlueBg"
        flexDirection="column"
        justifyContent="space-evenly"
        mt={4}
      >
        <Flex alignItems="center" flexDirection="row" justifyContent="space-between">
          <FundsPageEarningsChart campaign={campaign} />
          <FundsPageEarningsLegend campaign={campaign} />
        </Flex>
      </Flex>
      <Flex alignItems="center" flexDirection="column" justifyContent="flex-start">
        {renderGroups()}
      </Flex>

      <Box style={{ textAlign: "center" }} width={1}>
        <TouchableOpacity onTouch={() => (window.location.href = RailsUrl.membersUrl(id))}>
          <Box marginLeft={3} padding={[1, 2, 3]}>
            <Text color="primary" fontSize={[1, 2]}>
              {footerCTA}
            </Text>
          </Box>
        </TouchableOpacity>
      </Box>
    </Box>
  );
}

// == Styles ===============================================================
