import React, { useState } from "react";
import {
  CardElement,
  StripeProvider,
  Elements,
  injectStripe,
  ReactStripeElements,
} from "react-stripe-elements";
import { Formik, Form } from "formik";
// import * as Yup from "yup";
import { ClearButton } from "@components/common/atoms";
import { CheckboxField } from "@components/common/molecules";
import { useScript, useStoreAppValue } from "@hooks";
import { ErrorService } from "@services";

// == Types ================================================================

type TToken = stripe.Token;

interface IOnSaveCardClickPayload {
  token: TToken;
  saveCard: boolean;
}

interface IProps {
  setAddNewCard: (val: boolean) => void;
  onSaveNewCardClick: (payload: IOnSaveCardClickPayload) => void;
  forceSave?: boolean;
}

interface IStripeForm extends IProps, ReactStripeElements.InjectedStripeProps {}

// == Constants ============================================================
CheckoutViewStripeFields.defaultProps = {
  forceSave: false,
};

const createOptions = () => {
  return {
    style: {
      base: {
        color: "#333",
        lineHeight: "40px",
        fontFamily: 'CerebriSans, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "1rem",
        "::placeholder": {
          color: "#999",
        },
      },
      invalid: {
        color: "#a94442",
        iconColor: "#a94442",
      },
    },
  };
};

// == Component ============================================================

function StripeForm({ stripe, setAddNewCard, onSaveNewCardClick, forceSave }: IStripeForm) {
  const [stripeError, setStripeError] = useState<string | null>(null);
  const { currentUser } = useStoreAppValue();
  return (
    <Formik
      initialValues={{ saveCard: false }}
      onSubmit={async (values, { setSubmitting }) => {
        if (!stripe) return;

        const { error, token } = await stripe.createToken();
        if (error && error.message) {
          setStripeError(error.message);
          ErrorService.error(error);
        } else {
          setStripeError(null);
          setAddNewCard(false);
          if (token) {
            const saveCard = forceSave ? true : values.saveCard;
            onSaveNewCardClick({ token, saveCard });
          }
        }
        setSubmitting(false);
      }}
    >
      <Form className="ElementsApp">
        <CardElement {...createOptions()} />
        {stripeError && <span className="standalone-error">{stripeError}</span>}
        {currentUser && !forceSave && (
          <CheckboxField label="Save card after purchase" name="saveCard" />
        )}
        <ClearButton type="submit" width="100%">
          Use This Card
        </ClearButton>
      </Form>
    </Formik>
  );
}

const InjectedStripeForm = injectStripe(StripeForm);

function CheckoutViewStripeFields(props: IProps) {
  const { stripeKey } = useStoreAppValue();
  const [loaded] = useScript("https://js.stripe.com/v3/");

  if (!loaded) return null;
  return (
    <StripeProvider apiKey={stripeKey}>
      <Elements>
        <InjectedStripeForm {...props} />
      </Elements>
    </StripeProvider>
  );
}

export default CheckoutViewStripeFields;

// == Styles ===============================================================
