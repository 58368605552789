import React, { useState } from "react";
import gql from "graphql-tag";
import queryString from "query-string";
import { useStoreAppValue } from "@hooks";
import { Container } from "@atoms";
import {
  FundsPageTabs,
  FundsPageEarnings,
  FundsPageWithdrawals,
  FUNDS_TABS_ENUM,
} from "../components";
import { FundsPageFragment } from "./__generated__/FundsPageFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPageFragment;
  onGroupFetchMore: (groupId: string, endCursor: string) => void;
  onNoGroupFetchMore: () => void;
}

// == Constants ============================================================

FundsPage.fragments = gql`
  fragment FundsPageFragment on Campaign {
    id
    ...FundsPageEarningsFragment
    ...FundsPageWithdrawalsFragment
  }
  ${FundsPageEarnings.fragments}
  ${FundsPageWithdrawals.fragments}
`;

// == Component ============================================================

export default function FundsPage({ campaign, onNoGroupFetchMore, onGroupFetchMore }: IProps) {
  const [activeTab, setTab] = useState(() => {
    const queryHash = queryString.parse(window.location.hash);
    return Object.keys(queryHash).includes("withdraw")
      ? FUNDS_TABS_ENUM.WITHDRAWALS
      : FUNDS_TABS_ENUM.EARNINGS;
  });
  const { currentUser, isMember } = useStoreAppValue();

  const { payments } = campaign;

  return (
    <>
      {!!currentUser && !!isMember && <FundsPageTabs activeTab={activeTab} setTab={setTab} />}
      <Container>
        {activeTab === FUNDS_TABS_ENUM.EARNINGS ? (
          <FundsPageEarnings
            campaign={campaign}
            onGroupFetchMore={onGroupFetchMore}
            onNoGroupFetchMore={onNoGroupFetchMore}
          />
        ) : (
          <FundsPageWithdrawals campaign={campaign} withdrawalsList={payments} />
        )}
      </Container>
    </>
  );
}

// == Styles ============================================================
