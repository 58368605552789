import React from "react";
import { Box, IBoxProps } from "../Box";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export const Section = (props: IBoxProps) => {
  return <Box {...props} />;
};

// == Styles ===============================================================
