import React from "react";
import gql from "graphql-tag";
import { getPaginatedNodes } from "@utils";
import OnboardingBrandsGroup from "../OnboardingBrandsGroup/OnboardingBrandsGroup";
import { OnboardingBrandsCollectionFragment } from "./__generated__/OnboardingBrandsCollectionFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  collection: OnboardingBrandsCollectionFragment;
  activeBrands: string[];
  onBrandClick: (id: string) => void;
}

// == Constants ============================================================

OnboardingBrandsCollection.fragments = gql`
  fragment OnboardingBrandsCollectionFragment on Collection {
    id
    name
    brands(first: 30) {
      edges {
        cursor
        node {
          id
          ...OnboardingBrandsGroupFragment
        }
      }
    }
  }
  ${OnboardingBrandsGroup.fragments}
`;

// == Component ============================================================

export function OnboardingBrandsCollection({ collection, onBrandClick, activeBrands }: IProps) {
  const brands = getPaginatedNodes(collection.brands.edges);

  return (
    <OnboardingBrandsGroup
      allowShowMore
      activeBrands={activeBrands}
      brands={brands}
      name={collection.name || ""}
      onBrandClick={onBrandClick}
    />
  );
}

// == Styles ===============================================================
