import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { Formik, Form } from "formik";
import { useMutation } from "react-apollo";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { isAfter } from "date-fns";
import { SelectField } from "@components/common/molecules/inputComponents/SelectField/SelectField";
import { TouchableOpacity, CardForm, ClearButton, Box, Text } from "@atoms";
import { constants } from "@services";
import { TextField } from "@molecules";
import { CheckoutFormPreview } from "../CheckoutFormPreview";
import { BUY_UPDATE_MUTATION } from "../CheckoutView/queries";

// == Types ================================================================

interface IProps {
  giftcardIntent: $FixMe;
  isValid: boolean;
  dispatch: $FixMe;
}

// == Constants ============================================================

CheckoutRecipientForm.defaultProps = {};

CheckoutRecipientForm.validation = Yup.object({
  street1: Yup.string().required("Please provide your street address."),
  province: Yup.string().required("Please provide your province/state."),
  city: Yup.string().required("Please provide your city."),
  country: Yup.string().required("Please provide your country."),
  postalCode: Yup.string()
    .when("country", {
      is: "USA",
      then: Yup.string()
        .required("Please provide your ZIP.")
        .min(5, "Must be 5 characters long.")
        .matches(constants.ZIP_REGEX, {
          message: "Please provide a valid ZIP.",
        }),
    })
    .when("country", {
      is: "Canada",
      then: Yup.string()
        .required("Please provide your postal code.")
        .min(6, "Must be 6 characters long.")
        .matches(constants.POSTAL_CODE_REGEX, {
          message: "Please provide a valid postal code.",
        }),
    }),
  // shippingOption: Yup.string().required("Please choose a shipping option."),
});

CheckoutRecipientForm.fragments = gql`
  fragment CheckoutRecipientFormFragment on GiftcardIntent {
    id
    shippingOptions {
      id
      name
    }
    link {
      id
      currency
    }
    shipping {
      city
      country
      postalCode
      province
      street1
      street2
    }
    shippingOption {
      id
      name
    }
  }
`;

// == Component ============================================================

export default function CheckoutRecipientForm({ giftcardIntent, isValid, dispatch }: IProps) {
  const initialState = {
    street1: giftcardIntent?.shipping?.street1,
    street2: giftcardIntent?.shipping?.street2,
    province: giftcardIntent?.shipping?.province,
    city: giftcardIntent?.shipping?.city,
    postalCode: giftcardIntent?.shipping?.postalCode,
    country:
      giftcardIntent?.shipping?.country || giftcardIntent?.link.currency === "USD"
        ? "USA"
        : "Canada",
    shippingOption: giftcardIntent?.shippingOption?.id || giftcardIntent?.shippingOptions[0]?.id,
  };

  const [buyUpdateMutation] = useMutation(BUY_UPDATE_MUTATION);
  const [formValues, setFormValues] = useState(initialState);
  const { currency } = giftcardIntent.link;
  const { shippingOptions } = giftcardIntent;

  useEffect(() => {
    const validateForm = async () => {
      const valid = await CheckoutRecipientForm.validation.isValid(formValues);
      if (valid) {
        dispatch({ type: "ADD_VALID_FORM", formName: "SHIPPING_FORM" });
      }
    };

    validateForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditClick = () => {
    dispatch({ type: "REMOVE_VALID_FORM", formName: "SHIPPING_FORM" });
  };

  const onSubmit = (values, { setSubmitting }) => {
    setFormValues(values);

    const {
      name: giftName,
      email: giftEmail,
      message: giftMessage,
      deliverOn,
      giftcardTemplateId,
    } = values;

    buyUpdateMutation({
      variables: {
        id: giftcardIntent.id,
        input: values,
      },
    })
      .then(({ data }) => {
        // need to handle errors here
        dispatch({ type: "ADD_VALID_FORM", formName: "SHIPPING_FORM" });
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
        dispatch({ type: "REMOVE_VALID_FORM", formName: "SHIPPING_FORM" });
      });
  };
  // might come back
  // const onCountryChange = (e, setFieldValue) => {
  //   setFieldValue("province", "");
  // };
  const [postalInput, setPostalInput] = useState("");
  return (
    <CardForm
      checkMark={isValid}
      heading="Shipping Information"
      showEditButton={isValid}
      onEditClick={onEditClick}
    >
      <Box display={isValid ? "none" : "block"}>
        <Formik
          initialValues={{
            ...initialState,
            country: `${currency === "USD" ? "USA" : "Canada"}`,
          }}
          validationSchema={CheckoutRecipientForm.validation}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }) => {
            return (
              <Form>
                <TextField label="Street Address" name="street1" placeholder="" type="text" />
                <TextField
                  label="Apt, Suite, Building (optional)"
                  name="street2"
                  placeholder=""
                  type="text"
                />
                {/* in case we can select different countries */}
                {/* <SelectField
                  label="Country"
                  name="country"
                  placeholder="Please Select"
                  onChange={(e) => {
                    onCountryChange(e, setFieldValue);
                  }}
                >
                  <option value="">Please select a country</option>
                  {COUNTRIES.map((country) => {
                    return <option key={country[0]}>{country[1]}</option>;
                  })}
                </SelectField> */}
                <TextField
                  disabled
                  label="Country"
                  name="country"
                  placeholder=""
                  type="text"
                  value={currency === "USD" ? "USA" : "Canada"}
                />

                {currency === "USD" ? (
                  <SelectField label="State" name="province">
                    <>
                      <option value="">Please select a state</option>
                      {constants.STATES.map((state) => {
                        return (
                          <option key={state[0]} value={state[0]}>
                            {state[1]}
                          </option>
                        );
                      })}
                    </>
                  </SelectField>
                ) : (
                  <SelectField label="Province" name="province">
                    <>
                      <option value="">Please select a province</option>
                      {constants.PROVINCES.map((province) => {
                        return (
                          <option key={province[0]} value={province[0]}>
                            {province[1]}
                          </option>
                        );
                      })}
                    </>
                  </SelectField>
                )}
                <TextField label="City" name="city" placeholder="" type="text" />
                <TextField
                  label={currency === "USD" ? "ZIP " : " Postal Code"}
                  maxLength={currency === "USD" ? 5 : 7}
                  name="postalCode"
                  placeholder=""
                  type="text"
                  value={postalInput}
                  onChange={(e) => {
                    const { value, maxLength } = e.target;
                    const input = value.slice(0, maxLength);
                    setPostalInput(input);
                  }}
                />
                <SelectField label="Shipping Option" name="shippingOption">
                  {shippingOptions.map((shipping) => {
                    return (
                      <option key={shipping.id} value={shipping.id}>
                        {shipping.name}
                      </option>
                    );
                  })}
                </SelectField>
                {isAfter(new Date("2019-12-26"), new Date()) && (
                  <Text as="p" color="default" fontSize={0} lineHeight="title" mb={1} mt="-20px">
                    Due to holiday volumes and resulting delays, delivery cannot be guaranteed on or
                    before December 25.
                  </Text>
                )}
                <ClearButton type="submit" width="100%">
                  Save
                </ClearButton>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <Box display={!isValid ? "none" : "block"}>
        <ETouchableOpacity canEdit={isValid} onClick={isValid ? onEditClick : null}>
          <CheckoutFormPreview
            previewObject={{ recipientForm: formValues }}
            shippingOption={shippingOptions.find(
              (option) => option.id === formValues.shippingOption
            )}
          />
        </ETouchableOpacity>
      </Box>
    </CardForm>
  );
}

// == Styles ===============================================================
const ETouchableOpacity = styled(TouchableOpacity)`
  opacity: 0.6;
  cursor: ${({ canEdit }) => (canEdit ? "pointer" : "default")};
`;
