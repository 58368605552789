import React from "react";
import { string } from "prop-types";

const avatarColors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#c9d2d6",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

export default function AvatarImage({ className, src, alt, name }) {
  const defaultAvatarUrl = "defaults/users_avatars";
  if (!src || src.includes(defaultAvatarUrl)) {
    let initials;

    const nameSplit = String(name)
      .toUpperCase()
      .split(" ");

    if (nameSplit.length === 1) {
      initials = nameSplit[0] ? nameSplit[0].charAt(0) : "?";
    } else {
      initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
    }

    const charIndex = (initials === "?" ? 72 : initials.charCodeAt(0)) - 64;
    const colourIndex = charIndex % 20;
    return (
      <div
        className={`${className} letter-avatar`}
        style={{ backgroundColor: avatarColors[colourIndex] }}
      >
        <span className="letter-avatar__letters">{initials}</span>
      </div>
    );
  }

  return <img alt={alt} className={className} src={src} />;
}

AvatarImage.propTypes = {
  className: string,
  src: string,
  alt: string,
  name: string.isRequired,
};

AvatarImage.defaultProps = {
  className: "",
  src: null,
  alt: "",
};
