import React from "react";
import { Modal, Flex, Box, TouchableOpacity, ChevronLeft, H2, Button, ClearButton } from "@atoms";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { RailsUrl } from "@services";
import { useStoreAppValue } from "@hooks";
import {
  EventCancelMutation,
  EventCancelMutationVariables,
} from "./__generated__/EventCancelMutation";
import { ErrorService } from "@services";

// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: () => void;
  eventId: string;
}

// == Constants ============================================================

const CANCEL_EVENT = gql`
  mutation EventCancelMutation($id: ID!) {
    EventCancel(id: $id) {
      ... on Event {
        id
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;

EventCancelModal.defaultProps = {};

// == Component ============================================================

export default function EventCancelModal({ isModalOpen, setIsModalOpen, eventId }: IProps) {
  const { campaignId } = useStoreAppValue();

  const [eventCancelMutation, { loading, called }] = useMutation<
    EventCancelMutation,
    EventCancelMutationVariables
  >(CANCEL_EVENT);

  const onCancelEvent = () => {
    eventCancelMutation({
      variables: {
        id: eventId,
      },
    })
      .then(() => {
        window.location.replace(RailsUrl.eventsUrl({ campaignId: campaignId, eventId: eventId }));
      })
      .catch(ErrorService.error);
  };

  return (
    <Modal isOpen={isModalOpen} onDismiss={() => setIsModalOpen()}>
      <Flex alignItems="baseline" borderBottom="lightGreyBg" justifyContent="flex-start">
        <Box padding={[2, 2, 3]} paddingLeft={3}>
          <TouchableOpacity onTouch={() => setIsModalOpen()}>
            <ChevronLeft color="primary" />
          </TouchableOpacity>
        </Box>
      </Flex>
      <Box textAlign="center" padding={[2, 2, 3]}>
        <H2>Are you sure you want to cancel this event?</H2>
      </Box>
      <Flex my={4}>
        <Box textAlign="center" flex={1}>
          <Button onClick={onCancelEvent}>{!loading || !called ? "Yes" : "Cancelling..."}</Button>
        </Box>
        <Box textAlign="center" flex={1}>
          <ClearButton onClick={() => setIsModalOpen()}>No</ClearButton>
        </Box>
      </Flex>
    </Modal>
  );
}

// == Styles ===============================================================
