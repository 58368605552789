import React from "react";
import { navigate } from "@reach/router";
import { Formik, Form } from "formik";
import styled from "@emotion/styled";
import { addSeconds } from "date-fns";
import { useCookies } from "react-cookie";
import { RadioField } from "@components/common/molecules";
import { Button, H3, Text, Box, Link } from "@atoms";
import { RailsUrl, spelling } from "@services";
import { useStoreAppValue } from "@hooks";
import { BeneficiaryTeamType, PaymentType, Currency, Country } from "@types";
import {
  CampaignBeneficiaryQuery_Campaign_beneficiary,
  CampaignBeneficiaryQuery_Campaign_organization,
  CampaignBeneficiaryQuery_Campaign_category,
} from "../BeneficiaryForm/__generated__/CampaignBeneficiaryQuery";

// == Types ================================================================

interface IProps {
  beneficiary: CampaignBeneficiaryQuery_Campaign_beneficiary;
  category: CampaignBeneficiaryQuery_Campaign_category;
  organization: CampaignBeneficiaryQuery_Campaign_organization;
}

export interface IGooglePlace {
  placeId: string;
}

// == Constants ============================================================

const INITIAL_VALUES = {
  teamType: BeneficiaryTeamType.INDIVIDUAL,
  teamName: "",
  taxId: "",
  website: "",
  dateOfBirth: "",
  firstName: "",
  lastName: "",
  realTeamName: "",
  paymentType: PaymentType.HW_BANK_TRANSFER,
};

const CHECK_TYPES = (currency) => {
  return [
    [
      PaymentType.HW_BANK_TRANSFER,
      "Bank Transfer - Funds deposited within 15-20 business days after request to withdraw funds has been submitted",
    ],
    currency === Currency.USD
      ? [
          PaymentType.POSTAGE,
          `${spelling("checks", {
            country: `${currency === Currency.USD ? "USA" : "Canada"}`,
            capitalize: true,
            singular: true,
          })} - Funds processed and mailed within 20 to 25 business days after request to withdraw funds has been submitted`,
        ]
      : [
          PaymentType.POSTAGE,
          `${spelling("checks", {
            country: `${currency === Currency.USD ? "USA" : "Canada"}`,
            capitalize: true,
            singular: true,
          })} - Funds processed and mailed within 20 to 25 business days after request to withdraw funds has been submitted`,
        ],
  ];
};

BeneficiaryType.defaultProps = {};

// == Component ============================================================

export default function BeneficiaryType({ beneficiary, category, organization }: IProps) {
  const { currency, campaignId } = useStoreAppValue();
  const isCanada = currency === "CAD";
  const [cookies, setCookie] = useCookies(["beneficiaryType", "beneficiaryOrg"]);

  const onSubmit = (values) => {
    const formattedDOB = values.dateOfBirth !== "" ? values.dateOfBirth : "";
    const input = {
      ...INITIAL_VALUES,
      teamType: BeneficiaryTeamType.ORGANIZATION,
      realTeamName: values.realTeamName,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: formattedDOB,
      legalName: values.realTeamName,
      paymentType: values.paymentType,
    };
    setCookie("beneficiaryType", input, { expires: addSeconds(new Date(), 3600) });
    navigate(RailsUrl.newBeneficiaryUrl(campaignId));
  };

  const cookieBeneficiaryOrg = !cookies.beneficiaryOrg
    ? null
    : {
        orgStreet: cookies.beneficiaryOrg.street1,
        orgCity: cookies.beneficiaryOrg.city,
        orgPostalCode: cookies.beneficiaryOrg.postalCode,
        orgProvince: cookies.beneficiaryOrg.province,
        teamName: cookies.beneficiaryOrg.name,
      };
  const orgInputs = !organization
    ? null
    : {
        orgStreet: organization.street1,
        orgCity: organization.city,
        orgPostalCode: organization.postalCode,
        orgProvince: organization.province,
        teamName: organization.name,
      };

  const initialInputValues = {
    ...INITIAL_VALUES,
    ...(cookies.beneficiaryType || beneficiary || null),
    ...(cookieBeneficiaryOrg || orgInputs),
    paymentType: PaymentType.HW_BANK_TRANSFER,
  };

  const initialValues = () => {
    const values = {
      ...initialInputValues,
      currency,
      orgCategory: category?.id,
      orgCountry: isCanada ? Country.CANADA : Country.USA,
    };
    Object.keys(values).map((key) => {
      if (values[key] === null) {
        values[key] = "";
      }
    });
    return values;
  };
  return (
    <Formik
      initialValues={initialValues()}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ setFieldValue }) => {
        return (
          <Form>
            <H3 as="p" color="black" fontWeight="bold" mb={2} mt={3}>
              Payment Method
            </H3>
            <Text as="p" mb={3}>
              Our standard method of payment is via bank transfer, you will be asked to login to
              your online bank account on the next step to verify. Payment by{" "}
              {spelling("checks", {
                country: `${currency === "USD" ? "USA" : "Canada"}`,
                capitalize: false,
                singular: true,
              })}{" "}
              is also available.
            </Text>
            {CHECK_TYPES(currency).map((type) => (
              <RadioField
                label={type[1]}
                name="paymentType"
                key={type[0]}
                value={type[0]}
                onClick={(e) => setFieldValue("paymentType", e.target.value)}
              />
            ))}
            <Box textAlign="center">
              <Button mt={3} type="onSubmit" width={0.8}>
                Next
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

// == Styles ===============================================================

const ELink = styled(Link)`
  position: relative;
  z-index: 10;
`;
