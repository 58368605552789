import React, { useState } from "react";
// import styled from "@emotion/styled";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import gql from "graphql-tag";
import { ArrowCarouselBanner } from "@molecules";
import { Box } from "@atoms";
import { CollectionContentItem } from "../CollectionContentItem";
import { CollectionBannerFragment } from "./__generated__/CollectionBannerFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionBannerFragment;
  storefrontId?: string;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment CollectionBannerFragment on Collection {
      id
      assets {
        ...CollectionContentItemFragment
      }
    }
    ${CollectionContentItem.fragments.fields}
  `,
};

// == Component ============================================================

const CollectionBanner = ({ collection, storefrontId }: IProps) => {
  // great candidate for useToggle instead!
  const [isHovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  if (collection.assets.length === 1) {
    return (
      <>
        {collection.assets.map((collectionAsset, index) => {
          const { title: mainTitle, description: mainDescription, ...asset } = collectionAsset;
          return (
            <Box key={asset.image}>
              {!!asset.image && (
                <CollectionContentItem storefrontId={storefrontId} asset={collectionAsset} />
              )}
            </Box>
          );
        })}
      </>
    );
  }
  return (
    <Box position="relative" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Carousel
        addArrowClickHandler
        infinite
        animationSpeed={1000}
        arrowLeft={isHovered ? <ArrowCarouselBanner arrowDirection="left" /> : null}
        arrowRight={isHovered ? <ArrowCarouselBanner arrowDirection="right" /> : null}
        autoPlay={5000}
        slidesPerPage={1}
        slidesPerScroll={1}
      >
        {collection.assets.map((bannerAsset, index) => {
          const { title: mainTitle, description: mainDescription, ...asset } = bannerAsset;
          return (
            <Box key={asset.image}>
              {!!asset.image && (
                <CollectionContentItem storefrontId={storefrontId} asset={bannerAsset} />
              )}
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

CollectionBanner.fragments = FRAGMENTS;

export default CollectionBanner;

// == Styles ===============================================================
