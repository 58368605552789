import React from "react";
import produce from "immer";
import { subDays, addDays, startOfDay, endOfDay } from "date-fns";
import { DateRangeSentence } from "./DateRangeSentence";

export interface IPropertyFilter {
  model: string;
  column?: string;
  type?: string;
  operator?: string;
  value?: Array<string | boolean | number>;
}

interface IPropertyFiltersProps {
  originalPropertyFilters: IPropertyFilter[];
  propertyInputId: string;
  shopClouds: string[];
}

export function PropertyFilters({
  originalPropertyFilters,
  propertyInputId,
  shopClouds,
}: IPropertyFiltersProps) {
  const [propertyFilters, setPropertyFilters] = React.useState(originalPropertyFilters);
  if (shopClouds.length > 0) {
    columns.users.shop_cloud_id.options = shopClouds.map((item) => `${item.at(0)}||${item.at(1)}`);
    columns.campaigns.shop_cloud_id.options = shopClouds.map(
      (item) => `${item.at(0)}||${item.at(1)}`
    );
  }
  React.useEffect(() => {
    const input: HTMLInputElement | null = document.querySelector(propertyInputId);

    if (input) {
      input.value = JSON.stringify(
        propertyFilters.filter((filter: IPropertyFilter) => {
          return (
            filter.model &&
            filter.column &&
            filter.operator &&
            filter.value &&
            filter.value.length > 0
          );
        })
      );
    }
  }, [propertyFilters, propertyInputId]);

  return (
    <>
      {propertyFilters.map((filter, i) => (
        <Filter
          filter={filter}
          key={i}
          remove={() => {
            setPropertyFilters(
              produce((draft: IPropertyFilter[]) => {
                draft.splice(i, 1);
              })
            );
          }}
          update={(newFilter: IPropertyFilter) => {
            const newFilters = [...propertyFilters];
            newFilters[i] = newFilter;
            setPropertyFilters(newFilters);
          }}
        />
      ))}

      <button
        className="btn btn-default"
        type="button"
        onClick={() => {
          setPropertyFilters([...propertyFilters, { model: "users", value: [] }]);
        }}
      >
        Add
      </button>
    </>
  );
}

const columns = {
  users: {
    rfm_segment: {
      type: "options",
      options: [
        "Premium",
        "Regular",
        "Low",
        "Lapsed",
        "Dormant",
        "Churned",
        "Newly Active",
        "Never Shopped",
      ],
    },
    is_captain_or_co_captain: {
      type: "boolean",
    },
    is_captain: {
      type: "boolean",
    },
    default_partner_campaign_id: {
      type: "presence",
    },
    created_at: {
      type: "datetime",
    },
    purchase_count: {
      type: "integer",
    },
    campaigns_joined_count: {
      type: "integer",
    },
    campaigns_lifetime_count: {
      type: "integer",
    },
    continuity_reason: {
      type: "options",
      options: ["CLOSED", "LEFT", "REMOVED", "CHOICE"],
    },
    is_admin: {
      type: "boolean",
    },
    unsubscribed_at: {
      type: "presence",
    },
    active_roles: {
      type: "options",
      options: [
        "COACH",
        "TREASURER",
        "MANAGER",
        "FUNDRAISER_COORDINATOR",
        "SOCIAL_COORDINATOR",
        "PARENT_OR_PLAYER",
        "SUPPORTER",
        "BUDGET_ADMIN",
      ],
    },
    shop_cloud_id: {
      type: "options",
      options: [],
    },
  },
  campaigns: {
    amount_raised_cents: {
      type: "integer",
    },
    active_fundraisers_count: {
      type: "integer",
    },
    fundraisers_count: {
      type: "integer",
    },
    teams_count: {
      type: "integer",
    },
    teams_claimed_count: {
      type: "integer",
    },
    teams_unclaimed_count: {
      type: "integer",
    },
    created_at: {
      type: "datetime",
    },
    deadline_on: {
      type: "datetime",
    },
    ends_on: {
      type: "date",
    },
    status: {
      type: "options",
      options: ["pending", "approved", "verified", "denied"],
    },
    last_activity_at: {
      type: "datetime",
    },
    submitted_at: {
      description: "Filled in when the campaign submits their beneficiary details.",
      type: "presence",
    },
    referral_id: {
      type: "presence",
      description: "Filled in when campaign is referred by a fundraiser",
    },
    funds_distribution: {
      type: "options",
      options: ["TEAM", "PLAYER"],
    },
    ends_on_presence: {
      type: "presence",
    },
    owner_id: {
      type: "presence",
    },
    external_partner_ref: {
      type: "presence",
    },
    target_cents: {
      type: "integer",
    },
    bank_initialized_at: {
      type: "datetime",
    },
    budget_initialized_at: {
      type: "datetime",
    },
    shop_initialized_at: {
      type: "datetime",
    },
    partner_id: {
      type: "integer",
    },
    shop_cloud_id: {
      type: "options",
      options: [],
    },
  },
};

const typeOperators = (type: string) => {
  if (type === "integer") {
    return {
      "<": "Less Than",
      "<=": "At Most",
      "=": "Exactly",
      ">=": "At Least",
      ">": "Over",
    };
  }
  if (type === "boolean") {
    return {
      "=": "Exactly",
    };
  }
  if (type === "options") {
    return {
      in: "In",
    };
  }
  if (type === "datetime" || type === "date") {
    return {
      after: "After",
      before: "Before",
    };
  }
  if (type === "presence") {
    return {
      is: "Is",
    };
  }

  return {};
};

interface IFilterProps {
  filter: IPropertyFilter;
  update: (filter: IPropertyFilter) => void;
  remove: () => void;
}

function Filter({ filter, update, remove }: IFilterProps) {
  const operators = filter.type ? typeOperators(filter.type) : [];
  if (filter.column == "ends_on_presence") {
    filter.column = filter.column.replace("_presence", "");
  }

  return (
    <div style={{ padding: "10px", border: "1px solid #CFD8DC", margin: "0px 0px 10px 0px" }}>
      <div className="form-inline">
        <select
          className="form-control native"
          value={filter.model}
          onChange={(event) => {
            update({ ...filter, model: event.target.value });
          }}
        >
          <option value="">(choose)</option>
          {Object.keys(columns).map((model: string) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>

        {filter.model ? (
          <select
            className="form-control native"
            value={filter.column}
            onChange={(event) => {
              const { type } = columns[filter.model][event.target.value];

              // try to guess operator (if only 1)
              let operator = "";
              const operatorKeys = Object.keys(typeOperators(type));
              if (operatorKeys.length === 1) {
                operator = operatorKeys[0];
              }

              update({ ...filter, column: event.target.value, type, operator });
            }}
          >
            <option value="">(choose)</option>
            {Object.keys(columns[filter.model]).map((model: string) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </select>
        ) : null}

        {filter.model && filter.column ? (
          <select
            className="form-control native"
            value={filter.operator}
            onChange={(event) => {
              update({ ...filter, operator: event.target.value });
            }}
          >
            <option value="">(choose)</option>
            {Object.entries(operators).map(([value, display]: any) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </select>
        ) : null}
      </div>

      {filter.type === "boolean" && (
        <BooleanInput
          name={`${filter.model}.${filter.column}`}
          update={(value: boolean) => {
            update({ ...filter, value: [value] });
          }}
          value={filter.value[0]}
        />
      )}
      {filter.type === "integer" && (
        <IntegerInput
          update={(value: number) => {
            update({ ...filter, value: [value] });
          }}
          value={filter.value[0]}
        />
      )}
      {(filter.type === "datetime" || filter.type === "date") && (
        <>
          <DateInput
            days={filter.value[0]}
            duration={filter.value[1]}
            update={(days: number, duration: number) => {
              update({ ...filter, value: [days, duration] });
            }}
          />
          {filter.operator === "after" ? (
            <DisplayAfter days={filter.value[0]} duration={filter.value[1]} />
          ) : null}
          {filter.operator === "before" ? (
            <DisplayBefore days={filter.value[0]} duration={filter.value[1]} />
          ) : null}
        </>
      )}
      {filter.type === "options" && (
        <OptionsInput
          options={columns[filter.model][filter.column].options}
          update={(values: string[]) => {
            update({ ...filter, value: values });
          }}
          values={filter.value}
        />
      )}
      {filter.type === "presence" && (
        <PresenceInput
          name={`${filter.model}.${filter.column}`}
          update={(value: string) => {
            update({ ...filter, value: [value] });
          }}
          value={filter.value[0]}
        />
      )}

      <button className="btn btn-danger mt-3" type="button" onClick={remove}>
        Remove
      </button>
    </div>
  );
}

interface IBooleanInputProps {
  update: (value: boolean) => void;
  value: boolean;
  name: string;
}

function BooleanInput({ update, value, name }: IBooleanInputProps) {
  if (value === undefined || value === null) {
    value = false;
    update(false);
  }

  return (
    <div>
      <label className="radio-inline">
        <input
          checked={value === true}
          name={name}
          style={{ marginLeft: "-20px" }}
          type="radio"
          value="true"
          onChange={() => {
            update(true);
          }}
        />
        True
      </label>
      <label className="radio-inline">
        <input
          checked={value === false}
          name={name}
          style={{ marginLeft: "-20px" }}
          type="radio"
          value="false"
          onChange={() => {
            update(false);
          }}
        />
        False
      </label>
    </div>
  );
}

interface IIntegerInputProps {
  update: (value: number) => void;
  value: number;
}

function IntegerInput({ update, value }: IIntegerInputProps) {
  return (
    <div className="form-group">
      <input
        className="form-control"
        type="number"
        value={value}
        onChange={(event) => {
          update(parseInt(event.target.value, 10));
        }}
      />
    </div>
  );
}

interface IOptionsInputProps {
  update: (value: string[]) => void;
  values: string[];
  options: string[];
}

function OptionsInput({ update, values, options }: IOptionsInputProps) {
  return (
    <div className="form-group">
      <select
        multiple
        className="form-control native"
        value={values}
        onChange={(event) => {
          update(Array.from(event.target.selectedOptions).map((option) => option.value));
        }}
      >
        {options.map((val: string) => (
          <option
            key={val.split("||").at(1) || val.split("||").at(0)}
            value={val.split("||").at(1) || val.split("||").at(0)}
          >
            {val.split("||").at(0)}
          </option>
        ))}
      </select>
    </div>
  );
}

interface IDateInputProps {
  update: (days: number, duration: number) => void;
  days: number;
  duration: number;
}

function DateInput({ update, days, duration }: IDateInputProps) {
  return (
    <div className="form-inline">
      <div className="input-group">
        <input
          className="form-control"
          type="number"
          value={days}
          onChange={(event) => {
            update(parseInt(event.target.value, 10), duration);
          }}
        />
        <div className="input-group-addon">days</div>
      </div>{" "}
      for{" "}
      <div className="input-group">
        <input
          className="form-control"
          type="number"
          value={duration}
          onChange={(event) => {
            update(days, parseInt(event.target.value, 10));
          }}
        />
        <div className="input-group-addon">days</div>
      </div>
    </div>
  );
}

interface IPresenceInputProps {
  update: (value: string) => void;
  value: string;
  name: string;
}

function PresenceInput({ update, value, name }: IPresenceInputProps) {
  return (
    <div>
      <label className="radio-inline">
        <input
          checked={value === "null"}
          name={name}
          style={{ marginLeft: "-20px" }}
          type="radio"
          value="null"
          onChange={() => {
            update("null");
          }}
        />
        Missing
      </label>
      <label className="radio-inline">
        <input
          checked={value === "not null"}
          name={name}
          style={{ marginLeft: "-20px" }}
          type="radio"
          value="not null"
          onChange={() => {
            update("not null");
          }}
        />
        Present
      </label>
    </div>
  );
}

interface IDisplayAfterProps {
  days: number | null;
  duration: number | null;
}

function DisplayAfter({ days, duration }: IDisplayAfterProps) {
  if (days === undefined || days === null || duration === null || duration === undefined) {
    return <div>missing days or duration</div>;
  }
  if (Number.isNaN(days) || Number.isNaN(duration)) {
    return <div>missing days or duration</div>;
  }

  const now = new Date();
  const stop = endOfDay(subDays(now, days));
  const start = startOfDay(subDays(stop, duration - 1));

  return <DateRangeSentence now={now} start={start} stop={stop} />;
}

interface IDisplayBeforeProps {
  days: number | null;
  duration: number | null;
}

function DisplayBefore({ days, duration }: IDisplayBeforeProps) {
  if (days === undefined || days === null || duration === null || duration === undefined) {
    return <div>missing days or duration</div>;
  }
  if (Number.isNaN(days) || Number.isNaN(duration)) {
    return <div>missing days or duration</div>;
  }

  const now = new Date();
  const stop = endOfDay(addDays(now, days));
  const start = startOfDay(subDays(stop, duration - 1));

  return <DateRangeSentence now={now} start={start} stop={stop} />;
}
