import React from "react";
import { Card, H3, Text, Flex } from "@components/common/atoms";
import { BrandCircle } from "../../brandCircleComponents";
import { Event_Event } from "@components/common/pages/eventPageComponents/EventEditView/__generated__/Event";
// import { theme } from "@styles";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  event: Event_Event;
}

// == Constants ============================================================

EventEditInformation.defaultProps = {};

// == Component ============================================================

export default function EventEditInformation({ event }: IProps) {
  return (
    <Card mb={3} p={3} bg="lightPrimary">
      <Flex>
        <BrandCircle width="100px" brand={event.brand} mr={3}></BrandCircle>
        <Flex justifyContent="center" flexDirection="column">
          <H3 fontWeight="bold">{event.brand.name}</H3>
          <Text>{event.address}</Text>
        </Flex>
      </Flex>
    </Card>
  );
}

// == Styles ===============================================================
