import React, { useRef } from "react";
import Downshift from "downshift";
import { useDebouncedCallback } from "use-debounce";
// import styled from "@emotion/styled";
// import { Box } from "@atoms";
import { constants } from "@services";
import { SearchInput } from "@molecules";
// import OnboardingBrandsSearchResultsQuery from "../OnboardingBrandsSearchResults/OnboardingBrandsSearchResultsQuery";

// == Types ================================================================

interface IProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  // activeBrands: string[];
  onBrandClick: (brandId: string, brand: $FixMe) => void;
}

// == Constants ============================================================

OnboardingBrandsSearch.defaultProps = {};

// == Component ============================================================

export function OnboardingBrandsSearch({
  inputValue,
  setInputValue,
  // activeBrands,
  onBrandClick,
}: IProps) {
  const inputRef = useRef();
  const [onStateChange] = useDebouncedCallback(({ inputValue: newInputValue }) => {
    if (typeof newInputValue !== "undefined") setInputValue(newInputValue);
  }, 300);

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.touchEnd:
      case Downshift.stateChangeTypes.blurInput:
      case Downshift.stateChangeTypes.mouseUp:
        return {
          ...changes,
          inputValue: state.inputValue,
        };
      default:
        return changes;
    }
  };

  return (
    <Downshift
      initialIsOpen
      itemToString={(item) => (item ? item.value : "")}
      stateReducer={stateReducer}
      onStateChange={onStateChange}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        // isOpen,
        // inputValue,
        highlightedIndex,
        selectedItem,
        clearSelection,
      }) => (
        <div style={{ position: "relative", flex: 1 }}>
          <SearchInput
            placeholder={constants.SEARCH_PLACEHOLDER}
            showClear={inputValue.length > 0}
            onClearClick={() => {
              clearSelection();
              setInputValue("");
              if (inputRef.current) {
                inputRef.current.value = "";
                inputRef.current.focus();
              }
            }}
            {...getInputProps()}
            containerProps={{
              mb: 1,
              mx: 3,
              position: "relative",
            }}
            ref={inputRef}
            size="small"
          />
          {/* {inputValue.length > 0 && (
            <EResultsContainer {...getMenuProps()}>
              <OnboardingBrandsSearchResultsQuery
                activeBrands={activeBrands}
                inputValue={inputValue}
                onBrandClick={onBrandClick}
              />
            </EResultsContainer>
          )} */}
        </div>
      )}
    </Downshift>
  );
}

// == Styles ===============================================================
