import React from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { keyframes, css } from "@emotion/core";
import styled from "@emotion/styled";
import { CircleLoader } from "@components/common/atoms/CircleLoader";
import { BrandLogo } from "@organisms";
import { Box, Flex, Text, H3 } from "@atoms";
import { IntentLoadingBulkShopViewFragment } from "./__generated__/IntentLoadingBulkShopViewFragment";

// == Types ================================================================
interface IProps {
  intent: IntentLoadingBulkShopViewFragment;
  givePercent: string;
  logoUrl: string;
  name?: string;
}

type TProps = RouteComponentProps<IProps>;

// == Constants ============================================================

IntentLoadingBulkShopView.defaultProps = {};
IntentLoadingBulkShopView.fragments = gql`
  fragment IntentLoadingBulkShopViewFragment on Intent {
    id
    token
    buyer_name
    link {
      id
      giveAmount
      merchant {
        id
        website
      }
      defaultBrand {
        id
        name
        ...BrandLogoFragment
      }
    }
    campaign {
      id
      name
    }
  }
  ${BrandLogo.fragments.fields}
`;

// == Component ============================================================

export default function IntentLoadingBulkShopView() {
  return (
    <div className="full-screen-body full-screen-body--centered centered">
      <Flex
        alignItems="center"
        alignSelf="stretch"
        bg="white"
        flex="1"
        flexDirection="column"
        justifyContent="center"
      >
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
          position="relative"
        >
          <Box mt={5} mx={2} textAlign="center" zIndex={1}>
            <CircleLoader delay={null} />
          </Box>
        </Flex>
        <Flex alignItems="center" bg="lightPrimaryBg" justifyContent="center" py={4} width="100%">
          <H3 color="black" fontWeight="bold">
            <span
              css={css`
                animation: ${showHideDot} 1s ease infinite;
                animation-delay: 0.2s;
              `}
            >
              .
            </span>
            <span
              css={css`
                animation: ${showHideDot} 1s ease infinite;
                animation-delay: 0.2s;
              `}
            >
              .
            </span>
            <span
              css={css`
                animation: ${showHideDot} 1s ease infinite;
                animation-delay: 0.4s;
              `}
            >
              .
            </span>
          </H3>
        </Flex>
      </Flex>
    </div>
  );
}

// == Styles ===============================================================
const showHideDot = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const EContentFadeBox = styled(Box)`
  transition: opacity 0.3s ease-in;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
`;
