import React from "react";
import { Location, LocationContext } from "@reach/router";
import { ELinkModalContainer } from "@organisms/linkModalComponents/LinkModal";
import { useToggle, useStoreAppValue } from "@hooks";
import { Box, Text, TouchableOpacity, FontAwesome } from "@atoms";
import { FadeIn } from "@utils";
import { InStoreLinkedCards } from "../InStoreLinkedCards";
import { InStorePromo } from "../InStorePromo";
import { InStoreHero } from "../InStoreHero";
import { LinkModalPostShopQuery } from "../../linkModalComponents/LinkModalPostShop";
import { LinkModalWaysToEarn } from "../../linkModalComponents/LinkModalWaysToEarn";
import { InStoreTerms } from "../InStoreTerms";
import { LinkModalBorder } from "../../linkModalComponents/LinkModalBorder";
import { InStoreModalQuery_Link } from "./__generated__/InStoreModalQuery";
// import styled from "@emotion/styled";

// == Types ================================================================
type TLink = InStoreModalQuery_Link;

interface IProps {
  link: TLink;
  campaignId?: string;
  linkedCards: [];
  locationContext: LocationContext;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  campaignId: null,
};

// == Component ============================================================

const InStoreModal = ({ link, campaignId, linkedCards, locationContext }: IProps) => {
  const [showPostShopModal, togglePostShopModal] = useToggle(false);
  const { currentUser } = useStoreAppValue();
  const onLinkClick = () => {
    togglePostShopModal();
  };
  const renderInStoreModal = ({ location, navigate }: LocationContext) => {
    const onBackClick = () => {
      if (location.state.brandRoute) {
        navigate(location.state.brandRoute);
      } else {
        navigate(location.pathname);
      }
    };
    return (
      <FadeIn>
        <ELinkModalContainer>
          {!!link.location || !!link.nearestLocation ? (
            <>
              <InStoreHero
                campaignId={campaignId}
                link={link}
                linkedCardsAvailable={linkedCards.length > 0}
                linkLocation={link.location ? link.location : link.nearestLocation}
                locationContext={locationContext}
                p={3}
                pb={4}
                onBackClick={onBackClick}
                onLinkClick={onLinkClick}
              />
              <LinkModalBorder />

              <LinkModalWaysToEarn link={link} />
              <LinkModalBorder />

              {!!link.promoTitle && !!link.promoDescription && (
                <>
                  <InStorePromo link={link} p={3} />
                  <LinkModalBorder />
                </>
              )}

              {currentUser && (
                <>
                  <InStoreLinkedCards link={link} linkedCards={linkedCards} p={3} />
                  <LinkModalBorder />
                </>
              )}

              <InStoreTerms link={link} p={3} />
            </>
          ) : (
            <>
              <Box pb={4} textAlign="center">
                <Box p={3}>
                  <TouchableOpacity onTouch={onBackClick}>
                    <FontAwesome icon="arrow-left" />
                  </TouchableOpacity>
                </Box>
                <Text>There are no nearby location for this brand.</Text>
              </Box>
            </>
          )}
        </ELinkModalContainer>
      </FadeIn>
    );
  };
  const renderPostShopModal = ({ location, navigate }: LocationContext) => {
    const onBackClick = () => {
      navigate(location.pathname);
    };
    return <LinkModalPostShopQuery link={link} />;
  };
  if (!showPostShopModal) {
    return (
      <Location>
        {({ location, navigate }: LocationContext) => renderInStoreModal({ location, navigate })}
      </Location>
    );
  }
  return (
    <Location>
      {({ location, navigate }: LocationContext) => renderPostShopModal({ location, navigate })}
    </Location>
  );
};

InStoreModal.defaultProps = DEFAULT_PROPS;
export default InStoreModal;

// == Styles ===============================================================
