import constants from "@services/constants";
import { ErrorService } from "@services/ErrorService";
import { PlatformEnum } from "@types";

// == Types ================================================================

interface ITrackPropertiesAllowedTypes {
  [key: string]: string | number | boolean | undefined;
}

interface IUtmProperties {
  utm_source?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  utm_channel?: string;
}

interface ISharedProperties {
  platform?: keyof typeof PlatformEnum;
  agent?: string;
  analyticsLocation: string;
}

type TDefaultTrackingProperties = IUtmProperties & ITrackPropertiesAllowedTypes;
type TSharedBaseProperties = ISharedProperties & TDefaultTrackingProperties;

type TBranchLogEventTypes = "SEARCH" | "SHARE" | "COMPLETE_REGISTRATION" | "VIEW_ITEM" | "INVITE";

interface IBranchLogEventProperties {
  /** String indicating a transaction ID */
  transaction_id?: string;
  /** An ISO currency code (e.g. USD, JPY, EUR) */
  currency?: "USD" | "CAD";
  /** Revenue associated with this event as a string or number */
  revenue?: string | number;
  /** Shipping cost associated with this event as a string or number */
  shipping?: string | number;
  /** Tax associated with this event as a string or number */
  tax?: string | number;
  /** String indicating a coupon code for this event */
  coupon?: string;
  /** String indicating an affiliation for this event */
  affiliation?: string;
  /** String indicating a description for this event */
  description?: string;
  /** String indicating a search query for this event */
  search_query?: string;
  /** Object containing arbitrary key-value pairs for this event. */
  customData?: ITrackPropertiesAllowedTypes;
}

interface IAnalytics {
  track: (action: string, properties: ITrackPropertiesAllowedTypes) => void;
}

// == Constants ============================================================

// == Functions ============================================================

function isWindowObjectMissing(objectName: string) {
  if (window[objectName]) return false;

  // eslint-disable-next-line no-console
  if (constants.IS_DEV) console.warn(`window.${objectName} is not set`);
  ErrorService.error(new Error(`window.${objectName} is not set`));
  return true;
}

function cleanProperties({ analyticsLocation, ...properties }: TSharedBaseProperties) {
  // Switch analyticsLocation for location to satisfy historical data, but make searching code easier
  return { location: analyticsLocation || "", platform: constants.PLATFORM, ...properties };
}

function segmentTrack(action: string, properties: TSharedBaseProperties) {
  try {
    if (constants.IS_SUPER_ADMIN) return;
    if (isWindowObjectMissing("analytics")) return;
    const { analytics }: { analytics: IAnalytics } = window;
    if (constants.IS_DEV) console.log(`Segment Track: "${action}"`, properties);
    analytics.track(action, cleanProperties(properties));
  } catch (error) {
    ErrorService.error(error);
  }
}

function branchLogEvent(action: TBranchLogEventTypes, properties: IBranchLogEventProperties) {
  try {
    if (constants.IS_SUPER_ADMIN) return;
    if (isWindowObjectMissing("branch")) return;

    // Convert all customData values to string
    if (properties.customData) {
      properties.customData = Object.fromEntries(
        Object.entries(properties.customData).map(([key, value]: [string, unknown]) => {
          return [key, String(value)];
        })
      );
    }

    const { branch } = window;
    branch.logEvent(action, properties);
  } catch (error) {
    ErrorService.error(error);
  }
}

function agentName(isOwner = false) {
  return isOwner ? "owner" : "team-member";
}

// == Exports ==============================================================

interface IClickOfferProperties extends TSharedBaseProperties {
  analyticsLocation:
    | "occasion"
    | "store"
    | "shopping_list"
    | "activity_feed"
    | "notifications"
    | "home_search"
    | "store_search"
    | "directory"
    | "brands_show";
  linkId: string;
  linkType: string;
  promotionId?: number;
  promotionSlug?: string;
  collectionId?: string;
  collectionName?: string;
  collectionSlug?: string;
  occasionId?: number;
  occasionSlug?: string;
  storefrontId?: number;
  storefrontSlug?: string;
}
export const clickOffer = (
  properties: IClickOfferProperties
  // branch: IBranchLogEventProperties
) => {
  segmentTrack(`Click Offer`, {
    ...properties,
  });
  // branchLogEvent("VIEW_ITEM", {
  //   ...branch,
  //   customData: properties,
  // });
};

// ================================================================================

interface ISearchProperties extends TSharedBaseProperties {
  analyticsLocation: "store";
  query: string;
}
export const search = (properties: ISearchProperties, branch: IBranchLogEventProperties) => {
  segmentTrack("Search", properties);
  branchLogEvent("SEARCH", {
    ...branch,
    customData: properties,
  });
};

// ================================================================================

interface IShareTeamProperties extends TSharedBaseProperties {
  analyticsLocation: "store";
  campaignId: string;
}

export const shareTeam = (properties: IShareTeamProperties, branch: IBranchLogEventProperties) => {
  branchLogEvent("SHARE", {
    ...branch,
    customData: properties,
  });
};

// ================================================================================

interface ITeamCreateProperties extends TSharedBaseProperties {
  campaignId: string;
}
export const teamCreate = (
  properties: ITeamCreateProperties,
  branch: IBranchLogEventProperties
) => {
  branchLogEvent("COMPLETE_REGISTRATION", {
    ...branch,
    customData: properties,
  });
};

// ================================================================================

interface IInviteProperties extends TSharedBaseProperties {
  campaignId: string;
}
export const invite = (properties: IInviteProperties, branch: IBranchLogEventProperties) => {
  branchLogEvent("INVITE", {
    ...branch,
    customData: properties,
  });
};

// ================================================================================

interface IOnboardingStartProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingStart = (properties: IOnboardingStartProperties, isOwner: boolean) => {
  segmentTrack("Survey Start", {
    analyticsLocation: "onboarding-intro",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingQuestionProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingQuestion = (
  name: string,
  properties: IOnboardingQuestionProperties,
  isOwner: boolean
) => {
  segmentTrack(`Survey ${name}`, {
    analyticsLocation: "onboarding-question",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingFinishProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingFinish = (properties: IOnboardingFinishProperties, isOwner: boolean) => {
  segmentTrack(`Survey Finish`, {
    analyticsLocation: "onboarding-questions-finish",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingAddFavoritesProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingAddFavorite = (
  properties: IOnboardingAddFavoritesProperties,
  isOwner: boolean
) => {
  segmentTrack(`Favourites - Add`, {
    analyticsLocation: "onboarding-favorites",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingFinishFavoritesProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingFinishFavorites = (
  properties: IOnboardingFinishFavoritesProperties,
  isOwner: boolean
) => {
  segmentTrack(`Select Favorites`, {
    analyticsLocation: "onboarding-favorites",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingSetGoalProperties extends TDefaultTrackingProperties {
  campaignId: string;
  size: number;
}
export const onboardingSetGoal = (properties: IOnboardingSetGoalProperties, isOwner: boolean) => {
  segmentTrack(`Set Goal`, {
    analyticsLocation: "onboarding-goal",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingSetDeadlineProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingSetDeadline = (
  properties: IOnboardingSetDeadlineProperties,
  isOwner: boolean
) => {
  segmentTrack(`Set Deadline`, {
    analyticsLocation: "onboarding-goal",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingSetTeamSizeProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingSetTeamSize = (
  properties: IOnboardingSetTeamSizeProperties,
  isOwner: boolean
) => {
  segmentTrack(`Set Team Size`, {
    analyticsLocation: "onboarding-goal",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingSetTeamSizeProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingSetCustomGoal = (
  properties: IOnboardingSetTeamSizeProperties,
  isOwner: boolean
) => {
  segmentTrack(`Set Custom Goal`, {
    analyticsLocation: "onboarding-goal",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingFirstViewShopProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingFirstViewShop = (
  properties: IOnboardingFirstViewShopProperties,
  isOwner: boolean
) => {
  segmentTrack(`First View - Shop`, {
    analyticsLocation: "onboarding-welcome",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface IOnboardingFirstViewExploreProperties extends TDefaultTrackingProperties {
  campaignId: string;
}
export const onboardingFirstViewExplore = (
  properties: IOnboardingFirstViewExploreProperties,
  isOwner: boolean
) => {
  segmentTrack(`First View - Explore`, {
    analyticsLocation: "onboarding-welcome",
    agent: agentName(isOwner),
    ...properties,
  });
};

// ================================================================================

interface ICollectionViewProperties extends TDefaultTrackingProperties {
  collectionId: string;
  collectionName: string;
}
export const trackCollectionView = (properties: ICollectionViewProperties) => {
  segmentTrack(`Collection Viewed`, {
    analyticsLocation: "storefront",
    ...properties,
  });
};

// ================================================================================

interface ICloseReportTrackingProperties extends TDefaultTrackingProperties {
  campaignId: string;
  totalRaised: "0" | "<100" | ">=100";
}

type TCloseTeamEvents =
  | "Viewed Close Report"
  | "Click Survey"
  | "Click Create New Team"
  | "Click Personal Account"
  | "Activate Personal Account"
  | "Click Submit Testimonial";

export const trackCloseReportEvent = (
  event: TCloseTeamEvents,
  properties: ICloseReportTrackingProperties
) => {
  segmentTrack(event, {
    analyticsLocation: "team_close_report",
    ...properties,
  });
};
