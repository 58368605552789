import React from "react";
import styled from "@emotion/styled";
import { Box, Flex } from "@atoms";

interface IProps {
  group: $FixMe;
}

export default function GoalMeter({ group }: IProps) {
  const goalCents = group?.goal?.centsAmount;
  const amountRaised = group?.amountRaised?.centsAmount;
  const goalRemaining = goalCents - amountRaised;

  const chartHeight = [, 8, 12];

  const widthCalculator = (numer: number, denom: number) => {
    if (numer / denom > 1) return "100%";
    return `${(numer / denom) * 100}%`;
  };

  return (
    <EGoalMeterContainer
      border="primary"
      borderRadius={chartHeight.map((height) => height / 2)}
      height={chartHeight}
      width="50%"
    >
      <Box bg="primary" height={chartHeight} width={widthCalculator(amountRaised, goalCents)} />
      <Box bg="white" height={chartHeight} width={widthCalculator(goalRemaining, goalCents)} />
    </EGoalMeterContainer>
  );
}

// == Styles ===============================================================

const EGoalMeterContainer = styled(Flex)`
  overflow: hidden;
`;
