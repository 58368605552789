import React from "react";
// import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Box, Flex, Section, Column, Row } from "@atoms";
import { CollectionContentItem } from "../CollectionContentItem";
import { CollectionCollageFragment } from "./__generated__/CollectionCollageFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionCollageFragment;
  storefrontId?: string;
}
// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment CollectionCollageFragment on Collection {
      id
      assets {
        ...CollectionContentItemFragment
      }
    }
    ${CollectionContentItem.fragments.fields}
  `,
};

// == Component ============================================================

const CollectionCollage = ({ collection, storefrontId }: IProps) => {
  const { title: mainTitle, description: mainDescription, ...mainAsset } = collection.assets[0];
  const { title: leftTitle, description: leftDescription, ...leftAsset } = collection.assets[1];
  const { title: rightTitle, description: rightDescription, ...rightAsset } = collection.assets[2];

  return (
    <Section>
      <Row flexWrap="wrap" mx={0}>
        {!!mainAsset.image && (
          <Column collapse autoColumn={false} lineHeight={0} p="1px" width={[1, 1, 3 / 4]}>
            <CollectionContentItem asset={mainAsset} storefrontId={storefrontId} />
          </Column>
        )}
        <Column collapse autoColumn={false} lineHeight={0} width={[1, 1, 1 / 4]}>
          <Flex flexDirection={["row", "row", "column"]}>
            {!!leftAsset.image && (
              <Box p="1px" width={[1 / 2, 1 / 2, 1]}>
                <CollectionContentItem asset={leftAsset} storefrontId={storefrontId} />
              </Box>
            )}
            {!!rightAsset.image && (
              <Box p="1px" width={[1 / 2, 1 / 2, 1]}>
                <CollectionContentItem asset={rightAsset} storefrontId={storefrontId} />
              </Box>
            )}
          </Flex>
        </Column>
      </Row>
    </Section>
  );
};

export default CollectionCollage;

CollectionCollage.fragments = FRAGMENTS;
// == Styles ===============================================================
