import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Flex, Box, TouchableOpacity, Text, H3, FontAwesome, Image } from "@atoms";
import { FullStoryEventNames, pluralize } from "@services";
import { useFullStoryEvents, useFullStoryTrackingLocation } from "@hooks";

// == Types ================================================================

interface IProps {
  title: string;
  description: string;
  linkId: string;
  onClick: (linkId: string, locationId?: string) => void;
  giveSentence: string;
  showBorder?: boolean;
  locationId?: string;
  leftImage: string;
  promotionsCount: number;
  merchantName?: string;
  intentType?: "shop" | "giftcard";
  brandName?: string;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  showUpTo: false,
  showBorder: false,
  promotionsCount: 0,
};

const CIRCLE_SIZE = "60px";

// == Component ============================================================

const BrandModalLinkListItem = ({
  title,
  description,
  linkId,
  onClick,
  giveSentence,
  showBorder,
  leftImage,
  locationId,
  promotionsCount,
  merchantName,
  intentType,
  brandName,
}: IProps) => {
  const { collection, storefront } = useFullStoryTrackingLocation();
  const { sendFullStoryEvent } = useFullStoryEvents();

  const handleClick = useCallback(() => {
    sendFullStoryEvent(FullStoryEventNames.intentInitiated, {
      brandName: brandName ?? "",
      intentType: intentType ?? "",
      collectionName: collection,
      storefrontName: storefront,
    });
    onClick(linkId, locationId);
  }, [linkId, locationId, intentType, collection, storefront]);

  return (
    <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={handleClick}>
      <Flex alignItems="center" borderTop={showBorder ? "silver" : ""} p={3}>
        <Box alignSelf="flex-start" flex="0 0 auto" mr={3}>
          <Image alt={title} src={leftImage} width={CIRCLE_SIZE} />
        </Box>
        <Box>
          <H3 display="inline-block" pr={2}>
            {title}
          </H3>
          {promotionsCount > 0 && (
            <Box display="inline-block" position="relative" top={-2}>
              <EDealsText color="notification" fontSize={0} px={2}>
                {pluralize("Deal", promotionsCount)}
              </EDealsText>
            </Box>
          )}
          <Text color="primary" display="block" my={1}>
            {giveSentence}
          </Text>
          <Text>{description}</Text>
        </Box>
        <Flex alignItems="center" flex="1 0 auto" justifyContent="flex-end" ml={4} px={3}>
          <Text color="primary">
            <FontAwesome icon="chevron-right" />
          </Text>
        </Flex>
      </Flex>
    </TouchableOpacity>
  );
};

BrandModalLinkListItem.defaultProps = DEFAULT_PROPS;
export default BrandModalLinkListItem;

const EDealsText = styled(Text)`
  border: ${({ theme }) => theme.borders.modal};
  border-radius: ${({ theme }) => `${theme.radii.badge}px`};
  color: ${({ theme }) => theme.colors.linkPromoText};
  background-color: ${({ theme }) => theme.colors.linkPromo};
  border: ${({ theme }) => `1px solid ${theme.colors.linkPromo}`};
`;
