import React from "react";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Box } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  height?: number;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  height: 300,
};
// == Component ============================================================

const LoadingBox = ({ height }: IProps) => {
  const LoadingComponent = () => (
    <Box data-testid="loading-container" mb={3} width="100%">
      <RectShape color="#E0E0E0" style={{ height }} />
    </Box>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1500}
      ready={false}
    >
      {null}
    </ReactPlaceholder>
  );
};

LoadingBox.defaultProps = DEFAULT_PROPS;
export default LoadingBox;

// == Styles ===============================================================
