import React from "react";
import { Field, ErrorMessage } from "formik";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  checked: boolean;
  name: string;
  label: string;
  value: string;
  hint?: string;
  labelSize: "small" | "normal";
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  name: null,
  label: "",
  checked: false,
  value: false,
  hint: null,
  labelSize: "normal",
};

// == Component ============================================================

export const Checkbox = ({ label, name, hint, labelSize, ...rest }: IProps) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }) => (
          <p>
            <input
              className="filled-in"
              id={name}
              type="checkbox"
              {...rest}
              checked={!!field.value}
              onChange={() => {
                form.setFieldValue(name, !field.value);
              }}
            />
            <label className={labelSize === "small" ? "label--small" : ""} htmlFor={name}>
              {label}
            </label>
          </p>
        )}
      </Field>
      <ErrorMessage className="standalone-error" component="span" name={name} />
      {!!hint && <span className="checkbox-hint">{hint}</span>}
    </>
  );
};

Checkbox.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
