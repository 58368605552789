import React from "react";
import PropTypes from "prop-types";
import NotificationActivity from "./NotificationActivity";

export default class Notification extends React.Component {
  static propTypes = {
    notification: PropTypes.shape({}).isRequired,
  };

  statusClass = (notification) => {
    if (notification.isRead) {
      return "notification--read";
    } else if (notification.isSeen) {
      return "notification--seen";
    }
    return "notification--new";
  };

  render() {
    const { notification } = this.props;
    const { activities } = notification;

    const first = activities[0];

    return (
      <div className={`notification-item ${this.statusClass(notification)}`}>
        <NotificationActivity activity={first} key={first.id} notification={notification} />
      </div>
    );
  }
}
