import React from "react";
import gql from "graphql-tag";
import { Breakpoint } from "react-socks";
import { useSpring, animated } from "react-spring";
import { Box, Button } from "@atoms";
import { renderIf } from "@services";
import { IntentListItem } from "../IntentListItem";
import { IntentListHeader } from "../IntentListHeader";
import { IntentListEmpty } from "../IntentListEmpty";
import { IntentListFragment } from "./__generated__/IntentListFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  intents: IntentListFragment[];
  loadingMore: boolean;
  hasNextPage: boolean;
  onFetchMore: () => void;
}

interface IIntentListContainerProps {
  intent: IntentListFragment;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment IntentListFragment on Intent {
      id
      ...IntentListItemFragment
    }
    ${IntentListItem.fragments.fields}
  `,
};
IntentList.fragments = FRAGMENTS;
IntentList.defaultProps = {};

// == Component ============================================================

const IntentListItemContainer = ({ intent }: IIntentListContainerProps) => {
  const springProps = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <animated.div style={springProps}>
      <IntentListItem intent={intent} />
    </animated.div>
  );
};

export default function IntentList({ intents, loadingMore, onFetchMore, hasNextPage }: IProps) {
  return (
    <Box>
      {intents.length >= 1 ? (
        <>
          <Breakpoint medium up>
            <IntentListHeader />
          </Breakpoint>
          {intents.map((intent, index) => {
            return <IntentListItemContainer intent={intent} key={intent.id} />;
          })}
        </>
      ) : (
        <IntentListEmpty />
      )}
      {renderIf(hasNextPage)(
        <Box mt={3} textAlign="center">
          <Button disabled={loadingMore} onClick={onFetchMore}>
            {loadingMore ? "Loading..." : "Load More"}
          </Button>
        </Box>
      )}
    </Box>
  );
}

// == Styles ===============================================================
