import React, { useCallback, useState } from "react";
import gql from "graphql-tag";
import { RouteComponentProps, navigate } from "@reach/router";
import { useMutation, useQuery } from "react-apollo";
import queryString from "query-string";
import { useToggle, useStoreAppValue, useRecaptcha } from "@hooks";
import { ErrorService, RailsUrl } from "@services";
import { LinkType } from "@types";
import { ErrorLoading } from "@organisms";
import { IntentLoadingAnonymous } from "../IntentLoadingAnonymous";
import { IntentLoadingBulkShopView } from "../IntentLoadingBulkShopView";
import IntentLoadingView from "./IntentLoadingView";
import {
  IntentLoadingGiftCardViewMutation,
  IntentLoadingGiftCardViewMutationVariables,
} from "./__generated__/IntentLoadingGiftCardViewMutation";
import {
  IntentLoadingViewMutation as IntentLoadingViewMutationGenerics,
  IntentLoadingViewMutationVariables,
} from "./__generated__/IntentLoadingViewMutation";
import { useUtmCookieData } from "@components/common/utils";
import { FeatureFlagQuery, FeatureFlagQueryVariables } from "./__generated__/FeatureFlagQuery";
import { FEATURE_FLAG_QUERY } from "@hooks/useFeatureFlag";

// == Types ================================================================

interface IRouteProps {
  runMutation: boolean;
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================

const GIFT_CARD_INTENT_CREATE = gql`
  mutation IntentLoadingGiftCardViewMutation($input: GiftcardIntentCreateInput!) {
    GiftcardIntentCreate(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on GiftcardIntent {
        id
        token
        ...IntentLoadingViewFragment
      }
    }
  }
  ${IntentLoadingView.fragments}
`;

const INTENT_CREATE_BULK_SHOP = gql`
  mutation IntentLoadingViewMutation($input: IntentCreateInput!) {
    IntentCreate(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Intent {
        id
        token
        url
        interstitialUrl
        ...IntentLoadingBulkShopViewFragment
      }
    }
  }
  ${IntentLoadingBulkShopView.fragments}
`;

IntentLoadingViewMutation.defaultProps = {
  runMutation: false,
};

const selectedFundraisersMaker = (fundraiserId) => {
  const selectedFundraisers = JSON.parse(window.localStorage.getItem("selectedFundraisers"));
  window.localStorage.removeItem("selectedFundraisers");
  if (selectedFundraisers && selectedFundraisers.length > 0) {
    return selectedFundraisers;
  }
  if (fundraiserId) {
    return [fundraiserId];
  }
  return [];
};

// == Component ============================================================

export function IntentLoadingViewMutation({ location, runMutation }: TProps) {
  const [giftcardIntent, setGiftcardIntent] = useState();
  const [intent, setIntent] = useState();
  const { currentUser, recaptchaEnterpriseKey } = useStoreAppValue();
  const [showAnonForm, toggleAnonForm] = useToggle(false);
  const [hasError, toggleError] = useToggle(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [giftCardIntentCreateMutation] = useMutation<
    IntentLoadingGiftCardViewMutation,
    IntentLoadingGiftCardViewMutationVariables
  >(GIFT_CARD_INTENT_CREATE);
  const [intentCreateMutation] = useMutation<
    IntentLoadingViewMutationGenerics,
    IntentLoadingViewMutationVariables
  >(INTENT_CREATE_BULK_SHOP);
  const recaptchaAction = currentUser
    ? "CREATE_GIFTCARD_INTENT"
    : "CREATE_ANONYMOUS_GIFTCARD_INTENT";
  const getRecaptchaToken = useRecaptcha({ action: recaptchaAction });

  // == START TESTS STUFF

  useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FEATURE_FLAG_QUERY, {
    variables: {
      feature: "checkout",
    },
    onCompleted: (data) => {
      // don't forget to handle errors
      const isCheckoutReleased = !!data?.FeatureFlag.enabled;
      createIntent(isCheckoutReleased);
    },
  });

  // == END TESTS STUFF

  const utmData = useUtmCookieData();

  const createGiftcardIntent = async () => {
    const {
      amountCents,
      deviceAttributes,
      fundraiserId,
      isGift,
      isPhysical,
      linkId,
      brandId,
      quantity,
      reloadThresholdCents,
      promotionId,
      collectionId,
      storefrontId,
      labelPromotionId,
      commissionPromotionId,
    } = queryString.parse(location.search);

    try {
      const token = await getRecaptchaToken();

      const input = {
        fundraiserId,
        fundraiserIds: selectedFundraisersMaker(fundraiserId),
        amountCents: parseInt(amountCents, 10),
        deviceAttributes: JSON.parse(deviceAttributes),
        isGift: isGift === "true",
        isPhysical: isPhysical === "true",
        linkId,
        brandId,
        collectionId,
        storefrontId,
        labelPromotionId,
        commissionPromotionId,
        utmData,
        quantity: parseInt(quantity, 10),
        promotionId,
        recaptchaToken: token,
      };
      if (reloadThresholdCents) {
        input.reloadThresholdCents = parseInt(reloadThresholdCents, 10);
      }

      giftCardIntentCreateMutation({
        variables: {
          input,
        },
      })
        .then(({ data }) => {
          const { GiftcardIntentCreate } = data;
          if (!GiftcardIntentCreate || GiftcardIntentCreate.__typename === "MutationError") {
            if (GiftcardIntentCreate.errors?.[0]?.input === "quantity") {
              setErrorMessage(
                "We're currently sold out of this gift card. We've been notified and will re-stock it shortly, sorry for any inconvenience."
              );
            }
            toggleError();
            return;
          }
          const { token: intentToken } = GiftcardIntentCreate;
          navigate(RailsUrl.intentUrl({ intentToken }), {
            replace: true,
            state: { giftcardIntent: GiftcardIntentCreate },
          });
        })
        .catch((e) => {
          toggleError(true);
          ErrorService.error(e);
        });
    } catch (e) {
      toggleError(true);
      ErrorService.error(e);
    }
  };

  const createShopIntent = async () => {
    const {
      deviceAttributes,
      fundraiserId,
      linkId,
      brandId,
      promotionId,
      collectionId,
      storefrontId,
      labelPromotionId,
      commissionPromotionId,
    } = queryString.parse(location.search);
    try {
      intentCreateMutation({
        variables: {
          input: {
            fundraiserId,
            fundraiserIds: selectedFundraisersMaker(fundraiserId),
            deviceAttributes: JSON.parse(deviceAttributes),
            linkId,
            brandId,
            collectionId,
            storefrontId,
            labelPromotionId,
            commissionPromotionId,
            utmData,
            promotionId,
          },
        },
      })
        .then(({ data }) => {
          const { IntentCreate } = data;
          const { interstitialUrl } = IntentCreate;
          if (!IntentCreate || IntentCreate.errors) {
            toggleError();
          } else {
            setIntent(IntentCreate);
            window.location.replace(interstitialUrl);
          }
        })
        .catch((e) => {
          toggleError(true);
          ErrorService.error(e);
        });
    } catch (e) {
      toggleError(true);
      ErrorService.error(e);
    }
  };

  const createIntent = useCallback((isCheckoutReleased: boolean) => {
    const { linkType } = queryString.parse(location.search);
    if (runMutation) {
      if (
        linkType === LinkType.GIFTCARDOFFER ||
        linkType === LinkType.AUTORELOADGIFTCARD ||
        linkType === LinkType.VOUCHER
      ) {
        createGiftcardIntent();
      } else {
        createShopIntent();
      }
    } else {
      // this scenario occurs because the user is navigating here after creating the giftcard mutation
      // a page reload occurs, so we have to set the state
      if (!currentUser) {
        toggleAnonForm();
      }

      if (location.state?.giftcardIntent) {
        setGiftcardIntent(location.state.giftcardIntent);
        if (currentUser) {
          setTimeout(() => {
            const url = isCheckoutReleased
              ? RailsUrl.newCheckoutUrl(location.state.giftcardIntent.token)
              : RailsUrl.checkoutUrl(location.state.giftcardIntent.token);
            navigate(url, { replace: true });
          }, 3000);
        }
      }

      // this if statement shouldn't happen because anon shops shouldn't go through his path
      if (location.state?.intent) {
        setIntent(location.state.intent);
      }

      if (!location.state?.giftcardIntent && !location.state?.intent) {
        window.location.href = location.href;
      }
    }
    // better to fill with correct depth
  }, []);

  if (hasError)
    return (
      <ErrorLoading ctaLabel={errorMessage ? "Back To Store" : undefined} message={errorMessage} />
    );

  if (showAnonForm && giftcardIntent) {
    return (
      <IntentLoadingAnonymous
        giftcardIntent={giftcardIntent}
        location={location}
        toggleAnonForm={toggleAnonForm}
        toggleError={toggleError}
        setErrorMessage={setErrorMessage}
      />
    );
  }

  if (showAnonForm && intent) {
    return (
      <IntentLoadingAnonymous
        giftcardIntent={intent}
        location={location}
        toggleAnonForm={toggleAnonForm}
        toggleError={toggleError}
        setErrorMessage={setErrorMessage}
      />
    );
  }

  if (giftcardIntent) return <IntentLoadingView giftcardIntent={giftcardIntent} />;

  return <IntentLoadingBulkShopView intent={intent} />;
}
// == Styles ===============================================================
