import React, { Fragment } from "react";
import { Query } from "react-apollo";
import { func, string } from "prop-types";
import { RailsUrl } from "@services";
import { FUNDS_RECIPIENT_QUERY } from "../queries";
import UserCampaign from "./UserCampaign";

export default class UserCampaignList extends React.Component {
  static propTypes = {
    setCampaign: func.isRequired,
    currency: string.isRequired,
  };

  state = {
    checkedRadio: null,
  };

  changeRadio = (campaignId) => {
    this.setState({ checkedRadio: campaignId });
  };

  render() {
    const { setCampaign, currency } = this.props;
    const { checkedRadio } = this.state;
    return (
      <Query query={FUNDS_RECIPIENT_QUERY} variables={{ currency }}>
        {({ data, loading }) => {
          if (loading) {
            return <span>Loading campaigns...</span>;
          }

          const myCampaigns = data.Viewer.runningCampaigns;

          if (myCampaigns.length === 0) {
            return (
              <>
                <style
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: `
                  .transfer-title, .event-cancelled-modal__footer{display: none;}
                  `,
                  }}
                />
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <h2 className="mb-2">To access your funds you must transfer them to a team</h2>
                    <p>Join an existing tea or create a new team today!</p>
                    <div className="row justify-content-center">
                      <div className="col-10 col-sm-8 col-md-6">
                        <a className="btn btn-block mb-2" href={RailsUrl.joinNewTeamUrl()}>
                          Join a Team
                        </a>
                        <a className="btn btn-block btn-clear" href={RailsUrl.createTeamUrl()}>
                          Create a Team
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }

          return (
            <>
              {myCampaigns.map((myCampaign) => (
                <UserCampaign
                  changeRadio={this.changeRadio}
                  checkedRadio={checkedRadio}
                  key={`campaign-${myCampaign.id}`}
                  myCampaign={myCampaign}
                  setCampaign={setCampaign}
                />
              ))}
            </>
          );
        }}
      </Query>
    );
  }
}
