import React from "react";
import styled from "@emotion/styled";
import { useSelect } from "downshift";
import { constants } from "@services";
import { Box, ChevronDown, ClearButton } from "@atoms";

// == Types ================================================================

interface IProps {
  onDepartmentSelect: (department: string | null) => void;
}

// == Constants ============================================================

NearbyDepartmentSearch.defaultProps = {};

const DEPARTMENTS_ARRAY = [
  { id: "ALL_STORES", name: "All Stores" },
  ...constants.MERCHANT_DEPARTMENTS,
];
// == Component ============================================================

export default function NearbyDepartmentSearch({ onDepartmentSelect }: IProps) {
  const stateReducer = (state, actionAndChanges) => {
    switch (actionAndChanges.type) {
      // case useSelect.stateChangeTypes.ToggleButtonClick:
      // case useSelect.stateChangeTypes.ToggleButtonBlur:
      //   if (state.isOpen) {
      //     return {
      //       ...actionAndChanges.changes,
      //       isOpen: false,
      //     };
      //   }
      //   return {
      //     ...actionAndChanges.changes,
      //     isOpen: !state.isOpen,
      //   };
      case useSelect.stateChangeTypes.ItemClick:
      case useSelect.stateChangeTypes.FunctionSelectItem:
        if (actionAndChanges.changes.selectedItem.id === "ALL_STORES") {
          onDepartmentSelect(null);
        } else {
          onDepartmentSelect(actionAndChanges.changes.selectedItem);
        }
        return {
          ...actionAndChanges.changes,
          isOpen: false,
          highlightedIndex: state.highlightedIndex,
        };
      default:
        return actionAndChanges.changes;
    }
  };

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({ items: DEPARTMENTS_ARRAY, stateReducer });
  return (
    <Box position="absolute" right={0}>
      <EDepartmentButton {...getToggleButtonProps()}>
        <ChevronDown></ChevronDown>
      </EDepartmentButton>
      <Box
        position="absolute"
        bg="white"
        right={0}
        width="200px"
        border={isOpen && "silver"}
        zIndex={1}
        {...getMenuProps()}
      >
        {isOpen &&
          DEPARTMENTS_ARRAY.map((item, index) => (
            <Box
              pl={2}
              py={1}
              bg={highlightedIndex === index ? "gray" : "none"}
              key={`${item}${index}`}
              {...getItemProps({ item: item.name, index })}
            >
              {item.name}
            </Box>
          ))}
      </Box>
      <div tabIndex="0" />
    </Box>
  );
}

// == Styles ===============================================================

const EDepartmentButton = styled(ClearButton)`
  height: 40px;
  color: ${({ theme }) => theme.colors.primary};
  border: none;
`;
