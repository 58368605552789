import React from "react";
import { Breakpoint } from "react-socks";
import styled from "@emotion/styled";
import { oc } from "ts-optchain";
import { BrandBlock } from "@organisms/brandBlockComponents";
import { Flex, Column, Box } from "@atoms";
import { MultiItemCarousel } from "@molecules";
import { CollectionNearbyBrandsQuery_BrandSearch_edges } from "./__generated__/CollectionNearbyBrandsQuery";

// == Types ================================================================

interface IProps {
  nearbyBrands: CollectionNearbyBrandsQuery_BrandSearch_edges;
}

// == Constants ============================================================

// == Component ============================================================

export default function CollectionNearbyBrands({ nearbyBrands }: IProps) {
  const renderBrandRow = () => {
    return (
      <EFlex mx={[0, 0, -3]}>
        {nearbyBrands.slice(0, 6).map((nearbyBrand, index) => {
          const brand = oc(nearbyBrand).node();
          return (
            <Column autoColumn={false} key={brand.id} width={[0.45, 1 / 3, 1 / 4, 1 / 5]}>
              <BrandBlock brand={brand} isImageCircle isOneLocation />
            </Column>
          );
        })}
      </EFlex>
    );
  };

  return (
    <Box mx={[-2, -2, 0]} position="relative">
      {/*
        this mx above is a total hack, and will like cause me to regret it,
        but can't get the row to work properly otherwise
        the values there should be the same as what they are for the row
        in fact, bounty for putting this bad hack into a component for now
        cause it's used in multiple places.
        #rowHacks
      */}
      <Breakpoint xsmall>{renderBrandRow()}</Breakpoint>
      <Breakpoint small up>
        {nearbyBrands.length < 6 ? (
          renderBrandRow()
        ) : (
          <Box px={[0, 4, 4, 0]}>
            <MultiItemCarousel itemLength={nearbyBrands.length}>
              {nearbyBrands.map((nearbyBrand, index) => {
                const brand = oc(nearbyBrand).node();
                return (
                  <Box alignSelf="flex-start" key={brand.id} px={[1]} width="80%">
                    <BrandBlock brand={brand} isImageCircle isOneLocation />
                  </Box>
                );
              })}
            </MultiItemCarousel>
          </Box>
        )}
      </Breakpoint>
    </Box>
  );
}

// == Styles ===============================================================
const EFlex = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`;
