import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { BrandSearchListItem } from "@components/common/organisms/brandSearchListItemComponents";
import { geoDistance } from "@components/common/utils/geoDistance";
import { getPaginatedNodes } from "@components/common/utils";
import { useNearbyViewContext } from "@hooks";
import { Button, Box } from "@atoms";
import { NearbySearchResultFragment } from "./__generated__/NearbySearchResultFragment";

// == Types ================================================================

interface IProps {
  searchResults: NearbySearchResultFragment;
  hasNextPage: boolean;
  onFetchMore: () => void;
  loadingMore: boolean;
}

// == Constants ============================================================

NearbySearchResult.defaultProps = {};
NearbySearchResult.fragments = {
  fields: gql`
    fragment NearbySearchResultFragment on Brand {
      id
      ...BrandSearchListItemFragment
    }
    ${BrandSearchListItem.fragments.fields}
  `,
};

// == Component ============================================================

export default function NearbySearchResult({
  searchResults,
  hasNextPage,
  onFetchMore,
  loadingMore,
}: IProps) {
  const [nearbyViewValue, nearbyViewDispatch] = useNearbyViewContext();
  const { coordinates } = nearbyViewValue;
  const [sponsoredItem, setSponsoredItem] = useState(0);
  const onBrandHover = (e, brand) => {
    nearbyViewDispatch({ type: "UPDATE_BRAND_HOVERED_ID", brandHoveredId: brand.id });
  };
  const onBrandHoverLeave = (e) => {
    nearbyViewDispatch({ type: "REMOVE_BRAND_HOVERED_ID" });
  };

  const places = useMemo(() => {
    const rawResults = getPaginatedNodes(searchResults).map((node) => {
      return {
        ...node,
        distance: geoDistance(
          coordinates.latitude,
          coordinates.longitude,
          node.latitude,
          node.longitude,
          "K",
          "VALUE"
        ),
      };
    });

    const split = rawResults.reduce(
      (acc, place) => {
        // we only allow 2 sponsored to bubble to top
        // they must be within 20km in order to bubble
        if (acc.sponsored.length >= 2 || !place.sponsored || place.distance > 20) {
          return {
            ...acc,
            normal: [...acc.normal, place],
          };
        }
        if (acc.sponsored.length >= 1 && acc.sponsored[0].brand.id === place.brand.id) {
          return {
            ...acc,
            normal: [...acc.normal, place],
          };
        }
        return {
          ...acc,
          sponsored: [...acc.sponsored, place],
        };
      },
      { sponsored: [], normal: [] }
    );
    setSponsoredItem(split.sponsored.length);
    return [...split.sponsored, ...split.normal];
  }, [coordinates.latitude, coordinates.longitude, searchResults]);

  return (
    <EContainer loadingMore={loadingMore}>
      {places.map((result, index) => {
        return (
          <EBrandSearchListItem
            linkBrand
            address={result?.address}
            borderBottom="silver"
            brand={result.brand}
            distance={`${result.distance}K`}
            key={`${result.brand.id}+${result.id}`}
            location={result}
            locationId={result.id}
            p={3}
            sponsored={result.sponsored && index < sponsoredItem}
            onMouseEnter={(e) => onBrandHover(e, result)}
            onMouseLeave={(e) => onBrandHoverLeave(e)}
            linkCategories={["ONLINE", "GIFTCARDS", "IN-STORE", "REGISTER"]}
          />
        );
      })}
      {hasNextPage && (
        <Box my={3} textAlign="center">
          <Button disabled={loadingMore} onClick={onFetchMore}>
            {loadingMore ? "Loading..." : "Load More"}
          </Button>
        </Box>
      )}
    </EContainer>
  );
}
// == Styles ===============================================================

const EBrandSearchListItem = styled(BrandSearchListItem)`
  &:hover {
    background: ${({ theme }) => `${theme.colors.lightPrimary}`};
  }
`;

const EContainer = styled(Box)`
  position: relative;
  &:before {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    content: "";
    display: ${({ loadingMore }) => (loadingMore ? "block" : "none")};
  }
`;
