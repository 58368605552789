import URL from "url-parse";

const addUTM = (url, utm_source, utm_medium, utm_campaign) => {
  const parsed = new URL(url, null, true);

  parsed.query.utm_medium = utm_medium;
  parsed.query.utm_campaign = utm_campaign;
  parsed.query.utm_source = utm_source;
  parsed.set("query", parsed.query);

  return parsed.href;
};

const getYouTubeID = (url, opts) => {
  if (opts == undefined) {
    opts = { fuzzy: true };
  }

  if (/youtu\.?be/.test(url)) {
    // Look first for known patterns
    let i;
    const patterns = [
      /youtu\.be\/([^#\&\?]{11})/, // youtu.be/<id>
      /\?v=([^#\&\?]{11})/, // ?v=<id>
      /\&v=([^#\&\?]{11})/, // &v=<id>
      /embed\/([^#\&\?]{11})/, // embed/<id>
      /\/v\/([^#\&\?]{11})/, // /v/<id>
    ];

    // If any pattern matches, return the ID
    for (i = 0; i < patterns.length; ++i) {
      if (patterns[i].test(url)) {
        return patterns[i].exec(url)[1];
      }
    }

    if (opts.fuzzy) {
      // If that fails, break it apart by certain characters and look
      // for the 11 character key
      const tokens = url.split(/[\/\&\?=#\.\s]/g);
      for (i = 0; i < tokens.length; ++i) {
        if (/^[^#\&\?]{11}$/.test(tokens[i])) {
          return tokens[i];
        }
      }
    }
  } else {
    // check if they pasted only the ID
    const pattern = /([^#\&\?]{11})/;
    if (pattern.test(url)) {
      return pattern.exec(url)[1];
    }
  }

  return null;
};

export default { addUTM, getYouTubeID };
export { addUTM, getYouTubeID };
