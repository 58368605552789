import React from "react";
import ReactPlaceholder from "react-placeholder";
import { RectShape, TextRow, TextBlock } from "react-placeholder/lib/placeholders";
import { Flex, Box } from "@atoms";
import { theme } from "@styles";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export default function LinkModalLoading() {
  const {
    colors: { placeholderLightGrey },
  } = theme;

  const LoadingComponent = (
    <Box p={3} pb={4}>
      <Flex
        alignItems="center"
        borderBottom="silver"
        data-testid="container"
        flexDirection="column"
        justifyContent="center"
        mb={4}
        mt={2}
        pb={4}
      >
        <Box>
          <RectShape
            color={placeholderLightGrey}
            style={{ width: 200, height: 150, marginRight: 0 }}
          />
        </Box>
        <TextRow color={placeholderLightGrey} style={{ width: 200 }} />
        <TextRow color={placeholderLightGrey} style={{ width: 80 }} />
        <Box mt={3}>
          <RectShape
            color={placeholderLightGrey}
            style={{ width: 300, height: 30, marginRight: 0, borderRadius: 30 }}
          />
        </Box>
      </Flex>
      <Box mb={3} mt={3}>
        <RectShape
          color={placeholderLightGrey}
          style={{ width: "100%", height: 100, marginRight: 0 }}
        />
      </Box>
      <TextBlock color={placeholderLightGrey} rows={4} />
    </Box>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color={placeholderLightGrey}
      customPlaceholder={LoadingComponent}
      delay={1000}
      ready={false}
    />
  );
}

// == Styles ===============================================================
