import React from "react";
import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export const ButtonUnstyled = ({ children, ...rest }: HTMLButtonElement) => {
  return <EButton {...rest}>{children}</EButton>;
};

ButtonUnstyled.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================

const EButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */

  overflow: visible;
  &:focus {
    outline: 0;
  }

  &:active {
    opacity: 0.8;
  }
`;
