import React from "react";
import { navigate } from "@reach/router";
import { useStoreAppValue, useWindowSize } from "@hooks";
import { BrandFavoritesDialerQuery, BrandFavoritesDialer } from "@organisms";
import { Box } from "@atoms";
import { useNearbyBrands } from "@components";
import { RailsUrl, routes } from "@services";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export const FavNearbyTopSection = () => {
  const { campaignId, currentUser } = useStoreAppValue();
  const isCurrentUser = !!currentUser;
  const { brands, isLoading, onShareGeo, isAllowed } = useNearbyBrands();
  const { width } = useWindowSize();

  if (!isCurrentUser) return null;
  const isMobile = width < 768;

  // @ts-expect-error RailsUrl needs to be converted to ts
  const nearbyLink = RailsUrl.nearbyUrl({ campaignId });

  return (
    <Box mb={4} style={{ display: isMobile ? "block" : "flex" }}>
      <div
        style={
          isMobile ? { width: "100%", marginBottom: "10px" } : { width: "50%", marginRight: "10px" }
        }
      >
        <BrandFavoritesDialerQuery
          isFullWidth={isMobile}
          altOnClick={() => navigate(routes.funding.favorites)}
        />
      </div>
      <div style={{ width: isMobile ? "100%" : "50%" }}>
        <BrandFavoritesDialer
          brands={brands?.map(({ node }) => node) || []}
          hasAddCircle
          title="Nearby"
          altLink={!isAllowed ? "Share location" : ""}
          altOnClick={onShareGeo}
          showMoreLink={nearbyLink}
          isFullWidth={isMobile}
          isLoading={isLoading}
          isOneLocation
          topRightLink="See All"
          topRightLinkOnClick={() => navigate(nearbyLink)}
          shouldFillLastCircle
        />
      </div>
    </Box>
  );
};

// == Styles ===============================================================
