import React, { useState } from "react";
import gql from "graphql-tag";
import queryString from "query-string";
import styled from "@emotion/styled";
import { navigate } from "@reach/router";
import { useMutation } from "react-apollo";
import { Flex, Text, Button, TouchableOpacity, Box } from "@atoms";
import { CircleAddIcon, ImageListItem } from "@molecules";
import { RailsUrl } from "@services";
import { InviteModal } from "@organisms";
import { useStoreAppValue } from "@hooks";
import { FundsDistributionMethod } from "@types";
import { PlayersList } from "../components/PlayersList";
import { AddPlayerInputForm } from "../components/AddPlayerInputForm";
import {
  FundsDistributionView,
  UPDATE_FUNDS_DISTRIBUTION_METHOD,
} from "../../organizeMembersComponents/components/FundsDistributionView/FundsDistributionView";
import {
  UpdateFundsDistributionMethod,
  UpdateFundsDistributionMethodVariables,
} from "../../organizeMembersComponents/components/FundsDistributionView/__generated__/UpdateFundsDistributionMethod";
import { PlayerAddEditTitle } from "../components/PlayerAddEditTitle";
import { OrganizePlayersViewViewerFragment } from "./__generated__/OrganizePlayersViewViewerFragment";
import { OrganizePlayersViewCampaignFragment } from "./__generated__/OrganizePlayersViewCampaignFragment";

// == Typings ============================================================

interface IProps {
  campaign: OrganizePlayersViewCampaignFragment;
  viewer: OrganizePlayersViewViewerFragment;
  refetch: () => void;
}

// == Constants ============================================================

OrganizePlayersView.fragments = gql`
  fragment OrganizePlayersViewViewerFragment on Viewer {
    id
    ...AddPlayerInputFormViewerFragment
    ...PlayersListViewerFragment
  }

  fragment OrganizePlayersViewCampaignFragment on Campaign {
    id
    isViewerOwner
    fundsDistributionMethod
    noGroupFundraisers {
      id
      amountRaised {
        centsAmount
      }
      paginatedFundraisers {
        edges {
          cursor
          node {
            id
            userId
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    groups {
      id
      amountRaised {
        centsAmount
      }
      paginatedFundraisers {
        edges {
          cursor
          node {
            id
            userId
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    ...PlayersListCampaignFragment
  }
  ${AddPlayerInputForm.fragments}
  ${PlayersList.fragments}
`;

// == Functions ============================================================

// takes a unsorted list of groups and the viewer's id
// and returns the viewer's group at the top, then subsequent groups sorted
// by amountRaised from greatest to least
export function groupsSorter(groups, viewerId) {
  // it's possible currentUserGroup is undefined if user is not associated with player
  const [currentUserGroup] = groups?.filter((group) =>
    group.paginatedFundraisers.edges.map((edge) => edge.node.userId).includes(viewerId)
  );
  const otherGroups = groups
    ?.filter((group) => group.id !== currentUserGroup?.id && group.id !== "NO_GROUP")
    .sort((a, b) => b.amountRaised.centsAmount - a.amountRaised.centsAmount);
  return [currentUserGroup, ...otherGroups].filter(Boolean);
}

// == Component ============================================================

// Campaign.groups was the previous way to organize larger Campaigns into smaller groups
// the paradigm has shifted to have the group be the PLAYER of a campaign to which
// SUPPORTERS raise funds for.
// *** TL:DR => "groups" is now "players" as of Nov 2020 ***
export function OrganizePlayersView({ campaign, viewer, refetch }: IProps) {
  const { campaignId } = useStoreAppValue();
  const urlParams = queryString.parse(window.location.search);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>("");
  const [inviteModalData, setInviteModalData] = useState({
    open: !!urlParams.invite,
    template: (urlParams.invite || null) as string | null,
  });
  const playersSorted = groupsSorter(campaign?.groups, viewer?.id);

  const [createUpdateFundsDistributionMethod] = useMutation<
    UpdateFundsDistributionMethod,
    UpdateFundsDistributionMethodVariables
  >(UPDATE_FUNDS_DISTRIBUTION_METHOD, {
    onCompleted: ({ CampaignSetFundsDistributionMethod }) => {
      if (CampaignSetFundsDistributionMethod.__typename === "MutationError") {
        return;
      }
      const membersRoute = RailsUrl.membersUrl(campaignId, null);
      navigate(membersRoute);
    },
  });

  const onClick = () => {
    createUpdateFundsDistributionMethod({
      variables: { fundsDistributionMethod: FundsDistributionMethod.UNDECIDED },
    });
  };

  if (
    campaign.fundsDistributionMethod === FundsDistributionMethod.UNDECIDED &&
    campaign.isViewerOwner
  )
    return <FundsDistributionView />;

  return (
    <>
      <EOrganizePlayersView
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        <PlayerAddEditTitle />

        <Flex justifyContent="flex-start" px={[1, 5]} width="100%">
          <ImageListItem
            leftComponent={<CircleAddIcon />}
            padding={[0]}
            text="Add Player"
            onTouch={() => setSelectedPlayerId(selectedPlayerId === "ADD" ? "" : "ADD")}
          />
        </Flex>

        {selectedPlayerId === "ADD" && (
          <Box px={[1, 5]} width="100%">
            <AddPlayerInputForm
              refetch={refetch}
              setSelectedPlayerId={setSelectedPlayerId}
              viewer={viewer}
            />
          </Box>
        )}

        <PlayersList
          campaign={campaign}
          refetch={refetch}
          selectedPlayerId={selectedPlayerId}
          setSelectedPlayerId={setSelectedPlayerId}
          viewer={viewer}
        />

        {playersSorted.length === 0 && (
          <Box my={3} px={[1, 5]} width="100%">
            <Text as="p" fontSize={[0, 1]}>
              No players added yet
            </Text>
            <TouchableOpacity onTouch={onClick}>
              <Text as="p" color="primary" fontSize={[0, 1]}>
                No players to add? Track funds by team instead
              </Text>
            </TouchableOpacity>
          </Box>
        )}

        <InviteModal
          isOpen={inviteModalData.open}
          template={inviteModalData.template}
          onDismiss={() => setInviteModalData((old) => ({ ...old, open: false }))}
        />
      </EOrganizePlayersView>
    </>
  );
}

// == Styles ===============================================================

export const EOrganizePlayersView = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 992px;
  margin: 0 auto;
`;
