import React from "react";
import styled from "@emotion/styled";
import { Box, TouchableOpacity, ChevronLeft, ChevronRight } from "@atoms";

// == Types ================================================================

interface IProps {
  arrowDirection: "left" | "right";
  indent?: number[];
  iconSize: "xs" | "sm" | "lg" | "2x" | "3x" | "5x" | "7x" | "10x";
  color: string;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  arrowDirection: "left",
  indent: [],
  iconSize: "sm",
};

// == Component ============================================================

export const ArrowCarousel = ({ arrowDirection, indent, iconSize, ...rest }: IProps) => {
  return (
    <EBox
      data-testid="arrow-carousel"
      left={arrowDirection === "left" ? indent : "unset"}
      p={2}
      position="absolute"
      right={arrowDirection === "right" ? indent : "unset"}
      top="30%"
      {...rest}
    >
      {arrowDirection === "left" ? (
        <ChevronLeft size={iconSize} />
      ) : (
        <ChevronRight size={iconSize} />
      )}
    </EBox>
  );
};

ArrowCarousel.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================

const EBox = styled(Box)`
  cursor: pointer;
  z-index: 1;
`;
