import React from "react";
import gql from "graphql-tag";
import capitalize from "lodash/capitalize";
import { Box, Flex, Text, Circle, CircleInfo, Link } from "@atoms";
import { constants, RailsUrl } from "@services";
import { FundsPageWithdrawalsLegendFragment } from "./__generated__/FundsPageWithdrawalsLegendFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPageWithdrawalsLegendFragment;
}

// == Constants ============================================================

FundsPageWithdrawalsLegend.fragments = gql`
  fragment FundsPageWithdrawalsLegendFragment on Campaign {
    id
    name
    amountWithdrawnBreakdown @include(if: $isMember) {
      available {
        formatted
        centsAmount
        dollarsAmount
      }
      withdrawn {
        formatted
        centsAmount
        dollarsAmount
      }
      pending {
        formatted
        centsAmount
        dollarsAmount
      }
      fees {
        formatted
        centsAmount
        dollarsAmount
      }
    }
  }
`;

// == Component ============================================================

export default function FundsPageWithdrawalsLegend({ campaign }: IProps) {
  const {
    amountWithdrawnBreakdown: { available, pending, withdrawn, fees },
  } = campaign;
  const {
    CHART_COLORS: {
      available: colorAvailable,
      withdrawn: colorWithdrawn,
      pending: colorPending,
      fees: colorFees,
    },
  } = constants;
  const [
    availableCategoryTitle,
    withdrawnCategoryTitle,
    pendingCategoryTitle,
    feesCategoryTitle,
  ] = Object.keys(campaign.amountWithdrawnBreakdown);
  const { pendingPaymentInfoUrl, feesUrl } = RailsUrl;

  const renderPendingLegendItem = () => {
    // *** TODO: Clean up implementation of Link - KJL - Feb 24, 2020
    return (
      <Link href={pendingPaymentInfoUrl} target="_blank">
        <Flex alignItems="center" padding={3}>
          <Circle bg={colorPending} height={10} width={10} />
          <Flex flexDirection="column" paddingLeft={2}>
            <Box>
              <Text fontSize={[1, 2, 3]}>{pending.formatted}</Text>
            </Box>
            <Flex alignItems="center" flexDirection="row">
              <Text fontSize={[0, 1, 2]}>{capitalize(pendingCategoryTitle)}</Text>
              <CircleInfo containerProps={{ fontSize: [1], paddingLeft: [1] }} />
            </Flex>
          </Flex>
        </Flex>
      </Link>
    );
  };

  const renderFeesLegendItem = () => {
    if (fees.centsAmount === 0) return null;
    return (
      <Link href={feesUrl} target="_blank">
        <Flex alignItems="center" padding={3}>
          <Circle bg={colorFees} height={10} width={10} />
          <Flex flexDirection="column" paddingLeft={2}>
            <Box>
              <Text fontSize={[1, 2, 3]}>{fees.formatted}</Text>
            </Box>
            <Flex alignItems="center" flexDirection="row">
              <Text fontSize={[0, 1, 2]}>{capitalize(feesCategoryTitle)}</Text>
              <CircleInfo containerProps={{ fontSize: [1], paddingLeft: [1] }} />
            </Flex>
          </Flex>
        </Flex>
      </Link>
    );
  };

  return (
    <Flex alignItems="flex-start" flexDirection="row" justifyContent="space-evenly">
      <Flex alignItems="center" padding={3}>
        <Circle bg={colorWithdrawn} height={10} width={10} />
        <Flex flexDirection="column" paddingLeft={2}>
          <Box>
            <Text fontSize={[1, 2, 3]}>{withdrawn.formatted}</Text>
          </Box>
          <Box>
            <Text fontSize={[0, 1, 2]}>{capitalize(withdrawnCategoryTitle)}</Text>
          </Box>
        </Flex>
      </Flex>
      <Flex alignItems="center" padding={3}>
        <Circle bg={colorAvailable} height={10} width={10} />
        <Flex flexDirection="column" paddingLeft={2}>
          <Box>
            <Text fontSize={[1, 2, 3]}>{available.formatted}</Text>
          </Box>
          <Box>
            <Text fontSize={[0, 1, 2]}>{capitalize(availableCategoryTitle)}</Text>
          </Box>
        </Flex>
      </Flex>
      {renderPendingLegendItem()}
      {renderFeesLegendItem()}
    </Flex>
  );
}

// == Styles ===============================================================
