import React from "react";
import ReactPlaceholder from "react-placeholder";
import { RectShape, TextRow, TextBlock } from "react-placeholder/lib/placeholders";
import { Flex, Box } from "@atoms";
import { theme } from "@styles";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export default function LinkModalPromotionsLoading() {
  const {
    colors: { placeholderLightGrey },
  } = theme;

  const LoadingComponent = (
    <Box p={3} pb={4}>
      <Flex justifyContent="center" my={3}>
        <RectShape
          color={placeholderLightGrey}
          style={{ width: "10%", height: 30, marginRight: 0 }}
        />
      </Flex>
      <Box my={3}>
        <RectShape
          color={placeholderLightGrey}
          style={{ width: "100%", height: 100, marginRight: 0 }}
        />
      </Box>
      <Box my={3}>
        <RectShape
          color={placeholderLightGrey}
          style={{ width: "100%", height: 100, marginRight: 0 }}
        />
      </Box>
    </Box>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color={placeholderLightGrey}
      customPlaceholder={LoadingComponent}
      delay={1000}
      ready={false}
    />
  );
}

// == Styles ===============================================================
