import React from "react";
import gql from "graphql-tag";
import striptags from "striptags";
import styled from "@emotion/styled";
import { Box, IBoxProps, Text, H3 } from "@atoms";
import { LinkModalPromoFragment } from "./__generated__/LinkModalPromoFragment";

// == Types ================================================================

interface IProps extends IBoxProps {
  link: LinkModalPromoFragment;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment LinkModalPromoFragment on Link {
      id
      promoLabel
      promoTitle
      promoDescription
    }
  `,
};

// == Component ============================================================

const LinkModalPromo = ({ link, ...rest }: IProps) => {
  return (
    <Box bg="linkPromo" {...rest}>
      <H3 color="linkPromoText" display="block" mb={1}>
        {link.promoTitle}
      </H3>
      <EText
        as="div"
        color="default"
        dangerouslySetInnerHTML={{ __html: striptags(link.promoDescription, ["s", "b", "p", "u"]) }}
      />
    </Box>
  );
};

LinkModalPromo.fragments = FRAGMENTS;

export default LinkModalPromo;

// == Styles ===============================================================

const EText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  color: ${(props) => props.theme.colors.linkPromoText};
  * {
    font-size: ${(props) => props.theme.fontSizes[0]}px;
    color: ${(props) => props.theme.colors.linkPromoText};
  }
`;
