import styled from "@emotion/styled";
import { theme, themed } from "@styles";
import { Text, ITextProps } from "../Text";

// == Types ================================================================

export type IHeadingProps = ITextProps;

// == Constants ============================================================

// == Component ============================================================

export const Heading = styled<typeof Text, IHeadingProps>(Text)(themed("Heading"));

Heading.defaultProps = {
  as: "h2",
  m: 0,
  fontSize: 4,
  fontWeight: "bold",
  color: theme.colors.type.heading,
};

// == Styles ===============================================================
