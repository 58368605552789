import constants from "@services/constants";
import { ErrorService } from "@services/ErrorService";

// == Types ================================================================

// https://segment.com/docs/spec/identify/#traits
interface ISegmentIdentifyTraits {
  id: string;
  email?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  description?: string;
  createdAt?: Date;
  avatar?: string;
  company?: {
    id?: string;
    name?: string;
  };
}

// == Constants ============================================================

// == Functions ============================================================

// == Exports ==============================================================

export function identify(userId: string, traits: ISegmentIdentifyTraits) {
  if (constants.IS_SUPER_ADMIN) return;

  const { analytics } = window;
  if (!analytics) {
    // eslint-disable-next-line no-console
    if (constants.IS_DEV) console.warn("window.analytics is not set");
    ErrorService.error(new Error("window.analytics is not set"));
    return;
  }
  analytics.identify(userId, traits);
}
