import React, { useState } from "react";
import { IntentListQuery } from "@components/common/organisms/intentComponents/IntentList/IntentListQuery";
import { IntentListFilter } from "@components/common/organisms/intentComponents/IntentListFilter";
import { Box } from "@atoms";
import { MyActivityHeader } from "../MyActivityHeader";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// == Component ============================================================

const MyActivityPage = () => {
  const [filter, setFilter] = useState<"SUPPORTED" | "ALL">("ALL");
  return (
    <Box>
      <MyActivityHeader />
      <IntentListFilter filter={filter} setFilter={setFilter} />
      <IntentListQuery filter={filter} />
    </Box>
  );
};

export default MyActivityPage;

// == Styles ===============================================================
