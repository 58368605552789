import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ApolloError } from "apollo-client";
import { useStoreAppValue } from "@hooks";
import { ErrorService, RailsUrl } from "@services";
import { Box, Text, Flex, Image, TouchableOpacity, Link, Column, Row, Button } from "@atoms";
import { FundsDistributionMethod } from "@types";
import {
  UpdateFundsDistributionMethod,
  UpdateFundsDistributionMethodVariables,
} from "./__generated__/UpdateFundsDistributionMethod";

// == Types ================================================================

// == Constants ============================================================

export const UPDATE_FUNDS_DISTRIBUTION_METHOD = gql`
  mutation UpdateFundsDistributionMethod($fundsDistributionMethod: FundsDistributionMethod!) {
    CampaignSetFundsDistributionMethod(fundsDistributionMethod: $fundsDistributionMethod) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
      }
    }
  }
`;

const imageUrlTeam = `https://flipgive.twic.pics/images/fundsDistribution/team-funds.png?twic=v1/refit-inside=400x200@center/background=fff`;
const imageUrlPlayers = `https://flipgive.twic.pics/images/fundsDistribution/player-funds.png?twic=v1/refit-inside=400x200@center/background=fff`;

// == Component ============================================================

export function FundsDistributionView() {
  const { campaignId } = useStoreAppValue();

  const onError = (error?: ApolloError) => {
    if (error) {
      console.log("error", error);
      ErrorService.error(error);
    }
    Materialize?.toast?.(
      "There was an ERROR updating this team. Please try again.",
      4000,
      "rounded toast-danger"
    );
  };

  const [createUpdateFundsDistributionMethod] = useMutation<
    UpdateFundsDistributionMethod,
    UpdateFundsDistributionMethodVariables
  >(UPDATE_FUNDS_DISTRIBUTION_METHOD, {
    onError,
    onCompleted: ({ CampaignSetFundsDistributionMethod }) => {
      if (CampaignSetFundsDistributionMethod.__typename === "MutationError") {
        onError();
        return;
      }
      Materialize?.toast?.(`The team has been successfully updated`, 4000, "rounded toast-success");
    },
  });

  const onTouchPlayers = async () => {
    await createUpdateFundsDistributionMethod({
      variables: { fundsDistributionMethod: FundsDistributionMethod.PLAYER },
    });
    window.location.href = RailsUrl.playersUrl(campaignId);
  };

  const onTouchTeam = async () => {
    await createUpdateFundsDistributionMethod({
      variables: { fundsDistributionMethod: FundsDistributionMethod.TEAM },
    });
    window.location.href = RailsUrl.membersUrl(campaignId, "SUPPORTER");
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      maxWidth="1140px"
      mx="auto"
      pb={5}
    >
      <Box m={4} textAlign="center">
        <Text color="black" fontSize={[18, 18, 24]}>
          Before you send out invites, tell us how funds will be tracked on your team.
        </Text>
      </Box>
      <Row>
        <Column autoColumn={false} mb={[3, 3, 0]} width={[1, 1, 1 / 2]}>
          <Box
            border="silver"
            borderRadius="default"
            height="100%"
            mx={[3, 3, 0]}
            px={[3, 4]}
            py={3}
            textAlign="center"
          >
            <TouchableOpacity textAlign="center" onTouch={() => onTouchPlayers()}>
              <Flex justifyContent="center" py={2}>
                <Box maxHeight={["150px", "200px"]}>
                  <Image alt="players" margin="auto" src={imageUrlPlayers} />
                </Box>
              </Flex>
              <Text as="p">Each player keeps what they earn.</Text>
              <Button mt={2}>Earn By Player</Button>
            </TouchableOpacity>
          </Box>
        </Column>
        <Column autoColumn={false} width={[1, 1, 1 / 2]}>
          <Box
            border="silver"
            borderRadius="default"
            height="100%"
            mx={[3, 3, 0]}
            px={[3, 4]}
            py={3}
            textAlign="center"
          >
            <TouchableOpacity textAlign="center" onTouch={() => onTouchTeam()}>
              <Flex justifyContent="center" py={2}>
                <Box maxHeight={["150px", "200px"]} maxHeight={["150px", "200px"]}>
                  <Image alt="team" margin="auto" src={imageUrlTeam} />
                </Box>
              </Flex>
              <Text as="p">All funds are pooled together.</Text>
              <Button mt={2}>Pool Funds</Button>
            </TouchableOpacity>
          </Box>
        </Column>
      </Row>
      <Link href={RailsUrl.invitePlayersToTeamUrl} target="_blank">
        <Box m={4} textAlign="center">
          <Text color="primary" fontSize={[14, 14, 18]}>
            Not sure? Learn which option is best for your team.
          </Text>
        </Box>
      </Link>
    </Flex>
  );
}
