import React, { useEffect } from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import { oc } from "ts-optchain";
import { useScrollTopOnMount, useLoadingDelay, useFullStoryEvents } from "@hooks";
import { FullStoryEventNames, parseId } from "@services";
import { Text } from "@atoms";
// import { CollectionViewQuery as CollectionViewQueryType } from "./__generated__/CollectionViewQuery";
import { FadeIn } from "@utils";
import { StoreFrontViewLoading } from "../StoreFrontViewLoading";
import { StoreFrontViewQuery as StoreFrontVIewQueryType } from "./__generated__/StoreFrontViewQuery";
import StoreFrontView from "./StoreFrontView";

// == Types ================================================================

interface IRouteProps {
  id?: string;
  isLoading?: boolean;
  isHome: boolean;
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================

const STORE_FRONT_VIEW_QUERY = gql`
  query StoreFrontViewQuery($id: ID!, $carouselItems: Int = 10) {
    StoreFront(id: $id) {
      id
      ...StoreFrontViewFragment
    }
  }
  ${StoreFrontView.fragments.fields}
`;

const DEFAULT_PROPS = {
  isHome: false,
  isLoading: false,
  id: null,
};

// == Component ============================================================

export const StoreFrontViewQuery = ({ id, isLoading, isHome }: TProps) => {
  useScrollTopOnMount();

  const loadingDelayReady = useLoadingDelay();
  const queryId = !id || isHome ? "flipgive_shop" : id;
  const { loading, data, error } = useQuery<StoreFrontVIewQueryType>(STORE_FRONT_VIEW_QUERY, {
    variables: { id: parseId(queryId) },
  });

  const { sendFullStoryEvent } = useFullStoryEvents();

  useEffect(() => {
    sendFullStoryEvent(FullStoryEventNames.storefrontViewed, {
      storefrontName: queryId,
    });
  }, [queryId]);

  if (error) {
    return <Text>{error.message}</Text>;
  }

  const hasNotLoaded = isEmpty(data) || loading || !loadingDelayReady || isLoading;

  if (hasNotLoaded) return <StoreFrontViewLoading />;

  const storefront = oc(data).StoreFront();
  if (!storefront) return null;

  return (
    <FadeIn>
      <StoreFrontView isHome={isHome} showFeaturedBrand={isHome} storefront={storefront} />
    </FadeIn>
  );
};

StoreFrontViewQuery.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
