import React from "react";
import PropTypes from "prop-types";
import ReactPlaceholder from "react-placeholder";

export default class YouTubePreview extends React.Component {
  static propTypes = {
    youTubeId: PropTypes.string.isRequired,
  };

  state = {
    ready: false,
    title: null,
    description: null,
  };

  componentDidMount = () => {
    this.getYoutubeInfo();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.youTubeId !== this.props.youTubeId) {
      this.getYoutubeInfo();
    }
  };

  getYoutubeInfo = async () => {
    const { youTubeId } = this.props;

    const myInit = {
      method: "GET",
      mode: "cors",
      cache: "default",
    };
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${youTubeId}&key=AIzaSyDUS5u1YdPu0E32s5KioFfZB5TSbXIx2BI`,
      myInit,
    );
    const json = await response.json();

    if (json.items[0]) {
      const { title, description } = json.items[0].snippet;

      const truncatedDescription = description
        .split(" ")
        .splice(0, 24)
        .join(" ");

      this.setState({ ready: true, title, description: truncatedDescription });
    } else {
      this.setState({ ready: false, title: null, description: null });
    }
  };

  youTubeImageUrl = () => {
    const { youTubeId } = this.props;
    return `https://img.youtube.com/vi/${youTubeId}/default.jpg`;
  };

  render() {
    return (
      <ReactPlaceholder showLoadingAnimation ready={this.state.ready} rows={3} type="media">
        <div className="youtube-preview">
          <div className="youtube-preview__image-container">
            <img alt="Preview" className="timeline-post__image" src={this.youTubeImageUrl()} />
          </div>
          <div className="youtube-preview__content">
            <span className="youtube-preview__title h4">{this.state.title}</span>
            <span className="youtube-preview__desc">{this.state.description}</span>
          </div>
        </div>
      </ReactPlaceholder>
    );
  }
}
