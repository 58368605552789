import React from "react";
import { Box, IBoxProps } from "../Box";

// == Types ================================================================

interface IProps extends IBoxProps {
  collapse?: boolean;
  autoColumn?: boolean;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  autoColumn: true,
  collapse: false,
};

// == Component ============================================================
const Column = ({ collapse, autoColumn, ...rest }: IProps) => {
  const flex = autoColumn ? 1 : "0 0 auto";
  const px = collapse ? 0 : [2, 2, 3];
  return <Box flex={flex} px={px} {...rest} />;
};

Column.defaultProps = DEFAULT_PROPS;

export default Column;

// == Styles ===============================================================
