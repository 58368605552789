import React from "react";
import { DisplayDates } from "./DisplayDates";
import { MerchantComboBox } from "./MerchantComboBox";
import { DepartmentsSelect } from "./DepartmentsSelect";
import { TimingSelect } from "./TimingSelect";

export interface IEventFilter {
  event: string;
  left_operand?: string;
  operator?: string;
  right_operand?: string;
  days_start?: number;
  days_stop?: number;
  data_key?: string;
  data_value?: string | number;
}

const inputInt = (value: string): number | null => {
  if (value === "" || !value || value.length === 0) {
    return null;
  }
  return parseInt(value, 10);
};

export function EventFilter({ filter, index, update, remove }: any) {
  const events = [
    "intented",
    "shopped",
    "captained",
    "joined",
    "onboarded",
    "personal",
    "referred",
    "favorited_brand",
  ];
  const leftOperands = ["count", "sum"];
  const operators = {
    "<": "Less Than",
    "<=": "At Most",
    "=": "Exactly",
    ">=": "At Least",
    ">": "Over",
  };
  const dataKeys = {
    intented: ["merchant_id", "department"],
    shopped: ["merchant_id", "department"],
    personal: ["timing"],
    favorited_brand: ["merchant_id", "department"],
  };

  return (
    <div style={{ padding: "10px", border: "1px solid #CFD8DC", margin: "0px 0px 10px 0px" }}>
      <div className="form-inline">
        Event{" "}
        <select
          className="form-control native"
          id={`event${index}`}
          placeholder="event"
          value={filter.event}
          onChange={(event) => {
            update({ ...filter, event: event.target.value, data_key: null, data_value: null });
          }}
        >
          <option value="">(choose)</option>
          {events.map((event: string) => (
            <option key={event} value={event}>
              {event}
            </option>
          ))}
        </select>
        <br />
        <select
          className="form-control native"
          value={filter.left_operand ?? "count"}
          onChange={(event) => {
            update({ ...filter, left_operand: event.target.value });
          }}
        >
          {leftOperands.map((leftOperand: string) => (
            <option key={leftOperand} value={leftOperand}>
              {leftOperand}
            </option>
          ))}
        </select>
        {" is "}
        <select
          className="form-control native"
          placeholder="operator"
          value={filter.operator}
          onChange={(event) => {
            update({ ...filter, operator: event.target.value });
          }}
        >
          <option value="">(choose)</option>
          {Object.keys(operators).map((operator: string) => (
            <option key={operator} value={operator}>
              {operators[operator]}
            </option>
          ))}
        </select>{" "}
        <input
          className="form-control"
          placeholder="right operand"
          style={{ width: "75px" }}
          type="number"
          value={filter.right_operand}
          onChange={(event) => {
            update({ ...filter, right_operand: inputInt(event.target.value) });
          }}
        />{" "}
        {filter.left_operand === "sum" ? "dollars" : "times"}
      </div>

      {dataKeys[filter.event] ? (
        <div className="form-inline">
          Matching{" "}
          <select
            className="form-control native"
            placeholder="data_key"
            value={filter.data_key === null ? "" : filter.data_key}
            onChange={(event) => {
              update({ ...filter, data_key: event.target.value, data_value: "" });
            }}
          >
            <option value="">(choose)</option>
            {dataKeys[filter.event].map((key: string) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </select>{" "}
          to{" "}
          {(function() {
            if (filter.data_key === "merchant_id") {
              return (
                <MerchantComboBox
                  merchantId={filter.data_value}
                  setMerchantId={(id) => {
                    update({ ...filter, data_value: id });
                  }}
                />
              );
            }
            if (filter.data_key === "department") {
              return (
                <DepartmentsSelect
                  value={filter.data_value ? filter.data_value : ""}
                  setDepartment={(department: string) => {
                    update({ ...filter, data_value: department });
                  }}
                />
              );
            }
            if (filter.data_key === "timing") {
              return (
                <TimingSelect
                  value={filter.data_value ? filter.data_value : ""}
                  setTiming={(timing: string) => {
                    update({ ...filter, data_value: timing });
                  }}
                />
              );
            }
            return <span>(choose field)</span>;
          })()}
        </div>
      ) : null}

      <div className="form-inline">
        Starting{" "}
        <input
          className="form-control"
          placeholder="days"
          style={{ width: "75px" }}
          type="number"
          value={filter.days_start}
          onChange={(event) => {
            update({
              ...filter,
              days_start: inputInt(event.target.value),
            });
          }}
        />{" "}
        days ago until{" "}
        <input
          className="form-control"
          placeholder="days"
          style={{ width: "75px" }}
          type="number"
          value={filter.days_stop}
          onChange={(event) => {
            update({
              ...filter,
              days_stop: inputInt(event.target.value),
            });
          }}
        />{" "}
        days ago
      </div>

      <DisplayDates daysStart={filter.days_start} daysStop={filter.days_stop} />

      <button className="btn btn-danger mt-3" type="button" onClick={remove}>
        Remove
      </button>
    </div>
  );
}
