import React from "react";
// import styled from "@emotion/styled";
import { Text, ITextProps } from "../Text";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export const TextUppercase = (props: ITextProps) => {
  return <Text uppercase {...props} />;
};

// == Styles ===============================================================
