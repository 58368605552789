import React from "react";
import { inject, observer } from "mobx-react";

@inject("SharePercentStore")
@observer
export default class SharePercent extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.store = this.props.SharePercentStore;
  }

  componentDidMount() {
    this.validateShares();
  }

  validateShares = () => {
    const flipgiveShareValue = parseFloat(this.flipgiveShare.value) || 0;
    const merchantShareValue = parseFloat(this.merchantShare.value) || 0;
    const campaignShareValue = parseFloat(this.campaignShare.value) || 0;
    const offsetShareValue = parseFloat(this.offsetShare?.value) || 0;

    if (flipgiveShareValue + merchantShareValue + campaignShareValue + offsetShareValue !== 100) {
      this.store.updateFlipgiveShareError("should sum to 100%");
      this.store.updateMerchantShareError("should sum to 100%");
      this.store.updateCampaignShareError("should sum to 100%");
      this.store.updateOffsetShareError("should sum to 100%");
    } else if (parseFloat(this.flipgiveShare.value) > parseFloat(this.merchantShare.value)) {
      this.store.updateMerchantShareError("should be greater than FlipGive's and Campaign's share");
      this.store.updateFlipgiveShareError("should be less than merchant's share");
    } else if (parseFloat(this.campaignShare.value) > parseFloat(this.merchantShare.value)) {
      this.store.updateMerchantShareError("should be greater than FlipGive's and Campaign's share");
      this.store.updateCampaignShareError("should be less than merchant's share");
    } else {
      this.store.updateMerchantShareError(null);
      this.store.updateFlipgiveShareError(null);
      this.store.updateCampaignShareError(null);
    }
  };

  render() {
    const prefixUnderscoreText = this.props.prefix ? this.props.prefix + "_" : "";
    const objectUnderscoreText = this.props.objectName ? this.props.objectName + "_" : "";

    const flipgiveErrorClass = this.store.flipgiveShareError ? " has-error" : "";
    const merchantErrorClass = this.store.merchantShareError ? " has-error" : "";
    const offsetErrorClass = this.store.offsetShareError ? " has-error" : "";
    const campaignErrorClass = this.store.campaignShareError ? " has-error" : "";

    let flipgiveErrorMessageContent = null;
    let merchantErrorMessageContent = null;
    let campaignErrorMessageContent = null;
    let offsetErrorMessageContent = null;

    if (this.store.flipgiveShareError) {
      flipgiveErrorMessageContent = (
        <span className="help-block">{this.store.flipgiveShareError}</span>
      );
    }

    if (this.store.merchantShareError) {
      merchantErrorMessageContent = (
        <span className="help-block">{this.store.merchantShareError}</span>
      );
    }

    if (this.store.campaignShareError) {
      campaignErrorMessageContent = (
        <span className="help-block">{this.store.campaignShareError}</span>
      );
    }

    if (this.store.offsetShareError) {
      offsetErrorMessageContent = <span className="help-block">{this.store.offsetShareError}</span>;
    }

    return (
      <div>
        <div
          className={`form-group float optional ${objectUnderscoreText}${prefixUnderscoreText}campaign_share_percent${campaignErrorClass}`}
        >
          <label
            className="float optional control-label"
            htmlFor={`${objectUnderscoreText}${prefixUnderscoreText}campaign_share_percent`}
          >
            Campaign Share %
          </label>
          <input
            className="numeric float optional form-control form-control form-control"
            defaultValue={this.props.campaignSharePercent}
            id={`${objectUnderscoreText}${prefixUnderscoreText}campaign_share_percent`}
            name={`${this.props.objectName}[${prefixUnderscoreText}campaign_share_percent]`}
            ref={(campaignShare) => {
              this.campaignShare = campaignShare;
            }}
            step="any"
            type="number"
            onChange={(e) => {
              this.validateShares();
            }}
          />
          {campaignErrorMessageContent}
        </div>
        <div
          className={`form-group float optional ${objectUnderscoreText}${prefixUnderscoreText}merchant_share_percent${merchantErrorClass}`}
          ref={(merchantShareContainer) => {
            this.merchantShareContainer = merchantShareContainer;
          }}
        >
          <label
            className="float optional control-label"
            htmlFor={`${objectUnderscoreText}${prefixUnderscoreText}merchant_share_percent`}
          >
            Merchant Share %
          </label>
          <input
            className="numeric float optional form-control form-control form-control"
            defaultValue={this.props.merchantSharePercent}
            id={`${objectUnderscoreText}${prefixUnderscoreText}merchant_share_percent`}
            name={`${this.props.objectName}[${prefixUnderscoreText}merchant_share_percent]`}
            ref={(merchantShare) => {
              this.merchantShare = merchantShare;
            }}
            step="any"
            type="number"
            onChange={(e) => {
              this.validateShares();
            }}
          />
          {merchantErrorMessageContent}
        </div>
        <div
          className={`form-group float optional ${objectUnderscoreText}${prefixUnderscoreText}flipgive_share_percent${flipgiveErrorClass}`}
        >
          <label
            className="float optional control-label"
            htmlFor={`${objectUnderscoreText}${prefixUnderscoreText}flipgive_share_percent`}
          >
            FlipGive Share %
          </label>
          <input
            className="numeric float optional form-control form-control form-control"
            defaultValue={this.props.flipgiveSharePercent}
            id={`${objectUnderscoreText}${prefixUnderscoreText}flipgive_share_percent`}
            name={`${this.props.objectName}[${prefixUnderscoreText}flipgive_share_percent]`}
            ref={(flipgiveShare) => {
              this.flipgiveShare = flipgiveShare;
            }}
            step="any"
            type="number"
            onChange={(e) => {
              this.validateShares();
            }}
          />
          {flipgiveErrorMessageContent}
        </div>
      </div>
    );
  }
}
