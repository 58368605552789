import React from "react";
import styled from "@emotion/styled";
import { Box, IBoxProps } from "@atoms";
import { LinkPill } from "@molecules";

// == Types ================================================================

interface IProps extends IBoxProps {
  filter: string;
  setFilter: (arg: "SUPPORTED" | "ALL") => void;
}

// == Constants ============================================================

// == Component ============================================================

export default function IntentListFilter({ filter, setFilter, ...rest }: IProps) {
  return (
    <EContainer mb={[0, 0, 3]} {...rest}>
      <ELinkPill
        isActive={filter === "ALL"}
        text="All Shopping Trips"
        onClick={() => setFilter("ALL")}
      />
      <ELinkPill
        isActive={filter === "SUPPORTED"}
        text="Earnings"
        onClick={() => setFilter("SUPPORTED")}
      />
    </EContainer>
  );
}

// == Styles ===============================================================

const EContainer = styled(Box)`
  overflow-x: auto;
  white-space: nowrap;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    white-space: normal;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
  }
`;

const ELinkPill = styled(LinkPill)`
  margin-right: ${(props) => (props.last ? 0 : `${props.theme.space[2]}px`)};
`;
