import React, { useState, useRef, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import Downshift from "downshift";
import debounce from "lodash/debounce";
import { BrandListLinkTypeFilters } from "@pages/brandListComponents/BrandListLinkTypeFilters";
import {
  COLLECTION_ID_FOR_SHORTCUTS,
  FeatureFlags,
  FullStoryEventNames,
  constants,
} from "@services";
import { Box, Text, Flex, FontAwesome, TouchableOpacity, H2, Button } from "@atoms";
import { SearchInput } from "@molecules";
import { SearchModalResultsQuery } from "../SearchModalResults";
import NewBrandListLinkTypeFilters from "@components/common/pages/brandListComponents/BrandListLinkTypeFilters/NewBrandListLinkTypeFilters";
import { MerchantDepartmentType } from "@types";
import { TOrderByType } from "./types";
import { useFeatureFlag } from "@hooks/useFeatureFlag";
import { useFullStoryEvents, useShopShortcuts } from "@hooks";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

const SearchModal = () => {
  const inputRef = useRef();
  const { shortcuts } = useShopShortcuts(COLLECTION_ID_FOR_SHORTCUTS);

  const [activeLinkCategoryFilter, setActiveLinkCategoryFilter] = useState(null);
  const [departmentFilter, setActiveDepartmentFilter] = useState<MerchantDepartmentType | null>(
    null
  );
  const [orderByFilter, setOrderByFilter] = useState<TOrderByType>(TOrderByType.default);
  const [inputValue, setInputValue] = useState("");
  const { isEnabled: isSearchFeatureEnabled } = useFeatureFlag(FeatureFlags.Feature_Flag);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const onBackClick = () => {
    window.history.back();
  };

  const updateFilter = ({ linkCategoryFilter }) => {
    setActiveLinkCategoryFilter(linkCategoryFilter);
  };

  const onStateChange = debounce(({ inputValue: newInputValue }) => {
    if (typeof newInputValue !== "undefined") {
      setInputValue(newInputValue);
    }
  }, 300);

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.blurInput:
      case Downshift.stateChangeTypes.mouseUp:
        return {
          ...changes,
          inputValue: state.inputValue,
        };
      default:
        return changes;
    }
  };

  const { sendFullStoryEvent } = useFullStoryEvents();

  const renderShortcuts = useMemo(
    () =>
      shortcuts?.map(({ id, shortcutUrl, name, shortName }) => {
        return (
          <PillBtn
            key={id}
            variant="clear"
            onClick={() => {
              if (shortcutUrl) window.location.href = shortcutUrl;
              sendFullStoryEvent(FullStoryEventNames.storeFrontClicked, {
                location: name,
              });
            }}
          >
            {shortName ?? name}
          </PillBtn>
        );
      }),
    [shortcuts]
  );

  const isSearching = !!inputValue && inputValue.length > 2;
  const defaultOrderDirection = orderByFilter === TOrderByType.name ? "asc" : "desc";
  // shouldUseOrderDirection is when orderBy is either Name Give or Popular desc
  const shouldUseOrderDirection =
    orderByFilter === TOrderByType.name || orderByFilter === TOrderByType.popularity;

  return (
    <>
      <Flex alignItems="center" borderBottom="silver" p={3}>
        <TouchableOpacity onTouch={() => onBackClick()}>
          <Text color="primary">
            <FontAwesome icon="arrow-left" />
          </Text>
        </TouchableOpacity>
        <Box flex={1} ml={3} pr={2}>
          <H2>Search</H2>
        </Box>
      </Flex>

      <Downshift
        itemToString={(item) => (item ? item.value : "")}
        stateReducer={stateReducer}
        onStateChange={onStateChange}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          // isOpen,
          // inputValue,
          highlightedIndex,
          selectedItem,
        }) => (
          <div style={{ position: "relative", flex: 1 }}>
            <SearchInput
              placeholder={constants.SEARCH_PLACEHOLDER}
              {...getInputProps()}
              containerProps={{
                m: 3,
                mb: 0,
                position: "relative",
              }}
              ref={inputRef}
            />
            {isSearchFeatureEnabled && !isSearching && (
              <ShortcutWrapper>{renderShortcuts}</ShortcutWrapper>
            )}

            {isSearching && (
              <>
                {isSearchFeatureEnabled ? (
                  <NewBrandListLinkTypeFilters
                    borderBottom="silver"
                    onCategoryChange={updateFilter}
                    onDepartmentChange={setActiveDepartmentFilter}
                    linkCategoryFilter={activeLinkCategoryFilter}
                    departmentFilter={departmentFilter}
                    orderByFilter={orderByFilter}
                    onOrderByChange={setOrderByFilter}
                    mb={0}
                    my={2}
                    p={3}
                  />
                ) : (
                  <BrandListLinkTypeFilters
                    borderBottom="silver"
                    dispatch={updateFilter}
                    linkCategoryFilter={activeLinkCategoryFilter}
                    mb={0}
                    my={2}
                    p={3}
                  />
                )}
              </>
            )}
            <EResultsContainer {...getMenuProps()}>
              <SearchModalResultsQuery
                {...{
                  inputValue,
                  getItemProps,
                  highlightedIndex,
                  selectedItem,
                  orderByFilter,
                  linkCategoryFilter: activeLinkCategoryFilter,
                  departmentFilter: departmentFilter,
                  orderDirection: shouldUseOrderDirection ? defaultOrderDirection : null,
                }}
              />
            </EResultsContainer>
          </div>
        )}
      </Downshift>
    </>
  );
};

export default SearchModal;

// == Styles ===============================================================

// == Styles ===============================================================
const EResultsContainer = styled(Box)`
  background: white;
  overflow-y: auto;
  max-height: 80vh;
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    max-height: 60vh;
  }
`;

const ShortcutWrapper = styled("div")`
  padding: 8px 16px 2px;
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  scrollbar-width: none;
`;

const PillBtn = styled(Button)`
  font-size: 14px;
  border-radius: 10px;
  padding: 4px 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 12px;
  margin-top: 8px;
  margin-right: 8px;
  display: inline-block;
`;
