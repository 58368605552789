import React from "react";
import gql from "graphql-tag";
import { oc } from "ts-optchain";
import { useQuery } from "react-apollo";
import { LinkType } from "@types";
import { getPaginatedNodes } from "@utils";
import { constants, analytics } from "@services";
import OnboardingBrandsSearchResults from "./OnboardingBrandsSearchResults";

// == Types ================================================================

interface IProps {
  inputValue: string | null;
  activeBrands: string[];
  onBrandClick: (brandId: string) => void;
}

// == Constants ============================================================

const BRAND_SEARCH_QUERY = gql`
  query OnboardingBrandsSearchResultsQuery(
    $query: String!
    $first: Int!
    $after: String
    $coordinates: CoordinatesInput
  ) {
    BrandSearch(query: $query, first: $first, after: $after, coordinates: $coordinates)
      @connection(key: "BrandSearch", filter: ["query", "coordinates"]) {
      edges {
        cursor
        node {
          id
          ...OnboardingBrandsSearchResultsFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${OnboardingBrandsSearchResults.fragments.fields}
`;

// == Component ============================================================

export default function OnboardingBrandsSearchResultsQuery({
  inputValue,
  activeBrands,
  onBrandClick,
}: IProps) {
  const { loading, data } = useQuery(BRAND_SEARCH_QUERY, {
    variables: { query: inputValue, first: 100 },
    onCompleted: () => {
      if (inputValue)
        analytics.track.search(
          { analyticsLocation: "store", query: inputValue },
          { search_query: inputValue }
        );
    },
  });

  const brands = getPaginatedNodes(data?.BrandSearch?.edges);

  return (
    <OnboardingBrandsSearchResults
      inputValue={inputValue}
      loading={loading}
      brands={brands}
      activeBrands={activeBrands}
      onBrandClick={onBrandClick}
    />
  );
}

// == Styles ===============================================================
