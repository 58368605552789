import React from "react";
import { string } from "prop-types";

const Error = ({ msg }) => <span>{msg}</span>;

Error.propTypes = {
  msg: string.isRequired,
};

export default Error;
