import React, { useMemo, useEffect } from "react";
import { Formik, Form } from "formik";
import { string, object } from "yup";
import { states, provinces } from "@services";
import { DeliveryTitle } from "../DeliveryTitle";
import { useBeneficiaryContext } from "../../../context";
import { Select, TextInput, Button } from "../..";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  country: string;
}

export interface IDeliveryFormikValues {
  checkType: "EMAIL" | "POSTAGE" | "";
  city: string;
  country: string;
  phoneNumber: string;
  postalCode: string;
  province: string;
  street1: string;
  street2: string;
  email: string;
}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// COUNTRY SPECIFIC
const CHECK_TYPES = [
  ["", "Please select"],
  ["EMAIL", "eCheck - Sent by email within 5 business days"],
  ["POSTAGE", "Physical Check - Sent to mailing address within 10 business days"],
];

const INITIAL_VALUES = {
  street1: "",
  street2: "",
  city: "",
  province: "",
  postalCode: "",
  country: "",
  checkType: "",
  phoneNumber: "",
  email: "",
  checkTypeDisabled: "Postage - Sent to mailing address within 10 business days",
};

const VALIDATION_SCHEMA = object().shape({
  checkType: string().required("Check type is required"),
  city: string()
    .trim()
    .required("City is required"),
  country: string().required("Country is required"), // even tho this is sent from the page already
  postalCode: string()
    .trim()
    .when("country", {
      is: "USA",
      then: string().matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
        message: "Must be in format 12345",
        excludeEmptyString: true,
      }),
    })
    .when("country", {
      is: "CANADA",
      then: string().matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, {
        message: "Must be in format L1L 4L4",
        excludeEmptyString: true,
      }),
    })
    .required("Required"),
  province: string().required("Required"),
  street1: string().required("Adress is required"),
  street2: string(),
  phoneNumber: string().required("Phone number is required"),
  email: string()
    .required("Email is required")
    .email("Email is not formatted correctly"),
});

// == Component ============================================================

export const DeliveryForm = ({ country }: IProps) => {
  const [globalState, stateDispatch] = useBeneficiaryContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isCanada = useMemo(() => {
    return country.toLowerCase() === "canada";
  }, [country]);

  const onBackClick = () => {
    stateDispatch({ type: "onFormBack" });
  };

  const onSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    const { checkTypeDisabled, ...deliveryValues } = values;
    stateDispatch({ type: "confirmBeneficiary", deliveryValues });
    setSubmitting(false);
  };

  const initialCheckType = isCanada ? "POSTAGE" : "";
  const provinceLabel = isCanada ? "Province" : "State";
  const postalCodeLabel = isCanada ? "Postal Code" : "Zip Code";
  const checkTypeLabel = isCanada ? "Cheque Type" : "Check Type";
  const provinceOptions = isCanada ? provinces : states;

  return (
    <Formik<IDeliveryFormikValues>
      initialValues={{
        ...INITIAL_VALUES,
        ...globalState.deliveryValues,
        checkType: initialCheckType,
        country,
      }}
      validateOnBlur={false}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleChange, handleBlur }) => {
        return (
          <Form>
            <DeliveryTitle />
            {!isCanada ? (
              <Select
                handleBlur={handleBlur}
                handleChange={handleChange}
                label={checkTypeLabel}
                name="checkType"
                placeholder="Please Select"
              >
                {CHECK_TYPES.map((type) => {
                  return (
                    <option key={type[0]} value={type[0]}>
                      {type[1]}
                    </option>
                  );
                })}
              </Select>
            ) : (
              <TextInput disabled label="Check Delivery" name="checkTypeDisabled" />
            )}

            <TextInput
              label="Street Address"
              name="street1"
              placeholder="Street address, P.O. box, company name, c/o"
            />
            <TextInput
              label="Apt., Suite, Bldg. (optional)"
              name="street2"
              placeholder="Apartment, suite, unit, building, floor, etc."
            />
            <div className="row">
              <div className="col-md-6">
                <TextInput label="City" name="city" placeholder="City" />
              </div>
              <div className="col-md-6">
                <TextInput disabled label="Country" name="country" />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Select
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label={provinceLabel}
                  name="province"
                  placeholder="Please Select"
                >
                  <>
                    <option value="">Please Select</option>
                    {provinceOptions.map((type) => {
                      return (
                        <option key={type[1]} value={type[1]}>
                          {type[0]}
                        </option>
                      );
                    })}
                  </>
                </Select>
              </div>
              <div className="col-md-6">
                <TextInput
                  label={postalCodeLabel}
                  name="postalCode"
                  placeholder={postalCodeLabel}
                />
              </div>
            </div>

            <TextInput label="Phone Number" name="phoneNumber" placeholder="Phone Number" />
            <TextInput label="Email" name="email" placeholder="email@email.com" />

            <Button
              as="input"
              disabled={isSubmitting}
              label={isSubmitting ? "Submitting..." : "Next"}
              name="submit"
              type="submit"
            />
            <Button additionalClasses="mt-3" variant="clear" onClick={onBackClick}>
              Back
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

DeliveryForm.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
