import React from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { renderIf } from "@services";
import { Flex, IBoxProps, Container, H1, Text } from "@atoms";
import { theme } from "@styles";
import { CollectionViewFragment } from "../CollectionView/__generated__/CollectionViewFragment";

// == Types ================================================================
type TCollection = CollectionViewFragment;

interface IProps extends IBoxProps {
  collection: TCollection;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment CollectionViewHeroFragment on Collection {
      id
      name
      headerDescription
      headerImage
      nameColor
      headerBackgroundColor
      headerDescriptionColor
    }
  `,
};

// == Component ============================================================

const CollectionViewHero = ({ collection }: IProps) => {
  const {
    headerImage,
    headerDescription,
    headerBackgroundColor,
    headerDescriptionColor,
    nameColor,
  } = collection;

  const backgroundColor = headerBackgroundColor || theme.colors.primary;
  const backgroundContent = headerImage ? `url(${headerImage}})` : backgroundColor;
  const descriptionColor = headerDescriptionColor || "black";
  const headerNameColor = nameColor || "white";

  return (
    <ECollectionViewHeroContainer background={backgroundContent} py={[4, 4, 5]}>
      <Container textAlign="center">
        <H1 color={headerNameColor} mb={2}>
          {collection.name}
        </H1>
        {renderIf(!!headerDescription)(<Text color={descriptionColor}>{headerDescription}</Text>)}
      </Container>
    </ECollectionViewHeroContainer>
  );
};

CollectionViewHero.fragments = FRAGMENTS;

export default CollectionViewHero;

// == Styles ===============================================================

const ECollectionViewHeroContainer = styled(Flex)`
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
`;
