import React from "react";
import {
  BeneficiaryForm,
  DeliveryForm,
  BeneficiaryIntro,
  BeneficiaryConfirmation,
} from "./components";
import { useBeneficiaryValue } from "./context";

// == Types ================================================================

interface IProps {
  country: string;
}

export const CampaignBeneficiaryApp = ({ country }: IProps) => {
  const globalState = useBeneficiaryValue();
  const { currentStep } = globalState;

  if (currentStep === 1) {
    return <BeneficiaryIntro />;
  }

  if (currentStep === 2) {
    return <BeneficiaryForm country={country} />;
  }

  if (currentStep === 3) {
    return <DeliveryForm country={country} />;
  }

  return <BeneficiaryConfirmation />;
};
