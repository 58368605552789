import React, { useState } from "react";
import { format, parseISO } from "date-fns";
import { Box, Flex, Text, Button } from "@atoms";
import { FundsPageFundraiserTransferRecipientsList } from "../FundsPageFundraiserTransferRecipientsList";
import { FundsPageFundraiserTransferButton } from "../FundsPageFundraiserTransferButton";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  setTransferOpen: (isTransferOpen: boolean) => void;
  setOpen: (isOpen: boolean) => void;
  selectedListItem: FundsPageFundraiserListItemIntentFragment;
  campaign: FundsPageFundraiserTransferFragment;
}

// == Constants ============================================================

FundsPageFundraiserTransfer.defaultProps = {};

const postTransferComponent = (transferState: string | null, setOpen: (value: boolean) => void) => {
  return (
    <>
      <Box padding={[2, 3, 4]}>
        <Text
          color={!!transferState && transferState === "ERROR" ? "danger" : "default"}
          fontSize={[2, 3, 4]}
        >
          Your Transfer was {!!transferState && transferState === "ERROR" && `not`} successful.
        </Text>
      </Box>
      <Box padding={[2, 3, 4]}>
        <Button onClick={() => setOpen(false)}>Back</Button>
      </Box>
    </>
  );
};

// == Component ============================================================

export default function FundsPageFundraiserTransfer({
  selectedListItem,
  campaign,
  setOpen,
}: IProps) {
  const {
    merchantDisplayName,
    supportedAt,
    campaignAmount: { formatted: amount },
  } = selectedListItem;

  const [selectedFundraiserId, setSelectedFundraiserId] = useState(null);
  const [transferState, setTransferState] = useState(null);

  return (
    <Flex alignItems="center" flexDirection="column" padding={2}>
      <Box padding={2}>
        <Text color="black" fontSize={[3, 4, 4]}>
          {merchantDisplayName}
        </Text>
      </Box>
      <Box>
        <Text fontSize={[1, 2, 3]}>{format(parseISO(supportedAt), "MMMM d, yyyy")}</Text>
      </Box>
      <Box py={[2, 3]}>
        <Text color="primary" fontSize={[3, 3, 4]}>
          {amount} Earned
        </Text>
      </Box>
      {transferState ? (
        postTransferComponent(transferState, setOpen)
      ) : (
        <>
          <FundsPageFundraiserTransferRecipientsList
            campaign={campaign}
            setSelectedFundraiserId={setSelectedFundraiserId}
          />
          <FundsPageFundraiserTransferButton
            selectedFundraiserId={selectedFundraiserId}
            selectedListItem={selectedListItem}
            setOpen={setOpen}
            setTransferState={setTransferState}
            transferState={transferState}
          />
        </>
      )}
    </Flex>
  );
}

// == Styles ===============================================================
