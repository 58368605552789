import React from "react";
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStroopwafel,
  faHamburger,
  faBars,
  faTimes,
  faAngleDown,
  faChevronCircleDown,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faArrowLeft,
  faPlus,
  faStar,
  faStarHalfAlt,
  faChevronCircleRight,
  faChevronCircleLeft,
  faChevronUp,
  faChevronDown,
  faGripLines,
  faCheck,
  faCheckCircle,
  faHeart as faHeartSolid,
  faMapMarkerAlt,
  faExchangeAlt,
  faInfoCircle,
  faCheckSquare,
  faPen,
  faUniversity,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle,
  faHeart as faHeartRegular,
  faStar as faStarRegular,
  faDotCircle,
  faSquare,
} from "@fortawesome/free-regular-svg-icons";
import { Flex, IFlexProps } from "../Flex";

library.add(faStroopwafel);
library.add(faHamburger);
library.add(faBars);
library.add(faTimes);
library.add(faChevronCircleDown);
library.add(faAngleDown);
library.add(faSearch);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faChevronCircleRight);
library.add(faChevronCircleLeft);
library.add(faArrowLeft);
library.add(faPlus);
library.add(faStar);
library.add(faStarHalfAlt);
library.add(faStarRegular);
library.add(faHeartRegular);
library.add(faHeartSolid);
library.add(faSearch);
library.add(faGripLines);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faCircle);
library.add(faDotCircle);
library.add(faMapMarkerAlt);
library.add(faExchangeAlt);
library.add(faInfoCircle);
library.add(faSquare);
library.add(faCheckSquare);
library.add(faPen);
library.add(faUniversity);
library.add(faUser);

// == Types ================================================================

export interface IFontAwesomeProps {
  icon: IconProp;
  containerProps?: IFlexProps;
  onClick?: () => void;
}

// == Constants ============================================================

// == Component ============================================================

export const FontAwesome = ({ containerProps, ...rest }: IFontAwesomeProps) => {
  return (
    <Flex {...containerProps}>
      <FontAwesomeIcon {...rest} />
    </Flex>
  );
};

// == Styles ===============================================================
