import React from "react";
import gql from "graphql-tag";
import { format, parseISO } from "date-fns";
// import styled from "@emotion/styled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Breakpoint } from "react-socks";
import queryString from "query-string";
import { LocationContext } from "@reach/router";
import { Box, Button, Card, Flex, Text } from "@atoms";
import { useStoreAppValue, useToggle } from "@hooks";
import { ReadMore } from "@molecules";
import { RailsUrl } from "@services";
import { IntentCreateInput, LinkType } from "@types";
import { LinkModalPromotionItemFragment } from "./__generated__/LinkModalPromotionItemFragment";

// == Types ================================================================

interface IProps {
  promotion: LinkModalPromotionItemFragment;
  createIntentInput: IntentCreateInput;
  linkType: LinkType;
  fundraiserId?: string;
  linkId: string;
  locationContext: LocationContext;
  showShopButton: boolean;
}

// == Constants ============================================================

LinkModalPromotionItem.fragment = gql`
  fragment LinkModalPromotionItemFragment on LinkPromotion {
    id
    couponCode
    description
    endsAt
  }
`;

LinkModalPromotionItem.defaultProps = {};

// == Component ============================================================
//
export default function LinkModalPromotionItem({
  promotion,
  linkType,
  fundraiserId,
  createIntentInput,
  linkId,
  locationContext,
  showShopButton,
}: IProps) {
  const { campaignId, currentUser } = useStoreAppValue();
  const userNeedsSignUp = !currentUser && !campaignId && !fundraiserId;
  const { tinyLinkKey } = locationContext.location.state;

  const createIntentUrl = (labelPromotionId?: string) => {
    const { deviceAttributes, ...rest } = createIntentInput;
    const { collectionId, storefrontId, commissionPromotionId, utmData } = queryString.parse(
      location.search
    );
    const params = queryString.stringify({
      ...rest,
      linkType,
      promotionId: promotion.id,
      collectionId,
      storefrontId,
      labelPromotionId,
      commissionPromotionId,
      deviceAttributes: JSON.stringify({ ...deviceAttributes }),
    });
    return RailsUrl.createIntentUrl({ params, campaignId });
  };

  const checkoutHref = (labelPromotionId?: string) => {
    if (userNeedsSignUp) {
      return `${RailsUrl.createTeamUrl()}?s=${tinyLinkKey}`;
    }
    if (!currentUser) {
      return RailsUrl.createShopUrl({ campaignId, linkId, fundraiserId });
    }
    return createIntentUrl(labelPromotionId);
  };

  const [isCopied, toggleIsCopied] = useToggle(false);
  return (
    <Card bg="linkPromo" border="silver" boxShadow="lightShadow" mx={[2, 2, 3]} p={0}>
      <Box p={3}>
        <Breakpoint xsmall>
          <ReadMore
            renderHtml
            color="linkPromoText"
            hrefColor="linkPromoHref"
            maxCharacter={90}
            text={promotion.description}
          />
        </Breakpoint>
        <Breakpoint small up>
          <ReadMore
            renderHtml
            color="linkPromoText"
            hrefColor="linkPromoHref"
            maxCharacter={120}
            text={promotion.description}
          />
        </Breakpoint>
        {promotion.couponCode && (
          <Flex mt={3}>
            <Box bg="white" border="silver" borderRadius={["4px 0 0 4px"]} borderRight="none" p={2}>
              <Text color="default" fontWeight="bold">
                {promotion.couponCode}
              </Text>
            </Box>
            <CopyToClipboard
              text={promotion.couponCode ? promotion.couponCode : ""}
              onCopy={() => toggleIsCopied(true)}
            >
              <Button border="primary" borderRadius={["0 4px 4px 0"]} p={2} variant="promoClear">
                {isCopied ? "Copied!" : "Copy"}
              </Button>
            </CopyToClipboard>
          </Flex>
        )}
      </Box>
      <Flex justifyContent="space-between" pb={3} px={3}>
        <Flex alignItems="center">
          <Text color="linkPromoText" textStyle="legal">
            Exp. {format(parseISO(promotion.endsAt), "MM/dd/yyyy")}
          </Text>
        </Flex>
        {!!showShopButton && (
          <Button
            as="a"
            disabled={!currentUser}
            href={checkoutHref(promotion.id)}
            target="_blank"
            variant="promoClear"
          >
            Get Deal
          </Button>
        )}
      </Flex>
    </Card>
  );
}

// == Styles ===============================================================
