import constants from "./constants";

export const canLinkWithRouter = (href: string, featureFlags: $FixMe) => {
  if (!featureFlags.newCollection) {
    return href.includes(constants.linkParam) || href.includes(constants.brandParam);
  }

  return (
    href.includes(constants.linkParam) ||
    href.includes(constants.brandParam) ||
    href.includes(constants.collectionUrl) ||
    href.includes(constants.storeUrl) ||
    href.includes(constants.brandsUrl)
  );
};
