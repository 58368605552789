import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { MentionsInput, Mention } from "react-mentions";
import { Query } from "react-apollo";
import { get } from "lodash";
import { FundraisersQuery } from "@queries";

@inject("TimelineStore")
@observer
export default class NewComment extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  };

  handleChange = ({ target }) => {
    this.props.handleChange(target.value);
  };

  render() {
    return (
      <Query query={FundraisersQuery}>
        {({ data: fundraisersData }) => {
          const fundraisers = get(fundraisersData, "Campaign.joinedFundraisers", []);
          const users = fundraisers.map((fundraiser) => ({
            id: fundraiser.userId,
            display: fundraiser.name,
          }));
          const mentionUsers = [{ id: "team", display: "Team" }, ...users];

          return (
            <MentionsInput
              singleLine
              className="mentions new-comment__input"
              displayTransform={(_id, display) => `@${display}`}
              placeholder={this.props.placeholder}
              value={this.props.value}
              onChange={this.handleChange}
            >
              <Mention data={mentionUsers} trigger="@" />
            </MentionsInput>
          );
        }}
      </Query>
    );
  }
}
