import React from "react";
import gql from "graphql-tag";
import capitalize from "lodash/capitalize";
import { IntentCategoryEnum } from "@types";
import { constants } from "@services";
import { Box, Flex, Text, Circle } from "@atoms";
import { FundsPageEarningsLegendFragment } from "./__generated__/FundsPageEarningsLegendFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPageEarningsLegendFragment;
}

// == Constants ============================================================

FundsPageEarningsLegend.fragments = gql`
  fragment FundsPageEarningsLegendFragment on Campaign {
    id
    amountRaisedBreakdown {
      shopping {
        formatted
      }
      donations {
        formatted
      }
      bonuses {
        formatted
      }
      team {
        formatted
      }
      transfers {
        formatted
      }
    }
  }
`;

// == Component ============================================================

export default function FundsPageEarningsLegend({ campaign }: IProps) {
  const { amountRaisedBreakdown } = campaign;

  const { SHOPPING, DONATION, BONUS, TEAM, TRANSFER } = IntentCategoryEnum;

  const { CHART_COLORS } = constants;

  const renderLegendCategory = (category: IntentCategoryEnum) => {
    // *** the intent enums and the Campaign > amountRaisedBreakdown enums don't align 100%
    // *** translation code necessary to align enums with categories
    const categoryEnumTranslator = () => {
      if (category === DONATION) return "donations";
      if (category === BONUS) return "bonuses";
      if (category === TRANSFER) return "transfers";
      return category.toLowerCase();
    };

    const categoryTranslated = categoryEnumTranslator();

    return (
      <Flex alignItems="center" padding={[1, 1, 2]}>
        <Circle bg={CHART_COLORS[categoryTranslated]} height={10} width={10} />
        <Flex flexDirection="column" padding={2}>
          <Box>
            <Text fontSize={[1, 2, 3]}>{amountRaisedBreakdown[categoryTranslated]?.formatted}</Text>
          </Box>
          <Box>
            <Text fontSize={[0, 1, 2]}>{capitalize(categoryTranslated)}</Text>
          </Box>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex bg="lightBlueBg" height="100%" paddingRight={[1, 1, 2]}>
      <Flex alignItems="flex-start" flexDirection="column">
        {renderLegendCategory(SHOPPING)}
        {renderLegendCategory(DONATION)}
        {renderLegendCategory(TRANSFER)}
      </Flex>
      <Flex alignItems="flex-start" flexDirection="column">
        {renderLegendCategory(BONUS)}
        {renderLegendCategory(TEAM)}
      </Flex>
    </Flex>
  );
}

// == Styles ===============================================================
