import React from "react";
// import styled from "@emotion/styled";
import { format, isFuture, isToday } from "date-fns";
import { parseISO } from "date-fns/fp";
import gql from "graphql-tag";
import { Box, Link, Text } from "@atoms";
import { RailsUrl } from "@services";
import { MissingClaimStatusEnum, IntentTypeEnum } from "@types";
import { IntentCashbackStatusFragment } from "./__generated__/IntentCashbackStatusFragment";

// == Types ================================================================

interface IProps {
  intent: IntentCashbackStatusFragment;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment IntentCashbackStatusFragment on Intent {
      id
      buyer_name
      intentType
      canClaimMissing
      supportedAt
      expectedEarnedOn
      campaignAmount {
        formatted
      }
      missingClaim {
        id
        status
        denialReason
      }
      fundraiser {
        id
        name
      }
      token
    }
  `,
};
// == Component ============================================================

const IntentCashbackStatus = ({ intent }: IProps) => {
  if (intent.supportedAt) {
    return (
      <Box textAlign="right">
        <Text color="primary" display="block" fontSize={3} fontWeight="bold" lineHeight="title">
          {intent.campaignAmount.formatted}
        </Text>
        {intent.fundraiser ? (
          <Text color="primary" fontSize={0}>
            {intent.fundraiser.name}
          </Text>
        ) : (
          <Text color="primary" fontSize={0}>
            {intent.buyer_name}
          </Text>
        )}
      </Box>
    );
  }
  if (intent.missingClaim) {
    if (intent.missingClaim.status === MissingClaimStatusEnum.PENDING) {
      return (
        <Text display="block" fontSize={1}>
          Pending Claim #{intent.missingClaim.id}
        </Text>
      );
    }
    if (intent.missingClaim.status === MissingClaimStatusEnum.DENIED) {
      return (
        <Text display="block" fontSize={1}>
          Claim Rejected
        </Text>
      );
    }
  }
  if (intent.intentType === IntentTypeEnum.SHOP) {
    return (
      <Box textAlign="right">
        {!intent.canClaimMissing &&
          (isFuture(parseISO(intent.expectedEarnedOn)) ||
            isToday(parseISO(intent.expectedEarnedOn))) && (
            <Text display="block" fontSize={1} lineHeight="solid">
              Earn by {format(parseISO(intent.expectedEarnedOn), "MMM d, yyyy")}
            </Text>
          )}
        {!!intent.token && !!intent.canClaimMissing && (
          <>
            <Text display="block" fontSize={1} lineHeight="solid">
              Earn by {format(parseISO(intent.expectedEarnedOn), "MMM d, yyyy")}
            </Text>
            <Link as="a" href={RailsUrl.missingClaimUrl(intent.token)} target="_blank">
              <Text color="primary" fontSize={0}>
                Claim missing funds
              </Text>
            </Link>
          </>
        )}
      </Box>
    );
  }
  if (intent.intentType === "LINKEDCARDSALE") {
    return null;
  }

  return null;
};

export default IntentCashbackStatus;
IntentCashbackStatus.fragments = FRAGMENTS;

// == Styles ===============================================================
