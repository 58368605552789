/* eslint-disable import/prefer-default-export */

import React from "react";
import PropTypes from "prop-types";
import { withApollo, graphql } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import { NOTIFICATIONS_COUNT_QUERY } from "./queries";
import { Settings } from "@services";

const MARK_ALL_SEEN_MUTATION = gql`
  mutation MarkAllSeenMutation {
    ActivityMarkAllNotificationsSeen
  }
`;

export const NotificationsContext = React.createContext();

@withApollo
@graphql(MARK_ALL_SEEN_MUTATION, { name: "markAllSeenMutation" })
export class NotificationsProvider extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}).isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    markAllSeenMutation: PropTypes.func.isRequired,
  };

  state = {
    unseenCount: 0,
  };

  componentDidMount() {
    this.loadUnseenCountFromSession();
  }

  loadUnseenCountFromSession = () => {
    const data = window.sessionStorage.getItem(`unseen-count-${Settings.currentUser.id}`);

    if (data) {
      const parsedData = JSON.parse(data);
      const storedTime = moment(parsedData.time);
      const expiryTime = moment().subtract(5, "minutes");

      if (storedTime < expiryTime) {
        this.refreshUnseenCount();
      } else {
        this.setState({
          unseenCount: parsedData.unseenCount,
        });
      }
    } else {
      this.refreshUnseenCount();
    }
  };

  markAllSeen = async () => {
    const unseenCount = 0;
    const { markAllSeenMutation } = this.props;

    this.setState({ unseenCount });
    this.updateUnseenCountInSession(unseenCount);
    markAllSeenMutation();
  };

  refreshUnseenCount = async () => {
    const { client } = this.props;
    const response = await client.query({ query: NOTIFICATIONS_COUNT_QUERY });
    const unseenCount = response.data.Viewer.unseenNotificationCount;

    this.setState({ unseenCount });
    this.updateUnseenCountInSession(unseenCount);
  };

  updateUnseenCountInSession = (unseenCount) => {
    window.sessionStorage.setItem(
      `unseen-count-${Settings.currentUser.id}`,
      JSON.stringify({
        unseenCount,
        time: new Date(),
      }),
    );
  };

  render() {
    return (
      <NotificationsContext.Provider
        value={{
          ...this.state,
          refreshUnseenCount: this.refreshUnseenCount,
          markAllSeen: this.markAllSeen,
        }}
      >
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}
