import React, { Fragment } from 'react';
import { shape, func, bool } from 'prop-types';
import { AvatarImage } from '@components';

function Attendee({ participant, handleCheck, viewerIsParticipant }) {
  return (
    <div className="event-invite-list-item" key={participant.userId}>
      <AvatarImage
        className="event-invite-list-item__image"
        src={participant.avatarImage}
        alt={participant.name}
        name={participant.name}
      />
      <span className="event-invite-list-item__name">{participant.name}</span>
      {!viewerIsParticipant ? null : (
        <div className="event-invite-list-item__checkbox">
          <div className="standalone-checkbox">
            {participant.status === 'ACCEPTED' || participant.status === 'DECLINED' ? null : (
              <Fragment>
                <input
                  type="checkbox"
                  className="filled-in"
                  id={`checkbox-${participant.userId}`}
                  onChange={(e) => {
                    handleCheck(participant.userId, e.target.checked);
                  }}
                />
                <label htmlFor={`checkbox-${participant.userId}`}>
                  <span className="sr-only">Checkbox</span>
                </label>
              </Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

Attendee.propTypes = {
  participant: shape({}).isRequired,
  handleCheck: func.isRequired,
  viewerIsParticipant: bool.isRequired,
};

export default Attendee;
