import React from "react";
import gql from "graphql-tag";
import { getPaginatedNodes } from "@utils";
import { addsDirectFundraiserGroup } from "@services";
import { Box, Flex, Text } from "@atoms";
import { ImageCircle, NoAvatar } from "@molecules";
import { FundsPageEarningsFundraisersList } from "../FundsPageEarningsFundraisersList";
import {
  FundsPageEarningsGroupsListFragment,
  FundsPageEarningsGroupsListFragment_groups,
} from "./__generated__/FundsPageEarningsGroupsListFragment";
import { useSortedPlayers } from "../useSortedPlayers";

// == Types ================================================================

interface IProps {
  campaign: FundsPageEarningsGroupsListFragment;
  onGroupFetchMore: (groupId: string, endCursor: string) => void;
  onNoGroupFetchMore: () => void;
}

// == Constants ============================================================

FundsPageEarningsGroupsList.fragments = gql`
  fragment FundsPageEarningsGroupsListFragment on Campaign {
    id
    name
    fundsDistributionMethod
    privacyLevel
    viewerFundraiser @include(if: $hasCurrentUser) {
      id
      userId
      name
      group {
        id
        name
      }
    }
    directAmountRaised {
      dollarsAmount
      formatted
    }
    goal {
      dollarsAmount
    }
    group(id: $groupId) @include(if: $includeGroup) {
      id
      name
      activeFundraisersCount
      paginatedFundraisers(
        first: $paginationLimitGroup
        after: $groupAfter
        filterOnlyActive: $filterOnlyActive
      ) {
        edges {
          cursor
          node {
            id
            userId
            name
            firstName
            lastName
            group {
              id
              name
            }
            avatarImage
            isOwner
            joinedAt
            deactivatedAt
            amountRaised {
              dollarsAmount
              formatted
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
    filteredGroups {
      id
      name
      activeFundraisersCount
      imageUrl
      goal {
        centsAmount
        formatted
      }
      amountRaised {
        dollarsAmount
        formatted
      }
      isDraft
      deletedAt
      paginatedFundraisers(
        first: $paginationLimitGroup
        after: $groupAfter
        filterOnlyActive: $filterOnlyActive
      ) {
        edges {
          cursor
          node {
            id
            userId
            group {
              id
              name
            }
            ...FundsPageEarningsFundraisersListFundraisersFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    noGroupFundraisers {
      id
      name
      activeFundraisersCount
      amountRaised {
        dollarsAmount
        formatted
      }
      paginatedFundraisers(
        first: $paginationLimitNoGroup
        after: $noGroupAfter
        filterOnlyActive: $filterOnlyActive
      ) {
        edges {
          cursor
          node {
            id
            userId
            group {
              id
              name
            }
            ...FundsPageEarningsFundraisersListFundraisersFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    ...FundsPageEarningsFundraisersListViewerFundraiserFragment
    ...addsDirectFundraiserGroupFragment
  }
  ${FundsPageEarningsFundraisersList.fragments.viewerFundraiser}
  ${FundsPageEarningsFundraisersList.fragments.fundraisers}
  ${addsDirectFundraiserGroup.fragment}
`;

// == Component ============================================================

export default function FundsPageEarningsGroupsList({
  campaign,
  onNoGroupFetchMore,
  onGroupFetchMore,
}: IProps) {
  const {
    filteredGroups: groups,
    viewerFundraiser,
    noGroupFundraisers,
    privacyLevel,
    fundsDistributionMethod,
  } = campaign;

  const sortedPlayers = useSortedPlayers(groups);
  const combinedGroups = [...sortedPlayers, noGroupFundraisers];

  const renderPlayerHeader = (group: FundsPageEarningsGroupsListFragment_groups) => {
    const id = group?.id;
    const name = group?.id === "NO_GROUP" ? "Team Supporters" : group?.name;
    const initials = name
      .split(" ")
      .map((str) => str.substr(0, 1))
      .slice(0, 2);
    const imageUrl = group?.imageUrl;
    const formatted = group?.amountRaised.formatted;
    const dollarsAmount = group?.amountRaised.dollarsAmount;

    const circleDiameter = 50;
    const firstInitial = initials[0];
    const lastInitial = initials[1];
    const showGoalProgress = fundsDistributionMethod === "PLAYER" && group?.id !== "NO_GROUP";

    // if id === name -> it's a direct fundraiser
    if (dollarsAmount === 0 && id === name) return null;

    return (
      <Flex alignItems="center" justifyContent="space-between" padding={3}>
        <Flex alignItems="center">
          {imageUrl ? (
            <ImageCircle
              alt={name ?? "player"}
              border=""
              faded={false}
              height={circleDiameter}
              src={imageUrl}
              width={circleDiameter}
            />
          ) : (
            <NoAvatar
              circleDiameter={circleDiameter}
              firstName={firstInitial ?? name}
              isHighlighted={false}
              lastName={lastInitial ?? ""}
            />
          )}
          <Box px={3}>
            <Text fontSize={[1, 1, 2]}>
              {name} {group.deletedAt ? "(DELETED)" : ""}
            </Text>
          </Box>
        </Flex>

        {showGoalProgress && !!group?.goal?.formatted && (
          <Box>
            <Text fontSize={[1, 1, 2]} fontWeight="bold">
              {formatted || "$0.00"}
            </Text>
            <Text fontSize={[1, 1, 2]} fontWeight="bold">
              {" "}
              / {group.goal?.formatted} Goal
            </Text>
          </Box>
        )}
      </Flex>
    );
  };

  return (
    <Box width={1}>
      {combinedGroups.map((group) => {
        const id = group?.id;
        const name = group?.name;
        const fundraisers = getPaginatedNodes(group?.paginatedFundraisers?.edges);

        return (
          <Box bg="lightBg" key={`${id} ${name}`}>
            {renderPlayerHeader(group)}
            <FundsPageEarningsFundraisersList
              fundraisers={fundraisers}
              group={group}
              privacyLevel={privacyLevel}
              viewerFundraiser={viewerFundraiser}
              onGroupFetchMore={onGroupFetchMore}
              onNoGroupFetchMore={onNoGroupFetchMore}
            />
          </Box>
        );
      })}
    </Box>
  );
}

// == Styles ===============================================================
