import React from "react";
import { Button, IButtonProps } from "..";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export const ClearButton = (props: IButtonProps) => {
  // const [isHovering, setHover] = useState(false);
  // const clearButtonProps = useSpring({
  //   backgroundColor: isHovering ? `${theme.colors.primary}` : `${theme.colors.white}`,
  //   color: isHovering ? `${theme.colors.white}` : `${theme.colors.black}`,
  // });
  // const AnimatedButton = animated(Button);
  return (
    // <AnimatedButton
    //   style={clearButtonProps}
    //   onMouseEnter={() => setHover(true)}
    //   onMouseLeave={() => setHover(false)}
    //   variant="clear"
    //   {...props}
    // />
    <Button variant="clear" {...props} />
  );
};

// == Styles ===============================================================
