import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { oc } from "ts-optchain";
import isEmpty from "lodash/isEmpty";
import { Location, LocationContext } from "@reach/router";
import { Text, Box } from "@atoms";
import { useLoadingDelay, useStoreAppValue } from "@hooks";
import { InStoreHero } from "../InStoreHero";
import { LinkModalLoading } from "../../linkModalComponents/LinkModalLoading";
import { LinkModalWaysToEarn } from "../../linkModalComponents/LinkModalWaysToEarn";
import { InStorePromo } from "../InStorePromo";
import { InStoreLinkedCards } from "../InStoreLinkedCards";
import { InStoreGives } from "../InStoreGives";
import { LinkModalTerms } from "../../linkModalComponents/LinkModalTerms";
import InStoreModal from "./InStoreModal";

// == Types ================================================================

interface IProps {
  linkId: string;
  isLoading?: boolean;
  campaignId?: string;
  locationId?: string;
}

// == Constants ============================================================

const LOCATION_FRAGMENT = {
  fields: gql`
    fragment LocationFragment on Location {
      id
      name
      street1
      street2
      city
      province
      postalCode
      mapImage(zoom: 18, size: "600x400")
    }
  `,
};

const IN_STORE_MODAL_QUERY = gql`
  query InStoreModalQuery(
    $linkId: ID!
    $locationId: ID
    $hasLocationId: Boolean!
    $hasCurrentUser: Boolean!
  ) {
    Viewer @include(if: $hasCurrentUser) {
      id
      linkedCards {
        id
        cardType
        last4
      }
    }
    Link(id: $linkId) {
      id
      type
      cashbackTiming
      merchant {
        id
        name
      }
      location(id: $locationId) @include(if: $hasLocationId) {
        id
        ...LocationFragment
      }
      nearestLocation {
        id
        ...LocationFragment
      }
      ...InStoreHeroFragment
      ...LinkModalTermsFragment
      ...InStorePromoFragment
      ...InStoreLinkedCardsFragment
      ...InStoreGivesFragment
      ...LinkModalWaysToEarnFragment
    }
  }
  ${InStoreHero.fragments.fields}
  ${InStorePromo.fragments.fields}
  ${InStoreGives.fragments.fields}
  ${LinkModalTerms.fragments.fields}
  ${InStoreLinkedCards.fragments.fields}
  ${LOCATION_FRAGMENT.fields}
  ${LinkModalWaysToEarn.fragment}
`;
// == Component ============================================================

const InStoreModalQuery = ({ locationId, linkId, campaignId, isLoading }: IProps) => {
  const loadingDelayReady = useLoadingDelay();
  const { currentUser } = useStoreAppValue();
  const queryVariables = () => {
    let params = {
      linkId,
      hasLocationId: !!locationId,
      hasCurrentUser: !!currentUser,
    };
    if (locationId) {
      params = { locationId, ...params };
    }
    return params;
  };

  const { data, error, loading } = useQuery<InStoreModalQuery, InStoreQueryVariables>(
    IN_STORE_MODAL_QUERY,
    {
      variables: queryVariables(),
    }
  );

  if (error) {
    return (
      <Box>
        <Text>{error.message}</Text>
      </Box>
    );
  }
  const hasNotLoaded = isEmpty(data) || loading || !loadingDelayReady || isLoading;

  if (hasNotLoaded) return <LinkModalLoading />;

  const link = oc(data).Link();
  const { linkedCards } = oc(data).Viewer({ linkedCards: [] });

  if (!link) {
    return (
      <Box>
        <Text>Sorry couldn&apos;t find that LINK?</Text>
      </Box>
    );
  }

  return (
    <Location>
      {(locationContext: LocationContext) => {
        return (
          <InStoreModal
            campaignId={campaignId}
            link={link}
            linkedCards={linkedCards}
            locationContext={locationContext}
          />
        );
      }}
    </Location>
  );
};
export default InStoreModalQuery;
