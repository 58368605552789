import React from "react";
import { Box, Flex, Container } from "@components/common/atoms";
import { LoadingTextRow } from "@components/common/molecules";
import { RoundShape, RectShape } from "react-placeholder/lib/placeholders";
import ReactPlaceholder from "react-placeholder";
// import styled from "@emotion/styled";
// == Types ================================================================

interface IProps {}

// == Constants ============================================================

BeneficiaryTypeLoading.defaultProps = {};

// == Component ============================================================

export default function BeneficiaryTypeLoading(props: IProps) {
  const mb = 4;
  const mt = 3;
  const mr = 3;
  const LoadingComponent = () => (
    <>
      <Flex data-testid="loading-container" mb={mb} mt="24px" justifyContent="flex-start">
        <Box height={20} mr={mr} width={20}>
          <RoundShape color="#E0E0E0" style={{ width: 20 }} />
        </Box>
        <Box height={20} width="100%">
          <RectShape color="#E0E0E0" style={{ width: "35%", height: 20 }} />
        </Box>
      </Flex>
      <Flex mb={mb} mt={mt}>
        <Box height={20} mr={mr} width={20}>
          <RoundShape color="#E0E0E0" style={{ width: 20 }} />
        </Box>
        <Box height={20} width="100%">
          <RectShape color="#E0E0E0" style={{ width: "45%", height: 20 }} />
        </Box>
      </Flex>
      <Flex mb={mb} mt={mt}>
        <Box height={20} mr={mr} width={20}>
          <RoundShape color="#E0E0E0" style={{ width: 20 }} />
        </Box>
        <Box height={20} width="100%">
          <RectShape color="#E0E0E0" style={{ width: "40%", height: 20 }} />
        </Box>
      </Flex>
      <Box height={30} width="100%" mb={4}>
        <RectShape color="#E0E0E0" style={{ width: "100%", height: 30 }} />
      </Box>
      <Box height={30} width="100%" mb={4}>
        <RectShape color="#E0E0E0" style={{ width: "100%", height: 30 }} />
      </Box>
      <Box height={30} width="100%" mb={4}>
        <RectShape color="#E0E0E0" style={{ width: "100%", height: 30 }} />
      </Box>
    </>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1500}
      ready={false}
    >
      <></>
    </ReactPlaceholder>
  );
}
// == Styles ===============================================================
