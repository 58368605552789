import React, { useCallback } from "react";
import gql from "graphql-tag";
import { usePlaidLink } from "react-plaid-link";
import { isEmpty } from "lodash";
import { Text, Box, Button, Link } from "@atoms";
import { constants, ErrorService } from "@services";
import { useStoreAppValue } from "@hooks";
// import styled from "@emotion/styled";
// == Types ================================================================

type TPlaidValues = {
  plaidAccountId: string;
  plaidPublicToken: string;
  account: {
    id: string;
    name: string;
    type: string;
    subtype: string;
    mask: string;
  };
  institution: {
    name: string;
    institution_id: string;
  };
};
interface IProps {
  setPlaidValues: (plaidValues: TPlaidValues) => void;
  plaidValues: TPlaidValues | {};
  setFieldValue: (field, value) => void;
  loading: boolean;
  accountInfoExist: boolean;
  isSubmitting: boolean;
}

// == Constants ============================================================

BeneficiaryTransfer.defaultProps = {};

export const BENEFICIARY_UPDATE_MUTATION = gql`
  mutation BeneficiaryUpdateMutation(
    $input: BeneficiaryUpdateInputType!
    $organization: BeneficiaryOrganizationInput
  ) {
    BeneficiaryUpdate(input: $input, organization: $organization) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Beneficiary {
        id
        hwUserToken
        hwUserStatus
        hwUserVerificationStatus
        plaidAccountInfo
        paymentType
      }
    }
  }
`;

// == Component ============================================================

export default function BeneficiaryTransfer({
  plaidValues,
  setPlaidValues,
  setFieldValue,
  loading,
  accountInfoExist,
  isSubmitting,
}: IProps) {
  const { currency, environment, plaidKey } = useStoreAppValue();

  const onSuccess = useCallback(
    (token, metadata) => {
      const { public_token, account_id, account, institution } = metadata;
      setPlaidValues({
        plaidPublicToken: public_token,
        plaidAccountId: account_id,
        account,
        institution,
      });
      setFieldValue("plaidPublicToken", public_token);
      setFieldValue("plaidAccountId", account_id);
    },
    [setFieldValue, setPlaidValues]
  );

  const onEvent = useCallback((eventName, metadata) => {
    if (eventName === "ERROR") {
      ErrorService.error(new Error(JSON.stringify(metadata)));
    }
    return null;
  }, []);

  const onExit = useCallback((error, metadata) => {
    if (error) {
      ErrorService.error(new Error(JSON.stringify(error)));
    }
    return null;
  }, []);

  const plaidLinkEnv = () => {
    if (environment === "development") {
      return "sandbox";
    }
    if (environment === "staging") {
      return "sandbox";
    }
    if (constants.IS_PRODUCTION) {
      return "production";
    }
    return "sandbox";
  };

  const config = {
    clientName: "FlipGive",
    env: plaidLinkEnv(),
    product: ["auth", "identity"],
    publicKey: plaidKey,
    countryCodes: currency === "USD" ? ["US"] : ["CA"],
    onSuccess,
    onEvent,
    onExit,
    accountSubtypes: {
      depository: ["checking", "savings"],
    },
  };

  const { open, ready, error } = usePlaidLink(config);
  return (
    <>
      {!isEmpty(plaidValues) && (
        <Box mb={2}>
          <Box>
            <Text fontWeight="bold">Institution: </Text>
            <Text>{plaidValues.institution.name}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Account name: </Text>
            <Text>{plaidValues.account.name}</Text>
          </Box>
          <Box mb={3}>
            <Text fontWeight="bold">Account ending in: </Text>
            <Text>{plaidValues.account.mask}</Text>
          </Box>
        </Box>
      )}
      {accountInfoExist ? (
        <>
          {!ready || loading || isSubmitting ? (
            <Text color="primary" display="block" mb={3}>
              Change bank account
            </Text>
          ) : (
            <Link display="block" mb={3} onClick={() => open()}>
              {loading ? "Retrieving..." : "Change bank account"}
            </Link>
          )}
        </>
      ) : (
        <Box flex={1} mb={2} textAlign="center">
          <Button
            disabled={!ready || loading || isSubmitting}
            type="button"
            width={1}
            onClick={() => open()}
          >
            {loading ? "Retrieving..." : "Link Bank Account"}
          </Button>
        </Box>
      )}
    </>
  );
}

// == Styles ===============================================================
