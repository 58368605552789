import React from "react";
import { differenceInCalendarDays } from "date-fns";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { theme } from "@styles";
import { TextUppercase, Box, Flex, Heading } from "@atoms";
import { OccasionHeroFragment } from "./__generated__/OccasionHeroFragment";
// == Types ================================================================

interface IProps {
  occasion: OccasionHeroFragment;
  imageWidth: number;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment OccasionHeroFragment on Occasion {
      id
      heroHeader
      heroHeaderColor
      heroPromoLabel
      heroImage
      heroSubHeader
      heroSubHeaderColor
      showDaysLeft
      startDate
      endDate
      heroBackgroundColor
    }
  `,
};

const DEFAULT_PROPS = {
  imageWidth: theme.sizes.containerWidth,
};

// == Component ============================================================

const OccasionHero = ({ occasion, imageWidth }: IProps) => {
  const {
    heroHeader,
    heroHeaderColor,
    heroImage,
    heroSubHeader,
    heroPromoLabel,
    heroSubHeaderColor,
    showDaysLeft,
    endDate,
    heroBackgroundColor,
  } = occasion;

  const daysLeft = Math.abs(differenceInCalendarDays(new Date(), new Date(endDate)));

  const backgroundColor = heroBackgroundColor || theme.colors.primary;
  const backgroundContent = heroImage
    ? `url(${heroImage}&twic=v1/resize=${imageWidth})`
    : backgroundColor;
  const subheaderColor = heroSubHeaderColor || "white";

  return (
    <EHeroContainer background={backgroundContent} position="relative" width="100%">
      <Flex
        alignItems="center"
        bottom={0}
        flexDirection="column"
        height="100%"
        justifyContent="center"
        left={0}
        padding={[1, 2, 3]}
        position="absolute"
        right={0}
        top={0}
      >
        {!!heroPromoLabel && (
          <Box bg="white" borderRadius={4} color="black" fontSize={[0, 1]} mb={[2, 3]} p={[1, 2]}>
            {heroPromoLabel}
          </Box>
        )}
        <Heading
          color={heroHeaderColor}
          fontSize={[4, 5, 6]}
          lineHeight="title"
          mb={[2, 2]}
          ph={[1, 2, 3]}
          textAlign="center"
        >
          {heroHeader}
        </Heading>
        <TextUppercase as="p" color={subheaderColor} fontSize={0} mb={[1, 2, 3]} textAlign="center">
          {heroSubHeader}
        </TextUppercase>
        {showDaysLeft && (
          <Box bg="white" borderRadius={4} color="black" fontSize={[0, 1]} mt={1} p={[1, 2]}>
            {daysLeft} Days Left
          </Box>
        )}
      </Flex>
    </EHeroContainer>
  );
};

OccasionHero.defaultProps = DEFAULT_PROPS;

export default OccasionHero;

OccasionHero.fragments = FRAGMENTS;
// == Styles ===============================================================
const EHeroContainer = styled(Flex)`
  background-size: cover;
  &:after {
    content: "";
    display: block;
    padding-bottom: 50%;
  }
`;
