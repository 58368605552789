import React, { useMemo } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { LinkCategory, MerchantDepartmentType } from "@types";
import { analytics } from "@services";
import { getPaginatedNodes } from "@utils";
import SearchModalResults from "./SearchModalResults";

// == Types ================================================================

interface IProps {
  selectedItem: $FixMe;
  highlightedIndex: $FixMe;
  getItemProps: $FixMe;
  inputValue: string | null;
  linkCategoryFilter: LinkCategory | null;
  departmentFilter: MerchantDepartmentType | null;
  orderByFilter: string | null;
  orderDirection: string | null;
}

// == Constants ============================================================

const BRAND_SEARCH_QUERY = gql`
  query SearchBarResultsQuery(
    $query: String!
    $first: Int!
    $after: String
    $coordinates: CoordinatesInput
    $linkCategory: LinkCategory
    $orderBy: String
    $department: MerchantDepartmentType
    $orderDirection: String
  ) {
    AdvancedBrandSearch(
      query: $query
      first: $first
      after: $after
      coordinates: $coordinates
      linkCategory: $linkCategory
      department: $department
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      edges {
        cursor
        node {
          id
          ...SearchModalResultsFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${SearchModalResults.fragments.fields}
`;

// == Component ============================================================

const SearchBarResultsQuery = ({
  // selectedItem,
  // highlightedIndex,
  // getItemProps,
  inputValue,
  linkCategoryFilter,
  departmentFilter,
  orderByFilter,
  orderDirection,
}: IProps) => {
  const { loading, data } = useQuery(BRAND_SEARCH_QUERY, {
    variables: {
      query: inputValue,
      first: 100,
      linkCategory: linkCategoryFilter,
      department: departmentFilter,
      orderBy: orderByFilter,
      orderDirection,
    },
    skip: !(inputValue?.trim() && inputValue?.trim().length >= 3),
    onCompleted: () => {
      if (inputValue)
        analytics.track.search(
          { analyticsLocation: "store", query: inputValue },
          { search_query: inputValue }
        );
    },
  });

  const filteredBrands = useMemo(() => {
    const brands = getPaginatedNodes(data?.AdvancedBrandSearch.edges);
    if (linkCategoryFilter) {
      // to be replaced with updating the query instead
      // once we update the api to allow filtering by link type
      return brands.filter((brand) => {
        switch (linkCategoryFilter) {
          case LinkCategory.SHOP_ONLINE:
            return brand?.maxGiveLinks.shopOnline?.giveAmount;
          case LinkCategory.GIFT_CARDS:
            return brand?.maxGiveLinks.giftcard?.giveAmount;
          case LinkCategory.REGISTER:
            return brand?.maxGiveLinks.registerAndEarn?.giveAmount;
          case LinkCategory.SHOP_IN_STORE:
            return brand?.maxGiveLinks.inStore?.giveAmount;
          default:
            return false;
        }
      });
    }
    return brands;
  }, [data, linkCategoryFilter]);
  return <SearchModalResults {...{ inputValue, loading, brands: filteredBrands }} />;
};

export default SearchBarResultsQuery;

// == Styles ===============================================================
