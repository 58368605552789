// taken from https://github.com/streamich/react-use
import { useEffect, useState } from "react";

// == Types ================================================================

// == Constants ============================================================

// == Hook =================================================================

export function useLoadingDelay(ms = 300) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setReady(true), ms);
    return () => clearTimeout(timer);
  }, [ms]);

  return ready;
}
