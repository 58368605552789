import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetShortcuts } from "./__generated__/GetShortcuts";

const SHOP_SHORTCUTS_QUERY = gql`
  query GetShortcuts($id: ID!) {
    Collection(id: $id) {
      id
      links(first: 10) {
        id
        shortcutUrl
        name
        shortName
      }
    }
  }
`;

export const useShopShortcuts = (id: string) => {
  const { data, loading, error } = useQuery<GetShortcuts>(SHOP_SHORTCUTS_QUERY, {
    variables: {
      id,
    },
  });

  return {
    shortcuts: data?.Collection?.links,
    loading,
    error,
  };
};
