import gql from "graphql-tag";
import { BrandBlock } from "@organisms/brandBlockComponents";
import { useQuery } from "react-apollo";
import { getPaginatedNodes } from "@components/common/utils";

// == Constants ============================================================

const CATEGORY_QUERY = gql`
  query CurrentCategoryQuery {
    Viewer {
      id
      activeCampaign {
        id
        category {
          id
          name
        }
      }
    }
  }
`;

const SPORT_BRANDS_QUERY = gql`
  query SportBrandsQuery($categoryId: ID!) {
    CategorySearch(categoryId: $categoryId) {
      id
      name
      brands {
        edges {
          node {
            id
            ...BrandBlockFragment
          }
        }
      }
    }
  }
  ${BrandBlock.fragments.fields}
`;

// == Hook ============================================================

export default function useSportCategoryBrands() {
  const { data: campaignData, loading: isCampaignLoading, error: campaignError } = useQuery(
    CATEGORY_QUERY
  );
  const categoryId = campaignData?.Viewer?.activeCampaign?.category?.id;
  const categoryName = campaignData?.Viewer?.activeCampaign?.category?.name;
  const { data: brandsData, loading: isBrandsLoading, error: brandsError } = useQuery(
    SPORT_BRANDS_QUERY,
    {
      variables: {
        categoryId,
      },
      skip: !categoryId,
    }
  );

  const brands = getPaginatedNodes(brandsData?.CategorySearch?.brands?.edges);

  return {
    brands,
    isLoading: isCampaignLoading || isBrandsLoading,
    hasError: campaignError || brandsError,
    categoryName,
  };
}
