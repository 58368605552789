import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ApolloError } from "apollo-client";
import { Modal, Box, Flex, Text, TouchableOpacity, ChevronLeft, Button } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { ErrorService } from "@services";
import {
  RemoveMemberMutation,
  RemoveMemberMutationVariables,
} from "./__generated__/RemoveMemberMutation";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  isRemoveMemberOpen: boolean;
  setIsRemoveMemberOpen: (isRemoveMemberOpen: boolean) => void;
  id: string;
  userId: string;
  name: string;
  isViewerFundraiser: boolean;
}

// == Constants ============================================================

RemoveMember.defaultProps = {};

const REMOVE_MEMBER_MUTATION = gql`
  mutation RemoveMemberMutation($campaignId: ID!, $userId: ID!) {
    LeaveCampaign(input: { campaignId: $campaignId, userId: $userId }) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        fundraisers {
          id
          name
          joinedAt
          deactivatedAt
        }
      }
    }
  }
`;

// == Component ============================================================

export default function RemoveMember({
  isRemoveMemberOpen,
  setIsRemoveMemberOpen,
  id,
  userId,
  name,
  isViewerFundraiser,
}: IProps) {
  const { campaignId, appUrl } = useStoreAppValue();

  const onError = (error?: ApolloError) => {
    if (error) ErrorService.error(error);
    Materialize?.toast?.(
      `There was an ERROR removing ${name}. Please try again`,
      4000,
      "rounded toast-danger"
    );
    setIsRemoveMemberOpen(false);
  };

  const [createRemoveMemberMutation, { loading }] = useMutation<
    RemoveMemberMutation,
    RemoveMemberMutationVariables
  >(REMOVE_MEMBER_MUTATION, {
    onError,
    onCompleted: ({ LeaveCampaign }) => {
      if (LeaveCampaign.__typename === "MutationError") {
        onError();
        return;
      }
      Materialize?.toast?.(`${name} has been successfully removed.`, 4000, "rounded toast-success");
      if (isViewerFundraiser) {
        window.location.href = `${appUrl}/user/teams`;
      } else {
        setIsRemoveMemberOpen(false);
      }
    },
  });

  const onClickRemoveMember = () => {
    createRemoveMemberMutation({
      variables: { campaignId: campaignId.toString(), userId },
    });
  };

  const primary = useStoreAppValue()?.theme?.color?.primary ?? "#27B7BA";
  return (
    <Modal isOpen={isRemoveMemberOpen} onDismiss={() => setIsRemoveMemberOpen(false)}>
      <Flex alignItems="center" flexDirection="column" justifyContent="center" padding={3}>
        <Flex alignSelf="flex-start" paddingBottom={[2, 3]}>
          <TouchableOpacity onTouch={() => setIsRemoveMemberOpen(false)}>
            <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
          </TouchableOpacity>
          <Box paddingLeft={[2, 3]}>
            <Text fontWeight="bold">Remove Supporter</Text>
          </Box>
        </Flex>
        <Flex alignItems="center" flexDirection="column" py={2} width={1}>
          <Box display="inline" textAlign="center">
            <Text>This will remove</Text>
            <Text color="primary" fontWeight="bold">
              {" "}
              {name}
            </Text>
            <Text> from this team.</Text>
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" py={2}>
          <Button height={50} minWidth={250} variant="danger" onClick={onClickRemoveMember}>
            {loading ? "Processing..." : `Remove ${name}`}
          </Button>
        </Flex>
        <Box py={2} textAlign="center" width={1}>
          <Button
            height={50}
            minWidth={250}
            variant="clear"
            onClick={() => setIsRemoveMemberOpen(false)}
          >
            <Text color="primary" fontSize={[1, 2]}>
              Cancel
            </Text>
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
}

// == Styles ===============================================================
