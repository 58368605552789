import React from "react";
import { Button, IButtonProps } from "..";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export const DangerButton = (props: IButtonProps) => {
  return <Button color="white" variant="danger" {...props} />;
};

// == Styles ===============================================================
