import React from "react";
import ReactPlaceholder from "react-placeholder";
import { TextRow, RoundShape } from "react-placeholder/lib/placeholders";
import { LoadingBox } from "@components/common/molecules";
import { Flex, Box } from "@atoms";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// == Component ============================================================

const BrandModalLoading = () => {
  const brandModalLinkListItemPlaceholder = (numberOfPlaceholder: number): JSX.Element[] | null => {
    const placeholderItems = [];

    const BrandModalLinkListItemPlaceholder = (totalPlaceholders: number) => (
      <Flex alignItems="center" justifyContent="center" key={totalPlaceholders} mt={3} p={3}>
        <Box height={[50, 75]} width={[50, 75]}>
          <RoundShape color="#E0E0E0" />
        </Box>
        <Flex flex="1" flexDirection="column" ml={3}>
          <Box>
            <TextRow color="#E0E0E0" />
          </Box>
          <Box>
            <TextRow color="#E0E0E0" />
          </Box>
          <Box>
            <TextRow color="#E0E0E0" />
          </Box>
        </Flex>
      </Flex>
    );

    for (
      let totalPlaceholders = 0;
      totalPlaceholders < numberOfPlaceholder;
      totalPlaceholders += 1
    ) {
      placeholderItems.push(BrandModalLinkListItemPlaceholder(totalPlaceholders));
    }

    return placeholderItems;
  };
  const LoadingComponent = () => (
    <Box p={1}>
      <Flex
        alignItems="center"
        borderBottom="silver"
        data-testid="container"
        flexDirection="column"
        mb={4}
        pb={4}
      >
        <LoadingBox height={175} />
        <Flex alignItems="center" flexDirection="column" mt={[3, 4]}>
          <Box width={[300, 500]}>
            <TextRow color="#E0E0E0" />
          </Box>
          <Box width={[200, 300]}>
            <TextRow color="#E0E0E0" />
          </Box>
          <Box width={[240, 400]}>
            <TextRow color="#E0E0E0" />
          </Box>
          <Box width={[200, 300]}>
            <TextRow color="#E0E0E0" />
          </Box>
        </Flex>
      </Flex>
      <Box pb={4}>{brandModalLinkListItemPlaceholder(1)}</Box>
    </Box>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1500}
      ready={false}
    />
  );
};

export default BrandModalLoading;

// == Styles ===============================================================
