import React from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { func, shape, string, bool } from "prop-types";
import TransferButton from "./TransferButton";
import TransferSuccessModal from "./TransferSuccessModal";
import UserCampaignList from "./UserCampaignList";
import SearchCampaigns from "./SearchCampaigns";
import SearchTeamButton from "./SearchTeamButton";

const CAMPAIGN_SEARCH_QUERY = gql`
  query CampaignSearchQuery($query: String, $mineOnly: Boolean, $currency: Currency!) {
    CampaignSearch(query: $query, mineOnly: $mineOnly, status: RUNNING, currency: $currency) {
      edges {
        node {
          id
          name
          url
        }
      }
    }
  }
`;

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => (
  <span className="suggestion-content">{suggestion.name}</span>
);

@withApollo
export default class SearchCampaignModal extends React.Component {
  static propTypes = {
    client: shape({
      query: func.isRequired,
    }).isRequired,
    availableAmount: shape({
      formatted: string.isRequired,
    }).isRequired,
    showSuccess: bool.isRequired,
    onTransferComplete: func.isRequired,
    onCloseModal: func.isRequired,
    currency: string.isRequired,
  };

  state = {
    value: "",
    mineOnly: true,
    suggestions: [],
    campaign: null,
  };

  componentDidMount() {
    $("#searchCampaignModal").modal();
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      campaign: null,
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.setCampaign(suggestion);
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    const { mineOnly } = this.state;
    const { currency } = this.props;

    const data = await this.props.client.query({
      query: CAMPAIGN_SEARCH_QUERY,
      variables: { query: value, mineOnly, currency },
    });
    const suggestions = data.data.CampaignSearch.edges.map((edge) => edge.node);

    this.setState({
      suggestions,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  setCampaign = (campaign) => {
    this.setState({
      campaign,
    });
  };

  clickToOpenModal = (e) => {
    e.preventDefault();
    $("#searchCampaignModal").modal("open");
  };

  toggleMineOnly = () => {
    const { mineOnly } = this.state;
    this.setState({
      mineOnly: !mineOnly,
      campaign: null,
    });
  };

  render() {
    const { value, suggestions, mineOnly, campaign } = this.state;
    const { availableAmount, showSuccess, onTransferComplete, onCloseModal, currency } = this.props;
    return (
      <div className="modal" id="searchCampaignModal">
        {showSuccess ? (
          <TransferSuccessModal
            availableAmount={availableAmount}
            campaign={campaign}
            onCloseModal={onCloseModal}
          />
        ) : (
          <div className="modal-content modal-content--more-padding">
            <div className="modal-header centered h2 transfer-title">Transfer Funds</div>
            <button className="modal-close modal__close">
              <span className="modal__close-icon" />
              <span className="sr-only">Close</span>
            </button>
            <div className="modal-content centered">
              {/* {!mineOnly && (
                <SearchTeamButton mineOnly={false} toggleMineOnly={this.toggleMineOnly} />
              )} */}
              {mineOnly ? (
                <UserCampaignList
                  campaign={campaign}
                  currency={currency}
                  setCampaign={this.setCampaign}
                />
              ) : null}
              {/*
              ) : (
                <SearchCampaigns
                  value={value}
                  suggestions={suggestions}
                  mineOnly={mineOnly}
                  campaign={campaign}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionSelected={this.onSuggestionSelected}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  onChange={this.onChange}
                />
              )}
              */}
              {/* {mineOnly && <SearchTeamButton mineOnly toggleMineOnly={this.toggleMineOnly} />} */}
              <div className="event-cancelled-modal__footer">
                <TransferButton
                  availableAmount={availableAmount}
                  campaign={campaign}
                  onTransferComplete={onTransferComplete}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
