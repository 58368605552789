import React, { useState } from "react";
import gql from "graphql-tag";
import { Text, Flex, TouchableOpacity } from "@atoms";
import { constants } from "@services";
import { useStoreAppValue } from "@hooks";
import { FundsPageFundraiserQuery } from "../FundsPageFundraiser";
import { FundsPageEarningsFundraisersListItemFundraiserFragment } from "./__generated__/FundsPageEarningsFundraisersListItemFundraiserFragment";

// *** commented out and make type STRING because the logic below threw an error when using the codegen type
// import { FundsPageEarningsFundraisersListItemPrivacyLevel } from "./__generated__/FundsPageEarningsFundraisersListItemPrivacyLevel";

// == Types ================================================================

interface IProps {
  fundraiser: FundsPageEarningsFundraisersListItemFundraiserFragment;
  isViewerFundraiser: boolean;
  privacyLevel: string;
}

// == Constants ============================================================

FundsPageEarningsFundraisersListItem.fragments = {
  fundraiser: gql`
    fragment FundsPageEarningsFundraisersListItemFundraiserFragment on Fundraiser {
      id
      userId
      name
      firstName
      lastName
      avatarImage
      isOwner
      joinedAt
      deactivatedAt
      amountRaised {
        dollarsAmount
        formatted
      }
    }
  `,
  privacyLevel: gql`
    fragment FundsPageEarningsFundraisersListItemPrivacyLevel on Campaign {
      id
      privacyLevel
    }
  `,
};

// == Component ============================================================

export default function FundsPageEarningsFundraisersListItem({
  fundraiser,
  isViewerFundraiser,
  privacyLevel,
}: IProps) {
  const [isOpen, setOpen] = useState(false);
  const { currentUser, isMember } = useStoreAppValue();
  const {
    PRIVACY_LEVEL_TYPES: { ANONYMOUS },
  } = constants;
  const { id, userId, name, isOwner, amountRaised, deactivatedAt } = fundraiser;
  const isDirect = name === id;
  const isDeactivated = !!deactivatedAt;
  const isCampaignAnonymous = privacyLevel === ANONYMOUS;

  const renderName = () => {
    if (isDirect) return `${name} (Team)`;
    if (isViewerFundraiser && !isOwner) return `${name} (You)`;
    if (isViewerFundraiser && isCampaignAnonymous) return `${name} (You)`;
    if (isOwner) return `${name} (Captain)`;
    return name;
  };

  return (
    <>
      <TouchableOpacity
        containerProps={{ style: { cursor: currentUser && isMember ? `pointer` : `default` } }}
        onTouch={currentUser && isMember ? () => setOpen(true) : null}
      >
        <Flex
          bg={isViewerFundraiser ? "lightNotificationBackground" : "inherit"}
          borderRadius={isViewerFundraiser ? "5px" : ""}
          justifyContent="space-between"
          opacity={isDirect || !isDeactivated ? 1 : 0.5}
          padding={3}
          width={1}
        >
          <Flex alignItems="center">
            <Flex flexDirection="column" paddingLeft={5}>
              <Text display="block" fontSize={[1, 2]}>
                {renderName()}
              </Text>
            </Flex>
          </Flex>
          <Flex alignItems="center" marginLeft={2}>
            <Text fontSize={[1, 2]} paddingRight={2}>
              {amountRaised.formatted}
            </Text>
            {/* {!!currentUser && isMember && <ChevronRight color="#666" size="xs" />} */}
          </Flex>
        </Flex>
      </TouchableOpacity>
      {isOpen && (
        <FundsPageFundraiserQuery
          id={id}
          isOpen={isOpen}
          name={name}
          setOpen={setOpen}
          userId={userId}
        />
      )}
    </>
  );
}

// == Styles ===============================================================
