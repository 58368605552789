import React from "react";
import { format, formatDistanceStrict, isFuture } from "date-fns";

interface IDateRangeSentenceProps {
  now: Date;
  start?: Date | null;
  stop?: Date | null;
}

export function DateRangeSentence({ now, start, stop }: IDateRangeSentenceProps) {
  return (
    <div>
      {start ? (
        <span>
          From{" "}
          <strong>
            {formatDistanceStrict(start, now)} {isFuture(start) ? "from now" : "ago"}
          </strong>{" "}
          (<em>{format(start, "yyyy-MM-dd HH:mm:ss")} EST</em>)
        </span>
      ) : null}{" "}
      {stop ? (
        <span>
          to{" "}
          <strong>
            {formatDistanceStrict(stop, now)} {isFuture(stop) ? "from now" : "ago"}
          </strong>{" "}
          (<em>{format(stop, "yyyy-MM-dd HH:mm:ss")} EST</em>)
        </span>
      ) : null}
    </div>
  );
}
