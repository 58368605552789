import React from "react";
import { Provider } from "mobx-react";
import { ApolloProvider } from "react-apollo";
import { ThemeProvider } from "emotion-theming";
import { generateTheme } from "@styles";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";
import { FlipgiveStore } from "@stores";
import { ICurrentUser, StoreAppProvider, useFullStory } from "@hooks";
import { VoucherUpdateBalanceButton } from "./VoucherUpdateBalanceButton";
import { GiftcardReloadSettings } from "./GiftcardReloadSettings";
// import { BeneficiaryProvider } from "./context";
// import { CampaignBeneficiaryApp } from "./CampaignBeneficiaryApp";

interface IProps {
  campaignId: string;
  redirectUrl: string;
  currentUser: object;
  primaryColor: string;
  giftcardId: string;
}

export default (props: IProps) => {
  const {
    campaignId,
    environment,
    incentiveAvailable,
    incentiveAmount,
    incentiveTimeLeft,
    primaryColor,
    featureFlags,
    currency,
    currentUser,
    fundraiserId,
    isMember,
    isOwner,
    isContinuity,
    stripeKey,
    mapLocation,
    siteName,
    isVoucher,
    isPartnerSite,
  } = props;

  const { giftcardId } = props;
  const theme = generateTheme({ primary: primaryColor });
  Settings.setDefaultsFromProps(props);
  useFullStory(props.currentUser as ICurrentUser);

  FlipgiveStore.setCurrentUser(props.currentUser);
  FlipgiveStore.setCampaignId(props.campaignId);

  const initialState = {
    featureFlags,
    campaignId,
    environment,
    incentiveAvailable,
    incentiveAmount,
    incentiveTimeLeft,
    currency,
    currentUser,
    fundraiserId,
    isMember,
    isOwner,
    isContinuity,
    stripeKey,
    mapLocation,
    theme,
    siteName,
    isPartnerSite,
  };
  const client = apolloClient(Settings.apiUrl);
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <Provider FlipgiveStore={FlipgiveStore}>
          <StoreAppProvider initialState={initialState}>
            <ThemeProvider theme={theme}>
              {isVoucher ? (
                <VoucherUpdateBalanceButton giftcardId={giftcardId} />
              ) : (
                <GiftcardReloadSettings
                  giftcardId={giftcardId}
                  isPartnerSite={isPartnerSite}
                  primaryColor={primaryColor}
                />
              )}
            </ThemeProvider>
          </StoreAppProvider>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
