import React from "react";
import { FontAwesome } from "../../FontAwesome";
import { IFlexProps } from "../../Flex";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  containerProps?: IFlexProps;
  iconSize: "xs" | "sm" | "lg" | "2x" | "3x" | "5x" | "7x" | "10x";
}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

const ChevronRight = ({ containerProps, ...rest }: IProps) => {
  return (
    <FontAwesome
      containerProps={containerProps}
      data-testid="chevron-right"
      icon="chevron-right"
      {...rest}
    />
  );
};

ChevronRight.defaultProps = DEFAULT_PROPS;

export default ChevronRight;
// == Styles ===============================================================
