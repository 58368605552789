import React from "react";
import { string } from "prop-types";
import YouTube from "react-youtube";

export default class YouTubeVideo extends React.Component {
  static propTypes = {
    youtubeId: string.isRequired,
  };

  state = { showVideo: false };

  onYouTubePreviewClick = (e) => {
    this.setState({ showVideo: true });
    e.preventDefault();
  };

  render() {
    const { youtubeId } = this.props;
    const opts = {
      frameBorder: 0,
      gesture: "media",
      playerVars: {
        autoplay: 1,
        showinfo: 0,
        rel: 0,
      },
    };

    return (
      <div className="timeline-video-container">
        {!this.state.showVideo ? (
          <a className="timeline-video__link" href="#" onClick={this.onYouTubePreviewClick}>
            <i className="fa fa-youtube-play timeline-video__play-icon" />
            <img
              alt="Youtube Video"
              src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
            />
          </a>
        ) : (
          <div className="timeline-video">
            <YouTube opts={opts} videoId={youtubeId} />
          </div>
        )}
      </div>
    );
  }
}
