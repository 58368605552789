import React from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { Box, H3, Text } from "@atoms";
import { LinkType } from "@types";
import { isShopLink } from "@utils";

// == Types ================================================================

interface IProps {
  link: $FixMe;
}

interface ILinkModalHtmlSection {
  title: string;
  html?: string;
  linkType: LinkType;
}

// == Constants ============================================================
LinkModalWaysToEarn.fragment = gql`
  fragment LinkModalWaysToEarnFragment on Link {
    id
    earnInstructions
  }
`;

LinkModalWaysToEarn.defaultProps = {};

// == Component ============================================================

export function LinkModalWaysToEarn({ link }: IProps) {
  if (!link.earnInstructions) return null;
  return (
    <LinkModalHtmlSection
      html={link.earnInstructions}
      linkType={link.type}
      title="To earn cashback:"
    />
  );
}

export function LinkModalHtmlSection({ title, html, linkType }: ILinkModalHtmlSection) {
  if (!html) return null;

  return (
    <Box p={3}>
      <H3>{title}</H3>
      <EText as="div" color="default" dangerouslySetInnerHTML={{ __html: html }} />
      {isShopLink(linkType) && (
        <>
          <Text as="p" textStyle="legal">
            Only use coupon codes advertised here. The use of codes from other websites can void
            your cash back.
          </Text>

          <Text as="p" textStyle="legal">
            Do not pay with Loyalty Points or Rewards Cash. This will void your cash back.
          </Text>
        </>
      )}
    </Box>
  );
}

// == Styles ===============================================================

const EText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  * {
    font-size: ${({ theme }) => theme.fontSizes[0]}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    list-style: default;
    li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 15px;
    }
  }
`;
