import React from "react";
import { shape, func, string } from "prop-types";
import { AvatarImage } from "@components";

const UserCampaign = ({ myCampaign, setCampaign, checkedRadio, changeRadio }) => (
  <div
    className="user-transfer-campaign"
    role="button"
    tabIndex={1}
    onClick={(e) => {
      e.preventDefault();
      setCampaign(myCampaign);
      changeRadio(myCampaign.id);
    }}
    onKeyPress={(e) => {
      e.preventDefault();
      setCampaign(myCampaign);
      changeRadio(myCampaign.id);
    }}
  >
    <div className="user-transfer-campaign__radio-button">
      <input
        readOnly
        checked={myCampaign.id === checkedRadio}
        id={myCampaign.id}
        name="campaign"
        type="radio"
        value={myCampaign.id}
      />
      <label htmlFor={myCampaign.id} />
    </div>
    <div className="user-transfer-campaign__row">
      <AvatarImage
        alt={myCampaign.name}
        className="user-transfer-campaign__image"
        name={myCampaign.name}
        src={myCampaign.photoImage}
      />
      <div className="user-transfer-campaign__details">
        <div className="user-transfer-campaign__name">{myCampaign.name}</div>
        <div className="user-transfer-campaign__goal text-tiny">
          {myCampaign.amountRaised.formatted} of {myCampaign.goal.formatted} raised
        </div>
      </div>
    </div>
  </div>
);

export default UserCampaign;

UserCampaign.propTypes = {
  myCampaign: shape({}).isRequired,
  setCampaign: func.isRequired,
  checkedRadio: string,
  changeRadio: func.isRequired,
};

UserCampaign.defaultProps = {
  checkedRadio: null,
};
