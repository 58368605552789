import React from "react";
import { FontAwesome } from "../../FontAwesome";
import { IFlexProps } from "../../Flex";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  containerProps?: IFlexProps;
}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

const HeartOutline = ({ containerProps, ...rest }: IProps) => {
  return (
    <FontAwesome
      containerProps={containerProps}
      data-testid="heart-outline"
      icon={["far", "heart"]}
      {...rest}
    />
  );
};

HeartOutline.defaultProps = DEFAULT_PROPS;

export default HeartOutline;
// == Styles ===============================================================
