import React from "react";
import { LocationContext } from "@reach/router";
import queryString from "query-string";
import { ELinkModalBuyButton } from "@organisms/linkModalComponents/LinkModalBuyButton/LinkModalBuyButtonEmotion";
import { GiftcardIntentCreateInput, LinkType } from "@types";
import { useFullStoryEvents, useStoreAppValue, useFullStoryTrackingLocation } from "@hooks";
import { FullStoryEventNames, RailsUrl } from "@services";
import { LinkModalGiftcardContainerFragment } from "../__generated__/LinkModalGiftcardContainerFragment";

// == Types ================================================================

interface IProps {
  campaignId?: string;
  createIntentInput: GiftcardIntentCreateInput;
  disabled: boolean;
  fundraiserId?: string;
  link: LinkModalGiftcardContainerFragment;
  locationContext: LocationContext;
  onLinkClick: () => void;
}

// == Component ============================================================

export default function GiftcardBuyButton({
  campaignId,
  createIntentInput,
  disabled,
  fundraiserId,
  link,
  locationContext,
  onLinkClick,
}: IProps) {
  const { currentUser } = useStoreAppValue();

  const userNeedsSignUp = !currentUser && !campaignId && !fundraiserId;
  const { type: linkType } = link;
  const { tinyLinkKey } = locationContext.location.state;
  const { sendFullStoryEvent } = useFullStoryEvents();

  const createIntentUrl = () => {
    const { deviceAttributes, ...rest } = createIntentInput;
    const {
      brandId,
      collectionId,
      storefrontId,
      labelPromotionId,
      commissionPromotionId,
    } = queryString.parse(locationContext.location.search);

    const params = queryString.stringify({
      ...rest,
      brandId,
      collectionId,
      storefrontId,
      labelPromotionId,
      commissionPromotionId,
      linkType,
      deviceAttributes: JSON.stringify({ ...deviceAttributes }),
    });
    return RailsUrl.createIntentUrl({ params, campaignId });
  };

  const { collection, storefront } = useFullStoryTrackingLocation();

  const isBuy = linkType === LinkType.GIFTCARDOFFER || linkType === LinkType.AUTORELOADGIFTCARD;

  const onClick = (e: any) => {
    if (disabled) {
      e.preventDefault();
    } else {
      sendFullStoryEvent(FullStoryEventNames.intentClicked, {
        storefrontName: storefront,
        collectionName: collection,
        intentType: isBuy ? "buy" : "shop",
        brandName: link?.defaultBrand.name,
      });
      return currentUser && onLinkClick();
    }
    return null;
  };

  const checkoutHref = () => {
    if (disabled) return "";
    if (userNeedsSignUp) {
      return `${RailsUrl.createTeamUrl()}?s=${tinyLinkKey}`;
    }
    return createIntentUrl();
  };

  const variantPicker = () => (disabled ? "disabledSolid" : "primary");

  return (
    <ELinkModalBuyButton
      as="a"
      disabled={disabled}
      href={checkoutHref()}
      target="_blank"
      userNeedsSignUp={userNeedsSignUp}
      variant={variantPicker()}
      onClick={onClick}
    >
      {userNeedsSignUp ? "Sign Up And Register Your Team To Shop" : link.buttonCTA}
    </ELinkModalBuyButton>
  );
}
