import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Utils } from "@services";
import { NOTIFICATIONS_COUNT_QUERY } from "../queries";
import activityUrl from "../services/activityUrl";

const MARK_READ_MUTATION = gql`
  mutation MarkAllReadMutation($activityIds: [ID]!) {
    ActivityMarkNotificationsRead(activityIds: $activityIds)
  }
`;

@graphql(MARK_READ_MUTATION, { name: "markReadMutation" })
export default class NotificationActivity extends React.Component {
  static propTypes = {
    notification: PropTypes.shape({}).isRequired,
    activity: PropTypes.shape({}).isRequired,
    markReadMutation: PropTypes.func.isRequired,
  };

  handleClick = () => {
    const { notification, markReadMutation } = this.props;

    if (!notification.isRead) {
      const activityIds = notification.activities.map((activity) => activity.id);
      markReadMutation({
        variables: { activityIds },
        refetchQueries: [{ query: NOTIFICATIONS_COUNT_QUERY }],
      });
    }

    return true;
  };

  render() {
    const { notification, activity } = this.props;
    const { campaign, actor } = activity;
    const { title, description } = notification;

    return (
      <div className="notification-item__activity" onClick={this.handleClick}>
        <a
          className="notification-item__link"
          data-turbolinks={false}
          href={Utils.addUTM(activityUrl(notification), "feed", "notification", "")}
        >
          {actor.avatarImage ? (
            <img alt={actor.name} className="notification-item__image" src={actor.avatarImage} />
          ) : null}

          <div className="notification-item__content">
            <span className="notification-item__message">{title}</span>
            <span className="notification-item__description">{description}</span>
            <div className="notification-item__details">
              <span className="notification-item__date block-text">
                {moment.utc(activity.time).fromNow()}
              </span>
              {campaign && <span className="notification-item__campaign">{campaign.name}</span>}
            </div>
          </div>
        </a>
      </div>
    );
  }
}
