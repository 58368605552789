import React from "react";
import PropTypes from "prop-types";
import linkSelect from "../../services/linkSelect";

export default class SelectLinkInput extends React.Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    onRef: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.initSelect2();
    this.props.onRef(this);
    this.openSelect();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  setSelectRef = (input) => {
    this.selectInput = input;
  };

  selectValue = () => this.selectInput.value;

  openSelect = () => {
    $(this.selectInput).select2("open");
  };

  initSelect2 = () => {
    const { campaignId } = this.props;
    linkSelect(this.selectInput, campaignId, this.formatLink, this.formatLinkSelection);
  };

  clearInput = () => {
    $(this.selectInput)
      .val(null)
      .trigger("change");
  };

  render() {
    return (
      <div className="select2-input-field mt-3 shopping-list-select2">
        <select
          className="shopping-list-brand-search--js"
          id="shopping-list-brand-search"
          name="shopping_list_item[link_id]"
          ref={this.setSelectRef}
        />
        <label htmlFor="shopping-list-brand-search">Search by brand, department, or product</label>
      </div>
    );
  }
}
