import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import isEmpty from "lodash/isEmpty";
import { oc } from "ts-optchain";
import produce from "immer";
import { RouteComponentProps } from "@reach/router";
import { ErrorLoading } from "@organisms";
import { useStoreAppValue, useLoadingDelay } from "@hooks";
import { constants } from "@services";
// import { FundraiserStatus } from "@types";
import FundsPage from "./FundsPage";
import { FundsPageLoading } from "../../fundsPageComponents/components/FundsPageLoading";
import {
  FundsPageQuery as IFundsPageQuery,
  FundsPageQueryVariables,
} from "./__generated__/FundsPageQuery";

// == Types ================================================================

// == Constants ============================================================

const FUNDS_PAGE_QUERY = gql`
  query FundsPageQuery(
    $id: ID
    $filterOnlyActive: Boolean
    $groupAfter: String
    $groupId: ID!
    $hasCurrentUser: Boolean!
    $includeGroup: Boolean!
    $isMember: Boolean!
    $noGroupAfter: String
    $paginationLimitGroup: Int
    $paginationLimitNoGroup: Int
  ) {
    Campaign(id: $id) {
      id
      name
      ...FundsPageFragment
    }
  }
  ${FundsPage.fragments}
`;

export function FundsPageQuery(_props: RouteComponentProps) {
  const { campaignId, currentUser, isMember } = useStoreAppValue();
  const loadingDelayReady = useLoadingDelay();
  const { PAGINATION_FUNDRAISERS_LIMITS } = constants;

  const queryVariables = () => {
    return {
      id: campaignId,
      hasCurrentUser: !!currentUser,
      includeGroup: false,
      filterOnlyActive: true,
      isMember,
      groupAfter: null,
      groupId: 0,
      noGroupAfter: null,
      paginationLimitGroup: PAGINATION_FUNDRAISERS_LIMITS.PLAYER,
      paginationLimitNoGroup: PAGINATION_FUNDRAISERS_LIMITS.TEAM,
    };
  };

  const { data, error, loading, networkStatus, fetchMore } = useQuery<
    IFundsPageQuery,
    FundsPageQueryVariables
  >(FUNDS_PAGE_QUERY, {
    variables: queryVariables(),
    notifyOnNetworkStatusChange: true,
  });

  if ((loading && networkStatus !== 3) || !loadingDelayReady) return <FundsPageLoading />;
  if (error || isEmpty(data)) return <ErrorLoading />;

  const campaign = oc(data).Campaign();

  const onGroupFetchMore = (groupId: string, endCursor: string) => {
    fetchMore({
      query: FUNDS_PAGE_QUERY,
      variables: {
        ...queryVariables(),
        groupId,
        groupAfter: endCursor,
        includeGroup: true,
      },

      updateQuery: (previousResult, { fetchMoreResult }) => {
        // const { hasNextPage } = oc(previousResult).Campaign.group.paginatedFundraisers.pageInfo();

        if (!fetchMoreResult) return previousResult;

        const newFundraisers = oc(fetchMoreResult).Campaign.group.paginatedFundraisers.edges();

        const pageInfo = oc(fetchMoreResult).Campaign.group.paginatedFundraisers.pageInfo();

        const { endCursor: newResultEndCursor } = pageInfo;

        if (newResultEndCursor === endCursor) return previousResult;

        const newResult = produce(previousResult, (draftState) => {
          draftState.Campaign.groups.forEach((group) => {
            if (group.id === groupId) {
              group.paginatedFundraisers.edges = [
                ...group.paginatedFundraisers.edges,
                ...newFundraisers,
              ];
              group.paginatedFundraisers.pageInfo = pageInfo;
            }
          });

          draftState.Campaign.group = fetchMoreResult.Campaign.group;
        });

        return newResult;
      },
    });
  };

  const onNoGroupFetchMore = () => {
    const { endCursor } = oc(data).Campaign.noGroupFundraisers.paginatedFundraisers.pageInfo();

    fetchMore({
      query: FUNDS_PAGE_QUERY,
      variables: {
        ...queryVariables(),
        noGroupAfter: endCursor,
      },

      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { endCursor: previousResultEndCursor, hasNextPage } = oc(
          previousResult
        ).Campaign.noGroupFundraisers.paginatedFundraisers.pageInfo();

        if (!fetchMoreResult || !hasNextPage) return previousResult;

        const newFundraisers = oc(
          fetchMoreResult
        ).Campaign.noGroupFundraisers.paginatedFundraisers.edges();

        const pageInfo = oc(
          fetchMoreResult
        ).Campaign.noGroupFundraisers.paginatedFundraisers.pageInfo();

        const { endCursor: newResultEndCursor } = pageInfo;

        if (newResultEndCursor === previousResultEndCursor) return previousResult;

        const newResult = {
          Campaign: {
            ...previousResult.Campaign,
            noGroupFundraisers: {
              ...previousResult.Campaign.noGroupFundraisers,
              paginatedFundraisers: {
                cursor: newResultEndCursor,
                edges: [
                  ...previousResult.Campaign.noGroupFundraisers.paginatedFundraisers.edges,
                  ...newFundraisers,
                ],
                __typename:
                  previousResult.Campaign.noGroupFundraisers.paginatedFundraisers.__typename,
                pageInfo,
              },
            },
          },
        };

        return newResult;
      },
    });
  };

  return (
    <FundsPage
      campaign={campaign}
      onNoGroupFetchMore={onNoGroupFetchMore}
      onGroupFetchMore={onGroupFetchMore}
    />
  );
}
