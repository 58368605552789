import React from "react";
import { useStoreAppValue } from "@hooks";
// import styled from "@emotion/styled";
// import { BeneficiaryLoading } from "../BeneficiaryLoading";
import { useQuery } from "react-apollo";
import {
  CampaignBeneficiaryQueryVariables,
  CampaignBeneficiaryQuery,
} from "../BeneficiaryForm/__generated__/CampaignBeneficiaryQuery";
import { BeneficiaryType } from ".";
import BeneficiaryTypeLoading from "./BeneficiaryTypeLoading";
import { CAMPAIGN_BENEFICIARY_QUERY } from "../BeneficiaryForm/BeneficiaryFormQuery";

// == Types ================================================================

// == Constants ============================================================

BeneficiaryTypeQuery.defaultProps = {};

// == Component ============================================================

export default function BeneficiaryTypeQuery() {
  const { campaignId } = useStoreAppValue();

  const { data, loading } = useQuery<CampaignBeneficiaryQuery, CampaignBeneficiaryQueryVariables>(
    CAMPAIGN_BENEFICIARY_QUERY,
    {
      variables: {
        id: campaignId,
      },
      fetchPolicy: "no-cache",
    }
  );

  if (!data && loading) return <BeneficiaryTypeLoading />;

  const beneficiary = data?.Campaign?.beneficiary;
  const category = data?.Campaign?.category;
  const organization = data?.Campaign?.organization;

  return (
    <BeneficiaryType beneficiary={beneficiary} category={category} organization={organization} />
  );
}

// == Styles ===============================================================
