export default function addsDirectFundraiser(campaign) {
  const { name, photoImage, directAmountRaised } = campaign;
  return {
    id: name,
    name,
    avatarImage: photoImage,
    isGroup: false,
    isOwner: false,
    amountRaised: { ...directAmountRaised },
    isDirect: true,
  };
}
