import React from "react";
import Linkify from "react-linkify";
import moment from "moment";
import { string } from "prop-types";
import { Mentionify } from "@services";

function Comment({ avatarImage, name, comment, createdAt }) {
  return (
    <div className="timeline-comments__comment">
      <div className="media">
        <img
          alt="User avatar"
          className="d-flex mr-3 timeline-comments__comment-image"
          src={avatarImage}
        />
        <div className="media-body align-self-center timeline-comments__comment-content-container">
          <span className="timeline-comments__comment-content">
            <strong>{name}</strong>&nbsp;
            <Linkify properties={{ target: "_blank", rel: "nofollow" }}>
              {Mentionify(comment)}
            </Linkify>
          </span>
          <span className="timeline-comments__comment-time block-text">
            {moment.utc(createdAt).fromNow()}
          </span>
        </div>
      </div>
    </div>
  );
}

Comment.propTypes = {
  avatar_url: string,
  name: string.isRequired,
  created_at: string,
  comment: string.isRequired,
};

Comment.defaultProps = {
  avatar_url: null,
  created_at: null,
};

export default Comment;
