import React from "react";
import { Box, IBoxProps } from "../Box";

// == Types ================================================================

interface IProps extends IBoxProps {
  isMainContainer: boolean;
}

// == Constants ============================================================

// == Component ============================================================

export const Container = ({ isMainContainer, ...rest }: IProps) => {
  const py = isMainContainer ? [3, 3, 4] : 0;

  return <Box py={py} {...rest} />;
};

Container.defaultProps = {
  mx: "auto",
  maxWidth: "1140px",
  px: [2, 2, 3],
  isMainContainer: false,
};

// == Styles ===============================================================
