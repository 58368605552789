import React, { useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo";
import capitalize from "lodash/capitalize";
import { Box, Flex, H1, Image, Text } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { ErrorService, RailsUrl, analytics } from "@services";
import { OnboardingContainer } from "../components";
import { OnboardingAnswerButton } from "./components";
import { OnboardingQuestionsQuery } from "./__generated__/OnboardingQuestionsQuery";
import {
  OnboardingAnswerSubmit,
  OnboardingAnswerSubmitVariables,
} from "./__generated__/OnboardingAnswerSubmit";

// == Types ================================================================

interface IParams {
  id: string;
}

// == Constants ============================================================

const ONBOARDING_QUESTIONS_QUERY = gql`
  query OnboardingQuestionsQuery {
    Viewer {
      id
      onboarding {
        id
        questions {
          id
          text
          scenarios
          userAnswer {
            id
          }
          answers {
            id
            ...OnboardingAnswerButtonFragment
          }
        }
      }
    }
  }
  ${OnboardingAnswerButton.fragment}
`;

const SUBMIT_ANSWER_MUTATION = gql`
  mutation OnboardingAnswerSubmit($input: ViewerAnswerOnboardingQuestionInput!) {
    ViewerAnswerOnboardingQuestion(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Viewer {
        id
        onboarding {
          id
          questions {
            id
            userAnswer {
              id
            }
          }
        }
      }
    }
  }
`;

// == Component ============================================================

export function OnboardingQuestion({ id: idParam }: RouteComponentProps<IParams>) {
  const [selectedAnswerID, setSelectedAnswerID] = useState("");
  const { campaignId, isOwner } = useStoreAppValue();
  const { data, loading, error } = useQuery<OnboardingQuestionsQuery>(ONBOARDING_QUESTIONS_QUERY);
  const [submitAnswer] = useMutation<OnboardingAnswerSubmit, OnboardingAnswerSubmitVariables>(
    SUBMIT_ANSWER_MUTATION
  );

  const questions = data?.Viewer.onboarding?.questions || [];
  const activeQuestion = questions.find((question) => question.id === idParam);

  const trackClick = () => {
    analytics.track.onboardingQuestion(
      capitalize(idParam),
      {
        campaignId,
        analyticsLocation: `onboarding-question-${idParam}`,
      },
      isOwner
    );
  };

  const onAnswerPress = async (id: string) => {
    if (!idParam || !activeQuestion) return;

    setSelectedAnswerID(id);
    trackClick();

    try {
      const { data: mutationData } = await submitAnswer({
        variables: { input: { questionID: idParam, answerID: id } },
      });
      if (mutationData?.ViewerAnswerOnboardingQuestion.__typename === "MutationError") {
        throw new Error("mutation error");
      }
      const updatedQuestions =
        mutationData?.ViewerAnswerOnboardingQuestion.onboarding?.questions || [];
      const currentIndex = updatedQuestions.findIndex((question) => question.id === idParam);
      const nextQuestion = updatedQuestions[currentIndex + 1];
      if (nextQuestion) {
        setTimeout(() => {
          setSelectedAnswerID("");
          navigate(RailsUrl.onboardingQuestionUrl(nextQuestion.id));
        }, 500);
      } else if (updatedQuestions.every((question) => !!question.userAnswer)) {
        setTimeout(() => {
          setSelectedAnswerID("");
          navigate(RailsUrl.onboardingSuccessUrl);
        }, 500);
      }
    } catch (e) {
      ErrorService.error(e);
    }
  };

  const iconUrl = () => {
    switch (idParam) {
      case "apparel":
        return "https://flipgive.twic.pics/images/onboarding/home.png";
      case "gear":
        return "https://flipgive.twic.pics/images/onboarding/sports.png";
      case "travel":
        return "https://flipgive.twic.pics/images/onboarding/travel.png";
      case "dining":
        return "https://flipgive.twic.pics/images/onboarding/dine.png";
      case "groceries":
        return "https://flipgive.twic.pics/images/onboarding/gas.png";
      default:
        break;
    }
  };

  return (
    <OnboardingContainer hasError={!!error || !activeQuestion} loading={loading}>
      <Flex alignItems="center" flexDirection="column" justifyContent="space-between" width="100%">
        <Box textAlign="center">
          <Text color="em" fontSize={1} fontWeight="bold" lineHeight={1}>
            How often do you shop for
          </Text>
          <Image alt={idParam} m="0 auto" py={3} src={iconUrl()} width={1 / 2} />
        </Box>
        <Box pb={4} px={4} textAlign="center">
          <H1 pb={2}>{activeQuestion?.text}</H1>
          {activeQuestion?.scenarios && activeQuestion.scenarios.length > 0 && (
            <Box color="subText" fontSize="1" m="auto" textAlign="center">
              <Text color="subText" display="block" fontWeight="bold">
                Things like
              </Text>
              <Text>{activeQuestion.scenarios.join(" - ")}</Text>
            </Box>
          )}
        </Box>
        <Box textAlign="center">
          <Text color="em" fontSize="1">
            Select One{" "}
          </Text>
          <Box textAlign="center">
            <Flex flexWrap="wrap">
              {activeQuestion?.answers.map((answer) => (
                <Box key={answer.id} width={1 / 2}>
                  <OnboardingAnswerButton
                    answer={answer}
                    isSelected={selectedAnswerID === answer.id}
                    onPress={onAnswerPress}
                  />
                </Box>
              ))}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </OnboardingContainer>
  );
}

// == Styles ===============================================================
