import React from "react";
import { useToggle } from "@hooks";
import { Text, TouchableOpacity, Box } from "@atoms";

// == Types ================================================================
interface IProps {
  text: string;
  maxCharacter: number;
  color?: string;
  hrefColor?: string;
  renderHtml: boolean;
}
// == Constants ============================================================

ReadMore.defaultProps = {
  maxCharacter: 65,
  color: "default",
  hrefColor: "primary",
  renderHtml: true,
};

// == Functions ============================================================

// == Component ============================================================

export default function ReadMore({ renderHtml, text, maxCharacter, color, hrefColor }: IProps) {
  const [readMore, toggleReadMore] = useToggle(false);

  const renderText = (content: string) => {
    if (renderHtml) {
      return <Text color={color} dangerouslySetInnerHTML={{ __html: content }} />;
    }
    return <Text color={color}>{content}</Text>;
  };
  if (readMore) {
    return (
      <Box>
        {renderText(text)}
        <Text color={color}>
          {text.length > maxCharacter && (
            <TouchableOpacity onTouch={() => toggleReadMore(false)}>
              <Text color={hrefColor} pl={1}>
                {" "}
                less
              </Text>
            </TouchableOpacity>
          )}
        </Text>
      </Box>
    );
  }
  return (
    <Box>
      {text.length < maxCharacter ? (
        renderText(text.slice(0, maxCharacter))
      ) : (
        <>
          {renderText(text.slice(0, maxCharacter))}
          <Text color={color}>...</Text>
          <TouchableOpacity onTouch={() => toggleReadMore(true)}>
            <Text color={hrefColor} pl={1}>
              {" "}
              more
            </Text>
          </TouchableOpacity>
        </>
      )}
    </Box>
  );
}

// == Styles ===============================================================
