import React from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { Link } from "@reach/router";
import { TouchableOpacity, Box, FontAwesome, Text, H2 } from "@atoms";
import { renderIf, pluralize, RailsUrl } from "@services";
import { useStoreAppValue } from "@hooks";
import { BrandModalFavoriteButton } from "../BrandModalFavoriteButton";
import { BrandCircle } from "../../brandCircleComponents";
import { BrandLocationFragment } from "../BrandModal/__generated__/BrandLocationFragment";
import { BrandModalHeroFragment } from "./__generated__/BrandModalHeroFragment";
// == Types ================================================================

type TBrand = BrandModalHeroFragment;

interface IProps {
  brand: TBrand;
  onBackClick: () => void;
  location: BrandLocationFragment;
}

// == Constants ============================================================

// TODO id: $locationId (in location will be used when we have map page)

const FRAGMENTS = {
  fields: gql`
    fragment BrandModalHeroFragment on Brand {
      id
      name
      viewerHasFavorited
      heroImage
      locationsCount
      ...BrandCircleFragment
      linkedCardLink {
        id
      }
    }
    ${BrandCircle.fragments.fields}
  `,
};

const LOGO_SIZE = ["100px", "130px"];

// == Component ============================================================

const BrandModalHero = ({ brand, onBackClick, location }: IProps) => {
  const { locationsCount } = brand;
  const { campaignId } = useStoreAppValue();
  const renderLocationInfo = () => {
    if (!location) return null;
    return (
      <>
        <Text display="block" textAlign="center">
          {location.street1}
        </Text>
        <Text display="block" textAlign="center">
          {location.street2}
        </Text>
        <Text
          display="block"
          textAlign="center"
        >{`${location.city}, ${location.province} ${location.postalCode}`}</Text>
      </>
    );
  };

  return (
    <Box position="relative">
      <EArrowContainer>
        <TouchableOpacity onTouch={() => onBackClick()}>
          <Box bg="white" border="primary" borderRadius="100%" p={2}>
            <Text color="primary">
              <FontAwesome icon="arrow-left" />
            </Text>
          </Box>
        </TouchableOpacity>
      </EArrowContainer>
      {/* 80px for moving text below logo */}
      <Box mb={[5, "80px"]} position="relative">
        <EHeroImageBox
          backgroundColor={brand.heroImage !== null ? "none" : "primary"}
          height="175px"
          heroImage={brand.heroImage}
        />
        <BrandModalFavoriteButton
          brandId={brand.id}
          viewerHasFavorited={brand.viewerHasFavorited}
        />
        <EBox bottom="0" height={LOGO_SIZE} left="50%" position="absolute" width={LOGO_SIZE}>
          <BrandCircle brand={brand} />
        </EBox>
      </Box>
      <H2 mb={2} textAlign="center">
        {brand.name}
      </H2>
      {renderLocationInfo()}
      {renderIf(locationsCount > 0)(
        <Link
          to={RailsUrl.nearbyUrl({
            campaignId,
            brandId: brand.id,
            brandName: brand.name,
            allLocations: true,
          })}
        >
          <Text color="primary" display="block" textAlign="center">
            {locationsCount.toLocaleString()}{" "}
            {pluralize("Location", locationsCount, { showCount: false })}
          </Text>
        </Link>
      )}
    </Box>
  );
};

BrandModalHero.fragments = FRAGMENTS;
export default BrandModalHero;

// == Styles ===============================================================

const EArrowContainer = styled(Box)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
`;

const EBox = styled(Box)`
  transform: translate(-50%, 50%);
`;

const EHeroImageBox = styled(Box)`
  background-image: ${({ heroImage }) => (heroImage ? `url(${heroImage})` : "none")};
  background-size: cover;
  background-position: center top;
`;
