import styled from "@emotion/styled";
import { theme, themed } from "@styles";
import { Text, ITextProps } from "../Text";

// == Types ================================================================

export interface ILinkProps extends ITextProps {
  href: string;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  href: "#",
  as: "a",
  color: theme.colors.link,
  target: "",
};

// == Component ============================================================

export const Link = styled<typeof Text, ILinkProps>(Text)(
  `
  text-decoration: none;
`,
  themed("Link")
);

Link.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
