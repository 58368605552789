import React from "react";
// import styled from "@emotion/styled";
import * as StyledSystem from "styled-system";
// import { theme, themed, TThemeButtonVariants } from "@styles";
// import { placeholder } from "@babel/types";
import { Box, IBoxProps } from "../Box";

// == Types ================================================================

interface IInputKnownProps
  extends IBoxProps,
    StyledSystem.FontWeightProps,
    StyledSystem.BorderProps {}

export interface IInputProps
  extends IInputKnownProps,
    Omit<React.HTMLProps<HTMLInputElement>, keyof IInputKnownProps> {
  placeholder: string;
  value: string;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  as: "input" as const,
  placeholder: "placeholder",
};

// == Component ============================================================
export const Input = ({ placeholder, value, defaultValue, ...rest }: IInputProps) => {
  return (
    <Box
      as="input"
      data-testid="input-text"
      defaultValue={defaultValue}
      placeholder={placeholder}
      value={value}
      {...rest}
    />
  );
};

Input.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
