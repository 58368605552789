// copied from flipgive-mobile > utils > formatMoney.tsx - Jan 2020

import get from "lodash/get";

export default function formatMoney(num, mantissa = 2) {
  let value = parseFloat(num, 10);

  if (num !== 0 && !parseFloat(num)) {
    const dollarsAmount = get(num, "dollarsAmount");
    if (dollarsAmount) {
      value = dollarsAmount;
    } else {
      return "$0";
    }
  }
  let returnNum;
  if (mantissa === 0) {
    returnNum = value.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, "$1,");
  } else {
    returnNum = value.toFixed(mantissa).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }

  return `$${returnNum}`;
}
