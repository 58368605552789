import React from "react";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { constants } from "@services";
import { Box, Flex } from "@atoms";
import { FundsPageWithdrawalsChartBarGraphFragment } from "./__generated__/FundsPageWithdrawalsChartBarGraphFragment";

// == Types ================================================================
interface IProps {
  campaign: FundsPageWithdrawalsChartBarGraphFragment;
}

FundsPageWithdrawalsChartBarGraph.fragments = gql`
  fragment FundsPageWithdrawalsChartBarGraphFragment on Campaign {
    id
    name
    amountWithdrawnBreakdown @include(if: $isMember) {
      available {
        dollarsAmount
        centsAmount
        formatted
      }
      withdrawn {
        dollarsAmount
        centsAmount
        formatted
      }
      pending {
        dollarsAmount
        centsAmount
        formatted
      }
      fees {
        dollarsAmount
        centsAmount
        formatted
      }
    }
  }
`;

// == Constants ============================================================

// == Component ============================================================

export default function FundsPageWithdrawalsChartBarGraph({ campaign }: IProps) {
  const {
    amountWithdrawnBreakdown: { available, pending, withdrawn, fees },
  } = campaign;
  const {
    CHART_COLORS: {
      available: colorAvailable,
      withdrawn: colorWithdrawn,
      pending: colorPending,
      fees: colorFees,
    },
  } = constants;
  const centsAmountSum =
    available.centsAmount + pending.centsAmount + withdrawn.centsAmount + fees.centsAmount;
  const chartHeight = [12, 18, 24];

  return (
    <EFundsBarContainer
      borderRadius={chartHeight.map((height) => height / 2)}
      height={chartHeight}
      width="100%"
    >
      <Box
        bg={colorWithdrawn}
        height={chartHeight}
        width={`${(withdrawn.centsAmount / centsAmountSum) * 100}%`}
      />
      <Box
        bg={colorAvailable}
        height={chartHeight}
        width={`${(available.centsAmount / centsAmountSum) * 100}%`}
      />
      <Box
        bg={colorPending}
        height={chartHeight}
        width={`${(pending.centsAmount / centsAmountSum) * 100}%`}
      />
      <Box
        bg={colorFees}
        height={chartHeight}
        width={`${(fees.centsAmount / centsAmountSum) * 100}%`}
      />
    </EFundsBarContainer>
  );
}

// == Styles ===============================================================

const EFundsBarContainer = styled(Flex)`
  overflow: hidden;
`;
