import React from "react";
import { Box, Text, Flex, Button } from "@components/common/atoms";
// import styled from "@emotion/styled";
import { theme } from "@styles";
// == Types ================================================================

interface IProps {
  giftcardIntent: $FixMe;
  onPlaceOrder: () => void;
  isValid: boolean;
  submitting: boolean;
  errorMessage: string;
}

// == Constants ============================================================

CheckoutBuy.defaultProps = {};

// == Component ============================================================

export default function CheckoutBuy({
  onPlaceOrder,
  isValid,
  submitting,
  errorMessage,
  giftcardIntent,
}: IProps) {
  return (
    <Box
      bg="whitesmoke"
      borderTop={`1px solid ${theme.colors.components.card.formBorderColor}`}
      bottom={0}
      left={0}
      position="fixed"
      width="100%"
      zIndex={2}
    >
      <Flex alignItems="center" justifyContent="space-between" pt={3} px={[3, 4]}>
        <Box>
          <Text as="p">Order Total</Text>
          <Text as="p" color="black" fontSize={3}>
            {giftcardIntent.orderTotal.formatted}
          </Text>
          <Text as="p" color="primary" fontSize={1}>
            Cashback {giftcardIntent.giveAmount.formatted}
          </Text>
        </Box>
        <Box>
          <Button
            disabled={!isValid || submitting}
            variant={isValid || submitting ? "primary" : "disabled"}
            width="100%"
            onClick={onPlaceOrder}
          >
            {submitting ? "Ordering..." : "Place Order"}
          </Button>
        </Box>
      </Flex>
      {errorMessage && (
        <Box pb={3} px={4}>
          <Text color="danger" display="block" lineHeight="title" mt={1}>
            {errorMessage}
          </Text>
        </Box>
      )}
      {giftcardIntent.deliveryEstimate && (
        <Text display="block" textAlign="center" fontSize="10px" my={1} px={2}>
          {giftcardIntent.deliveryEstimate}
        </Text>
      )}
    </Box>
  );
}

// == Styles ===============================================================
