import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import { useLoadingDelay, useNearbyViewContext, useStoreAppValue } from "@hooks";
// import styled from "@emotion/styled";
// import { BrandListLinkTypeFilters } from "@pages/brandListComponents/BrandListLinkTypeFilters";
import { Text } from "@atoms";
import { NearbyListEmpty } from "../NearbyListEmpty";
import { NearbySearchResultLoading } from "../NearbySearchResultLoading";
import NearbySearchResult from "./NearbySearchResult";
import { getDefaultLat, getDefaultLong } from "@components/common/utils";

// == Types ================================================================

interface IProps {
  location: $FixMe;
  onResetForm: () => void;
}

// == Constants ============================================================

NearbySearchResultQuery.defaultProps = {};

const NEARBY_SEARCH_QUERY = gql`
  query NearbySearchQuery(
    $coordinates: CoordinatesInput!
    $department: MerchantDepartmentType
    $query: String
    $first: Int!
    $after: String
    $distance: Int
    $currency: Currency
    $brandId: ID
    $linkCategory: LinkCategory
  ) {
    LocationSearch(
      coordinates: $coordinates
      department: $department
      query: $query
      first: $first
      after: $after
      distance: $distance
      currency: $currency
      brandId: $brandId
      linkCategory: $linkCategory
    )
      @connection(
        key: "NearbySearchQuery"
        filter: [
          "currency"
          "query"
          "department"
          "distance"
          "linkCategory"
          "coordinates"
          "brandId"
        ]
      ) {
      edges {
        cursor
        node {
          id
          name
          address
          street1
          street2
          province
          postalCode
          city
          country
          latitude
          longitude
          sponsored
          hasLinkedCard
          brand {
            id
            ...NearbySearchResultFragment
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${NearbySearchResult.fragments.fields}
`;
const PAGINATION_SIZE = 30;
const MAX_PAGINATED_ITEMS = 100;

// == Component ============================================================

export default function NearbySearchResultQuery({ location, onResetForm }: IProps) {
  const loadingDelayReady = useLoadingDelay();
  const { currency } = useStoreAppValue();
  const [nearbyViewState, nearbyViewDispatch] = useNearbyViewContext();
  const {
    coordinates,
    query,
    department,
    linkCategoryFilter,
    distance,
    brandId,
    searching,
  } = nearbyViewState;
  const queryVariables = {
    coordinates: coordinates
      ? {
          latitude: coordinates?.latitude ? +coordinates.latitude : getDefaultLat(currency),
          longitude: coordinates?.longitude ? +coordinates.longitude : getDefaultLong(currency),
        }
      : null,
    query,
    first: 30,
    distance,
    department,
    linkCategory: linkCategoryFilter,
    currency,
    after: null,
    brandId,
  };
  const { loading, data, error, fetchMore, networkStatus } = useQuery(NEARBY_SEARCH_QUERY, {
    variables: queryVariables,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const results = data?.LocationSearch.edges;
      nearbyViewDispatch({ type: "UPDATE_RESULTS", results });
    },
    notifyOnNetworkStatusChange: true,
  });

  const queryParams = queryString.parse(location.search);
  const params = Object.keys(queryParams);
  const restrictBrand = () => {
    if (params.includes("b")) {
      const brandName = queryParams.brand as string;
      nearbyViewDispatch({
        type: "UPDATE_BRAND_ID_SEARCH",
        brandId: queryParams.b as string,
        query: brandName,
      });
    }
  };
  useEffect(() => {
    restrictBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (error) {
    return <Text>{error.message}</Text>;
  }

  // TODO: add loading for this component
  if ((isEmpty(data) && loading) || !loadingDelayReady) return <NearbySearchResultLoading />;
  if ((networkStatus !== 3 && networkStatus !== 7) || searching) {
    return <NearbySearchResultLoading />;
  }
  const searchResults = data?.LocationSearch.edges;
  if (!searchResults || searchResults.length === 0)
    return <NearbyListEmpty onResetForm={onResetForm} />;

  const onFetchMore = () => {
    const { endCursor } = data?.LocationSearch.pageInfo;
    fetchMore({
      query: NEARBY_SEARCH_QUERY,
      variables: {
        ...queryVariables,
        after: endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const {
          endCursor: previousResultEndCursor,
          hasNextPage,
        } = previousResult?.LocationSearch?.pageInfo;
        if (!hasNextPage || !fetchMoreResult) {
          return previousResult;
        }

        const newItems = fetchMoreResult?.LocationSearch.edges;
        const pageInfo = fetchMoreResult?.LocationSearch.pageInfo;
        const { endCursor: newResultEndCursor } = pageInfo;

        if (newResultEndCursor === previousResultEndCursor) return previousResult;

        return {
          LocationSearch: {
            cursor: newResultEndCursor,
            edges: [...previousResult.LocationSearch.edges, ...newItems],
            __typename: previousResult.LocationSearch.__typename,
            pageInfo,
          },
        };
      },
    });
  };
  const hasNextPage = data?.LocationSearch.pageInfo.hasNextPage;

  return (
    <NearbySearchResult
      hasNextPage={hasNextPage}
      loadingMore={networkStatus === 3}
      searchResults={searchResults}
      onFetchMore={onFetchMore}
      onResetForm={onResetForm}
    />
  );
}

// == Styles ===============================================================
