import React from "react";
import { Breakpoint } from "react-socks";
import styled from "@emotion/styled";
import { BrandBlock } from "@organisms/brandBlockComponents";
import { Flex, Column, Box, Heading } from "@atoms";
import { MultiItemCarousel, Spinner } from "@molecules";
import useSportCategoryBrands from "./useSportCategoryBrands";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export default function CollectionSportsCategory({ collectionId = "", storefrontId = "" }) {
  const { brands, hasError, isLoading, categoryName } = useSportCategoryBrands();

  if (isLoading)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );

  if (hasError) return null;

  const renderBrandRow = () => {
    return (
      <EFlex mx={[0, 0, -3]}>
        {brands.slice(0, 6).map((brand) => (
          <Column autoColumn={false} key={brand.id} width={[0.45, 1 / 3, 1 / 4, 1 / 5]}>
            <BrandBlock
              collectionId={collectionId}
              storefrontId={storefrontId}
              brand={brand}
              isImageCircle
              isOneLocation
            />
          </Column>
        ))}
      </EFlex>
    );
  };

  return (
    <Box position="relative">
      <Box maxWidth="55%" mb={2}>
        <Heading ellipsis fontSize={[2, 3]}>
          {categoryName} Picks
        </Heading>
      </Box>
      <Breakpoint xsmall>{renderBrandRow()}</Breakpoint>
      <Breakpoint small up>
        {brands.length < 6 ? (
          renderBrandRow()
        ) : (
          <Box px={[0, 4, 4, 0]}>
            <MultiItemCarousel itemLength={brands.length}>
              {brands.map((brand) => (
                <Box alignSelf="flex-start" key={brand.id} px={[1]} width="80%">
                  <BrandBlock brand={brand} isImageCircle />
                </Box>
              ))}
            </MultiItemCarousel>
          </Box>
        )}
      </Breakpoint>
    </Box>
  );
}

// == Styles ===============================================================
const EFlex = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`;
