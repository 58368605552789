import React from "react";
import { FontAwesome } from "../../FontAwesome";
import { IFlexProps } from "../../Flex";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  containerProps?: IFlexProps;
}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

const ChevronCircleRight = ({ containerProps, ...rest }: IProps) => {
  return (
    <FontAwesome
      containerProps={containerProps}
      data-testid="chevron-circle-right"
      icon="chevron-circle-right"
      {...rest}
    />
  );
};

ChevronCircleRight.defaultProps = DEFAULT_PROPS;

export default ChevronCircleRight;
// == Styles ===============================================================
