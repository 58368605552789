import React from "react";
import { ApolloProvider } from "react-apollo";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";
import Notifications from "./components/Notifications";
import { NotificationsProvider } from "./NotificationsContext";
import { useFullStory } from "@hooks";

export default (props) => {
  Settings.setDefaultsFromProps(props);
  useFullStory(props.currentUser);
  const client = apolloClient(Settings.apiUrl, {
    cacheKey: `apollo-notifications-cache-${props.currentUser.id}`,
  });

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <NotificationsProvider>
          <Notifications appUrl={props.appUrl} />
        </NotificationsProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
