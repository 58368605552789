import React, { ComponentProps, Dispatch, SetStateAction, useMemo } from "react";
import styled from "@emotion/styled";
import { LinkCategory, MerchantDepartmentType } from "@types";
import { Box, IBoxProps, ITextProps, Text } from "@atoms";
import { DropDown } from "@molecules";
import { theme } from "@styles";
import { TOrderByType } from "@components/common/organisms/searchModalComponents/SearchModal/types";
import { FullStoryEventNames } from "@services";
import { useFullStoryEvents } from "@hooks";

// == Types ================================================================

interface IProps extends IBoxProps {
  linkCategoryFilter: LinkCategory | null;
  departmentFilter: MerchantDepartmentType | null;
  orderByFilter: TOrderByType;
  nearbySearch?: boolean;
  showRegister?: boolean;
  onCategoryChange: ({ linkCategoryFilter }: { linkCategoryFilter: LinkCategory | null }) => void;
  onDepartmentChange: (departmentFilter: MerchantDepartmentType | null) => void;
  onOrderByChange: Dispatch<SetStateAction<TOrderByType>>;
}

// == Constants ============================================================

NewBrandListLinkTypeFilters.defaultProps = {
  nearbySearch: false,
  showRegister: true,
};

const linkCategoriesData: ComponentProps<typeof DropDown>["items"] = [
  { label: "All", slug: "" },
  { label: "Gift Cards", slug: LinkCategory.GIFT_CARDS },
  { label: "Voucher", slug: LinkCategory.REGISTER },
  { label: "Online", slug: LinkCategory.SHOP_ONLINE },
];

const departmentsData: ComponentProps<typeof DropDown>["items"] = [
  { label: "All", slug: "" },
  { label: "Fashion & Beauty", slug: MerchantDepartmentType.FASHION_BEAUTY },
  { label: "Food & Groceries", slug: MerchantDepartmentType.FOOD_GROCERIES },
  { label: "Gadgets & Electronics", slug: MerchantDepartmentType.GADGETS_ELECTRONICS },
  { label: "General Retail", slug: MerchantDepartmentType.GENERAL_RETAIL },
  { label: "Gifts & Specialty", slug: MerchantDepartmentType.GIFTS_SPECIALTY },
  { label: "Home & Office", slug: MerchantDepartmentType.HOME_OFFICE },
  { label: "Local", slug: MerchantDepartmentType.LOCAL },
  { label: "Restaurant & Dining", slug: MerchantDepartmentType.RESTAURANT_DINING },
  { label: "Services & Utilities", slug: MerchantDepartmentType.SERVICES_UTILITIES },
  { label: "Sports & Outdoor", slug: MerchantDepartmentType.SPORTS_OUTDOOR },
  { label: "Travel & Entertainment", slug: MerchantDepartmentType.TRAVEL_ENTERTAINMENT },
];

const filterButtonsData = [
  {
    label: "Name",
    slug: TOrderByType.name,
  },
  {
    label: "Give %",
    slug: TOrderByType.max_give,
  },
  {
    label: "Popular",
    slug: TOrderByType.popularity,
  },
];

const labelStyle: ITextProps = {
  color: "primary",
  fontSize: 1,
  mr: 2,
  ml: 2,
};

// == Component ============================================================

export default function NewBrandListLinkTypeFilters({
  nearbySearch,
  linkCategoryFilter,
  departmentFilter,
  showRegister,
  onDepartmentChange,
  onCategoryChange,
  onOrderByChange,
  orderByFilter,
  ...rest
}: IProps) {
  const selectedCategoryLabel = useMemo(
    () =>
      linkCategoriesData.find((category) => category.slug === linkCategoryFilter)?.label ?? "Type",
    [linkCategoryFilter, linkCategoriesData]
  );

  const selectedDepartmentLabel = useMemo(() => {
    return departmentsData.find((dept) => dept.slug === departmentFilter)?.label ?? "Category";
  }, [departmentFilter, departmentsData]);

  const getOrderEventType = useMemo(() => {
    if (orderByFilter === TOrderByType.max_give) return "give_%";
    if (orderByFilter === TOrderByType.name) return "name";
    if (orderByFilter === TOrderByType.popularity) return "popular";
    return "none";
  }, [orderByFilter]);

  const { sendFullStoryEvent } = useFullStoryEvents();

  const renderOrderByButtons = useMemo(() => {
    return filterButtonsData.map(({ slug, label }) => {
      const isActive = slug === orderByFilter;

      return (
        <span key={label} style={{ cursor: "pointer", fontWeight: isActive ? "bold" : "normal" }}>
          <Text
            {...labelStyle}
            onClick={() => {
              onOrderByChange(slug as TOrderByType);
              sendFullStoryEvent(FullStoryEventNames.searchSorted, {
                type: getOrderEventType,
              });
            }}
          >
            {label}
          </Text>
        </span>
      );
    });
  }, [orderByFilter, onOrderByChange]);

  return (
    <EContainer my={4} {...rest}>
      <div>
        <Text {...labelStyle} color="black">
          Sort By:
        </Text>
        {renderOrderByButtons}
      </div>
      <div>
        <DropDown
          chevronColor={theme.colors.gray}
          items={linkCategoriesData}
          label={selectedCategoryLabel}
          onSelect={(slug) => {
            onCategoryChange({ linkCategoryFilter: slug ? (slug as LinkCategory) : null });
            sendFullStoryEvent(FullStoryEventNames.searchFiltered, {
              filter: "type",
              transactionType: !!slug ? slug.toLocaleLowerCase() : "none",
            });
          }}
          labelProps={labelStyle}
        />
        <DropDown
          chevronColor={theme.colors.gray}
          items={departmentsData}
          label={selectedDepartmentLabel}
          onSelect={(slug) => {
            onDepartmentChange(slug ? (slug as MerchantDepartmentType) : null);
            sendFullStoryEvent(FullStoryEventNames.searchFiltered, {
              filter: "category",
              transactionType: !!slug ? slug.toLocaleLowerCase() : "none",
            });
          }}
          labelProps={labelStyle}
        />
      </div>
    </EContainer>
  );
}

// == Styles ===============================================================

const EContainer = styled(Box)`
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    white-space: normal;
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
  }
`;
