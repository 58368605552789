import React from "react";
import { useMutation } from "react-apollo";
import {
  FAVORITE_BRANDS_QUERY,
  TOGGLE_FAVORITE_BRAND_MUTATION,
  ToggleFavoriteBrandMutation,
  ToggleFavoriteBrandMutationVariables,
  FavoriteBrands as FavoriteBrandsQuery,
} from "@queries";
import { ClearButton } from "@atoms";

// == Types ================================================================

interface IProps {
  brandId: string;
}

// == Constants ============================================================

// == Component ============================================================

const FavoriteModalListRemoveButton = ({ brandId }: IProps) => {
  const [RemoveFavoriteBrand] = useMutation<
    ToggleFavoriteBrandMutation,
    ToggleFavoriteBrandMutationVariables
  >(TOGGLE_FAVORITE_BRAND_MUTATION);

  const onRemoveFavoriteBrands = () => {
    RemoveFavoriteBrand({
      variables: { brandId },
      update(cache) {
        const data = cache.readQuery<FavoriteBrandsQuery>({
          query: FAVORITE_BRANDS_QUERY,
        })!;
        const newData = {
          Viewer: {
            ...data.Viewer,
            favoriteBrandsCount: data.Viewer.favoriteBrandsCount! - 1,
            favoriteBrands: data.Viewer.favoriteBrands.filter(
              (brand) => brand.id !== brandId && brand.viewerHasFavorited
            ),
          },
        };

        cache.writeQuery({
          id: data.Viewer.id,
          query: FAVORITE_BRANDS_QUERY,
          data: newData,
        });
      },
    });
  };
  return (
    <ClearButton
      alignSelf="center"
      data-testid="loading-container"
      onClick={onRemoveFavoriteBrands}
    >
      Remove
    </ClearButton>
  );
};

// FavoriteModalListRemoveButton.fragments = FRAGMENTS

export default FavoriteModalListRemoveButton;

// == Styles ===============================================================
