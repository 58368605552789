import React from "react";
import styled, { CSSObject, Interpolation } from "@emotion/styled";
import * as StyledSystem from "styled-system";
import { TextAlignProperty, PositionProperty } from "csstype";
import { theme } from "@styles";

// == Types ================================================================

interface IBoxKnownProps
  extends $EmotionBaseProps,
    StyledSystem.AlignSelfProps,
    StyledSystem.BackgroundColorProps,
    StyledSystem.FlexProps,
    StyledSystem.FontSizeProps,
    StyledSystem.HeightProps,
    StyledSystem.MinHeightProps,
    StyledSystem.MaxWidthProps,
    StyledSystem.MinWidthProps,
    StyledSystem.OrderProps,
    StyledSystem.SpaceProps,
    StyledSystem.WidthProps,
    // NEW
    StyledSystem.BackgroundImageProps,
    StyledSystem.BackgroundPositionProps,
    StyledSystem.BackgroundProps,
    StyledSystem.BackgroundRepeatProps,
    StyledSystem.BackgroundSizeProps,
    StyledSystem.BorderBottomProps,
    StyledSystem.BorderColorProps,
    StyledSystem.BorderRadiusProps,
    StyledSystem.BordersProps,
    StyledSystem.BottomProps,
    StyledSystem.BoxShadowProps,
    StyledSystem.ColorProps,
    StyledSystem.DisplayProps,
    StyledSystem.LeftProps,
    StyledSystem.LineHeightProps,
    StyledSystem.OpacityProps,
    StyledSystem.PositionProps,
    StyledSystem.RightProps,
    StyledSystem.TopProps,
    StyledSystem.TextAlignProps,
    StyledSystem.ZIndexProps {}

export interface IBoxProps
  extends IBoxKnownProps,
    Omit<React.HTMLProps<HTMLDivElement>, keyof IBoxKnownProps> {}

// == Constants ============================================================

const DEFAULT_PROPS = {
  textAlign: "left" as TextAlignProperty,
  style: {},
  borderRadius: 0,
  position: "static" as PositionProperty,
};

// == Component ============================================================

export const Box = styled<"div", IBoxProps>("div")(
  {
    boxSizing: "border-box",
    fontFamily: theme.fonts.main,
  },
  StyledSystem.alignSelf,
  StyledSystem.backgroundColor,
  StyledSystem.flex,
  StyledSystem.fontSize,
  StyledSystem.height,
  StyledSystem.maxHeight,
  StyledSystem.minHeight,
  StyledSystem.maxWidth,
  StyledSystem.minWidth,
  StyledSystem.order,
  StyledSystem.space,
  StyledSystem.width,
  // NEW
  StyledSystem.background,
  StyledSystem.backgroundImage,
  StyledSystem.backgroundSize,
  StyledSystem.backgroundPosition,
  StyledSystem.backgroundRepeat,
  StyledSystem.borderBottom,
  StyledSystem.borderColor,
  StyledSystem.borderRadius,
  StyledSystem.borders,
  StyledSystem.bottom,
  StyledSystem.boxShadow,
  StyledSystem.color,
  StyledSystem.display,
  StyledSystem.left,
  StyledSystem.lineHeight,
  StyledSystem.opacity,
  StyledSystem.position,
  StyledSystem.right,
  StyledSystem.textAlign,
  StyledSystem.top,
  StyledSystem.padding,
  StyledSystem.zIndex
);

Box.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
