import React, { useReducer, Reducer, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import produce from "immer";
import { useFullStoryEvents, useScrollTopOnMount } from "@hooks";
import { Container } from "@atoms";
import { BrandListContentQuery } from "../BrandListContent";
import { BrandListLetters } from "../BrandListLetters";
import { BrandListFeaturedBrands } from "../BrandListFeaturedBrands";
import { BrandListLinkTypeFilters } from "../BrandListLinkTypeFilters";
import { TReducerActions, TReducerState } from "../types";
import { useCookies } from "react-cookie";
import { CustomStorefrontsIds } from "@components/common/utils/customStorefrontsIds";
import { FullStoryEventNames } from "@services";

// == Types ================================================================

// == Constants ============================================================

const FILTERS_REDUCER: Reducer<TReducerState, TReducerActions> = (state, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_LETTER":
        draft.filterType = "LETTER";
        draft.activeFilter = action.filter;
        break;
      case "SET_NAMED_FILTER":
        draft.filterType = "NAMED_SORT";
        draft.activeFilter = action.filter;
        break;
      case "SET_TYPE_FILTER":
        draft.linkCategoryFilter = action.linkCategoryFilter;
        break;
      default:
        break;
    }
  });
};

const INITIAL_STATE: TReducerState = {
  filterType: "NAMED_SORT",
  activeFilter: "POPULARITY",
  linkCategoryFilter: null,
};

// == Component ============================================================

export default function BrandListView(_props: RouteComponentProps) {
  useScrollTopOnMount();
  const [state, dispatch] = useReducer(FILTERS_REDUCER, INITIAL_STATE);
  const { filterType, activeFilter, linkCategoryFilter } = state;
  const [_cookies, setCookie] = useCookies();

  const { sendFullStoryEvent } = useFullStoryEvents();

  useEffect(() => {
    setCookie("storefront_id", CustomStorefrontsIds.allBrands);
    sendFullStoryEvent(FullStoryEventNames.storefrontViewed, {
      storefront_name: "all_brands",
    });
  }, []);

  return (
    <Container>
      <BrandListFeaturedBrands storefrontId={CustomStorefrontsIds.allBrands} />
      <BrandListLetters activeFilter={activeFilter} dispatch={dispatch} />
      <BrandListLinkTypeFilters dispatch={dispatch} linkCategoryFilter={linkCategoryFilter} />
      <BrandListContentQuery
        activeFilter={activeFilter}
        filterType={filterType}
        linkCategoryFilter={linkCategoryFilter}
      />
    </Container>
  );
}

// == Styles ===============================================================
