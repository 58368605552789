import React from "react";
import { navigate } from "@reach/router";
import { Breakpoint } from "react-socks";
import styled from "@emotion/styled";
import { RailsUrl } from "@services";
import { Box, Text, Flex, ChevronDown, Container } from "@atoms";
import { OpenSearchBar } from "../OpenSearchBar";
import { SearchBarFavorites } from "../SearchBarFavorites";
import { useCookies } from "react-cookie";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// == Component ============================================================

const SearchBar = () => {
  const [cookies] = useCookies();
  const onOpenSearchModal = () => {
    const url = RailsUrl.searchUrl();
    navigate(`${url}&storefrontId=${cookies.storefront_id}`);
  };

  return (
    <Box>
      <Breakpoint xsmall>
        <Box mx={2} my={3} position="relative">
          <OpenSearchBar onTouch={onOpenSearchModal} />
          <EMobileSideLink
            className="department-side-nav-link--js"
            data-activates="departments-slide-out"
            href="#"
          >
            <Text color="primary" mr={1}>
              Browse
            </Text>
          </EMobileSideLink>
        </Box>
      </Breakpoint>
      <Breakpoint small up>
        <Container>
          <Flex alignItems="center" py={3}>
            <ESideLink
              className="department-side-nav-link--js"
              data-activates="departments-slide-out"
              href="#"
            >
              <Text color="primary" mr={1}>
                Browse
              </Text>
              <Text color="primary">
                <ChevronDown />
              </Text>
            </ESideLink>
            <OpenSearchBar onTouch={onOpenSearchModal} />
            <SearchBarFavorites />
          </Flex>
        </Container>
      </Breakpoint>
    </Box>
  );
};

export default SearchBar;

// == Styles ===============================================================

const ESideLink = styled.a`
  color: ${({ theme }) => theme.colors.default};
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${({ theme }) => theme.space[3]}px;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const EMobileSideLink = styled(ESideLink)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 ${({ theme }) => theme.space[2]}px;
  border-left: 1px solid ${({ theme }) => theme.colors.gray};
`;
