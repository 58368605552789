import React from "react";
import gql from "graphql-tag";
import { VictoryPie } from "victory";
import { Box, Text, Flex } from "@atoms";
import { constants } from "@services";
import {
  FundsPageEarningsChartFragment,
  FundsPageEarningsChartFragment_goal,
} from "./__generated__/FundsPageEarningsChartFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPageEarningsChartFragment;
}

// == Constants ============================================================

FundsPageEarningsChart.fragments = gql`
  fragment FundsPageEarningsChartFragment on Campaign {
    id
    goal {
      dollarsAmount
      formatted
    }
    amountRaised {
      dollarsAmount
      formatted
    }
    amountRaisedBreakdown {
      bonuses {
        dollarsAmount
      }
      shopping {
        dollarsAmount
        formatted
      }
      donations {
        dollarsAmount
      }
      team {
        dollarsAmount
      }
      transfers {
        dollarsAmount
      }
    }
  }
`;

// == Functions ============================================================

const goalFormattedStringMaker = (goal: FundsPageEarningsChartFragment_goal) => {
  return goal?.formatted.length > 10 ? `of ${goal?.formatted}` : `of ${goal?.formatted} goal`;
};

// == Component ============================================================

export default function FundsPageEarningsChart({ campaign }: IProps) {
  const {
    amountRaisedBreakdown: { bonuses, shopping, donations, team, transfers },
    amountRaised,
    goal,
  } = campaign;

  const goalRemaining = goal?.dollarsAmount ? goal.dollarsAmount - amountRaised.dollarsAmount : "";

  const chartData = [
    { y: shopping.dollarsAmount },
    { y: donations.dollarsAmount },
    { y: bonuses.dollarsAmount },
    { y: team.dollarsAmount },
    { y: transfers.dollarsAmount },
    { y: goalRemaining },
  ];

  const {
    CHART_COLORS: {
      shopping: colorShopping,
      donations: colorDonations,
      bonuses: colorBonuses,
      team: colorTeam,
      transfers: colorTransfers,
      remaining: colorRemaining,
    },
  } = constants;

  const graphColors = [
    colorShopping,
    colorDonations,
    colorBonuses,
    colorTeam,
    colorTransfers,
    colorRemaining,
  ];

  return (
    <Flex alignItems="center" bg="lightBlueBg" flexDirection="column" maxWidth="60%">
      <Flex alignItems="center" flexDirection="column" justifyContent="center" width={1}>
        <VictoryPie
          colorScale={graphColors}
          data={chartData}
          innerRadius={130}
          labels={() => ""}
          radius={170}
        />
        <Box maxWidth="20%" position="absolute" textAlign="center">
          <Box textAlign="center">
            <Text fontSize={amountRaised.formatted.length > 9 ? [1, 3, 5] : [2, 4, 5]}>
              {amountRaised.formatted}
            </Text>
          </Box>
          <Box textAlign="center">
            <Text color="primary" fontSize={[0, 1, 2]}>
              Earned {campaign?.goal?.formatted && ` of ${campaign.goal.formatted} goal`}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

// == Styles ===============================================================
