import React from "react";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { useStoreAppValue } from "@hooks";
import { getPaginatedNodes } from "@utils";
import { constants, RailsUrl } from "@services";
import { Text, Box } from "@atoms";
import { LinkType } from "@types";
import { BrandModalLinkListItem } from "../BrandModalLinkListItem";
import {
  BrandModalSimpleLinksFragment,
  BrandModalSimpleLinksFragment_links_edges_node,
} from "./__generated__/BrandModalSimpleLinksFragment";
import { useCookies } from "react-cookie";
import queryString from "query-string";

// == Types ================================================================

interface IProps {
  brand: BrandModalSimpleLinksFragment;
  tinyLinkKey?: string;
  locationId?: string;
}

type TLink = BrandModalSimpleLinksFragment_links_edges_node;
interface ILinksGroup {
  sectionTitle: string;
  links: TLink[];
}

// == Constants ============================================================

BrandModalSimpleLinks.defaultProps = {};
BrandModalSimpleLinks.fragment = gql`
  fragment BrandModalSimpleLinksFragment on Brand {
    id
    name
    links(first: 50, linkTypes: $linkTypes, locationID: $locationId) {
      edges {
        cursor
        node {
          id
          name
          type
          giftcardType
          giveSentence
          labelPromotionId
          commissionPromotionId
          previewInfo {
            icon
            description
            sectionName
            promotionsCount
          }
        }
      }
    }
  }
`;

const GROUP_REDUCER = (finalLinks: ILinksGroup[], link: TLink) => {
  // create the first array element
  if (finalLinks.length === 0) {
    finalLinks.push({ sectionTitle: link.previewInfo.sectionName, links: [link] });
    return finalLinks;
  }

  if (finalLinks[finalLinks.length - 1].sectionTitle !== link.previewInfo.sectionName) {
    // new section
    finalLinks.push({ sectionTitle: link.previewInfo.sectionName, links: [link] });
  } else {
    // add link to existing section
    // tbh gotta test if this works
    finalLinks[finalLinks.length - 1].links.push(link);
  }
  return finalLinks;
};

const GIFTCARD_DESCRIPTIONS = (link: TLink) => {
  const { giftcardType } = link;
  switch (giftcardType) {
    case "Voucher":
      return "Get a one-time use voucher to instantly redeem for a product or service. See terms and conditions for details.";
    case "":
      return "Receive digital cards instantly to redeem online or in-store.";
    default:
      return "Receive digital gift cards instantly to spend online or in-store.";
  }
};

const LINK_DESCRIPTIONS = {
  BRAND: "Shop online directly at the retailers website on your phone or desktop.",
  AUTORELOADGIFTCARD:
    "Receive and customize reloadable gift cards instantly to spend online or in-store.",
  EMPYR: "Activate this offer and use a linked payment card to purchase in-store.",
};

const LINK_DESCRIPTION = (link: TLink, locationId?: string) => {
  // overwrite default api functionality for this hardcoded description
  if (link.type === LinkType.EMPYR && !locationId) return constants.NEARBY_LINKED_CARD_DESCRIPTION;
  if (link.previewInfo.description) return link.previewInfo.description;
  if (link.type === LinkType.GIFTCARDOFFER) return GIFTCARD_DESCRIPTIONS(link);
  if (Object.keys(LINK_DESCRIPTIONS).includes(link.type)) return LINK_DESCRIPTIONS[link.type];
  return "";
};

const LINK_LEFT_IMAGE = (link: TLink) => {
  if (link?.previewInfo?.icon) return link.previewInfo.icon;
  if (link.type === LinkType.BRAND)
    return "https://flipgive.twic.pics/images/site/shop/brand-modal-shop.png";
  if (link.type === LinkType.EMPYR)
    return "https://flipgive.twic.pics/images/site/shop/brand-modal-in-store.png";
  if (link.type === LinkType.GIFTCARDOFFER || link.type === LinkType.AUTORELOADGIFTCARD)
    return "https://flipgive.twic.pics/images/site/shop/brand-modal-giftcard-v2.png";
  return "https://flipgive.twic.pics/images/site/shop/brand-modal-shop.png";
};

// == Component ============================================================

export function BrandModalSimpleLinks({ brand, tinyLinkKey, locationId: locationIdProp }: IProps) {
  const { campaignId } = useStoreAppValue();
  const links = getPaginatedNodes(brand.links.edges);
  const groupedLinks = links.reduce(GROUP_REDUCER, []);

  const [cookies] = useCookies();

  const navigateToLink = (linkId: string, locationId?: string, link?: TLink) => {
    const { labelPromotionId, commissionPromotionId } = link ?? {};
    const { storefrontId } = queryString.parse(location.search);

    const brandRoute = `?${constants.brandParam}=${brand.id}`;

    const searchParams = new URL(document.location.href).searchParams;
    searchParams.set(constants.linkParam, linkId);

    if (labelPromotionId) searchParams.set(constants.labelPromotionParam, labelPromotionId);
    if (commissionPromotionId)
      searchParams.set(constants.commisionPromotionParam, commissionPromotionId);
    if (cookies.storefront_id) searchParams.set(constants.storefrontParam, cookies.storefront_id);
    if (storefrontId) searchParams.set(constants.storefrontParam, storefrontId);

    let navigationRoute = `?${searchParams.toString()}`;

    if (locationId) {
      navigationRoute += `&location_id=${locationId}`;
      navigate(navigationRoute, {
        state: { brandRoute, tinyLinkKey, brandId: brand.id, canReturn: true },
      });
    }
    navigate(navigationRoute, {
      state: { brandRoute, tinyLinkKey, brandId: brand.id, canReturn: true },
    });
  };

  const navigateToLinkedCardLink = (linkId: string, locationId?: string, link?: TLink) => {
    if (locationIdProp) {
      navigateToLink(linkId, locationId, link);
    } else {
      const route = RailsUrl.nearbyUrl({
        campaignId,
        brandId: brand.id,
        brandName: brand.name,
        allLocations: true,
      });
      navigate(route);
    }
  };

  return (
    <>
      {groupedLinks.map((linkGroup) => {
        return (
          <Box key={linkGroup.sectionTitle}>
            <Box bg="lightBg" p={3}>
              <Text fontWeight="bold">{linkGroup.sectionTitle}</Text>
            </Box>
            {linkGroup.links.map((link: TLink, linkIndex: number) => {
              const merchantName =
                link.type === LinkType.GIFTCARDOFFER
                  ? brand?.giftcardLink?.merchant?.name
                  : brand?.shopLink?.merchant?.name;
              const description = LINK_DESCRIPTION(link, locationIdProp);
              const image = LINK_LEFT_IMAGE(link);

              if (link.type === LinkType.EMPYR) {
                return (
                  <BrandModalLinkListItem
                    description={description}
                    giveSentence={link.giveSentence}
                    key={link.id}
                    leftImage={image}
                    linkId={link.id}
                    promotionsCount={link.previewInfo.promotionsCount}
                    showBorder={linkIndex > 0}
                    title={link.name}
                    onClick={(linkId, locationId) =>
                      navigateToLinkedCardLink(linkId, locationId, link)
                    }
                  />
                );
              }
              return (
                <BrandModalLinkListItem
                  description={description}
                  giveSentence={link.giveSentence}
                  key={link.id}
                  leftImage={image}
                  linkId={link.id}
                  promotionsCount={link.previewInfo.promotionsCount}
                  showBorder={linkIndex > 0}
                  title={link.name}
                  onClick={(linkId, locationId) => navigateToLink(linkId, locationId, link)}
                  intentType={link.type === LinkType.GIFTCARDOFFER ? "giftcard" : "shop"}
                  merchantName={merchantName}
                  brandName={brand.name}
                />
              );
            })}
          </Box>
        );
      })}
    </>
  );
}
