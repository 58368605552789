import React from "react";
import { string, shape } from "prop-types";
import { format } from "date-fns";

export default class UserTransferListItem extends React.Component {
  static propTypes = {
    payment: shape(
      {
        id: string.isRequired,
        currency: string.isRequired,
        processedAt: string.isRequired,
        reference: string,
        amount: shape({
          formatted: string.isRequired,
        }).isRequired,
      }.isRequired,
    ),
  }.isRequired;

  constructor(props) {
    super(props);
    this.state = {
      showPayment: false,
    };
  }
  showPayment = (e) => {
    e.preventDefault();
    const { showPayment } = this.state;
    this.setState({ showPayment: !showPayment });
  };
  render() {
    const { showPayment } = this.state;
    const { payment } = this.props;
    const { processedAt } = payment;
    const formattedDate = format(new Date(processedAt), "MMMM do, yyyy");
    return (
      <div role="button" tabIndex={0} onClick={this.showPayment} onKeyPress={this.showPayment}>
        <div className={`user-transfer-table__row ${showPayment ? " is-open" : ""}`}>
          <div className="user-transfer-table__date-col">{formattedDate}</div>
          <div className="user-transfer-table__amount-col">
            {payment.amount.formatted} {payment.currency}
          </div>
          <div className="user-transfer-table__expand-col">
            <i className="fa fa-chevron-down user-transfer-table__arrow" />
          </div>
        </div>
        {showPayment && payment.reference ? <small>{payment.reference}</small> : null}
      </div>
    );
  }
}
