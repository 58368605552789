import React, { useEffect } from "react";
import { useField } from "formik";
import styled from "@emotion/styled";
import { Box } from "@atoms";

// == Types ================================================================

interface IProps {
  label?: string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  customErrorText?: boolean;
  placeholder?: string;
  type?: string;
  errorMessage?: string | null;
  onChange?: (e) => void;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  disabled: false,
  errorMessage: "",
  onChange: undefined,
  customErrorText: false,
};

// == Component ============================================================
TextField.defaultProps = DEFAULT_PROPS;

export default function TextField({
  errorMessage,
  onChange,
  customErrorText,
  type,
  ...rest
}: IProps) {
  // maybe change customErrorText to a children component, maybe
  useEffect(() => {
    Materialize.updateTextFields();
  }, []);
  const { label, disabled } = rest;
  const [field, meta] = useField(rest);

  const onTextFieldChange = (e) => {
    const { onChange: onFieldChange } = field;
    onFieldChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Box display={type === "hidden" ? "none" : "block"}>
      {label && (
        <ELabel className="active" htmlFor={field.name}>
          {label}
        </ELabel>
      )}
      <div className={`input-field ${disabled ? "disabled" : ""}`}>
        <EInput
          disabled={disabled}
          name={field.name}
          {...field}
          onChange={onTextFieldChange}
          {...rest}
        />
      </div>
      {((meta.touched && meta.error) || errorMessage) &&
        (customErrorText ? (
          <ESpan className="standalone-error">{errorMessage || meta.error}</ESpan>
        ) : (
          <span className="standalone-error">{errorMessage || meta.error}</span>
        ))}
    </Box>
  );
}

// == Styles ===============================================================

const ESpan = styled.span`
  margin: 0 0 5px !important;
  display: block;
  line-height: 1.25;
`;

const EInput = styled.input`
  height: 40px !important;
  padding-top: 0 !important;
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;
