import React from "react";
import gql from "graphql-tag";
import produce from "immer";
import { getPaginatedNodes } from "@components/common/utils";
import { Flex, Box, Text } from "@atoms";
import { FundsPagePaymentListItem } from "../FundsPagePaymentListItem";
import { FundsPagePaymentListFragment_payment } from "./__generated__/FundsPagePaymentListFragment";

// == Types ================================================================

interface IProps {
  paymentList: FundsPagePaymentListFragment_payment;
}

// == Constants ============================================================

FundsPagePaymentList.fragments = gql`
  fragment FundsPagePaymentListFragment on Campaign {
    id
    payment(id: $id) {
      id
      amount {
        formatted
      }
      processedAt
      paymentType
      recipientsCount
      recipients {
        edges {
          cursor
          node {
            id
            ...FundsPagePaymentListItemFragment
          }
        }
      }
    }
  }
  ${FundsPagePaymentListItem.fragments}
`;

// == Functions ============================================================

const recipientGrouper = (recipients) => {
  const grouped = recipients.reduce((acc, recipient) => {
    // handle lack of group
    const group = recipient.fundraiser?.group || { id: "0", name: "Unassigned" };

    return produce(acc, (draft) => {
      // initialize
      if (!draft[group.id]) {
        draft[group.id] = {
          id: group.id,
          total: 0.0,
          recipients: [],
          group,
        };
      }

      draft[group.id].total += recipient.amount.dollarsAmount;
      draft[group.id].recipients.push(recipient);
    });
  }, {});

  return Object.values(grouped);
};

function MoreRecipients() {
  return (
    <Flex justifyContent="center" padding={[2, 2, 3]}>
      <Box>More details in full report.</Box>
    </Flex>
  );
}

// == Component ============================================================

export default function FundsPagePaymentList({ paymentList }: IProps) {
  const {
    recipientsCount,
    recipients: { edges },
  } = paymentList;

  const recipients = getPaginatedNodes(edges);
  const recipientsGrouped = recipientGrouper(recipients);
  const isGroups = recipientsGrouped.length > 1;
  const isMore = recipientsCount > 30;

  if (isGroups) {
    return (
      <>
        {recipientsGrouped.map((recipientGroup) => {
          return (
            <Box key={recipientGroup.id}>
              <Flex
                alignItems="row"
                bg="lighterPrimaryBg"
                justifyContent="space-between"
                padding={3}
              >
                <Box>
                  <Text fontSize={[1, 1, 2]} fontWeight="bold">
                    {recipientGroup.group.name}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={[1, 1, 2]} fontWeight="bold">
                    ${recipientGroup.total.toFixed(2)}
                  </Text>
                </Box>
              </Flex>
              {recipientGroup.recipients.map((recipient) => (
                <FundsPagePaymentListItem
                  isGroups={isGroups}
                  key={recipient.id}
                  paymentListItem={recipient}
                />
              ))}
            </Box>
          );
        })}
        {isMore && <MoreRecipients />}
      </>
    );
  }

  return (
    <>
      {getPaginatedNodes(edges)?.map((paymentListItem) => (
        <FundsPagePaymentListItem
          isGroups={isGroups}
          key={paymentListItem?.id}
          paymentListItem={paymentListItem}
        />
      ))}
      {isMore && <MoreRecipients />}
    </>
  );
}

// == Styles ===============================================================
