import React from "react";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { RailsUrl } from "@services";
import { Box, H3, Image, Flex, Text, TouchableOpacity } from "@atoms";
import { BrandOfferSingleFragment } from "./__generated__/BrandOfferSingleFragment";
import styled from "@emotion/styled";
import { theme } from "@styles";

// == Types ================================================================

interface IProps {
  brand: BrandOfferSingleFragment;
  isHome?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  imagePadding?: number;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment BrandOfferSingleFragment on Brand {
      id
      name
      logoImage
      logoBackgroundColor
      invertedLogoImage
      maxGiveLink {
        id
        giveSentence
      }
    }
  `,
};

// == Component ============================================================

const BrandOfferSingle = ({
  brand,
  imageWidth = 120,
  imageHeight = 120,
  imagePadding = 25,
  isHome,
  ...rest
}: IProps) => {
  const { maxGiveLink: link } = brand;
  if (!link) return null;
  const onTouch = () => {
    const brandRoute = RailsUrl.brandModalUrl({ id: brand.id });
    navigate(brandRoute, { state: { brandRoute } });
  };
  const brandLogo = brand.invertedLogoImage ? brand.invertedLogoImage : brand.logoImage;
  const brandLogoBg = brand.invertedLogoImage ? brand.logoBackgroundColor : "#FFFFFF";

  return (
    <Flex width="100%" justifyContent="center">
      <TouchableOpacity
        containerProps={{ style: { maxWidth: "680px", width: "100%" } }}
        onTouch={onTouch}
      >
        <FeaturedBox alignItems="center" borderRadius={8} backgroundColor="#E4F7F7" {...rest}>
          <FeaturedLabel>FEATURED</FeaturedLabel>
          {brandLogoBg && (
            <Box width={60} height={60} mr={3} style={{ flexShrink: "0" }}>
              <BrandImage
                alt={brand.name}
                src={`${brandLogo}&twic=v1/resize=${imageWidth}x${imageHeight}(${imagePadding})|background=${brandLogoBg.slice(
                  1
                )}`}
                theme={theme}
              />
            </Box>
          )}
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Box>
              <Text display="block" fontSize={[1]} lineHeight="title">
                {brand.name}
              </Text>
              <H3 fontSize={[3]}>{link.giveSentence}</H3>
            </Box>
            <Box
              color="white"
              fontSize={[2]}
              borderRadius={6}
              backgroundColor="#22BCB9"
              style={{
                padding: "4px 12px",
              }}
            >
              Shop Now
            </Box>
          </Flex>
        </FeaturedBox>
      </TouchableOpacity>
    </Flex>
  );
};

BrandOfferSingle.fragments = FRAGMENTS;
export default BrandOfferSingle;

const BrandImage = styled(Image)`
  border-radius: 8px;
  height: 100%;
  width: 100%;
  objectfit: cover;
`;

const FeaturedLabel = styled(Box)`
  color: #6b7280;
  margin-right: 4px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 11px;
`;

const FeaturedBox = styled(Flex)`
  padding: 28px 18px 28px 4px;

  @media only screen and (min-width: ${theme.breakpoints[0]}) {
    padding: 28px 36px 28px 12px;
  }
`;
