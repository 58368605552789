import React from "react";
import { get } from "lodash";
import { Generic, ActivityLink } from "./contents";

export default ({ activity }) => {
  const link = get(activity, "data.link");
  if (link) {
    return <ActivityLink activity={activity} />;
  }

  return <Generic activity={activity} />;
};
