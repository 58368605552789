import React from "react";
import { FontAwesome } from "../../FontAwesome";
import { IFlexProps } from "../../Flex";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  containerProps?: IFlexProps;
}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export function CircleIcon({ containerProps, ...rest }: IProps) {
  return (
    <FontAwesome
      containerProps={containerProps}
      data-testid="circle"
      icon={["far", "circle"]}
      {...rest}
    />
  );
}

CircleIcon.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
