import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { oc } from "ts-optchain";
import isEmpty from "lodash/isEmpty";
import { Text } from "@atoms";
import { useLoadingDelay } from "@hooks";
import { IntentListLoading } from "../IntentListLoading";
import IntentList from "./IntentList";
import { IntentListQuery as IntentListQueryType } from "./__generated__/IntentListQuery";
// import { OccasionListQuery as TOccasionListQuery } from "./__generated__/OccasionListQuery";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  filter: string;
}

// == Constants ============================================================

const INTENTS_QUERY = gql`
  query IntentListQuery($after: String, $first: Int, $filterBy: IntentFilterByEnum) {
    Viewer {
      id
      intentsPaginated(first: $first, after: $after, filterBy: $filterBy) {
        edges {
          cursor
          node {
            id
            ...IntentListFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${IntentList.fragments.fields}
`;

// == Component ============================================================

export const IntentListQuery = ({ filter }: IProps) => {
  const loadingDelayReady = useLoadingDelay();
  const queryVariables = () => {
    return {
      filterBy: filter === "SUPPORTED" ? "SUPPORTED" : null,
      first: 15,
      after: null,
    };
  };
  const { loading, data, error, fetchMore, networkStatus } = useQuery<IntentListQueryType>(
    INTENTS_QUERY,
    {
      variables: queryVariables(),
      notifyOnNetworkStatusChange: true,
    }
  );

  const reduceIntents = (edgedIntents) => {
    const reducedIntents = oc(edgedIntents).Viewer.intentsPaginated.edges([]);
    if (reducedIntents.length === 0) return [];

    const nodeReducer = (acc, intent) => {
      if (!intent.node) return acc;
      return [...acc, intent.node];
    };

    return reducedIntents.reduce(nodeReducer, []);
  };

  if (error) {
    return <Text>{error.message}</Text>;
  }
  if ((isEmpty(data) && loading) || !loadingDelayReady) return <IntentListLoading />;

  const intents = oc(data).Viewer.intentsPaginated.edges();
  if (!intents) return null; // return error

  const onFetchMore = () => {
    const { endCursor } = oc(data).Viewer.intentsPaginated.pageInfo();
    fetchMore({
      query: INTENTS_QUERY,
      notifyOnNetworkStatusChange: true,
      variables: {
        ...queryVariables(),
        after: endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { endCursor: previousResultEndCursor, hasNextPage } = oc(
          previousResult
        ).Viewer.intentsPaginated.pageInfo();
        if (!hasNextPage || !fetchMoreResult) {
          return previousResult;
        }

        const newIntents = oc(fetchMoreResult).Viewer.intentsPaginated.edges();
        const pageInfo = oc(fetchMoreResult).Viewer.intentsPaginated.pageInfo();
        const { endCursor: newResultEndCursor } = pageInfo;

        if (newResultEndCursor === previousResultEndCursor) return previousResult;

        return {
          Viewer: {
            ...previousResult.Viewer,
            intentsPaginated: {
              cursor: newResultEndCursor,
              edges: [...previousResult.Viewer.intentsPaginated.edges, ...newIntents],
              __typename: previousResult.Viewer.intentsPaginated.__typename,
              pageInfo,
            },
          },
        };
      },
    });
  };
  const hasNextPage = oc(data).Viewer.intentsPaginated.pageInfo.hasNextPage(false);
  return (
    <IntentList
      hasNextPage={hasNextPage}
      intents={reduceIntents(data)}
      loadingMore={networkStatus === 3}
      onFetchMore={onFetchMore}
    />
  );
};

// == Styles ===============================================================
