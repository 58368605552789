import React, { useMemo } from "react";
import gql from "graphql-tag";
import { format } from "date-fns";
import { parseISO } from "date-fns/fp";
import styled from "@emotion/styled";
import { Flex, Box, Text, H3, Image, Link } from "@atoms";
import { RailsUrl } from "@services";
import { GiftcardListItemFragment } from "./__generated__/GiftcardListItemFragment";

// == Types ================================================================

interface IProps {
  giftcard: GiftcardListItemFragment;
}

// == Constants ============================================================

GiftcardListItem.fragment = gql`
  fragment GiftcardListItemFragment on Giftcard {
    id
    uuid
    externalUrl
    updatedAt
    isExternalTarget
    isReloadable
    pin
    giftcardType

    balance {
      dollarsAmount
    }
    value {
      formatted(round: 0)
    }
    lastBalance {
      formatted
      dollarsAmount
    }
    merchant {
      id
      name
    }
    link {
      id
      name
      image
      giftcardType
      walletDescription
    }
    reloadInfo {
      isReloadingEnabled
      isAutoReloadable
      reloadThreshold {
        formatted
      }
    }
    transaction {
      id
      createdAt
      giftName
      giftEmail
    }
  }
`;

// GiftcardListItem.defaultProps = {};

const IMAGE_DIMENSIONS = {
  height: 100,
  width: 130,
};

// == Component ============================================================

export function GiftcardListItem({ giftcard }: IProps) {
  const renderReloadText = useMemo(() => {
    if (!giftcard?.isReloadable) return null;
    if (!giftcard?.reloadInfo.isAutoReloadable) return null;

    const text = giftcard.reloadInfo.isReloadingEnabled
      ? `Reloads at ${giftcard.reloadInfo.reloadThreshold.formatted}`
      : "Reload disabled";
    return (
      <Text display="block" fontSize={3} lineHeight="1" mb={1}>
        {text}
      </Text>
    );
  }, [giftcard]);

  return (
    <EGiftcardListItemContainer mb={4}>
      <EGiftcardLink href={RailsUrl.giftcardUrl(giftcard.uuid)} />
      {giftcard?.link?.image && (
        <EGiftcardImageContainer mr={3} width={[100, 200]}>
          <EGiftcardImage
            active={giftcard.balance.dollarsAmount > 0}
            borderRadius="giftcard"
            src={`${giftcard.link.image}&twic=v1/resize=${IMAGE_DIMENSIONS.width}x${IMAGE_DIMENSIONS.height}`}
          />
        </EGiftcardImageContainer>
      )}
      <Flex flexDirection="column" justifyContent="center">
        <H3 fontSize={2} mb={1}>
          {!giftcard.isReloadable && `${giftcard.value.formatted} `}
          {giftcard.link.name}
        </H3>
        {giftcard.giftcardType === "VOUCHER" && giftcard.link?.walletDescription && (
          <Text className="color-money" display="block" fontSize={3} lineHeight="1" mb={1}>
            {giftcard.link?.walletDescription}
          </Text>
        )}
        {giftcard?.lastBalance.formatted && giftcard.giftcardType !== "VOUCHER" && (
          <Text className="color-money" display="block" fontSize={3} lineHeight="1" mb={1}>
            Balance: {giftcard.lastBalance.formatted}
            {renderReloadText}
          </Text>
        )}
        <Text color="subText" display="block" fontSize={0}>
          Purchased on {format(parseISO(giftcard?.transaction.createdAt), "MMM d, yyyy")}
        </Text>
      </Flex>
    </EGiftcardListItemContainer>
  );
}

// == Styles ===============================================================
const EGiftcardListItemContainer = styled(Flex)`
  cursor: pointer;
  position: relative;
`;

const EGiftcardLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const EGiftcardImageContainer = styled(Box)`
  flex-shrink: 0;
`;

const EGiftcardImage = styled(Image)`
  opacity: ${({ active }) => (active ? "1" : "0.5")};
`;
