import React from "react";
import styled from "@emotion/styled";
import * as StyledSystem from "styled-system";
import { Box, IBoxProps } from "../Box";
import { themed } from "@styles";

// == Types ================================================================

interface IFlexKnownProps
  extends IBoxProps,
    StyledSystem.FlexWrapProps,
    StyledSystem.FlexDirectionProps,
    StyledSystem.AlignItemsProps,
    StyledSystem.JustifyContentProps {}

export interface IFlexProps
  extends IFlexKnownProps,
    Omit<React.HTMLProps<HTMLDivElement>, keyof IFlexKnownProps> {}

// == Constants ============================================================

// == Component ============================================================

export const Flex = styled(Box)(
  {
    display: "flex",
  },
  StyledSystem.flexWrap,
  StyledSystem.flexDirection,
  StyledSystem.alignItems,
  StyledSystem.justifyContent,
  themed("Flex")
) as React.FunctionComponent<IFlexProps>;

// == Styles ===============================================================
