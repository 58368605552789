import React from "react";
import { IBoxProps, Image, Circle } from "@atoms";
import { renderIf } from "@services";
// import styled from "@emotion/styled";

// == Types ================================================================

export interface IImageCircleProps extends IBoxProps {
  src: string;
  alt: string;
}

// == Constants ============================================================

// == Component ============================================================

const ImageCircle = ({ src, alt, ...rest }: IImageCircleProps) => {
  return <Circle {...rest}>{renderIf(!!src)(<Image alt={alt} src={src} />)}</Circle>;
};

export default ImageCircle;

// == Styles ===============================================================
