import React from "react";
import PropTypes from "prop-types";

const CurrencyFunds = ({ funds: { currency, availableAmount } }) => (
  <div className="centered mt-5 mb-2 user-funds__available">
    {currency} {availableAmount.formatted}
  </div>
);

CurrencyFunds.propTypes = {
  funds: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    outstandingAmount: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }).isRequired,
    heldAmount: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }).isRequired,
    availableAmount: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CurrencyFunds;
