import React from "react";
import styled from "@emotion/styled";
import { Box, IBoxProps, Text } from "@atoms";

// == Types ================================================================

interface IProps extends IBoxProps {
  source: string;
}

// == Constants ============================================================

// == Component ============================================================

export default function LinkModalHtmlTerms({ source, ...rest }: IProps) {
  return (
    <Box mb={2} mt={1} {...rest}>
      <EText as="div" color="default" dangerouslySetInnerHTML={{ __html: source }} />
    </Box>
  );
}

// == Styles ===============================================================

const EText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  * {
    font-size: ${({ theme }) => theme.fontSizes[0]}px;
  }
`;
