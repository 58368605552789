import { useStoreAppValue } from "@hooks";
import React, { useContext } from "react";
import { ILocation } from "./types";
import { useDeviceLocation } from "./useDeviceLocation";

interface ILocationContextValue {
  isAllowed: boolean;
  forceSetGeo: React.Dispatch<React.SetStateAction<ILocation | undefined>>;
  requestGeo: () => void;
  location?: ILocation;
  teamLocation?: ILocation;
}

const defaultLocationValue: ILocationContextValue = {
  isAllowed: false,
  forceSetGeo: () => null,
  requestGeo: () => null,
};

const LocationContext = React.createContext(defaultLocationValue);

export const LocationProvider: React.FC = ({ children }) => {
  const { mapLocation } = useStoreAppValue();
  const { forceSetGeo, isAllowed, location, requestGeo } = useDeviceLocation(mapLocation);

  return (
    <LocationContext.Provider
      value={{ forceSetGeo, isAllowed, location, requestGeo, teamLocation: mapLocation }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationValue = () => useContext(LocationContext);

export default LocationProvider;
