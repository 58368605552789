import React from "react";
import { Box } from "@components/common/atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

// == Constants ============================================================

LinkModalBorder.defaultProps = {};

// == Component ============================================================

export default function LinkModalBorder() {
  return (
    <Box
      data-testid="link-modal-border"
      borderTop="lightSilver"
      borderBottom="lightSilver"
      height="8px"
      bg="lightBg"
    ></Box>
  );
}

// == Styles ===============================================================
