import React from "react";
import { H2, Text, Flex, Button, Container } from "@atoms";

// == Types ================================================================

interface IProps {
  message?: string;
  ctaLabel?: string;
}

// == Constants ============================================================

const DEFAULT_MESSAGE = `We&apos;re experiencing issues and the site is currently unavailable.
<br /> We are working on a fix and&#10; expect to be back as soon as possible. <br />
Thank you for your patience.`;

// == Component ============================================================

export default function ErrorLoading({
  message = DEFAULT_MESSAGE,
  ctaLabel = "Return Home",
}: IProps) {
  return (
    <Container>
      <Flex alignItems="center" flexDirection="column" p={5}>
        <H2 color="primary" fontSize={7} mb={4}>
          Oops!
        </H2>
        <Text
          as="p"
          fontSize={3}
          mb={4}
          textAlign="center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Button as="a" href="/">
          {ctaLabel}
        </Button>
      </Flex>
    </Container>
  );
}

// == Styles ===============================================================
