import React, { useState } from "react";
import { TextField, TextAreaField, DatePickerField, TimePickerField } from "@molecules";
import { Box, Flex, Button } from "@atoms";
// import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { format } from "date-fns";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { RailsUrl } from "@services";
import { useStoreAppValue, useToggle } from "@hooks";
import { startOfHour, addMinutes } from "date-fns";
import { EventCancelModal } from "../EventCancelModal";
import {
  EventUpdateMutation,
  EventUpdateMutationVariables,
} from "./__generated__/EventUpdateMutation";
import { Event_Event } from "@components/common/pages/eventPageComponents/EventEditView/__generated__/Event";
import { ErrorService } from "@services";

// == Types ================================================================

interface IProps {
  event: Event_Event;
}

EventEditForm.validation = (startDate) =>
  Yup.object({
    name: Yup.string().required("Please provide an event name."),
    startsAt: Yup.date()
      .required("Please select a start time greater than now.")
      .min(startOfHour(new Date()), "Please select a end time greater than start date."),
    endsAt: Yup.date()
      .required("Please select a end time greater than start date.")
      .min(
        addMinutes(new Date(startDate), 15),
        "Please select a end time greater than start date."
      ),
  });
// == Constants ============================================================

EventEditForm.defaultProps = {};

const EDIT_EVENT = gql`
  mutation EventUpdateMutation($input: EventUpdateInput!) {
    EventUpdate(input: $input) {
      ... on Event {
        id
        name
        description
        startsAt
        endsAt
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;

// == Component ============================================================

export default function EventEditForm({ event }: IProps) {
  const { campaignId } = useStoreAppValue();
  const initialState = {
    name: event.name,
    description: event.description,
    startsAt: event.startsAt,
    endsAt: event.endsAt,
  };
  const [editEventMutation, { loading, called }] = useMutation<
    EventUpdateMutation,
    EventUpdateMutationVariables
  >(EDIT_EVENT);
  const [startDate, setStartDate] = useState(event.startsAt);
  const [isModalOpen, setIsModalOpen] = useToggle(false);

  const onSubmit = (values, { setSubmitting }) => {
    editEventMutation({
      variables: {
        input: {
          eventId: event.id,
          brandLocationId: event.location.id,
          brandId: event.brand.id,
          ...values,
        },
      },
    })
      .then((e) => {
        setSubmitting(false);
        window.location.href = RailsUrl.eventsUrl({ campaignId: campaignId, eventId: event.id });
      })
      .catch((e) => {
        // if nothing is changed on the form, it creates an error on the backend, so this
        // is just a redirect back to the event page
        setSubmitting(false);
        ErrorService.error(e);
        window.location.href = RailsUrl.eventsUrl({ campaignId: campaignId, eventId: event.id });
      });
  };

  return (
    <>
      <Formik
        initialValues={initialState}
        validationSchema={EventEditForm.validation(startDate)}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          return (
            <Form>
              <TextField label="Event Name" name="name" placeholder="" type="text" />
              <Flex>
                <Box pr={3} flex={1}>
                  <DatePickerField
                    value={format(new Date(values.startsAt), "yyyy-MM-dd").toString()}
                    label="Start At"
                    name="startsAt"
                    placeholder="YYYY-MM-DD"
                    type="date"
                    onChange={setStartDate}
                  />
                </Box>
                <Box pl={3} flex={1}>
                  <TimePickerField
                    date={values.startsAt}
                    value={format(new Date(values.startsAt), "p").toString()}
                    name="startsAt"
                    label="Start Time"
                    placeholder="hh:mm PM"
                    type="time"
                    onChange={setStartDate}
                  />
                </Box>
              </Flex>
              <Flex>
                <Box pr={3} flex={1}>
                  <DatePickerField
                    value={format(new Date(values.endsAt), "yyyy-MM-dd").toString()}
                    label="End Date"
                    name="endsAt"
                    placeholder="YYYY-MM-DD"
                    type="date"
                  />
                </Box>
                <Box pl={3} flex={1}>
                  <TimePickerField
                    value={format(new Date(values.endsAt), "p").toString()}
                    name="endsAt"
                    placeholder="hh:mm PM"
                    type="time"
                    label="End Time"
                  />
                </Box>
              </Flex>
              <TextAreaField label="Details" name="description" placeholder="" type="text" />
              <Flex justifyContent="space-between">
                <Button type="submit">{!loading || !called ? "Save Changes" : "Saving..."}</Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen();
                  }}
                  variant="danger"
                >
                  Cancel Event
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
      <EventCancelModal
        eventId={event.id}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}

// == Styles ===============================================================
