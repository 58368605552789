import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { oc } from "ts-optchain";
import isEmpty from "lodash/isEmpty";
import { ErrorLoading } from "@organisms";
import { useLoadingDelay, useStoreAppValue } from "@hooks";
import { IntentCategoryEnum } from "@types";
import { FundsPageFundraiser } from "./FundsPageFundraiser";
import {
  FundsPageFundraiserQuery as IFundsPageFundraiserQuery,
  FundsPageFundraiserQueryVariables,
} from "./__generated__/FundsPageFundraiserQuery";

// == Types ================================================================

interface IProps {
  id: string;
  userId: string;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  name: string;
}

// == Constants ============================================================

const FUNDS_PAGE_FUNDRAISER_QUERY = gql`
  query FundsPageFundraiserQuery(
    $fundraiserUserId: ID
    $fundraiserId: ID
    $hasCurrentUser: Boolean!
    $onlyTeamSupports: Boolean
    $filterByIntentCategory: IntentCategoryEnum
    $after: String
  ) {
    Campaign {
      id
      ...FundsPageFundraiserFragment
    }
  }
  ${FundsPageFundraiser.fragments}
`;

// == Functions ============================================================

const directFundraiserIdQueryVariableMaker = (id: string, name: string) => {
  // Query for directFundraiser Totals by Category Intents needs the fundraiser ID to be null && onlyTeamSupports to be true
  // to return the totals by intent for directFundraising
  return id === name ? null : id;
};

// == Component ============================================================

export function FundsPageFundraiserQuery({ id, userId, isOpen, setOpen, name }: IProps) {
  const [activeFundraiserTab, setFundraiserTab] = useState(IntentCategoryEnum.ALL);
  const [isTransferOpen, setTransferOpen] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState(null);
  const loadingDelayReady = useLoadingDelay();
  const { currentUser } = useStoreAppValue();

  const isDirect = id === "0";
  const queryVariables = () => {
    return {
      fundraiserUserId: isDirect ? null : userId,
      fundraiserId: isDirect ? null : directFundraiserIdQueryVariableMaker(id, name),
      onlyTeamSupports: isDirect,
      hasCurrentUser: !!currentUser,
      filterByIntentCategory: activeFundraiserTab,
    };
  };

  const { data, error, loading, networkStatus, fetchMore } = useQuery<
    IFundsPageFundraiserQuery,
    FundsPageFundraiserQueryVariables
  >(FUNDS_PAGE_FUNDRAISER_QUERY, {
    variables: queryVariables(),
    notifyOnNetworkStatusChange: true,
  });

  const onFetchMore = () => {
    const { endCursor } = oc(data).Campaign.supportedIntentsPaginated.pageInfo();

    fetchMore({
      query: FUNDS_PAGE_FUNDRAISER_QUERY,
      notifyOnNetworkStatusChange: true,
      variables: {
        ...queryVariables(),
        after: endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { Campaign: oldCampaign } = previousResult;
        const { endCursor: previousResultEndCursor, hasNextPage } = oc(
          previousResult
        ).Campaign.supportedIntentsPaginated.pageInfo();
        if (!hasNextPage || !fetchMoreResult) {
          return previousResult;
        }

        const newIntents = oc(fetchMoreResult).Campaign.supportedIntentsPaginated.edges();
        const pageInfo = oc(fetchMoreResult).Campaign.supportedIntentsPaginated.pageInfo();
        const { endCursor: newResultEndCursor } = pageInfo;

        if (newResultEndCursor === previousResultEndCursor) return previousResult;

        return {
          Campaign: {
            ...oldCampaign,
            supportedIntentsPaginated: {
              cursor: newResultEndCursor,
              edges: [...previousResult.Campaign.supportedIntentsPaginated.edges, ...newIntents],
              __typename: previousResult.Campaign.supportedIntentsPaginated.__typename,
              pageInfo,
            },
          },
        };
      },
    });
  };
  const campaign = oc(data).Campaign();
  const hasNextPage = oc(data).Campaign.supportedIntentsPaginated.pageInfo.hasNextPage();
  const isLoading = (loading && networkStatus !== 3) || !loadingDelayReady;
  if (error) return <ErrorLoading />;
  if (isEmpty(data)) return null;

  return (
    <FundsPageFundraiser
      activeFundraiserTab={activeFundraiserTab}
      campaign={campaign}
      hasNextPage={hasNextPage}
      id={id}
      isLoading={isLoading}
      isOpen={isOpen}
      isTransferOpen={isTransferOpen}
      name={name}
      queryVariables={queryVariables}
      selectedListItem={selectedListItem}
      setFundraiserTab={setFundraiserTab}
      setOpen={setOpen}
      setSelectedListItem={setSelectedListItem}
      setTransferOpen={setTransferOpen}
      onFetchMore={onFetchMore}
    />
  );
}
