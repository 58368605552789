import React from "react";
import { capitalize } from "lodash";
import styled from "@emotion/styled";
import { useBeneficiaryValue } from "../../../context";
import { TEAM_TYPES } from "../BeneficiaryForm";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export const BeneficiaryConfirmationContent = () => {
  const globalState = useBeneficiaryValue();
  const { errors: globalErrors } = globalState;
  const renderField = (value) => {
    if (!value) return null;
    return <EBeneficiaryField>{value}</EBeneficiaryField>;
  };

  const {
    beneficiaryValues: { legalName, taxId, teamName, teamType, website },
    deliveryValues: {
      street1,
      street2,
      city,
      province,
      postalCode,
      country,
      checkType,
      phoneNumber,
      email,
    },
  } = globalState;

  const isCanada = country.toLowerCase() === "canada";
  const checkLabel = isCanada ? "Cheque" : "Check";
  const countryName = isCanada ? "Canada" : "USA";
  const deliveryType = checkType.toLowerCase() === "postage" ? `Mailed ${checkLabel}` : "Email";
  const filteredTeamType = TEAM_TYPES.filter(
    (type) => type[0].toUpperCase() === teamType.toUpperCase()
  );

  const displayTeamType =
    filteredTeamType.length > 0 ? filteredTeamType[0][1] : capitalize(teamType);

  return (
    <>
      <div className="mb-5">
        <h2 className="mb-2 centered">Review</h2>
        <p className="">
          Please make sure the information provided is correct. There will be a $40 charge if
          changes to payment details are required after your check has been mailed.
        </p>
      </div>
      <div className="mb-5">
        {!!globalErrors && globalErrors.length > 0 && (
          <div className="card red">
            <div className="card-content">
              <ul className="mb-0 browser-default">
                {globalErrors.map((error) => {
                  return (
                    <li className="white-text" key={error}>
                      {error}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}

        <h3 className="mb-2">
          <strong>Beneficiary Information</strong>
        </h3>
        {renderField(displayTeamType)}
        {renderField(capitalize(legalName))}
        {renderField(capitalize(taxId))}
        {renderField(capitalize(teamName))}
        {renderField(website)}
      </div>

      <div>
        <h3 className="mb-2">
          <strong>Delivery Information</strong>
        </h3>
        <div className="mb-4">
          {renderField(capitalize(deliveryType))}
          {renderField(capitalize(street1))}
          {renderField(capitalize(street2))}
          {renderField(`${capitalize(city)}, ${province}, ${countryName}`)}
          {renderField(postalCode.toUpperCase())}
          {renderField(phoneNumber)}
          {renderField(email)}
        </div>
        {/* <div className="mb-3">
          {renderField(capitalize(deliveryType))}
        </div>

        <div className="mb-3">
          {renderField(capitalize(street1))}
          {renderField(capitalize(street2))}
          {renderField(`${capitalize(city)}, ${province}, ${countryName}`)}
          {renderField(postalCode.toUpperCase())}
        </div>

        <div className="mb-3">
          {renderField(phoneNumber)}
          {renderField(email)}
        </div> */}
      </div>
    </>
  );
};

BeneficiaryConfirmationContent.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================

const EBeneficiaryField = styled.span`
  display: block;
`;
