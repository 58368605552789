import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Text } from "@atoms";
import { useLoadingDelay } from "@hooks";
import { EventEditView } from ".";
// import { OccasionListQuery as TOccasionListQuery } from "./__generated__/OccasionListQuery";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  id: string;
}

// == Constants ============================================================

const EVENT_EDIT_FORM_QUERY = gql`
  query Event($eventId: ID!) {
    Event(id: $eventId) {
      id
      address
      name
      brand {
        name
        id
        logoImage
      }
      location {
        id
      }
      campaign {
        id
        name
      }
      description
      startsAt
      endsAt
      isCancelled
    }
  }
`;

// == Component ============================================================

export const EventEditViewQuery = ({ id }: IProps) => {
  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery(EVENT_EDIT_FORM_QUERY, {
    variables: {
      eventId: id,
    },
  });

  if (error) {
    return <Text>{error.message}</Text>;
  }
  if (!data || loading || !loadingDelayReady) return null;
  const event = data?.Event;
  if (!event) return null; // return error

  return <EventEditView event={event} />;
};

// == Styles ===============================================================
