import React from "react";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import times from "lodash/times";
import { Breakpoint } from "react-socks";
import { Box } from "@atoms";
import { IntentListHeader } from "../IntentListHeader";
// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

const INTENT_LENGTH = 5;

const IntentListLoading = () => {
  const LoadingComponent = () => (
    <Box data-testid="loading-container">
      <Breakpoint medium up>
        <IntentListHeader mb={3} />
      </Breakpoint>
      {times(INTENT_LENGTH, (i) => {
        return (
          <Box key={i}>
            <Box mb={3}>
              <RectShape color="#E0E0E0" style={{ width: "100%", height: 1 }} />
            </Box>
            <Box mb={3}>
              <RectShape color="#E0E0E0" style={{ width: "100%", height: 75 }} />
            </Box>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1500}
      ready={false}
    />
  );
};

export default IntentListLoading;

// == Styles ===============================================================
