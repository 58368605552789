import React from "react";
import gql from "graphql-tag";
import { Flex, Box, TouchableOpacity, Text } from "@atoms";
import { FundsPageEarningsFundraisersListItem } from "../FundsPageEarningsFundraisersListItem";
import { FundsPageEarningsFundraisersListFundraisersFragment } from "./__generated__/FundsPageEarningsFundraisersListFundraisersFragment";
import { FundsPageEarningsFundraisersListViewerFundraiserFragment } from "./__generated__/FundsPageEarningsFundraisersListViewerFundraiserFragment";
import { FundsPageEarningsGroupsListFragment_groups } from "../FundsPageEarningsGroupsList/__generated__/FundsPageEarningsGroupsListFragment";

// == Types ================================================================

interface IProps {
  fundraisers: FundsPageEarningsFundraisersListFundraisersFragment[];
  viewerFundraiser: FundsPageEarningsFundraisersListViewerFundraiserFragment | null;
  privacyLevel: string;
  group: FundsPageEarningsGroupsListFragment_groups;
  onNoGroupFetchMore: () => void;
  onGroupFetchMore: (groupId: String, endCursor: String) => void;
}

FundsPageEarningsFundraisersList.fragments = {
  viewerFundraiser: gql`
    fragment FundsPageEarningsFundraisersListViewerFundraiserFragment on Campaign {
      id
      viewerFundraiser @include(if: $hasCurrentUser) {
        id
        name
        userId
      }
      ...FundsPageEarningsFundraisersListItemPrivacyLevel
    }
    ${FundsPageEarningsFundraisersListItem.fragments.privacyLevel}
  `,
  fundraisers: gql`
    fragment FundsPageEarningsFundraisersListFundraisersFragment on Fundraiser {
      id
      ...FundsPageEarningsFundraisersListItemFundraiserFragment
    }
    ${FundsPageEarningsFundraisersListItem.fragments.fundraiser}
  `,
};

// == Constants ============================================================

const remainingFundraisersCalculator = (group) => {
  const count = group.activeFundraisersCount - group.paginatedFundraisers.edges.length;

  // makes addition of campaignDirectFundraiser
  return group.id === "NO_GROUP" ? count + 1 : count;
};

// == Component ============================================================

export default function FundsPageEarningsFundraisersList({
  fundraisers,
  viewerFundraiser,
  privacyLevel,
  onNoGroupFetchMore,
  onGroupFetchMore,
  group,
}: IProps) {
  const { hasNextPage, endCursor } = group?.paginatedFundraisers.pageInfo;

  const onTouch = () => {
    if (group.id === "NO_GROUP") {
      onNoGroupFetchMore();
    } else {
      onGroupFetchMore(group.id, endCursor);
    }
  };

  return (
    <Flex bg="white" flexDirection="column" justifyContent="flex-start" width={1}>
      {fundraisers.map((fundraiser) => (
        <FundsPageEarningsFundraisersListItem
          fundraiser={fundraiser}
          isViewerFundraiser={fundraiser.id === viewerFundraiser?.id}
          key={fundraiser.id}
          privacyLevel={privacyLevel}
        />
      ))}

      {fundraisers.length === 0 && (
        <Box paddingLeft={5} py={[2, 3]} marginLeft={3}>
          <Text fontSize={[1, 2]}>This player does not have any family members yet!</Text>
        </Box>
      )}

      {hasNextPage && (
        <Box paddingLeft={5} px={[3, 4]} py={[2, 3]} width={1}>
          <TouchableOpacity variant="clear" onTouch={onTouch}>
            <Text color="primary">+{remainingFundraisersCalculator(group)} More</Text>
          </TouchableOpacity>
        </Box>
      )}
    </Flex>
  );
}

// == Styles ===============================================================
