import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { Box, Text, IInputProps, IBoxProps, SearchIcon, TimesIcon } from "@atoms";

// == Types ================================================================

interface ISearchInputProps extends IInputProps {
  placeholder: string;
  position: IBoxProps["position"];
  value: string;
  containerProps: $FixMe;
  showClear?: boolean;
  size?: "normal" | "small";
  onClearClick?: () => void;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  placeholder: "Search",
  as: "input" as const,
  containerProps: {},
  showClear: false,
  onClearClick: null,
  size: "normal",
};

// == Component ============================================================

const SearchInput = forwardRef(
  (
    {
      placeholder,
      position,
      value,
      containerProps,
      showClear,
      onClearClick,
      size,
      ...rest
    }: ISearchInputProps,
    ref
  ) => {
    return (
      <Box {...containerProps}>
        <EText color="primary" size={size}>
          <SearchIcon />
        </EText>
        <EInput placeholder={placeholder} ref={ref} size={size} {...rest} />
        {showClear && (
          <ETextClear color="primary" onClick={onClearClick}>
            <TimesIcon />
          </ETextClear>
        )}
      </Box>
    );
  }
);
SearchInput.defaultProps = DEFAULT_PROPS;

export default SearchInput;

// == Styles ===============================================================

const EText = styled(Text)`
  position: absolute;
  left: 16px;
  top: ${({ size }) => (size === "normal" ? "22px" : "16px")};
`;

const ETextClear = styled(Text)`
  position: absolute;
  right: 16px;
  top: ${({ size }) => (size === "normal" ? "22px" : "16px")};
  cursor: pointer;
`;

const EInput = styled.input`
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 45px !important;
  padding-right: 45px !important;
  border-color: ${({ theme }) => theme.colors.gray}!important;
  height: ${({ size }) => (size === "normal" ? "60px" : "45px")} !important;
`;
