import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { useQuery } from "react-apollo";
import styled from "@emotion/styled";
import { useInView } from "react-intersection-observer";
import { RailsUrl, analytics } from "@services";
import { useStoreAppValue, useBreakpoint } from "@hooks";
import { getPaginatedNodes } from "@utils";
import { Row, Column, Text, Box } from "@atoms";
import { CollectionTitle } from "../CollectionTitle";
import { LinkPromotionBlock } from "../../linkPromotionBlockComponents";

// == Types ================================================================

interface IProps {
  collection: $FixMe;
  storefrontId?: string;
}
// == Constants ============================================================

const COLLECTION_LINK_PROMOTIONS_QUERY = gql`
  query CollectionLinkPromotionsQuery($collection_id: ID!) {
    LinkPromotion(uniqueBrands: true, collectionId: $collection_id, first: 6) {
      edges {
        node {
          id
          ...LinkPromotionBlockFragment
        }
      }
    }
  }
  ${LinkPromotionBlock.fragment}
`;

CollectionLinkPromotions.defaultProps = {};

// == Component ============================================================

export function CollectionLinkPromotions({ collection, storefrontId }: IProps) {
  const [tracked, setTracked] = useState(false);
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });
  useEffect(() => {
    if (inView && !tracked) {
      analytics.track.trackCollectionView({
        collectionId: collection.id,
        collectionName: collection.name ?? "",
      });
      setTracked(true);
    }
  }, [collection.id, collection.name, inView, tracked]);
  const breakpoint = useBreakpoint();
  const { campaignId } = useStoreAppValue();
  const { data, loading, error } = useQuery<CollectionLinkPromotionsQuery>(
    COLLECTION_LINK_PROMOTIONS_QUERY,
    {
      variables: { collection_id: collection.id },
    }
  );

  if (loading || !!error) return null;
  const linkPromotions = getPaginatedNodes(data?.LinkPromotion.edges);
  if (linkPromotions.length === 0) return null;
  const promotions = ["xs", "sm"].includes(breakpoint)
    ? linkPromotions.slice(0, 3)
    : linkPromotions;

  const onEditClick = () => {
    navigate(RailsUrl.favoritesUrl());
  };

  return (
    <Box ref={ref}>
      <CollectionTitle
        forceCount
        collectionId={collection.id}
        title={collection.name}
        titleChildren={
          <EEditText color="primary" onClick={onEditClick}>
            Edit
          </EEditText>
        }
        url={RailsUrl.dealsUrl(campaignId)}
        storefrontId={storefrontId}
      />
      <Row mt={3}>
        {promotions.map((linkPromotion) => {
          if (!linkPromotion.link) return null;
          return (
            <Column autoColumn={false} key={linkPromotion.id} mb={3} width={["100%", "50%"]}>
              <LinkPromotionBlock
                linkPromotion={linkPromotion}
                metaInfo={{
                  collectionId: collection.id,
                  collectionName: collection.name,
                  storefrontId,
                }}
              />
            </Column>
          );
        })}
      </Row>
    </Box>
  );
}

// == Styles ===============================================================

const EEditText = styled(Text)`
  margin-left: ${({ theme }) => theme.space[2]}px;
  position: relative;
  top: -2px;
  cursor: pointer;
`;
