import React from "react";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";

const sliderSettings = {
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: false,
  dots: true,
};

export const Slider: React.FC<React.ComponentProps<typeof SlickSlider>> = ({
  children,
  ...sliderProps
}) => {
  return (
    <div>
      <SlickSlider {...sliderSettings} {...sliderProps} className="w-full" dotsClass="dots">
        {children}
      </SlickSlider>
    </div>
  );
};
