import React from "react";
import { Flex, IFlexProps } from "../Flex";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export const Row = ({ children, ...rest }: IFlexProps) => {
  return (
    <Flex flexWrap="wrap" mx={[-2, -2, -3]} {...rest}>
      {children}
    </Flex>
  );
};

// == Styles ===============================================================
