/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { func, bool, string } from "prop-types";

export default function FlipGiveTeamListItemNew({
  isSelected,
  setSelectedCreateNewTeam,
  teamName,
}) {
  const onClick = (e) => {
    setSelectedCreateNewTeam();
    e.preventDefault();
  };

  return (
    <a className="default-color-link campaign-list-item-link" href="#" onClick={onClick}>
      <div className="media campaign-list-item align-items-center mb-3">
        <div className="campaign-list-item__image-container mr-3">
          <img
            alt="Create new team"
            className="campaign-list-item__image campaign-list-item__image--logo"
            src="https://d2wkegjj8g6j4.cloudfront.net/images/uploads/temp/teamsnap/flipgive-logo-circle.jpg"
          />
        </div>

        <div className="media-body pr-2">
          <span className="campaign-list-item__name">None of the above</span>
          <span className="campaign-list-item__raised">Create {teamName} on FlipGive</span>
        </div>

        <div className={`${isSelected ? null : "opacity-0"}`}>
          <i className="material-icons color-link">check</i>
        </div>
      </div>
    </a>
  );
}

FlipGiveTeamListItemNew.propTypes = {
  isSelected: bool.isRequired,
  setSelectedCreateNewTeam: func.isRequired,
  teamName: string.isRequired,
};
