import React from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "@emotion/styled";
import { useScrollTopOnMount, useStoreAppValue } from "@hooks";
import { OccasionsListQuery, AnnouncementModalQuery } from "@organisms";
import { Container } from "@atoms";
import { DefaultStoreFrontViewQuery } from "../../storeFrontComponents/StoreFrontView";
import { StoreFrontFeaturedBrand } from "../../storeFrontComponents/StoreFrontFeaturedBrand";
import OnboardingWelcomeModalQuery from "../components/OnboardingWelcomeModal/OnboardingWelcomeModalQuery";
import { FavNearbyTopSection } from "../components/FavNearbyTopSection/FavNearbyTopSection";
import { OnboardingTodo } from "../components/OnboardingTodo/OnboardingTodo";

// == Component ============================================================

const Home = (_props: RouteComponentProps) => {
  useScrollTopOnMount();
  const { featureFlags, campaignId, currentUser } = useStoreAppValue();

  return (
    <>
      <Container data-testid="container" mt={3} pt={[3, 3, 4]}>
        <EStoreFrontFeaturedBrand isHome campaignId={campaignId} />
        <OnboardingTodo />
        <FavNearbyTopSection />
        <OccasionsListQuery />
      </Container>
      <DefaultStoreFrontViewQuery type="HOME_DEFAULT" />
      {currentUser?.id && <AnnouncementModalQuery />}
      {featureFlags.newOnboarding && <OnboardingWelcomeModalQuery />}
    </>
  );
};

export default Home;

// == Styles ===============================================================

const EStoreFrontFeaturedBrand = styled(StoreFrontFeaturedBrand)`
  margin-bottom: ${({ theme }) => `${theme.space[3]}px`};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-bottom: ${({ theme }) => `${theme.space[4]}px`};
  }
`;
