import React, { Dispatch, SetStateAction } from "react";
import gql from "graphql-tag";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Box, Modal, Flex, Text, TouchableOpacity, ChevronLeft } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { theme } from "@styles";
import { IntentCategoryEnum } from "@types";
import { FundsPageFundraiserTransfer } from "../FundsPageFundraiserTransfer";
import { FundsPageFundraiserTabs } from "../FundsPageFundraiserTabs";
import { FundsPageFundraiserList } from "../FundsPageFundraiserList";
import { FundsPageFundraiserFragment } from "./__generated__/FundsPageFundraiserFragment";

// == Types ================================================================

interface IProps {
  activeFundraiserTab: IntentCategoryEnum;
  campaign: FundsPageFundraiserFragment;
  hasNextPage: boolean;
  id: string;
  isLoading: boolean;
  isOpen: boolean;
  isTransferOpen: boolean;
  name: string;
  onFetchMore: () => void;
  queryVariables: $FixMe;
  selectedListItem: $FixMe;
  setFundraiserTab: Dispatch<SetStateAction<IntentCategoryEnum>>;
  setOpen: (isOpen: boolean) => void;
  setSelectedListItem: (selectedListItem: string) => void;
  setTransferOpen: (isTransferOpen: boolean) => void;
}

// == Constants ============================================================

FundsPageFundraiser.fragments = gql`
  fragment FundsPageFundraiserFragment on Campaign {
    id
    ...FundsPageFundraiserListFragment
  }
  ${FundsPageFundraiserList.fragments}
`;

// == Functions ============================================================

const {
  colors: { lightGreyBg, placeholderLightGrey },
} = theme;

const LoadingComponent = (
  <Box p={[3]}>
    <Flex alignItems="center" justifyContent="space-between">
      <RectShape color={placeholderLightGrey} style={{ width: 40, height: 28 }} />
      <RectShape color={placeholderLightGrey} style={{ width: 60, height: 28 }} />
    </Flex>
  </Box>
);

// == Component ============================================================

export function FundsPageFundraiser({
  activeFundraiserTab,
  campaign,
  hasNextPage,
  id,
  isLoading,
  isOpen,
  isTransferOpen,
  name,
  onFetchMore,
  queryVariables,
  selectedListItem,
  setFundraiserTab,
  setOpen,
  setSelectedListItem,
  setTransferOpen,
}: IProps) {
  const {
    theme: {
      colors: { primary },
    },
  } = useStoreAppValue();

  const isDirectFundraiser = id === name; // comparing fundraiserID to campaignName - will be equal for injected Direct Fundraiser

  const renderContent = () => {
    if (isTransferOpen)
      return (
        <FundsPageFundraiserTransfer
          campaign={campaign}
          selectedListItem={selectedListItem}
          setOpen={setOpen}
          setTransferOpen={setTransferOpen}
        />
      );
    if (campaign) {
      return (
        <>
          <FundsPageFundraiserTabs
            activeFundraiserTab={activeFundraiserTab}
            setFundraiserTab={setFundraiserTab}
          />
          {isLoading ? (
            <ReactPlaceholder
              showLoadingAnimation
              color={lightGreyBg}
              customPlaceholder={LoadingComponent}
              delay={1000}
              ready={false}
            />
          ) : (
            <FundsPageFundraiserList
              activeFundraiserTab={activeFundraiserTab}
              campaign={campaign}
              hasNextPage={hasNextPage}
              isDirectFundraiser={isDirectFundraiser}
              setOpen={setOpen}
              setSelectedListItem={setSelectedListItem}
              setTransferOpen={setTransferOpen}
              onFetchMore={onFetchMore}
            />
          )}
        </>
      );
    }
    if (!campaign) {
      return (
        <FundsPageFundraiserTabs
          activeFundraiserTab={activeFundraiserTab}
          setFundraiserTab={setFundraiserTab}
        />
      );
    }
    return null;
  };

  const renderHeader = () => {
    if (isTransferOpen) return `Transfer Funds`;
    if (isDirectFundraiser) return `${name} (Team)`;
    return name;
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => (isTransferOpen ? setTransferOpen(false) : setOpen(false))}
    >
      <Flex alignItems="baseline" borderBottom="lightGreyBg" justifyContent="flex-start">
        <Box padding={[2, 2, 3]} paddingLeft={3}>
          <TouchableOpacity
            onTouch={() => (isTransferOpen ? setTransferOpen(false) : setOpen(false))}
          >
            <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
          </TouchableOpacity>
        </Box>

        <Box padding={[2, 2, 3]}>
          <Text color="black" fontSize={[2, 2, 3]}>
            {renderHeader()}
          </Text>
        </Box>
      </Flex>
      {renderContent()}
    </Modal>
  );
}

// == Styles ===============================================================
