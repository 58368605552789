import React from "react";
import times from "lodash/times";
import { StarIcon, Flex, IFlexProps } from "@atoms";

// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends IFlexProps {
  total: number;
  rating: number;
}

// == Constants ============================================================

// == Component ============================================================

const Ratings = ({ total, rating, ...rest }: IProps) => {
  return (
    <Flex {...rest}>
      {times(total, (i) => {
        let outline = false;
        let half = false;
        const count = i + 1;
        if (rating > count && rating < count + 1) {
          half = true;
        } else if (rating < count) {
          outline = true;
        }
        return <StarIcon half={half} key={`rating-${i}`} outline={outline} />;
      })}
    </Flex>
  );
};

export default Ratings;

// == Styles ===============================================================
