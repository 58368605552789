import React from "react";
import gql from "graphql-tag";
import { IBoxProps, Image } from "@atoms";
// import { ImageCircle } from "@molecules";
import { BrandCircleFragment } from "./__generated__/BrandCircleFragment";

// == Types ================================================================

type TBrand = BrandCircleFragment;

interface IProps extends IBoxProps {
  brand: TBrand;
  imageWidth: number;
  borderColor?: string;
  pad?: number;
  border?: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment BrandCircleFragment on Brand {
      id
      name
      logoImage
      logoBackgroundColor
      invertedLogoImage
    }
  `,
};

const DEFAULT_PROPS = {
  imageWidth: 100,
  pad: 18,
  borderColor: null,
  border: "silver",
};

// == Functions ============================================================

const REMOVE_HASH = (color: string) => {
  return color?.replace("#", "");
};

// == Component ============================================================

const BrandCircle = ({ brand, imageWidth, pad, borderColor, border, ...rest }: IProps) => {
  const brandLogo = brand.invertedLogoImage ? brand.invertedLogoImage : brand.logoImage;
  const brandLogoBg = brand.invertedLogoImage ? brand.logoBackgroundColor : "fff";
  const bg = brandLogo ? brandLogoBg : "fff";
  const imgixBorder = borderColor || bg;

  const src = brandLogo
    ? `${brandLogo}&w=${imageWidth}&h=${imageWidth}&fit=fill&bg=${REMOVE_HASH(
        bg
      )}&pad=${pad}&border-radius=500&border=1%2C${REMOVE_HASH(imgixBorder)}`
    : null;
  if (!src) return null;
  return <Image alt={brand.name} border={border} borderRadius="circle" src={src} {...rest} />;

  // return <ImageCircle alt={brand.name} bg={bg} border="silver" p="17%" src={src} {...rest} />;
};

BrandCircle.fragments = FRAGMENTS;
BrandCircle.defaultProps = DEFAULT_PROPS;
export default BrandCircle;

// == Styles ===============================================================
