import React, { useMemo, useState } from "react";
import gql from "graphql-tag";
import { oc } from "ts-optchain";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import { RouteComponentProps } from "@reach/router";
import { renderIf } from "@services";
import { Container, Box, Heading, Row, Column, Flex } from "@atoms";
import { DropDown } from "@molecules";
import { BrandBlock, LinkBlock } from "@organisms";
import { CollectionViewHero } from "../CollectionViewHero";
import { CollectionViewFragment } from "./__generated__/CollectionViewFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IRouteProps {
  collection: CollectionViewFragment;
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment CollectionViewFragment on Collection {
      id
      ...CollectionViewHeroFragment
      brands(first: $firstLinks) {
        edges {
          cursor
          node {
            id
            ...BrandBlockFragment
          }
        }
      }
      links(first: $firstLinks) {
        id
        name
        giveAmount
        ...LinkBlockFragment
      }
    }
    ${BrandBlock.fragments.fields}
    ${CollectionViewHero.fragments.fields}
    ${LinkBlock.fragments.fields}
  `,
};

const SORT_OPTIONS = [
  { label: "Recommended", slug: "recommended" },
  { label: "Give %", slug: "give" },
  { label: "Name", slug: "name" },
];

// == Component ============================================================

const CollectionView = ({ collection }: TProps) => {
  const [currentSort, setSort] = useState(SORT_OPTIONS[0].slug);

  const reducedBrands = useMemo(() => {
    const newBrands = oc(collection).brands.edges([]);
    if (newBrands.length === 0) return [];

    const nodeReducer = (acc, brand) => {
      if (!brand.node) return acc;
      return [...acc, brand.node];
    };

    return newBrands.reduce(nodeReducer, []);
  }, [collection]);

  const onSortSelect = (slug: "recommended" | "name" | "give") => {
    setSort(slug);
  };

  const links = oc(collection).links([]);

  const sortedLinks = useMemo(() => {
    if (currentSort === "give") {
      return reverse(sortBy(links, (link) => parseInt(link.giveAmount, 10)));
    }

    if (currentSort === "name") {
      return sortBy(links, ["name"]);
    }

    // last default option, means current sort is popular
    return links;
  }, [currentSort, links]);

  return (
    <>
      <CollectionViewHero collection={collection} mb={3} />
      <Container isMainContainer>
        {renderIf(reducedBrands.length > 0)(
          <Box mb={4}>
            <Heading fontSize={[2, 3]} mb={2}>
              Featured Brands
            </Heading>
            <Row flexWrap="wrap" justifyContent="center">
              {reducedBrands.map((brand) => {
                return (
                  <Column autoColumn={false} key={brand.id} mb={3} width={[1 / 2, 1 / 4]}>
                    <BrandBlock brand={brand} />
                  </Column>
                );
              })}
            </Row>
            {/* brand list also has to do a show more if there are more than 8 */}
          </Box>
        )}
        {renderIf(sortedLinks.length > 0)(
          <Box>
            <Flex alignItems="center" mb={3}>
              <Heading flex={1} fontSize={[2, 3]}>
                Top Picks
              </Heading>
              <DropDown
                items={SORT_OPTIONS}
                label="Sort By"
                selected={currentSort}
                onSelect={onSortSelect}
              />
            </Flex>
            <Row flexWrap="wrap" justifyContent="center">
              {sortedLinks.map((link) => {
                return (
                  <Column autoColumn={false} key={link.id} mb={3} width={[1 / 2, 1 / 4]}>
                    <LinkBlock collectionId={collection.id} link={link} />
                  </Column>
                );
              })}
            </Row>
          </Box>
        )}
      </Container>
    </>
  );
};

CollectionView.fragments = FRAGMENTS;

export default CollectionView;

// == Styles ===============================================================
