import React, { ReactNode, useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import { Location, LocationContext, Link } from "@reach/router";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import gql from "graphql-tag";
import arrayMove from "array-move";
import { BrandCircle } from "@organisms/brandCircleComponents/BrandCircle";
import { renderIf, RailsUrl } from "@services";
import { Box, Circle, Text, FontAwesome, Flex, GripLinesIcon } from "@atoms";
import { ImageListItem } from "@molecules";
import { FAVORITE_BRANDS_QUERY, FavoriteBrands as FavoriteBrandsQuery } from "@queries";
import { useStoreAppValue } from "@hooks";
import { FavoriteModalListRemoveButton } from "../FavoriteModalListRemoveButton";
import { FavoritesSearchBar } from "../FavoritesSearchBar";
import {
  ReorderFavoriteBrandsMutation_ViewerReorderFavoriteBrands_Viewer,
  ReorderFavoriteBrandsMutationVariables,
} from "./__generated__/ReorderFavoriteBrandsMutation";

// == Types ================================================================

interface IProps {
  brands: ($FixMe | null)[];
  isEditing: boolean;
  isSearchBarOpen: boolean;
  toggleSearchBar: () => void;
}

// == Constants ============================================================

const REORDER_FAVORITE_BRANDS = gql`
  mutation ReorderFavoriteBrandsMutation($brandIds: [ID!]!) {
    ViewerReorderFavoriteBrands(brandIds: $brandIds) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Viewer {
        id
        favoriteBrandsCount
        favoriteBrands(first: 50) {
          id
          name
          logoImage
          viewerHasFavorited
        }
      }
    }
  }
`;
// == Component ============================================================

const FavoritesModalList = ({ brands, isEditing, toggleSearchBar, isSearchBarOpen }: IProps) => {
  const [newBrandsList, setBrandsList] = useState(brands);
  // TODO: i think the way we do this could be better, (referring to newBrandsList)
  // it would be better if this component was just responsible for listing out brands
  useEffect(() => {
    setBrandsList(brands);
  }, [brands]);
  const [reorderFavoriteBrandsMutation] = useMutation<
    ReorderFavoriteBrandsMutation_ViewerReorderFavoriteBrands_Viewer,
    ReorderFavoriteBrandsMutationVariables
  >(REORDER_FAVORITE_BRANDS);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void | null => {
    setBrandsList(arrayMove(newBrandsList, oldIndex, newIndex));
    const newBrands = arrayMove(newBrandsList, oldIndex, newIndex);
    const newBrandsListIDs = newBrands.map((brand) => (brand ? brand.id : ""));

    reorderFavoriteBrandsMutation({
      variables: { brandIds: newBrandsListIDs },
      update(cache) {
        const data = cache.readQuery<FavoriteBrandsQuery>({
          query: FAVORITE_BRANDS_QUERY,
        })!;

        const newData = {
          Viewer: {
            ...data.Viewer,
            favoriteBrandsCount: data.Viewer.favoriteBrandsCount,
            favoriteBrands: newBrands,
          },
        };

        cache.writeQuery({
          id: data.Viewer.id,
          query: FAVORITE_BRANDS_QUERY,
          data: newData,
        });
      },
    });
  };

  const renderBrandImage = (brand: $FixMe, index: number) => {
    if (brand.logoImage) {
      return (
        <BrandCircle
          border={index < 7 ? "primary" : "silver"}
          brand={brand}
          imageSize={50}
          pad={20}
        />
      );
    }
    return <Circle bg="gray" />;
  };

  const DragHandle = SortableHandle(() => (
    <GripLinesIcon containerProps={{ style: { cursor: "row-resize" } }} />
  ));

  const SortableListContainer = SortableContainer(({ children }: { children: ReactNode }) => {
    return <Box>{children}</Box>;
  });

  const SortableItem = SortableElement(
    ({ value, sortIndex }: { value: $FixMe; sortIndex: number }) => (
      <Flex zIndex={100}>
        <Flex alignItems="center" justifyContent="center" mt={2} width="50px">
          {isEditing && <DragHandle />}
        </Flex>
        <Box cursor="pointer" flex={1} mr={2}>
          <Link state={{ canReturn: true }} to={RailsUrl.brandModalUrl({ id: value.id })}>
            <ImageListItem
              key={value.id}
              leftComponent={renderBrandImage(value, sortIndex)}
              mt={2}
              text={value.name ? value.name : "N/A"}
            />
          </Link>
        </Box>
        {renderIf(isEditing)(<FavoriteModalListRemoveButton brandId={value.id} />)}
      </Flex>
    )
  );

  // to be abstracted
  const AddIconCircle = () => {
    return (
      <Circle border="silver">
        <Text color="primary" fontSize={3}>
          <FontAwesome icon="plus" />
        </Text>
      </Circle>
    );
  };

  const renderFavoritesModalList = ({ location, navigate }: LocationContext) => {
    return (
      <>
        <Box pr={3} py={3}>
          <Flex>
            <Box width="50px" />
            <ImageListItem
              leftComponent={<AddIconCircle />}
              text={!isSearchBarOpen ? "Add New Favorites" : "Show All Favorites"}
              onTouch={toggleSearchBar}
            />
          </Flex>
          {isSearchBarOpen ? (
            <Box pl={3} py={3}>
              <FavoritesSearchBar />
            </Box>
          ) : (
            <SortableListContainer useDragHandle onSortEnd={onSortEnd}>
              {newBrandsList.map((brand, index) => {
                if (!brand) return null;
                return (
                  <SortableItem
                    index={index}
                    key={`item-${brand.id}`}
                    sortIndex={index}
                    value={brand}
                  />
                );
              })}
            </SortableListContainer>
          )}
        </Box>
      </>
    );
  };
  // TODO, this component needs to account for a max height of items
  return (
    <Location>
      {({ location, navigate }: LocationContext) =>
        renderFavoritesModalList({ location, navigate })
      }
    </Location>
  );
};

export default FavoritesModalList;

// == Styles ===============================================================
