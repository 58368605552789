import { useLocation } from "@reach/router";

// == Types ================================================================

// == Constants ============================================================

// == Hook =================================================================

export const useQueryParams = (paramName: string, defaultValue?: $IntentionalAny) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(paramName) ?? defaultValue;
};
