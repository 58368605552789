/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import gql from "graphql-tag";
import { graphql, useMutation } from "react-apollo";
import LoadingTeamsSpinner from "./LoadingTeamsSpinner";
import { RailsUrl } from "@services";

const CAMPAIGN_JOIN_MUTATION = gql`
  mutation CampaignJoinMutation($accessCode: String, $externalPartner: ExternalPartnerInput) {
    JoinCampaign(input: { accessCode: $accessCode, externalPartner: $externalPartner }) {
      ... on Fundraiser {
        id
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;

export default function FlipGiveChoose({ externalTeam, chooseCreate }) {
  const [accessCode, setAccessCode] = React.useState("");
  const [error, setError] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const hasAccessCode = accessCode.length === 6;
  const [joinCampaignMutation] = useMutation(CAMPAIGN_JOIN_MUTATION);

  async function joinCampaignWithAccessCode() {
    setError(null);

    const { data } = await joinCampaignMutation({
      variables: {
        accessCode,
        externalPartner: {
          id: externalTeam.id,
          type: externalTeam.type,
        },
      },
    });
    const {
      JoinCampaign: { errors },
    } = data;

    if (errors) {
      setError(errors[0].inputErrors.join(", "));
    } else {
      setSubmitting(true);
      window.location.href = RailsUrl.newTeamsnapPasswordUrl();
    }
  }

  if (submitting) {
    return <LoadingTeamsSpinner text="Joining..." />;
  }

  return (
    <div>
      <h1 className="centered mb-3">You need an account on FlipGive to start earning funds</h1>
      <p className="centered mb-4">
        If you received an invite from a teammate enter the invite code below, otherwise you&apos;ll
        need to create an account for <strong>{externalTeam.name}</strong> on FlipGive to to get
        started.
      </p>

      <form
        onSubmit={(event) => {
          event.preventDefault();
          joinCampaignWithAccessCode();
        }}
      >
        <input
          id="invite-code"
          maxLength={6}
          name="invite-code"
          placeholder="Invite Code"
          value={accessCode}
          onChange={(event) => {
            setAccessCode(event.target.value.toUpperCase());
          }}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}

        <button className={`${!hasAccessCode ? "disabled" : null} btn btn-block`} type="submit">
          Join Existing Team
        </button>
      </form>

      <p className="centered mt-3">or</p>

      <a
        className="btn btn-block"
        href="#"
        onClick={(event) => {
          event.preventDefault();
          chooseCreate();
        }}
      >
        Create New Team
      </a>
    </div>
  );
}
