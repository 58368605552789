import gql from "graphql-tag";

export default gql`
  query CampaignQuery {
    Campaign {
      id
      joinedFundraisers {
        id
        name
        userId
      }
    }
  }
`;
