import React, { useState } from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import queryString from "query-string";
import { RailsUrl } from "@services";
import { Box, Text, Flex, Button, Circle } from "@atoms";
import { InviteModal } from "@organisms";
import { useStoreAppValue } from "@hooks";
import { theme } from "@styles";
import { ImageListItem } from "@molecules";
// import { theme } from "@styles";
import { FundsDistributionMethod } from "@types";
// import { PrivacyLevel } from "../../../../../types/globalApolloTypes";
// import { RestrictedAccess } from "../components/RestrictedAccess";
import { MemberListGrouped } from "../components/MemberListGrouped";
import { FundsDistributionView } from "../components/FundsDistributionView";
import { OrganizeMembersViewFragment } from "./__generated__/OrganizeMembersViewFragment";

// == Types ================================================================

interface IProps {
  campaign: OrganizeMembersViewFragment;
  refetch: () => void;
}

// == Constants ============================================================

// used with the other commented code
// const {
//   colors: { darkerPrimary, lightBg },
// } = theme;

OrganizeMembersView.fragment = gql`
  fragment OrganizeMembersViewFragment on Campaign {
    id
    name
    privacyLevel
    isViewerOwner
    fundsDistributionMethod
    hasBudget
    ...MemberListGroupedFragment
  }
  ${MemberListGrouped.fragment}
`;

// == Component ============================================================

export default function OrganizeMembersView({ campaign, refetch }: IProps) {
  const { fundsDistributionMethod } = campaign;
  const { isViewerOwner } = campaign;
  const { privacyLevel } = campaign;

  const { campaignId } = useStoreAppValue();
  const urlParams = queryString.parse(window.location.search);
  const [inviteModalData, setInviteModalData] = useState({
    open: !!urlParams.invite,
    template: (urlParams.invite || null) as string | null,
  });

  const { PLAYER, TEAM, UNDECIDED } = FundsDistributionMethod;

  const [activeReassignmentMemberId, setActiveReassignmentMemberId] = useState(null);

  // const isAnonymous = privacyLevel === PrivacyLevel.ANONYMOUS;
  // if (isAnonymous && !isViewerOwner) return <RestrictedAccess />;

  if (fundsDistributionMethod === UNDECIDED && isViewerOwner) return <FundsDistributionView />;

  // in case it comes back
  // const renderSubTabs = () => {
  //   if (isViewerOwner && fundsDistributionMethod === PLAYER) {
  //     return (
  //       <Box bg={lightBg} mb={4} py={2}>
  //         <Container>
  //           <Flex width={["100%", "100%", "30%"]}>
  //             <TouchableOpacity
  //               containerProps={{ style: { width: "100%" } }}
  //               py={3}
  //               textAlign="center"
  //               width={1}
  //               onTouch={() => (window.location.href = RailsUrl.membersUrl(campaignId))}
  //             >
  //               <Text color={darkerPrimary}>Invite Team</Text>
  //             </TouchableOpacity>
  //             <TouchableOpacity
  //               containerProps={{ style: { width: "100%" } }}
  //               py={3}
  //               textAlign="center"
  //               width={1}
  //               onTouch={() => (window.location.href = RailsUrl.playersUrl(campaignId))}
  //             >
  //               <Text>Player Roster</Text>
  //             </TouchableOpacity>
  //           </Flex>
  //         </Container>
  //       </Box>
  //     );
  //   }
  //   return null;
  // };

  const renderPageTip = () => {
    return (
      <Box mb={3} pt={[4]} px={[2, 3]}>
        <Text>
          Before sending invites check your team membership below so that you don&apos;t bug people
          who&apos;ve already joined.
        </Text>
      </Box>
    );
  };

  const renderUpperCTA = () => {
    if (!isViewerOwner) return null;
    return (
      <Flex alignItems="center" justifyContent="space-between" paddingLeft={2} width={1}>
        <EImageListItem
          leftComponent={
            <Circle
              border="silver"
              className="circle"
              height="40px"
              innerProps={{ height: 20, width: 20 }}
              width="40px"
            >
              <svg height="20" viewBox="0 0 140 140" width="20" xmlns="http://www.w3.org/2000/svg">
                <g
                  fill="#fff"
                  stroke={theme.colors.primary}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                >
                  <path
                    d="M65.625 100.625a35 35 0 1070 0 35 35 0 10-70 0zM100.625 83.125v35M83.125 100.625h35M4.375 100.625a39.392 39.392 0 0154.833-36.213M19.688 28.438a24.063 24.063 0 1048.124 0 24.063 24.063 0 10-48.124 0z"
                    strokeWidth="8.749995"
                  />
                </g>
              </svg>
            </Circle>
          }
          leftComponentProps={{ mr: 3, width: 40, height: 40 }}
          mb={3}
          padding={[1, 2]}
          text="Setup Player Roster"
          onTouch={() => {
            window.location.href = RailsUrl.playersUrl(campaignId);
          }}
        />
      </Flex>
    );
  };

  return (
    <>
      {/* {renderSubTabs()} */}

      <Box maxWidth="1140px" mb={4} mt={isViewerOwner ? 0 : 4} mx="auto" position="relative">
        {renderPageTip()}

        <InviteModal
          isOpen={inviteModalData.open}
          template={fundsDistributionMethod === TEAM ? "PARENT" : inviteModalData.template}
          onDismiss={() => setInviteModalData((old) => ({ ...old, open: false }))}
        />

        {renderUpperCTA()}

        <MemberListGrouped
          activeReassignmentMemberId={activeReassignmentMemberId}
          campaign={campaign}
          refetch={refetch}
          setActiveReassignmentMemberId={setActiveReassignmentMemberId}
          setInviteModalData={setInviteModalData}
        />
        <Flex bottom={["50px", "30px"]} justifyContent="center" p={4} position="sticky">
          <Button
            borderRadius="20px"
            boxShadow="floatingButton"
            onClick={() => {
              setInviteModalData({
                open: true,
                template: fundsDistributionMethod === TEAM ? "PARENT" : inviteModalData.template,
              });
            }}
          >
            Send Invitations
          </Button>
        </Flex>
      </Box>
    </>
  );
}

// == Styles ===============================================================

const EImageListItem = styled(ImageListItem)`
  &:hover {
    .image-list-item-react__title {
      color: ${theme.colors.primary};
    }
  }
`;
