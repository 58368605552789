/* eslint-disable import/prefer-default-export */

import gql from "graphql-tag";
import { ActivityFragment } from "@queries";

export const ACTIVITIES_QUERY = gql`
  query ActivitiesQuery(
    $feed: FeedType
    $first: Int
    $after: String
    $publicView: Boolean = false
  ) {
    Activities(feed: $feed, first: $first, after: $after) {
      edges {
        cursor
        node {
          ...ActivityFragment
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
  ${ActivityFragment}
`;
