import React from "react";
import { Provider } from "mobx-react";
import { ApolloProvider } from "react-apollo";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";
import { FlipgiveStore } from "@stores";
import { SwitchTeamQuery } from "./SwitchTeamQuery";

interface IProps {
  currentUser: string;
  newCampaignUrl: string;
  campaignMembersUrl: string;
  joinCampaignUrl: string;
  switchTeamUrl: string;
  personalUserUrl: string;
  editCampaignUrl: string;
  userFundsUrl: string;
  rootUrl: string;
  environment: string;
}

export const SwitchTeamApp = (props: IProps) => {
  Settings.setDefaultsFromProps(props);
  const {
    currentUser,
    campaignMembersUrl,
    editCampaignUrl,
    joinCampaignUrl,
    newCampaignUrl,
    personalUserUrl,
    rootUrl,
    switchTeamUrl,
    userFundsUrl,
    environment,
  } = props;

  FlipgiveStore.setCurrentUser(currentUser);
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient(Settings.apiUrl)}>
        <Provider FlipgiveStore={FlipgiveStore}>
          <SwitchTeamQuery
            campaignMembersUrl={campaignMembersUrl}
            editCampaignUrl={editCampaignUrl}
            environment={environment}
            joinCampaignUrl={joinCampaignUrl}
            newCampaignUrl={newCampaignUrl}
            personalUserUrl={personalUserUrl}
            rootUrl={rootUrl}
            switchTeamUrl={switchTeamUrl}
            userFundsUrl={userFundsUrl}
          />
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
