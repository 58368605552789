import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@atoms";
// == Types ================================================================

interface IProps {
  loading: boolean;
  delay?: number;
}

// == Constants ============================================================

CircleLoader.defaultProps = {
  delay: 2000,
  loading: true,
};

// == Component ============================================================

export function CircleLoader({ delay, loading }: IProps) {
  const [stateLoaded, setLoading] = useState(false);
  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setLoading(true);
      }, delay);
    }
  }, [delay]);

  return (
    <div
      className={`circle-loader ${stateLoaded || !loading ? "load-complete" : ""}`}
      data-test-id="circle-loader"
    >
      <Box className="checkmark draw" display={stateLoaded || !loading ? "block!important" : ""} />
    </div>
  );
}

// == Styles ===============================================================
