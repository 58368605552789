import React from "react";
import { shape, string } from "prop-types";

const SwitchTeamHelperButton = ({ campaign, url, buttonText }) => (
  <div className="col">
    <a href={url.replace("campaign_id", campaign.id)}>
      <button className="btn btn-block">{buttonText}</button>
    </a>
  </div>
);

export default SwitchTeamHelperButton;

SwitchTeamHelperButton.propTypes = {
  campaign: shape({}).isRequired,
  url: string.isRequired,
  buttonText: string.isRequired,
};
