import React, { useState } from "react";
import gql from "graphql-tag";
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import { useMutation } from "react-apollo";
import { ApolloError } from "apollo-client";
import styled from "@emotion/styled";
import { Modal, Box, Flex, Text, TouchableOpacity, ChevronLeft, Button } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { ErrorService } from "@services";
import {
  ReassignMemberMutation,
  ReassignMemberMutationVariables,
} from "./__generated__/ReassignMemberMutation";

// == Types ================================================================

interface IProps {
  id: string;
  name: string;
  groupedFundraisers: $FixMe;
  currentGroup: $FixMe;
  refetch: () => void;
  activeReassignmentMemberId: string | null;
  setActiveReassignmentMemberId: (activeReassignmentMemberId: string | null) => void;
}

// == Constants ============================================================

const REASSIGN_MEMBER_MUTATION = gql`
  mutation ReassignMemberMutation($id: ID!, $groupId: ID) {
    FundraiserSetGroup(id: $id, groupId: $groupId) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Fundraiser {
        id
        name
        group {
          id
          name
          amountRaised {
            dollarsAmount
          }
        }
      }
    }
  }
`;

// == Component ============================================================

export default function ReassignMember({
  activeReassignmentMemberId,
  currentGroup,
  groupedFundraisers,
  id,
  name,
  refetch,
  setActiveReassignmentMemberId,
}: IProps) {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const filteredGroupedFundraisers = groupedFundraisers.filter(
    (group) => group.id !== "__pending__" && currentGroup?.id !== group.id
  );
  const primary = useStoreAppValue()?.theme?.color?.primary ?? "#27B7BA";

  const onError = (error?: ApolloError) => {
    if (error) ErrorService.error(error);
    Materialize?.toast?.(
      "There was an ERROR reassigning this member. Please try again.",
      4000,
      "rounded toast-danger"
    );
    setActiveReassignmentMemberId(null);
  };

  const [createReassignMemberMutation, { loading }] = useMutation<
    ReassignMemberMutation,
    ReassignMemberMutationVariables
  >(REASSIGN_MEMBER_MUTATION, {
    onError,
    onCompleted: ({ FundraiserSetGroup }) => {
      if (FundraiserSetGroup.__typename === "MutationError") {
        onError();
        return;
      }
      const { name, group: returnedGroup } = FundraiserSetGroup;
      Materialize?.toast?.(
        `${name} has been successfully reassigned ${
          returnedGroup?.name ? `to ${returnedGroup?.name}` : ``
        }`,
        4000,
        "rounded toast-success"
      );
      setActiveReassignmentMemberId(null);
      refetch();
    },
  });

  const genericGroupIdMaker = (selectedGroup) => {
    if (selectedGroup.id === "NO_GROUP") return null;
    if (selectedGroup.id === "__pending__") return null;
    return selectedGroup.id;
  };

  const onSubmit = () => {
    createReassignMemberMutation({
      variables: { id, groupId: genericGroupIdMaker(selectedGroup) },
    });
  };

  return (
    <Modal
      isOpen={activeReassignmentMemberId === id}
      onDismiss={() => setActiveReassignmentMemberId(null)}
    >
      <Flex alignItems="center" flexDirection="column" justifyContent="center" padding={3}>
        <Flex alignSelf="flex-start" paddingBottom={2}>
          <TouchableOpacity onTouch={() => setActiveReassignmentMemberId(null)}>
            <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
          </TouchableOpacity>
          <Box paddingLeft={[2, 3]}>
            <Text fontWeight="bold">Reassign {name}</Text>
          </Box>
        </Flex>
        <Flex alignItems="center" flexDirection="column" py={3} width={1}>
          <Menu>
            <Flex>
              <EMenuButton>
                <EInput
                  disabled
                  value={
                    selectedGroup?.id === "NO_GROUP"
                      ? "Unassigned"
                      : selectedGroup?.name ?? `Select Player`
                  }
                />
                <ESpan aria-hidden>▾</ESpan>
                <EText color="placeholderColor" fontSize={0} pt={1}>
                  Player
                </EText>
              </EMenuButton>
            </Flex>
            <EMenuList className="slide-down">
              {filteredGroupedFundraisers.map((group) => {
                if (!group) return null;
                return (
                  <MenuItem
                    key={`${group.id}-item`}
                    onSelect={() => {
                      setSelectedGroup(group);
                    }}
                  >
                    <Flex alignItems="center">
                      <Text fontSize={[1, 2]} paddingLeft={[1, 2, 3]}>
                        {group?.id === "NO_GROUP" ? "Unassigned" : group?.name}
                      </Text>
                    </Flex>
                  </MenuItem>
                );
              })}
            </EMenuList>
          </Menu>
        </Flex>
        <Flex py={3} width={0.6}>
          <Text color="subText" fontSize={1}>
            Please note reassigning supporter will affect earning reports.
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="center" py={2}>
          <Button height={50} minWidth={200} width={0.8} onClick={onSubmit}>
            Reassign
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

// == Styles ===============================================================

const EMenuList = styled(MenuList)`
  z-index: 1000;
  position: relative;
  overflow-y: auto;
  max-height: 200px;
  > [data-reach-menu-item]:hover {
    background: ${({ theme }) => theme.colors.primary};
  }
  > [data-reach-menu-item][data-selected] {
    background: ${({ theme }) => theme.colors.primary};
  }
  > [data-reach-menu-item][data-selected] span {
    color: white !important;
  }
  > [data-reach-menu-item]:hover span {
    color: white !important;
  }
`;

const EMenuButton = styled(MenuButton)`
  padding: 0;
  width: 300px;
  display: block;
  background: none;
  border: none;
  position: relative;
`;

const EInput = styled.input`
  margin-bottom: 0 !important;
  padding-top: 10px !important;
  border-color: ${({ theme }) => theme.colors.gray}!important;
  color: ${({ theme }) => theme.colors.default}!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 24px;
`;

const ESpan = styled.span`
  position: absolute;
  right: 0;
  top: 33%;
  margin-right: 10px;
`;

const EText = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 10px;
`;
