import * as FullStory from "@fullstory/browser";
import { ICurrentUser } from "./context";
import { FullStoryEventNames } from "@services";
import { useUserAgent } from "./useUserAgent";

export const useFullStory = (currentUser: ICurrentUser) => {
  if (
    (process.env.SENTRY_ENV === "production" || process.env.SENTRY_ENV === "staging") &&
    process.env.FULLSTORY_ORG_ID &&
    !FullStory.isInitialized()
  ) {
    FullStory.init({
      orgId: process.env.FULLSTORY_ORG_ID,
    });
  }

  if (currentUser && FullStory.isInitialized()) {
    FullStory.identify(currentUser.id, {
      displayName: currentUser.name,
      email: currentUser.email,
    });
  }
};

export const useFullStoryEvents = () => {
  const { device, platform, browser } = useUserAgent();

  const sendFullStoryEvent = (
    eventName: FullStoryEventNames,
    properties?: Record<string, string>
  ) => {
    if (!process.env.FULLSTORY_ORG_ID) {
      console.log(eventName, {
        eventName,
        device,
        platform,
        browser,
        ...properties,
      });
      return;
    }

    if (!FullStory.isInitialized()) {
      FullStory.init({
        orgId: process.env.FULLSTORY_ORG_ID,
      });
    }

    FullStory.event(eventName, {
      eventName,
      device,
      platform,
      browser,
      ...properties,
    });
  };

  return {
    sendFullStoryEvent,
  };
};
