import React, { Dispatch, SetStateAction } from "react";
import capitalize from "lodash/capitalize";
import { Flex, Text, TouchableOpacity, Container, Box } from "@atoms";
import { theme } from "@styles";

// == Types ================================================================

interface IProps {
  activeTab: FUNDS_TABS_ENUM;
  setTab: Dispatch<SetStateAction<FUNDS_TABS_ENUM>>;
}

// == Constants ============================================================

export enum FUNDS_TABS_ENUM {
  EARNINGS = "EARNINGS",
  PAYOUTS = "PAYOUTS",
}

// == Component ============================================================

const {
  colors: { darkerPrimary, lightBg },
} = theme;

const { EARNINGS, PAYOUTS } = FUNDS_TABS_ENUM;

export function FundsPageTabs({ activeTab, setTab }: IProps) {
  return (
    <Box bg={lightBg} py={2}>
      <Container>
        <Flex
          width={["100%", "100%", "30%"]}
          margin="0 auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TouchableOpacity
            containerProps={{ style: { width: "100%" } }}
            py={3}
            textAlign="center"
            width={1}
            onTouch={() => setTab(EARNINGS)}
          >
            <Text color={activeTab === EARNINGS ? darkerPrimary : ""}>{capitalize(EARNINGS)}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            containerProps={{ style: { width: "100%" } }}
            py={3}
            textAlign="center"
            width={1}
            onTouch={() => setTab(PAYOUTS)}
          >
            <Text color={activeTab === PAYOUTS ? darkerPrimary : ""}>{capitalize(PAYOUTS)}</Text>
          </TouchableOpacity>
        </Flex>
      </Container>
    </Box>
  );
}

// == Styles ===============================================================
