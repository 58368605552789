import React, { useMemo, useState } from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { keyframes, css } from "@emotion/core";
import styled from "@emotion/styled";
import { CircleLoader } from "@components/common/atoms/CircleLoader";
import { BrandLogo } from "@organisms";
import { FadeOut, getPaginatedNodes } from "@utils";
import { Box, Flex, Text, H3 } from "@atoms";
import { LinkType } from "@types";

// == Types ================================================================
interface IProps {
  giftcardIntent: $FixMe;
  givePercent: string;
  logoUrl: string;
  name?: string;
}

type TProps = RouteComponentProps<IProps>;

// == Constants ============================================================

IntentLoadingView.defaultProps = {};
IntentLoadingView.fragments = gql`
  fragment IntentLoadingViewFragment on GiftcardIntent {
    id
    token
    buyerName
    deliveryEstimate
    orderTotal {
      absFormatted
      absCentsAmount
      absDollarsAmount
      centsAmount
    }
    link {
      id
      giveAmount
      type
      defaultBrand {
        id
        name
        ...BrandLogoFragment
      }
      giftcardDenominations {
        edges {
          cursor
          node {
            id
            deliveryEstimate
            value {
              absFormatted
              absCentsAmount
              absDollarsAmount
              centsAmount
            }
          }
        }
      }
    }
    campaign {
      id
      name
    }
  }
  ${BrandLogo.fragments.fields}
`;

// == Component ============================================================

export default function IntentLoadingView({ giftcardIntent }: TProps) {
  let displayGive = "";
  let brand = null;

  if (giftcardIntent) {
    const {
      link: { giveAmount, defaultBrand },
    } = giftcardIntent;
    displayGive = giveAmount;
    brand = defaultBrand;
  }

  const giftcardDeliveryEstimate = useMemo(() => {
    const denominations = getPaginatedNodes(giftcardIntent?.link?.giftcardDenominations?.edges);
    const itemPrice = giftcardIntent?.orderTotal?.absDollarsAmount || 0;
    const selectedAmount = denominations.find(
      (denomination) => denomination.value.absDollarsAmount === itemPrice
    );
    return selectedAmount?.deliveryEstimate;
  }, [giftcardIntent]);

  const renderBeneficiary = () => {
    if (!giftcardIntent) return "";
    const { campaign, buyerName: name } = giftcardIntent;
    const campaignName = campaign?.name;

    if (!name && !campaignName) return "";
    if (!name && campaignName) return campaignName;
    if (name && !campaignName) return name;
    return `${name} - ${campaignName}`;
  };

  return (
    <div className="full-screen-body full-screen-body--centered centered">
      <Flex
        alignItems="center"
        alignSelf="stretch"
        bg="white"
        flex="1"
        flexDirection="column"
        justifyContent="center"
      >
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
          position="relative"
        >
          <Box mt={5} mx={2} textAlign="center" zIndex={1}>
            {giftcardIntent ? (
              <div className="intent-checkmark-and-logo">
                <div className="intent-logo-container">
                  <FadeOut delay={1800} duration={200}>
                    <BrandLogo brand={brand} className="intent-logo" p={2} withBorder={false} />
                  </FadeOut>
                </div>
                <CircleLoader delay={2000} />
              </div>
            ) : (
              <CircleLoader delay={null} />
            )}
            <EContentFadeBox loaded={!!giftcardIntent} textAlign="center">
              <H3 fontWeight="bold" mb={2}>
                Purchase {giftcardIntent.link.type === LinkType.VOUCHER ? "Voucher" : "Giftcard"} to
                earn {displayGive}
              </H3>
              <Text>{giftcardDeliveryEstimate ?? "Orders will be delivered immediately"}</Text>
            </EContentFadeBox>
          </Box>
        </Flex>
        <Flex alignItems="center" bg="lightPrimaryBg" justifyContent="center" py={4} width="100%">
          <H3 color="black" fontWeight="bold">
            Taking you to checkout
            <span
              css={css`
                animation: ${showHideDot} 1s ease infinite;
                animation-delay: 0.2s;
              `}
            >
              .
            </span>
            <span
              css={css`
                animation: ${showHideDot} 1s ease infinite;
                animation-delay: 0.2s;
              `}
            >
              .
            </span>
            <span
              css={css`
                animation: ${showHideDot} 1s ease infinite;
                animation-delay: 0.4s;
              `}
            >
              .
            </span>
          </H3>
        </Flex>
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mt={3}
          textAlign="center"
        >
          {/* <Text fontSize={0} color="black">
          Activity #15843
        </Text> */}
          <Text color="primary" fontWeight="bold">
            {renderBeneficiary()}
          </Text>
        </Flex>
      </Flex>
    </div>
  );
}

// == Styles ===============================================================
const showHideDot = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const EContentFadeBox = styled(Box)`
  transition: opacity 0.3s ease-in;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
`;
