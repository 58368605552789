import React from "react";
import { NotificationsContext } from "../NotificationsContext";

const UnseenCount = () => (
  <NotificationsContext.Consumer>
    {({ unseenCount }) =>
      unseenCount > 0 ? <span className="notifications-header__count">{unseenCount}</span> : null
    }
  </NotificationsContext.Consumer>
);

export default UnseenCount;
