import React from "react";
import gql from "graphql-tag";
import { GiveSentence } from "@utils";
import { constants } from "@services";
import { BrandModalLinkListItem } from "../BrandModalLinkListItem";
import { BrandModalInStoreLinkFragment } from "./__generated__/BrandModalInStoreLinkFragment";
// import styled from "@emotion/styled";

// == Types ================================================================
type TLink = BrandModalInStoreLinkFragment;

interface IProps {
  link: TLink | null;
  isFirst?: boolean;
  onClick: (link: TLink, locationId?: string) => void;
  locationId?: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment BrandModalInStoreLinkFragment on Link {
      id
      giveSentence
      positiveGiveAmount
      baselineGiveAmount
    }
  `,
};

const DEFAULT_PROPS = {
  isFirst: false,
};

// == Component ============================================================

const BrandModalInStoreLink = ({ locationId, link, onClick, isFirst }: IProps) => {
  if (!link) return null;
  const giveSentence = GiveSentence({
    positiveGiveAmount: link.positiveGiveAmount,
    baselineGiveAmount: link.baselineGiveAmount,
    giveSentence: link.giveSentence,
  });

  const onLinkClick = () => {
    onClick(link, locationId);
  };

  const description = locationId
    ? "Activate this offer and use a linked payment card to purchase in-store."
    : constants.NEARBY_LINKED_CARD_DESCRIPTION;

  return (
    <BrandModalLinkListItem
      description={description}
      giveSentence={giveSentence}
      leftImage="https://flipgive.twic.pics/images/site/shop/brand-modal-in-store.png"
      linkId={link.id}
      locationId={locationId}
      showBorder={!isFirst}
      title="Shop In-Store"
      onClick={onLinkClick}
    />
  );
};

BrandModalInStoreLink.fragments = FRAGMENTS;
BrandModalInStoreLink.defaultProps = DEFAULT_PROPS;

export default BrandModalInStoreLink;

// == Styles ===============================================================
