import React from "react";
// import styled from "@emotion/styled";
import { Box, Text, Link, Button } from "@atoms";
import { RailsUrl } from "@services";
// == Types ================================================================

// == Component ============================================================

export default function IntentListEmpty() {
  return (
    <Box pt={3} textAlign="center">
      <Text display="block">You haven&apos;t earned for any teams yet.</Text>
      <Link as="a" href={RailsUrl.rootUrl}>
        <Button mt={3}>Shop Now</Button>
      </Link>
    </Box>
  );
}
// == Styles ===============================================================
