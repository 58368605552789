import React, { useMemo } from "react";
import styled from "@emotion/styled";
import queryString from "query-string";
import { useLoadScript } from "@react-google-maps/api";
import { Box, Flex } from "@components/common/atoms";
import { ShopSubTabs } from "@components/common/organisms";
import { NearbyViewProvider, useStoreAppValue } from "@hooks";
import { constants } from "@services";
import { NearbyMap } from "../NearbyMap";
import { NearbySearch } from "../NearbySearch";
// == Types ================================================================

interface IProps {}

// == Constants ============================================================

NearbyView.defaultProps = {};

// == Component ============================================================

const googleMapsLibraries = ["places"];

export default function NearbyView({ location }: IProps) {
  const { mapLocation, currency, gmapsKey } = useStoreAppValue();
  const queryParams = queryString.parse(location.search);
  const initialCoordinates = mapLocation
    ? {
        latitude: mapLocation.latitude,
        longitude: mapLocation.longitude,
        address: mapLocation.city,
      }
    : currency === "USD"
    ? constants.SAN_FRANCISCO_LAT_LNG
    : constants.TORONTO_LAT_LNG;
  const initialState = {
    coordinates: initialCoordinates,
    initialCoordinates,
    query: "",
    distance:
      queryParams.locationFilter === "ALL"
        ? constants.DISTANCES_ENUM.ALL_LOCATIONS
        : constants.DISTANCES_ENUM.DEFAULT_DISTANCE,
    // brandId: $brandId,
    linkCategoryFilter: null,
    results: [],
    loaded: false,
    brandId: null,
    location,
    searching: true,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: gmapsKey,
    libraries: googleMapsLibraries,
    mapTypeControl: false,
  });

  const urlLocation = useMemo(() => {
    return location;
  }, [location]);

  if (!isLoaded) return null;
  return (
    <>
      <ShopSubTabs showSearchBar={false} />
      <NearbyViewProvider initialState={initialState}>
        <Flex>
          <ESearchBox>
            <NearbySearch location={urlLocation} />
          </ESearchBox>
          <EMapBox>
            <NearbyMap />
          </EMapBox>
        </Flex>
      </NearbyViewProvider>
    </>
  );
}

// == Styles ===============================================================

const ESearchBox = styled(Box)`
  height: calc(100vh - (70px + 54.11px));
  width: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    height: calc(100vh - 112px);
    width: 550px;
    border-right: ${({ theme }) => theme.borders.silver};
  }
`;

const EMapBox = styled(Box)`
  height: calc(100vh - (70px + 54.11px));
  width: 100%;
  display: none;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    height: calc(100vh - 112px);
    display: block;
    width: calc(100% - 550px);
  }
`;
