import React, { useEffect } from "react";
import { Location, LocationContext, navigate as importedNavigate } from "@reach/router";
import queryString from "query-string";
import { Modal } from "@atoms";
import { constants, RailsUrl } from "@services";
import {
  LinkModal,
  LinkModalQuery,
  BrandModal,
  FavoritesModalQuery,
  InStoreModalQuery,
  SearchModal,
} from "@organisms";
import { useStoreAppValue, useKeyPress } from "@hooks";

// == Types ================================================================

interface IProps {
  campaignId?: string;
}

// == Constants ============================================================

// == Component ============================================================

const RenderModals = ({ campaignId }: IProps) => {
  const {
    featureFlags: { newCollection },
  } = useStoreAppValue();
  const escPressed = useKeyPress("Escape");

  const onClick = (e) => {
    const { linkId, isBrand, tinyLinkKey } = e.target.closest(
      ".story-brands__collection-card.modalBtn"
    ).dataset;

    if (isBrand === "true") {
      importedNavigate(RailsUrl.brandModalUrl({ id: linkId }), {
        state: { tinyLinkKey, canReturn: true },
      });
    } else {
      importedNavigate(RailsUrl.linkModalUrl({ id: linkId }), {
        state: { tinyLinkKey, canReturn: true },
      });
    }

    e.preventDefault();
  };

  useEffect(() => {
    const modalBtns = document.querySelectorAll(".modalBtn");
    modalBtns.forEach((btn) => {
      btn.addEventListener("click", onClick);
    });
  }, []);

  const renderModal = ({ location, navigate }: LocationContext) => {
    const queryParams = queryString.parse(location.search);
    const params = Object.keys(queryParams);
    const onDismiss = () => {
      navigate(location.pathname);
    };

    if (
      params.includes(constants.linkParam) ||
      params.includes(constants.brandParam) ||
      (!!newCollection && params.includes(constants.inStoreParam)) ||
      params.includes("modal")
    ) {
      if (escPressed) {
        onDismiss();
      }

      return (
        <Modal
          data-testid="linkModal"
          size={
            params.includes("modal") && queryParams.modal === constants.favoritesParam
              ? "small"
              : "default"
          }
          onDismiss={onDismiss}
        >
          {!!newCollection && !!queryParams[constants.inStoreParam] && (
            <InStoreModalQuery
              campaignId={campaignId}
              linkId={queryParams[constants.inStoreParam] as string}
              locationId={queryParams[constants.locationParam] as string}
            />
          )}
          {!!queryParams[constants.linkParam] && (
            <LinkModalQuery
              campaignId={campaignId}
              linkId={queryParams[constants.linkParam] as string}
              locationId={queryParams[constants.locationParam] as string}
            />
          )}

          {!!queryParams[constants.brandParam] && !queryParams[constants.linkParam] && (
            <BrandModal
              brandId={queryParams[constants.brandParam] as string}
              locationId={queryParams[constants.locationParam] as string}
            />
          )}

          {queryParams.modal === constants.favoritesParam && <FavoritesModalQuery />}
          {queryParams.modal === constants.searchParam && <SearchModal />}
        </Modal>
      );
    }
    return null;
  };

  return (
    <Location>
      {({ location, navigate }: LocationContext) => renderModal({ location, navigate })}
    </Location>
  );
};

export default RenderModals;

// == Styles ===============================================================
