export enum CustomCollectionsIds {
  search = "-1",
  favorites = "-2",
  nearby = "-3",
}

export const customCollectionsNames = {
  ["-1"]: "search",
  ["-2"]: "favorites",
  ["-3"]: "nearby",
};
