import React, { useMemo } from "react";
import gql from "graphql-tag";
import queryString from "query-string";
import styled from "@emotion/styled";
import { groupBy, sortBy } from "lodash";
import { parseISO, format } from "date-fns";
import { NetworkStatus } from "apollo-client";
import { Box, Text, Heading, SearchIcon, Button } from "@atoms";
import { GiftcardType } from "@types";
import { DropDown, Spinner } from "@molecules";
import { getPaginatedNodes } from "@utils";
import { GiftcardListItem } from "../components";
import { IReducerState } from "./GiftcardListPageQuery";
import {
  GiftcardListPageFragment,
  GiftcardListPageFragment_giftcardsPaginated_edges_node,
} from "./__generated__/GiftcardListPageFragment";

// == Types ================================================================
type TGiftcard = GiftcardListPageFragment_giftcardsPaginated_edges_node;
interface IProps {
  viewer?: GiftcardListPageFragment;
  state: IReducerState;
  dispatch: $FixMe;
  hasNoFilter: boolean;
  onFetchMore: () => void;
}

// == Constants ============================================================

GiftcardListPage.fragment = gql`
  fragment GiftcardListPageFragment on Viewer {
    id
    giftcardsPaginated(
      first: $first
      after: $after
      giftcardTypes: $giftcardTypes
      nameContains: $query
      sortBy: $sortBy
      withBalance: $withBalance
    )
      @connection(
        key: "viewerGiftcards"
        filter: ["giftcardTypes", "nameContains", "sortBy", "withBalance"]
      ) {
      edges {
        cursor
        node {
          id
          ...GiftcardListItemFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${GiftcardListItem.fragment}
`;
GiftcardListPage.defaultProps = {};

const SORT_OPTIONS = [
  { label: "Date", slug: "DATE" },
  { label: "Name", slug: "NAME" },
  { label: "Balance", slug: "BALANCE" },
];

const SORT_BY_DATE = (giftcards: TGiftcard[]) => {
  const groupedGiftcards = groupBy(giftcards, (giftcard) => {
    // group by Jan 2020
    return format(parseISO(giftcard.transaction?.createdAt), "MMM yyyy");
  });

  return Object.keys(groupedGiftcards).map((key) => {
    return {
      title: key,
      data: groupedGiftcards[key],
    };
  });
};

const SORT_BY_NAME = (giftcards: TGiftcard[]) => {
  const sortedGiftcards = sortBy(giftcards, "merchant.name");
  const groupedGiftcards = groupBy(sortedGiftcards, (giftcard) => giftcard?.merchant?.name);
  return Object.keys(groupedGiftcards).map((key) => {
    return {
      title: key,
      data: groupedGiftcards[key],
    };
  });
};

const BALANCE_OPTIONS = [
  "$100+",
  "$76 - $100",
  "$51 - $75",
  "$26 - $50",
  "$1 - $25",
  "$0",
  "No Balance",
];

interface IOptions {
  title: string;
  data: TGiftcard[];
}

const SORT_BY_BALANCE = (giftcards: TGiftcard[]) => {
  const groupedGiftcards = groupBy(giftcards, (giftcard) => {
    if (!giftcard?.balance?.dollarsAmount && giftcard?.balance?.dollarsAmount !== 0)
      return BALANCE_OPTIONS[6]; // "No Balance";

    const balance = giftcard.balance.dollarsAmount;
    if (balance > 100) return BALANCE_OPTIONS[0]; // "$100+"
    if (balance > 75) return BALANCE_OPTIONS[1]; // $76 - $100"
    if (balance > 50) return BALANCE_OPTIONS[2]; // "$51 - $75"
    if (balance > 25) return BALANCE_OPTIONS[3]; // "$26 - $50"
    if (balance > 0) return BALANCE_OPTIONS[4]; // "$1 - $25"
    if (balance === 0) return BALANCE_OPTIONS[5]; // "$0"
    return BALANCE_OPTIONS[6]; // "No Balance"
  });

  return BALANCE_OPTIONS.reduce((options: IOptions[], currentOption) => {
    if (groupedGiftcards[currentOption]) {
      options.push({
        title: currentOption,
        data: groupedGiftcards[currentOption],
      });
    }
    return options;
  }, []);
};

const BUILD_STRING_LIST = (list: string[]) => {
  if (list.length === 0) return "";
  if (list.length === 1) return list[0];
  list[list.length - 1] = `or ${list[list.length - 1]}`;
  return list.join(", ");
};

// == Component ============================================================

export default function GiftcardListPage({
  viewer,
  state,
  dispatch,
  hasNoFilter,
  onFetchMore,
}: IProps) {
  const hasNextPage = viewer?.giftcardsPaginated.pageInfo.hasNextPage;
  const loadingMore = state.currentNetworkStatus === NetworkStatus.fetchMore;
  const giftcards = getPaginatedNodes(viewer?.giftcardsPaginated?.edges);

  const queryParams = queryString.parse(location.search);
  const params = Object.keys(queryParams);
  const showGiftcardWarning = params.includes("showGiftcardWarning");

  const formattedGiftcards = useMemo(() => {
    if (state.currentSort === "DATE") return SORT_BY_DATE(giftcards);
    if (state.currentSort === "NAME") return SORT_BY_NAME(giftcards);
    if (state.currentSort === "BALANCE") return SORT_BY_BALANCE(giftcards);
    return [{ title: "All Giftcards", data: giftcards }];
  }, [state.currentSort, giftcards]);

  const onSortSelect = (slug: string) => {
    dispatch({ type: "UPDATE_SORT", sort: slug });
  };

  const onToggleFilter = (filter) => {
    if (state.filters.includes(filter)) {
      dispatch({ type: "REMOVE_FILTER", filter });
    } else {
      dispatch({ type: "ADD_FILTER", filter });
    }
  };

  const onSearchInput = (e) => {
    dispatch({ type: "UPDATE_SEARCH", query: e.target.value });
  };

  function renderEmpty() {
    let formattedTypes = [];
    if (state.filters.length === 0) {
      formattedTypes = ["giftcards", "vouchers"];
    } else {
      const someGiftcards = state.filters.some((filter: GiftcardType) =>
        [GiftcardType.RELOADABLE, GiftcardType.GIFTCARD].includes(filter)
      );
      if (someGiftcards) formattedTypes.push("giftcards");
      if (state.filters.includes(GiftcardType.VOUCHER)) formattedTypes.push("vouchers");
    }

    return (
      <Text fontSize={3}>
        You haven&apos;t purchased any {BUILD_STRING_LIST(formattedTypes)} yet
      </Text>
    );
  }

  return (
    <Box mx="auto" width={["100%", 500, 600]}>
      <Heading className="sr-only" mb={4} textAlign="center">
        FlipGive Wallet
      </Heading>
      <Box mb={3} position="relative">
        <EText color="primary">
          <SearchIcon />
        </EText>
        <EInput placeholder="Search for a brand" onChange={onSearchInput} />
      </Box>

      <Box mb={4}>
        <DropDown
          customButton
          customButtonProps={{ fontSize: 0, px: 2, py: 1, variant: "clear" }}
          items={SORT_OPTIONS}
          label={`Sort by ${state.currentSort.toLowerCase()}`}
          labelProps={{ color: "primary" }}
          selected={state.currentSort}
          onSelect={onSortSelect}
        />
        <Button
          fontSize={0}
          ml={2}
          px={2}
          py={1}
          variant={state.filters.includes(GiftcardType.GIFTCARD) ? "primary" : "clear"}
          onClick={() => onToggleFilter(GiftcardType.GIFTCARD)}
        >
          Digital
        </Button>
        <Button
          fontSize={0}
          ml={2}
          px={2}
          py={1}
          variant={state.filters.includes(GiftcardType.RELOADABLE) ? "primary" : "clear"}
          onClick={() => onToggleFilter(GiftcardType.RELOADABLE)}
        >
          Reloadable
        </Button>
        <Button
          fontSize={0}
          ml={2}
          px={2}
          py={1}
          variant={state.filters.includes(GiftcardType.VOUCHER) ? "primary" : "clear"}
          onClick={() => onToggleFilter(GiftcardType.VOUCHER)}
        >
          Voucher
        </Button>
        <Button
          fontSize={0}
          ml={2}
          px={2}
          py={1}
          variant={!state.withBalance ? "primary" : "clear"}
          onClick={() => dispatch({ type: "TOGGLE_BALANCE" })}
        >
          Show Empty
        </Button>
      </Box>
      {showGiftcardWarning && (
        <Text display="block" mb={3} mt={-2} textAlign="center" variant="legal">
          If you just purchased your card it may take a few hours to be delivered. If it's not
          listed below please wait and check again (click reload to update the wallet).
        </Text>
      )}

      {formattedGiftcards.map((giftcardSection) => {
        return (
          <>
            <Box bg="lightGrayBg" mb={3} p={3}>
              <Text>{giftcardSection.title}</Text>
            </Box>
            {giftcardSection.data.map((giftcard) => {
              return <GiftcardListItem giftcard={giftcard} key={giftcard.id} />;
            })}
          </>
        );
      })}
      {[NetworkStatus.loading, NetworkStatus.fetchMore].includes(state.currentNetworkStatus) && (
        <Spinner />
      )}
      {hasNextPage && (
        <Box mt={3} textAlign="center">
          <Button disabled={loadingMore} onClick={onFetchMore}>
            {loadingMore ? "Loading..." : "Load More"}
          </Button>
        </Box>
      )}
      {giftcards.length === 0 && state.loaded && (
        <Box p={[3, 5]} textAlign="center">
          {hasNoFilter ? (
            <Text fontSize={3}>
              Your wallet is empty, purchase a gift card for it to show up here.
            </Text>
          ) : (
            renderEmpty()
          )}
        </Box>
      )}
    </Box>
  );
}

// == Styles ===============================================================

const EText = styled(Text)`
  position: absolute;
  left: 16px;
  top: 22px;
`;

const EInput = styled.input`
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 45px !important;
  border-color: ${({ theme }) => theme.colors.gray}!important;
`;
