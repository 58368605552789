import React, { ReactNode } from "react";
// import styled from "@emotion/styled";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { ArrowCarousel } from "../ArrowCarousel";

// == Types ================================================================

interface IProps {
  children: ReactNode;
  itemLength: number;
  indent?: string;
}

// == Constants ============================================================

MultiItemCarousel.defaultProps = {
  indent: [0, 0, 0, -20, -35],
};

// == Component ============================================================

export default function MultiItemCarousel({ children, itemLength, indent, ...rest }: IProps) {
  return (
    <Carousel
      addArrowClickHandler
      infinite
      keepDirectionWhenDragging
      animationSpeed={1500}
      arrowLeft={<ArrowCarousel arrowDirection="left" indent={indent} />}
      arrowRight={<ArrowCarousel arrowDirection="right" indent={indent} />}
      breakpoints={{
        768: {
          slidesPerPage: 3,
          slidesPerScroll: 3,
        },
        992: {
          slidesPerPage: 4,
          slidesPerScroll: 4,
        },
      }}
      draggable={false}
      slidesPerPage={5}
      slidesPerScroll={5}
      {...rest}
    >
      {children}
    </Carousel>
  );
}

// == Styles ===============================================================
