import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToggle } from "@hooks";
import { Modal, Box, Flex, Text, Button } from "@atoms";

// == Types ================================================================

export enum InviteTemplate {
  PARENT = "PARENT",
  REMINDER = "REMINDER",
  SUPPORTER = "SUPPORTER",
}

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
  template?: InviteTemplate | null;
}

// == Constants ============================================================

const TEMPLATE_QUERY = gql`
  query TemplateQuery($template: InviteTemplateType) {
    InviteTemplate(template: $template) {
      id
      title
      subject
      bodyHTML
      bodyPlain
    }
    Campaign {
      id
      accessCode
    }
  }
`;

const SET_INVITED_SUPPORTERS_MUTATION = gql`
  mutation FundraiserSetInvitedSupporters {
    FundraiserSetInvitedSupporters {
      ... on Fundraiser {
        id
        hasInvitedSupporters
      }
    }
  }
`;

// == Component ============================================================

export default function InviteModal({ isOpen, onDismiss, template }: IProps) {
  if (!isOpen) return null;
  // Wanted to avoid actually doing query until modal is opened
  return <InviteModalQuery isOpen={isOpen} onDismiss={onDismiss} template={template} />;
}

function InviteModalQuery({ isOpen, onDismiss, template }: IProps) {
  const cleanTemplate =
    template && Object.values(InviteTemplate).includes(template) ? template : "SUPPORTER";
  const { data, loading } = useQuery(TEMPLATE_QUERY, { variables: { template: cleanTemplate } });
  const [setInvitedSupporters] = useMutation(SET_INVITED_SUPPORTERS_MUTATION);
  const [isCopied, toggleIsCopied] = useToggle(false);

  useEffect(() => {
    setInvitedSupporters();
  }, []);

  if (loading) return null;

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Box padding={0}>
        <div className="invite-top">
          <div className="invite-top-content">
            <h1>{data.InviteTemplate.title}</h1>
            <p className="invite-subheading">Share your team join code below.</p>

            <Flex mt={3} justifyContent="center">
              <Box
                bg="white"
                border="silver"
                borderRadius={["4px 0 0 4px"]}
                borderRight="none"
                p={2}
                style={{ position: "relative", minWidth: "200px" }}
              >
                <Text style={{ position: "absolute", top: "2px", fontSize: "11px" }}>
                  Your Team Code
                </Text>
                <Text
                  color="default"
                  fontWeight="bold"
                  style={{ display: "inline-block", transform: "translateY(9px)" }}
                >
                  {data.Campaign.accessCode}
                </Text>
              </Box>
              <CopyToClipboard text={data.Campaign.accessCode} onCopy={() => toggleIsCopied(true)}>
                <Button border="primary" borderRadius={["0 4px 4px 0"]} p={2} variant="promoClear">
                  {isCopied ? "Copied!" : "Copy"}
                </Button>
              </CopyToClipboard>
            </Flex>
          </div>
        </div>

        <div className="invite-or">
          <span>OR</span>
        </div>

        <div className="invite-email">
          <div className="invite-email-content">
            <p className="invite-subheading">Invite them using this email.</p>

            <div className="invite-email-subject">
              <span className="block-text d-block">Subject</span>
              <h3>{data.InviteTemplate.subject}</h3>
            </div>
            <div
              className="invite-email-body"
              dangerouslySetInnerHTML={{ __html: data.InviteTemplate.bodyHTML }}
            />
            <div className="invite-email-send">
              <button onClick={onDismiss} className="btn invite-email__close-button">
                Close
              </button>
              <a
                href={`mailto:?body=${encodeURIComponent(
                  data.InviteTemplate.bodyPlain.replace("&amp;", "&")
                )}&subject=${encodeURIComponent(
                  data.InviteTemplate.subject.replace("&amp;", "&")
                )}`}
                target="_blank"
                className="btn"
              >
                Edit &amp; Send
                {/* Preview Invite Email */}
              </a>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

// *** Nick wants to add in a "thanks for inviting ppl" modal after the "Edit & Send" CTA is pressed
// *** in the same vein as <LinkModalPostShop /> for <LinkModal />

// == Styles ===============================================================
