/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-for */

import React from "react";
import PropTypes from "prop-types";
import { MentionsInput, Mention } from "react-mentions";
import { Utils } from "@services";
import ImageResizer from "../../services/ImageResizer";
import YouTubePreview from "../YouTubePreview";
import SelectLinkInput from "./SelectLinkInput";

const { getYouTubeID } = Utils;

export default class NewPost extends React.Component {
  static propTypes = {
    onNewPostSubmit: PropTypes.func.isRequired,
    addingPost: PropTypes.bool,
    mentionUsers: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.any.isRequired, display: PropTypes.string.isRequired }),
    ).isRequired,
    current_user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      avatar_url: PropTypes.object.isRequired,
    }).isRequired,
    message: PropTypes.string.isRequired,
    updateMessage: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    campaignId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    addingPost: false,
    onFocus: () => {},
    onBlur: () => {},
  };

  state = {
    visibility: "team",
    visibility_label: "Message will be seen by teammates",
    photoBlob: null,
    imagePreviewUrl: null,
    youTubeId: null,
    isShowingYouTube: false,
    writingPost: false,
    showOverlay: false,
    isShowingLink: false,
  };

  componentDidMount() {
    $(this.photoIcon).tooltip({ delay: 50 });
    $(this.shoppingListIcon).tooltip({ delay: 50 });
    $(this.inviteIcon).tooltip({ delay: 50 });

    $(".dropdown-button").dropdown({
      constrainWidth: true,
    });
  }

  onMessageChange = ({ target }) => {
    this.props.updateMessage(target.value);
  };

  onYouTubeChange = (e) => {
    let youTubeId = getYouTubeID(e.target.value, { fuzzy: false });

    if (!youTubeId) {
      youTubeId = null;
    }

    this.setState({
      youTubeId,
    });
    this.clearPhotoState();
  };

  onOverlayClick = () => {
    this.setState({ showOverlay: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { message } = this.props;
    const youtubeInputVal =
      this.youtubeInput && this.youtubeInput.value ? this.youtubeInput.value : "";
    const youtubeId = getYouTubeID(youtubeInputVal, { fuzzy: false }) || "";
    const linkId = this.selectLinkComponent ? this.selectLinkComponent.selectValue() : null;

    this.props.onNewPostSubmit({
      message,
      linkId,
      youtubeId,
      photoBase64: this.state.imagePreviewUrl,
      visibility: this.state.visibility === "team" ? "TEAM" : "PUBLIC",
    });

    e.target.reset();

    this.clearPhotoState();
    this.clearYoutubeState();
    this.clearLinkSelectState();
    this.props.updateMessage("");
    this.updateVisibility("team");
    this.setState({ showOverlay: false });
  };

  clearPhotoState = () => {
    this.setState({
      photoBlob: null,
      imagePreviewUrl: null,
    });
  };

  removePhoto = (e) => {
    e.preventDefault();
    this.clearPhotoState();
  };

  updateVisibility = (visibility) => {
    const visibility_label =
      visibility === "public"
        ? "Message will be seen by everyone"
        : "Message will be seen by teammates";
    this.setState({ visibility, visibility_label });
  };

  handleVisibilityChange = (e) => {
    const visibility = e.target.checked ? "public" : "team";
    this.updateVisibility(visibility);
  };

  handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const resizer = new ImageResizer(file);
    const blob = await resizer.resize();
    this.setState({
      imagePreviewUrl: resizer.dataURL,
      photoBlob: blob,
      showOverlay: true,
    });
    this.clearYoutubeState();
    this.clearLinkSelectState();
  };

  clearYoutubeState = () => {
    if (this.youtubeInput) {
      this.youtubeInput.value = "";
    }

    this.setState({ youTubeId: null, isShowingYouTube: false });
  };

  clearLinkSelectState = () => {
    if (this.selectLinkComponent) {
      this.selectLinkComponent.clearInput();
    }
    this.setState({ isShowingLink: false });
  };

  isButtonDisabled = () => {
    if (
      this.props.addingPost ||
      (this.props.message.length === 0 &&
        this.state.photoBlob === null &&
        this.state.youTubeId === null)
    ) {
      return true;
    }
    return false;
  };

  toggleLink = (e) => {
    e.preventDefault();
    const newState = !this.state.isShowingLink;
    if (newState) {
      this.clearYoutubeState();
      this.clearPhotoState();
    } else {
      this.clearLinkSelectState();
    }
    this.setState({ isShowingLink: newState, showOverlay: true });
  };

  toggleYouTube = (e, shouldShowYoutube) => {
    e.preventDefault();
    if (shouldShowYoutube) {
      this.clearPhotoState();
      this.clearLinkSelectState();
    }
    this.showHideYouTube(shouldShowYoutube);

    setTimeout(() => {
      Materialize.updateTextFields(); // need to delay a bit to show changes
    }, 10);
  };

  showHideYouTube = (shouldShowYoutube) => {
    this.setState({ isShowingYouTube: shouldShowYoutube, showOverlay: true });
  };

  render() {
    const { current_user } = this.props;

    return (
      <div
        className={`timeline-post card mt-0 ${
          this.state.writingPost ? "timeline-post--writing" : ""
        }
        ${this.state.showOverlay ? "timeline-post--has-overlay" : ""}
        `}
      >
        <div className="timeline-post__overlay" onClick={this.onOverlayClick} />
        <form className="timeline-post__form" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="timeline-post__body">
            <div className="media">
              <img
                alt={current_user.name}
                className="d-flex mr-3 timeline-heading__image"
                src={
                  current_user.avatar_url.square_100 ||
                  "https://d2wkegjj8g6j4.cloudfront.net/images/uploads/square_100.png"
                }
              />
              <div className="media-body align-self-center pt-2">
                <label className="sr-only" htmlFor="timeline-post__msg">
                  Write a comment...
                </label>
                <MentionsInput
                  className="mentions new-post-input"
                  displayTransform={(id, display) => `@${display}`}
                  id="timeline-post__msg"
                  placeholder="Message your team. Use @ to notify your teammates."
                  value={this.props.message}
                  onBlur={() => {
                    this.setState({
                      writingPost: false,
                    });
                    this.props.onBlur();
                  }}
                  onChange={this.onMessageChange}
                  onFocus={() => {
                    this.setState({
                      writingPost: true,
                      showOverlay: true,
                    });
                    this.props.onFocus();
                  }}
                >
                  <Mention data={this.props.mentionUsers} trigger="@" />
                </MentionsInput>

                {this.state.imagePreviewUrl ? (
                  <div className="timeline-post__preview-image-container">
                    <a
                      className="timeline-post__remove-image"
                      href="#"
                      onClick={(e) => this.removePhoto(e)}
                    >
                      <span className="sr-only">Close</span>
                    </a>
                    <img
                      alt="Preview"
                      className="timeline-post__image-preview"
                      src={this.state.imagePreviewUrl}
                    />
                  </div>
                ) : (
                  ""
                )}

                {this.state.isShowingLink && (
                  <SelectLinkInput
                    campaignId={this.props.campaignId}
                    onRef={(ref) => {
                      this.selectLinkComponent = ref;
                    }}
                  />
                )}

                {this.state.isShowingYouTube && (
                  <div className="timeline-post__youtube-input-container">
                    {this.state.youTubeId && (
                      <div className="mb-3">
                        <YouTubePreview youTubeId={this.state.youTubeId} />
                      </div>
                    )}

                    <div className="input-field">
                      <input
                        disabled={this.props.addingPost}
                        id="activityYoutubeInput"
                        placeholder="https://www.youtube.com/watch?v=qJK2xa2UeTM"
                        ref={(input) => {
                          this.youtubeInput = input;
                        }}
                        type="text"
                        onChange={(e) => this.onYouTubeChange(e)}
                      />
                      <label htmlFor="activityYoutubeInput">YouTube Link</label>
                    </div>
                  </div>
                )}
                <div className="timeline-post__actions-container">
                  <a
                    className="block-text timeline-post__add-link"
                    href="#"
                    onClick={(e) => this.toggleLink(e)}
                  >
                    <i className="material-icons">&#xE8CC;</i> <span>Store</span>
                  </a>

                  <label
                    className="timeline-post__image-upload-container"
                    htmlFor="activityFileUpload"
                  >
                    <span className="block-text color-link">
                      <i className="material-icons">insert_photo</i> <span>Photo</span>
                    </span>
                    <input
                      className=""
                      id="activityFileUpload"
                      type="file"
                      onChange={this.handleFileUpload}
                    />
                  </label>

                  <a
                    className="block-text timeline-post__youtube-link"
                    href="#"
                    onClick={(e) => this.toggleYouTube(e, !this.state.isShowingYouTube)}
                  >
                    <i className="fa fa-youtube-play" /> <span>YouTube</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="timeline-post__post-container">
            <button
              className={`btn timeline-post__post ${this.isButtonDisabled() ? "disabled" : ""}`}
            >
              {this.props.addingPost ? "Posting" : "Post"}
            </button>
            <div className="switch timeline-post__switch">
              <a
                className="dropdown-button btn-flat px-2 timeline-post__privacy-btn block-text"
                data-activates="newPostDropDown"
                href="#"
              >
                {this.state.visibility_label}
                <i className="ml-1 fa fa-caret-down" />
              </a>
              <ul className="dropdown-content" id="newPostDropDown">
                <li>
                  <a href="#" onClick={() => this.updateVisibility("team")}>
                    Teammates
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => this.updateVisibility("public")}>
                    Everyone
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
