import React from "react";
import { CenteredBoxLayout } from "@molecules";
import { CircleLoader, Box, H2 } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

// == Constants ============================================================

BeneficiaryLoading.defaultProps = {};

// == Component ============================================================

export default function BeneficiaryLoading() {
  return (
    <CenteredBoxLayout p={[4, 4, 5]}>
      <Box textAlign="center">
        <CircleLoader delay={null}></CircleLoader>
      </Box>
    </CenteredBoxLayout>
  );
}

// == Styles ===============================================================
