import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends ReactDatePickerProps {
  selected: Date;
  onChange: (d: Date) => void;
}

// == Constants ============================================================

MonthInput.defaultProps = {};

// == Component ============================================================

export function MonthInput({ selected, onChange, ...rest }: IProps) {
  return (
    <EContainer>
      <EDatePicker
        showMonthYearPicker
        dateFormat="MMM yyyy"
        selected={selected}
        onChange={onChange}
        {...rest}
      />
    </EContainer>
  );
}

// == Styles ===============================================================

const EContainer = styled.div`
  .react-datepicker__month--selected {
    background-color: ${({ theme }) => theme.colors.primary};
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const EDatePicker = styled(DatePicker)`
  margin-bottom: 0 !important;
`;
