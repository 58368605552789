import React, { ReactChild, useEffect, useRef } from "react";
import { Field, ErrorMessage } from "formik";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  children: ReactChild[] | ReactChild;
  label: string;
  name: string;
  handleChange: (e) => $FixMe;
  handleBlur: (e) => void;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  placeholder: "",
};

// == Component ============================================================

export const Select = ({ name, label, children, handleChange, handleBlur, ...rest }: IProps) => {
  const selectRef = useRef(null);

  useEffect(() => {
    $(selectRef.current).material_select();
    $(selectRef.current).on("change", function(e) {
      handleChange(e);
    });

    $(selectRef.current).on("blur", function(e) {
      handleBlur(e);
    });

    return () => {
      $(selectRef.current).material_select("destroy");
      $(selectRef.current).off();
    };
  }, []);

  return (
    <>
      <Field name={name}>
        {({ field, form }) => (
          <div className="input-field">
            <select {...field} id={name} ref={selectRef}>
              {children}
            </select>
            <label htmlFor={name}>{label}</label>
          </div>
        )}
      </Field>
      <ErrorMessage className="standalone-error" component="span" name={name} />
    </>
  );
};

Select.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
{
  /*  */
}
