import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Typography, Modal, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ErrorService } from "@services";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const LEAVE_CAMPAIGN = gql`
  mutation CampaignLeaveMutation($input: LeaveCampaignInput!) {
    LeaveCampaign(input: $input) {
      ... on Campaign {
        id
        name
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  container: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
  },
  buttonContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    justifyItems: "center",
    marginTop: "2rem",
  },
});

class SimpleModal extends React.Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      awaitingSubmit: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  sendCompleted = () => {
    const { isOwner } = this.props;

    if (isOwner) {
      window.location.reload();
    } else {
      window.location.href = "/user/teams";
    }
  };

  handleLeaveCampaign = (e, CampaignLeaveMutation) => {
    e.preventDefault();
    this.setState({ awaitingSubmit: true });
    const { campaignId, userId } = this.props;

    CampaignLeaveMutation({
      variables: {
        input: { campaignId, userId },
      },
    })
      .then(this.sendCompleted)
      .catch(ErrorService.error);
  };

  renderLoadingButton = () => {
    const { isOwner } = this.props;

    if (isOwner) {
      return "Removing...";
    }

    return "Leaving Team...";
  };

  render() {
    const { classes, isOwner, variant } = this.props;
    const { awaitingSubmit } = this.state;

    return (
      <Mutation mutation={LEAVE_CAMPAIGN}>
        {(CampaignLeaveMutation) => (
          <>
            {variant === "button" ? (
              <a
                className="sidebar-member__dropdown-link--js btn btn-danger"
                href="#"
                onClick={this.handleOpen}
              >
                {isOwner ? "Remove From Team" : "Leave Team"}
              </a>
            ) : (
              <li>
                <a
                  className="sidebar-member__dropdown-link--js color-danger"
                  href="#"
                  onClick={this.handleOpen}
                >
                  {isOwner ? "Remove From Team" : "Leave Team"}
                </a>
              </li>
            )}
            <Modal
              aria-describedby="simple-modal-description"
              aria-labelledby="simple-modal-title"
              open={this.state.open}
              onClose={this.handleClose}
            >
              <div className={classes.container}>
                <div className={classes.paper} style={getModalStyle()}>
                  <Typography id="modal-title" variant="h6">
                    {isOwner
                      ? "Are you sure you want to remove this person from the team?"
                      : "Are you sure you want to leave this team?"}
                  </Typography>
                  <div
                    className={classes.buttonContainer}
                    style={{
                      gridTemplateColumns: awaitingSubmit ? "1fr" : "1fr 1fr",
                    }}
                  >
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={awaitingSubmit}
                      variant="contained"
                      onClick={(e) => {
                        this.handleLeaveCampaign(e, CampaignLeaveMutation);
                      }}
                    >
                      {awaitingSubmit ? this.renderLoadingButton() : "Yes"}
                    </Button>
                    {awaitingSubmit ? null : (
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="outlined"
                        onClick={this.handleClose}
                      >
                        No
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(SimpleModal);
