import React from "react";
import styled from "@emotion/styled";
import { AlphabetEnum } from "@types";
import { Box } from "@atoms";
import { LinkPill } from "@molecules";
import { TDispatch, TActiveFilterType } from "../types";

// == Types ================================================================

interface IProps {
  activeFilter: TActiveFilterType;
  dispatch: TDispatch;
}

// == Constants ============================================================

// == Component ============================================================

export default function BrandListLetters({ activeFilter, dispatch }: IProps) {
  return (
    <EContainer my={4}>
      <LinkPill
        isActive={activeFilter === "POPULARITY"}
        text="Popular"
        onClick={() => dispatch({ type: "SET_NAMED_FILTER", filter: "POPULARITY" })}
      />
      <LinkPill
        isActive={activeFilter === "NEWEST"}
        text="New"
        onClick={() => dispatch({ type: "SET_NAMED_FILTER", filter: "NEWEST" })}
      />
      <LinkPill
        isActive={activeFilter === "GIVE"}
        text="Give %"
        onClick={() => dispatch({ type: "SET_NAMED_FILTER", filter: "GIVE" })}
      />
      {Object.values(AlphabetEnum).map((letter) => {
        return (
          <LinkPill
            isActive={activeFilter.toUpperCase() === letter.toUpperCase()}
            key={`letter-${letter}`}
            text={letter.toUpperCase()}
            onClick={() => dispatch({ type: "SET_LETTER", filter: letter })}
          />
        );
      })}
    </EContainer>
  );
}

// == Styles ===============================================================

const EContainer = styled(Box)`
  overflow-x: auto;
  white-space: nowrap;
  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    white-space: normal;
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
  }
`;
