import React from "react";
import PropTypes, { string, shape } from "prop-types";

const TransferSuccessModal = (props) => {
  const { availableAmount, onCloseModal, campaign } = props;
  return (
    <div className="modal-content modal-content--more-padding">
      <div className="modal-header centered h2">Transfer Sent</div>
      <button className="modal-close modal__close">
        <span className="modal__close-icon" />
        <span className="sr-only">Close</span>
      </button>
      <div className="mt-4 centered">
        Your transfer of <span className="color-link">{availableAmount.formatted}</span> has been
        sent to <a href={campaign.url}>{campaign.name}</a>
        <div className="mt-4">
          <button className="btn btn-active user-transfer-modal__button" onClick={onCloseModal}>
            Okay, Got It
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferSuccessModal;

TransferSuccessModal.propTypes = {
  availableAmount: shape({
    formatted: string.isRequired,
  }).isRequired,
  campaign: shape({
    name: string.isRequired,
    url: string.isRequired,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
