import { Flex, Text } from "@components";
import styled from "@emotion/styled";
import { creditCardLogoURI } from "./GiftcardReloadPaymentFields";

interface IUpdateCardButton {
  last4?: string;
  expiryMonth?: string | number;
  expiryYear?: string | number;
  brand?: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export const AddNewCardButton = ({
  onClick,
  isLoading,
}: {
  onClick: () => void;
  isLoading?: boolean;
}) => (
  <ButtonWrapper
    opacity={isLoading ? 0.6 : 1}
    alignItems="center"
    height="58px"
    borderRadius="4px"
    justifyContent="center"
    onClick={onClick}
  >
    <ChangeCardText>Link a new Card</ChangeCardText>
  </ButtonWrapper>
);

export const UpdateCardButton = ({
  last4,
  expiryMonth,
  expiryYear,
  brand,
  onClick,
  isLoading,
}: IUpdateCardButton) => {
  return (
    <ButtonWrapper
      alignItems="center"
      height="58px"
      borderRadius="4px"
      justifyContent="space-between"
      opacity={isLoading ? 0.6 : 1}
    >
      {last4 && (
        <LeftContentWrapper alignItems="center">
          <img
            src={`https://flipgive.twic.pics/images/site/linked-cards/${creditCardLogoURI(
              brand ?? ""
            )}.png?twic=v1/resize=200x200/background=FFFFFF`}
            alt="card type"
            height="40px"
            width="40px"
          />
          <Flex flexDirection="column" marginLeft="16px" height="33px" justifyContent="center">
            <CardText>
              {brand} ending in {last4}
            </CardText>
            <CardSubText>
              Expiring in {expiryMonth}/{expiryYear}
            </CardSubText>
          </Flex>
        </LeftContentWrapper>
      )}
      <RightContentWrapper>
        <ChangeCardText onClick={isLoading ? () => null : onClick}>
          {isLoading ? "Please wait..." : "Change Card"}
        </ChangeCardText>
      </RightContentWrapper>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled(Flex)`
  border: 1px solid rgb(72, 177, 191);
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 16px;
  transition: border 150ms ease-in 0s, color 200ms ease-in 0s;
  cursor: pointer;
`;

const RightContentWrapper = styled(Flex)`
  border-radius: 4px;
  padding-right: 16px;
`;
const LeftContentWrapper = styled(Flex)`
  padding: 8px 16px;
  border-radius: 4px;
`;

const ChangeCardText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
`;
const CardText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  line-height: 1;
`;
const CardSubText = styled(Text)`
  color: rgb(102, 102, 102);
  font-size: 12px;
  line-height: 1;
`;
