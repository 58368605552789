import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { isEmpty } from "lodash";
// import styled from "@emotion/styled";
import { LocationContext } from "@reach/router";
import { getPaginatedNodes } from "@components/common/utils";
import { useLoadingDelay } from "@hooks";
import { Box, Text, TouchableOpacity, H3, FontAwesome } from "@atoms";
import { IntentCreateInput, LinkType } from "@types";
import { LinkModalPromotionItem } from "../LinkModalPromotionItem";
import {
  LinkModalPromotions as LinkModalPromotionsQuery,
  LinkModalPromotionsVariables,
} from "./__generated__/LinkModalPromotions";
import LinkModalPromotionsLoading from "./LinkModalPromotionsLoading";

// == Types ================================================================

interface IProps {
  linkId: string;
  onBackClick: () => void;
  linkType: LinkType;
  fundraiserId?: string;
  locationContext: LocationContext;
  createIntentInput: IntentCreateInput;
}

// == Constants ============================================================

const LINK_MODAL_PROMOTIONS_QUERY = gql`
  query LinkModalPromotions($id: ID) {
    Link(id: $id) {
      id
      promotionInfo {
        promotions(first: 25) {
          edges {
            cursor
            node {
              id
              ...LinkModalPromotionItemFragment
            }
          }
        }
      }
    }
  }
  ${LinkModalPromotionItem.fragment}
`;

// == Component ============================================================

export const LinkModalPromotions = ({
  linkId,
  onBackClick,
  linkType,
  fundraiserId,
  createIntentInput,
  locationContext,
}: IProps) => {
  const loadingDelayReady = useLoadingDelay();
  const { data, error, loading } = useQuery<LinkModalPromotionsQuery, LinkModalPromotionsVariables>(
    LINK_MODAL_PROMOTIONS_QUERY,
    {
      variables: {
        id: linkId,
      },
    }
  );

  if (error) {
    return (
      <Box>
        <Text>There was a problem, please try again.</Text>
      </Box>
    );
  }
  if (loading || isEmpty(data) || !loadingDelayReady) return <LinkModalPromotionsLoading />;

  const { promotionInfo } = data?.Link;

  const promotions = getPaginatedNodes(promotionInfo.promotions.edges);
  return (
    <Box p={3} textAlign="center">
      <Box mb={3} position="relative">
        <TouchableOpacity position="absolute" onTouch={onBackClick}>
          <Text color="primary" fontSize={[3, 4]}>
            <FontAwesome icon="arrow-left" />
          </Text>
        </TouchableOpacity>
        <H3 textAlign="center">Deals</H3>
      </Box>
      {promotions.map((promotion) => {
        return (
          <Box key={promotion.id} my={3}>
            <LinkModalPromotionItem
              createIntentInput={createIntentInput}
              fundraiserId={fundraiserId}
              linkId={linkId}
              linkType={linkType}
              locationContext={locationContext}
              promotion={promotion}
              showShopButton={
                ![LinkType.GIFTCARDOFFER, LinkType.VOUCHER, LinkType.AUTORELOADGIFTCARD].includes(
                  linkType
                )
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};

// == Styles ===============================================================
