import React from "react";
import { Provider } from "mobx-react";
import { ApolloProvider } from "react-apollo";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";
import { FlipgiveStore } from "@stores";
import { BeneficiaryProvider } from "./context";
import { CampaignBeneficiaryApp } from "./CampaignBeneficiaryApp";

interface IProps {
  country: "CANADA" | "USA";
  campaignId: string;
  redirectUrl: string;
  currentUser: object;
}

export default (props: IProps) => {
  const { country, campaignId, redirectUrl } = props;
  Settings.setDefaultsFromProps(props);

  FlipgiveStore.setCurrentUser(props.currentUser);
  FlipgiveStore.setCampaignId(props.campaignId);

  const initialState = {
    campaignId,
    currentStep: 1,
    redirectUrl,
    beneficiaryValues: { legalName: "" },
    deliveryValues: {
      street1: "",
      street2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      checkType: undefined,
      phoneNumber: "",
      email: "",
    },
  };
  const client = apolloClient(Settings.apiUrl);

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <Provider FlipgiveStore={FlipgiveStore}>
          <BeneficiaryProvider initialState={initialState}>
            <CampaignBeneficiaryApp country={country} />
          </BeneficiaryProvider>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
