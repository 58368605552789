import React from "react";
import produce from "immer";
// import styled from "@emotion/styled";
import { useMutation } from "react-apollo";
import { Heart, Box, HeartOutline, TouchableOpacity } from "@atoms";
import {
  FAVORITE_BRANDS_QUERY,
  TOGGLE_FAVORITE_BRAND_MUTATION,
  FavoriteBrands as FavoriteBrandsQuery,
  ToggleFavoriteBrandMutation,
  ToggleFavoriteBrandMutationVariables,
} from "@queries";

// == Types ================================================================

interface IProps {
  brandId: string;
  viewerHasFavorited: boolean;
}

// == Constants ============================================================

// const FRAGMENTS = {
//   fields: gql`
//     fragment BrandModalFavoriteButtonFragment on Brand {
//       id
//       viewerHasFavorited
//     }
//   `,
// };

// == Component ============================================================

const BrandModalFavoriteButton = ({ brandId, viewerHasFavorited }: IProps) => {
  const [ToggleFavoriteBrand] = useMutation<
    ToggleFavoriteBrandMutation,
    ToggleFavoriteBrandMutationVariables
  >(TOGGLE_FAVORITE_BRAND_MUTATION);

  const toggleFavoriteBrand = () => {
    ToggleFavoriteBrand({
      variables: { brandId },
      update(cache, { data }) {
        if (!data) return;

        const { ViewerToggleFavoriteBrand } = data;

        if (ViewerToggleFavoriteBrand.__typename === "MutationError") return;

        const cacheData = cache.readQuery<FavoriteBrandsQuery>({
          query: FAVORITE_BRANDS_QUERY,
        })!;
        const newData = produce(cacheData, ({ Viewer }) => {
          if (ViewerToggleFavoriteBrand.viewerHasFavorited) {
            Viewer.favoriteBrands.push(ViewerToggleFavoriteBrand);
            Viewer.favoriteBrandsCount = (Viewer.favoriteBrandsCount || 0) + 1;
          } else {
            Viewer.favoriteBrands = Viewer.favoriteBrands.filter(
              (favoriteBrand) => favoriteBrand && favoriteBrand.viewerHasFavorited
            );
            Viewer.favoriteBrandsCount = (Viewer.favoriteBrandsCount || 0) - 1;
          }
        });

        cache.writeQuery({
          query: FAVORITE_BRANDS_QUERY,
          data: newData,
        });
      },
    });
  };

  return (
    <Box color="primary" fontSize={4} p={2} position="absolute" right={0}>
      <TouchableOpacity onTouch={toggleFavoriteBrand}>
        {viewerHasFavorited ? <Heart /> : <HeartOutline />}
      </TouchableOpacity>
    </Box>
  );
};

// BrandModalFavoriteButton.framgents = FRAGMENTS;

export default BrandModalFavoriteButton;

// == Styles ===============================================================
