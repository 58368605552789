import React from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import ActivityContent from "./ActivityContent";
import ActivityReactions from "./ActivityReactions";
import { ErrorBoundary } from "@utils";

@inject("TimelineStore")
export default class Activity extends React.Component {
  static propTypes = {
    activity: PropTypes.shape({
      visibility: PropTypes.string.isRequired,
      likesCount: PropTypes.number.isRequired,
      comments: PropTypes.arrayOf(PropTypes.shape({})),
      commentsCount: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      viewerHasLiked: PropTypes.bool,
    }).isRequired,
  };

  render() {
    const { activity, TimelineStore } = this.props;

    return (
      <ErrorBoundary>
        <div className="timeline-activity card">
          <ActivityContent activity={activity} />
          {!TimelineStore.hideReactions && (
            <ActivityReactions
              activityId={activity.id}
              comments={activity.comments || []}
              commentsCount={activity.commentsCount}
              likesCount={activity.likesCount}
              viewerHasLiked={activity.viewerHasLiked || false}
              visibility={activity.visibility}
            />
          )}
        </div>
      </ErrorBoundary>
    );
  }
}
