import React from "react";
import styled from "@emotion/styled";
// import * as StyledSystem from "styled-system";
// import { theme, themed, TThemeButtonVariants } from "@styles";
import { Box, TouchableOpacity, ChevronCircleLeft, ChevronCircleRight } from "@atoms";

// == Types ================================================================

interface IProps {
  arrowDirection: "left" | "right";
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  arrowDirection: "left",
};

// == Component ============================================================

const ArrowCarouselBanner = ({ arrowDirection }: IProps) => {
  return (
    <EBox
      bottom={0}
      data-testid="arrow-carousel-banner"
      fontSize={5}
      left={arrowDirection === "left" ? 10 : "unset"}
      p={2}
      position="absolute"
      right={arrowDirection === "right" ? 10 : "unset"}
      top={0}
    >
      {arrowDirection === "left" ? <ChevronCircleLeft /> : <ChevronCircleRight />}
    </EBox>
  );
};

ArrowCarouselBanner.defaultProps = DEFAULT_PROPS;

export default ArrowCarouselBanner;
// == Styles ===============================================================

const EBox = styled(Box)`
  opacity: 0.5;
  transition: opacity 150ms ease;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
