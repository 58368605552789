import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { oc } from "ts-optchain";
import { AnnouncementModalQuery as AnnouncementModalQueryType } from "./__generated__/AnnouncementModalQuery";
import AnnouncementModal from "./AnnouncementModal";

// == Constants ============================================================

const ANNOUNCEMENT_MODAL_QUERY = gql`
  query AnnouncementModalQuery {
    Viewer {
      id
      announcements(first: 1) {
        edges {
          cursor
          node {
            id
            ...AnnouncementModalFragment
          }
        }
        # pageInfo
      }
    }
  }
  ${AnnouncementModal.fragments}
`;

// == Component ============================================================

export interface ISlide {
  description: string | null;
  image?: string | null;
}

function formatAnnouncement(announcementData) {
  if (!announcementData) return null;
  const {
    image,
    image_1,
    image_2,
    image_3,
    image_4,
    image_5,
    description,
    description_1,
    description_2,
    description_3,
    description_4,
    description_5,
  } = announcementData;

  const slides: ISlide[] = [];
  const hasDefaultOnly = !image_1 && !image_2 && !image_3 && !image_4 && !image_5;
  if (hasDefaultOnly) slides.push({ image: image?.large, description });
  const newSlides = [
    { image: image_1?.large, description: description_1 },
    { image: image_2?.large, description: description_2 },
    { image: image_3?.large, description: description_3 },
    { image: image_4?.large, description: description_4 },
    { image: image_5?.large, description: description_5 },
  ].filter(({ description: descr }) => !!descr);
  slides.push(...newSlides);
  const announcement = { ...announcementData, slides };
  return announcement;
}

export default function AnnouncementModalQuery() {
  const { loading, data, error } = useQuery<AnnouncementModalQueryType>(ANNOUNCEMENT_MODAL_QUERY);

  if (loading || error) return null;
  const announcementData = oc(data).Viewer.announcements.edges[0].node();
  const announcement = formatAnnouncement(announcementData);

  if (!announcement) return null;
  return <AnnouncementModal announcement={announcement} />;
}

// == Styles ===============================================================
