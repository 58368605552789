import React from "react";
import { Row, Column, TextUppercase, IFlexProps } from "@atoms";

// import styled from "@emotion/styled";

// == Types ================================================================

// == Constants ============================================================

IntentListHeader.defaultProps = {};

// == Component ============================================================

export default function IntentListHeader({ ...rest }: IFlexProps) {
  return (
    <Row mt={3} {...rest}>
      <Column autoColumn={false} width={[2 / 12]}>
        <TextUppercase fontSize={1}>Time (EST)</TextUppercase>
      </Column>
      <Column autoColumn={false} width={[2 / 12]}>
        <TextUppercase fontSize={1}>Activity ID</TextUppercase>
      </Column>
      <Column autoColumn={false} width={[5 / 12]}>
        <TextUppercase fontSize={1}>Shopping Trip</TextUppercase>
      </Column>
      <Column autoColumn={false} textAlign="right" width={[3 / 12]}>
        <TextUppercase fontSize={1}>Earnings</TextUppercase>
      </Column>
    </Row>
  );
}

// == Styles ===============================================================
