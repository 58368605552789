import styled from "@emotion/styled";
import * as StyledSystem from "styled-system";
import { themed } from "@styles";

// == Types ================================================================

interface IImageKnownProps
  extends $EmotionBaseProps,
    StyledSystem.SpaceProps,
    StyledSystem.WidthProps,
    StyledSystem.MaxWidthProps,
    StyledSystem.BackgroundColorProps,
    // NEW
    StyledSystem.TextAlignProps,
    StyledSystem.BordersProps,
    StyledSystem.BorderColorProps,
    StyledSystem.BorderRadiusProps,
    StyledSystem.BoxShadowProps,
    StyledSystem.OpacityProps,
    StyledSystem.DisplayProps,
    StyledSystem.PositionProps,
    StyledSystem.HeightProps,
    StyledSystem.MaxHeightProps {}
interface IImageProps
  extends IImageKnownProps,
    Omit<React.HTMLProps<HTMLImageElement>, keyof IImageKnownProps> {}

// == Constants ============================================================

const DEFAULT_PROPS = {
  as: "img",
  m: 0,
  display: "block",
};

// == Component ============================================================

export const Image = styled<"img", IImageProps>("img")(
  {
    maxWidth: "100%",
    height: "initial",
  },
  StyledSystem.space,
  StyledSystem.width,
  StyledSystem.maxWidth,
  StyledSystem.backgroundColor,
  StyledSystem.borders,
  StyledSystem.borderColor,
  StyledSystem.borderRadius,
  StyledSystem.boxShadow,
  StyledSystem.opacity,
  StyledSystem.display,
  StyledSystem.position,
  StyledSystem.height,
  StyledSystem.maxHeight,
  themed("Image")
);

Image.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================

// IMGIX updates to come
// ==========================================================
// import React from "react";
// import styled from "@emotion/styled";
// import * as StyledSystem from "styled-system";
// import Imgix from "react-imgix";
// import { themed } from "@styles";

// // == Types ================================================================

// interface IImageKnownProps
//   extends $EmotionBaseProps,
//     StyledSystem.SpaceProps,
//     StyledSystem.WidthProps,
//     StyledSystem.MaxWidthProps,
//     StyledSystem.BackgroundColorProps,
//     // NEW
//     StyledSystem.TextAlignProps,
//     StyledSystem.BordersProps,
//     StyledSystem.BorderColorProps,
//     StyledSystem.BorderRadiusProps,
//     StyledSystem.BoxShadowProps,
//     StyledSystem.OpacityProps,
//     StyledSystem.DisplayProps,
//     StyledSystem.PositionProps,
//     StyledSystem.HeightProps,
//     StyledSystem.MaxHeightProps {}
// interface IImageProps
//   extends IImageKnownProps,
//     Omit<React.HTMLProps<HTMLImageElement>, keyof IImageKnownProps> {}

// interface IImgixComponentProps extends IImageProps {
//   htmlAttributes: object;
// }

// // == Constants ============================================================

// const DEFAULT_PROPS = {
//   as: "img",
//   m: 0,
//   display: "block",
// };

// // == Component ============================================================

// ImgixComponent.defaultProps = {
//   htmlAttributes: {},
// };

// function ImgixComponent({ alt, htmlAttributes, ...rest }: IImgixComponentProps) {
//   const componentHtmlAttributes = { alt, ...htmlAttributes };
//   return <Imgix htmlAttributes={componentHtmlAttributes} {...rest} />;
// }

// export const Image = styled<ImgixComponent, IImageProps>(ImgixComponent)(
//   {
//     maxWidth: "100%",
//     height: "initial",
//   },
//   StyledSystem.space,
//   StyledSystem.width,
//   StyledSystem.maxWidth,
//   StyledSystem.backgroundColor,
//   StyledSystem.borders,
//   StyledSystem.borderColor,
//   StyledSystem.borderRadius,
//   StyledSystem.boxShadow,
//   StyledSystem.opacity,
//   StyledSystem.display,
//   StyledSystem.position,
//   StyledSystem.height,
//   StyledSystem.maxHeight,
//   themed("Image"),
// );

// Image.defaultProps = DEFAULT_PROPS;

// // == Styles ===============================================================
