import React, { ReactNode } from "react";
import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  disabled: boolean;
  label?: string;
  type: string;
  name: string;
  loadingText: string;
  additionalClasses: string;
  onClick: () => void;
  as?: "button" | "input";
  children?: ReactNode | string;
  variant?: "clear";
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  disabled: false,
  type: "button",
  name: "",
  loadingText: "Loading...",
  additionalClasses: "",
  onClick: null,
  as: "button",
  label: null,
  variant: null,
};

// == Component ============================================================

export const Button = ({
  as,
  type,
  name,
  label,
  disabled,
  loadingText,
  additionalClasses,
  onClick,
  variant,
  ...rest
}: IProps) => {
  const variantClassNames = variant === "clear" ? "btn-clear" : null;
  return (
    <EEinput
      as={as}
      className={`btn btn-block ${variantClassNames} ${
        disabled ? "disabled" : ""
      } ${additionalClasses}`}
      data-loading-text={loadingText}
      name={name}
      type={type}
      value={label}
      onClick={onClick}
      {...rest}
    />
  );
};

Button.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
const EEinput = styled.input``;
