import React, { useEffect } from "react";
import { Field, ErrorMessage, FieldProps } from "formik";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  label: string;
  name: string;
  defaultValue: string;
  disabled: boolean;
  placeholder: string;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  defaultValue: null,
  disabled: false,
  placeholder: "",
};

// == Component ============================================================

export const TextInput = ({
  name,
  label,
  defaultValue,
  disabled,
  placeholder,
  ...rest
}: IProps) => {
  useEffect(() => {
    Materialize.updateTextFields();
  }, []);

  return (
    <Field name={name} {...rest}>
      {({ field, form }: FieldProps) => (
        <>
          <div className={`input-field ${disabled ? "disabled" : ""}`}>
            <input disabled={disabled} placeholder={placeholder} type="text" {...field} />
            <label htmlFor={name}>{label}</label>
          </div>
          <ErrorMessage className="standalone-error" component="span" name={name} />
        </>
      )}
    </Field>
  );
};
TextInput.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
