import React from "react";
// import styled from "@emotion/styled";
import { theme } from "@styles";
import { Box } from "../Box";
import { ICardProps } from "../Card/Card";
import { H3 } from "../H3";
import { CheckCircleIcon } from "../Icon";
import { Flex } from "../Flex";
import { TouchableOpacity } from "../TouchableOpacity";
// == Types ================================================================
interface IProps extends ICardProps {
  heading: string | null;
  border?: ICardProps["border"];
  checkMark?: boolean;
  showEditButton?: boolean;
  onEditClick?: () => void;
  editButtonText?: string;
  contentPadding?: ICardProps["padding"];
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  border: `1px solid ${theme.colors.components.card.formBorderColor}`,
  borderRadius: 4,
  padding: "24px",
  contentPadding: "24px",
  checkMark: false,
  showEditButton: false,
  editButtonText: "Edit",
};

// == Component ============================================================
const CardForm = ({
  onEditClick,
  showEditButton,
  checkMark,
  padding,
  contentPadding,
  children,
  heading,
  border,
  editButtonText,
  ...rest
}: IProps) => {
  return (
    <Box bg="white" border={[0, 0, border]} mt={4} {...rest}>
      {!!heading && (
        <Flex
          alignItems="center"
          bg="#eee"
          borderBottom={border}
          borderRadius={[0, 0, "4px 4px 0 0"]}
          justifyContent="space-between"
          p={padding}
        >
          <Flex alignItems="baseline">
            <H3>{heading}</H3>
            {showEditButton && (
              <TouchableOpacity
                containerProps={{
                  style: { color: `${theme.colors.primary}`, paddingLeft: `${theme.space[2]}px` },
                }}
                onTouch={onEditClick}
              >
                {editButtonText}
              </TouchableOpacity>
            )}
          </Flex>
          {checkMark && <CheckCircleIcon containerProps={{ color: "default", fontSize: 4 }} />}
        </Flex>
      )}
      {children && <Box p={contentPadding}>{children}</Box>}
    </Box>
  );
};

CardForm.defaultProps = DEFAULT_PROPS;

export default CardForm;

// == Styles ===============================================================
