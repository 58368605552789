import React from "react";
import gql from "graphql-tag";
import { useSpring, animated } from "react-spring";
import { BrandCircle } from "@organisms/brandCircleComponents";
import { renderIf } from "@services";
import { Box, Row, Column, Button } from "@atoms";
import { BrandListItem } from "../BrandListItem";
import { BrandListFragment } from "./__generated__/BrandListFragment";

// == Types ================================================================
type TBrands = BrandListFragment;

interface IProps {
  activeFilter: string;
  brands: TBrands;
  onFetchMore: () => void;
  loadingMore: boolean;
  hasNextPage: boolean;
}

// == Constants ============================================================

BrandListContent.fragments = {
  fields: gql`
    fragment BrandListFragment on Brand {
      id
      name
      ...BrandListItemFragment
    }
    ${BrandListItem.fragments.fields}
  `,
};

const BrandListItemContainer = ({ brand }) => {
  const springProps = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <Column autoColumn={false} mb={3} width={[1, 1 / 2, 1 / 3]}>
      <animated.div style={springProps}>
        <BrandListItem linkBrand brand={brand} />
      </animated.div>
    </Column>
  );
};

// == Component ============================================================

export default function BrandListContent({
  brands,
  onFetchMore,
  loadingMore,
  hasNextPage,
}: IProps) {
  return (
    <>
      <Row>
        {brands.map((brand) => {
          return <BrandListItemContainer brand={brand} key={brand.id} />;
        })}
      </Row>
      {renderIf(hasNextPage)(
        <Box my={3} textAlign="center">
          <Button disabled={loadingMore} onClick={onFetchMore}>
            {loadingMore ? "Loading..." : "Load More"}
          </Button>
        </Box>
      )}
    </>
  );
}

// == Styles ===============================================================
