import React from "react";
import { useQuery } from "react-apollo";
import { oc } from "ts-optchain";
import { FavoriteBrands, FAVORITE_BRANDS_QUERY } from "@queries";
import { Text } from "@atoms";
import BrandFavoritesDialer from "./BrandFavoritesDialer";

// == Types ================================================================

interface IProps {
  isFullWidth: boolean;
  altOnClick: () => void;
  showMoreLink?: string;
}

// == Constants ============================================================

// == Component ============================================================

export const BrandFavoritesDialerQuery = ({ isFullWidth, altOnClick, showMoreLink }: IProps) => {
  const { data, error, loading } = useQuery<FavoriteBrands>(FAVORITE_BRANDS_QUERY);
  if (error) {
    return <Text>{error.message}</Text>;
  }
  const brands = oc(data).Viewer.favoriteBrands();
  if (!brands && !loading) return null; // return error
  return (
    <BrandFavoritesDialer
      brands={brands ?? []}
      isFullWidth={isFullWidth}
      topRightLinkOnClick={altOnClick}
      topRightLink="See All"
      isLoading={loading}
      showMoreLink={showMoreLink}
      shouldFillLastCircle
    />
  );
};

// == Styles ===============================================================
