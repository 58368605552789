import React, { useEffect } from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "@apollo/react-hooks";
import isEmpty from "lodash/isEmpty";
import { oc } from "ts-optchain";
import { useScrollTopOnMount, useLoadingDelay, useStoreAppContext } from "@hooks";
import { Text } from "@atoms";
import { FadeIn } from "@utils";
import { StoreFrontViewLoading } from "../StoreFrontViewLoading";
import StoreFrontView from "./StoreFrontView";
import { StoreFrontViewQuery as StoreFrontViewQueryType } from "./__generated__/StoreFrontViewQuery";
// import { CollectionViewQuery as CollectionViewQueryType } from "./__generated__/CollectionViewQuery";

// == Types ================================================================

interface IRouteProps {
  isLoading?: boolean;
  type: "TEAM_STORE_DEFAULT" | "HOME_STORE_DEFAULT" | "STORE_DEFAULT";
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================

const STORE_FRONT_VIEW_QUERY = gql`
  query TeamStoreFrontViewQuery($type: DefaultStoreFrontType!, $carouselItems: Int = 10) {
    DefaultStoreFront(type: $type) {
      id
      ...StoreFrontViewFragment
    }
  }
  ${StoreFrontView.fragments.fields}
`;

const DEFAULT_PROPS = {
  isLoading: false,
};

// == Component ============================================================

export const TeamStoreFrontViewQuery = ({ type, isLoading }: TProps) => {
  useScrollTopOnMount();
  const [, appDispatch] = useStoreAppContext();

  useEffect(() => {
    appDispatch({ type: "SET_STOREFRONT_TYPE", storeFrontType: "teamStore" });
    return () => appDispatch({ type: "SET_STOREFRONT_TYPE", storeFrontType: null });
  }, [appDispatch]);

  const loadingDelayReady = useLoadingDelay(100);
  const { loading, data, error } = useQuery<StoreFrontViewQueryType>(STORE_FRONT_VIEW_QUERY, {
    context: {
      skipBatch: true,
    },
    variables: { type },
  });

  if (error) {
    return <Text>{error.message}</Text>;
  }

  const hasNotLoaded = isEmpty(data) || loading || !loadingDelayReady || isLoading;

  if (hasNotLoaded) return <StoreFrontViewLoading />;

  const storefront = oc(data).DefaultStoreFront();
  if (!storefront) return null;

  return (
    <FadeIn>
      <StoreFrontView isHome storefront={storefront} />
    </FadeIn>
  );
};

TeamStoreFrontViewQuery.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
