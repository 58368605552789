import React, { useEffect } from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import { oc } from "ts-optchain";
import get from "lodash/get";
import { parseUrl } from "query-string";
import { useScrollTopOnMount, useToggle } from "@hooks";
import { ErrorLoading } from "@organisms";
import CheckoutThanksView from "./CheckoutThanksView";

// == Types ================================================================

interface IRouteProps {
  // id?: string;
  isLoading?: boolean;
  // isHome: boolean;
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================
export const CHECKOUT_VIEW_QUERY = gql`
  query CheckoutThanksViewQuery($token: String!) {
    GiftcardIntent(token: $token) {
      id
      isPending
      ...CheckoutThanksViewFragment
    }
  }
  ${CheckoutThanksView.fragments}
`;

CheckoutThanksViewQuery.defaultProps = {
  isHome: false,
  isLoading: false,
  id: null,
};

// == Component ============================================================

export function CheckoutThanksViewQuery({ location, isLoading }: TProps) {
  useScrollTopOnMount();

  const {
    state: { buyIntent },
    href,
  } = location;
  const [pendingTimeoutFinished, togglePending] = useToggle(false);
  const parsedUrl = parseUrl(href);
  const token = get(parsedUrl, "query.t");
  const locationToken = get(buyIntent, "token");
  const { loading, data, error, startPolling, stopPolling } = useQuery(CHECKOUT_VIEW_QUERY, {
    variables: { token: locationToken || token, skip: !token && !locationToken },
  });

  const hasNotLoaded = isEmpty(data) || loading || isLoading;

  useEffect(() => {
    startPolling(2000);
    const polling = setTimeout(() => {
      stopPolling();
      togglePending();
    }, 6000);
    return () => {
      stopPolling();
      clearTimeout(polling);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <ErrorLoading />;
  }

  if (hasNotLoaded) return null;

  const dataGiftcardIntent = oc(data).GiftcardIntent();

  if (!dataGiftcardIntent && !buyIntent) return <ErrorLoading />;
  return (
    <CheckoutThanksView
      buyIntent={dataGiftcardIntent || buyIntent}
      location={location}
      pendingTimeoutFinished={pendingTimeoutFinished}
    />
  );
}

// == Styles ===============================================================
