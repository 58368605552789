export const spelling = (
  word: "favorites" | "checks",
  { capitalize = true, singular = false, country = "USA" } = {}
) => {
  const spellingOptions = {
    favorites: country === "USA" ? "favorites" : "favourites",
    checks: country === "USA" ? "checks" : "cheques",
  };
  let correctWord = spellingOptions[word];
  if (singular) correctWord = correctWord.slice(0, -1);
  if (!capitalize) return correctWord;
  return correctWord[0].toUpperCase() + correctWord.slice(1); // eslint-disable-line @typescript-eslint/restrict-plus-operands
};
