import React from "react";
import { string } from "prop-types";

export default function LoadingTeamsSpinner({ text }) {
  return (
    <div className="centered">
      <div className="preloader-wrapper big active">
        <div className="spinner-layer spinner-primary-only">
          <div className="circle-clipper left">
            <div className="circle" />
          </div>
          <div className="gap-patch">
            <div className="circle" />
          </div>
          <div className="circle-clipper right">
            <div className="circle" />
          </div>
        </div>
      </div>
      <h2 className="centered mt-3">{text}</h2>
    </div>
  );
}

LoadingTeamsSpinner.propTypes = {
  text: string,
};

LoadingTeamsSpinner.defaultProps = {
  text: "Loading Teams...",
};
