import React from "react";
import gql from "graphql-tag";
import { Box, Text, Row, Column } from "@atoms";
import { OnboardingBrandItem } from "../OnboardingBrandItem/OnboardingBrandItem";
import OnboardingBrandsGroup from "../OnboardingBrandsGroup/OnboardingBrandsGroup";
import { OnboardingBrandsSearchResultsFragment } from "./__generated__/OnboardingBrandsSearchResultsFragment";

// == Types ================================================================

interface IProps {
  inputValue: string | null;
  loading: boolean;
  brands: OnboardingBrandsSearchResultsFragment[];
  activeBrands: string[];
  onBrandClick: (id: string) => void;
}

// == Constants ============================================================

OnboardingBrandsSearchResults.fragments = {
  fields: gql`
    fragment OnboardingBrandsSearchResultsFragment on Brand {
      id
      ...OnboardingBrandItemFragment
    }
    ${OnboardingBrandItem.fragments}
  `,
};

// == Component ============================================================

export default function OnboardingBrandsSearchResults({
  inputValue,
  loading,
  brands,
  activeBrands,
  onBrandClick,
}: IProps) {
  if (!inputValue || inputValue.length < 3)
    return (
      <Text display="block" p={3} pl="60px" pr={3}>
        Please enter 3 or more characters...
      </Text>
    );

  if (loading)
    return (
      <Text display="block" pl="60px" pr={3} py={3}>
        Searching...
      </Text>
    );

  if (brands.length === 0)
    return (
      <Text display="block" pl="60px" pr={3} py={3}>
        No results found
      </Text>
    );

  return (
    <Box mx={[0, 3]}>
      <OnboardingBrandsGroup
        activeBrands={activeBrands}
        allowShowMore={false}
        brands={brands}
        name={`Search Results for ${inputValue}`}
        onBrandClick={onBrandClick}
      />
    </Box>
  );
}
// == Styles ===============================================================
