import React from "react";
import styled from "@emotion/styled";
import { useMutation } from "react-apollo";
import produce from "immer";
import { Flex, TouchableOpacity } from "@components/common/atoms";
import { BrandListItem } from "@components/common/pages/brandListComponents";
import { ClearButton } from "@components/common/atoms/Button/ClearButton";
import {
  FAVORITE_BRANDS_QUERY,
  FavoriteBrands,
  TOGGLE_FAVORITE_BRAND_MUTATION,
  ToggleFavoriteBrandMutation,
  ToggleFavoriteBrandMutationVariables,
} from "@queries";

// == Types ================================================================

interface IProps {
  brand: $FixMe;
}

// == Constants ============================================================

// == Component ============================================================

const FavoritesModalSearchResultItem = ({ brand }: IProps) => {
  const [ToggleFavoriteBrand] = useMutation<
    ToggleFavoriteBrandMutation,
    ToggleFavoriteBrandMutationVariables
  >(TOGGLE_FAVORITE_BRAND_MUTATION);
  const toggleFavoriteBrand = () => {
    ToggleFavoriteBrand({
      variables: { brandId: brand.node.id },
      update(cache, { data }) {
        if (!data) return;

        const { ViewerToggleFavoriteBrand } = data;

        if (ViewerToggleFavoriteBrand.__typename === "MutationError") return;

        const cacheData = cache.readQuery<FavoriteBrands>({
          query: FAVORITE_BRANDS_QUERY,
        })!;
        const newData = produce(cacheData, ({ Viewer }) => {
          if (ViewerToggleFavoriteBrand.viewerHasFavorited) {
            Viewer.favoriteBrands.push(ViewerToggleFavoriteBrand);
            Viewer.favoriteBrandsCount = (Viewer.favoriteBrandsCount || 0) + 1;
          } else {
            Viewer.favoriteBrands = Viewer.favoriteBrands.filter(
              (favoriteBrand) => favoriteBrand && favoriteBrand.viewerHasFavorited
            );
            Viewer.favoriteBrandsCount = (Viewer.favoriteBrandsCount || 0) - 1;
          }
        });

        cache.writeQuery({
          query: FAVORITE_BRANDS_QUERY,
          data: newData,
        });
      },
    });
  };
  return (
    <Flex>
      <TouchableOpacity
        containerProps={{ style: { width: "100%" } }}
        width="100%"
        onTouch={toggleFavoriteBrand}
      >
        <EFlex alignItems="center" justifyContent="space-between" pr={[1, 2, 3]}>
          <BrandListItem brand={brand.node} px="45px" py={2} />
          <ClearButton>{brand.node.viewerHasFavorited ? "Remove" : "Add"}</ClearButton>
        </EFlex>
      </TouchableOpacity>
    </Flex>
  );
};

export default FavoritesModalSearchResultItem;

// == Styles ===============================================================

const EFlex = styled(Flex)`
  &:hover {
    background: #eee;
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
