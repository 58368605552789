import React from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";
import gql from "graphql-tag";
import { parseISO } from "date-fns/fp";
import { prettyUrl, renderIf } from "@services";
import { GiveSentence } from "@utils";
import { useStoreAppValue } from "@hooks";
import { Box, Text } from "@atoms";
import { InStoreGivesFragment } from "./__generated__/InStoreGivesFragment";
// == Types ================================================================

type TLink = InStoreGivesFragment;

interface IProps {
  link: TLink;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment InStoreGivesFragment on Link {
      id
      giveSentence
      giveAmount
      promoSecondaryLabel
      promoEndsAt
      positiveGiveAmount
      baselineGiveAmount
    }
  `,
};
// == Component ============================================================

const InStoreGives = ({ link }: IProps) => {
  const { incentiveAvailable, incentiveAmount } = useStoreAppValue();
  const giveSentence = GiveSentence({
    positiveGiveAmount: link.positiveGiveAmount,
    baselineGiveAmount: link.baselineGiveAmount,
    giveSentence: link.giveSentence,
  });
  return (
    <Box mb={3} textAlign="center">
      <Text display="block" lineHeight="1" textAlign="center" textStyle="givesText">
        {giveSentence}
        {incentiveAvailable &&
          !!incentiveAmount &&
          ` +$${parseFloat(incentiveAmount).toFixed()} Bonus`}
      </Text>

      {renderIf(!!link.promoSecondaryLabel || !!link.promoEndsAt)(
        <Box textAlign="center">
          {!!link.promoSecondaryLabel && (
            <EPromoLabelText
              as="div"
              color="default"
              dangerouslySetInnerHTML={{ __html: link.promoSecondaryLabel }}
            />
          )}
        </Box>
      )}
      {!!link.promoEndsAt && (
        <>
          <Text display="block" fontSize={0} fontWeight="bold" mt={1} textAlign="center">
            Gives back immediately
          </Text>
          <Text color="red" display="block">
            This offer is not available until{" "}
            {format(parseISO(link.promoEndsAt), "MMM d, yyyy h:mma")}
          </Text>
          <Text display="block" fontSize={0} mb={2}>
            See Terms & Conditions
          </Text>
        </>
      )}
    </Box>
  );
};

InStoreGives.fragments = FRAGMENTS;

export default InStoreGives;

// == Styles ===============================================================

const EPromoLabelText = styled(Text)`
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  * {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes[0]}px;
  }
`;
