import React, { useMemo } from "react";
import gql from "graphql-tag";
import { DropDown } from "@components/common/molecules";
import { Flex, Text, TouchableOpacity } from "@atoms";
import { useStoreAppValue, useToggle } from "@hooks";
import { FundsDistributionMethod, FundraiserRole } from "@types";
import { theme } from "@styles";
import { ReassignMember, RemoveMember, ManageBudgetAccess, MakeCaptain } from "../Modals";
import { groupedFundraisersMaker } from "../MemberListGrouped";
import {
  MemberListGroupedFragment,
  MemberListGroupedFragment_groups,
  MemberListGroupedFragment_noGroupFundraisers,
} from "../MemberListGrouped/__generated__/MemberListGroupedFragment";
import { MemberListItemFragment } from "./__generated__/MemberListItemFragment";

// == Types ================================================================
interface IItem {
  label: string;
  slug: string;
}
interface IProps {
  activeReassignmentMemberId: string | null;
  campaign: MemberListGroupedFragment;
  fundraiser: MemberListItemFragment;
  group: MemberListGroupedFragment_groups | MemberListGroupedFragment_noGroupFundraisers;
  refetch: () => void;
  setActiveReassignmentMemberId: (value: string | null) => void;
}

// == Constants ============================================================

MemberListItem.fragment = gql`
  fragment MemberListItemFragment on Fundraiser {
    id
    name
    userId
    isOwner
    joinedAt
    deactivatedAt
    amountRaised {
      centsAmount
      dollarsAmount
      formatted
    }
    group {
      id
      name
      amountRaised {
        dollarsAmount
        formatted
      }
    }
    budgetRoles
  }
`;

MemberListItem.defaultProps = {};

// == Component ============================================================

export default function MemberListItem({
  activeReassignmentMemberId,
  campaign,
  fundraiser,
  group,
  refetch,
  setActiveReassignmentMemberId,
}: IProps) {
  // ** State Handling ***
  const [isRemoveMemberOpen, setIsRemoveMemberOpen] = useToggle(false);
  const [isManageBudgetAccessOpen, setIsManageBudgetAccessOpen] = useToggle(false);
  const [isMakeCaptainOpen, setIsMakeCaptainOpen] = useToggle(false);
  const { primary } = theme.colors;
  // *** Data Handling ***
  const groups = campaign?.groups;
  const noGroupFundraisers = campaign?.noGroupFundraisers;
  const sortedGroups = useMemo(
    () => groups?.sort((a, b) => b.amountRaised.dollarsAmount - a.amountRaised.dollarsAmount),
    [groups]
  );
  const groupedFundraisers = groupedFundraisersMaker(sortedGroups, noGroupFundraisers);
  const { id, name, userId, isOwner, joinedAt, deactivatedAt } = fundraiser;
  const currentUserId = useStoreAppValue()?.currentUser?.id.toString();
  const isViewerOwner = campaign?.isViewerOwner;
  const { PLAYER } = FundsDistributionMethod;
  const fundsDistributionMethod = campaign?.fundsDistributionMethod;

  // *** Rendering Logic ***
  const hasGroups = campaign.groups.length > 0;
  const isPending = !isOwner && !joinedAt && !deactivatedAt && !group;
  const isViewerFundraiser = fundraiser.userId === currentUserId;
  const viewerName = isViewerFundraiser && !isOwner ? `${name} (You)` : name;
  const isReassignable = !isPending && (isViewerOwner || isViewerFundraiser) && hasGroups;
  const isRemovable =
    (!isViewerOwner && isViewerFundraiser) || (isViewerOwner && !isViewerFundraiser);
  const canAssignBudgetAdminRole = campaign.hasBudget && isViewerOwner && !isViewerFundraiser;

  const dropdownItems: IItem[] = [];
  if (isReassignable) {
    dropdownItems.push({ label: "Reassign", slug: "edit" });
  }
  if (isRemovable) {
    dropdownItems.push({
      label: isViewerFundraiser ? "Leave Team" : "Remove From Team",
      slug: "remove",
    });
  }
  if (canAssignBudgetAdminRole) {
    const label = fundraiser.budgetRoles.includes(FundraiserRole.BUDGET_ADMIN) ? "Revoke" : "Make";
    dropdownItems.push({ label: `${label} Budget Admin`, slug: "manage_budget_access" });
  }
  if (isViewerOwner && !isViewerFundraiser) {
    dropdownItems.push({ label: "Make Captain", slug: "make_captain" });
  }

  if (deactivatedAt || isPending || (!!fundraiser?.id && !userId)) return null;
  return (
    <>
      <Flex
        alignItems="center"
        bg={isViewerFundraiser ? "lightNotificationBackground" : "none"}
        justifyContent="space-between"
        pl={[5]}
        pr={[3]}
        py={[1, 2, 3]}
      >
        <TouchableOpacity
          containerProps={{ style: { cursor: isReassignable ? "pointer" : "default" } }}
          onTouch={() => (isReassignable ? setActiveReassignmentMemberId(id) : null)}
        >
          <Flex alignItems="center" justifyContent="flex-start">
            <Flex alignItems="flex-start" flexDirection="column" marginLeft={[3]}>
              <Text display="block" fontSize={[1, 2]}>
                {name === id ? `${name} (Team)` : viewerName}
                {!!isOwner && " (Captain)"}
              </Text>
            </Flex>
          </Flex>
        </TouchableOpacity>
        {dropdownItems.length > 0 && (
          <Flex justifyContent="flex-end">
            <DropDown
              chevronColor={primary}
              items={dropdownItems}
              label=""
              onSelect={(slug) => {
                if (slug === "edit") setActiveReassignmentMemberId(id);
                if (slug === "remove") setIsRemoveMemberOpen(true);
                if (slug === "manage_budget_access") setIsManageBudgetAccessOpen(true);
                if (slug === "make_captain") setIsMakeCaptainOpen(true);
              }}
            />
          </Flex>
        )}
      </Flex>
      {activeReassignmentMemberId === id && hasGroups && (
        <ReassignMember
          activeReassignmentMemberId={activeReassignmentMemberId}
          currentGroup={group}
          groupedFundraisers={groupedFundraisers}
          id={id}
          name={name}
          refetch={refetch}
          setActiveReassignmentMemberId={setActiveReassignmentMemberId}
        />
      )}
      {!!isRemoveMemberOpen && (
        <RemoveMember
          id={id}
          isRemoveMemberOpen={isRemoveMemberOpen}
          isViewerFundraiser={isViewerFundraiser}
          name={name}
          setIsRemoveMemberOpen={setIsRemoveMemberOpen}
          userId={userId}
        />
      )}
      {!!isManageBudgetAccessOpen && (
        <ManageBudgetAccess
          closeModal={() => setIsManageBudgetAccessOpen(false)}
          id={id}
          isBudgetAdmin={fundraiser.budgetRoles.includes(FundraiserRole.CAPTAINABLE)}
          name={name}
          refetch={refetch}
        />
      )}
      {!!isMakeCaptainOpen && (
        <MakeCaptain closeModal={() => setIsMakeCaptainOpen(false)} id={id} name={name} />
      )}
    </>
  );
}

// == Styles ===============================================================
