import React from "react";
import { Box, Text, Flex } from "@atoms";

export default function NoTimelinePlaceholder() {
  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <Box my={[2, 3]}>
        <Text as="p" fontSize={[4, 5]} textAlign="center">
          Welcome to your News Feed
        </Text>
      </Box>
      <Box my={[2, 3]}>
        <Text as="p" textAlign="center">
          Here you&apos;ll find details on your earnings, announcements on new brands, contests and
          promotions as well as tips on how to earn more.
        </Text>
      </Box>
      <Box my={[2, 3]}>
        <Text as="p" textAlign="center">
          Check back soon!
        </Text>
      </Box>
    </Flex>
  );
}
