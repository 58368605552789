import React from "react";
import gql from "graphql-tag";
import { Flex, Column } from "@atoms";
import { CollectionContentItem } from "../CollectionContentItem";
import { DepartmentItem } from "../../departmentItemComponents";
import { CollectionDepartmentGridFragment } from "./__generated__/CollectionDepartmentGridFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionDepartmentGridFragment;
  storefrontId?: string;
}
// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment CollectionDepartmentGridFragment on Collection {
      id
      assets {
        ...CollectionContentItemFragment
      }
    }
    ${CollectionContentItem.fragments.fields}
  `,
};

// == Component ============================================================

const CollectionDepartmentGrid = ({ collection, storefrontId }: IProps) => {
  // will be a map of links that renders column with a link block in it
  return (
    <Flex flexWrap="wrap" justifyContent="center">
      {collection.assets.map((asset) => {
        return (
          <Column autoColumn={false} key={asset.image ?? ""} mb={3} width={[1 / 3, 1 / 4, 1 / 6]}>
            <DepartmentItem
              asset={asset}
              storefrontId={storefrontId}
              collectionId={collection.id}
            />
          </Column>
        );
      })}
    </Flex>
  );
};

export default CollectionDepartmentGrid;

CollectionDepartmentGrid.fragments = FRAGMENTS;
