import React from "react";
import { Circle, Text } from "@atoms";

// == Types ================================================================

interface IProps {
  text?: string;
  bg?: string;
  border?: string;
}

// == Component ============================================================

const CircleText = ({ text, bg, border = "silver" }: IProps) => {
  return (
    <Circle border={border} data-testid="circle" bg={bg}>
      <Text color="primary" fontSize={3}>
        {text}
      </Text>
    </Circle>
  );
};
export default CircleText;
