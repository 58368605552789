import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { getPaginatedNodes } from "@utils";
import { LinkPromotionBlock } from "@organisms";
import { Box, H1, Container, Row, Column } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

const DEALS_PAGE_QUERY = gql`
  query DealsQuery {
    LinkPromotion(first: 50) {
      edges {
        node {
          id
          ...LinkPromotionBlockFragment
        }
      }
    }
  }
  ${LinkPromotionBlock.fragment}
`;

DealsPage.defaultProps = {};

// == Component ============================================================

export function DealsPage() {
  const { data, loading, error } = useQuery<DealsQuery>(DEALS_PAGE_QUERY);

  if (loading || !!error) return null;
  const linkPromotions = getPaginatedNodes(data?.LinkPromotion.edges);
  if (linkPromotions.length === 0) return null;

  return (
    <>
      <Box bg="linkPromo" py={[4, 4, 5]}>
        <Container textAlign="center">
          <H1 color="linkPromoText" mb={2}>
            Your Top Deals
          </H1>
        </Container>
      </Box>
      <Container isMainContainer>
        <Row>
          {linkPromotions.map((linkPromotion) => {
            if (!linkPromotion.link) return null;
            return (
              <Column autoColumn={false} key={linkPromotion.id} mb={3} width={["100%", "50%"]}>
                <LinkPromotionBlock linkPromotion={linkPromotion} />
              </Column>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

// == Styles ===============================================================
