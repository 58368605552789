export enum CustomStorefrontsIds {
  nearby = "-1",
  newsfeed = "-2",
  allBrands = "-3",
}

export const customStorefrontsNames = {
  ["-1"]: "nearby",
  ["-2"]: "newsfeed",
  ["-3"]: "allBrands",
};
