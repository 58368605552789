import React from "react";
import { differenceInCalendarDays } from "date-fns";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { theme } from "@styles";
import { TextUppercase, Box, Flex, Heading } from "@atoms";
import { OccasionPreviewFragment } from "./__generated__/OccasionPreviewFragment";
// == Types ================================================================

interface IProps {
  occasion: OccasionPreviewFragment;
  imageWidth: number;
  onClick?: (occasion: OccasionPreviewFragment) => void;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment OccasionPreviewFragment on Occasion {
      id
      heroHeader
      heroHeaderColor
      heroPromoLabel
      heroImage
      heroSubHeader
      heroSubHeaderColor
      showDaysLeft
      startDate
      endDate
      heroBackgroundColor
      squareHeroImage
    }
  `,
};

const DEFAULT_PROPS = {
  imageWidth: theme.sizes.containerWidth,
};

// == Component ============================================================
OccasionPreview.defaultProps = DEFAULT_PROPS;
OccasionPreview.fragments = FRAGMENTS;

export function OccasionPreview({ occasion, imageWidth, onClick }: IProps) {
  const {
    heroHeader,
    heroHeaderColor,
    heroImage,
    heroSubHeader,
    heroPromoLabel,
    heroSubHeaderColor,
    showDaysLeft,
    endDate,
    heroBackgroundColor,
    squareHeroImage,
  } = occasion;

  const onContainerClick = () => {
    if (onClick) {
      onClick(occasion);
    }
  };

  const daysLeft = Math.abs(differenceInCalendarDays(new Date(), new Date(endDate)));

  const backgroundColor = heroBackgroundColor || theme.colors.primary;
  const bgImage = squareHeroImage || heroImage;
  const backgroundContent = bgImage
    ? `url(${bgImage}&twic=v1/resize=${imageWidth})`
    : backgroundColor;
  const subheaderColor = heroSubHeaderColor || "white";

  return (
    <EHeroContainer
      background={backgroundContent}
      position="relative"
      width="100%"
      onClick={onContainerClick}
    >
      <Flex
        alignItems="center"
        bottom={0}
        flexDirection="column"
        height="100%"
        justifyContent="center"
        left={0}
        padding={[1, 2, 3]}
        position="absolute"
        right={0}
        top={0}
      >
        {!!heroPromoLabel && (
          <Box bg="white" borderRadius={4} color="black" fontSize={[0, 1]} mb={[2, 3]} p={[1, 2]}>
            {heroPromoLabel}
          </Box>
        )}
        <Heading
          color={heroHeaderColor}
          fontSize={[4, 5, 6]}
          lineHeight="title"
          mb={[2, 2]}
          ph={[1, 2, 3]}
          textAlign="center"
        >
          {heroHeader}
        </Heading>
        <TextUppercase as="p" color={subheaderColor} fontSize={0} mb={[1, 2, 3]} textAlign="center">
          {heroSubHeader}
        </TextUppercase>
        {showDaysLeft && (
          <Box bg="white" borderRadius={4} color="black" fontSize={[0, 1]} mt={1} p={[1, 2]}>
            {daysLeft} Days Left
          </Box>
        )}
      </Flex>
    </EHeroContainer>
  );
}

// == Styles ===============================================================
const EHeroContainer = styled(Flex)`
  background-size: cover;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;
