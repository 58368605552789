import React from "react";
import gql from "graphql-tag";
import striptags from "striptags";
import { Box, IBoxProps, Text, H3, TouchableOpacity } from "@atoms";
import { renderIf } from "@services";
import { useToggle } from "@hooks";
import { LinkModalHtmlTerms } from "../../linkModalComponents/LinkModalHtmlTerms";
import { InStoreTermsFragment } from "./__generated__/InStoreTermsFragment";

// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends IBoxProps {
  link: InStoreTermsFragment;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment InStoreTermsFragment on Link {
      id
      terms
      termsSummary
    }
  `,
};

// == Component ============================================================

const InStoreTerms = ({ link, ...rest }: IProps) => {
  const [showFullTerms, toggleShowFullTerms] = useToggle(true);

  return (
    <Box {...rest}>
      {renderIf(!!link.terms || !!link.termsSummary)(
        <Box mb={3}>
          <H3>Terms & Conditions</H3>
          {renderIf(!!link.termsSummary)(
            <Text display="block" textStyle="legal">
              <LinkModalHtmlTerms source={link.termsSummary} />
            </Text>
          )}
          {renderIf(!!link.terms)(
            <>
              {renderIf(showFullTerms)(<LinkModalHtmlTerms source={link.terms} />)}
              <TouchableOpacity onTouch={toggleShowFullTerms}>
                <Text color="primary" display="block" mb={2} textStyle="legal">
                  {showFullTerms ? "Hide" : "See"} full terms & conditions
                </Text>
              </TouchableOpacity>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

InStoreTerms.fragments = FRAGMENTS;

// == Styles ===============================================================
export default InStoreTerms;
