import React from "react";
import gql from "graphql-tag";
import queryString from "query-string";
import { useQuery } from "react-apollo";
import { isEmpty } from "lodash";
import { Location, LocationContext } from "@reach/router";
import { useLoadingDelay, useStoreAppValue } from "@hooks";
import { Box, Text } from "@atoms";
import { LinkModalLoading } from "../LinkModalLoading";
import LinkModal from "./LinkModal";
import {
  LinkModalQuery as LinkModalQueryType,
  LinkModalQueryVariables,
} from "./__generated__/LinkModalQuery";

// == Types ================================================================

interface IProps {
  campaignId: string;
  linkId: string;
  locationId?: string;
}

// == Constants ============================================================

export const LINK_MODAL_QUERY = gql`
  query LinkModalQuery(
    $id: ID!
    $campaignId: ID
    $hasUser: Boolean!
    $hasCampaign: Boolean!
    $promotionId: ID!
    $hasPromotionId: Boolean!
  ) {
    Link(id: $id) {
      id
      type
      ...LinkModalFragment
    }
    Campaign(id: $campaignId) @include(if: $hasCampaign) {
      id
      ...LinkModalCampaignFragment
    }
  }
  ${LinkModal.fragments.link}
  ${LinkModal.fragments.campaign}
`;

// == Component ============================================================

export const LinkModalQuery = ({ locationId, linkId, campaignId }: IProps) => {
  const loadingDelayReady = useLoadingDelay();
  const queryParams = queryString.parse(location.search);
  const { currentUser } = useStoreAppValue();
  const { data, error, loading } = useQuery<LinkModalQueryType, LinkModalQueryVariables>(
    LINK_MODAL_QUERY,
    {
      variables: {
        id: linkId,
        campaignId: campaignId || null,
        hasUser: !!currentUser,
        hasCampaign: !!campaignId,
        promotionId: queryParams?.link_promotion_id ?? "0",
        hasPromotionId: !!queryParams.link_promotion_id,
      },
    }
  );
  if (error) {
    return (
      <Box>
        <Text>There was a problem, please try again.</Text>
      </Box>
    );
  }
  if (loading || isEmpty(data) || !loadingDelayReady) return <LinkModalLoading />;
  const link = data?.Link;
  const campaign = data?.Campaign;
  if (!link) return null;

  return (
    <Location>
      {(locationContext: LocationContext) => {
        return (
          <LinkModal
            campaign={campaign}
            campaignId={campaignId}
            link={link}
            locationContext={locationContext}
            locationId={locationId}
          />
        );
      }}
    </Location>
  );
};

// == Styles ===============================================================
