import React, { useMemo } from "react";
import { useStoreAppValue, useWindowSize } from "@hooks";
import { Box, Flex, Text } from "@atoms";
import { CheckboxComponent } from "@components";
import "./OnboardingTodo.css";
import { MOBILE_WIDTH } from "./constants";
import { useOnboardingSteps } from "./useOnboardingSteps";

const titleStyles = {
  fontSize: 20,
  fontWeight: "bold",
  color: "#000",
  textAlign: "center",
  width: "100%",
  display: "block",
};

const checkboxTitleStyles = {
  fontSize: 16,
  color: "#22BCB9",
  fontWeight: "600",
  style: { textWrap: "nowrap" },
};

export const OnboardingTodo = () => {
  const { currentUser, isMember, isOwner } = useStoreAppValue();
  const { width } = useWindowSize();
  const isMobile = width < MOBILE_WIDTH;

  const stepsStatus = useOnboardingSteps();

  const Checkbox = useMemo(
    () => ({ title = "", description = "", checked = false, href = "" }) => (
      <Box className={`onb-checkbox ${isMobile ? "mobile" : ""}`} width={isMobile ? "100%" : "30%"}>
        <a href={href}>
          <CheckboxComponent
            checked={checked}
            name={title}
            label={<Text {...checkboxTitleStyles}>{title}</Text>}
            hint={isMobile ? "" : <Text color="#6B7280"> {description} </Text>}
            onChange={() => {
              document.location.href = href;
            }}
          />
        </a>
      </Box>
    ),
    [isMobile]
  );

  if (!currentUser || !stepsStatus.length || (!isMember && !isOwner)) return null;

  return (
    <Box mb={4}>
      <Box p={isMobile ? 20 : 30} borderRadius={8} bg="#E4F7F7" className="onb-todo">
        <Text {...titleStyles}>Finish team setup </Text>
        <Flex justifyContent="center" flexDirection={isMobile ? "column" : "row"} mt={16}>
          {stepsStatus.map((step) => (step ? <Checkbox key={step.title} {...step} /> : null))}
        </Flex>
      </Box>
    </Box>
  );
};
