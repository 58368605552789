import React from "react";
import gql from "graphql-tag";
import { navigate } from "@reach/router";
import { Breakpoint } from "react-socks";
import styled from "@emotion/styled";
import { constants } from "@services";
import { Box, H3, Image, Flex, Text, FontAwesome, TouchableOpacity } from "@atoms";
import { CollectionSingleFragment } from "./__generated__/CollectionSingleFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionSingleFragment;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment CollectionSingleFragment on Collection {
      id
      links(first: 1) {
        id
        name
        type
        logoImage
        giveSentence
      }
      assets {
        ...CollectionContentItemFragment
      }
    }
  `,
};

// == Component ============================================================

const CollectionSingle = ({ collection, storefrontId = "" }: IProps) => {
  const { links } = collection;
  if (!links) return null;
  const link = links[0];
  if (!link) return null;

  const onTouch = () => {
    navigate(
      `?${constants.linkParam}=${link.id}&${constants.storefrontParam}=${storefrontId}&${constants.collectionParam}=${collection.id}`
    );
  };

  // TODO: check if this component still can just render a single banner

  return (
    <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={onTouch}>
      <Flex alignItems="center" border="silver" p={2}>
        <Box maxWidth="100px" mr={3}>
          <Image alt={link.name} src={link.logoImage} />
        </Box>
        <Box>
          <Text fontSize={[1, 3]} fontWeight="bold">
            {link.name}
          </Text>
          <H3>{link.giveSentence}</H3>

          <Breakpoint xsmall>
            <Flex alignItems="center" justifyContent="flex-start">
              <Text color="primary" fontWeight="bold" mr={1}>
                Shop Now
              </Text>
              <Text color="primary">
                <FontAwesome icon="chevron-right" />
              </Text>
            </Flex>
          </Breakpoint>
        </Box>

        <EShopNowColumnBreakpoint small up>
          <Flex alignItems="center" flex={1} justifyContent="flex-end">
            <Text color="primary" fontSize={4} fontWeight="bold" mr={2}>
              Shop Now
            </Text>
            <Text color="primary">
              <FontAwesome color="primary" icon="chevron-right" />
            </Text>
          </Flex>
        </EShopNowColumnBreakpoint>
      </Flex>
    </TouchableOpacity>
  );
};

export default CollectionSingle;

// == Styles ===============================================================

const EShopNowColumnBreakpoint = styled(Breakpoint)`
  flex: 1;
`;
