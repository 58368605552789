import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Box, Container } from "@atoms";
import { MultiItemCarousel } from "../carouselComponents/MultiItemCarousel";

// == Types ================================================================

interface IProps {
  numItems: number;
  children: ReactNode;
}

// == Constants ============================================================

CenteredCarousel.defaultProps = {};

// == Component ============================================================

// component cannot be in a container
export default function CenteredCarousel({ children, numItems, ...rest }: IProps) {
  return (
    <EContainer>
      <MultiItemCarousel
        centered
        clickToChange
        dots
        breakpoints={{
          768: {
            slidesPerPage: 1,
            slidesPerScroll: 1,
          },
          992: {
            slidesPerPage: 1,
            slidesPerScroll: 1,
          },
        }}
        itemLength={numItems}
        slidesPerPage={1}
        slidesPerScroll={1}
        {...rest}
      >
        {children}
      </MultiItemCarousel>
    </EContainer>
  );
}

// == Styles ===============================================================
const EContainer = styled(Box)`
  .BrainhubCarousel {
    flex-wrap: wrap;
    justify-content: center;
  }

  .BrainhubCarousel__dots {
    margin-top: ${({ theme }) => theme.space[3]}px;
  }

  .BrainhubCarouselItem {
    &:not(.BrainhubCarouselItem--active) {
      .centered-carousel-mask {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          // this accounts for the margin that is put between, it's a hack
          // if you can think of a nicer way to do this im all ears!
          right: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: 1;
        }
      }
    }
  }
`;
