// default calculation is in miles, pass "K" as unit param
export function geoDistance(
  lat1?: number,
  lon1?: number,
  lat2?: number,
  lon2?: number,
  unit = "M",
  returnType = "STRING"
) {
  if (!lat1 || !lon1 || !lat2 || !lon2) return null;
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist *= 1.609344;
  }
  dist = Math.round(dist * 1e1) / 1e1;
  if (returnType === "VALUE") return dist;
  if (unit === "K") return `${dist}km`;
  return `${dist}mi`;
}
