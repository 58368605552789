import React from "react";

// == Types ================================================================

interface IProps {
  text?: string;
}

// == Constants ============================================================

// == Component ============================================================

export default function Spinner({ text }: IProps) {
  return (
    <div className="centered">
      <div className="preloader-wrapper big active">
        <div className="spinner-layer spinner-primary-only">
          <div className="circle-clipper left">
            <div className="circle" />
          </div>
          <div className="gap-patch">
            <div className="circle" />
          </div>
          <div className="circle-clipper right">
            <div className="circle" />
          </div>
        </div>
      </div>
      {!!text && <h2 className="centered mt-3">{text}</h2>}
    </div>
  );
}

// == Styles ===============================================================
