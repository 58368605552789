/* eslint-disable import/prefer-default-export */

import gql from "graphql-tag";

export const NOTIFICATIONS_COUNT_QUERY = gql`
  query ViewerNotificationsCount {
    Viewer {
      id
      unseenNotificationCount
    }
  }
`;

export const NOTIFICATIONS_QUERY = gql`
  query Notifications($first: Int!, $beforeId: ID) {
    Viewer {
      id
      notifications(first: $first, beforeId: $beforeId) {
        id
        isRead
        isSeen
        isPersonal
        time
        title
        description
        campaignIds
        activities(first: 5) {
          id
          time
          destinationActivityId
          actor {
            name
            avatarImage
          }
          campaign {
            id
            name
          }
        }
      }
    }
  }
`;
