import styled from "@emotion/styled";
import * as StyledSystem from "styled-system";
import { css } from "@emotion/core";
import { theme, themed, TThemeTextStyles } from "@styles";

// == Types ================================================================

interface ITextKnownProps
  extends $EmotionBaseProps,
    StyledSystem.FontFamilyProps,
    StyledSystem.FontWeightProps,
    StyledSystem.FontStyleProps,
    StyledSystem.TextAlignProps,
    StyledSystem.LineHeightProps,
    StyledSystem.LetterSpacingProps,
    // Inherit from Default Box
    StyledSystem.DisplayProps,
    StyledSystem.SpaceProps,
    StyledSystem.WidthProps,
    StyledSystem.FontSizeProps,
    StyledSystem.ColorProps,
    StyledSystem.FlexProps,
    StyledSystem.OrderProps,
    StyledSystem.AlignSelfProps {}

export interface ITextProps
  extends ITextKnownProps,
    Omit<React.HTMLProps<HTMLSpanElement>, keyof ITextKnownProps> {
  textStyle?: TThemeTextStyles;
  ellipsis?: boolean;
  uppercase?: boolean;
}

interface IEllipsisProps {
  ellipsis?: boolean;
}
interface IUppercaseProps {
  uppercase?: boolean;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  as: "span",
  m: 0,
  textDecoration: "none",
  ellipsis: false,
};

// == Component ============================================================

const ellipsisStyle = ({ ellipsis }: IEllipsisProps) => {
  if (!ellipsis) return null;
  return css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
};

const uppercaseStyle = ({ uppercase }: IUppercaseProps) => {
  if (!uppercase) return null;
  return css`
    text-transform: uppercase;
  `;
};

export const Text = styled<"span", ITextProps>("span")(
  {
    boxSizing: "border-box",
    fontFamily: theme.fonts.main,
    color: theme.colors.type.default,
    WebkitFontSmoothing: "antialiased",
    fontSmooth: "always",
    MozOsxFontSmoothing: "grayscale",
  },
  // Original Text Styles
  StyledSystem.fontFamily,
  StyledSystem.fontWeight,
  StyledSystem.fontStyle,
  StyledSystem.textAlign,
  StyledSystem.lineHeight,
  StyledSystem.letterSpacing,
  // Inherit from Default Box
  StyledSystem.display,
  StyledSystem.space,
  StyledSystem.width,
  StyledSystem.fontSize,
  StyledSystem.color,
  StyledSystem.flex,
  StyledSystem.order,
  StyledSystem.alignSelf,
  StyledSystem.textStyle,
  ellipsisStyle,
  uppercaseStyle,
  // StyledSystem.variant({ key: "text" }),
  themed("Text")
);

Text.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
