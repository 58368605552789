import React from "react";
import { bool, func } from "prop-types";

const SearchTeamButton = ({ mineOnly, toggleMineOnly }) => (
  <div
    className="user-transfer__search-button mt-2"
    role="button"
    tabIndex={0}
    onClick={toggleMineOnly}
    onKeyPress={toggleMineOnly}
  >
    <div className="user-transfer__search-icon">
      <i className="material-icons color-link">{mineOnly ? "add" : "arrow_back"}</i>
    </div>
    <div>{mineOnly ? "Find a team" : "Back to your teams"}</div>
  </div>
);

SearchTeamButton.propTypes = {
  mineOnly: bool.isRequired,
  toggleMineOnly: func.isRequired,
};

export default SearchTeamButton;
