import React from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { useMutation, useQuery } from "react-apollo";
import { ErrorService } from "@services";
import { Button } from "@atoms";

// == Types ================================================================

interface IProps {
  giftcardId: string;
}

// == Constants ============================================================

VoucherUpdateBalanceButton.fragment = gql`
  fragment VoucherUpdateBalanceButtonFragment on Giftcard {
    id
    giftcardType
    value {
      dollarsAmount
    }
    balance {
      dollarsAmount
    }
  }
`;

const VOUCHER_UPDATE_BALANCE_QUERY = gql`
  query VoucherUpdateBalanceQuery($ids: [ID!]) {
    Viewer {
      id
      giftcardsPaginated(first: 1, ids: $ids) {
        edges {
          cursor
          node {
            id
            ...VoucherUpdateBalanceButtonFragment
          }
        }
      }
    }
  }
  ${VoucherUpdateBalanceButton.fragment}
`;

const UPDATE_BALANCE_MUTATION = gql`
  mutation UpdateGiftcardBalanceVoucher($id: ID!, $lastBalance: Float!) {
    UpdateGiftcardBalance(input: { id: $id, lastBalance: $lastBalance }) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Giftcard {
        id
        updatedAt
        balance {
          dollarsAmount
          formatted
        }
      }
    }
  }
`;

// == Component ============================================================

export function VoucherUpdateBalanceButton({ giftcardId }: IProps) {
  const { data } = useQuery(VOUCHER_UPDATE_BALANCE_QUERY, {
    variables: {
      ids: [String(giftcardId)],
    },
  });

  const [fillBalance, { loading }] = useMutation(UPDATE_BALANCE_MUTATION, {
    onCompleted: ({ UpdateGiftcardBalance }) => {
      if (UpdateGiftcardBalance.__typename === "MutationError") return;
      Materialize.toast("Voucher has been updated", 4000, "rounded toast-success");
    },
  });

  const markUsed = () => {
    fillBalance({
      variables: {
        lastBalance: 0,
        id: giftcard.id,
      },
    }).catch(ErrorService.error);
  };

  const markUnused = () => {
    fillBalance({
      variables: {
        lastBalance: giftcard.value.dollarsAmount,
        id: giftcard.id,
      },
    }).catch(ErrorService.error);
  };

  if (!data?.Viewer?.giftcardsPaginated?.edges) return null;
  const edge = data.Viewer.giftcardsPaginated.edges[0];
  const giftcard = edge?.node;
  if (!giftcard)
    return (
      <EButton disabled width="100%">
        Checking Balance...
      </EButton>
    );

  if (giftcard.balance && giftcard.balance.dollarsAmount > 0) {
    return (
      <EButton disabled={loading} width="100%" onClick={markUsed}>
        {loading ? "Updating..." : "Mark as Used"}
      </EButton>
    );
  }

  return (
    <EButton disabled={loading} width="100%" onClick={markUnused}>
      {loading ? "Updating..." : "Mark as Unused"}
    </EButton>
  );
}

const EButton = styled(Button)`
  font-weight: bold;
`;
