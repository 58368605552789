import React, { createContext, useContext, useReducer, Reducer, Dispatch } from "react";
import { generateTheme } from "@styles";
import { SplitIOWrapper } from "./SplitIOWrapper";

export interface ICurrentUser {
  avatar_url: string;
  id: string;
  name: string;
  token: string;
  email: string;
}

export interface IReducerState {
  campaignId: string;
  currency: string;
  environment: string;
  currentUser?: ICurrentUser | null;
  featureFlags: {
    newCollection: boolean;
    newCheckout: boolean;
    nearbyTab: boolean;
    newBeneficiary: boolean;
  };
  fundraiserId?: string;
  incentiveAmount?: string;
  incentiveAvailable: boolean;
  incentiveTimeLeft?: string;
  isContinuity: boolean;
  isMember: boolean;
  isOwner: boolean;
  stripeKey?: string;
  gmapsKey?: string;
  plaidKey?: string;
  mapLocation?: {
    latitude: string;
    longitude: string;
    city: string;
  };
  siteName: string;
  theme: $FixMe;
  transaction?: {};
  pageType?: string;
  pageTitle?: string;
  isPartnerSite?: boolean;
  appUrl?: boolean;
  recaptchaEnterpriseKey?: boolean;
}

interface IReducerActions {
  type: "none";
}

// this is only used in storybook or testing
const INITIAL_STATE: IReducerState = {
  campaignId: "1",
  environment: "prod",
  incentiveAvailable: false,
  currency: "CAD",
  featureFlags: {
    newCollection: true,
    newCheckout: true,
    nearbyTab: true,
    newBeneficiary: true,
  },
  isMember: true,
  isOwner: true,
  isContinuity: false,
  theme: generateTheme(),
  siteName: "FlipGive",
};

const reducer: Reducer<IReducerState, IReducerActions> = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const StateContext = createContext<IReducerState | undefined>(undefined);
const StateDispatchContext = createContext<Dispatch<IReducerActions> | undefined>(undefined);

export function StoreAppProvider({
  initialState,
  children,
}: {
  initialState: IReducerState;
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StateDispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </StateDispatchContext.Provider>
  );
}

export function useStoreAppValue() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useStoreAppValue must be used within a StoreAppProvider");
  }
  return context;
}

export function useStoreAppDispatch() {
  const context = useContext(StateDispatchContext);
  if (context === undefined) {
    throw new Error("useStoreAppDispatch must be used within a StoreAppProvider");
  }
  return context;
}

export const useStoreAppContext = () => {
  return [useStoreAppValue(), useStoreAppDispatch()] as [IReducerState, Dispatch<IReducerActions>];
};

// For use with enzyme in tests, we maye need to look at doing something like:
// https://github.com/styled-components/jest-styled-components/issues/191#issuecomment-508752557
// https://github.com/styled-components/styled-components/issues/1319
export const withStoreProvider = (children: React.ReactNode) => (
  <StoreAppProvider initialState={INITIAL_STATE}>{children}</StoreAppProvider>
);
