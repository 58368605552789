import React, { useMemo, useState } from "react";
import { navigate, Link } from "@reach/router";
import queryString from "query-string";
import { Breakpoint } from "react-socks";
import styled from "@emotion/styled";
import { FullStoryEventNames, RailsUrl, constants, spelling } from "@services";
import { useFullStoryEvents, useStoreAppValue } from "@hooks";
import { Box, Text, Flex, Container, SearchIcon } from "@atoms";
import { theme } from "@styles";
import { BASE_SHOP_URL } from "../../../../services/constants";

// == Types ================================================================

interface IProps {
  showSearchBar: boolean;
}

// == Constants ============================================================

export enum SHOP_SUB_TABS_ENUM {
  HOME = "HOME",
  STORE = "STORE",
  GIFT_CARDS = "GIFT_CARDS",
  BONUS_GIVES = "BONUS_GIVES",
  NEARBY = "NEARBY",
  TEAM_STORE = "TEAM_STORE",
  FAVORITES = "FAVORITES",
  TEAM_BUY = "TEAM_BUY",
  ALL_BRANDS = "ALL_BRANDS",
  DEALS = "DEALS",
  HOLIDAY = "HOLIDAY",
  TRAVEL = "TRAVEL",
}

export enum TAB_TYPE {
  MOBILE = "MOBILE",
  WEB = "WEB",
}

ShopSubTabs.defaultProps = {
  showSearchBar: true,
};

// == Component ============================================================

export default function ShopSubTabs({ showSearchBar }: IProps) {
  // *** TODO: to be upgraded to use useLocation found in reach/router v1.3.1
  const [activeSubTab, setActiveSubTab] = useState(() => {
    const query = queryString.parse(window.location.href);
    const [queryStr] = Object.keys(query);
    const queryArr = queryStr.split("/");

    if (queryArr.includes("flipgive_giftcards")) return SHOP_SUB_TABS_ENUM.GIFT_CARDS;
    if (queryArr.includes("nearby")) return SHOP_SUB_TABS_ENUM.NEARBY;
    if (queryArr.includes("team-store")) return SHOP_SUB_TABS_ENUM.TEAM_STORE;
    if (queryArr.includes("deals-store")) return SHOP_SUB_TABS_ENUM.DEALS;
    if (queryArr.includes("brands")) return SHOP_SUB_TABS_ENUM.ALL_BRANDS;
    if (queryArr.includes("holiday-guide")) return SHOP_SUB_TABS_ENUM.HOLIDAY;
    return SHOP_SUB_TABS_ENUM.HOME;
  });

  const { SEARCH_PLACEHOLDER } = constants;

  const { campaignId, currency, isMember } = useStoreAppValue();
  const {
    radii: { default: defaultRadii },
  } = theme;

  const {
    HOME,
    GIFT_CARDS,
    TEAM_STORE,
    ALL_BRANDS,
    DEALS,
    TRAVEL,
    TEAM_BUY,
    BONUS_GIVES,
  } = SHOP_SUB_TABS_ENUM;
  const showTeamStoreTab = !campaignId || isMember;
  const shopSubTabNames = {
    HOME: "Home",
    STORE: "Home",
    BONUS_GIVES: "Bonus Gives",
    GIFT_CARDS: "Gift Cards",
    NEARBY: "Nearby",
    TEAM_STORE: "Gear",
    FAVORITES: spelling("favorites", {
      country: `${currency === "USD" ? "USA" : "Canada"}`,
      capitalize: true,
      singular: false,
    }),
    TEAM_BUY: "TeamBuy",
    ALL_BRANDS: "All Brands",
    CATEGORIES: "Categories",
    DEALS: "Deals",
    HOLIDAY: "Holiday",
    TRAVEL: "Travel",
  };

  const { sendFullStoryEvent } = useFullStoryEvents();

  const onOpenSearchModal = () => {
    const url = RailsUrl.searchUrl();
    sendFullStoryEvent(FullStoryEventNames.searchInitiated);
    navigate(url);
  };

  const navigateToTab = (selectedTab: SHOP_SUB_TABS_ENUM) => {
    setActiveSubTab(selectedTab);
  };

  const renderMobileSearchBar = () => {
    return (
      <Flex alignItems="center" justifyContent="center" position="relative" width="100%">
        <EFakeInput onClick={onOpenSearchModal}>
          <Flex
            alignItems="center"
            border="silver"
            borderRadius={defaultRadii}
            boxShadow="none"
            flexDirection="row"
            fontSize="1rem"
            height="40px"
            justifyContent="flex-start"
            lineHeight="40px"
            marginBottom={0}
            width="100%"
          >
            <Box padding={3}>
              <Text color="primary" fontSize={[2, 3]}>
                <SearchIcon />
              </Text>
            </Box>
            <Box paddingRight={4}>
              <Text color="gray">{SEARCH_PLACEHOLDER}</Text>
            </Box>
          </Flex>
        </EFakeInput>
      </Flex>
    );
  };

  const renderSearchBar = () => {
    return showSearchBar ? (
      <Flex
        alignItems="center"
        background="white"
        border="silver"
        borderRadius={defaultRadii}
        justifyContent="space-between"
        position="relative"
        width="25%"
        marginLeft="20px"
      >
        <EFakeInput
          boxShadow="none"
          fontSize="1rem"
          height="40px"
          lineHeight="40px"
          marginBottom={0}
          px="10px"
          onClick={onOpenSearchModal}
        >
          <Box>
            <Text color="gray" fontSize={[1, 1, 2]}>
              {SEARCH_PLACEHOLDER}
            </Text>
          </Box>
        </EFakeInput>

        <Flex alignItems="center" backgroundColor="primary" height="40px" lineHeight="40px" px={3}>
          <Text color="white" fontSize={[2, 3]}>
            <SearchIcon />
          </Text>
        </Flex>
      </Flex>
    ) : (
      <Box height="40px" paddingLeft={4} px={3} width="41%" />
    );
  };

  const { MOBILE, WEB } = TAB_TYPE;

  const renderTab = (tabType: TAB_TYPE, tab: SHOP_SUB_TABS_ENUM) => {
    if (tab === BONUS_GIVES) {
      return (
        <a
          style={{
            color: "#666",
            fontSize: tabType === MOBILE ? "14px" : "16px",
            marginTop: tabType === MOBILE ? "-16px" : "0px",
          }}
          href={`${BASE_SHOP_URL}/bonus-gives`}
        >
          {shopSubTabNames[tab]}
        </a>
      );
    }

    if (tabType === MOBILE) {
      return (
        <ECursorPointer
          to={RailsUrl.shopSubTabsUrls[tab](campaignId)}
          onClick={() => navigateToTab(tab)}
          theme={theme}
        >
          <Box mx={2} marginBottom={2} marginTop={-2}>
            <Text
              color={activeSubTab === tab ? "primary" : ""}
              fontSize={1}
              fontWeight={activeSubTab === tab ? "bold" : "regular"}
            >
              {shopSubTabNames[tab]}
            </Text>
          </Box>
        </ECursorPointer>
      );
    }

    return (
      <ECursorPointer
        to={RailsUrl.shopSubTabsUrls[tab](campaignId)}
        onClick={() => navigateToTab(tab)}
        theme={theme}
      >
        <Box mx={showTeamStoreTab ? "" : 3}>
          <Text color={activeSubTab === tab ? "primary" : ""}>{shopSubTabNames[tab]}</Text>
        </Box>
      </ECursorPointer>
    );
  };

  const renderTabs = (tabType: TAB_TYPE) => {
    if (tabType === MOBILE) {
      return (
        <>
          {renderTab(MOBILE, HOME)}
          {renderTab(MOBILE, GIFT_CARDS)}
          {renderTab(MOBILE, BONUS_GIVES)}
          {renderTab(MOBILE, DEALS)}
          {renderTab(MOBILE, TRAVEL)}
          {showTeamStoreTab && renderTab(MOBILE, TEAM_STORE)}
          {renderTab(MOBILE, TEAM_BUY)}
          {renderTab(MOBILE, ALL_BRANDS)}
        </>
      );
    }

    return (
      <>
        {renderTab(WEB, HOME)}
        {renderTab(WEB, GIFT_CARDS)}
        {renderTab(WEB, BONUS_GIVES)}
        {renderTab(WEB, DEALS)}
        {renderTab(WEB, TRAVEL)}
        {showTeamStoreTab && renderTab(WEB, TEAM_STORE)}
        {renderTab(WEB, TEAM_BUY)}
        {renderTab(WEB, ALL_BRANDS)}
      </>
    );
  };

  const isTeamBuyPage = useMemo(() => {
    return window.location.href.includes("/stores/teambuy");
  }, []);

  if (isTeamBuyPage) return null;

  return (
    <Box bg="#FAFBFC" margin={0} pb={2} pt={2}>
      <Breakpoint xsmall>
        <Flex alignItems="center" flexDirection="column" justifyContent="center">
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="flex-start"
            px={2}
            py={2}
            width="100%"
          >
            <EMobileSubTabs theme={theme}>{renderTabs(MOBILE)}</EMobileSubTabs>
          </Flex>
          {!!showSearchBar && renderMobileSearchBar()}
        </Flex>
      </Breakpoint>
      <Breakpoint small>
        <Flex alignItems="center" flexDirection="column" justifyContent="center">
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
            px={2}
            py={2}
            width="100%"
          >
            <EMobileSubTabs theme={theme}>{renderTabs(MOBILE)}</EMobileSubTabs>
          </Flex>
          {!!showSearchBar && renderMobileSearchBar()}
        </Flex>
      </Breakpoint>
      <Breakpoint medium>
        <Flex alignItems="center" flexDirection="column" justifyContent="center">
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
            px={2}
            py={2}
            width="100%"
          >
            <EMobileSubTabs theme={theme}>{renderTabs(MOBILE)}</EMobileSubTabs>
          </Flex>
          {!!showSearchBar && renderMobileSearchBar()}
        </Flex>
      </Breakpoint>
      <Breakpoint large up>
        <Container>
          <Flex alignItems="center" flexDirection="row" justifyContent="center" my={3}>
            <Flex
              alignItems="center"
              justifyContent={showTeamStoreTab ? "space-between" : "flex-start"}
              px={3}
              width={showSearchBar ? "70%" : "100%"}
            >
              {renderTabs(WEB)}
            </Flex>
            {renderSearchBar()}
          </Flex>
        </Container>
      </Breakpoint>
    </Box>
  );
}

// == Styles ===============================================================

const EMobileSubTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
  white-space: nowrap;
  padding: ${({ theme }) => theme.space[2]}px;
`;

const ECursorPointer = styled(Link)`
  cursor: pointer;
  margin: 0 6px;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const EFakeInput = styled(Box)`
  cursor: pointer;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
`;
