import React from "react";
import { useQuery } from "react-apollo";
import { oc } from "ts-optchain";
import { useLoadingDelay } from "@hooks";
import { Text } from "@atoms";
import { FadeIn } from "@utils";
import { FAVORITE_BRANDS_QUERY, FavoriteBrands as TFavoriteBrandsQuery } from "@queries";
import { FavoritesModalListLoading } from "../FavoritesModalListLoading";
import { FavoritesModal } from "./FavoritesModal";

// == Types ================================================================

interface IProps {
  isLoading?: boolean;
}

// == Constants ============================================================

// == Component ============================================================

export const FavoritesModalQuery = ({ isLoading }: IProps) => {
  const { loading, data, error } = useQuery<TFavoriteBrandsQuery>(FAVORITE_BRANDS_QUERY, {
    partialRefetch: true,
  });
  const loadingDelayReady = useLoadingDelay();
  if (error) {
    return <Text>{error.message}</Text>;
  }
  // TODO: add loading for this component
  if (!data || loading || !loadingDelayReady || isLoading) return <FavoritesModalListLoading />;
  const brands = oc(data).Viewer.favoriteBrands([]);
  if (!brands) return null; // return error
  return (
    <FadeIn>
      <FavoritesModal brands={brands} />
    </FadeIn>
  );
};

// == Styles ===============================================================
