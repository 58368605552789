import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { Formik, Form } from "formik";
import { object, bool } from "yup";
import { useBeneficiaryContext } from "../../../context";
import { Button, Checkbox } from "../..";
import { BeneficiaryConfirmationContent } from "../BeneficiaryConfirmationContent";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {
// }

interface IConfirmationFormikValues {
  isAgreed: boolean;
}

// == Constants ============================================================

const DEFAULT_PROPS = {};

const BENEFICIARY_CREATE_MUTATION = gql`
  mutation BeneficiaryCreateMutationOld($input: BeneficiaryCreateInput!) {
    BeneficiaryCreate(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Beneficiary {
        id
      }
    }
  }
`;

const VALIDATION_SCHEMA = object().shape({
  isAgreed: bool()
    .oneOf([true], "Please agree to the terms before continuing")
    .required("Required"),
});

const HINT =
  "By checking this box, you acknowledge and agree that the beneficiary, purpose, and related content you publish in connection with your team are true and correct and you will manage and disburse all funds raised for your campaign in accordance therewith.";

// == Component ============================================================

export const BeneficiaryConfirmation = () => {
  const [globalState, stateDispatch] = useBeneficiaryContext();
  const [createBeneficiaryMutation, { loading }] = useMutation<$FixMe, $FixMe>(
    BENEFICIARY_CREATE_MUTATION
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (values, actions) => {
    const { setSubmitting } = actions;

    const { campaignId, deliveryValues, beneficiaryValues, redirectUrl } = globalState;
    const input = { campaignId, ...deliveryValues, ...beneficiaryValues };
    createBeneficiaryMutation({ variables: { input } })
      .then((response) => {
        const { data } = response;
        const { BeneficiaryCreate } = data;
        if (BeneficiaryCreate.errors) {
          const errorMessages = [];
          BeneficiaryCreate.errors.map((error: $FixMe) => {
            error.inputErrors.map((inputError) => {
              errorMessages.push(`${error.input} ${inputError}`);
            });
          });
          stateDispatch({ type: "updateErrors", errors: errorMessages });
          setSubmitting(false);
        } else {
          stateDispatch({ type: "updateErrors", errors: null });
          window.location.href = redirectUrl;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onBackClick = () => {
    stateDispatch({ type: "onFormBack" });
  };

  return (
    <Formik<IConfirmationFormikValues>
      initialValues={{ isAgreed: false }}
      validateOnBlur={false}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, actions) => onSubmit(values, actions)}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <div className="mb-3">
              <BeneficiaryConfirmationContent />
            </div>
            <Checkbox label={HINT} labelSize="small" name="isAgreed" />
            <Button
              as="input"
              disabled={isSubmitting}
              label={isSubmitting || loading ? "Submitting..." : "Submit Payment Info"}
              name="submit"
              type="submit"
            />
            <Button additionalClasses="mt-3" variant="clear" onClick={onBackClick}>
              Back
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

BeneficiaryConfirmation.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
