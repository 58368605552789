import React from "react";
import styled from "@emotion/styled";
import * as StyledSystem from "styled-system";
import { theme, themed, TThemeButtonVariants } from "@styles";
import { Box, IBoxProps } from "../Box";

// == Types ================================================================

interface IButtonKnownProps
  extends IBoxProps,
    StyledSystem.FontWeightProps,
    StyledSystem.BorderProps {}

export interface IButtonProps
  extends IButtonKnownProps,
    Omit<React.HTMLProps<HTMLButtonElement>, keyof IButtonKnownProps> {
  variant?: TThemeButtonVariants;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  variant: "primary" as const,
  as: "button" as const,
  fontSize: "inherit",
  m: 0,
  px: 3,
  py: 2,
  color: "white",
  bg: theme.colors.primary,
  border: 0,
  borderRadius: 4,
};

// == Component ============================================================

export const Button = styled(Box)(
  {
    appearance: "none",
    display: "inline-block",
    textAlign: "center",
    lineHeight: "inherit",
    textDecoration: "none",
    cursor: "pointer",
    ":disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
    ":focus": {
      outline: 0,
      boxShadow: `0px 1px 5px ${theme.colors.primary}`,
    },
  },
  StyledSystem.fontWeight,
  StyledSystem.border,
  StyledSystem.display,
  StyledSystem.buttonStyle,
  StyledSystem.variant({ key: "buttons" }),
  themed("Button")
) as React.FunctionComponent<IButtonProps>;

Button.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
