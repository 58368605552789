import React from "react";
import { oc } from "ts-optchain";
import gql from "graphql-tag";
import { Link } from "@reach/router";
import styled from "@emotion/styled";
import { BrandCircle } from "@organisms/brandCircleComponents";
import { RailsUrl } from "@services";
import { Link as LinkAtom } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { ImageListItem } from "@molecules";

// == Types ================================================================

interface IProps {
  brand: $FixMe;
  linkBrand: boolean;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment BrandListItemFragment on Brand {
      id
      name
      slug
      maxGiveLink {
        id
        giveSentence
      }
      ...BrandCircleFragment
    }
    ${BrandCircle.fragments.fields}
  `,
};

// == Component ============================================================

const BrandItem = ({ brand, ...rest }) => {
  const subText = oc(brand).maxGiveLink.giveSentence();
  return (
    <ImageListItem
      key={brand.id}
      leftComponent={<BrandCircle brand={brand} imageWidth={50} pad={9} />}
      mt={2}
      subText={subText}
      text={brand.name ? brand.name : "N/A"}
      {...rest}
    />
  );
};

const BrandListItem = ({ brand, linkBrand, ...rest }: IProps) => {
  const { currentUser, campaignId } = useStoreAppValue();

  if (linkBrand) {
    const href =
      currentUser || campaignId
        ? RailsUrl.brandModalUrl({ id: brand.id })
        : `/brands/${brand.slug}`;

    // return brand modal link
    if (!!currentUser || !!campaignId) {
      return (
        <ELink state={{ canReturn: true }} to={href}>
          <BrandItem brand={brand} {...rest} />
        </ELink>
      );
    }

    // anon, so link to anon brand page
    return (
      <LinkAtom href={href}>
        <BrandItem brand={brand} {...rest} />
      </LinkAtom>
    );
  }

  return <BrandItem brand={brand} {...rest} />;
};

BrandListItem.fragments = FRAGMENTS;
export default BrandListItem;

// == Styles ===============================================================

const ELink = styled(Link)`
  text-decoration: none;
`;
