import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { constants } from "@services";
import { Text } from "@atoms";
import { LinkModalLoading } from "../LinkModalLoading";
import LinkModalPostShop from "./LinkModalPostShop";
import { LinkModalPostShopQuery as ILinkModalPostShopQuery } from "./__generated__/LinkModalPostShopQuery";
import { LinkModalPostShopQueryFragment } from "./__generated__/LinkModalPostShopQueryFragment";

// == Types ================================================================

interface IProps {
  link: LinkModalPostShopQueryFragment;
}

// == Constants ============================================================

const LINK_MODAL_POST_SHOP_QUERY = gql`
  query LinkModalPostShopQuery($id: ID!) {
    Collection(id: $id) {
      id
      ...LinkModalPostShopCollectionFragment
    }
  }
  ${LinkModalPostShop.fragments.collection}
`;

LinkModalPostShopQuery.fragment = gql`
  fragment LinkModalPostShopQueryFragment on Link {
    id
    ...LinkModalPostShopLinkFragment
  }
  ${LinkModalPostShop.fragments.link}
`;

// == Component ============================================================

export default function LinkModalPostShopQuery({ link }: IProps) {
  const { loading, data, error } = useQuery<ILinkModalPostShopQuery>(LINK_MODAL_POST_SHOP_QUERY, {
    variables: {
      id: constants.featuredBrandsCollectionId,
    },
  });

  if (error) return <Text>{error.message}</Text>;
  if (!data || loading) return <LinkModalLoading />;
  if (!data.Collection) return null; // return error

  return <LinkModalPostShop collection={data.Collection} link={link} />;
}

// == Styles ===============================================================
