import React from "react";
import { string } from "prop-types";
import { Query } from "react-apollo";
import { get } from "lodash";
import { inject } from "mobx-react";
import { ACTIVITIES_QUERY } from "../../queries";
import Timeline from "./Timeline";

@inject("TimelineStore")
export default class TimelineData extends React.Component {
  static propTypes = {
    feed: string.isRequired,
  };

  render() {
    const { feed, TimelineStore } = this.props;

    return (
      <Query
        query={ACTIVITIES_QUERY}
        variables={{
          feed,
          publicView: TimelineStore.publicView,
        }}
      >
        {({ data }) => {
          const activities = get(data, "Activities.edges", []).map((edge) => edge.node);
          return <Timeline {...this.props} activities={activities} />;
        }}
      </Query>
    );
  }
}
