import React from "react";
import gql from "graphql-tag";
import { Breakpoint } from "react-socks";
import styled from "@emotion/styled";
import { BrandBlock } from "@organisms/brandBlockComponents";
import { Box, Flex, Column } from "@atoms";
import { MultiItemCarousel } from "@molecules";
import { CollectionBrandCarouselFragment } from "./__generated__/CollectionBrandCarouselFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionBrandCarouselFragment;
  storefrontId: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment CollectionBrandCarouselFragment on Collection {
      id
      brands(first: $carouselItems) {
        edges {
          cursor
          node {
            id
            ...BrandBlockFragment
          }
        }
      }
    }
    ${BrandBlock.fragments.fields}
  `,
};

// == Component ============================================================

const CollectionBrandCarousel = ({ collection, storefrontId }: IProps) => {
  const { brands } = collection;
  if (!brands) return null;
  const renderBrandRow = () => {
    return (
      <EFlex mx={[0, 0, -3]}>
        {brands.edges.slice(0, 6).map((brand, index) => (
          <Column autoColumn={false} key={brand.node.id} width={[0.45, 1 / 3, 1 / 4, 1 / 5]}>
            <BrandBlock
              brand={brand.node}
              collectionId={collection.id}
              storefrontId={storefrontId}
            />
          </Column>
        ))}
      </EFlex>
    );
  };
  return (
    <Box mx={[-2, -2, 0]} position="relative">
      {/*
        this mx above is a total hack, and will like cause me to regret it,
        but can't get the row to work properly otherwise
        the values there should be the same as what they are for the row
        in fact, bounty for putting this bad hack into a component for now
        cause it's used in multiple places.
        #rowHacks
      */}
      <Breakpoint xsmall>{renderBrandRow()}</Breakpoint>
      <Breakpoint small up>
        {brands.edges.length < 6 ? (
          renderBrandRow()
        ) : (
          <Box px={[0, 4, 4, 0]}>
            <MultiItemCarousel itemLength={brands.edges.length}>
              {brands.edges.map((brand, index) => (
                <Box alignSelf="flex-start" key={brand.node.id} px={[1]} width="100%">
                  <BrandBlock
                    brand={brand.node}
                    key={brand.id}
                    collectionId={collection.id}
                    storefrontId={storefrontId}
                  />
                </Box>
              ))}
            </MultiItemCarousel>
          </Box>
        )}
      </Breakpoint>
    </Box>
  );
};

CollectionBrandCarousel.fragments = FRAGMENTS;
export default CollectionBrandCarousel;

// == Styles ===============================================================

const EFlex = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`;
