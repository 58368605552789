import React from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { BrandSearchListItem } from "@organisms/brandSearchListItemComponents/BrandSearchListItem";
import { Text } from "@atoms";

// == Types ================================================================

interface IProps {
  inputValue: string | null;
  loading: boolean;
  brands: $FixMe;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment SearchModalResultsFragment on Brand {
      id
      ...BrandSearchListItemFragment
    }
    ${BrandSearchListItem.fragments.fields}
  `,
};

// == Component ============================================================

const SearchModalResults = ({ inputValue, loading, brands }: IProps) => {
  if (!inputValue || inputValue.length < 3)
    return (
      <Text display="block" p={3} pl="60px" pr={3}>
        Please enter 3 or more characters...
      </Text>
    );

  if (loading)
    return (
      <Text display="block" pl="60px" pr={3} py={3}>
        Searching...
      </Text>
    );

  if (brands.length === 0)
    return (
      <Text display="block" pl="60px" pr={3} py={3}>
        No results found
      </Text>
    );

  return brands.map((brand) => {
    return (
      <EBrandSearchListItem linkBrand borderBottom="silver" brand={brand} key={brand.id} p={3} />
    );
  });
};

SearchModalResults.fragments = FRAGMENTS;
export default SearchModalResults;

// == Styles ===============================================================

const EBrandSearchListItem = styled(BrandSearchListItem)`
  &:hover {
    background: #eee;
  }
`;
