import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import Confetti from "react-confetti";
import { RailsUrl, analytics } from "@services";
import { useStoreAppValue, useWindowSize } from "@hooks";
import { H1, Box, Button, Heading, Text, Flex } from "@atoms";
import { BrandShowcase, OnboardingContainer } from "../components";
import { OnboardingSuccessQuery } from "./__generated__/OnboardingSuccessQuery";

// == Types ================================================================

// == Constants ============================================================

const ONBOARDING_SUCCESS_QUERY = gql`
  query OnboardingSuccessQuery($skipCampaign: Boolean!) {
    Viewer {
      id
      onboarding {
        id
        earningsEstimate {
          dollarsAmount
          centsAmount
          formatted
        }
      }
      favoriteBrandsCount
    }
    Campaign @skip(if: $skipCampaign) {
      id
      isViewerOwner
    }
  }
`;

const DEFAULT_ESTIMATE = "$1,200";

// == Component ============================================================

export function OnboardingSuccess(_props: RouteComponentProps) {
  const { campaignId, isOwner } = useStoreAppValue();
  const { data, loading, error } = useQuery<OnboardingSuccessQuery>(ONBOARDING_SUCCESS_QUERY, {
    variables: {
      skipCampaign: !campaignId,
    },
  });
  const earningsEstimate = data?.Viewer.onboarding?.earningsEstimate;
  const userHasFavoriteBrands = data?.Viewer.favoriteBrandsCount > 0;
  const userIsOwner = data?.Campaign?.isViewerOwner;
  const { width } = useWindowSize();

  const routeToNextStep = () => {
    analytics.track.onboardingFinish({ campaignId }, isOwner);
    if (userHasFavoriteBrands && campaignId) {
      userIsOwner
        ? navigate(RailsUrl.onboardingGoalUrl)
        : (window.location.href = RailsUrl.setupTeamUrl(campaignId));
    }

    navigate(RailsUrl.onboardingBrandsUrl);
  };

  return (
    <>
      <Confetti
        run
        gravity={0.1}
        initialVelocityX={10}
        initialVelocityY={10}
        numberOfPieces={250}
        recycle={false}
        style={{ zIndex: 1, top: 64 }}
        width={width}
        wind={0}
      />
      <OnboardingContainer hasError={!!error} loading={loading}>
        <Flex alignItems="center" flexDirection="column" justifyContent="center">
          {/* ===Header begins=== */}
          <Box pt={3} px={4} textAlign="center">
            <H1>Cha Ching!</H1>
            <Text fontSize="1">
              If you shop with FlipGive this season you should be able to raise...
            </Text>
          </Box>
          {/* ===Header ends=== */}
          {/* ===User Earnings begins=== */}
          <Box py={3}>
            <Heading color="primary" fontSize="7">
              {earningsEstimate?.formatted || DEFAULT_ESTIMATE}
            </Heading>
          </Box>
          {/* ===User Earnings ends=== */}
          {/* ===Brand Showcase begins=== */}
          <Box py={3}>
            <BrandShowcase />
          </Box>
          {/* ===Brand Showcase ends=== */}
          {/* ===Finish Button begins=== */}
          <Button borderRadius="none" width="100%" onClick={routeToNextStep}>
            Continue
          </Button>
          {/* ===Finish Button ends=== */}
        </Flex>
      </OnboardingContainer>
    </>
  );
}

// == Styles ===============================================================
