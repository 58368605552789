import React from "react";
import gql from "graphql-tag";
import { oc } from "ts-optchain";
import { useQuery } from "react-apollo";
import { BrandListItem } from "@pages/brandListComponents/BrandListItem";
import { Text } from "@atoms";
import { FavoritesModalSearchResultItem } from "../FavoritesModalSearchResultItem";

// == Types ================================================================

interface IProps {
  selectedItem: $FixMe;
  highlightedIndex: $FixMe;
  getItemProps: $FixMe;
  inputValue: string | null;
}

// == Constants ============================================================

const BRAND_SEARCH_QUERY = gql`
  query FavoritesModalSearchResultQuery(
    $query: String!
    $first: Int!
    $after: String
    $coordinates: CoordinatesInput
  ) {
    BrandSearch(query: $query, first: $first, after: $after, coordinates: $coordinates)
      @connection(key: "BrandSearch", filter: ["query", "coordinates"]) {
      edges {
        cursor
        node {
          id
          viewerHasFavorited
          logoImage
          name
          ...BrandListItemFragment
          merchant {
            id
            department
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${BrandListItem.fragments.fields}
`;

// == Component ============================================================

const FavoritesModalSearchResultQuery = ({
  selectedItem,
  highlightedIndex,
  getItemProps,
  inputValue,
}: IProps) => {
  const { loading, data } = useQuery(BRAND_SEARCH_QUERY, {
    variables: { query: inputValue, first: 100 },
  });

  if (!inputValue || inputValue.length < 3)
    return (
      <Text display="block" p={3} px="45px">
        Please enter 3 or more characters...
      </Text>
    );

  if (loading)
    return (
      <Text display="block" px="45px" py={3}>
        Searching...
      </Text>
    );

  const brands = oc(data).BrandSearch.edges([]);
  if (brands.length === 0)
    return (
      <Text display="block" px="45px" py={3}>
        No results found
      </Text>
    );

  return brands.map((brand) => {
    return <FavoritesModalSearchResultItem brand={brand} key={brand.node.id} />;
  });
};

export default FavoritesModalSearchResultQuery;

// == Styles ===============================================================
