import React from "react";
import styled from "@emotion/styled";
import { parseISO, formatDistanceStrict, isFuture } from "date-fns";
import { Text, Box } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { theme } from "@styles";

// == Types ================================================================

interface IProps {
  giveSentence: string;
  secondaryLabel: string | null;
  promoEndsAt?: string;
  isBulkShop?: boolean;
}

// == Constants ============================================================

LinkBlockGives.defaultProps = {
  secondaryLabel: null,
  isBulkShop: false,
};

const {
  colors: { black, primary },
} = theme;

// == Component ============================================================

export default function LinkBlockGives({ giveSentence, secondaryLabel, promoEndsAt }: IProps) {
  return (
    <>
      <Text as="span" color={black} display="block" textAlign="center">
        {giveSentence}
      </Text>
      <Box lineHeight="solid" textAlign="center">
        {!!secondaryLabel && (
          <EText
            color={primary}
            dangerouslySetInnerHTML={{
              __html: secondaryLabel,
            }}
            fontSize={[0]}
            textAlign="center"
          />
        )}
        {!!promoEndsAt && isFuture(parseISO(promoEndsAt)) && (
          <EText color={primary} fontSize={[0]} textAlign="center">
            {" "}
            {formatDistanceStrict(parseISO(promoEndsAt), Date.now())} left
          </EText>
        )}
      </Box>
    </>
  );
}

// == Styles ===============================================================

const EText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  * {
    font-size: ${(props) => props.theme.fontSizes[0]}px;
  }
`;
