/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { shape, func, string } from "prop-types";

export default function FlipGiveTeamListItem({
  selectedFlipgiveTeam,
  setSelectedFlipgiveTeam,
  campaign,
}) {
  const onClick = (e) => {
    setSelectedFlipgiveTeam(campaign);
    e.preventDefault();
  };

  return (
    <a className="default-color-link campaign-list-item-link" href="#" onClick={onClick}>
      <div className="media campaign-list-item align-items-center mb-3">
        {!!campaign.photoImage && (
          <div className="campaign-list-item__image-container mr-3">
            <img
              alt={campaign.name}
              className="campaign-list-item__image"
              src={campaign.photoImage}
            />
          </div>
        )}

        <div className="media-body pr-2">
          <span className="campaign-list-item__name">{campaign.name}</span>
          <span className="campaign-list-item__raised">
            {!!campaign.amountRaised && <span>{campaign.amountRaised.formatted}</span>}
            {campaign.goal ? <span> of {campaign.goal.formatted} goal</span> : <span> earned</span>}
          </span>
        </div>
        <div
          className={`${
            !!selectedFlipgiveTeam && selectedFlipgiveTeam.id === campaign.id ? null : "opacity-0"
          }`}
        >
          <i className="material-icons color-link">check</i>
        </div>
      </div>
    </a>
  );
}

FlipGiveTeamListItem.propTypes = {
  campaign: shape({
    id: string,
    name: string,
    amountRaised: shape({
      formatted: string,
    }),
    goal: shape({
      formatted: string,
    }),
  }).isRequired,
  setSelectedFlipgiveTeam: func.isRequired,
  selectedFlipgiveTeam: shape({
    id: string,
    name: string,
    amountRaised: shape({
      formatted: string,
    }),
    goal: shape({
      formatted: string,
    }),
  }),
};

FlipGiveTeamListItem.defaultProps = {
  selectedFlipgiveTeam: null,
};
