import React from "react";
import gql from "graphql-tag";
import { Link, navigate } from "@reach/router";
import times from "lodash/times";
import { Breakpoint } from "react-socks";
import { BrandCircle } from "@organisms/brandCircleComponents";
import { FavoriteBrands_Viewer_favoriteBrands as TBrand } from "@queries";
import { Box, Flex } from "@atoms";
import { ContentBlock, CircleText, Spinner } from "@molecules";
import { constants, RailsUrl, spelling } from "@services";
import { useStoreAppValue } from "@hooks";
import { CustomCollectionsIds } from "@components/common/utils/customCollectionsIds";

// == Types ================================================================

interface IProps {
  brands: TBrand[];
  showMoreLink?: string;
  hasAddCircle?: boolean;
  title?: string;
  altLink?: string;
  altOnClick?: () => void;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isOneLocation?: boolean;
  topRightLink?: string;
  topRightLinkOnClick?: () => void;
  shouldFillLastCircle?: boolean;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment BrandFavoritesDialerFragment on Brand {
      id
      name
      logoImage
      logoBackgroundColor
      invertedLogoImage
      viewerHasFavorited
    }
  `,
};

export enum BREAKPOINTS_ENUM {
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

// == Component ============================================================

const BrandFavoritesDialer = ({
  brands,
  hasAddCircle = true,
  title,
  altLink,
  altOnClick,
  isFullWidth,
  isLoading,
  showMoreLink,
  isOneLocation,
  topRightLink,
  topRightLinkOnClick,
  shouldFillLastCircle,
}: IProps) => {
  const brandsCount = brands.length;
  const maxCircles = {
    XSMALL: isFullWidth ? 4 : 3,
    SMALL: isFullWidth ? 5 : 3,
    MEDIUM: isFullWidth ? 7 : 4,
    LARGE: isFullWidth ? 9 : 5,
  };

  const { currency } = useStoreAppValue();

  const onEditClick = () => {
    navigate(RailsUrl.favoritesUrl());
  };

  const renderBrandCircle = (brand) => {
    return (
      <Link
        to={RailsUrl.brandModalUrl({
          id: brand.id,
          locationId: isOneLocation ? brand.locationId : undefined,
          [constants.collectionParam]: CustomCollectionsIds.favorites,
        })}
      >
        <BrandCircle brand={brand} />
      </Link>
    );
  };

  const circleRenderSelector = (index: number, maxCirclesPerRow: number) => {
    const brandOverflowCount = brandsCount - (maxCirclesPerRow - 1);
    if (!brands[index]) {
      if (!hasAddCircle) return null;
      return (
        <Box
          key={`brand-fab-empty-circle-${index}`}
          mt={index < maxCirclesPerRow ? 0 : 2}
          px={[1, 2]}
          width={1 / maxCirclesPerRow}
          onClick={topRightLinkOnClick}
        >
          <CircleText bg="#f3f4f6" border="#f3f4f6" />
        </Box>
      );
    }

    // Last item, and greater than brands to show
    if (
      !shouldFillLastCircle &&
      index === maxCirclesPerRow - 1 &&
      brandsCount >= maxCirclesPerRow
    ) {
      return (
        <Box
          key={`brand-fab-empty-circle-${index}`}
          mt={index < maxCirclesPerRow ? 0 : 2}
          px={[1, 2]}
          width={1 / maxCirclesPerRow}
        >
          <a href={showMoreLink}>
            <CircleText text="..." />
          </a>
        </Box>
      );
    }

    // Default brand item
    return (
      <Box
        key={`${title}-${brands[index].id}`}
        mt={index < maxCirclesPerRow ? 0 : 2}
        px={[1, 2]}
        textAlign="center"
        width={1 / maxCirclesPerRow}
      >
        {renderBrandCircle(brands[index])}
      </Box>
    );
  };

  const renderRow = (breakpointEnum: BREAKPOINTS_ENUM) => {
    return (
      <Flex flexWrap="wrap" mx={-1}>
        {times(maxCircles[breakpointEnum], (index) =>
          circleRenderSelector(index, maxCircles[breakpointEnum])
        )}
      </Flex>
    );
  };

  return (
    <ContentBlock
      altLink={altLink}
      altOnClick={altOnClick || onEditClick}
      title={
        title ||
        spelling("favorites", {
          country: `${currency === "USD" ? "USA" : "Canada"}`,
          capitalize: true,
          singular: false,
        })
      }
      topRightLink={topRightLink}
      topRightLinkOnClick={topRightLinkOnClick}
    >
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <Breakpoint xsmall>{renderRow(BREAKPOINTS_ENUM.XSMALL)}</Breakpoint>
          <Breakpoint small>{renderRow(BREAKPOINTS_ENUM.SMALL)}</Breakpoint>
          <Breakpoint medium>{renderRow(BREAKPOINTS_ENUM.MEDIUM)}</Breakpoint>
          <Breakpoint large up>
            {renderRow(BREAKPOINTS_ENUM.LARGE)}
          </Breakpoint>
        </>
      )}
    </ContentBlock>
  );
};

BrandFavoritesDialer.fragments = FRAGMENTS;

export default BrandFavoritesDialer;

// == Styles ===============================================================
