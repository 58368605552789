import React from "react";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import { get, groupBy, find, isEmpty } from "lodash";
import { inject } from "mobx-react";
import { shape, string } from "prop-types";
import AttendanceList from "./AttendanceList";
import InvitationList from "./InvitationList";

const PARTICIPANTS_FRAGMENT = gql`
  fragment Participants on Event {
    participants {
      edges {
        node {
          id
          userId
          name
          status
          avatarImage
        }
      }
    }
  }
`;

const FUNDRAISERS_QUERY = gql`
  query Event($eventId: ID!) {
    Event(id: $eventId) {
      id
      ...Participants
      campaign {
        id
        name
        joinedFundraisers {
          id
          userId
          name
          avatarImage
        }
      }
    }
  }

  ${PARTICIPANTS_FRAGMENT}
`;

const INVITE_MUTATION = gql`
  mutation InvitesCreate($eventId: ID!, $participants: [ParticipantInput]!) {
    InvitesCreate(eventId: $eventId, participants: $participants) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Event {
        id
        ...Participants
      }
    }
  }
  ${PARTICIPANTS_FRAGMENT}
`;

@inject("FlipgiveStore")
export default class CampaignEventInvite extends React.Component {
  static propTypes = {
    FlipgiveStore: shape({}).isRequired,
    eventId: string.isRequired,
  };

  state = {
    userIdsToInvite: {},
    awaitingReload: false,
  };

  handleSend = (inviteMutation) => {
    const { eventId } = this.props;
    inviteMutation({
      variables: { eventId, participants: this.participantsToInvite() },
    });
  };

  participantsToInvite = () => {
    const { userIdsToInvite } = this.state;
    return Object.entries(userIdsToInvite).reduce((acc, [userId, checked]) => {
      if (checked) {
        acc.push({ userId });
      }
      return acc;
    }, []);
  };

  sendCompleted = () => {
    this.setState({ awaitingReload: true });
    window.location.reload(true);
  };

  buildInvitableFundraisers = (participants, fundraisers) => {
    const { FlipgiveStore } = this.props;
    const fundraisersExceptViewer = fundraisers.filter(
      (fundraiser) => fundraiser.userId !== String(FlipgiveStore.user_id),
    );
    const invitableFundraisers = fundraisersExceptViewer.filter(
      (fundraiser) => !find(participants, { userId: fundraiser.userId }),
    );
    return invitableFundraisers;
  };

  buildParticipants = (participants) => {
    const groupedResults = groupBy(participants, "status");
    return groupedResults;
  };

  handleCheck = (userId, checked) => {
    this.setState({
      userIdsToInvite: { ...this.state.userIdsToInvite, [userId]: checked },
    });
  };

  allowSubmit = () => {
    const { userIdsToInvite } = this.state;
    return Object.values(userIdsToInvite).some((checked) => checked);
  };

  render() {
    const { eventId, FlipgiveStore } = this.props;

    return (
      <Query query={FUNDRAISERS_QUERY} variables={{ eventId }}>
        {({ data }) => {
          const { userIdsToInvite, awaitingReload } = this.state;
          const participants = get(data, "Event.participants.edges", []).map((edge) => edge.node);
          const fundraisers = get(data, "Event.campaign.joinedFundraisers", []);
          const invitableFundraisers = this.buildInvitableFundraisers(participants, fundraisers);
          const groupedParticipants = this.buildParticipants(participants);
          const viewerIsParticipant = !!find(participants, {
            userId: String(FlipgiveStore.user_id),
            status: "ACCEPTED",
          });
          if (isEmpty(groupedParticipants)) return null;
          return (
            <Mutation mutation={INVITE_MUTATION} onCompleted={this.sendCompleted}>
              {(inviteMutation, { loading }) => (
                <div>
                  <div className="event-attendance-list">
                    <InvitationList
                      handleCheck={this.handleCheck}
                      invitableFundraisers={invitableFundraisers}
                      userIdsToInvite={userIdsToInvite}
                      viewerIsParticipant={viewerIsParticipant}
                    />
                    <AttendanceList
                      groupedParticipants={groupedParticipants}
                      handleCheck={this.handleCheck}
                      userIdsToInvite={userIdsToInvite}
                      viewerIsParticipant={viewerIsParticipant}
                    />
                  </div>

                  {!!viewerIsParticipant && (
                    <div className="centered mt-3 mb-3">
                      <button
                        className={`btn ${
                          loading || awaitingReload || this.participantsToInvite().length === 0
                            ? "disabled"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleSend(inviteMutation);
                        }}
                      >
                        {loading || awaitingReload ? "Sending..." : "Send"}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}
