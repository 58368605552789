import { action, observable } from "mobx";

class SharePercentStore {
  @observable merchantShareError = null;
  @observable flipgiveShareError = null;
  @observable campaignShareError = null;

  @action
  updateMerchantShareError = (error) => {
    this.merchantShareError = error;
  };

  @action
  updateFlipgiveShareError = (error) => {
    this.flipgiveShareError = error;
  };

  @action
  updateCampaignShareError = (error) => {
    this.campaignShareError = error;
  };

  @action
  updateOffsetShareError = (error) => {
    this.offsetShareError = error;
  };
}

const store = new SharePercentStore();
export default store;
