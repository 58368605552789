import React from "react";
import gql from "graphql-tag";
import format from "date-fns/format";
import parseISO from "date-fns/fp/parseISO";
import { navigate } from "@reach/router";
import { Box, Text, Flex, TouchableOpacity, ExchangeAlt } from "@atoms";
import { constants } from "@services";
import { IntentTypeEnum } from "@types";
import { useStoreAppValue } from "@hooks";
import { FundsPageFundraiserListItemViewerFundraiserFragment } from "./__generated__/FundsPageFundraiserListItemViewerFundraiserFragment";
import { FundsPageFundraiserListItemIntentFragment } from "./__generated__/FundsPageFundraiserListItemIntentFragment";

// == Types ================================================================

interface IProps {
  isViewerOwner: boolean;
  viewerFundraiser: FundsPageFundraiserListItemViewerFundraiserFragment | null;
  listItem: FundsPageFundraiserListItemIntentFragment;
  setOpen: (value: boolean) => void;
  setTransferOpen: (value: boolean) => void;
  setSelectedListItem: (listItem: FundsPageFundraiserListItemIntentFragment) => void;
}

FundsPageFundraiserListItem.fragments = {
  viewerFundraiser: gql`
    fragment FundsPageFundraiserListItemViewerFundraiserFragment on Fundraiser {
      id
      userId
      name
    }
  `,
  intent: gql`
    fragment FundsPageFundraiserListItemIntentFragment on Intent {
      id
      supportedAt
      giveSentence
      intentType
      isSelfSupport
      isTransferrable
      message
      campaignAmount {
        formatted
      }
      fundraiserAmount {
        formatted
      }
      fundraiser {
        id
        userId
      }
      link {
        id
      }
      merchantDisplayName
      brand {
        id
        name
        description
      }
    }
  `,
};

// == Constants ============================================================

// == Functions ============================================================

const renderSubtext = ({ listItem, viewerFundraiser, setOpen }: IProps) => {
  const { giveSentence, message, intentType, isSelfSupport, link, fundraiser } = listItem;

  const onPressBuyAgain = () => {
    setOpen(false);
    navigate(`?${constants.linkParam}=${link?.id}`);
  };

  const showBuyButtons =
    !!link?.id && !!viewerFundraiser?.id && viewerFundraiser?.id === fundraiser?.id;

  if (!giveSentence && !message) return null;

  switch (intentType) {
    case IntentTypeEnum.BUY:
    case IntentTypeEnum.SHOP:
      return (
        <>
          {!isSelfSupport && (
            <Box>
              <Text small>{giveSentence}</Text>
            </Box>
          )}
          {!!showBuyButtons && (
            <TouchableOpacity
              accessibilityLabel="BuyAgain"
              accessibilityRole="button"
              onTouch={onPressBuyAgain}
            >
              <Box>
                <Text marginT-spacing1 primary small color="primary">
                  Buy It Again
                </Text>
              </Box>
            </TouchableOpacity>
          )}
        </>
      );
    case IntentTypeEnum.DONATION:
    case IntentTypeEnum.TRANSFER:
      return isSelfSupport ? null : <Text small>{giveSentence}</Text>;
    case IntentTypeEnum.FLIPGIVECREDIT:
      return isSelfSupport ? null : <Text small>{message}</Text>;
    case IntentTypeEnum.LINKEDCARDSALE:
    default:
      return null;
  }
};

const transferExecution = (
  setTransferOpen: (value: boolean) => void,
  setSelectedListItem: (listItem: FundsPageFundraiserListItemIntentFragment) => void,
  listItem: FundsPageFundraiserListItemIntentFragment
) => {
  setTransferOpen(true);
  setSelectedListItem(listItem);
};

const renderTransferButton = (
  color: string,
  setTransferOpen: (value: boolean) => void,
  isTransferrableByViewer: boolean,
  listItem: FundsPageFundraiserListItemIntentFragment,
  setSelectedListItem: (listItem: FundsPageFundraiserListItemIntentFragment) => void
) => {
  return isTransferrableByViewer ? (
    <TouchableOpacity
      onTouch={() => transferExecution(setTransferOpen, setSelectedListItem, listItem)}
    >
      <ExchangeAlt color={color} containerProps={{ fontSize: [2, 3, 4] }} />
    </TouchableOpacity>
  ) : null;
};

// == Component ============================================================

export default function FundsPageFundraiserListItem({
  listItem,
  isViewerOwner,
  viewerFundraiser,
  setOpen,
  setTransferOpen,
  setSelectedListItem,
}: IProps) {
  const {
    merchantDisplayName,
    supportedAt,
    campaignAmount,
    fundraiserAmount,
    fundraiser,
    isTransferrable,
  } = listItem;

  const {
    theme: {
      colors: { primary },
    },
  } = useStoreAppValue();

  const canViewerTransferItem = () => {
    // *** sent Leigh a note about `Intent > isTransferrable` to see if it can handle the `Link > isBulkShop` logic as well
    if (!isTransferrable) return false;
    if (!fundraiser && isViewerOwner) return true;
    return fundraiser?.id === viewerFundraiser?.id;
  };

  const isTransferrableByViewer = canViewerTransferItem();

  return (
    <Flex
      alignItems="center"
      borderBottom="lightSilver"
      flexDirection="row"
      justifyContent="space-between"
      padding={[2, 3]}
      width={1}
    >
      <Flex flexDirection="column">
        <Text color="black" fontSize={[1, 2, 3]}>
          {!!merchantDisplayName && `${merchantDisplayName}`}
        </Text>
        <Text fontSize={[0, 0, 1]}>{format(parseISO(supportedAt), "MMM d, yyyy - h:mm a")}</Text>
        <Text fontSize={[0, 0, 1]}>{renderSubtext({ listItem, viewerFundraiser, setOpen })}</Text>
      </Flex>
      <Flex alignItems="center" flexDirection="row" justifyContent="space-between">
        {renderTransferButton(
          primary,
          setTransferOpen,
          isTransferrableByViewer,
          listItem,
          setSelectedListItem
        )}
        {!!fundraiserAmount && !!fundraiserAmount.formatted && (
          <Flex alignItems="flex-end" flexDirection="column" marginLeft={[1, 2, 3]}>
            <Text color="black" fontSize={[1, 2, 3]}>
              {fundraiserAmount.formatted}
            </Text>
            <Text fontSize={[0, 0, 1]}>Earned</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

// == Styles ===============================================================
