import React from "react";
import { graphql } from "react-apollo";
import { shape, string, func } from "prop-types";
import gql from "graphql-tag";
import { FUNDS_QUERY, PAYMENTS_QUERY } from "../queries";

const TRANSFER_MUTATION = gql`
  mutation TransferFundsMutation($campaignId: ID!) {
    TransferFunds(campaignId: $campaignId) {
      ... on Viewer {
        id
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;
@graphql(TRANSFER_MUTATION, { name: "transferFundsMutation" })
export default class TransferButton extends React.Component {
  static propTypes = {
    campaign: shape({}),
    transferFundsMutation: func.isRequired,
    availableAmount: shape({
      formatted: string.isRequired,
    }).isRequired,
    onTransferComplete: func.isRequired,
  };

  static defaultProps = {
    campaign: null,
  };

  onClick = async (e) => {
    const { transferFundsMutation, onTransferComplete, campaign } = this.props;
    e.preventDefault();

    await transferFundsMutation({
      variables: { campaignId: campaign.id },
      refetchQueries: [{ query: FUNDS_QUERY }, { query: PAYMENTS_QUERY }],
    });
    onTransferComplete();
  };

  render() {
    const { campaign, availableAmount } = this.props;

    if (campaign) {
      return (
        <button className="btn btn-active mt-4" onClick={this.onClick}>
          Transfer {availableAmount.formatted}
        </button>
      );
    }

    return <div className="mt-4 btn disabled">Transfer {availableAmount.formatted}</div>;
  }
}
