import { useMemo } from "react";
import { useCookies } from "react-cookie";

export function useUtmCookieData() {
  const [cookies] = useCookies(["utm_data"]);

  return useMemo(() => {
    const utm = cookies?.utm_data;
    if (!utm) return "";
    return {
      utmType: utm.utm_type,
      utmSource: utm.utm_source,
      utmMedium: utm.utm_medium,
      utmCampaign: utm.utm_campaign,
      utmTerm: utm.utm_term,
      utmContent: utm.utm_content,
      utmChannel: utm.utm_channel,
      branchLinkId: utm.branch_link_id,
      appsFlyerId: utm.apps_flyer_id,
    };
  }, [cookies]);
}
