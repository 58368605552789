import React, { Fragment } from "react";
import { string, shape, number } from "prop-types";
import SearchCampaignModal from "./SearchCampaignModal";
import CurrencyFunds from "./CurrencyFunds";
import UserTransferList from "./UserTransferList";

export default class UserFunds extends React.Component {
  static propTypes = {
    transferrableFunds: shape({
      currency: string.isRequired,
      heldAmount: shape({
        formatted: string.isRequired,
        centsAmount: number.isRequired,
      }).isRequired,
      availableAmount: shape({
        formatted: string.isRequired,
        centsAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    showSuccess: false,
    availableAmount: {
      formatted: "0",
    },
  };

  componentDidMount() {
    $(".tooltipped").tooltip({ delay: 50 });
  }

  onOpenModal = (availableAmount) => {
    this.setState({ availableAmount });
    $("#searchCampaignModal").modal("open");
  };

  onTransferComplete = () => {
    this.setState({ showSuccess: true });
  };

  onCloseModal = (e) => {
    e.preventDefault();
    this.setState({ showSuccess: false });
    $("#searchCampaignModal").modal("close");
  };

  render() {
    const { transferrableFunds } = this.props;
    const { showSuccess, availableAmount } = this.state;
    const funds = transferrableFunds;
    return (
      <>
        <h1 className="mt-4 centered">Funds Available To Transfer</h1>

        <CurrencyFunds
          funds={funds}
          key={`currency-funds-${funds.availableAmount.formatted}-${funds.availableAmount.formatted}`}
        />
        <p className="centered">
          {funds.heldAmount.formatted} is pending
          <i
            className="material-icons user-funds__info-button tooltipped"
            data-tooltip="We require 2 weeks for funds to clear before they can be transferred."
            style={{ cursor: "pointer" }}
          >
            info_outline
          </i>
        </p>

        {funds.availableAmount.centsAmount === 0 && funds.heldAmount.centsAmount === 0 && (
          <p className="mt-4 mb-4 centered">
            Earn back all year long.
            <span className="d-block" />
            <a href="/">Visit your store</a> to start shopping and
            <span className="d-block" />
            get a jump start on your next team.
          </p>
        )}

        <p className="centered">
          Personal Account Funds become available <span className="d-block" />
          for withdrawal after you transfer them to a team.
        </p>

        {funds.availableAmount.centsAmount > 0 && (
          <div
            className="centered mb-5"
            key={`transfer-button-${funds.availableAmount.formatted}-${funds.availableAmount.formatted}`}
          >
            <button
              className="cancel-button btn btn-clear"
              id="eventCancelButton"
              onClick={() => this.onOpenModal(funds.availableAmount)}
            >
              Transfer Funds
            </button>
          </div>
        )}

        <SearchCampaignModal
          availableAmount={availableAmount}
          currency={funds.currency}
          showSuccess={showSuccess}
          onCloseModal={this.onCloseModal}
          onTransferComplete={this.onTransferComplete}
        />

        <UserTransferList />
      </>
    );
  }
}
