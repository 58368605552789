import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { oc } from "ts-optchain";
import { Text } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { LinkCategory } from "@types";
import { TFilterType, TActiveFilterType } from "../types";
import BrandListContent from "./BrandListContent";
import { BrandDirectoryQuery as BrandDirectoryQueryType } from "./__generated__/BrandDirectoryQuery";
import { useCookies } from "react-cookie";
import { CustomStorefrontsIds } from "@components/common/utils/customStorefrontsIds";
import { Spinner } from "@components/common/molecules";
import { NetworkStatus } from "apollo-client";

// == Types ================================================================

interface IProps {
  activeFilter: TActiveFilterType;
  filterType: TFilterType;
  linkCategoryFilter: LinkCategory | null;
}

// == Constants ============================================================

const Loader = (
  <div style={{ padding: "20px 0" }}>
    <Spinner />
  </div>
);

const BRANDS_DIRECTORY_QUERY = gql`
  query BrandListContentQuery(
    $orderBy: BrandDirectoryOrderBy
    $firstLetter: String
    $currency: Currency
    $after: String
    $first: Int
    $linkCategory: LinkCategory
  ) {
    BrandDirectory(
      orderBy: $orderBy
      firstLetter: $firstLetter
      linkCategory: $linkCategory
      currency: $currency
      after: $after
      first: $first
    )
      @connection(
        key: "BrandDirectory"
        filter: ["currency", "firstLetter", "orderBy", "linkCategory"]
      ) {
      edges {
        cursor
        node {
          id
          ...BrandListFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${BrandListContent.fragments.fields}
`;

// == Component ============================================================

export const BrandListContentQuery = ({ activeFilter, filterType, linkCategoryFilter }: IProps) => {
  const { currency } = useStoreAppValue();
  const [_cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    removeCookie("storefront_id");
    setCookie("storefront_id", CustomStorefrontsIds.allBrands, { path: "/" });

    return () => {
      removeCookie("storefront_id");
    };
  }, []);

  const queryVariables = () => {
    return {
      orderBy: filterType === "NAMED_SORT" ? `${activeFilter}_DESC` : "NAME_ASC",
      firstLetter: filterType === "LETTER" ? activeFilter : null,
      currency,
      after: null,
      first: 99,
      linkCategory: linkCategoryFilter,
    };
  };

  const { loading, data, error, fetchMore, networkStatus } = useQuery<BrandDirectoryQueryType>(
    BRANDS_DIRECTORY_QUERY,
    {
      variables: queryVariables(),
      notifyOnNetworkStatusChange: true,
    }
  );

  // nodeReducer
  const reduceBrands = (edgedBrands) => {
    const reducedBrands = oc(edgedBrands).BrandDirectory.edges([]);
    if (reducedBrands.length === 0) return [];

    const nodeReducer = (acc, brand) => {
      if (!brand.node) return acc;
      return [...acc, brand.node];
    };

    return reducedBrands.reduce(nodeReducer, []);
  };

  if (error) {
    return <Text>{error.message}</Text>;
  }

  const brands = oc(data).BrandDirectory.edges();
  if (!brands) return Loader;

  const onFetchMore = () => {
    const { endCursor } = oc(data).BrandDirectory.pageInfo();
    fetchMore({
      query: BRANDS_DIRECTORY_QUERY,
      notifyOnNetworkStatusChange: true,
      variables: {
        ...queryVariables(),
        after: endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { endCursor: previousResultEndCursor, hasNextPage } = oc(
          previousResult
        ).BrandDirectory.pageInfo();
        if (!hasNextPage || !fetchMoreResult) {
          return previousResult;
        }

        const newBrands = oc(fetchMoreResult).BrandDirectory.edges();
        const pageInfo = oc(fetchMoreResult).BrandDirectory.pageInfo();
        const { endCursor: newResultEndCursor } = pageInfo;

        if (newResultEndCursor === previousResultEndCursor) return previousResult;

        return {
          BrandDirectory: {
            cursor: newResultEndCursor,
            edges: [...previousResult.BrandDirectory.edges, ...newBrands],
            __typename: previousResult.BrandDirectory.__typename,
            pageInfo,
          },
        };
      },
    });
  };
  const hasNextPage = oc(data).BrandDirectory.pageInfo.hasNextPage();
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  if (!data || (loading && !isFetchingMore)) return Loader;

  return (
    <BrandListContent
      activeFilter={activeFilter}
      brands={reduceBrands(data)}
      hasNextPage={hasNextPage}
      loadingMore={isFetchingMore}
      onFetchMore={onFetchMore}
    />
  );
};

// == Styles ===============================================================
