import React from "react";
import styled from "@emotion/styled";
import { LinkCategory } from "@types";
import { Box, IBoxProps } from "@atoms";
import { renderIf } from "@services";
import { LinkPill } from "@molecules";
import { useStoreAppValue } from "@hooks";
import { TDispatch } from "../types";

// == Types ================================================================

interface IProps extends IBoxProps {
  linkCategoryFilter: LinkCategory | null;
  dispatch: TDispatch;
  nearbySearch?: boolean;
  showRegister?: boolean;
}

// == Constants ============================================================

BrandListLinkTypeFilters.defaultProps = {
  nearbySearch: false,
  showRegister: true,
};

// == Component ============================================================

export default function BrandListLinkTypeFilters({
  nearbySearch,
  linkCategoryFilter,
  dispatch,
  showRegister,
  ...rest
}: IProps) {
  const { currency } = useStoreAppValue();
  return (
    <EContainer my={4} {...rest}>
      <ELinkPill
        isActive={linkCategoryFilter === null}
        text="All"
        onClick={() => dispatch({ type: "SET_TYPE_FILTER", linkCategoryFilter: null })}
      />
      <ELinkPill
        isActive={linkCategoryFilter === LinkCategory.SHOP_ONLINE}
        text="Online"
        onClick={() =>
          dispatch({ type: "SET_TYPE_FILTER", linkCategoryFilter: LinkCategory.SHOP_ONLINE })
        }
      />
      <ELinkPill
        isActive={linkCategoryFilter === LinkCategory.GIFT_CARDS}
        text="Gift Cards"
        onClick={() =>
          dispatch({ type: "SET_TYPE_FILTER", linkCategoryFilter: LinkCategory.GIFT_CARDS })
        }
      />
      {showRegister && (
        <ELinkPill
          isActive={linkCategoryFilter === LinkCategory.REGISTER}
          text="Register"
          onClick={() =>
            dispatch({ type: "SET_TYPE_FILTER", linkCategoryFilter: LinkCategory.REGISTER })
          }
        />
      )}
    </EContainer>
  );
}

// == Styles ===============================================================

const EContainer = styled(Box)`
  overflow-x: auto;
  white-space: nowrap;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    white-space: normal;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
  }
`;

const ELinkPill = styled(LinkPill)`
  margin-right: ${(props) => (props.last ? 0 : `${props.theme.space[2]}px`)};
`;
