import React from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import { RailsUrl } from "@services";

const { joinTeamUrl } = RailsUrl;

@inject("TimelineStore", "FlipgiveStore")
export default class JoinTeam extends React.Component {
  static propTypes = {
    FlipgiveStore: PropTypes.shape({}).isRequired,
    TimelineStore: PropTypes.shape({}).isRequired,
  };

  teamOrFundraiser() {
    const { isPartner } = this.props.TimelineStore;
    return isPartner ? "team" : "fundraiser";
  }

  render() {
    const { campaign_id: campaignId } = this.props.FlipgiveStore;

    return (
      <div className="timeline-join">
        <a href={joinTeamUrl(campaignId)}>Join {this.teamOrFundraiser()} to like or comment</a>
      </div>
    );
  }
}
