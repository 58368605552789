import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { OnboardingIntro } from "./OnboardingIntro/OnboardingIntro";
import { OnboardingQuestion } from "./OnboardingQuestion/OnboardingQuestion";
import { OnboardingSuccess } from "./OnboardingSuccess/OnboardingSuccess";
import { OnboardingBrandsQuery } from "./OnboardingBrands/OnboardingBrandsQuery";
import { OnboardingGoal } from "./OnboardingGoal/OnboardingGoal";
import { OnboardingPlayer } from "./OnboardingPlayer/OnboardingPlayer";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export function OnboardingRouter(_props: RouteComponentProps) {
  return (
    <Router>
      <OnboardingIntro path="intro" />
      <OnboardingSuccess path="success" />
      <OnboardingBrandsQuery path="brands" />
      <OnboardingGoal path="goal" />
      <OnboardingQuestion path="questions/:id" />
      <OnboardingPlayer path="player" />
    </Router>
  );
}

// == Styles ===============================================================
