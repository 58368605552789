import React from "react";
import PropTypes from "prop-types";
import { flatten } from "lodash";
import Notification from "./Notification";

export default class NotificationsList extends React.Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    fetchMore: PropTypes.func.isRequired,
    loadMoreNotifications: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.notificationsDiv.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const { loading } = this.props;
    if (loading) {
      return;
    }

    const node = event.target;
    const pixelsToBottom = Math.abs(node.scrollHeight - node.scrollTop - node.clientHeight);

    if (pixelsToBottom < 10) {
      const { loadMoreNotifications, fetchMore, notifications } = this.props;
      const activityIds = flatten(
        notifications.map((notification) => notification.activities.map((activity) => activity.id)),
      ).sort();

      if (activityIds.length === 0) {
        return;
      }

      loadMoreNotifications(fetchMore, activityIds[0]);
    }
  };

  render() {
    const { notifications } = this.props;

    return (
      <div
        className="notifications-content__activities"
        ref={(div) => {
          this.notificationsDiv = div;
        }}
      >
        {notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </div>
    );
  }
}
