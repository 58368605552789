import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { Formik, Form } from "formik";
import { useMutation } from "react-apollo";
import startOfToday from "date-fns/startOfToday";
import * as Yup from "yup";
import styled from "@emotion/styled";
import format from "date-fns/format";
import { getPaginatedNodes } from "@utils";
import { TextField, DatePickerField, TextAreaField, GiftcardTemplateField } from "@molecules";
import { Box, CardForm, ClearButton, TouchableOpacity, Text } from "@atoms";
import { LinkType } from "@types";
import { CheckoutFormPreview } from "../CheckoutFormPreview";
import { BUY_UPDATE_MUTATION } from "../CheckoutView/queries";
// == Types ================================================================

interface IProps {
  giftcardIntent: $FixMe;
  giftcardTemplates: [];
  dispatch: ({ type, formName }: { type: string; formName: string }) => void;
  isValid: boolean;
}

// == Constants ============================================================
const MESSAGE_MAX_LENGTH = 500;

CheckoutGiftForm.validation = Yup.object({
  name: Yup.string().required("Please provide a name."),
  email: Yup.string()
    .when("isPhysical", {
      is: false,
      then: Yup.string()
        .email("Please provide a valid email address.")
        .required("Please provide an email address."),
    })
    .when("isPhysical", {
      is: true,
      then: Yup.string().nullable(),
    }),
  message: Yup.string().max(
    MESSAGE_MAX_LENGTH,
    `Your message must be less than ${MESSAGE_MAX_LENGTH} characters`
  ),
  deliverOn: Yup.date()
    .when("isPhysical", {
      is: false,
      then: Yup.date()
        .min(startOfToday())
        .required("Please select a valid date.")
        .nullable(),
    })
    .when("isPhysical", {
      is: true,
      then: Yup.date().nullable(),
    }),
});
CheckoutGiftForm.defaultProps = {};

CheckoutGiftForm.fragments = gql`
  fragment CheckoutGiftFormFragment on GiftcardIntent {
    id
    isPhysical
    link {
      id
      type
    }
    giftcardTemplate {
      id
      backgroundImage
      giftcardImage
      isPhysical
      name
      previewImage
      providerCode
    }
    gifting {
      name
      email
      message
      deliverOn
    }
  }
`;

const getDefaultTemplateId = (giftcardTemplates) => {
  const defaultTemplateId = "";
  if (giftcardTemplates.length > 0) {
    return giftcardTemplates[0].id;
  }
  return defaultTemplateId;
};

// == Component ============================================================

export default function CheckoutGiftForm({
  giftcardIntent,
  giftcardTemplates: gcTemplates,
  dispatch,
  isValid,
}: IProps) {
  const [buyUpdateMutation] = useMutation(BUY_UPDATE_MUTATION);
  const giftcardTemplates = getPaginatedNodes(gcTemplates);
  const initialDeliverOn = () => {
    if (giftcardIntent?.isPhysical) {
      return null;
    }
    return giftcardIntent?.gifting?.deliverOn || format(new Date(), "yyyy-MM-dd").toString();
  };
  const initialState = {
    name: giftcardIntent?.gifting?.name,
    email: giftcardIntent?.gifting?.email,
    message: giftcardIntent?.gifting?.message,
    buyerName: giftcardIntent?.buyerName ?? "",
    buyerEmail: giftcardIntent?.buyerEmail ?? "",
    deliverOn: initialDeliverOn(),
    giftcardTemplateId:
      giftcardIntent?.giftcardTemplate?.id || getDefaultTemplateId(giftcardTemplates),
  };

  const [formValues, setFormValues] = useState(initialState);

  useEffect(() => {
    const validateForm = async () => {
      const valid = await CheckoutGiftForm.validation.isValid({
        ...formValues,
        isPhysical: giftcardIntent?.isPhysical,
      });
      if (valid) {
        dispatch({ type: "ADD_VALID_FORM", formName: "GIFT_FORM" });
      }
    };

    validateForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditClick = () => {
    dispatch({ type: "REMOVE_VALID_FORM", formName: "GIFT_FORM" });
  };

  const onSubmit = (values, { setSubmitting }) => {
    setFormValues(values);
    const {
      name: giftName,
      email: giftEmail,
      message: giftMessage,
      deliverOn,
      giftcardTemplateId,
      buyerName,
      buyerEmail,
    } = values;

    buyUpdateMutation({
      variables: {
        id: giftcardIntent.id,
        input: {
          giftName,
          giftEmail,
          giftMessage,
          deliverOn,
          giftcardTemplateId,
          buyerName,
          buyerEmail,
          isAnonymous: !buyerName,
        },
      },
    })
      .then(({ data }) => {
        // need to handle errors here
        dispatch({ type: "ADD_VALID_FORM", formName: "GIFT_FORM" });
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
        dispatch({ type: "REMOVE_VALID_FORM", formName: "GIFT_FORM" });
      });
  };
  const title =
    giftcardIntent.link.type === LinkType.VOUCHER ? "Personalize Voucher" : "Personalize Gift Card";
  return (
    <CardForm
      checkMark={isValid}
      heading={title}
      showEditButton={isValid}
      onEditClick={onEditClick}
    >
      <Box display={isValid ? "none" : "block"}>
        <Formik
          initialValues={{ ...formValues, isPhysical: giftcardIntent?.isPhysical }}
          validationSchema={CheckoutGiftForm.validation}
          onSubmit={onSubmit}
        >
          <Form>
            <TextField label="Sender Name" name="buyerName" placeholder="" type="text" />
            <TextField label="Recipient Name" name="name" placeholder="" type="text" />
            {!giftcardIntent.isPhysical && (
              <TextField label="Recipient Email" name="email" placeholder="" type="text" />
            )}
            <TextAreaField
              label="Message to Recipient"
              maxLength={MESSAGE_MAX_LENGTH}
              name="message"
              placeholder="Gift for you!"
              type="text"
            />
            {!giftcardIntent.isPhysical && (
              <DatePickerField
                defaultValue={format(new Date(), "yyyy-MM-dd").toString()}
                label="Deliver On"
                name="deliverOn"
                placeholder="YYYY-MM-DD"
                type="date"
              />
            )}
            {giftcardTemplates.length > 0 && (
              <ELabelText display="block" fontSize={0} pb={1}>
                Style
              </ELabelText>
            )}
            <Box className="new-checkout-gc-template-container">
              {giftcardTemplates.map((template) => {
                return (
                  <GiftcardTemplateField
                    key={`${template.id}+${template.name}`}
                    name="giftcardTemplateId"
                    template={template}
                    value={template.id}
                  />
                );
              })}
            </Box>
            <ClearButton type="submit" width="100%">
              Save
            </ClearButton>
            {/* {globalFormErrors && <Text color="danger">{globalFormErrors}</Text>} */}
          </Form>
        </Formik>
      </Box>
      <Box display={!isValid ? "none" : "block"}>
        <ETouchableOpacity canEdit={isValid} onClick={isValid ? onEditClick : null}>
          <CheckoutFormPreview
            previewObject={{
              giftForm: formValues,
            }}
            selectedGiftcardTemplate={giftcardTemplates.find(
              (template) => template.id === formValues.giftcardTemplateId
            )}
          />
        </ETouchableOpacity>
      </Box>
    </CardForm>
  );
}

// == Styles ===============================================================

const ETouchableOpacity = styled(TouchableOpacity)`
  opacity: 0.6;
  cursor: ${({ canEdit }) => (canEdit ? "pointer" : "default")};
`;

const ELabelText = styled(Text)`
  color: #999999;
`;
