import React from "react";
import { Box, Text } from "@atoms";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export function PlayerAddEditTitle() {
  return (
    <Box mb={3} mt={4} px={[1, 5]} width="100%">
      <Text className="sr-only" fontSize={[2, 3]} fontWeight="bold">
        Player Roster
      </Text>
      <Box>
        <Text fontSize={[0, 1]}>
          Fill out your player roster by adding the name and funding goal of each player before
          sending out invites. Add as many as you can - you can always come back and edit the roster
          later.
        </Text>
      </Box>
    </Box>
  );
}
