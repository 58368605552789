import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Activity } from "@components";
import NewPost from "../NewPost";
import NoTimelinePlaceholder from "./NoTimelinePlaceholder";

@inject("TimelineStore")
@observer
export default class Timeline extends React.Component {
  static propTypes = {
    activities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hideNewPost: PropTypes.bool.isRequired,
    TimelineStore: PropTypes.shape({
      teamView: PropTypes.bool,
      writingPost: PropTypes.bool,
    }).isRequired,
  };

  render() {
    const { activities, TimelineStore } = this.props;
    const { writingPost } = TimelineStore;

    return (
      <div
        className={`timeline-container ${writingPost ? "timeline-container--writing-post" : ""}`}
      >
        {activities.length === 0 && <NoTimelinePlaceholder />}
        {!this.props.hideNewPost && this.props.TimelineStore.teamView ? <NewPost /> : ""}

        {activities.map((activity) => (
          <Activity activity={activity} key={activity.id} />
        ))}
      </div>
    );
  }
}
