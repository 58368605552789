import React from "react";
import { Button, IButtonProps } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends IButtonProps {
  text?: string;
  isActive?: boolean;
  activeVariant?: string;
}

// == Constants ============================================================

ToggleButton.defaultProps = {
  activeVariant: "fadedButton",
};

// == Component ============================================================

export default function ToggleButton({ text, isActive, children, activeVariant, ...rest }: IProps) {
  return (
    <Button variant={isActive ? activeVariant : "clearGrey"} {...rest}>
      {text || children}
    </Button>
  );
}

// == Styles ===============================================================
