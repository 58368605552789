import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { Utils, Settings } from "@services";
import { NOTIFICATIONS_QUERY } from "../queries";
import activityUrl from "../services/activityUrl";
import { NotificationsContext } from "../NotificationsContext";
import MarkAllRead from "./MarkAllRead";
import NotificationsListData from "./NotificationsListData";
import UnseenCount from "./UnseenCount";
import SvgBell from "./SvgBell";

class Notifications extends React.Component {
  static propTypes = {
    markAllSeen: PropTypes.func.isRequired,
    refreshUnseenCount: PropTypes.func.isRequired,
  };

  state = {
    open: false,
  };

  toggleOpen = (e) => {
    e.preventDefault();

    if (this.state.open) {
      this.hide();
    } else {
      this.open();
    }
  };

  open = () => {
    document.addEventListener("click", this.handleHide);
    this.setState({ open: true });
    this.props.markAllSeen();
  };

  hide = () => {
    document.removeEventListener("click", this.handleHide);
    this.setState({ open: false });
  };

  handleHide = () => {
    this.hide();
  };

  toast = (notification) => {
    const url = Utils.addUTM(activityUrl(notification), "feed", "toast", "");
    const toastContent = `
      <span>${notification.description}</span>
      <a class="btn-flat toast-action" href="${url}">View</a>
    `;

    Materialize.toast(toastContent, 5000);
  };

  render() {
    return (
      <div className="notifications-container">
        <div className="notifications-header">
          <a
            className="notifications-header__link navbar--team"
            href={`${this.props.appUrl}/user/notifications?return_url=${encodeURIComponent(
              window.location.href
            )}`}
            // onClick={(e) => this.toggleOpen(e)}
            title="My notifications"
          >
            {/* <i className="material-icons">notifications</i> */}
            <SvgBell />
            <UnseenCount />
          </a>
        </div>

        {this.state.open ? (
          <div className="notifications-content">
            <div className="notifications-content__header">
              <span className="notifications-content__label block-text">Notifications</span>
              <MarkAllRead />
            </div>
            <NotificationsListData />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withApollo((props) => (
  <NotificationsContext.Consumer>
    {({ markAllSeen, refreshUnseenCount }) => (
      <Notifications {...props} markAllSeen={markAllSeen} refreshUnseenCount={refreshUnseenCount} />
    )}
  </NotificationsContext.Consumer>
));
