import React, { useReducer, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { RouteComponentProps } from "@reach/router";
import { NetworkStatus } from "apollo-client";
import { ErrorLoading } from "@organisms";
import { GiftcardType } from "@types";
import GiftcardListPage from "./GiftcardListPage";
import {
  GiftCardListPageQuery,
  GiftCardListPageQueryVariables,
} from "./__generated__/GiftCardListPageQuery";
// import FundsPage from "./FundsPage";
// import {
//   FundsPageQuery as IFundsPageQuery,
//   FundsPageQueryVariables,
// } from "./__generated__/FundsPageQuery";

// == Types ================================================================

export interface IReducerState {
  currentSort: "DATE" | "NAME" | "BALANCE";
  filters: string[];
  searchQuery: string;
  loaded: boolean;
  currentNetworkStatus: NetworkStatus;
  withBalance: boolean;
}

type TAction =
  | { type: "ADD_FILTER"; filter: GiftcardType }
  | { type: "REMOVE_FILTER"; filter: GiftcardType }
  | { type: "QUERY_FINISHED" }
  | { type: "UPDATE_SORT"; sort: IReducerState["currentSort"] }
  | { type: "SET_NETWORK_STATUS"; networkStatus: NetworkStatus }
  | { type: "UPDATE_SEARCH"; query: string };

// == Constants ============================================================

const GIFTCARDS_QUERY = gql`
  query GiftCardListPageQuery(
    $first: Int!
    $after: String
    $giftcardTypes: [GiftcardType!]
    $query: String!
    $sortBy: GiftcardSortEnum!
    $withBalance: Boolean
  ) {
    Viewer {
      id
      ...GiftcardListPageFragment
    }
  }
  ${GiftcardListPage.fragment}
`;

function REDUCER(state: IReducerState, action: TAction) {
  switch (action.type) {
    case "QUERY_FINISHED":
      return { ...state, loaded: true };
    case "ADD_FILTER":
      if (state.filters.includes(action.filter)) return state;
      return { ...state, filters: [...state.filters, action.filter] };
    case "REMOVE_FILTER":
      if (!state.filters.includes(action.filter)) return state;
      return {
        ...state,
        filters: state.filters.filter((filter: string) => filter !== action.filter),
      };
    case "UPDATE_SORT":
      return { ...state, currentSort: action.sort };
    case "UPDATE_SEARCH":
      return { ...state, searchQuery: action.query };
    case "SET_NETWORK_STATUS":
      return { ...state, currentNetworkStatus: action.networkStatus };
    case "TOGGLE_BALANCE":
      return { ...state, withBalance: !state.withBalance };
    default:
      throw new Error();
  }
}

const INITIAL_STATE: IReducerState = {
  currentSort: "DATE",
  filters: [],
  searchQuery: "",
  loaded: false,
  currentNetworkStatus: NetworkStatus.loading,
  withBalance: true,
};

// == Component ============================================================

export function GiftcardListPageQuery(_props: RouteComponentProps) {
  // const { campaignId, currentUser, isMember } = useStoreAppValue();
  const [state, dispatch] = useReducer(REDUCER, INITIAL_STATE);
  const isAllFilter =
    state.filters.length === Object.keys(GiftcardType).length || state.filters.length === 0;
  const queryVariables = () => {
    return {
      first: 50,
      after: null,
      query: state.searchQuery,
      sortBy: state.currentSort,
      giftcardTypes: state.filters,
      // sortBy: state.currentSort as GiftcardSortEnum,
      withBalance: state.withBalance ? true : undefined,
    };
  };

  const { data, error, networkStatus, fetchMore } = useQuery<
    GiftCardListPageQuery,
    GiftCardListPageQueryVariables
  >(GIFTCARDS_QUERY, {
    variables: queryVariables(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onCompleted(resp) {
      dispatch({ type: "QUERY_FINISHED" });
    },
  });

  useEffect(() => {
    dispatch({ type: "SET_NETWORK_STATUS", networkStatus });
  }, [networkStatus, dispatch]);

  const onFetchMore = () => {
    const { endCursor } = data?.Viewer.giftcardsPaginated.pageInfo;
    fetchMore({
      query: GIFTCARDS_QUERY,
      variables: {
        ...queryVariables(),
        after: endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const {
          endCursor: previousResultEndCursor,
          hasNextPage,
        } = previousResult?.Viewer.giftcardsPaginated.pageInfo;
        if (!hasNextPage || !fetchMoreResult) {
          return previousResult;
        }

        const newGiftcards = fetchMoreResult?.Viewer.giftcardsPaginated.edges || [];
        const pageInfo = fetchMoreResult?.Viewer.giftcardsPaginated.pageInfo;
        const { endCursor: newResultEndCursor } = pageInfo;

        if (newResultEndCursor === previousResultEndCursor) return previousResult;
        const oldGiftcards = previousResult.Viewer.giftcardsPaginated.edges || [];

        return {
          Viewer: {
            ...previousResult.Viewer,
            giftcardsPaginated: {
              cursor: newResultEndCursor,
              edges: [...oldGiftcards, ...newGiftcards],
              __typename: previousResult.Viewer.giftcardsPaginated.__typename,
              pageInfo,
            },
          },
        };
      },
    });
  };

  if (error) return <ErrorLoading />;
  return (
    <GiftcardListPage
      dispatch={dispatch}
      hasNoFilter={isAllFilter && state.searchQuery.length === 0}
      state={state}
      viewer={data?.Viewer}
      onFetchMore={onFetchMore}
    />
  );
}
