export const defaultLocation = {
  losAngeles: {
    latitude: 34.0522,
    longitude: -118.2437,
  },
  toronto: {
    latitude: 43.6532,
    longitude: -79.3832,
  },
};

export const getDefaultLat = (currency: string) =>
  currency === "USD" ? defaultLocation.losAngeles.latitude : defaultLocation.toronto.latitude;

export const getDefaultLong = (currency: string) =>
  currency === "USD" ? defaultLocation.toronto.latitude : defaultLocation.toronto.latitude;
