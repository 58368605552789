import React, { Fragment } from "react";
import { Query } from "react-apollo";
import { PAYMENTS_QUERY } from "../queries";
import UserTransferListItem from "./UserTransferListItem";

export default class UserTransferList extends React.Component {
  render() {
    return (
      <Query query={PAYMENTS_QUERY}>
        {({ data, loading }) => {
          if (loading) {
            return <span>Loading payments...</span>;
          }

          const payments = data.Viewer.personalAccount.payments.edges.map((edge) => edge.node);

          if (payments.length === 0) {
            return null;
          }

          return (
            <>
              <h2 className="mb-3">My Transfers</h2>
              <ul>
                {payments.map((payment) => (
                  <UserTransferListItem
                    key={payment.id}
                    payment={payment}
                    onClick={this.showPayment}
                  />
                ))}
              </ul>
            </>
          );
        }}
      </Query>
    );
  }
}
