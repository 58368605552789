import { PlatformEnum } from "@types";
// == Types ================================================================

// == Functions ============================================================

function platform(): PlatformEnum {
  const { location, navigator } = window;
  if (/(\?|&)src=extension/.test(location.href)) return PlatformEnum.EXTENSION;
  if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
    return PlatformEnum.WEB_IOS;
  }
  if (navigator.platform && /(android)/i.test(navigator.userAgent)) {
    return PlatformEnum.WEB_ANDROID;
  }
  return PlatformEnum.WEB;
}

// == Exports ==============================================================

const SEARCH_FILTERS_TYPES = {
  ALL: "ALL",
  ONLINE: "ONLINE",
  GIFTCARDS: "GIFTCARDS",
  REGISTER: "REGISTER",
};

const SEARCH_FILTERS_TYPE_NAMES = {
  ALL: "All",
  ONLINE: "Online",
  GIFTCARDS: "Gift Cards",
  REGISTER: "Register",
};

const SEARCH_FILTERS_LINK_TYPES = {
  BRAND: "shopLink",
  GIFTCARDOFFER: "giftcardLink",
};

const POSTAL_ZIP_REGEX = RegExp(
  "([a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z][0-9])|([0-9]{5})(?:[-s]*([0-9]{4}))?$"
);
const ZIP_REGEX = RegExp("([0-9]{5})(?:[-s]*([0-9]{4}))?$");
const POSTAL_CODE_REGEX = RegExp("^([A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9])$");

const COUNTRIES = [
  ["US", "USA"],
  ["CA", "Canada"],
];

const STATES = [
  ["AL", "Alabama"],
  ["AK", "Alaska"],
  ["AZ", "Arizona"],
  ["AR", "Arkansas"],
  ["CA", "California"],
  ["CO", "Colorado"],
  ["CT", "Connecticut"],
  ["DE", "Delaware"],
  ["FL", "Florida"],
  ["GA", "Georgia"],
  ["HI", "Hawaii"],
  ["ID", "Idaho"],
  ["IL", "Illinois"],
  ["IN", "Indiana"],
  ["IA", "Iowa"],
  ["KS", "Kansas"],
  ["KY", "Kentucky"],
  ["LA", "Louisiana"],
  ["ME", "Maine"],
  ["MD", "Maryland"],
  ["MA", "Massachusetts"],
  ["MI", "Michigan"],
  ["MN", "Minnesota"],
  ["MS", "Mississippi"],
  ["MO", "Missouri"],
  ["MT", "Montana"],
  ["NE", "Nebraska"],
  ["NV", "Nevada"],
  ["NH", "New Hampshire"],
  ["NJ", "New Jersey"],
  ["NM", "New Mexico"],
  ["NY", "New York"],
  ["NC", "North Carolina"],
  ["ND", "North Dakota"],
  ["OH", "Ohio"],
  ["OK", "Oklahoma"],
  ["OR", "Oregon"],
  ["PA", "Pennsylvania"],
  ["RI", "Rhode Island"],
  ["SC", "South Carolina"],
  ["SD", "South Dakota"],
  ["TN", "Tennessee"],
  ["TX", "Texas"],
  ["UT", "Utah"],
  ["VT", "Vermont"],
  ["VA", "Virginia"],
  ["WA", "Washington"],
  ["WV", "West Virginia"],
  ["WI", "Wisconsin"],
  ["WY", "Wyoming"],
];

const PROVINCES = [
  ["AB", "Alberta"],
  ["BC", "British Columbia"],
  ["MB", "Manitoba"],
  ["NB", "New Brunswick"],
  ["NL", "Newfoundland and Labrador"],
  ["NS", "Nova Scotia"],
  ["ON", "Ontario"],
  ["PE", "Prince Edward Island"],
  ["QC", "Quebec"],
  ["SK", "Saskatchewan"],
  ["NT", "Northwest Territories"],
  ["NU", "Nunavut"],
  ["YT", "Yukon"],
];

// interface IMerchantDepartment {
//   id: keyof typeof MerchantDepartmentType;
//   name: string;
// }

const MERCHANT_DEPARTMENTS = [
  {
    id: "FASHION_BEAUTY",
    name: "Fashion & Beauty",
  },
  {
    id: "FOOD_GROCERIES",
    name: "Food & Groceries",
  },
  {
    id: "GADGETS_ELECTRONICS",
    name: "Gadgets & Electronics",
  },
  {
    id: "GENERAL_RETAIL",
    name: "General Retail",
  },
  {
    id: "GIFTS_SPECIALTY",
    name: "Gifts & Specialty",
  },
  {
    id: "HOME_OFFICE",
    name: "Home & Office",
  },
  {
    id: "RESTAURANT_DINING",
    name: "Restaurant & Dining",
  },
  {
    id: "SERVICES_UTILITIES",
    name: "Services & Utilities",
  },
  {
    id: "SPORTS_OUTDOOR",
    name: "Sports & Outdoor",
  },
  {
    id: "TRAVEL_ENTERTAINMENT",
    name: "Travel & Entertainment",
  },
];

const DISTANCES_ENUM = {
  DEFAULT_DISTANCE: 50,
  ALL_LOCATIONS: 10000,
};

const CURRENT_LOCATION_TEXT = "Current Location";
const ALL_LOCATIONS_TEXT = "All Locations";

const TORONTO_LAT_LNG = {
  latitude: 43.6532,
  longitude: -79.3832,
};

const SAN_FRANCISCO_LAT_LNG = {
  latitude: 37.7749,
  longitude: -122.4194,
};

const shopping = "#27B7BA";
const donations = "#FF8A5B";
const bonuses = "#FFCD00";
const remaining = "#FFF";
const fees = "#FF2222";
const team = "#AEA5D4";
const transfers = "#28A745";

const CHART_COLORS = {
  shopping,
  available: shopping,
  donations,
  withdrawn: donations,
  bonuses,
  pending: bonuses,
  remaining,
  fees,
  team,
  transfers,
};

const BENEFICIARY_REVIEW_DAYS = "5-7";

const NO_AVATAR_COLORS = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#c9d2d6",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

const DEFAULT_USER_AVATAR_STRING = "defaults/users_avatars";

const ALPHA_NUMBER_ARRAY = "#abcdefghijklmnopqrstuvwxyz1234567890".toUpperCase().split("");

const GIFTCARD_PURCHASE_DELAY_LIMIT = 3000;

const TEAM_TYPES = [
  ["", "Please Select"],
  ["ORGANIZATION", "Club / League / School"],
  ["CHARITY", "Registered Charity / Non-Profit"],
  ["INDIVIDUAL", "Family  / Individual / Other"],
];

const PLAID_PUBLIC_KEY = "2a265863853218778dbf7d66ef69a3";
const PRIVACY_LEVEL_TYPES = {
  NONE: "NONE",
  MASKED: "MASKED",
  ANONYMOUS: "ANONYMOUS",
};

const PAGINATION_FUNDRAISERS_LIMITS = {
  PLAYER: 10,
  TEAM: 50,
};

const BENEFICIARY_VALIDATIONS = {
  MAX_REAL_TEAM_NAME: 100,
  MAX_PHONE_NUMBER: 17,
  MAX_FIRST_NAME: 50,
  MAX_LAST_NAME: 50,
  MAX_CITY: 50,
  MAX_PROVINCE: 50,
  MAX_STREET_1: 35,
};

export enum Environment {
  production = "production",
  staging = "staging",
  development = "development",
}

const NEARBY_LINKED_CARD_DESCRIPTION = "Find a location nearby to shop in-store.";

const isStagingUrl = document.location.host === "www.develop.almostflip.com";
let API_ENVIRONMENT = (process.env.NODE_ENV ?? Environment.development) as Environment;
if (isStagingUrl) API_ENVIRONMENT = Environment.staging;

export const PRODUCTION_ENVIRONMENT = Environment.production;
export const STAGING_ENVIRONMENT = Environment.staging;
export const DEVELOPMENT_ENVIRONMENT = Environment.development;

// Budget App URL
export const BASE_BUDGET_URL = {
  [PRODUCTION_ENVIRONMENT]: "https://budget.flipgive.com",
  [STAGING_ENVIRONMENT]: "https://budget.almostflip.com",
  [DEVELOPMENT_ENVIRONMENT]: "https://budget.flipgive.test",
}[API_ENVIRONMENT];

// Funding App URL
export const BASE_FUNDING_URL = {
  [PRODUCTION_ENVIRONMENT]: "https://funding.flipgive.com",
  [STAGING_ENVIRONMENT]: "https://funding.almostflip.com",
  [DEVELOPMENT_ENVIRONMENT]: "https://funding.flipgive.test",
}[API_ENVIRONMENT];

// Shop App URL
export const BASE_SHOP_URL = {
  [PRODUCTION_ENVIRONMENT]: "https://shop.flipgive.com",
  [STAGING_ENVIRONMENT]: "https://shop.almostflip.com",
  [DEVELOPMENT_ENVIRONMENT]: "https://shop.flipgive.test",
}[API_ENVIRONMENT];

// Main App URL
export const BASE_MAIN_URL = {
  [PRODUCTION_ENVIRONMENT]: "https://www.flipgive.com",
  [STAGING_ENVIRONMENT]: "https://develop.almostflip.com",
  [DEVELOPMENT_ENVIRONMENT]: "https://www.flipgive.test",
}[API_ENVIRONMENT];

// Auth App URL
export const BASE_AUTH_URL = {
  [PRODUCTION_ENVIRONMENT]: "https://auth.flipgive.com",
  [STAGING_ENVIRONMENT]: "https://auth.almostflip.com",
  [DEVELOPMENT_ENVIRONMENT]: "https://auth.flipgive.test",
}[API_ENVIRONMENT];

// App App URL
export const BASE_APP_URL = {
  [PRODUCTION_ENVIRONMENT]: "https://app.flipgive.com",
  [STAGING_ENVIRONMENT]: "https://app.almostflip.com",
  [DEVELOPMENT_ENVIRONMENT]: "https://app.flipgive.test",
}[API_ENVIRONMENT];

// Marketing App URL
export const BASE_MARKETING_URL = {
  [PRODUCTION_ENVIRONMENT]: "https://marketing.flipgive.com",
  [STAGING_ENVIRONMENT]: "https://marketing.almostflip.com",
  [DEVELOPMENT_ENVIRONMENT]: "https://marketing.flipgive.test",
}[API_ENVIRONMENT];

// Banking App URL
export const BASE_BANKING_URL = {
  [PRODUCTION_ENVIRONMENT]: "https://www.flipgive.com/bank",
  [STAGING_ENVIRONMENT]: "https://www.develop.almostflip.com/bank",
  [DEVELOPMENT_ENVIRONMENT]: "https://marketing.flipgive.test/bank",
}[API_ENVIRONMENT];

// collectionId used for search shortcuts
export const COLLECTION_ID_FOR_SHORTCUTS = {
  [PRODUCTION_ENVIRONMENT]: "4912",
  [STAGING_ENVIRONMENT]: "4847",
  [DEVELOPMENT_ENVIRONMENT]: "1640",
}[API_ENVIRONMENT];

export const routes = {
  funding: {
    favorites: `${BASE_SHOP_URL}/favorites`,
  },
};

export default {
  favoritesParam: "my_favorites",
  utmParam: "utmData",
  linkParam: "link_id",
  storefrontParam: "storefrontId",
  collectionParam: "collectionId",
  labelPromotionParam: "labelPromotionId",
  commisionPromotionParam: "commissionPromotionId",
  linkPromotionParam: "link_promotion_id",
  brandParam: "brand_id",
  inStoreParam: "in_store_id",
  locationParam: "location_id",
  searchParam: "search",
  storeUrl: "/stores/",
  collectionUrl: "/collections/",
  brandsUrl: "brands",
  IS_DEV: process.env.NODE_ENV === "development",
  IS_STAGING: process.env.NODE_ENV === "staging",
  IS_PRODUCTION: process.env.NODE_ENV === "production",
  featuredBrandsCollectionId:
    process.env.NODE_ENV === "production" ? 1020 : process.env.NODE_ENV === "staging" ? 423 : 975,
  SEARCH_PLACEHOLDER: "Search for a brand or product",
  IS_SUPER_ADMIN: new RegExp(/admin\.\w+\.com/).exec(window.location.hostname),
  PLATFORM: platform(),
  SEARCH_FILTERS_TYPES,
  SEARCH_FILTERS_TYPE_NAMES,
  SEARCH_FILTERS_LINK_TYPES,
  CHART_COLORS,
  POSTAL_ZIP_REGEX,
  COUNTRIES,
  STATES,
  PROVINCES,
  ZIP_REGEX,
  POSTAL_CODE_REGEX,
  MERCHANT_DEPARTMENTS,
  DISTANCES_ENUM,
  CURRENT_LOCATION_TEXT,
  ALL_LOCATIONS_TEXT,
  TORONTO_LAT_LNG,
  SAN_FRANCISCO_LAT_LNG,
  NO_AVATAR_COLORS,
  DEFAULT_USER_AVATAR_STRING,
  ALPHA_NUMBER_ARRAY,
  GIFTCARD_PURCHASE_DELAY_LIMIT,
  TEAM_TYPES,
  PRIVACY_LEVEL_TYPES,
  PAGINATION_FUNDRAISERS_LIMITS,
  PLAID_PUBLIC_KEY,
  BENEFICIARY_VALIDATIONS,
  BENEFICIARY_REVIEW_DAYS,
  NEARBY_LINKED_CARD_DESCRIPTION,
  API_ENVIRONMENT,
};

export enum FeatureFlags {
  Feature_Flag = "Search_Filters_8678qtuk5",
  test_kenshin = "test_kenshin",
}

export enum FullStoryEventNames {
  searchInitiated = "Search Initiated",
  storeFrontClicked = "Search Shortcut Clicked",
  storefrontViewed = "Storefront Viewed",
  searchSorted = "Search Sorted",
  searchTypeClicked = "searchTypeClicked",
  searchFiltered = "Search Filtered",
  searchResultClicked = "Search Result Clicked",
  intentInitiated = "Intent Initiated",
  intentClicked = "Intent Clicked",
  orderStarted = "orderStarted",
}
