/**
 * @param {String} string
 * @return {String}
 */

//from github.com/batusai513/possessive

const APOSTROPHE_CHAR = "’";

export function possessive(string: string) {
  if (string == "") {
    return string;
  }
  var lastChar = string.slice(-1);
  var endOfWord = lastChar.toLowerCase() == "s" ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`;
  return `${string}${endOfWord}`;
}
