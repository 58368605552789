import React from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import { oc } from "ts-optchain";
import { useScrollTopOnMount, useLoadingDelay } from "@hooks";
import { parseId } from "@services";
import { Text } from "@atoms";
import { ErrorLoading } from "@organisms";
import { FadeIn } from "@utils";
import { CollectionViewLoading } from "../CollectionViewLoading";
import { CollectionViewQuery as CollectionViewQueryType } from "./__generated__/CollectionViewQuery";
import CollectionView from "./CollectionView";

// == Types ================================================================

interface IRouteProps {
  id: string;
  isLoading?: boolean;
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================

const COLLECTION_VIEW_QUERY = gql`
  query CollectionViewQuery($id: ID!, $firstLinks: Int!) {
    Collection(id: $id) {
      id
      ...CollectionViewFragment
    }
  }
  ${CollectionView.fragments.fields}
`;

// == Component ============================================================

export const CollectionViewQuery = ({ id, isLoading }: TProps) => {
  useScrollTopOnMount();

  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery<CollectionViewQueryType>(COLLECTION_VIEW_QUERY, {
    variables: { id: parseId(id), firstLinks: 100 },
  });

  if (error) {
    return <ErrorLoading />;
  }

  const hasNotLoaded = isEmpty(data) || loading || !loadingDelayReady || isLoading;

  if (hasNotLoaded) return <CollectionViewLoading />;

  const collection = oc(data).Collection();
  // console.log(data, collection);
  if (!collection) return null;

  return (
    <FadeIn>
      <CollectionView collection={collection} />
    </FadeIn>
  );
};

// CollectionViewQuery.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
