import React, { useReducer, Reducer } from "react";
import styled from "@emotion/styled";
import produce from "immer";
import { Breakpoint } from "react-socks";
import { Container, Box, Row, Column } from "@atoms";
import { LinkType } from "@types";
import { CheckoutGiftForm } from "../CheckoutGiftForm";
import { CheckoutRecipientForm } from "../CheckoutRecipientForm";
import { CheckoutPaymentForm } from "../CheckoutPaymentForm";
import { CheckoutOrderSummary } from "../CheckoutOrderSummary";
import { CheckoutHelp } from "../CheckoutHelp";
import { CHECKOUT_FRAGMENT, CHECKOUT_VIEW_FRAGMENT } from "./queries";

// == Types ================================================================

interface ISavedCardsValues {
  id: string;
  last4: string;
  expiryMonth: number;
  expiryYear: number;
}

interface IProps {
  transaction: $FixMe;
  giftcardTemplates: $FixMe;
  showMainNav: boolean;
  stripeCards: ISavedCardsValues[];
}

type IFormNames = "GIFT_FORM" | "SHIPPING_FORM" | "PAYMENT_FORM";
type TToken = stripe.Token;
type IReducerActions =
  | { type: "ADD_VALID_FORM"; formName: IFormNames }
  | { type: "REMOVE_VALID_FORM"; formName: IFormNames }
  | { type: "SET_CARD_ID"; cardId: string }
  | { type: "SET_POSTAL_CODE"; postalCode: string }
  | { type: "REMOVE_CARD"; cardId: string }
  | { type: "ADD_NEW_CARD"; cardId: string; shouldSaveCard: boolean; token: TToken }
  | { type: "SET_POSTAL_CODE_ERROR"; error: string }
  | { type: "SET_SHOULD_SAVE_CARD"; shouldSaveCard: boolean };

interface IReducerState {
  validForms: string[];
  cardId: string;
  postalCode: string;
  shouldSaveCard: boolean;
  postalCodeError: string | null;
  savedCards: ISavedCardsValues[];
}

// == Constants ============================================================

const CHECKOUT_REDUCER: Reducer<IReducerState, IReducerActions> = (state, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "ADD_VALID_FORM":
        draft.validForms = [...state.validForms, action.formName];
        break;
      case "REMOVE_VALID_FORM":
        draft.validForms = state.validForms.filter((form) => form !== action.formName);
        break;
      case "SET_CARD_ID":
        draft.cardId = action.cardId;
        break;
      case "SET_POSTAL_CODE":
        draft.postalCode = action.postalCode;
        break;
      case "SET_SHOULD_SAVE_CARD":
        draft.shouldSaveCard = action.shouldSaveCard;
        break;
      case "REMOVE_CARD":
        draft.savedCards = state.savedCards.filter((card) => {
          return card.id !== action.cardId;
        });
        break;
      case "ADD_NEW_CARD":
        draft.cardId = action.token.id;
        draft.postalCode = "";
        draft.shouldSaveCard = action.shouldSaveCard;
        draft.savedCards = [
          ...state.savedCards,
          {
            id: action.token.id,
            token: action.token.id,
            default: false,
            last4: action.token.card.last4,
            brand: action.token.card.brand,
            expiryFormatted: action.token.card.expiryFormatted,
            expiryMonth: action.token.card.exp_month,
            expiryYear: action.token.card.exp_year,
          },
        ];
        break;
      case "SET_POSTAL_CODE_ERROR":
        draft.postalCodeError = action.error;
        break;
      default:
        break;
    }
  });
};

CheckoutView.fragments = {
  viewerFields: CHECKOUT_VIEW_FRAGMENT,
  fields: CHECKOUT_FRAGMENT,
};

const INITIAL_STATE = {
  validForms: [],
  cardId: "",
  postalCode: "",
  shouldSaveCard: true,
  postalCodeError: null,
  savedCards: [],
};

// == Component ============================================================

export default function CheckoutView({
  transaction: giftcardIntent,
  giftcardTemplates,
  showMainNav,
  stripeCards,
}: IProps) {
  const [state, dispatch] = useReducer(CHECKOUT_REDUCER, INITIAL_STATE, (initialState) => {
    return {
      ...initialState,
      savedCards: stripeCards,
    };
  });

  const validateForms = () => {
    let valid = true;
    const { validForms } = state;

    if (giftcardIntent.isGift && !validForms.includes("GIFT_FORM")) {
      valid = false;
    }

    if (giftcardIntent.isPhysical && !validForms.includes("SHIPPING_FORM")) {
      valid = false;
    }

    if (!validForms.includes("PAYMENT_FORM")) {
      valid = false;
    }

    return valid;
  };

  const isFullFormValid = validateForms();
  const { shouldSaveCard, savedCards, validForms } = state;
  return (
    <>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
      #intercom-container { display: none!important }
    `,
        }}
      />
      {showMainNav && (
        <nav className="navbar navbar--secondary" role="navigation">
          <div className="nav-wrapper">
            <EDiv className="container centered">
              <EBrandLogo className="brand-logo">
                <img
                  alt="FlipGive"
                  src="https://flipgive.twic.pics/images/sites/site_logo_campaigns/000/000/031/original/flipgive_logo_black.png?twic=v1/resize=125"
                />
              </EBrandLogo>
            </EDiv>
          </div>
        </nav>
      )}
      <Box bg="white" pb={5}>
        <Container>
          <Row justifyContent="center">
            <Column flex="none" mb={[4, 4, 0]} px={[0, 0, 3]} width={[1, 1, 0.6, 1 / 2]}>
              <Breakpoint down small>
                <CheckoutOrderSummary
                  dispatch={dispatch}
                  formValues={state}
                  giftcardIntent={giftcardIntent}
                  isValid={isFullFormValid}
                  postalCodeErrorMessage={state.postalCodeError}
                />
              </Breakpoint>

              {!!giftcardIntent.isGift && (
                <CheckoutGiftForm
                  dispatch={dispatch}
                  giftcardIntent={giftcardIntent}
                  giftcardTemplates={giftcardTemplates}
                  isValid={validForms.includes("GIFT_FORM")}
                />
              )}

              {!!giftcardIntent.isPhysical && (
                <CheckoutRecipientForm
                  dispatch={dispatch}
                  giftcardIntent={giftcardIntent}
                  isValid={validForms.includes("SHIPPING_FORM")}
                />
              )}
              <CheckoutPaymentForm
                dispatch={dispatch}
                forceSave={giftcardIntent.link.type === LinkType.AUTORELOADGIFTCARD}
                isValid={validForms.includes("PAYMENT_FORM")}
                postalCodeErrorMessage={state.postalCodeError}
                savedCards={savedCards}
                shouldSaveCard={shouldSaveCard}
              />
            </Column>
            <Column autoColumn={false} px={[0, 0, 3]} width={[1, 1, 0.4]}>
              <EBreakpoint medium up>
                <Box position="sticky" top={0}>
                  <CheckoutOrderSummary
                    dispatch={dispatch}
                    formValues={state}
                    giftcardIntent={giftcardIntent}
                    isValid={isFullFormValid}
                    postalCodeErrorMessage={state.postalCodeError}
                  />
                  <CheckoutHelp />
                </Box>
              </EBreakpoint>
            </Column>
          </Row>
        </Container>
      </Box>
    </>
  );
}

// == Styles ===============================================================
const EBrandLogo = styled.span`
  display: inline-block !important;
  margin: 0 !important;
  float: none !important;
`;

const EDiv = styled.div`
  @media only screen and (max-width: ${(props) => props.theme.breakpoints[1]}) {
    box-shadow: ${(props) => props.theme.shadows.regularShadow};
  }
`;

const EBreakpoint = styled(Breakpoint)`
  @media only screen and (min-height: 690px) {
    height: 100%;
  }
`;
