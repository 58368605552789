import React, { useMemo, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { isEmpty } from "lodash";
import { oc } from "ts-optchain";
import styled from "@emotion/styled";
import { Location, LocationContext } from "@reach/router";
import { FadeIn } from "@utils";
import { Box, Text, Row, Column, TouchableOpacity } from "@atoms";
import { useAuthenticated, useLoadingDelay } from "@hooks";
import { LinkType } from "@types";
import { BrandModalHero } from "../BrandModalHero";
import { BrandModalLinks } from "../BrandModalLinks";
import { BrandModalLoading } from "../BrandModalLoading";
import { BrandModalAbout } from "../BrandModalAbout";
import { BrandModalSimpleLinks } from "../BrandModalSimpleLinks";
import { BrandModalQuery, BrandModalQueryVariables } from "./__generated__/BrandModalQuery";

// == Types ================================================================

interface IProps {
  brandId: string;
  isLoading?: boolean;
  locationId?: string;
}

// == Constants ============================================================

const TABS = {
  OFFER: "OFFER",
  ABOUT: "ABOUT",
};

const BRAND_LOCATION_FRAGMENT = {
  fields: gql`
    fragment BrandLocationFragment on Location {
      id
      street1
      street2
      province
      city
      postalCode
      phoneNumber
      latitude
      longitude
      googlePlacesId
      hasLinkedCard
      metaInfo {
        rating
        ratingsCount
        priceRange
        primaryCategory
        categories
        ambiance
        menuUrl
        mealTypesServed
        attire
        knownFor
        bestNightsToVisit
      }
    }
  `,
};

const BRAND_MODAL_QUERY = gql`
  query BrandModalQuery(
    $id: ID!
    $locationId: ID
    $includeLocation: Boolean!
    $linkTypes: [LinkType!]!
  ) {
    Brand(id: $id) {
      id
      name
      description
      ...BrandModalHeroFragment
      ...BrandModalLinksFragment
      location(id: $locationId) @include(if: $includeLocation) {
        id
        ...BrandLocationFragment
      }
      ...BrandModalSimpleLinksFragment
    }
  }
  ${BrandModalHero.fragments.fields}
  ${BrandModalLinks.fragments.fields}
  ${BRAND_LOCATION_FRAGMENT.fields}
  ${BrandModalSimpleLinks.fragment}
`;

const NEW_LINK_TYPES_AUTHORIZED = [
  LinkType.BRAND,
  LinkType.GIFTCARDOFFER,
  LinkType.AUTORELOADGIFTCARD,
  LinkType.EMPYR,
  LinkType.TRAVELSHOP,
  LinkType.BOUNTYSHOP,
  LinkType.GIFTCARDSHOP,
  LinkType.VOUCHER,
];

const NEW_LINK_TYPES_ANON = [
  LinkType.BRAND,
  LinkType.GIFTCARDOFFER,
  LinkType.EMPYR,
  LinkType.TRAVELSHOP,
  LinkType.BOUNTYSHOP,
  LinkType.GIFTCARDSHOP,
  LinkType.VOUCHER,
];

// == Component ============================================================

const BrandModal = ({ brandId, locationId, isLoading }: IProps) => {
  const [activeTab, setActiveTab] = useState(TABS.OFFER);
  const loadingDelayReady = useLoadingDelay();
  const isAuthenticated = useAuthenticated();

  const linkTypes = useMemo(() => {
    if (isAuthenticated) return NEW_LINK_TYPES_AUTHORIZED;
    return NEW_LINK_TYPES_ANON;
  }, []);

  const { data, error, loading } = useQuery<BrandModalQuery, BrandModalQueryVariables>(
    BRAND_MODAL_QUERY,
    {
      variables: {
        id: brandId,
        locationId,
        includeLocation: !!locationId,
        linkTypes,
      },
    }
  );

  if (error) {
    return (
      <Box>
        <Text>{error.message}</Text>
      </Box>
    );
  }
  const hasNotLoaded = isEmpty(data) || loading || !loadingDelayReady || isLoading;

  if (hasNotLoaded) return <BrandModalLoading />;

  const brand = oc(data).Brand();
  // possible because the brand queried may not be returned
  if (!brand) {
    return (
      <Box>
        <Text>Sorry couldn&apos;t find that brand</Text>
      </Box>
    );
  }

  const renderTab = ({
    label,
    active,
    onTouch,
  }: {
    label: string;
    active: boolean;
    onTouch: () => void;
  }) => {
    return (
      <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={onTouch}>
        <Box borderBottom={active ? "primary" : null} borderWidth={4}>
          <Text
            color={active ? "primary" : "default"}
            display="block"
            px={1}
            py={3}
            textAlign="center"
          >
            {label}
          </Text>
        </Box>
      </TouchableOpacity>
    );
  };

  const renderTabs = () => {
    return (
      <ETabsContainer borderBottom="silver">
        <Row>
          <Column autoColumn>
            {renderTab({
              label: "Offers",
              active: activeTab === TABS.OFFER,
              onTouch: () => setActiveTab(TABS.OFFER),
            })}
          </Column>
          <Column autoColumn>
            {renderTab({
              label: "About",
              active: activeTab === TABS.ABOUT,
              onTouch: () => setActiveTab(TABS.ABOUT),
            })}
          </Column>
        </Row>
      </ETabsContainer>
    );
  };

  const renderBrandModal = ({ location, navigate }: LocationContext) => {
    const onBackClick = () => {
      if (location.state.canReturn) {
        window.history.back();
      } else {
        navigate(location.pathname);
      }
    };

    const { tinyLinkKey } = location.state;
    return (
      <FadeIn>
        <EModalContainer>
          <BrandModalHero brand={brand} location={brand?.location} onBackClick={onBackClick} />

          {renderTabs()}

          {activeTab === TABS.OFFER && (
            <Box>
              <BrandModalSimpleLinks
                brand={brand}
                locationId={locationId}
                tinyLinkKey={tinyLinkKey}
              />
            </Box>
          )}

          {activeTab === TABS.ABOUT && (
            <Box>
              <BrandModalAbout brand={brand} location={brand.location} />
            </Box>
          )}
        </EModalContainer>
      </FadeIn>
    );
  };

  return (
    <Location>
      {({ location, navigate }: LocationContext) => renderBrandModal({ location, navigate })}
    </Location>
  );
};
export default BrandModal;

// == Styles ===============================================================

const ETabsContainer = styled(Box)`
  overflow: hidden;
`;

const EModalContainer = styled(Box)`
  min-height: 700px;
`;
