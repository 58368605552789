import React, { useState } from "react";
import * as Yup from "yup";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import styled from "@emotion/styled";
import chunk from "lodash/chunk";
import { Formik, Form } from "formik";
import { format, parse } from "date-fns";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { useSpring, animated } from "react-spring";
import { useToggle, useStoreAppValue, useBreakpoint } from "@hooks";
import { RailsUrl, analytics, ErrorService, pluralize } from "@services";
import {
  Container,
  Box,
  H1,
  Row,
  Column,
  H2,
  Text,
  Image,
  Flex,
  Button,
  Modal,
  Fade,
  CircleLoader,
  ChevronDown,
} from "@atoms";
import { TextAreaField } from "@molecules";
import { BrandCircle } from "@organisms";

// == Types ================================================================

interface IProps {}
type TTHeme = "dark" | "light";
interface IModuleProps {
  theme?: TTHeme;
  campaign: $FixMe;
}

// == Constants ============================================================

// const CLOSE_REPORT_QUERY = gql`
//   query CampaignCloseReportQuery {
//     Campaign {
//       id
//       closeReport {
//         didMeetGoal
//         topMonth
//         topMembers {
//           id
//           name
//           avatarImage
//         }
//         amountRaised {
//           dollarsAmount
//           formatted
//         }
//         referralsCount
//         referralsAmountRaised {
//           formatted
//         }
//         topCategories
//         teamCaptainName
//         teamEndDate
//         topBrands {
//           id
//           name
//           ...BrandLogoFragment
//         }
//         numGiftcards
//       }
//     }
//   }
//   ${BrandLogo.fragments.fields}
// `;

const MODULE_SPACING = [3, 4, 4];
const CONTAINER_MAX_WIDTH = 900;
const LARGE_MODULE_HEIGHT = 400;
const SMALL_MODULE_HEIGHT = 200;

const GET_COLORS = (theme: TTHeme) => {
  const bg = theme === "dark" ? "closeTeamDarkBg" : "closeTeamLightBg";
  const heading = theme === "dark" ? "black" : "black";
  const color = theme === "dark" ? "white" : "black";
  const highlight = theme === "dark" ? "closeTeamOrange" : "closeTeamOrange";
  const buttonVariant = theme === "light" ? undefined : "highlight";
  return {
    bg,
    heading,
    color,
    highlight,
    buttonVariant,
  };
};

// == Component ============================================================

type TCloseTeamEvents =
  | "Click Survey"
  | "Click Create New Team"
  | "Click Personal Account"
  | "Activate Personal Account"
  | "Click Submit Testimonial";

interface ITrack {
  eventName: TCloseTeamEvents;
  amountRaised: number;
  campaignId: string;
  isOwner: boolean;
  isMember: boolean;
}
// const { campaignId, isOwner, isMember } = useStoreAppValue();
function trackAnalytics({ eventName, amountRaised, campaignId, isOwner, isMember }: ITrack) {
  const agent = isOwner ? "owner" : isMember ? "team-member" : "";
  const totalRaised = amountRaised >= 100 ? ">=100" : amountRaised > 0 ? "<100" : "0";
  analytics.track.trackCloseReportEvent(eventName, {
    campaignId,
    agent,
    totalRaised,
  });
}

function CloseTeamLargeModule(props) {
  return (
    <Flex
      borderRadius="default"
      flexDirection="column"
      height={LARGE_MODULE_HEIGHT}
      justifyContent="center"
      mb={MODULE_SPACING}
      px={4}
      py={4}
      {...props}
    />
  );
}

function CloseTeamSmallModule(props) {
  return (
    <Flex
      borderRadius="default"
      flexDirection="column"
      height={SMALL_MODULE_HEIGHT}
      justifyContent="center"
      mb={MODULE_SPACING}
      px={4}
      py={4}
      {...props}
    />
  );
}

export function CloseTeamHeader({ campaign }) {
  return (
    <Box border="1px solid #E6E6E6" borderRadius="default" mb={MODULE_SPACING} mt={4}>
      <Box bg="lightBg" p={2} textAlign="center">
        <Text>
          Team closed on{" "}
          {!!campaign.closeReport.teamEndDate &&
            format(
              parse(campaign.closeReport.teamEndDate, "yyyy-MM-dd", new Date()),
              "MMM do, yyyy"
            )}
        </Text>
      </Box>
      <Flex
        alignItems="center"
        backgroundImage="url('https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/confetti.png')"
        backgroundSize="cover"
        justifyContent="center"
        minHeight={250}
        px={[2, 2, 4]}
      >
        <H1 fontSize={[5, 6, 6]} lineHeight="title" textAlign="center">
          Your Season
          <br />
          In Review
        </H1>
      </Flex>
    </Box>
  );
}

export function CloseTeamGoalMet() {
  return (
    <CloseTeamLargeModule bg="closeTeamLightBg" textAlign="center">
      <H2 fontSize={4} mb={2}>
        Nailed It
      </H2>
      <Text>You surpassed your fundraising goal!</Text>
      <Box mt={3} textAlign="center">
        <Image
          alt="High Five"
          display="inline"
          maxHeight={200}
          src="https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/high5.png"
        />
      </Box>
    </CloseTeamLargeModule>
  );
}

export function CloseTeamTopMonth({ month }: { month: string }) {
  const monthNum = format(parse(month, "yyyy-MM-dd", new Date()), "M");
  return (
    <CloseTeamSmallModule bg="closeTeamDarkBg">
      <Flex alignItems="center">
        <H2 color="white" fontSize={4} lineHeight="title">
          Your team really racked up the dollars in{" "}
        </H2>
        <Box>
          <Image
            alt="Calendar"
            maxHeight={250}
            src={`https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/Calendar/${monthNum}.png`}
          />
        </Box>
      </Flex>
    </CloseTeamSmallModule>
  );
}

export function CloseTeamTopMembers({ campaign, theme }: IModuleProps) {
  return (
    <CloseTeamLargeModule bg="closeTeamLightBg" textAlign="center">
      <H2 fontSize={4} mb={3}>
        These teammates raised
        <Text display={["none", "none", "block"]} />
        like a boss
      </H2>
      <Text>
        {campaign.closeReport.topMembers.map((fundraiser) => {
          return (
            <>
              <Text color="black">{fundraiser.name}</Text>,{" "}
            </>
          );
        })}
        were the top earners on your team.
      </Text>
      <Box mt={3} pl={20} position="relative" textAlign="center">
        {campaign.closeReport.topMembers.map((fundraiser) => {
          return (
            <Image
              alt="Photo"
              border="5px solid white"
              borderRadius="circle"
              display="inline"
              key={fundraiser.id}
              marginLeft={-20}
              src={fundraiser.avatarImage}
              width={125}
            />
          );
        })}
      </Box>
    </CloseTeamLargeModule>
  );
}

export function CloseTeamRaised({ campaign, theme = "dark" }: IModuleProps) {
  const { bg, heading, color } = GET_COLORS(theme);
  if (campaign.closeReport.amountRaised.dollarsAmount === 0) {
    return (
      <CloseTeamSmallModule bg={bg} textAlign="center">
        <H2 color="black" fontSize={4} mb={2}>
          Your team raised <Text color="white">$0</Text>
        </H2>
        <Text color="black" display="block">
          We see that you had a little trouble
          <Text display={["none", "none", "block"]} />
          getting started.
        </Text>
      </CloseTeamSmallModule>
    );
  }

  return (
    <CloseTeamSmallModule bg="closeTeamDarkBg">
      <H2 color={heading} fontSize={4}>
        Your team raised
      </H2>
      <Text color={color} display="block" fontSize={6} fontWeight="bold" textAlign="right">
        {campaign.closeReport.amountRaised.formatted}
      </Text>
      {campaign.closeReport.amountRaised.dollarsAmount >= 100 && (
        <H2 color={heading} fontSize={4} textAlign="right">
          &amp; we&apos;re impressed
        </H2>
      )}
    </CloseTeamSmallModule>
  );
}

export function CloseTeamReferrals({ theme, campaign }: IModuleProps) {
  return (
    <CloseTeamLargeModule bg="closeTeamLightBg">
      <Box>
        <Image
          alt="Megaphone"
          height={240}
          src="https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/Mic.png"
        />
      </Box>
      <H2 color="closeTeamOrange" fontSize={4} mb={2} mt={3}>
        Good news travels fast
      </H2>
      <Text fontSize={3} lineHeight="title">
        Your team referred <Text color="primary">{campaign.closeReport.referralsCount}</Text> other
        team(s) and earned{" "}
        <Text color="primary">{campaign.closeReport.referralsAmountRaised.formatted}</Text> for it.
      </Text>
    </CloseTeamLargeModule>
  );
}

export function CloseTeamTopCategories({ campaign, theme = "dark" }: IModuleProps) {
  const { color, bg, heading } = GET_COLORS(theme);
  return (
    <CloseTeamLargeModule bg={bg}>
      <H2 color={heading} mb={2}>
        You didn&apos;t spend it all in one place
      </H2>
      <Text color={heading} display="block" mb={3}>
        Here are the top categories your team shopped:
      </Text>
      {campaign.closeReport.topCategories.map((category, index: number) => {
        return (
          <Text color={color} display="block" fontSize={4} key={category} mb={2}>
            {index + 1}. {category}
          </Text>
        );
      })}
    </CloseTeamLargeModule>
  );
}

export function CloseTeamActions({ campaign }) {
  const breakpoint = useBreakpoint();
  const [modalOpen, toggleModalOpen] = useToggle(false);
  const { campaignId, isOwner, isMember } = useStoreAppValue();
  const [mobileDrawerOpen, toggleMobileDrawer] = useToggle(false);
  const mobileDrawerStyleProps = useSpring({
    transform: mobileDrawerOpen ? "translateY(-52px)" : "translateY(110px)",
  });
  const chevronStyleProps = useSpring({
    transform: mobileDrawerOpen ? "rotate(0deg)" : "rotate(180deg)",
  });

  const MODAL_POINTS = [
    "Your personal account is for you to continue shopping and earning between seasons.",
    "Only you have access to your personal account, it is not shared with any other teammates.",
    "To receive your personal earnings, simply transfer them to your next team!",
  ];

  const onOpenModal = () => {
    toggleModalOpen(true);
    trackAnalytics({
      eventName: "Click Personal Account",
      campaignId,
      isOwner,
      isMember,
      amountRaised: campaign.closeReport.amountRaised.dollarsAmount,
    });
  };

  const onActivateClick = () => {
    trackAnalytics({
      eventName: "Activate Personal Account",
      campaignId,
      isOwner,
      isMember,
      amountRaised: campaign.closeReport.amountRaised.dollarsAmount,
    });
  };

  const onCreateTeamClick = () => {
    trackAnalytics({
      eventName: "Click Create New Team",
      campaignId,
      isOwner,
      isMember,
      amountRaised: campaign.closeReport.amountRaised.dollarsAmount,
    });
  };

  const activatePersonalAccountModal = (
    <Modal isOpen={modalOpen} size="small" onDismiss={() => toggleModalOpen(false)}>
      <Box p={4} textAlign="center">
        <H1 color="black">
          Switching To Your{" "}
          <Text color="black" display="block">
            Personal Account
          </Text>
        </H1>
        <Box my={4}>
          {MODAL_POINTS.map((point) => {
            return (
              <Flex flexWrap="nowrap" key={point} mb={2} mx={3}>
                <Box backgroundColor="primary" height={8} minWidth={8} mr={3} mt={2} width={8} />
                <Text lineHeight="1.25">{point}</Text>
              </Flex>
            );
          })}
        </Box>
        <Flex alignItems="center" flexDirection="column" justifyContent="center">
          <Button
            as="a"
            // borderRadius="25px"
            href={RailsUrl.activatePersonalAccountUrl}
            mb={2}
            textDecoration="none"
            width="80%"
            onClick={onActivateClick}
          >
            <Text color="white">Activate My Personal Account</Text>
          </Button>
          <Button
            // borderRadius="25px"
            variant="clear"
            width="80%"
            onClick={() => toggleModalOpen(false)}
          >
            Go Back
          </Button>
        </Flex>
      </Box>
    </Modal>
  );

  if (["xs"].includes(breakpoint)) {
    const drawerStyle = {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
    };
    return (
      <>
        <animated.div style={{ ...drawerStyle, ...mobileDrawerStyleProps }}>
          <Box backgroundColor="closeTeamOrange">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              p={3}
              style={{ cursor: "pointer" }}
              onClick={toggleMobileDrawer}
            >
              <Text color="white" fontWeight="bold">
                Keep earning in the off-season!
              </Text>
              <animated.div style={chevronStyleProps}>
                <ChevronDown color="white" />
              </animated.div>
            </Flex>
            <Box pb={3} px={3}>
              <Text color="white" display="block" mb={2}>
                Our top members continue to earn on their Personal Accounts and bank their cashback
                for next season
              </Text>
              <Box mt={3}>
                <Button
                  as="a"
                  display="block"
                  href={RailsUrl.createTeamUrl()}
                  mb={2}
                  variant="whiteTransparent"
                  onClick={onCreateTeamClick}
                >
                  Start a New Team
                </Button>
                <Button display="block" variant="promoClear" width="100%" onClick={onOpenModal}>
                  Activate Personal Account
                </Button>
              </Box>
            </Box>
          </Box>
        </animated.div>
        {activatePersonalAccountModal}
      </>
    );
  }

  return (
    <>
      <ECloseTeamActionsContainer bg="closeTeamOrange" boxShadow="topRegularShadow" py={3}>
        <Container maxWidth={CONTAINER_MAX_WIDTH} textAlign="center">
          <H2 color="white" mb={1}>
            Keep earning in the off-season!
          </H2>
          <Text color="white" display="block" mb={2}>
            Our top members continue to earn on their Personal Accounts and bank their cashback for
            next season
          </Text>
          <Button
            as="a"
            href={RailsUrl.createTeamUrl()}
            mr={2}
            variant="whiteTransparent"
            width={250}
            onClick={onCreateTeamClick}
          >
            Start a New Team
          </Button>
          <Button variant="promoClear" width={250} onClick={onOpenModal}>
            Activate Personal Account
          </Button>
        </Container>
      </ECloseTeamActionsContainer>
      {activatePersonalAccountModal}
    </>
  );
}

const ECloseTeamActionsContainer = styled(Box)`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }
`;

export function CloseTeamTopBrands({ theme = "dark", campaign }: { theme: TTHeme }) {
  const { bg, color } = GET_COLORS(theme);
  const chunkBy = campaign.closeReport.topBrands.length === 4 ? 2 : 3;
  const groupedBrands = chunk(campaign.closeReport.topBrands, chunkBy);
  return (
    <CloseTeamLargeModule bg={bg}>
      <H2 color={color} mb={3} textAlign="center">
        Your team loves
      </H2>
      {groupedBrands.map((brandGroup, index) => {
        return (
          <Row justifyContent="center" key={brandGroup[0].id} mb={index === 0 ? 3 : 0}>
            {brandGroup.map((brand) => {
              return (
                <Column autoColumn={false} key={brand.id} px={[2]} width={1 / 3}>
                  <BrandCircle
                    border="4px solid white"
                    borderRadius="circle"
                    brand={brand}
                    display="inline"
                  />
                </Column>
              );
            })}
          </Row>
        );
      })}
    </CloseTeamLargeModule>
  );
}

export function CloseTeamPlaceholder({ theme }) {
  const { bg } = GET_COLORS(theme);
  return (
    <CloseTeamLargeModule alignItems="center" bg={bg}>
      <Image
        alt="max"
        src="https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/Max%20Jar.png"
        width={200}
      />
    </CloseTeamLargeModule>
  );
}

export function CloseTeamGiftcards({ theme, campaign }) {
  const { bg, color } = GET_COLORS(theme);
  return (
    <CloseTeamLargeModule bg={bg} textAlign="center">
      <H2 color={color} fontSize={4} mb={2}>
        The gift that keeps on giving
      </H2>
      <Text color={color}>
        Your team purchased{" "}
        {pluralize("gift card", campaign.closeReport.numGiftcards, { showCount: true })}
      </Text>
      <Box mt={3} textAlign="center">
        <Image
          alt="High Five"
          display="inline"
          maxHeight={200}
          src="https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/Giftcard.png"
        />
      </Box>
    </CloseTeamLargeModule>
  );
}
const TESTIMONIAL_CREATE_MUTATION = gql`
  mutation CampaignTestimonialCreateMutation($input: CampaignTestimonialCreateInput!) {
    CampaignTestimonialCreate(input: $input) {
      ... on Campaign {
        id
        testimonial
      }
    }
  }
`;

const TestimonialSchema = Yup.object().shape({
  testimonial: Yup.string().required("Please enter a testimonial"),
});

export function CloseTeamTestimonial({ theme = "light", campaign }: { theme: TTHeme }) {
  const [createTestimonialMutation, { loading }] = useMutation(TESTIMONIAL_CREATE_MUTATION);
  const [modalOpen, toggleModalOpen] = useToggle(false);
  const [showModalSuccess, toggleModalSuccess] = useToggle(false);
  const { bg, color, buttonVariant } = GET_COLORS(theme);
  const { campaignId, isOwner, isMember } = useStoreAppValue();
  const onSubmit = (formData) => {
    createTestimonialMutation({
      variables: {
        input: {
          testimonial: formData.testimonial,
        },
      },
    })
      .then(() => {
        toggleModalSuccess();
      })
      .catch(ErrorService.error);
  };

  const onOpenModalClick = () => {
    toggleModalOpen(true);
    trackAnalytics({
      eventName: "Click Submit Testimonial",
      campaignId,
      isOwner,
      isMember,
      amountRaised: campaign.closeReport.amountRaised.dollarsAmount,
    });
  };

  return (
    <>
      <CloseTeamLargeModule bg={bg} textAlign="center">
        <H2 color={color} fontSize={4} mb={2}>
          Thanks for choosing FlipGive
        </H2>
        <Text color={color} display="block" mb={3}>
          We&apos;re glad we made team funding a little easier for you.
        </Text>
        <Image
          alt="Stars"
          src="https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/Review%20Stars.png"
        />
        <Button
          alignSelf="center"
          display="block"
          mt={3}
          variant="highlight"
          onClick={onOpenModalClick}
        >
          Share your experience
        </Button>
      </CloseTeamLargeModule>
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        isOpen={modalOpen}
        onDismiss={() => toggleModalOpen(false)}
      >
        {!showModalSuccess ? (
          <Box p={3} textAlign="center">
            <Formik
              initialValues={{ testimonial: "" }}
              validationSchema={TestimonialSchema}
              onSubmit={onSubmit}
            >
              {({ values }) => {
                return (
                  <Form>
                    <H2 mb={2}>
                      We&apos;re glad we made team funding <Text display="block" />a little easier
                      for you.
                    </H2>
                    <TextAreaField
                      label="Please take a moment to tell others about your experience."
                      maxLength={250}
                      name="testimonial"
                      placeholder="Your testimonial here..."
                    />
                    <Box textAlign="center">
                      <Button mr={2} type="button" variant="clear" onClick={toggleModalOpen}>
                        Cancel
                      </Button>
                      <Button
                        disabled={loading}
                        type="submit"
                        variant={loading ? "disabled" : "primary"}
                      >
                        {!loading ? "Submit" : "Submitting..."}
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        ) : (
          <Fade duration=".5s" show={showModalSuccess}>
            <Flex alignItems="center" flexDirection="column" px={2} py={4}>
              <CircleLoader delay={50} />
              <H2 mb={2} textAlign="center">
                Thank you!
              </H2>
              <Text display="block" mb={2}>
                We appreciate your feedback.
              </Text>

              <Button type="button" variant="clear" onClick={toggleModalOpen}>
                Close
              </Button>
            </Flex>
          </Fade>
        )}
      </Modal>
    </>
  );
}

export function CloseTeamSurvey({ theme = "dark", campaign }) {
  const { campaignId, isOwner, isMember } = useStoreAppValue();
  const { bg, color } = GET_COLORS(theme);
  const surveyUrl =
    campaign.closeReport.amountRaised.dollarsAmount === 0
      ? " https://www.surveymonkey.com/r/teamclosehelp"
      : "https://www.surveymonkey.com/r/teamclosesuccess";

  const onClick = () => {
    trackAnalytics({
      eventName: "Click Survey",
      campaignId,
      isOwner,
      isMember,
      amountRaised: campaign.closeReport.amountRaised.dollarsAmount,
    });
  };

  return (
    <CloseTeamLargeModule bg={bg} textAlign="center">
      <H2 color={color} fontSize={4} mb={2}>
        How can we better help you?
      </H2>
      <Image
        alt="board"
        display="block"
        height={150}
        mb={2}
        src="https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/Whiteboard.png"
        style={{ alignSelf: "center" }}
      />
      <Text color={color} display="block" mb={3}>
        We&apos;d love to learn more about your experience with us.
      </Text>
      <Button
        alignSelf="center"
        as="a"
        display="block"
        href={surveyUrl}
        target="_blank"
        variant="highlight"
        onClick={onClick}
      >
        Start the survey
      </Button>
    </CloseTeamLargeModule>
  );
}

export function CloseTeamFG({ theme = "light" }) {
  const { bg, color, heading } = GET_COLORS(theme);
  return (
    <CloseTeamLargeModule bg={bg} textAlign="center">
      <H2 color={heading} fontSize={4} mb={3}>
        Teams on FlipGive have earned over{" "}
        <Text color={theme === "light" ? "primary" : "white"}>$20M</Text>
      </H2>
      <Text color={color} display="block" mb={2}>
        FlipGive is used by teams, leagues, community groups, animal shelters, churches and more.
      </Text>
      <Text color={color} display="block" mb={3}>
        The average team earns over{" "}
        <Text color={theme === "light" ? "primary" : "black"}>$500+</Text> simply by shopping with
        our partner stores and brands.
      </Text>
      <Image
        alt="max"
        maxWidth={180}
        mx="auto"
        src="https://s3.amazonaws.com/files.flipgive.com/images/site/close_report/Max.png"
      />
    </CloseTeamLargeModule>
  );
}

export const CloseTeamLoading = () => {
  const LoadingComponent = () => (
    <Container maxWidth={CONTAINER_MAX_WIDTH}>
      <Box mb={3} mt={4}>
        <RectShape color="#E0E0E0" style={{ width: "100%", height: 290, borderRadius: 4 }} />
      </Box>
      <Row>
        <Column autoColumn={false} width={[1, 1 / 2, 1 / 2]}>
          <Box mb={3}>
            <RectShape
              color="#E0E0E0"
              style={{ width: "100%", height: LARGE_MODULE_HEIGHT, borderRadius: 4 }}
            />
          </Box>
          <Box mb={3}>
            <RectShape
              color="#E0E0E0"
              style={{ width: "100%", height: SMALL_MODULE_HEIGHT, borderRadius: 4 }}
            />
          </Box>
          <Box mb={3}>
            <RectShape
              color="#E0E0E0"
              style={{ width: "100%", height: LARGE_MODULE_HEIGHT, borderRadius: 4 }}
            />
          </Box>
        </Column>
        <Column autoColumn={false} width={[1, 1 / 2, 1 / 2]}>
          <Box mb={3}>
            <RectShape
              color="#E0E0E0"
              style={{ width: "100%", height: SMALL_MODULE_HEIGHT, borderRadius: 4 }}
            />
          </Box>
          <Box mb={3}>
            <RectShape
              color="#E0E0E0"
              style={{ width: "100%", height: LARGE_MODULE_HEIGHT, borderRadius: 4 }}
            />
          </Box>
          <Box mb={3}>
            <RectShape
              color="#E0E0E0"
              style={{ width: "100%", height: LARGE_MODULE_HEIGHT, borderRadius: 4 }}
            />
          </Box>
        </Column>
      </Row>
    </Container>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1500}
      ready={false}
    />
  );
};
