import React from "react";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Row, Column, Container } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// == Component ============================================================

const StoreFrontViewLoading = () => {
  const LoadingComponent = () => (
    <Container isMainContainer data-testid="container">
      <RectShape color="#E0E0E0" style={{ width: "100%", height: 300, marginBottom: 25 }} />
      <Row mb={4}>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
      </Row>
      <Row mb={4}>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
      </Row>
      <Row mb={4}>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
      </Row>
      <Row mb={4}>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
        <Column width={[1 / 2, 1 / 2, 1 / 4]}>
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
        </Column>
      </Row>
    </Container>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1000}
      ready={false}
    />
  );
};

export default StoreFrontViewLoading;
