// == Types ================================================================

interface IEdges<TNode> {
  readonly __typename: string;
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: TNode | null;
}

type TEdgesArray<TNode> = ReadonlyArray<IEdges<TNode> | null> | null | undefined;

// == Constants ============================================================

// == Functions ============================================================

// == Exports ==============================================================

export function getPaginatedNodes<TNode extends $IntentionalAny>(
  edges: TEdgesArray<TNode>
): TNode[] {
  if (!edges || !edges.length) return [];

  return edges.reduce<TNode[]>((array, edge) => {
    if (!edge) return array;
    const { node } = edge;
    if (!node) return array;

    array.push(node);
    return array;
  }, []);
}
