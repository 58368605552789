import React from "react";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { LinkBlock } from "@organisms/linkBlockComponents";
import { Flex, Column, Row } from "@atoms";
import { CollectionContentItem } from "../CollectionContentItem";
import { CollectionGridQuarterBannerFragment } from "./__generated__/CollectionGridQuarterBannerFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionGridQuarterBannerFragment;
  storefrontId?: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment CollectionGridQuarterBannerFragment on Collection {
      id
      links(first: 4) {
        id
        ...LinkBlockFragment
      }
      assets {
        ...CollectionContentItemFragment
      }
    }
    ${LinkBlock.fragments.fields}
    ${CollectionContentItem.fragments.fields}
  `,
};

// == Component ============================================================

const CollectionGridQuarterBanner = ({ collection, storefrontId }: IProps) => {
  return (
    <Row flexWrap="wrap">
      <Column autoColumn={false} pb={[3, 0]} width={[1, 1 / 2]}>
        <CollectionContentItem asset={collection.assets[0]} />
      </Column>
      <Column width={[1, 1 / 2]}>
        <Flex flexWrap="wrap" justifyContent="center">
          {collection.links.slice(0, 4).map((link, index) => {
            return (
              <EColumn autoColumn={false} index={index} key={link.id} mb={3} width={[1 / 2]}>
                <LinkBlock link={link} collectionId={collection.id} storefrontId={storefrontId} />
              </EColumn>
            );
          })}
        </Flex>
      </Column>
    </Row>
  );
};

export default CollectionGridQuarterBanner;

CollectionGridQuarterBanner.fragments = FRAGMENTS;

// == Styles ===============================================================
const EColumn = styled(Column)`
  @media only screen and (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding-left: ${(props) =>
      props.index % 2 === 0 ? `${props.theme.space[0]}px` : `${props.theme.space[2]}px`};
    padding-right: ${(props) =>
      props.index % 2 === 0 ? `${props.theme.space[2]}px` : `${props.theme.space[0]}px`};
  }
`;
