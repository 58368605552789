const GIFTCARD_BUTTON_IMAGE =
  "https://d2wkegjj8g6j4.cloudfront.net/images/site/shop/link-modal-giftcard-v2.png";

const SHOP_BUTTON_IMAGE =
  "https://d2wkegjj8g6j4.cloudfront.net/images/site/shop/link-modal-shop.png";

const IN_STORE_BUTTON_IMAGE = "https://flipgive.twic.pics/images/site/shop/link-modal-in-store.png";

export default {
  GIFTCARD_BUTTON_IMAGE,
  SHOP_BUTTON_IMAGE,
  IN_STORE_BUTTON_IMAGE,
};
