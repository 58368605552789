import React from "react";
import { Provider } from "mobx-react";
import { ApolloProvider } from "react-apollo";
import CampaignEventInvite from "./components/CampaignEventInvite";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient } from "@services";
import { FlipgiveStore } from "@stores";

export default (props) => {
  Settings.setDefaultsFromProps(props);

  FlipgiveStore.setCurrentUser(props.currentUser);
  FlipgiveStore.setCampaignId(props.campaignId);

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient(Settings.apiUrl)}>
        <Provider FlipgiveStore={FlipgiveStore}>
          <CampaignEventInvite eventId={props.eventId} />
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
