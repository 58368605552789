import { Box, Flex, Image, TextUppercase, TouchableOpacity } from "@atoms";
import { navigate } from "@reach/router";
import { constants } from "@services";
import { LinkType } from "@types";
import gql from "graphql-tag";
import { LinkBlockGives } from "../LinkBlockGives";
import { RelatedLinkBlockFragment } from "./__generated__/RelatedLinkBlockFragment";

// == Types ================================================================

interface IProps {
  link: RelatedLinkBlockFragment;
}

// == Constants ============================================================

RelatedLinkBlock.defaultProps = {};

RelatedLinkBlock.fragment = gql`
  fragment RelatedLinkBlockFragment on Link {
    id
    type
    name
    shortName
    squareImage
    promoSecondaryLabel
    promoEndsAt
    promoLabel
    giveSentence
    isBulkShop
  }
`;

// == Component ============================================================

export function RelatedLinkBlock({ link }: IProps) {
  const onClick = () => {
    navigate(`?${constants.linkParam}=${link.id}`);
  };

  return (
    <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={onClick}>
      <Flex flexDirection="column">
        <Box position="relative">
          {!!link.squareImage && (
            <Image
              alt="link-image"
              borderRadius={
                [
                  LinkType.GIFTCARDOFFER,
                  LinkType.GIFTCARDSHOP,
                  LinkType.VOUCHER,
                  LinkType.AUTORELOADGIFTCARD,
                ].includes(link.type)
                  ? "giftcard"
                  : 0
              }
              boxShadow="lightShadow"
              loading="lazy"
              src={`${link.squareImage}&twic=v1/resize=200x200`}
            />
          )}
        </Box>

        <TextUppercase ellipsis as="p" fontSize={[0]} pt={2} textAlign="center">
          {link.shortName ? link.shortName : link.name}
        </TextUppercase>
        <LinkBlockGives
          giveSentence={link.giveSentence}
          promoEndsAt={link.promoEndsAt}
          secondaryLabel={link.promoSecondaryLabel}
        />
      </Flex>
    </TouchableOpacity>
  );
}

// == Styles ===============================================================
