import React, { useState } from "react";
import { oc } from "ts-optchain";
import { isBefore } from "date-fns";
import { navigate } from "@reach/router";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { Breakpoint } from "react-socks";
import { RailsUrl, ErrorService, constants } from "@services";
import { CardForm, Box, Image, Text, Flex, Button, H3 } from "@atoms";
import { useStoreAppValue, useWindowSize } from "@hooks";
import { REACT_SOCKS_BREAKPOINTS } from "@styles";
import { LinkType } from "@types";
import { CheckoutBuy } from "../CheckoutBuy";

// == Types ================================================================

interface IProps {
  giftcardIntent: $FixMe;
  isValid: boolean;
  postalCodeErrorMessage: string | null;
  dispatch: $FixMe;
  formValues: $FixMe;
}

// == Constants ============================================================

const CHECKOUT_ORDER_SUMMARY_FRAGMENT = gql`
  fragment CheckoutOrderSummaryFragment on Viewer {
    id
    stripeCards {
      id
      brand
      default
      last4
      expiryFormatted
      expiryMonth
      expiryYear
    }
  }
`;
const BUY_PROCESS = gql`
  mutation BuyProcessMutation($id: ID!, $input: BuyProcessInput!) {
    BuyProcess(id: $id, input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on GiftcardIntent {
        id
        buyerEmail
        buyerName
        createdAt
        isGift
        isPending
        isBeingReviewed
        token
        quantity
        link {
          id
          name
        }
        gifting {
          name
          email
          message
          deliverOn
        }
        shipping {
          city
          country
          postalCode
          province
          street1
          street2
        }
        shippingAmount {
          centsAmount
          dollarsAmount(round: 2)
          formatted(round: 2)
        }
        unitPrice {
          centsAmount
          dollarsAmount(round: 2)
          formatted(round: 2)
        }
        orderTotal {
          centsAmount
          dollarsAmount(round: 2)
          formatted(round: 2)
        }
        giveAmount {
          centsAmount
          dollarsAmount(round: 2)
          formatted(round: 2)
        }
      }
    }
  }
`;

// == Component ============================================================
CheckoutOrderSummary.defaultProps = {};
CheckoutOrderSummary.fragments = { fields: CHECKOUT_ORDER_SUMMARY_FRAGMENT };

const jan1 = new Date(2021, 0, 2); // nov 1st
const showXmasWarning = isBefore(new Date(), jan1);

export default function CheckoutOrderSummary({
  giftcardIntent,
  isValid,
  formValues,
  postalCodeErrorMessage,
  dispatch,
}: IProps) {
  const { width } = useWindowSize();
  const [submitting, setSubmitting] = useState(false);
  const [serverError, setError] = useState();
  const { currency } = useStoreAppValue();
  const [buyProcessMutation] = useMutation(BUY_PROCESS);

  const onPlaceOrder = async () => {
    const { cardId, shouldSaveCard, postalCode } = formValues;
    setError("");
    const cardVariables = {};

    if (!cardId.includes("tok")) {
      cardVariables.stripeCardId = cardId;
      cardVariables.stripeVerificationCode = postalCode;
    } else {
      cardVariables.stripeTokenId = cardId;
      cardVariables.shouldSaveCard = shouldSaveCard;
    }

    if (isValid) {
      dispatch({ type: "SET_POSTAL_CODE_ERROR", error: null });
      setSubmitting(true);
      buyProcessMutation({
        variables: {
          id: giftcardIntent.id,
          input: {
            ...cardVariables,
          },
        },
      })
        .then(({ data }) => {
          if (oc(data).BuyProcess.errors()) {
            setSubmitting(false);
            const errors = oc(data).BuyProcess.errors();
            const errorsOutput = errors
              .map(
                (error) => `input: ${error.input}, inputErrors: ${error.inputErrors.join(", ")}.`
              )
              .join(". ");
            ErrorService.error(errorsOutput);
            errors.forEach((error) => {
              let foundError = false;
              if (error.input === "stripeVerificationCode") {
                foundError = true;
                dispatch({
                  type: "SET_POSTAL_CODE_ERROR",
                  error:
                    error.inputErrors[0] ||
                    `Please enter the correct ${
                      currency === "USD" ? "ZIP Code" : "Postal Code"
                    } for your card.`,
                });
              }

              if (
                error.input === "buyInput" &&
                error.inputErrors[0] === "unable to complete purchase"
              ) {
                foundError = true;
                setError(
                  "We were unable to complete your purchase. Please try again with another payment method."
                );
              }

              if (error.input === "quantity" && !!error.inputErrors[0]) {
                foundError = true;
                setError(error.inputErrors[0]);
              }

              if (!foundError) {
                setError("An error occurred and we were unable to complete your purchase.");
              }
            });
          } else {
            const buyIntent = data.BuyProcess;
            dispatch({ type: "SET_POSTAL_CODE_ERROR", error: null });
            navigate(RailsUrl.checkoutThanksUrl(buyIntent.token), {
              state: { buyIntent },
              replace: true,
            });
          }
        })
        .catch((e) => {
          ErrorService.error(e);
          setError("An error occurred and we were unable to complete the transaction.");
          setSubmitting(false);
        });
    }
    return false;
  };

  const totalCost = `$${(giftcardIntent.quantity * giftcardIntent.unitPrice.dollarsAmount).toFixed(
    2
  )}`;
  return (
    <>
      <CardForm
        contentPadding={["0 24px 0 24px", "0 24px 0 24px", "24px"]}
        heading={width > REACT_SOCKS_BREAKPOINTS[2].medium ? "Order Summary" : null}
      >
        <Box mb={4} textAlign="center">
          <Breakpoint medium up>
            <Flex justifyContent="center">
              {oc(giftcardIntent).link.image() && (
                <EGiftcardImage
                  alt="gift-card-preview"
                  borderRadius="giftcard"
                  boxShadow="lightShadow"
                  src={giftcardIntent.link.image}
                  width="50%"
                />
              )}
            </Flex>
            <H3 fontWeight="bold" mt={3}>
              {giftcardIntent.link.name}
            </H3>
          </Breakpoint>
          <Breakpoint down small>
            <H3 fontWeight="bold">Order Summary</H3>
          </Breakpoint>
        </Box>
        <Flex alignItems="flex-start" justifyContent="space-between" mb={2}>
          <Text fontSize={1} lineHeight="1.4" pr={2}>
            {giftcardIntent.quantity} x {giftcardIntent.unitPrice.formatted}{" "}
            {giftcardIntent.link.name}
            {giftcardIntent.link.type === LinkType.AUTORELOADGIFTCARD &&
              giftcardIntent.reloadSentence && (
                <Text color="primary" display="block" fontSize={1}>
                  {giftcardIntent.reloadSentence}
                </Text>
              )}
          </Text>
          <Text fontSize={1}>{totalCost}</Text>
        </Flex>
        {giftcardIntent.isPhysical && (
          <Flex justifyContent="space-between" mb={2}>
            <Text fontSize={1}>Shipping Cost</Text>
            <Text fontSize={1}>{giftcardIntent.shippingAmount.formatted}</Text>
          </Flex>
        )}
        <Box borderBottom="1px dashed #ccc" mb={2} width="100%" />
        <Flex justifyContent="space-between" mb={2}>
          <Text fontSize={1}>Order Total</Text>
          <Text fontSize={1}>{giftcardIntent.orderTotal.formatted}</Text>
        </Flex>
        {giftcardIntent.quantity * giftcardIntent.unitPrice.dollarsAmount >
          constants.GIFTCARD_PURCHASE_DELAY_LIMIT && (
          <Flex bg="notification" boxShadow="regularShadow" color="notificationColor" mb={2} p={2}>
            <Text fontSize={0}>This amount can take up to 3 hours to be delivered.</Text>
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <Text color="primary" fontWeight="bold" mr={3}>
            {giftcardIntent.campaign ? giftcardIntent.campaign.name : "You"} will earn
          </Text>
          <EEarned color="primary" fontWeight="bold">
            {giftcardIntent.giveAmount.formatted}
          </EEarned>
        </Flex>
        <Box display="block" lineHeight={1} mb={[2, 3]} textAlign="center">
          {giftcardIntent.link.type === LinkType.GIFTCARDOFFER && (
            <Text fontSize={0}>
              Gift Card purchases are FINAL SALE and are not eligible for refunds or exchange.
              {giftcardIntent.link.reloadableGiftcardInfo.isManualReloadable && (
                <>
                  <br />
                  <br />
                  FlipGive will notify you when this card&apos;s balance is low. You can top this
                  card up in the FlipGive Wallet at any time after purchase.
                </>
              )}
            </Text>
          )}
          {giftcardIntent.link.type === LinkType.AUTORELOADGIFTCARD && showXmasWarning && (
            <Text fontSize={0}>
              This gift card will automatically reload{" "}
              {giftcardIntent.reloadThreshold?.formatted &&
                `at ${giftcardIntent.reloadThreshold?.formatted} `}
              using this payment method. You can also top this card up in the FlipGive Wallet any
              time after purchase.
            </Text>
          )}
          {giftcardIntent.link.type === LinkType.VOUCHER && (
            <Text fontSize={0}>
              By Placing Order, I agree that all Voucher purchases are FINAL SALE and are not
              eligible for refunds or exchange, and confirm that I have read and understood this
              product&apos;s terms and conditions. Physical Vouchers are delivered in 4 to 15
              business days.
            </Text>
          )}
        </Box>
        <Breakpoint medium up>
          <>
            <Button
              disabled={!isValid || submitting}
              variant={isValid || submitting ? "primary" : "disabled"}
              width="100%"
              onClick={onPlaceOrder}
            >
              {submitting ? "Ordering..." : "Place Order"}
            </Button>
            {(postalCodeErrorMessage || serverError) && (
              <EErrorText display="block" lineHeight="title" mt={1}>
                {postalCodeErrorMessage || serverError}
              </EErrorText>
            )}
            {giftcardIntent.deliveryEstimate && (
              <Text display="block" fontSize={0} mt={1} textAlign="center">
                {giftcardIntent.deliveryEstimate}
              </Text>
            )}
          </>
        </Breakpoint>
      </CardForm>
      <Breakpoint down small>
        <CheckoutBuy
          errorMessage={postalCodeErrorMessage || serverError}
          giftcardIntent={giftcardIntent}
          isValid={isValid}
          submitting={submitting}
          onPlaceOrder={onPlaceOrder}
        />
      </Breakpoint>
    </>
  );
}

// == Styles ===============================================================

const EErrorText = styled(Text)`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.danger};
`;

const EEarned = styled(Text)`
  align-self: flex-end;
`;

const EGiftcardImage = styled(Image)`
  align-self: center;
`;
