import {
  FeatureFlagQuery,
  FeatureFlagQueryVariables,
} from "@components/common/pages/intentLoadingComponents/IntentLoadingView/__generated__/FeatureFlagQuery";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";

export const FEATURE_FLAG_QUERY = gql`
  query FeatureFlagQuery($feature: String!) {
    FeatureFlag(feature: $feature) {
      enabled
    }
  }
`;

export const useFeatureFlag = (feature: string) => {
  const { data, loading, error } = useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(
    FEATURE_FLAG_QUERY,
    {
      variables: {
        feature,
      },
    }
  );

  return {
    isEnabled: !!data?.FeatureFlag.enabled,
    loading,
    error,
  };
};
