import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ApolloError } from "apollo-client";
import { Flex, Box, Text, Button, Input, Modal, ChevronLeft, TouchableOpacity } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { ErrorService } from "@services";
import {
  EditSubGroupMutation,
  EditSubGroupMutationVariables,
} from "./__generated__/EditSubGroupMutation";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  id: string;
  name: string;
  isEditSubGroupOpen: boolean;
  setIsEditSubGroupOpen: (isEditSubGroupOpen: boolean) => void;
}

// == Constants ============================================================

EditSubGroup.defaultProps = {};

const EDIT_SUB_GROUP_MUTATION = gql`
  mutation EditSubGroupMutation($id: ID!, $name: String!) {
    CampaignGroupUpdate(id: $id, name: $name) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
        name
        groups {
          id
          name
        }
        fundraisers {
          id
          name
          group {
            id
            name
          }
        }
      }
    }
  }
`;
// == Component ============================================================

export default function EditSubGroup({
  id,
  name,
  isEditSubGroupOpen,
  setIsEditSubGroupOpen,
}: IProps) {
  const [pendingGroupName, setPendingGroupName] = useState("");
  const onError = (error?: ApolloError) => {
    if (error) ErrorService.error(error);
    Materialize?.toast?.(
      `There was an ERROR editing the ${pendingGroupName} subgroup. Please try again.`,
      4000,
      "rounded toast-danger"
    );
    setIsEditSubGroupOpen(false);
  };

  const isPendingGroupNameUsed = pendingGroupName === name;

  const [createEditSubGroupMutation, { loading }] = useMutation<
    EditSubGroupMutation,
    EditSubGroupMutationVariables
  >(EDIT_SUB_GROUP_MUTATION, {
    onError,
    onCompleted: ({ CampaignGroupUpdate }) => {
      if (CampaignGroupUpdate.__typename === "MutationError") {
        onError();
        return;
      }
      Materialize?.toast?.(
        `The group name has been updated to ${pendingGroupName}`,
        4000,
        "rounded toast-success"
      );
      setIsEditSubGroupOpen(false);
    },
  });
  const primary = useStoreAppValue()?.theme?.color?.primary ?? "#27B7BA";

  const onChange = (e) => {
    setPendingGroupName(e.target.value);
  };

  const onClickEditSubGroup = () => {
    return isPendingGroupNameUsed
      ? null
      : createEditSubGroupMutation({
          variables: { id, name: pendingGroupName },
        });
  };

  return (
    <Modal isOpen={isEditSubGroupOpen} onDismiss={() => setIsEditSubGroupOpen(false)}>
      <Flex alignItems="center" flexDirection="column" justifyContent="center" padding={3}>
        <Flex alignSelf="flex-start" paddingBottom={2}>
          <TouchableOpacity onTouch={() => setIsEditSubGroupOpen(false)}>
            <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
          </TouchableOpacity>
          <Box paddingLeft={[2, 3]}>
            <Text fontWeight="bold">Edit Player</Text>
          </Box>
        </Flex>
        <Flex alignItems="center" flexDirection="column" padding={2} width={0.6}>
          <Input
            height={40}
            minWidth={200}
            placeholder={name}
            value={pendingGroupName}
            width={0.6}
            onChange={(e) => onChange(e)}
          />
        </Flex>
        <Flex alignItems="center" justifyContent="center" py={2}>
          <Button
            height={50}
            minWidth={200}
            variant={isPendingGroupNameUsed ? "disabled" : "primary"}
            onClick={onClickEditSubGroup}
          >
            Edit Player
          </Button>
        </Flex>
        <Box py={2} textAlign="center" width={1}>
          <Button
            height={50}
            minWidth={200}
            variant="clear"
            onClick={() => setIsEditSubGroupOpen(false)}
          >
            <Text color="primary" fontSize={[1, 2]}>
              Cancel
            </Text>
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
}

// == Styles ===============================================================
