import { useCallback } from "react";
import { useStoreAppValue } from "@hooks/context/storeAppContext";
import { ErrorService } from "@services";

// == Types ================================================================

// == Constants ============================================================

// == Hooks ================================================================

export function useRecaptcha({ action }: { action: string }) {
  const { recaptchaEnterpriseKey } = useStoreAppValue();

  return useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise
            .execute(recaptchaEnterpriseKey, { action })
            .then((token) => {
              resolve(token);
            })
            .catch((error) => {
              ErrorService.error(error);
              reject(error);
            });
        });
      } catch (error) {
        ErrorService.error(error);
        reject(error);
      }
    });
  }, [action]);
}
