import { Box, Flex, FontAwesome, Text, TouchableOpacity } from "@atoms";
import styled from "@emotion/styled";
import { useLoadingDelay } from "@hooks";
import { combinedFundraisersMaker, isFundraiserActive } from "@services";
import { theme } from "@styles";
import { FadeIn } from "@utils";
import gql from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useQuery } from "react-apollo";
import ReactPlaceholder from "react-placeholder";
import { RectShape, TextRow } from "react-placeholder/lib/placeholders";
import {
  LinkModalBulkShopQuery,
  LinkModalBulkShopQueryVariables,
  LinkModalBulkShopQuery_Campaign,
} from "./__generated__/LinkModalBulkShopQuery";

// == Types ================================================================

interface IProps {
  isFundraiserSelectorOpen: boolean;
  setIsFundraiserSelectorOpen: (value: boolean) => void;
  setSelectedFundraisers: (value: string[]) => void;
  combinedFundraisersCount: number;
  selectedFundraisers: string[] | [];
  campaignId?: string;
  linkId: string | null;
}

// == Constants ============================================================
// *** === NB: made stand-alone query for BULK SHOP to avoid extra query on regular non-BulkShop Link Modals
// *** === Placed both Team-Store banner component shown in <LinkModal /> AND the team split modal selector here to easily share returned data from Query -kjl

export const LINK_MODAL_BULK_SHOP_QUERY = gql`
  query LinkModalBulkShopQuery($id: ID!, $campaignId: ID!) {
    Link(id: $id) {
      id
      type
      isBulkShop
    }
    Campaign(id: $campaignId) {
      id
      name
      groups {
        id
        name
        paginatedFundraisers(first: 200) {
          edges {
            cursor
            node {
              id
              name
              firstName
              lastName
              joinedAt
              deactivatedAt
              userId
            }
          }
        }
      }
      noGroupFundraisers {
        id
        name
        paginatedFundraisers(first: 200) {
          edges {
            cursor
            node {
              id
              name
              firstName
              lastName
              joinedAt
              deactivatedAt
              userId
            }
          }
        }
      }
    }
  }
`;

LinkModalBulkShop.defaultProps = {};

// == Functions ============================================================

const renderError = () => {
  return (
    <Box>
      <Text>There was a problem, please try again.</Text>
    </Box>
  );
};

const {
  colors: { placeholderLightGrey, purple, lightPurple, gray, primary },
  borders: { lightSilver },
} = theme;

const loadingComponent = (
  <Box padding={3}>
    <Flex flexDirection="column" justifyContent="space-between">
      <Box my={[1]}>
        <TextRow
          color={placeholderLightGrey}
          style={{ width: 200, height: 30, borderRadius: 10 }}
        />
      </Box>
      <Box my={[1]}>
        <TextRow
          color={placeholderLightGrey}
          style={{ width: 400, height: 20, borderRadius: 10 }}
        />
      </Box>
      <Box>
        <RectShape
          color={placeholderLightGrey}
          style={{ width: 200, height: 30, borderRadius: 5 }}
        />
      </Box>
    </Flex>
  </Box>
);

const activeFundraisersMaker = (campaign: LinkModalBulkShopQuery_Campaign) => {
  return combinedFundraisersMaker(campaign).filter((fundraiser) => isFundraiserActive(fundraiser));
};

// == Component ============================================================

function LinkModalBulkShop({
  setIsFundraiserSelectorOpen,
  setSelectedFundraisers,
  isFundraiserSelectorOpen,
  selectedFundraisers,
  campaignId,
  linkId,
}: IProps) {
  // *** DATA FETCHING ===================================================

  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery<
    LinkModalBulkShopQuery,
    LinkModalBulkShopQueryVariables
  >(LINK_MODAL_BULK_SHOP_QUERY, {
    variables: {
      id: linkId,
      campaignId,
    },
    onCompleted: (data) => {
      const campaign = data?.Campaign;
      if (isEmpty(selectedFundraisers))
        return setSelectedFundraisers(activeFundraisersMaker(campaign));
      return null;
    },
  });

  if (error) renderError();
  if (loading || isEmpty(data) || !loadingDelayReady) {
    return (
      <ReactPlaceholder
        showLoadingAnimation
        color="#FFF"
        customPlaceholder={loadingComponent}
        ready={false}
      />
    );
  }

  const link = data?.Link;
  const campaign = data?.Campaign;
  if (!link) return null;

  // *** DATA HANDLING =======================================================

  const combinedFundraisers = combinedFundraisersMaker(campaign);
  const combinedFundraisersCount = combinedFundraisers.length;
  const combinedFundraisersId = combinedFundraisers
    .map((fundraiser) => fundraiser.id)
    .sort((a, b) => a - b);
  const selectedFundraisersCount = selectedFundraisers.length;
  const selectedFundraisersId = selectedFundraisers
    .map((fundraiser) => fundraiser.id)
    .sort((a, b) => a - b);

  // *** TEXT COPY HELPERS =======================================================

  const renderFundraiserCount = () => {
    if (selectedFundraisersCount === combinedFundraisersCount) return `All team members will earn`;
    if (selectedFundraisersCount === 0) return `0 team members will earn`;
    if (selectedFundraisersCount === 1)
      return `1/${combinedFundraisersCount} team member will earn`;
    return `${selectedFundraisersCount}/${combinedFundraisersCount} team members will earn`;
  };

  // *** SELECTION HELPERS =======================================================

  const updateSelection = (isSelected: boolean, id: string | number) => {
    const updatedSelectedFundraisers = selectedFundraisers;
    const [selectedFundraiser] = combinedFundraisers.filter((fundraiser) => fundraiser.id === id);

    if (isSelected) {
      return setSelectedFundraisers(
        updatedSelectedFundraisers.filter((fundraiser) => fundraiser.id !== id)
      );
    }
    if (!isSelected)
      return setSelectedFundraisers([selectedFundraiser, ...updatedSelectedFundraisers]);
    return null;
  };

  const isSelectedAll = isEqual(selectedFundraisersId, combinedFundraisersId);
  const isSelectedEmpty = isEmpty(selectedFundraisersId);

  const toggleSelectAll = () => {
    if (isSelectedAll) return setSelectedFundraisers([]);
    return setSelectedFundraisers(combinedFundraisers);
  };

  const renderCheckbox = (isSelected: boolean) => {
    return (
      <Box paddingRight={2}>
        {isSelected ? (
          <Text color={purple} fontSize={[3, 4]}>
            <FontAwesome icon="check-square" />
          </Text>
        ) : (
          <Text color={lightPurple} fontSize={[3, 4]}>
            <FontAwesome icon={["far", "square"]} />
          </Text>
        )}
      </Box>
    );
  };

  const renderLeftArrow = () => (
    <Box marginLeft={[2, 3]}>
      <TouchableOpacity onTouch={() => setIsFundraiserSelectorOpen(false)}>
        <Text color={`${primary}`} fontSize={[3, 4]}>
          <FontAwesome icon="arrow-left" />
        </Text>
      </TouchableOpacity>
    </Box>
  );

  // *** RETURNABLES ===========================================================

  // *** === LinkModal Status Banner === will only render if isBulkShop === true
  if (!isFundraiserSelectorOpen) {
    return (
      <Box bg={purple} p={[3]}>
        <Flex flexDirection="column" justifyContent="space-between" alignItems="center">
          {/* <Box>
            <Text color="white" fontSize={[3, 4]} fontWeight="bold">
              Team Purchase
            </Text>
          </Box> */}
          <Box>
            <Text color="white">
              Note: TeamBuy offers give cash back to the team and not to a supporter.
            </Text>
          </Box>
          {/* <TouchableOpacity onTouch={() => setIsFundraiserSelectorOpen( true )}>
            <Box>
              <Box
                backgroundColor="white"
                border={`2px solid ${lightPurple}`}
                borderRadius="5px"
                mt={[2]}
                py={[1, 2]}
                textAlign="center"
                width="200px"
              >
                <Text color={`${purple}`}>Select Team Members</Text>
              </Box>
            </Box>
          </TouchableOpacity> */}
        </Flex>
      </Box>
    );
  }

  // *** === Team Split Edit Modal ===
  return (
    <FadeIn>
      <ELinkModalBulkShopContainer padding={[2, 3]}>
        <Flex alignItems="center" justifyContent="flex-start" width="100%">
          {renderLeftArrow()}
        </Flex>

        <Box pb={[2, 3]}>
          <Text fontSize={[2, 3]} fontWeight="bold">
            Who gets a share of this cashback?
          </Text>
        </Box>

        <Flex mb={[2, 3]} mx={[2, 3]} px={[3, 4]}>
          <Text fontSize={[1, 2]} textAlign="center">
            Cashback earned from this purchase will be split equally across the team members
            selected below.
          </Text>
        </Flex>

        <Flex alignItems="center" alignSelf="flex-start" mx={[4, 5]} my={2} px={[2, 3]} py={[1, 2]}>
          <TouchableOpacity onTouch={() => toggleSelectAll()}>
            {renderCheckbox(!isSelectedEmpty)}
          </TouchableOpacity>
          <Text fontWeight="bold">{renderFundraiserCount()}</Text>
        </Flex>
        <EFundraiserListContainer>
          <Box borderTop={lightSilver} mx={[4, 5]} />
          {combinedFundraisers.map((fundraiser) => {
            const { userId, id, name } = fundraiser;
            const isSelected = selectedFundraisersId.includes(id);
            return (
              <TouchableOpacity key={userId} onTouch={() => updateSelection(isSelected, id)}>
                <Flex
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="flex-start"
                  mx={[4, 5]}
                  my={[2]}
                  px={[2, 3]}
                  py={[1, 2]}
                >
                  {renderCheckbox(isSelected)}
                  <Box>
                    <Text>{name}</Text>
                  </Box>
                </Flex>
              </TouchableOpacity>
            );
          })}
        </EFundraiserListContainer>

        <Flex justifyContent="center" paddingBottom={[2, 3]} width="100%">
          <TouchableOpacity
            onTouch={isSelectedEmpty ? null : () => setIsFundraiserSelectorOpen(false)}
          >
            <Box
              backgroundColor={isSelectedEmpty ? gray : primary}
              borderRadius="5px"
              mt={[4]}
              px={[2, 3]}
              py={[1, 2]}
              textAlign="center"
              width="500px"
            >
              <Text color="white">{isSelectedEmpty ? "Select Fundraisers" : "Save"}</Text>
            </Box>
          </TouchableOpacity>
        </Flex>
      </ELinkModalBulkShopContainer>
    </FadeIn>
  );
}

export default LinkModalBulkShop;

// == Styles ===============================================================

export const ELinkModalBulkShopContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;
  height: 100vh;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 80vh;
  }
`;

export const EFundraiserListContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  width: 100%;
`;
