import React from "react";
import gql from "graphql-tag";
import { LinkType } from "@types";
import { useStoreAppValue } from "@hooks";
import { RailsUrl, renderIf, linkButtonImageUrl } from "@services";
import { Box, Image } from "@atoms";
import { InStoreModalQuery_Link_location } from "../InStoreModal/__generated__/InStoreModalQuery";
import { InStoreBuyButtonFragment } from "./__generated__/InStoreBuyButtonFragment";
import { EButtonIconBox, EInStoreBuyButton } from "./InStoreBuyButtonEmotion";

// == Types ================================================================

interface IProps {
  campaignId?: string;
  fundraiserId?: string;
  link: InStoreBuyButtonFragment;
  linkLocation?: InStoreModalQuery_Link_location;
  onLinkClick: () => void;
  selectedFundraisersId: string[];
  tinyLinkKey?: string;
}

// == Constants ============================================================

InStoreBuyButton.fragment = gql`
  fragment InStoreBuyButtonFragment on Link {
    id
    type
    isBulkShop
    buttonCTA
    defaultBrand {
      id
    }
  }
`;

const DEFAULT_PROPS = {
  selectedFundraisersId: [],
};

// == Component ============================================================

// TODO: remove giftcard code here once checkout goes up

function InStoreBuyButton({
  link,
  campaignId,
  fundraiserId,
  onLinkClick,
  tinyLinkKey,
  linkLocation,
}: IProps) {
  const {
    currentUser,
    featureFlags: { newCheckout },
  } = useStoreAppValue();

  const { GIFTCARDOFFER, EMPYR, AUTORELOADGIFTCARD } = LinkType;
  const { GIFTCARD_BUTTON_IMAGE, SHOP_BUTTON_IMAGE, IN_STORE_BUTTON_IMAGE } = linkButtonImageUrl;

  const isGiftcard = link.type === GIFTCARDOFFER;
  const isEmpyr = link.type === EMPYR;

  if ((isGiftcard && newCheckout) || link.type === AUTORELOADGIFTCARD) return null;

  const userNeedsSignUp = !currentUser && !campaignId && !fundraiserId;
  const { isBulkShop } = link;

  const imageAltCopyMaker = () => {
    if (isGiftcard) return "Giftcard";
    if (isEmpyr) return "Empyr";
    return "Shop";
  };

  const imagePointer = () => {
    if (isGiftcard) return GIFTCARD_BUTTON_IMAGE;
    if (isEmpyr) return IN_STORE_BUTTON_IMAGE;
    return SHOP_BUTTON_IMAGE;
  };

  let href;
  let ctaText;
  if (userNeedsSignUp) {
    href = `${RailsUrl.createTeamUrl()}?s=${tinyLinkKey}`;
    ctaText = "Sign Up And Register Your Team To Shop";
  } else if (isEmpyr && currentUser) {
    href = RailsUrl.createInStoreUrl({
      campaignId,
      linkId: link.id,
      locationId: linkLocation.id,
      fundraiserId,
    });
    ctaText = link.buttonCTA;
  }

  return (
    <EInStoreBuyButton
      as="a"
      href={href}
      isBulkShop={!!isBulkShop}
      target="_blank"
      userNeedsSignUp={userNeedsSignUp}
      onClick={() => currentUser && onLinkClick()}
    >
      {ctaText}
    </EInStoreBuyButton>
  );
}

InStoreBuyButton.defaultProps = DEFAULT_PROPS;

export default InStoreBuyButton;

// == Styles ===============================================================
