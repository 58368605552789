import React, { ReactNode } from "react";
import { useField } from "formik";
import styled from "@emotion/styled";
import { Text, CircleIcon, Flex, Box } from "@atoms";

// == Types ================================================================

interface IProps {
  label: string;
  leftContent?: ReactNode | string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  value: string;
  secondaryLabel?: string;
  activeComponent?: ReactNode;
}

// == Constants ============================================================

PrettyRadioOption.defaultProps = {
  disabled: false,
};

// == Component ============================================================

export default function PrettyRadioOption({ activeComponent, ...rest }: IProps) {
  const { label, disabled, leftContent, secondaryLabel } = rest;
  const [field, meta] = useField({ ...rest, type: "radio" });
  const id = `${field.name}-${field.value}`;
  return (
    <>
      <ELabel checked={field.checked} htmlFor={id}>
        <Flex alignItems="center">
          {!!leftContent && leftContent}
          <Flex
            alignItems="flex-start"
            flex={1}
            flexDirection="column"
            justifyContent="center"
            ml={leftContent ? 3 : 0}
          >
            <ETextLabel as="p" color={field.checked ? "primary" : "default"} fontSize={1}>
              {label}
            </ETextLabel>
            {secondaryLabel && (
              <Text fontSize={0} lineHeight="solid">
                Expires on {secondaryLabel}
              </Text>
            )}
          </Flex>
          <EInput disabled={disabled} id={id} name={field.name} type="radio" {...field} {...rest} />
          {field.checked ? (
            <Flex
              alignItems="center"
              bg="white"
              borderColor="primary"
              borderRadius="circle"
              borderStyle="solid"
              borderWidth={2}
              height="24px"
              justifyContent="center"
              width="24px"
            >
              <Box
                bg="primary"
                borderColor="primary"
                borderRadius="circle"
                borderWidth="primary"
                height="14px"
                width="14px"
              />
            </Flex>
          ) : (
            <CircleIcon containerProps={{ fontSize: 4, alignItems: "center" }} />
          )}
          <span className="radiobtn" />
        </Flex>
        {field.checked && activeComponent}
      </ELabel>
    </>
  );
}

// == Styles ===============================================================

const ELabel = styled.label`
  border: ${({ theme }) => theme.borders.input};
  border-color: ${({ theme, checked }) =>
    checked ? theme.colors.primary : theme.colors.inputBorder};
  display: block;
  border-radius: ${({ theme }) => theme.radii.input}px;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[3]}px;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  transition: border 150ms ease-in, color 200ms ease-in;
  cursor: pointer;
`;

const ETextLabel = styled(Text)`
  transition: color 150ms ease-in;
  display: flex;
  align-items: center;
`;

const EInput = styled.input``;
