import React from "react";
import gql from "graphql-tag";
import { RailsUrl } from "@services";
import { Flex, Button, Text } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { FundsPageWithdrawalsButtonFragment } from "./__generated__/FundsPageWithdrawalsButtonFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPageWithdrawalsButtonFragment;
}

// == Constants ============================================================

FundsPageWithdrawalsButton.fragments = gql`
  fragment FundsPageWithdrawalsButtonFragment on Campaign {
    id
    hasBeneficiary
    viewerFundraiser {
      id
      isCaptainable
      isCoCaptainable
    }
  }
`;

// == Component ============================================================

export default function FundsPageWithdrawalsButton({ campaign }: IProps) {
  const { payoutsUrl } = RailsUrl;
  const { hasBeneficiary, isViewerOwner, handlesMoney, viewerFundraiser } = campaign;
  const isWithdrawalReady =
    hasBeneficiary &&
    (isViewerOwner || viewerFundraiser?.isCaptainable || viewerFundraiser?.isCoCaptainable);

  const {
    featureFlags: { newBeneficiary },
  } = useStoreAppValue();

  if (!viewerFundraiser?.isCaptainable || !handlesMoney) return null;

  if (!hasBeneficiary) {
    return (
      <Flex justifyContent="center" mb={3} mt={2} width={1}>
        {newBeneficiary ? (
          <Button as="a" href={`${payoutsUrl()}?campaignId=${campaign.id}`}>
            <Text color="white" fontSize={[2, 3, 3]}>
              Payout
            </Text>
          </Button>
        ) : (
          <Button as="a" href={`${payoutsUrl()}?campaignId=${campaign.id}`}>
            <Text color="white" fontSize={[2, 3, 3]}>
              Payout
            </Text>
          </Button>
        )}
      </Flex>
    );
  }

  return (
    <>
      <Flex justifyContent="center" mb={3} mt={2} width={1}>
        <Button
          as="a"
          href={isWithdrawalReady ? `${payoutsUrl()}?campaignId=${campaign.id}` : "#"}
          px={[2, 3, 4]}
          py={2}
          variant={isWithdrawalReady ? "primary" : "disabled"}
        >
          <Text color="white" fontSize={[2, 3, 3]}>
            Payout
          </Text>
        </Button>
      </Flex>
    </>
  );
}
