import React from "react";
import { shape } from "prop-types";

const SwtichTeamReason = ({ personalAccount }) => {
  const { continuityPrevCampaign, continuityReason } = personalAccount;
  switch (continuityReason) {
    case "NEW_USER":
      return (
        <div className="switch-team__get-started centered">
          <h3 className="mb-2 mt-2">You need a team to start earning funds</h3>
          <p className="mb-0">
            Create a new team or join an existing one (you&apos;ll need an invite code) to get
            started.
          </p>
        </div>
      );
    case "CLOSED":
      return (
        <div className="switch-team__get-started centered">
          <h3 className="mt-2">Select a new team to continue</h3>
          <p className="mt-3 mb-0">
            <strong>{continuityPrevCampaign.name}</strong> is now closed. Create/join/switch teams
            to continue earning. You can also earn on your own by selecting your Personal Account
            below.
          </p>
        </div>
      );
    case "LEFT":
      return (
        <div className="switch-team__get-started centered">
          <h3 className="mt-2">Select a new team to continue</h3>
          <p className="mt-3 mb-0">
            You&apos;ve left <strong>{continuityPrevCampaign.name}</strong>. Create/join/switch
            teams to continue earning. You can also earn on your own by selecting your Personal
            Account below.
          </p>
        </div>
      );
    case "REMOVED":
      return (
        <div className="switch-team__get-started centered">
          <h3 className="mt-2">Select a new team to continue</h3>
          <p className="mt-3 mb-0">
            You&apos;ve been removed from <strong>{continuityPrevCampaign.name}</strong>.
            Create/join/switch teams to continue earning. You can also earn on your own by selecting
            your Personal Account below.
          </p>
        </div>
      );
    default:
      return null;
  }
};

export default SwtichTeamReason;

SwtichTeamReason.propTypes = {
  personalAccount: shape({}).isRequired,
};
