import React from "react";
import { Box, Text, Flex, ClearButton } from "@atoms";
import { LocationFragment } from "../../inStoreComponents/InStoreModal/__generated__/LocationFragment";
import { BrandModalHeroFragment } from "../BrandModalHero/__generated__/BrandModalHeroFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  location: LocationFragment;
  brand: BrandModalHeroFragment;
}

// == Constants ============================================================

// == Component ============================================================

const BrandModalLocationAddress = ({ location, brand }: IProps) => {
  const address = `${brand.name} at ${location.street1}, ${location.city}, ${location.province}, ${location.postalCode}`;
  const linkToDirections = `https://www.google.com/maps/search/?api=1&query=${address}`;
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Box>
          <Text display="block">{location.street1}</Text>
          <Text display="block">{location.street2}</Text>
          <Text display="block">{`${location.city}, ${location.province} ${location.postalCode}`}</Text>
        </Box>
        <Box>
          <ClearButton as="a" href={linkToDirections} target="_blank">
            Directions
          </ClearButton>
        </Box>
      </Flex>
    </>
  );
};

export default BrandModalLocationAddress;

// == Styles ===============================================================
