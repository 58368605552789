import React from "react";
import { format, parse } from "date-fns";
import { Box, Text, Image } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  previewObject: {
    giftForm: {
      name: string;
      email: string;
      message: string;
      deliverOn: string;
      giftcardTemplateId: string;
    };
  };
  selectedGiftcardTemplate?: {
    id: string;
    previewImage: string;
  };
  shippingOption?: { id: string; name: string };
}

// == Constants ============================================================

CheckoutFormPreview.defaultProps = {
  selectedGiftcardTemplate: null,
  shippingOption: null,
};

// == Component ============================================================

export default function CheckoutFormPreview({
  previewObject,
  selectedGiftcardTemplate,
  shippingOption,
}: IProps) {
  const { giftForm, recipientForm } = previewObject;
  if (giftForm) {
    return (
      <Box>
        <Box mb={2}>
          <Text as="p" fontWeight="bold">
            Gift Recipient Name
          </Text>
          <Text as="p">{giftForm.name}</Text>
        </Box>
        {giftForm.email && (
          <Box mb={2}>
            <Text as="p" fontWeight="bold">
              Gift Recipient Email
            </Text>
            <Text as="p">{giftForm.email}</Text>
          </Box>
        )}
        {giftForm.message && (
          <Box mb={2}>
            <Text as="p" fontWeight="bold">
              Message
            </Text>
            <Text as="p">{giftForm.message}</Text>
          </Box>
        )}
        {giftForm.deliverOn && (
          <Box mb={2}>
            <Text as="p" fontWeight="bold">
              Deliver On
            </Text>
            <Text as="p">{giftForm.deliverOn}</Text>
          </Box>
        )}
        {!!selectedGiftcardTemplate && (
          <Box mb={2}>
            <Text as="p" fontWeight="bold">
              Style
            </Text>
            <Image maxWidth="200px" src={selectedGiftcardTemplate.previewImage} />
          </Box>
        )}
      </Box>
    );
  }
  if (recipientForm) {
    return (
      <Box>
        <Box mb={2}>
          <Text as="p" fontWeight="bold">
            Shipping Address
          </Text>
          <Text as="p">
            {recipientForm.street1}
            {!!recipientForm.street2 && `, ${recipientForm.street2}`}
          </Text>
          <Text as="p">
            {recipientForm.city}, {recipientForm.province}
          </Text>
          <Text as="p">{recipientForm.postalCode}</Text>
          <Text as="p">{recipientForm.country}</Text>
        </Box>
        {shippingOption && (
          <Box mb={2}>
            <Text as="p" fontWeight="bold">
              Shipping Option
            </Text>
            <Text as="p">{shippingOption.name}</Text>
          </Box>
        )}
      </Box>
    );
  }
  return null;
}

// == Styles ===============================================================
