import React from "react";
import { Button } from "../..";
import { useBeneficiaryDispatch } from "../../../context";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export const BeneficiaryIntro = (props: IProps) => {
  const stateDispatch = useBeneficiaryDispatch();
  const onClick = () => {
    stateDispatch({ type: "startBeneficiaryForm" });
  };

  return (
    <div className="">
      <h2 className="mb-2 centered">Submit Payment Information</h2>
      <p className="mb-4">
        In order to withdraw your team earnings you must first submit your team's payment info for
        approval.
      </p>
      <h3 className="mb-2">
        <strong>Beneficiary</strong>
      </h3>
      <p>
        Tell us what type of team and who the recipient of the funds will be. Registered
        charity/non-profits and club league & school teams are eligible for additional exclusive
        offers.
      </p>

      <h3 className="mb-2">
        <strong>Delivery</strong>
      </h3>
      <p>Tell us how and where you would like your payment sent.</p>
      <p>
        We will review your submission and notify you by email within 7-10 business days. In the
        meantime, continue to earn for your team and once approved you can request outstanding team
        funds as long as it's over $100.{" "}
      </p>
      <Button additionalClasses="mb-3" name="submit" onClick={onClick}>
        Get Started
      </Button>
    </div>
  );
};

BeneficiaryIntro.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
