import React from "react";
// import styled from "@emotion/styled";
import { Box, Text } from "@atoms";

// == Types ================================================================

interface IProps {
  plaidAccountInfo?: any;
  hwAccountInfo?: any;
}

// == Constants ============================================================

BeneficiaryAccountInfo.defaultProps = {};

// == Component ============================================================

export default function BeneficiaryAccountInfo({ plaidAccountInfo, hwAccountInfo }: IProps) {
  const renderAccountInfo = (plaidAccountInfo, hwAccountInfo) => {
    if (plaidAccountInfo) {
      return (
        <>
          {plaidAccountInfo?.owner && (
            <Box>
              <Text fontWeight="bold">Owner: </Text>
              <Text>{plaidAccountInfo.owner[0]}</Text>
            </Box>
          )}
          {plaidAccountInfo?.bankAccountPurpose && (
            <Box>
              <Text fontWeight="bold">Account name: </Text>
              <Text>{plaidAccountInfo.bankAccountPurpose}</Text>
            </Box>
          )}
          {plaidAccountInfo?.bankAccountId && (
            <Box mb={3}>
              <Text fontWeight="bold">Account ending in: </Text>
              <Text>{plaidAccountInfo.bankAccountId.slice(-4)}</Text>
            </Box>
          )}
        </>
      );
    }
    if (hwAccountInfo) {
      return (
        <>
          {hwAccountInfo?.profileType === "INDIVIDUAL" && (
            <Box>
              <Text fontWeight="bold">Owner: </Text>
              <Text>
                {hwAccountInfo.firstName} {hwAccountInfo.lastName}
              </Text>
            </Box>
          )}
          {hwAccountInfo?.profileType === "BUSINESS" && (
            <Box>
              <Text fontWeight="bold">Owner: </Text>
              <Text>{hwAccountInfo.businessName}</Text>
            </Box>
          )}
          {hwAccountInfo?.bankName && (
            <Box>
              <Text fontWeight="bold">Bank name: </Text>
              <Text>{hwAccountInfo.bankName}</Text>
            </Box>
          )}
          {hwAccountInfo?.bankAccountPurpose && (
            <Box>
              <Text fontWeight="bold">Account name: </Text>
              <Text>{hwAccountInfo.bankAccountPurpose}</Text>
            </Box>
          )}
          {hwAccountInfo?.bankAccountId && (
            <Box mb={3}>
              <Text fontWeight="bold">Account ending in: </Text>
              <Text>{hwAccountInfo.bankAccountId.slice(-4)}</Text>
            </Box>
          )}
        </>
      );
    }
    return;
  };
  return <Box mb={2}>{renderAccountInfo(plaidAccountInfo, hwAccountInfo)}</Box>;
}

// == Styles ===============================================================
