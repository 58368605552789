import React from "react";
import isEmpty from "lodash/isEmpty";
import { Text } from "@components";
import { ActiveStatusEnum, ContinuityReasonEnum } from "@types";
import SwitchTeamCampaignList from "./components/SwitchTeamCampaignList";
import { SwitchTeamIntro } from "./components/SwitchTeamIntro";
import SwitchTeamHeader from "./components/SwitchTeamHeader";
import SwitchTeamPersonalBox from "./components/SwitchTeamPersonalBox";
import {
  ViewerCampaignsQuery_Viewer_runningCampaigns,
  ViewerCampaignsQuery_Viewer_activeCampaign,
  ViewerCampaignsQuery_Viewer_endedCampaigns,
  ViewerCampaignsQuery_Viewer_personalAccount,
} from "./__generated__/ViewerCampaignsQuery";

// == Types ================================================================

interface IProps {
  runningCampaigns: ViewerCampaignsQuery_Viewer_runningCampaigns[];
  activeCampaign: ViewerCampaignsQuery_Viewer_activeCampaign;
  endedCampaigns: ViewerCampaignsQuery_Viewer_endedCampaigns[];
  firstName: string;
  lastName: string;
  photoImage: string;
  personalAccount: ViewerCampaignsQuery_Viewer_personalAccount;
  activeStatus: string;
  switchTeamUrl: string;
  personalUserUrl: string;
  editCampaignUrl: string;
  userFundsUrl: string;
  joinCampaignUrl: string;
  newCampaignUrl: string;
  campaignMembersUrl: string;
  rootUrl: string;
  environment: string;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  activeCampaign: null,
  photoImage: null,
};

// == Component ============================================================

export const SwitchTeam = ({
  activeStatus,
  activeCampaign,
  personalAccount,
  runningCampaigns,
  endedCampaigns,
  firstName,
  lastName,
  photoImage,
  switchTeamUrl,
  personalUserUrl,
  editCampaignUrl,
  userFundsUrl,
  newCampaignUrl,
  campaignMembersUrl,
  joinCampaignUrl,
  rootUrl,
  environment,
}: IProps) => {
  // old query
  // {runningCampaigns.length === 0 &&
  //     endedCampaigns.length === 0 &&
  //     (activeStatus !== ActiveStatusEnum.PERSONAL) ? (

  if (environment !== "development") return <SwitchTeamIntro personalUserUrl={personalUserUrl} />;
  return (
    <>
      {runningCampaigns.length === 0 &&
      endedCampaigns.length === 0 &&
      activeStatus !== ActiveStatusEnum.PERSONAL ? (
        <SwitchTeamIntro personalUserUrl={personalUserUrl} />
      ) : (
        <div className="row justify-content-center">
          <div className="switch-team__container col-md-8">
            <SwitchTeamHeader
              showCheckmark
              activeCampaign={activeCampaign}
              activeStatus={activeStatus}
              campaign={activeCampaign}
              campaignMembersUrl={campaignMembersUrl}
              editCampaignUrl={editCampaignUrl}
              firstName={firstName}
              lastName={lastName}
              personalAccount={personalAccount}
              photoImage={photoImage}
              rootUrl={rootUrl}
              title="Header"
              userFundsUrl={userFundsUrl}
            />
            <a href={joinCampaignUrl}>
              <div className="switch-team__row">
                <div className="switch-team__icon color-link">
                  <i className="material-icons">add</i>
                </div>
                <div className="d-flex align-items-center color-text">Join Existing Team</div>
              </div>
            </a>
            <a href={newCampaignUrl}>
              <div className="switch-team__row">
                <div className="switch-team__icon color-link">
                  <i className="material-icons">add</i>
                </div>
                <div className="d-flex align-items-center color-text">Create New Team</div>
              </div>
            </a>
            {personalAccount.continuityReason === ContinuityReasonEnum.NEW_USER ? null : (
              <>
                {isEmpty(runningCampaigns) ? null : (
                  <SwitchTeamCampaignList
                    activeCampaign={activeCampaign}
                    campaigns={runningCampaigns}
                    switchTeamUrl={switchTeamUrl}
                    title="Running Teams"
                  />
                )}
                <SwitchTeamPersonalBox
                  firstName={firstName}
                  lastName={lastName}
                  personalAccount={personalAccount}
                  personalUserUrl={personalUserUrl}
                  photoImage={photoImage}
                  showCheckmark={activeStatus === ActiveStatusEnum.PERSONAL}
                  title="Personal Account"
                />
                {endedCampaigns.length > 0 ? (
                  <SwitchTeamCampaignList
                    isClosed
                    activeCampaign={activeCampaign}
                    campaigns={endedCampaigns}
                    switchTeamUrl={switchTeamUrl}
                    title="Closed Teams"
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

// == Styles ===============================================================

SwitchTeam.defaultProps = DEFAULT_PROPS;
