import React from "react";
import gql from "graphql-tag";
import chunk from "lodash/chunk";
import { useQuery } from "react-apollo";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/core";
import { BrandLogo } from "@organisms";
import { getPaginatedNodes } from "@utils";
import { Box, Flex, Text, Row } from "@atoms";
import { Currency } from "@types";

// == Types ================================================================

interface IProps {
  currency?: Currency;
}

// == Constants ============================================================

BrandShowcase.defaultProps = {};

const BRAND_SHOWCASE_QUERY = gql`
  query BrandShowcaseQuery($id: ID!) {
    StoreFront(id: $id) {
      id
      name
      collections {
        id
        brands(first: 16) {
          edges {
            cursor
            node {
              id
              ...BrandLogoFragment
            }
          }
        }
      }
    }
  }
  ${BrandLogo.fragments.fields}
`;

// == Component ============================================================

export default function BrandShowcase({ currency }: IProps) {
  const { data, loading, error } = useQuery(BRAND_SHOWCASE_QUERY, {
    variables: {
      id: "onboarding",
    },
    fetchPolicy: "no-cache",
    context: { currency },
  });

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error! {error.message}</Text>;

  const { collections } = data.StoreFront;
  const collection = getPaginatedNodes(collections[0].brands.edges);
  const chunkedCollections = chunk(collection, 8);
  return (
    <>
      <EContainer>
        {chunkedCollections.map((brands, index) => {
          return (
            <EFlex
              alignItems="center"
              // eslint-disable-next-line react/no-array-index-key
              css={
                index === 0
                  ? css`
                      animation: ${slideShowcaseLeft} 35s ease-in-out infinite;
                      animation-delay: 1s;
                    `
                  : css`
                      animation: ${slideShowcaseRight} 35s ease-in-out infinite;
                      animation-delay: 1s;
                    `
              }
              flexDirection="row"
              flexWrap="nowrap"
              justifyContent="center"
              key={`collection-${index}`}
              py={2}
            >
              {brands.map((brand) => {
                return (
                  <Box
                    className={
                      index === 0
                        ? "brand-showcase-container--child_even"
                        : "brand-showcase-container--child_odd"
                    }
                    key={brand.id}
                    width={["150px", "150px", "200px"]}
                  >
                    <EBrandLogo brand={brand} mx={3} />
                  </Box>
                );
              })}
              {brands.map((brand) => {
                return (
                  <Box
                    className={
                      index === 0
                        ? "brand-showcase-container--child_even"
                        : "brand-showcase-container--child_odd"
                    }
                    key={`dupe-${brand.id}`}
                    width={["150px", "150px", "200px"]}
                  >
                    <EBrandLogo brand={brand} mx={3} />
                  </Box>
                );
              })}
            </EFlex>
          );
        })}
      </EContainer>
    </>
  );
}

// == Styles ===============================================================
const EFlex = styled(Flex)`
  .brand-showcase-container--child_even:nth-of-type(even) {
    width: 100px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 150px;
    }
  }
  .brand-showcase-container--child_odd:nth-of-type(odd) {
    width: 100px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 150px;
    }
  }
`;

const EBrandLogo = styled(BrandLogo)`
  border: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const EContainer = styled(Box)`
  overflow: hidden !important;
  margin: 0 -32px;
`;

const slideShowcaseLeft = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
`;

const slideShowcaseRight = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-30%, 0, 0);
  }
`;
