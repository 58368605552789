import React, { useEffect } from "react";
import { Location, LocationContext, navigate } from "@reach/router";
import { useCookies } from "react-cookie";
import { useMutation, useQuery } from "react-apollo";
import { H2, Text, Button, Box, CircleLoader, Link } from "@atoms";
import { CenteredBoxLayout } from "@molecules";
import { RailsUrl, constants, ErrorService } from "@services";
import { useStoreAppValue, useToggle } from "@hooks";
import { PaymentType } from "@types";
import { BENEFICIARY_UPDATE_MUTATION } from "../BeneficiaryTransfer";
import { BENEFICIARY_CREATE_MUTATION } from "../BeneficiaryForm";
import {
  BeneficiaryCreateMutation,
  BeneficiaryCreateMutationVariables,
} from "../BeneficiaryForm/__generated__/BeneficiaryCreateMutation";
import {
  BeneficiaryUpdateMutation,
  BeneficiaryUpdateMutationVariables,
} from "../BeneficiaryTransfer/__generated__/BeneficiaryUpdateMutation";
import { CAMPAIGN_BENEFICIARY_QUERY } from "../BeneficiaryForm/BeneficiaryFormQuery";
import {
  CampaignBeneficiaryQuery,
  CampaignBeneficiaryQueryVariables,
} from "../BeneficiaryForm/__generated__/CampaignBeneficiaryQuery";
import { BeneficiaryLoading } from "../BeneficiaryLoading";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {}

// == Constants ============================================================

BeneficiarySuccess.defaultProps = {};

// == Component ============================================================

export default function BeneficiarySuccess(props: IProps) {
  const { campaignId } = useStoreAppValue();
  const { data, loading } = useQuery<CampaignBeneficiaryQuery, CampaignBeneficiaryQueryVariables>(
    CAMPAIGN_BENEFICIARY_QUERY,
    {
      variables: {
        id: campaignId,
      },
      fetchPolicy: "no-cache",
    }
  );

  if (!data || loading) return <BeneficiaryLoading />;

  return (
    <Location>
      {({ location }: LocationContext) => {
        if (!location?.state?.variables) {
          if (data?.Campaign?.beneficiary) {
            window.location.href = RailsUrl.paymentsUrl(campaignId);
            return (
              <CenteredBoxLayout p={[4, 4, 5]}>
                <Box textAlign="center">
                  <CircleLoader delay={5000} />
                </Box>
                <H2 mb={3} textAlign="center">
                  Payment Information Already Exists
                </H2>
                <Text>
                  You&apos;ve already submitted your payment information. You can resubmit your
                  information on the Withdrawals page.
                </Text>
              </CenteredBoxLayout>
            );
          }
          window.location.href = RailsUrl.beneficiaryIntroUrl(campaignId);
          return (
            <CenteredBoxLayout p={[4, 4, 5]}>
              <Box textAlign="center">
                <CircleLoader delay={5000} />
              </Box>
              <H2 mb={3} textAlign="center">
                Payment Information Doesn&apos;t Exists
              </H2>
              <Text>Start payment info flow again</Text>
            </CenteredBoxLayout>
          );
        }

        return <BeneficiarySubmit location={location} navigate={navigate} />;
      }}
    </Location>
  );
}

function BeneficiarySubmit({ location }: LocationContext) {
  const { campaignId } = useStoreAppValue();
  const [globalError, toggleGlobalError] = useToggle(false);
  const onClick = () => {
    window.location.href = RailsUrl.rootUrl;
  };
  const [completed, setCompleted] = useToggle(false);
  const { input, organization } = location.state.variables;

  const [cookies, _setCookie, removeCookie] = useCookies(["beneficiaryType", "beneficiaryOrg"]);

  const [createBeneficiaryMutation, { loading }] = useMutation<
    BeneficiaryCreateMutation,
    BeneficiaryCreateMutationVariables
  >(BENEFICIARY_CREATE_MUTATION);

  const [updateBeneficiaryMutation, { loading: updateBeneficiaryLoading }] = useMutation<
    BeneficiaryUpdateMutation,
    BeneficiaryUpdateMutationVariables
  >(BENEFICIARY_UPDATE_MUTATION);

  useEffect(() => {
    if (!input?.id) {
      createBeneficiaryMutation({
        variables: {
          input: { ...input, campaignId },
          organization,
        },
      })
        .then((response) => {
          const { data } = response;
          const { BeneficiaryCreate } = data;
          const { errors } = BeneficiaryCreate;
          if (errors && errors.length > 0) {
            const errorMessages = [];
            errors.map((error: $FixMe) => {
              error.inputErrors.map((inputError) => {
                errorMessages.push(`${error.input} ${inputError}`);
              });
            });
            toggleGlobalError(true);
            throw new Error(errorMessages.join(","));
          } else if (
            !BeneficiaryCreate.plaidAccountInfo &&
            BeneficiaryCreate.paymentType === PaymentType.HW_BANK_TRANSFER
          ) {
            toggleGlobalError(true);
            setCompleted(true);
          } else {
            removeCookie("beneficiaryOrg");
            removeCookie("beneficiaryType");
            setCompleted(true);
          }
        })
        .catch((e) => {
          ErrorService.error(e);
          toggleGlobalError(true);
        });
    } else {
      updateBeneficiaryMutation({
        variables: {
          input,
          organization,
        },
      })
        .then((response) => {
          const { data } = response;
          const { BeneficiaryUpdate } = data;
          const { errors } = BeneficiaryUpdate;
          if (errors && errors.length > 0) {
            const errorMessages = [];
            errors.map((error: $FixMe) => {
              error.inputErrors.map((inputError) => {
                errorMessages.push(`${error.input} ${inputError}`);
              });
            });
            toggleGlobalError(true);
            throw new Error(errorMessages.join(","));
          } else if (
            !BeneficiaryUpdate.plaidAccountInfo &&
            BeneficiaryUpdate.paymentType === PaymentType.HW_BANK_TRANSFER
          ) {
            toggleGlobalError(true);
            setCompleted(true);
          } else {
            removeCookie("beneficiaryOrg");
            removeCookie("beneficiaryType");
            setCompleted(true);
          }
        })
        .catch((e) => {
          ErrorService.error(e);
          toggleGlobalError(true);
        });
    }
  }, [
    campaignId,
    createBeneficiaryMutation,
    input,
    organization,
    removeCookie,
    setCompleted,
    toggleGlobalError,
    updateBeneficiaryMutation,
  ]);

  return (
    <CenteredBoxLayout p={[4, 4, 5]}>
      <Box textAlign="center">
        {!globalError && <CircleLoader delay={60000} loading={!completed} />}
      </Box>
      <Box mb={2}>
        {!globalError ? (
          <>
            {loading || updateBeneficiaryLoading ? (
              <Text as="p" mb={3} textAlign="center">
                Hang tight, we&apos;re connecting your account information. This might take up to 5
                minutes. Please don&apos;t close your browser in the meantime.
              </Text>
            ) : (
              <>
                <H2 mb={3} textAlign="center">
                  Success!
                </H2>
                <Text>
                  Your payment info has been received and will be reviewed within{" "}
                  {constants.BENEFICIARY_REVIEW_DAYS} business days to ensure it&apos;s in
                  accordance with our{" "}
                </Text>
                <Link href={RailsUrl.beneficiaryGuidelinesUrl} target="_blank">
                  payment approval guidelines.
                </Link>
                <Text as="p" my={3}>
                  In the meantime, continue earning for your team. We will notify you by email once
                  approved and you can then request funds anytime.
                </Text>
                <Box textAlign="center">
                  <Button width="80%" onClick={onClick}>
                    Go Back To My Team
                  </Button>
                </Box>
              </>
            )}
          </>
        ) : (
          <Box mb={3} textAlign="center">
            <Text>
              There seems to be an issue with your bank account. Please
              <Link href={RailsUrl.beneficiaryIntroUrl(campaignId)}> click here </Link>
              and try again. If you&apos;re still having issues with submitting your payment
              information, contact{" "}
            </Text>
            <Link href="mailto:support@flipgive.com" target="_blank">
              support@flipgive.com
            </Link>
            <Text> for assistance.</Text>
          </Box>
        )}
      </Box>
    </CenteredBoxLayout>
  );
}

// == Styles ===============================================================
