import React from "react";
import gql from "graphql-tag";
import parseISO from "date-fns/fp/parseISO";
import format from "date-fns/format";
import { Box, Flex, Text, ChevronRight, TouchableOpacity } from "@atoms";
import { useToggle, useStoreAppValue } from "@hooks";
import { FundsPagePaymentQuery } from "../FundsPagePayment";
import { FundsPageWithdrawalsListItemFragment } from "./__generated__/FundsPageWithdrawalsListItemFragment";
import { RailsUrl } from "@services";

// == Types ================================================================

interface IProps {
  listItem: FundsPageWithdrawalsListItemFragment;
  isViewerOwner: boolean;
}

// == Constants ============================================================

FundsPageWithdrawalsListItem.fragments = gql`
  fragment FundsPageWithdrawalsListItemFragment on Payment {
    id
    paymentType
    processedAt
    amount {
      formatted
    }
  }
`;

// == Component ============================================================

export default function FundsPageWithdrawalsListItem({ listItem, isViewerOwner }: IProps) {
  const { id, processedAt, amount } = listItem;
  const [isModalOpen, setModalOpen] = useToggle(false);
  const { siteName, campaignId } = useStoreAppValue();

  const isLynchCreekFarms = siteName === "Lynch Creek Farm";
  const isIndigo = siteName === "Indigo";

  function onTouch() {
    if (isLynchCreekFarms || isIndigo) {
      window.location.href = `${RailsUrl.paymentsUrl(campaignId)}/${id}`;
    } else {
      setModalOpen(true);
    }
  }

  function TouchableListItem() {
    return (
      <TouchableOpacity onTouch={onTouch}>
        <Flex alignItems="center" justifyContent="space-between" padding={3} width="100%">
          <Flex alignItems="center">
            <Box>
              {processedAt ? (
                <Text fontSize={[1, 1, 2]}>{format(parseISO(processedAt), "MMM d, yyyy")}</Text>
              ) : (
                <Text paddingRight={[1, 2]}>Processing...</Text>
              )}
            </Box>
          </Flex>

          <Flex alignItems="center">
            <Text fontSize={[1, 1, 2]} paddingRight={3}>
              {amount.formatted}
            </Text>
            <ChevronRight color="#666" size="xs" />
          </Flex>
        </Flex>
      </TouchableOpacity>
    );
  }

  function ListItem() {
    return (
      <Flex alignItems="center" justifyContent="space-between" padding={3} width="100%">
        <Flex alignItems="center">
          <Box>
            {processedAt ? (
              <Text fontSize={[1, 1, 2]}>{format(parseISO(processedAt), "MMM d, yyyy")}</Text>
            ) : (
              <Text paddingRight={[1, 2]}>Processing...</Text>
            )}
          </Box>
        </Flex>

        <Flex alignItems="center">
          <Text fontSize={[1, 1, 2]} paddingRight={3}>
            {amount.formatted}
          </Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      {isViewerOwner ? <TouchableListItem /> : <ListItem />}
      {isModalOpen && (
        <FundsPagePaymentQuery
          isModalOpen={isModalOpen}
          paymentId={id}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
}

// == Styles ===============================================================
