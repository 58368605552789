import { Box, Flex, H3, Text } from "@atoms";
import { useFeatureFlag } from "@hooks/useFeatureFlag";
import { CheckboxComponent, ToggleButton } from "@molecules";
import currency from "currency.js";
import { useEffect } from "react";
import { GiftcardRefillButton } from "./GiftcardRefillButton";
import { GiftcardReloadPaymentFields } from "./GiftcardReloadPaymentFields";
import { IGiftcardReloadState, TGiftcardReloadDispatch } from "./types";
import { ViewerCampaignDropdown } from "./ViewerCampaignDropdown";
import { GiftcardReloadSettingsQuery_Viewer } from "./__generated__/GiftcardReloadSettingsQuery";

// == Types ================================================================
interface IProps {
  state: IGiftcardReloadState;
  dispatch: TGiftcardReloadDispatch;
  viewer?: GiftcardReloadSettingsQuery_Viewer;
}
// == Constants ============================================================

// == Component ============================================================
// this component handles 2 states, 1 for auto reloadable (esso), and also for non auto-reloadable (givex)

export function GiftcardReloadRefill({ state, dispatch, viewer }: IProps) {
  const { isEnabled } = useFeatureFlag("reload");
  useEffect(() => {
    if (state.manualReloadDollarsAmount === 0) {
      const dollarsAmount = state.giftcard.reloadInfo?.manualReloadDenominations[0] || 50;

      dispatch({
        type: "SET_MANUAL_RELOAD_AMOUNT",
        dollarsAmount,
      });
    }
  }, [dispatch, state.giftcard, state.giftcard.reloadInfo, state.manualReloadDollarsAmount]);

  return (
    <Box>
      {!state.giftcard.reloadInfo?.isAutoReloadable && (
        <CheckboxComponent
          checked={state.isNotificationAllowed}
          label="Remind me when balance drops below $10"
          name="topup-permission"
          onChange={() => dispatch({ type: "TOGGLE_NOTIFICATION" })}
        />
      )}
      <Box mb={2}>
        <H3 mb={2}>Reload Card</H3>
        <Box>
          {state.giftcard.reloadInfo?.manualReloadDenominations.map((reload: number) => {
            return (
              <ToggleButton
                isActive={reload === state.manualReloadDollarsAmount}
                key={String(reload)}
                mb={2}
                mr={2}
                text={currency(reload).format()}
                onClick={() =>
                  dispatch({
                    type: "SET_MANUAL_RELOAD_AMOUNT",
                    dollarsAmount: reload,
                  })
                }
              />
            );
          })}
        </Box>
      </Box>

      <Flex mb={1} width="100%">
        <Text>You will be charged</Text>
        <Box flex="1" textAlign="right">
          <Text>{currency(state.manualReloadDollarsAmount).format()}</Text>
        </Box>
      </Flex>

      <Flex mb={1} width="100%">
        <Text>Updated balance will be</Text>
        <Box flex="1" textAlign="right">
          <Text>
            {currency(state.giftcard?.balance?.formatted)
              .add(state.manualReloadDollarsAmount)
              .format()}
          </Text>
        </Box>
      </Flex>
      <Flex alignItems="flex-start" width="100%">
        <H3 color="primary">You will earn:</H3>
        <Box flex="1" textAlign="right">
          <Text color="primary" fontSize={3}>
            {currency(state.manualReloadDollarsAmount)
              .multiply(state.giftcard.link.givePercentage / 100)
              .format()}
          </Text>
        </Box>
      </Flex>

      <Box mb={3} mt={3}>
        <Text mb={2} mt={4}>
          Cashback Will Be Sent To
        </Text>
        {!!viewer && (
          <ViewerCampaignDropdown
            currency={state.giftcard.currency}
            selectedCampaignId={state.selectedCampaignId}
            viewer={viewer}
            onCampaignSelect={(id: string) => dispatch({ type: "SET_CAMPAIGN_ID", campaignId: id })}
            extraCampaign={state.giftcard?.campaign}
          />
        )}
      </Box>

      {!isEnabled && (
        <Box mb={3} mt={3}>
          <Text mb={2} mt={4}>
            Payment Method
          </Text>
          <GiftcardReloadPaymentFields
            cardId={state.stripeCardId || state.stripeTokenId}
            dispatch={dispatch}
          />
        </Box>
      )}

      <GiftcardRefillButton dispatch={dispatch} state={state} />
      <Text display="block" fontSize={0} mt={1} textAlign="center">
        Your payment method will be charged the amount indicated above. You will earn cashback for
        each reload based on the most up-to-date cashback rate. Card balance are updated on a
        nightly basis.
      </Text>
    </Box>
  );
}
