import React from "react";
import { Box, Button, Card, Flex, H2, Link, Text } from "@components/common/atoms";
import { CheckboxField, TextField } from "@components/common/molecules";
import styled from "@emotion/styled";
import { useFeatureFlag } from "@hooks/useFeatureFlag";
import { navigate } from "@reach/router";
import { BASE_MAIN_URL, BASE_SHOP_URL, RailsUrl } from "@services";
import { Form, Formik } from "formik";
import { useMutation } from "react-apollo";
import * as Yup from "yup";
import { BUY_UPDATE_MUTATION } from "../../checkoutComponents/CheckoutView/queries";
// == Types ================================================================

interface IProps {
  giftcardIntent: $FixMe;
  toggleAnonForm: () => void;
  toggleError: () => void;
  setErrorMessage?: (message: string) => void;
}

// == Constants ============================================================

IntentLoadingAnonymous.defaultProps = {};
IntentLoadingAnonymous.validation = Yup.object({
  name: Yup.string().required("Please provide a name."),
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide an email address."),
});

// == Component ============================================================

export default function IntentLoadingAnonymous({
  giftcardIntent,
  toggleAnonForm,
  toggleError,
  setErrorMessage,
}: IProps) {
  const isCheckoutReleased = useFeatureFlag("checkout");
  const [buyUpdateMutation] = useMutation(BUY_UPDATE_MUTATION);
  const { link, campaign } = giftcardIntent;
  const encodedIntentURI = encodeURI(RailsUrl.intentUrl({ intentToken: giftcardIntent.token }));
  const onSubmit = (values, { setSubmitting }) => {
    const { id } = giftcardIntent;
    const { name: buyerName, email: buyerEmail, isAnonymous: isAnonymous } = values;
    buyUpdateMutation({
      variables: {
        id,
        input: {
          buyerName,
          buyerEmail,
          isAnonymous,
        },
      },
    })
      .then(({ data }) => {
        const { BuyUpdate } = data;
        setSubmitting(false);
        if (BuyUpdate.errors) {
          const redirectUrl = `${BASE_MAIN_URL}${RailsUrl.campaignsUrl(campaign.id)}`;
          // if errors, then the email is already in use
          const hasBuyerInputError: boolean = BuyUpdate.errors.some(
            (error) => error.input === "buyer"
          );
          if (hasBuyerInputError) {
            setErrorMessage?.(
              `This email has an account on FlipGive. <a href="${RailsUrl.signInUrl(
                redirectUrl
              )}">Please login to continue.</a>`
            );
          }
          toggleError();
        } else {
          toggleAnonForm();
          setTimeout(() => {
            const url = isCheckoutReleased
              ? RailsUrl.newCheckoutUrl(giftcardIntent.token)
              : RailsUrl.checkoutUrl(giftcardIntent.token);
            navigate(url, { replace: true });
          }, 3000);
        }
      })
      .catch(() => {
        toggleError();
        setSubmitting(false);
      });
  };

  return (
    <>
      <Flex
        bg="white"
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        justifyContent="center"
      >
        <ENav className="navbar navbar--secondary" role="navigation">
          <div className="nav-wrapper">
            <EDiv className="container centered">
              <EBrandLogo className="brand-logo">
                <img
                  alt="FlipGive"
                  src="https://flipgive.twic.pics/images/sites/site_logo_campaigns/000/000/031/original/flipgive_logo_black.png?twic=v1/resize=125"
                />
              </EBrandLogo>
            </EDiv>
          </div>
        </ENav>
        <Flex maxWidth="500px" alignItems="center" px={[3, 0]}>
          <Card bg="white" boxShadow="regularShadow">
            <Box textAlign="center" px={[0, 3]}>
              <H2 color="default" mb={3} mt={[3, 0]}>
                Before you buy a {link?.defaultBrand?.name} gift card...
              </H2>
              <Text as="p" mb={3}>
                We require your name and email to deliver a purchase confirmation.
              </Text>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  isAnonymous: false,
                }}
                validationSchema={IntentLoadingAnonymous.validation}
                onSubmit={onSubmit}
              >
                <Form>
                  <TextField label="Name" name="name" placeholder="" type="text" />
                  <TextField label="Email" name="email" placeholder="" type="text" />
                  <CheckboxField label="Do not display my name on Team's page" name="isAnonymous" />
                  <Button mb={3} onSubmit={onSubmit} fontWeight="bold">
                    Go To Checkout
                  </Button>
                </Form>
              </Formik>

              <Box textAlign="center" lineHeight="solid" mb={3}>
                <Text fontSize={0}>By clicking shop now, you agree to our </Text>
                <Link href={RailsUrl.termsOfUseUrl} fontSize={0}>
                  Terms of Use
                </Link>
                <Text fontSize={0}> and </Text>
                <Link href={RailsUrl.privacyUrl} fontSize={0}>
                  Privacy Policy.
                </Link>
              </Box>
            </Box>
          </Card>
        </Flex>
      </Flex>
    </>
  );
}

// == Styles ===============================================================

const EBrandLogo = styled.span`
  display: inline-block !important;
  margin: 0 !important;
  float: none !important;
`;

const EDiv = styled.div`
  @media only screen and (max-width: ${(props) => props.theme.breakpoints[1]}) {
    box-shadow: ${(props) => props.theme.shadows.regularShadow};
  }
`;

const ENav = styled.nav`
  position: fixed;
`;
