import React, { ReactNode, useMemo } from "react";
import { Link } from "@reach/router";
import styled from "@emotion/styled";
import { constants, RailsUrl } from "@services";
import { useStoreAppValue } from "@hooks";
import { Box, Heading, TextUppercase, Flex } from "@atoms";
import queryString from "query-string";

// == Types ================================================================

interface IProps {
  title?: string;
  collectionId?: string;
  count?: number;
  onTitlePress?: () => void;
  forceCount?: boolean;
  url?: string;
  titleChildren?: ReactNode;
  storefrontId?: string;
}

// == Constants ============================================================

CollectionTitle.defaultProps = {
  title: null,
  count: null,
  forceCount: false,
  url: null,
  titleChildren: null,
};

// == Component ============================================================

// == Component ============================================================

export default function CollectionTitle({
  count,
  title,
  collectionId,
  // onTitlePress,
  url,
  forceCount,
  titleChildren,
  storefrontId,
}: IProps) {
  const { campaignId } = useStoreAppValue();

  const urlWithParams = useMemo(() => {
    if (!url) return url;
    const [path, search] = url.split("?");
    const currentQs = queryString.parse(search);
    if (collectionId) currentQs[constants.collectionParam] = collectionId;
    if (storefrontId) currentQs[constants.storefrontParam] = storefrontId;
    return `${path}?${queryString.stringify(currentQs)}`;
  }, [url]);

  const collectionUrl =
    urlWithParams ?? RailsUrl.collectionUrl({ id: collectionId, campaignId, storefrontId });

  const renderViewAllLink = () => {
    if ((count && count > 1) || forceCount) {
      return (
        <Link to={collectionUrl}>
          <Box>
            <TextUppercase fontSize={[0, 1]}>
              View All {count && count > 1 && `(${count})`}
            </TextUppercase>
          </Box>
        </Link>
      );
    }
    return false;
  };

  return (
    <Flex justifyContent="space-between" mb={2}>
      {!!title && (
        <EContainer>
          <ELink to={collectionUrl}>
            <Heading ellipsis display="inline-block" fontSize={[2, 3]}>
              {title}
            </Heading>
          </ELink>
          {titleChildren}
        </EContainer>
      )}
      {renderViewAllLink()}
    </Flex>
  );
}

// == Styles ===============================================================
const ELink = styled(Link)`
  vertical-align: middle;
`;
const EContainer = styled(Box)`
  width: 70%;
`;
