import React from "react";
import gql from "graphql-tag";
import format from "date-fns/format";
import parseISO from "date-fns/fp/parseISO";
import { Box, Flex, Text, Modal, TouchableOpacity, ChevronLeft } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { formatMoney } from "@services";
import { FundsPagePaymentList } from "../FundsPagePaymentList";
import { FundsPagePaymentCsvButton } from "../FundsPagePaymentCsvButton";
import { FundsPagePaymentFragment } from "./__generated__/FundsPagePaymentFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPagePaymentFragment;
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
}
// == Constants ============================================================

FundsPagePayment.fragments = gql`
  fragment FundsPagePaymentFragment on Campaign {
    id
    isViewerOwner
    ...FundsPagePaymentListFragment
  }
  ${FundsPagePaymentList.fragments}
`;

// == Component ============================================================

export default function FundsPagePayment({ campaign, isModalOpen, setModalOpen }: IProps) {
  const { payment, isViewerOwner } = campaign;

  const {
    theme: {
      colors: { primary },
    },
  } = useStoreAppValue();

  const paymentTotal = payment?.amount?.formatted;

  return (
    <Modal isOpen={isModalOpen} onDismiss={() => setModalOpen(false)}>
      <Flex flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" flexDirection="row" justifyContent="space-between" width={1}>
            <Flex alignItems="baseline" justifyContent="flex-start">
              <Box padding={[2, 2, 3]} paddingLeft={3}>
                <TouchableOpacity onTouch={() => setModalOpen(false)}>
                  <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
                </TouchableOpacity>
              </Box>
              <Box padding={[2, 2, 3]}>
                <Text color="black" fontSize={[2, 2, 3]}>
                  {payment.paymentType === "disbursement"
                    ? `Disbursement #${payment.id}`
                    : payment?.id
                    ? `Withdrawal #${payment.id}`
                    : "Withdrawal"}
                </Text>
              </Box>
            </Flex>
            {isViewerOwner && <FundsPagePaymentCsvButton campaign={campaign} payment={payment} />}
          </Flex>
        </Flex>
        <Flex bg="lightBg" justifyContent="space-between" padding={[2, 2, 3]}>
          <Flex alignItems="baseline">
            <Text fontSize={[2, 2, 3]}>Total</Text>
            {!!payment?.processedAt && (
              <Text fontSize={[0, 0, 1]} paddingLeft={[2, 2, 3]}>
                {format(parseISO(payment?.processedAt), "MMM d, yyyy")}
              </Text>
            )}
          </Flex>
          <Box>
            <Text fontSize={[1, 1, 2]}>{campaign ? paymentTotal : ""}</Text>
          </Box>
        </Flex>
        {!!payment && <FundsPagePaymentList paymentList={payment} />}
      </Flex>
    </Modal>
  );
}

// == Styles ===============================================================
