import { Box, Flex, Text, TextUppercase, TouchableOpacity } from "@atoms";
import styled from "@emotion/styled";
import { ImageCircle } from "@molecules";
import { navigate } from "@reach/router";
import { constants } from "@services";
import { theme } from "@styles";
import gql from "graphql-tag";
import { LinkBlockFeaturedFragment } from "./__generated__/LinkBlockFeaturedFragment";

// == Types ================================================================

// export enum LinkType {
//   BRAND = "BRAND",
//   DEPARTMENT = "DEPARTMENT",
//   EMPYR = "EMPYR",
//   GIFTCARDOFFER = "GIFTCARDOFFER",
//   PRODUCT = "PRODUCT",
// }

interface IProps {
  link: LinkBlockFeaturedFragment;
  collectionId: string;
  storefrontId?: string;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment LinkBlockFeaturedFragment on Link {
      id
      type
      giftcardType
      isBulkShop
      name
      image
      logoImage
      giveSentence
      terms
      promoLabel
      promoDescription
      promoSecondaryLabel
      promoEndsAt
      labelPromotionId
      commissionPromotionId
    }
  `,
};

// == Component ============================================================
const LinkBlockFeatured = ({ link, collectionId, storefrontId }: IProps) => {
  const onClick = () => {
    const { id: linkId, labelPromotionId, commissionPromotionId } = link;
    const searchParams = new URL(document.location.href).searchParams;

    searchParams.set(constants.linkParam, linkId);
    if (labelPromotionId) searchParams.set(constants.labelPromotionParam, labelPromotionId);
    if (commissionPromotionId)
      searchParams.set(constants.commisionPromotionParam, commissionPromotionId);
    if (collectionId) searchParams.set(constants.collectionParam, collectionId);
    if (storefrontId) searchParams.set(constants.storefrontParam, storefrontId);

    navigate(`?${searchParams.toString()}`);
  };

  // TODO: use Image Circle molecule here instead!

  return (
    <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={onClick}>
      <Flex flexDirection="column">
        <Box position="relative">
          {!!link.logoImage && (
            <ImageCircle alt={link.name} border="silver" p={[3, 4]} src={link.logoImage} />
          )}
        </Box>
        <ETextUppercase fontSize={[0]} pt={2} textAlign="center">
          {link.name}
        </ETextUppercase>
        <Text color={theme.colors.black} textAlign="center">
          {link.giveSentence}
        </Text>
        {!!link.promoSecondaryLabel && (
          <EText
            as="div"
            color="primary"
            dangerouslySetInnerHTML={{
              __html: link.promoSecondaryLabel,
            }}
            fontSize={[0]}
            textAlign="center"
          />
        )}
      </Flex>
    </TouchableOpacity>
  );
};

export default LinkBlockFeatured;

LinkBlockFeatured.fragments = FRAGMENTS;

// == Styles ===============================================================

const EText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  * {
    font-size: ${(props) => props.theme.fontSizes[0]}px;
  }
`;

const ETextUppercase = styled(TextUppercase)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
