export function getThresholdDenominations(giftcardValue: number | null, denominations: [number]) {
  if (!giftcardValue) return [25];
  const thresholds: [number?] = [];
  denominations.forEach((denom) => {
    if (denom <= giftcardValue) {
      thresholds.push(denom);
    }
  });
  return thresholds;
}
