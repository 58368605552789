import React from "react";
import { Provider } from "mobx-react";
import SharePercent from "./components/SharePercent";
import SharePercentStore from "./stores/SharePercentStore";
import { ErrorBoundary } from "@utils";

export default class SharePercentApp extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <Provider SharePercentStore={SharePercentStore}>
          <SharePercent {...this.props} />
        </Provider>
      </ErrorBoundary>
    );
  }
}
