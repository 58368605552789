import React from "react";
// import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Box, IBoxProps, Text, H3, Button } from "@atoms";
// import styled from "@emotion/styled";
import { RailsUrl } from "@services";
import { InStoreLinkedCardsAddFragment } from "./__generated__/InStoreLinkedCardsAddFragment";

// == Styles ===============================================================

// == Types ================================================================

interface IProps extends IBoxProps {
  link: InStoreLinkedCardsAddFragment;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment InStoreLinkedCardsAddFragment on Link {
      id
      name
    }
  `,
};
// == Component ============================================================

const InStoreLinkedCardsAdd = ({ link }: IProps) => {
  const href = RailsUrl.linkedCardsNewUrl;
  return (
    <Box bg="lightPrimary" p={3} textAlign="center">
      <H3>Please Link Your Payment Card</H3>
      <Text as="p" mt={2}>
        Earn cash back with {link.name} and other participating merchants when you link your
        eligible card and use it in-store
      </Text>
      <Button as="a" href={href} mt={3} target="_blank">
        Link Card
      </Button>
    </Box>
  );
};

InStoreLinkedCardsAdd.fragments = FRAGMENTS;

export default InStoreLinkedCardsAdd;
