import React, { Fragment } from "react";
import { string, shape, arrayOf, bool } from "prop-types";
import SwitchTeamListItem from "./SwitchTeamListItem";

const SwitchTeamCampaignList = ({ campaigns, activeCampaign, title, switchTeamUrl, isClosed }) => {
  return (
    <>
      <div className="switch-team__header">{title}</div>
      {campaigns.map((campaign) => (
        <SwitchTeamListItem
          activeCampaign={activeCampaign}
          campaign={campaign}
          isClosed={isClosed}
          key={`campaign-list-${campaign.id}`}
          showCheckmark={activeCampaign ? campaign.id === activeCampaign.id : false}
          switchTeamUrl={switchTeamUrl}
          title={title}
        />
      ))}
    </>
  );
};

SwitchTeamCampaignList.propTypes = {
  campaigns: arrayOf(shape({})).isRequired,
  title: string.isRequired,
  switchTeamUrl: string.isRequired,
  activeCampaign: shape({}),
  isClosed: bool,
};

SwitchTeamCampaignList.defaultProps = {
  activeCampaign: null,
  isClosed: false,
};

export default SwitchTeamCampaignList;
