import React, { useEffect } from "react";
import { Formik, Form, FormikConfig } from "formik";
import { string, object } from "yup";
import { BeneficiaryTitle, BeneficiaryCheque } from "..";
import { useBeneficiaryContext } from "../../../context";
import { Select, TextInput, Button } from "../..";
// import styled from "@emotion/styled";

// == Types ================================================================

export type TTeamType = "" | "INDIVIDUAL" | "CHARITY" | "ORGANIZATION";
export type TOrganizationType = "" | "CLUBS" | "LEAGUE" | "SCHOOL";

interface IProps {
  country: string;
}

// == Constants ============================================================

const VALIDATION_SCHEMA = object().shape({
  teamType: string().required("Team Type is required"),
  legalName: string().required("Required"),
  taxId: string()
    .when("country", {
      is: "USA",
      then: string().matches(/^\d{2}-\d{7}$/, {
        message: "EIN Number should be in the format 12-1234567",
        excludeEmptyString: true,
      }),
    })
    .when("country", {
      is: "CANADA",
      then: string().matches(/^([0-9a-zA-Z]+)$/, {
        message: "Please enter a valid Charity Registration Number",
        excludeEmptyString: true,
      }),
    })
    .when("teamType", {
      is: "CHARITY",
      then: string().required("Required"),
    }),

  teamName: string().when("teamType", {
    is: "ORGANIZATION",
    then: string(),
  }),
  website: string().when("teamType", {
    is: "ORGANIZATION",
    then: string()
      .trim()
      .required("Must enter a website")
      .matches(/^(?:(ftp|http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/, {
        message: "Please enter a valid url",
        excludeEmptyString: true,
      }),
  }),
});

const DEFAULT_PROPS = {};

const INITIAL_VALUES = {
  teamType: "",
  teamName: "",
  taxId: "",
  legalName: "",
  website: "",
};

export const TEAM_TYPES = [
  ["", "Please Select"],
  ["ORGANIZATION", "Club / League / School"],
  ["CHARITY", "Registered Charity / Non-Profit"],
  ["INDIVIDUAL", "Family  / Individual / Other"],
];

const legalNameLabel = (teamType: TTeamType) => {
  switch (teamType) {
    case "INDIVIDUAL":
      return "Beneficiary Name";
    case "CHARITY":
      return "Charity / Non-Profit Name";
    case "ORGANIZATION":
      return "Team Name";
    default:
      return "Team Name";
  }
};

// == Component ============================================================

export const BeneficiaryForm = ({ country }: IProps) => {
  const [globalState, stateDispatch] = useBeneficiaryContext();
  type TBeneficiaryValues = typeof globalState.beneficiaryValues;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit: FormikConfig<TBeneficiaryValues>["onSubmit"] = (values, actions) => {
    stateDispatch({ type: "updateBeneficiaryForm", beneficiaryValues: values });
  };

  const taxIdLabel =
    country.toLowerCase() === "canada" ? "Charity Registration Number" : "EIN Number (12-1234567)";
  const taxIdPlaceholder =
    country.toLowerCase() === "canada" ? "Format: 123456789RR0001" : "EIN Format: 12-123456";

  const initialValues = {
    country,
    ...INITIAL_VALUES,
    ...globalState.beneficiaryValues,
  };
  return (
    <Formik<TBeneficiaryValues>
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleChange, handleBlur, values }) => {
        return (
          <Form>
            <BeneficiaryTitle />
            <BeneficiaryCheque name={values.legalName} />
            <Select
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Team Type"
              name="teamType"
              placeholder="Please Select"
            >
              {TEAM_TYPES.map((type) => {
                return (
                  <option key={type[0]} value={type[0]}>
                    {type[1]}
                  </option>
                );
              })}
            </Select>
            {values.teamType !== "" && (
              <TextInput
                label={legalNameLabel(values.teamType)}
                name="legalName"
                placeholder={legalNameLabel(values.teamType)}
              />
            )}

            {values.teamType === "CHARITY" && (
              <TextInput label={taxIdLabel} name="taxId" placeholder={taxIdPlaceholder} />
            )}

            {values.teamType === "ORGANIZATION" && (
              <>
                <TextInput
                  label="Club, League or School Name"
                  name="teamName"
                  placeholder="Club, League or School Name"
                />
                <TextInput
                  label="Club, League or School Website"
                  name="website"
                  placeholder="Club, League or School Website"
                />
              </>
            )}

            <Button
              as="input"
              disabled={isSubmitting}
              label={isSubmitting ? "Submitting..." : "Next"}
              name="submit"
              type="submit"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

BeneficiaryForm.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
