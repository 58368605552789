import React from "react";
import gql from "graphql-tag";
import { IBoxProps } from "@components/common/atoms";
import { ImageCircle } from "@molecules";
import { BrandLogoFragment } from "./__generated__/BrandLogoFragment";
// import styled from "@emotion/styled";

// == Types ================================================================
type TBrand = BrandLogoFragment;
interface IProps {
  brand: TBrand;
  withBorder?: boolean;
  p: IBoxProps["p"];
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment BrandLogoFragment on Brand {
      id
      name
      logoImage
      logoBackgroundColor
      invertedLogoImage
    }
  `,
};

const DEFAULT_PROPS = {
  withBorder: true,
  p: 3,
};

// == Component ============================================================

const BrandLogo = ({ withBorder, brand, p, ...rest }: IProps) => {
  const brandLogo = brand.invertedLogoImage ? brand.invertedLogoImage : brand.logoImage;
  const brandLogoBg = brand.invertedLogoImage ? brand.logoBackgroundColor : "white";
  return (
    <ImageCircle
      alt={brand.name}
      bg={brandLogoBg}
      border={withBorder ? "silver" : ""}
      p={p}
      src={brandLogo}
      {...rest}
    />
  );
};

BrandLogo.fragments = FRAGMENTS;
BrandLogo.defaultProps = DEFAULT_PROPS;
export default BrandLogo;

// == Styles ===============================================================
