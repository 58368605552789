import React from "react";
// import styled from "@emotion/styled";
import gql from "graphql-tag";
import { LinkBlock } from "@organisms/linkBlockComponents";
import { Column, Row } from "@atoms";
import { CollectionGridFragment } from "./__generated__/CollectionGridFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionGridFragment;
  storefrontId?: string;
}

// == Constants ============================================================
// for some reason first 8 would still give me all
const FRAGMENTS = {
  fields: gql`
    fragment CollectionGridFragment on Collection {
      id
      links(first: 8) {
        id
        ...LinkBlockFragment
      }
    }
    ${LinkBlock.fragments.fields}
  `,
};

// == Component ============================================================

const CollectionGrid = ({ collection, storefrontId }: IProps) => {
  return (
    <Row flexWrap="wrap" justifyContent="center">
      {collection.links.slice(0, 8).map((link) => {
        return (
          <Column autoColumn={false} key={link.id} mb={3} width={[1 / 2, 1 / 4]}>
            <LinkBlock link={link} collectionId={collection.id} storefrontId={storefrontId} />
          </Column>
        );
      })}
    </Row>
  );
};

export default CollectionGrid;

CollectionGrid.fragments = FRAGMENTS;
