import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import * as FullStory from "@fullstory/browser";

import ReactOnRails from "react-on-rails";
import SharePercentApp from "./bundles/SharePercentApp";
import NotificationsApp from "./bundles/NotificationsApp";
import TimelineApp from "./bundles/TimelineApp";
import ActivityApp from "./bundles/ActivityApp";
import CampaignEventInviteApp from "./bundles/CampaignEventInviteApp";
import LeaveCampaignApp from "./bundles/LeaveCampaignApp";
import UserFundsApp from "./bundles/UserFundsApp";
import { SwitchTeamApp } from "./bundles/SwitchTeamApp";
import TeamsnapConnectApp from "./bundles/TeamsnapConnectApp";
import StoreApp from "./bundles/StoreApp";
import CampaignBeneficiaryApp from "./bundles/CampaignBeneficiaryApp";
import AudienceFiltersApp from "./bundles/AudienceFiltersApp";
import ProgramCreationApp from "./bundles/ProgramCreationApp";
import GiftcardReloadSettingsApp from "./bundles/GiftcardReloadSettingsApp";
import InviteModalApp from "./bundles/InviteModalApp";

if ((process.env.SENTRY_ENV == "staging" || process.env.SENTRY_ENV == "production") && process.env.SENTRY_MAIN_DSN) {
  Sentry.init({
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    dsn: process.env.SENTRY_MAIN_DSN,
    environment: process.env.SENTRY_ENV,
    integrations: [new BrowserTracing()],
    denyUrls: [
      // ignore errors from twitter analytics
      /static\.ads-twitter\.com/i,
    ],
  });
}

ReactOnRails.register({
  SharePercentApp,
  NotificationsApp,
  TimelineApp,
  ActivityApp,
  CampaignEventInviteApp,
  UserFundsApp,
  LeaveCampaignApp,
  SwitchTeamApp,
  TeamsnapConnectApp,
  StoreApp,
  CampaignBeneficiaryApp,
  AudienceFiltersApp,
  ProgramCreationApp,
  GiftcardReloadSettingsApp,
  InviteModalApp,
});
