import React, { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import styled from "@emotion/styled";
import "react-datepicker/dist/react-datepicker.css";
import { isSameDay, startOfToday, endOfDay } from "date-fns";

// == Types ================================================================

interface IProps {
  label: string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  minDate?: string;
  onChange?: () => void;
}

// == Constants ============================================================

TimePickerField.defaultProps = {
  disabled: false,
  placeholder: "7:00 PM",
  defaultValue: null,
  minDate: new Date(),
};

// == Component ============================================================

export default function TimePickerField({ minDate, ...props }: IProps) {
  const { name, disabled, label, placeholder, onChange: onFieldChange } = props;
  const calculateMinTime = (minDate) =>
    isSameDay(new Date(minDate), new Date()) ? new Date() : startOfToday();
  const [minTime, setMinTime] = useState(calculateMinTime(minDate));
  useEffect(() => {
    Materialize.updateTextFields();
    setMinTime(calculateMinTime(minDate));
  }, [minDate]);

  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const onTimePickerFieldChange = (val) => {
    if (onFieldChange) {
      onFieldChange(val);
    }
    setMinTime(calculateMinTime(val));
    setFieldValue(field.name, val);
  };

  return (
    <>
      <ELabel className="active" htmlFor={name}>
        {label}
      </ELabel>
      <EView className={`input-field ${disabled ? "disabled" : ""}`}>
        <EDatePicker
          {...field}
          {...props}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          name={name}
          minTime={minTime}
          minDate={new Date(minDate)}
          maxTime={endOfDay(new Date())}
          placeholderText={placeholder}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            onTimePickerFieldChange(val);
          }}
        />
        {meta.touched && meta.error ? <span className="standalone-error">{meta.error}</span> : null}
      </EView>
    </>
  );
}

// == Styles ===============================================================

const EDatePicker = styled(DatePicker)`
  padding: ${({ theme }) => theme.space[3]}px;
  border: ${({ theme }) => {
    return `1px solid ${theme.colors.gray}`;
  }};
  border-radius: 5px;
  height: 40px !important;
  padding-top: 0 !important;
`;

const EView = styled.div`
  > div.react-datepicker-wrapper,
  > div
    > div.react-datepicker__input-container
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }
  width: 100%;
  > div.react-datepicker-popper {
    margin-top: -15px;
  }
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;
