import { Box, FontAwesome, H2, Image, Text, TouchableOpacity } from "@atoms";
import styled from "@emotion/styled";
import { useStoreAppValue } from "@hooks";
import { BrandCircle } from "@organisms/brandCircleComponents";
import { LocationContext } from "@reach/router";
import { renderIf } from "@services";
import { LinkType } from "@types";
import { formatDistance } from "date-fns";
import gql from "graphql-tag";
import React, { ReactNode } from "react";
import { LinkModalFragment_giftcardDenominations_edges_node } from "../LinkModal/__generated__/LinkModalFragment";
import { LinkModalHeroFragment } from "./__generated__/LinkModalHeroFragment";

// == Types ================================================================

interface IProps {
  children: ReactNode;
  link: LinkModalHeroFragment;
  campaignId?: string;
  fundraiserId?: string;
  onLinkClick: () => void;
  locationContext: LocationContext;
  selectedDenom: LinkModalFragment_giftcardDenominations_edges_node;
  setSelectedDenom: React.Dispatch<
    React.SetStateAction<LinkModalFragment_giftcardDenominations_edges_node>
  >;
}

// == Constants ============================================================

LinkModalHero.fragment = gql`
  fragment LinkModalHeroFragment on Link {
    id
    type
    logoImage
    image
    name
    isBulkShop
    availableDenominations
    giveSentence
    promoSecondaryLabel
    promoEndsAt
    cashbackTiming
    defaultBrand {
      id
      ...BrandCircleFragment
    }
    merchant {
      id
      website
    }
  }
  ${BrandCircle.fragments.fields}
`;

const LOGO_WIDTH = 200;

const DEFAULT_PROPS = {
  selectedFundraisers: [],
};

// == Component ============================================================

function LinkModalHero({ link, locationContext, children, selectedDenom }: IProps) {
  const { incentiveAvailable, incentiveAmount } = useStoreAppValue();
  const { location, navigate } = locationContext;

  const onBackClick = () => {
    if (location.state.canReturn) {
      window.history.back();
    } else {
      navigate(location.pathname);
    }
  };

  const renderImage = () => {
    if ([LinkType.BRAND, LinkType.TRAVELSHOP].includes(link.type) && link.defaultBrand) {
      return <BrandCircle brand={link.defaultBrand} imageWidth={70} mb={2} mx="auto" pad={14} />;
    }

    if (link.image) {
      return (
        <Image
          alt={link.name}
          borderRadius={isGiftcard ? "giftcard" : 0}
          boxShadow="lightShadow"
          maxWidth={LOGO_WIDTH}
          mb={2}
          mx="auto"
          src={`${link.image}?w=${LOGO_WIDTH}`}
        />
      );
    }
    return null;
  };

  const { type: linkType } = link;
  const { GIFTCARDOFFER } = LinkType;
  const bulkShopColorChecker = () => "primary";

  const isGiftcard = linkType === GIFTCARDOFFER;
  return (
    <Box p={3} textAlign="center">
      <Box>
        <TouchableOpacity onTouch={onBackClick}>
          <Text color={bulkShopColorChecker()} fontSize={[3, 4]}>
            <FontAwesome icon="arrow-left" />
          </Text>
        </TouchableOpacity>
      </Box>
      {renderImage()}
      <Box mb={2} textAlign="center">
        <H2 textAlign="center">{link.name}</H2>
      </Box>
      <Box mb={1} textAlign="center">
        <Text display="block" lineHeight="1" textAlign="center" textStyle={"givesText"}>
          {link.giveSentence}
          {incentiveAvailable &&
            !!incentiveAmount &&
            ` +$${parseFloat(incentiveAmount).toFixed()} First Shop Bonus`}
        </Text>
        {renderIf(!!link.promoSecondaryLabel || !!link.promoEndsAt)(
          <Box textAlign="center">
            {!!link.promoSecondaryLabel && (
              <EPromoLabelText
                as="div"
                color={bulkShopColorChecker()}
                dangerouslySetInnerHTML={{ __html: link.promoSecondaryLabel }}
              />
            )}
            {!!link.promoEndsAt && (
              <Text color={bulkShopColorChecker()} display="block" textStyle="blockText">
                Ends in {formatDistance(new Date(link.promoEndsAt), new Date())}
              </Text>
            )}
          </Box>
        )}
        <Text display="block" fontSize={0} fontWeight="bold" mt={1} textAlign="center">
          {`Gives back ${link.cashbackTiming}`}
        </Text>
      </Box>
      {children}
    </Box>
  );
}

LinkModalHero.defaultProps = DEFAULT_PROPS;

export default LinkModalHero;

// == Styles ===============================================================

const EPromoLabelText = styled(Text)`
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  * {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes[0]}px;
  }
`;
