import gql from "graphql-tag";

const CAMPAIGN_LIST_ITEM_FRAGMENT = gql`
  fragment CampaignListItemFragment on Campaign {
    id
    name
    photoImage
    url
    currency
    amountRaised {
      formatted
    }
    goal {
      formatted
    }
  }
`;

export const FUNDS_QUERY = gql`
  {
    Viewer {
      id
      personalAccount {
        id
        transferrableFunds {
          currency
          heldAmount {
            formatted
            centsAmount
          }
          availableAmount {
            formatted
            centsAmount
          }
        }
      }
    }
  }
`;

export const PAYMENTS_QUERY = gql`
  {
    Viewer {
      id
      personalAccount {
        id
        payments(last: 15) {
          edges {
            node {
              id
              currency
              amount {
                formatted
              }
              processedAt
              reference
            }
          }
        }
      }
    }
  }
`;

export const VIEWER_CAMPAIGNS_QUERY = gql`
  query CampaignSearchQuery($currency: Currency!) {
    CampaignSearch(query: "", mineOnly: true, status: RUNNING, currency: $currency) {
      edges {
        node {
          id
          ...CampaignListItemFragment
        }
      }
    }
  }
  ${CAMPAIGN_LIST_ITEM_FRAGMENT}
`;

export const FUNDS_RECIPIENT_QUERY = gql`
  query FundsRecipientQuery($currency: Currency) {
    Viewer {
      id
      runningCampaigns(currency: $currency) {
        id
        ...CampaignListItemFragment
      }
    }
  }
  ${CAMPAIGN_LIST_ITEM_FRAGMENT}
`;
