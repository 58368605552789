import React from "react";
// import styled from "@emotion/styled";
import { Text, Box, IBoxProps } from "@atoms";

// == Types ================================================================
interface IProps extends IBoxProps {
  text?: string;
  inline?: boolean;
}
// == Constants ============================================================
const DEFAULT_PROPS = {
  inline: true,
};
// == Component ============================================================

const LabelPill = ({ inline, text, children, ...rest }: IProps) => {
  return (
    <Box
      bg="lightPrimaryBg"
      borderRadius="100px"
      display={inline ? "inline-block" : "block"}
      px={2}
      {...rest}
    >
      {text ? <Text color="black">{text}</Text> : children}
    </Box>
  );
};

export default LabelPill;

LabelPill.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
