import React from "react";
import { IBoxProps } from "@atoms";
import { BrandOfferSingleQuery } from "@organisms";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends IBoxProps {
  campaignId: string;
  isHome?: boolean;
}

// == Constants ============================================================

// == Component ============================================================

const StoreFrontFeaturedBrand = ({ campaignId, isHome, ...rest }: IProps) => {
  if (!campaignId) return null;
  return <BrandOfferSingleQuery campaignId={campaignId} isHome={isHome} {...rest} />;
};

export default StoreFrontFeaturedBrand;

// == Styles ===============================================================
