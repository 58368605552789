import React, { useEffect } from "react";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "@apollo/react-hooks";
import isEmpty from "lodash/isEmpty";
import { oc } from "ts-optchain";
import { useScrollTopOnMount, useLoadingDelay, useFullStoryEvents } from "@hooks";
import { Text } from "@atoms";
import { FadeIn } from "@utils";
import { DefaultStoreFrontType } from "@types";
import { StoreFrontViewLoading } from "../StoreFrontViewLoading";
import StoreFrontView from "./StoreFrontView";
import { StoreFrontViewQuery as StoreFrontViewQueryType } from "./__generated__/StoreFrontViewQuery";
import { FullStoryEventNames } from "@services";
// import { CollectionViewQuery as CollectionViewQueryType } from "./__generated__/CollectionViewQuery";

// == Types ================================================================

interface IRouteProps {
  isLoading?: boolean;
  type: DefaultStoreFrontType;
}

type TProps = RouteComponentProps<IRouteProps>;

// == Constants ============================================================

const STORE_FRONT_VIEW_QUERY = gql`
  query DefaultStoreFrontViewQuery($type: DefaultStoreFrontType!, $carouselItems: Int = 10) {
    DefaultStoreFront(type: $type) {
      id
      ...StoreFrontViewFragment
    }
  }
  ${StoreFrontView.fragments.fields}
`;

const DEFAULT_PROPS = {
  isLoading: false,
};

// == Component ============================================================

export const DefaultStoreFrontViewQuery = ({ type, isLoading }: TProps) => {
  useScrollTopOnMount();

  const loadingDelayReady = useLoadingDelay(100);
  const { loading, data, error } = useQuery<StoreFrontViewQueryType>(STORE_FRONT_VIEW_QUERY, {
    context: {
      skipBatch: true,
    },
    variables: { type },
  });

  const { sendFullStoryEvent } = useFullStoryEvents();

  if (error) {
    return <Text>{error.message}</Text>;
  }

  const hasNotLoaded = isEmpty(data) || loading || !loadingDelayReady || isLoading;
  useEffect(() => {
    sendFullStoryEvent(FullStoryEventNames.storefrontViewed, {
      storefrontName: type,
    });
  }, []);

  if (hasNotLoaded) return <StoreFrontViewLoading />;

  const storefront = oc(data).DefaultStoreFront();
  if (!storefront) return null;

  return (
    <FadeIn>
      <StoreFrontView isHome storefront={storefront} />
    </FadeIn>
  );
};

DefaultStoreFrontViewQuery.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
