import React from "react";
import styled from "@emotion/styled";
import { Breakpoint } from "react-socks";
import gql from "graphql-tag";
import { LinkBlock } from "@organisms/linkBlockComponents";
import { MultiItemCarousel } from "@molecules";
import { Box, Column, Flex } from "@atoms";
import { CollectionCarouselFragment } from "./__generated__/CollectionCarouselFragment";

// == Types ================================================================

interface IProps {
  collection: CollectionCarouselFragment;
  storefrontId?: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment CollectionCarouselFragment on Collection {
      id
      links(first: $carouselItems) {
        id
        ...LinkBlockFragment
      }
    }
    ${LinkBlock.fragments.fields}
  `,
};

// == Component ============================================================

const CollectionCarousel = ({ collection, storefrontId }: IProps) => {
  const renderLinkRow = () => {
    return (
      <EFlex mx={[0, 0, -3]}>
        {collection.links.map((link) => (
          <Column autoColumn={false} key={link.id} width={[0.45, 1 / 3, 1 / 4, 1 / 5]}>
            <LinkBlock link={link} collectionId={collection.id} storefrontId={storefrontId} />
          </Column>
        ))}
      </EFlex>
    );
  };
  return (
    <Box mx={[-2, -2, 0]} position="relative">
      {/*
        this mx above is a total hack, and will like cause me to regret it,
        but can't get the row to work properly otherwise
        the values there should be the same as what they are for the row
        in fact, bounty for putting this bad hack into a component for now
        cause it's used in multiple places.
        #rowHacks
      */}
      <Breakpoint xsmall>{renderLinkRow()}</Breakpoint>
      <Breakpoint small up>
        {collection.links.length < 6 ? (
          renderLinkRow()
        ) : (
          <Box px={[0, 4, 4, 0]}>
            <MultiItemCarousel itemLength={collection.links.length}>
              {collection.links.map((link) => (
                <Box alignSelf="flex-start" key={link.id} px={[1]} width="100%">
                  <LinkBlock link={link} collectionId={collection.id} storefrontId={storefrontId} />
                </Box>
              ))}
            </MultiItemCarousel>
          </Box>
        )}
      </Breakpoint>
    </Box>
  );
};

export default CollectionCarousel;

CollectionCarousel.fragments = FRAGMENTS;

// == Styles ===============================================================

const EFlex = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`;
