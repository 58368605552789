import React, { useState } from "react";

const ProgramCreation = () => {
  const [programType, setProgramType] = useState("");

  if (programType === "") {
    return (
      <div className="row">
        <div className="col-md-4">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => {
              window.location.href = `/admin/programs/new?program_type=BEHAVIOUR&audience_type=DYNAMIC`;
            }}
          >
            Behavioural
          </button>
          <p>
            Behavioural programs use a dynamic audience which changes over time based on the user or
            campaign&apos;s events and properties.
          </p>
        </div>

        <div className="col-md-4">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => {
              setProgramType("TIME");
            }}
          >
            Set Time
          </button>
          <p>
            Time based programs run for a specific period of time and can either be targeted to a
            dynamic (based on user/campaign events and properties) or a static audience, optionally
            split into control and test groups.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-4">
        <button
          className="btn btn-default"
          type="button"
          onClick={() => {
            window.location.href = `/admin/programs/new?program_type=TIME&audience_type=DYNAMIC`;
          }}
        >
          Dynamic
        </button>
        <p>
          A dynamic audience is based on the user/campaign events or properties, but can change over
          time.
        </p>
      </div>

      <div className="col-md-4">
        <button
          className="btn btn-default"
          type="button"
          onClick={() => {
            window.location.href = `/admin/programs/new?program_type=TIME&audience_type=STATIC`;
          }}
        >
          Static
        </button>
        <p>
          A static audience is locked, and no members can enter or leave the group once the program
          is published. This static audience can be optionally split into control and test groups.
        </p>
      </div>
    </div>
  );
};

export default () => {
  return <ProgramCreation />;
};
