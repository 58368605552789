import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { oc } from "ts-optchain";
import { isEmpty } from "lodash";
import { Text } from "@atoms";
import { useLoadingDelay } from "@hooks";
import { OccasionListLoading } from "../OccasionListLoading";
import { OccasionsList } from "./OccasionsList";
import { OccasionListQuery as TOccasionListQuery } from "./__generated__/OccasionListQuery";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

const OCCASION_LIST_QUERY = gql`
  query OccasionListQuery {
    Occasions(first: 30) {
      ...OccasionsListFragment
    }
  }
  ${OccasionsList.fragments.fields}
`;

// == Component ============================================================

export const OccasionsListQuery = () => {
  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery<TOccasionListQuery>(OCCASION_LIST_QUERY, {
    context: {
      skipBatch: true,
    },
  });

  if (error) {
    return <Text>{error.message}</Text>;
  }

  if (isEmpty(data) || loading || !loadingDelayReady) return <OccasionListLoading />;
  const occasions = oc(data).Occasions();
  if (!occasions) return null; // return error

  return <OccasionsList occasions={occasions} />;
};

// == Styles ===============================================================
