import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Box, IBoxProps } from "@atoms/Box";

// == Types ================================================================

export interface IProps extends IBoxProps {
  children?: ReactNode;
  innerProps?: IBoxProps;
}

// == Constants ============================================================

// == Component ============================================================

export const Circle = ({ children, innerProps = {}, ...rest }: IProps) => {
  return (
    <EBox borderRadius="100%" {...rest}>
      {!!children && <Box {...innerProps}>{children}</Box>}
    </EBox>
  );
};

// == Styles ===============================================================

const EBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  :before {
    display: block;
    content: "";
    padding-top: 100%;
  }
`;
