import React, { useState } from "react";
// import styled from "@emotion/styled";
import gql from "graphql-tag";
import { LocationContext } from "@reach/router";
import { RailsUrl } from "@services";
import { Box, IBoxProps, Image, Text, H2, FontAwesome, TouchableOpacity } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { EButtonIconBox, EInStoreBuyButton } from "../InStoreBuyButton/InStoreBuyButtonEmotion";
import { InStoreModalQuery_Link_location } from "../InStoreModal/__generated__/InStoreModalQuery";
import { InStoreGives } from "../InStoreGives";
import { LinkModalFundraiserListQuery } from "../../linkModalComponents/LinkModalFundraiserList/LinkModalFundraiserListQuery";
import { InStoreBuyButton } from "../InStoreBuyButton";
import { InStoreHeroFragment } from "./__generated__/InStoreHeroFragment";

// == Types ================================================================
type TLink = InStoreHeroFragment;

interface IProps extends IBoxProps {
  link: TLink;
  onBackClick: () => void;
  campaignId?: string;
  onLinkClick: () => void;
  linkedCardsAvailable: boolean;
  linkLocation: InStoreModalQuery_Link_location;
  locationContext: LocationContext;
}

// == Constants ============================================================

InStoreHero.defaultProps = {
  campaignId: null,
};

InStoreHero.fragments = {
  fields: gql`
    fragment InStoreHeroFragment on Link {
      id
      image
      name
      type
      availableDenominations
      ...InStoreBuyButtonFragment
    }
    ${InStoreBuyButton.fragment}
  `,
};
// == Component ============================================================

export default function InStoreHero({
  link,
  onBackClick,
  campaignId,
  linkedCardsAvailable,
  onLinkClick,
  linkLocation,
  locationContext,
  ...rest
}: IProps) {
  const logoWidth = 200;
  const { location } = locationContext;
  const { isContinuity, isMember, currentUser } = useStoreAppValue();
  const [fundraiserId, selectFundraiserId] = useState("");
  const { tinyLinkKey } = location.state;

  let href;
  if (!["EMPYR"].includes(link.type)) {
    href = "#";
  } else if (!currentUser) {
    href = tinyLinkKey ? `${RailsUrl.createTeamUrl()}?s=${tinyLinkKey}` : RailsUrl.createTeamUrl();
  } else {
    href = RailsUrl.createInStoreUrl({
      campaignId,
      linkId: link.id,
      locationId: linkLocation.id,
      fundraiserId,
    });
  }

  const onFundraiserSelect = (id: string) => {
    selectFundraiserId(id);
    return null;
  };

  const onClick = () => {
    // add tracking here
    onLinkClick();
    return null;
  };
  const renderLocationInfo = () => {
    if (location) {
      return (
        !!location && (
          <Box mb={2}>
            <Text display="block" textAlign="center">
              {linkLocation.street1}
            </Text>
            <Text display="block" textAlign="center">
              {linkLocation.street2}
            </Text>
            <Text
              display="block"
              textAlign="center"
            >{`${linkLocation.city}, ${linkLocation.province} ${linkLocation.postalCode}`}</Text>
          </Box>
        )
      );
    }
    return null;
  };

  return (
    <Box textAlign="center" {...rest} pb={4}>
      <Box>
        <TouchableOpacity onTouch={onBackClick}>
          <FontAwesome icon="arrow-left" />
        </TouchableOpacity>
      </Box>
      {!!linkLocation && !!linkLocation.mapImage && (
        <Image
          alt={link.name}
          borderRadius="mapImage"
          boxShadow="lightShadow"
          maxWidth={logoWidth}
          mb={2}
          mx="auto"
          src={linkLocation.mapImage}
        />
      )}
      <Box mb={2} textAlign="center">
        <H2 textAlign="center">{link.name}</H2>
      </Box>
      {renderLocationInfo()}
      <InStoreGives link={link} />
      {(!isMember || isContinuity) && (
        <LinkModalFundraiserListQuery onFundraiserSelect={onFundraiserSelect} />
      )}
      {!!linkedCardsAvailable && currentUser && (
        <InStoreBuyButton
          campaignId={campaignId}
          fundraiserId={fundraiserId}
          link={link}
          linkedCardsAvailable={linkedCardsAvailable}
          linkLocation={linkLocation}
          tinyLinkKey={tinyLinkKey}
          onLinkClick={onLinkClick}
        />
      )}
      {!currentUser && (
        <EInStoreBuyButton
          as="a"
          href={href}
          target="_blank"
          userNeedsSignup={!currentUser}
          onClick={onClick}
        >
          <EButtonIconBox>
            <Box>
              <Image
                alt="Empyr"
                src="https://flipgive.twic.pics/images/site/shop/link-modal-in-store.png"
              />
            </Box>
          </EButtonIconBox>
          Sign Up And Register Your Team To Earn In-Store
        </EInStoreBuyButton>
      )}
    </Box>
  );
}

// == Styles ===============================================================
