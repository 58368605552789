import React from "react";
import { Flex, Button, Text } from "@atoms";
import { RailsUrl } from "@services";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  id: string;
}

// == Constants ============================================================

FundsPageFundraiserListReferralItem.defaultProps = {};

// == Component ============================================================

export default function FundsPageFundraiserListReferralItem({ id }: IProps) {
  const { referralsUrl } = RailsUrl;
  return (
    <Flex justifyContent="center" padding={[1, 2, 3]}>
      <Button as="a" href={referralsUrl(id)}>
        <Text color="white">Refer and Earn Bonus Cash</Text>
      </Button>
    </Flex>
  );
}

// == Styles ===============================================================
