import React from "react";
import gql from "graphql-tag";
import { renderIf } from "@services";
import { Text, Box, Heading, Container } from "@atoms";
import { OccasionHero } from "../OccasionHero";
import { OccasionHeaderFragment } from "./__generated__/OccasionHeaderFragment";
// == Types ================================================================

interface IProps {
  occasion: OccasionHeaderFragment;
}

interface IOccasionAboutProps {
  aboutHeader?: string;
  aboutParagraph?: string;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment OccasionHeaderFragment on Occasion {
      id
      aboutHeader
      aboutParagraph
      ...OccasionHeroFragment
    }
    ${OccasionHero.fragments.fields}
  `,
};

// == Component ============================================================

const OccasionAbout = ({ aboutHeader, aboutParagraph }: IOccasionAboutProps) => {
  if (!aboutHeader && !aboutParagraph) return null;

  return (
    <Box mt={3}>
      {renderIf(!!aboutHeader)(
        <Heading as="h1" mb={2}>
          {aboutHeader}
        </Heading>
      )}
      {renderIf(!!aboutParagraph)(<Text as="p">{aboutParagraph}</Text>)}
    </Box>
  );
};

const OccasionHeader = ({ occasion }: IProps) => {
  const { aboutHeader, aboutParagraph } = occasion;

  return (
    <Box mb={4}>
      <OccasionHero occasion={occasion} />
      <OccasionAbout aboutHeader={aboutHeader} aboutParagraph={aboutParagraph} />
    </Box>
  );
};

export default OccasionHeader;

OccasionHeader.fragments = FRAGMENTS;
// == Styles ===============================================================
