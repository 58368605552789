import { useMemo } from "react";

interface IPlayer {
  isDraft: boolean;
  activeFundraisersCount: number;
  deletedAt: string | null;
}

const hasSupportersOrActive = ({ isDraft, activeFundraisersCount, deletedAt }: IPlayer) =>
  activeFundraisersCount || (!deletedAt && !isDraft);

export function useSortedPlayers(players: IPlayer[]) {
  return useMemo(
    () => players.filter(hasSupportersOrActive).sort((a, b) => +!!a.deletedAt - +!!b.deletedAt),
    []
  );
}
