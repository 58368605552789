import React from "react";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Box, Container, Row, Column } from "@atoms";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

const OccasionListLoading = () => {
  const mb = 4;
  const LoadingComponent = () => (
    <Row>
      <Column>
        <Box data-testid="loading-container" mb={mb}>
          <RectShape color="#E0E0E0" style={{ width: "100%", paddingBottom: "50%" }} />
        </Box>
      </Column>
    </Row>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1500}
      ready={false}
    >
      {null}
    </ReactPlaceholder>
  );
};

export default OccasionListLoading;

// == Styles ===============================================================
