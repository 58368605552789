import React from "react";
import { arrayOf, shape, func, bool } from "prop-types";
import Attendee from "./Attendee";

function InvitationList({
  invitableFundraisers,
  handleCheck,
  viewerIsParticipant,
  userIdsToInvite,
}) {
  return (
    <div className="event-invite-list">
      <div className="event-invite-list-item__status">My Teammates</div>
      {invitableFundraisers.map((participant) => (
        <Attendee
          handleCheck={handleCheck}
          key={`invitation-${participant.id}`}
          participant={participant}
          userIdsToInvite={userIdsToInvite}
          viewerIsParticipant={viewerIsParticipant}
        />
      ))}
    </div>
  );
}

InvitationList.propTypes = {
  invitableFundraisers: arrayOf(shape({})).isRequired,
  userIdsToInvite: shape({}).isRequired,
  handleCheck: func.isRequired,
  viewerIsParticipant: bool.isRequired,
};

export default InvitationList;
