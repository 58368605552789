import { Box, Flex, Image, TextUppercase, TouchableOpacity } from "@atoms";
import { navigate } from "@reach/router";
import { constants } from "@services";
import { LinkType } from "@types";
import gql from "graphql-tag";
import { LinkBlockGives } from "../LinkBlockGives";
import { LinkBlockPromoLabel } from "../LinkBlockPromoLabel";
import { LinkBlockFragment } from "./__generated__/LinkBlockFragment";

// == Types ================================================================

interface IProps {
  link: LinkBlockFragment;
  collectionId: string;
  storefrontId?: string;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment LinkBlockFragment on Link {
      id
      type
      giftcardType
      name
      image
      logoImage
      giveSentence
      terms
      labelPromotionId
      commissionPromotionId
      promotionInfo {
        promotionLabel
        promotionSecondaryLabel
        promotionEndsAt
      }
      isBulkShop
    }
  `,
};

// == Component ============================================================

const LinkBlock = ({ link, collectionId, storefrontId }: IProps) => {
  const {
    id: linkId,
    type: linkType,
    image,
    name,
    giveSentence,
    promotionInfo,
    labelPromotionId,
    commissionPromotionId,
  } = link;

  const { promotionEndsAt, promotionSecondaryLabel, promotionLabel } = promotionInfo;
  const { GIFTCARDOFFER } = LinkType;

  const onClick = () => {
    const searchParams = new URL(document.location.href).searchParams;

    searchParams.set(constants.linkParam, linkId);
    if (labelPromotionId) searchParams.set(constants.labelPromotionParam, labelPromotionId);
    if (commissionPromotionId)
      searchParams.set(constants.commisionPromotionParam, commissionPromotionId);
    if (collectionId) searchParams.set(constants.collectionParam, collectionId);
    if (storefrontId) searchParams.set(constants.storefrontParam, storefrontId);

    navigate(`?${searchParams.toString()}`);
  };

  const isGiftcard = linkType === GIFTCARDOFFER;
  // temp because clicking a link block on a carousel cauess it to perm faded
  // <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={onClick}></TouchableOpacity>
  return (
    <TouchableOpacity containerProps={{ style: { width: "100%" } }} onTouch={onClick}>
      <Flex flexDirection="column">
        <Box position="relative">
          {!!image && (
            <Image
              alt="link-image"
              borderRadius={isGiftcard ? "giftcard" : 0}
              boxShadow="lightShadow"
              loading="lazy"
              src={`${image}&twic=v1/refit-inside=250x187.5@center/background=fff`}
            />
          )}

          {promotionLabel && (
            <LinkBlockPromoLabel
              bottom="20px"
              label={promotionLabel}
              left="0"
              position="absolute"
            />
          )}
        </Box>

        <TextUppercase ellipsis as="p" fontSize={[0]} pt={2} textAlign="center">
          {name}
        </TextUppercase>
        <LinkBlockGives
          giveSentence={giveSentence}
          promoEndsAt={promotionEndsAt}
          secondaryLabel={promotionSecondaryLabel}
        />
      </Flex>
    </TouchableOpacity>
  );
};

export default LinkBlock;

LinkBlock.fragments = FRAGMENTS;

// == Styles ===============================================================
