import React from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { useMutation } from "react-apollo";
import {
  Modal,
  Text,
  TouchableOpacity,
  Flex,
  Button,
  Box,
  TimesIcon,
  DangerButton,
} from "@components/common/atoms";
import { ErrorService } from "@services";

// == Types ================================================================

interface IProps {
  removeCardModal: boolean;
  closeRemoveCardModal: () => void;
  card: string;
  dispatch: () => void;
  formProps: $FixMe;
  currency: string;
  setFieldValue: () => void;
  currentCardId: string;
}

// == Constants ============================================================

CheckoutRemoveCardModal.defaultProps = {};

const STRIPE_CARD_DELETE = gql`
  mutation StripeCardDeleteMutation($cardId: ID!, $currency: Currency!) {
    StripeCardDelete(cardId: $cardId, currency: $currency) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Viewer {
        id
        stripeCards {
          id
        }
      }
    }
  }
`;

// == Component ============================================================

export default function CheckoutRemoveCardModal({
  removeCardModal,
  closeRemoveCardModal,
  card,
  formProps,
  currency,
  dispatch,
  currentCardId,
  setFieldValue,
}: IProps) {
  const [stripeCardDelete] = useMutation(STRIPE_CARD_DELETE);

  const onConfirmDeleteCard = (cardId) => {
    closeRemoveCardModal();
    onRemoveStripeCard({ cardId, formProps });
    if (!cardId.includes("tok")) {
      stripeCardDelete({
        variables: {
          cardId,
          currency,
        },
      }).catch(ErrorService.error);
    }
  };

  const onRemoveStripeCard = ({ cardId }) => {
    if (currentCardId === cardId) {
      setFieldValue("cardId", "");
      dispatch({ type: "REMOVE_VALID_FORM", formName: "PAYMENT_FORM" });
    }
    dispatch({ type: "REMOVE_CARD", cardId });
  };

  return (
    <EModal
      fullScreenMobile={false}
      isOpen={removeCardModal}
      size="small"
      onDismiss={closeRemoveCardModal}
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        px={[3, 4]}
        py={[4, 5]}
      >
        <Text as="p" mb={4}>
          Are you sure you want to remove card ending with {card.last4}?
        </Text>
        <Flex justifyContent="space-between" width={1 / 2}>
          <DangerButton onClick={() => onConfirmDeleteCard(card.id)}>Yes</DangerButton>
          <Button onClick={closeRemoveCardModal}>No</Button>
        </Flex>
      </Flex>
      <Box position="absolute" right={16} top={16}>
        <TouchableOpacity onClick={closeRemoveCardModal}>
          <TimesIcon />
        </TouchableOpacity>
      </Box>
    </EModal>
  );
}

// == Styles ===============================================================
const EModal = styled(Modal)`
  position: relative;
`;
