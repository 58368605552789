import { Settings } from "@services";

export default (notification) => {
  const { campaignIds, activities, isPersonal } = notification;
  const activity = activities[0];
  const { destinationActivityId, campaign } = activity;

  if (isPersonal) {
    return `/user/feed/${destinationActivityId}`;
  } else if (campaign) {
    return `/teams/${campaign.id}/activity/${destinationActivityId}`;
  } else if (campaignIds && campaignIds.length > 0) {
    // if campaign you're viewing can view notification, stick with current
    if (campaignIds.includes(String(Settings.campaignId))) {
      return `/teams/${Settings.campaignId}/activity/${destinationActivityId}`;
    }
    return `/teams/${campaignIds[0]}/activity/${destinationActivityId}`;
  }

  // last ditch effort... hopefully this campaign can view activity
  return `/teams/${Settings.campaignId}/activity/${destinationActivityId}`;
};
