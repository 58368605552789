const stop = () => {
  if (typeof Intercom !== "undefined" && Intercom !== null) {
    Intercom("hide");
  }
  const intercomDiv = document.querySelector("#intercom-container");
  if (intercomDiv) {
    intercomDiv.style.display = "none";
  }
};
const start = () => {
  const intercomDiv = document.querySelector("#intercom-container");
  if (intercomDiv) {
    intercomDiv.style.display = "block";
  }
};
export const intercom = {
  stop,
  start,
};
