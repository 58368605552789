import React, { useState } from "react";
import { shape, string, bool } from "prop-types";
import styled from "@emotion/styled";
import { Box, Button, Column, Flex, H1, Modal, Text } from "@atoms";
import { theme } from "@styles";
import { AvatarImage } from "@components";
import { RailsUrl } from "@services";
import { SwitchTeamModal } from "./SwitchTeamModal";

const MODAL_POINTS = [
  "Your personal account is for you to continue shopping and earning between seasons.",
  "Only you have access to your personal account, it is not shared with any other teammates.",
  "To receive your personal earnings, simply transfer them to your next team!",
];

const SwitchTeamPersonalBox = ({
  firstName,
  lastName,
  personalAccount,
  photoImage,
  personalUserUrl,
  showCheckmark,
  title,
  hideHeader,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const dismissModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box>
        {!hideHeader && <Box className="switch-team__header">Personal Account</Box>}
        <Box
          className={
            title === "Header"
              ? "switch-team__row-header"
              : "switch-team__row switch-team__row--personal"
          }
        >
          <Box className="switch-team__info-container">
            <AvatarImage
              alt={firstName}
              className="switch-team__image"
              name={firstName}
              src={photoImage}
            />
            <Box className="switch-team__details">
              <Text className="switch-team__name color-text" fontSize={2}>
                {firstName}&apos;s Personal Account
              </Text>
              <Text
                className="switch-team__goal text-tiny"
                color={theme.colors.primary}
                display="block"
              >
                {personalAccount.transferrableFunds.outstandingAmount.formatted} Earned
              </Text>
              {!showCheckmark && (
                <Flex flexDirection="column" mt={3}>
                  <Text fontSize={1}>
                    Season over? Continue earning with a Personal Account.{" "}
                    <Text as="a" color={theme.colors.primary} href={RailsUrl.personalAccountUrl}>
                      Learn more
                    </Text>
                  </Text>
                  <Button as="a" borderRadius="25px" maxWidth="300px" mt={2} onClick={openModal}>
                    <Text color="white">Activate My Personal Account</Text>
                  </Button>
                </Flex>
              )}
            </Box>
          </Box>
          {showCheckmark ? <i className="material-icons d-flex align-items-center">check</i> : null}
        </Box>
      </Box>
      <SwitchTeamModal isOpen={isOpen} size="small" onDismiss={dismissModal}>
        <Box p={4} textAlign="center">
          <H1 color={theme.colors.black}>
            Switching To Your{" "}
            <Text color={theme.colors.black} display="block">
              Personal Account
            </Text>
          </H1>
          <Box my={4}>
            {MODAL_POINTS.map((point) => {
              return (
                <Flex flexWrap="nowrap" key={point} mb={2} mx={3}>
                  <EBox backgroundColor={theme.colors.primary} mr={3} mt={2} />
                  <Text lineHeight="1.25">{point}</Text>
                </Flex>
              );
            })}
          </Box>
          <Flex alignItems="center" flexDirection="column" justifyContent="center">
            <Button
              as="a"
              borderRadius="25px"
              href={personalUserUrl}
              mb={2}
              textDecoration="none"
              width="80%"
            >
              <Text color="white">Activate My Personal Account</Text>
            </Button>
            <Button
              outline
              backgroundColor={theme.colors.white}
              border={theme.borders.primary}
              borderRadius="25px"
              width="80%"
              onClick={dismissModal}
            >
              <Text color={theme.colors.primary}>Go Back</Text>
            </Button>
          </Flex>
        </Box>
      </SwitchTeamModal>
    </>
  );
};

export default SwitchTeamPersonalBox;

SwitchTeamPersonalBox.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  personalAccount: shape({}).isRequired,
  showCheckmark: bool.isRequired,
  title: string.isRequired,
  photoImage: string,
  personalUserUrl: string.isRequired,
  hideHeader: bool,
};

SwitchTeamPersonalBox.defaultProps = {
  photoImage: null,
  hideHeader: false,
};

const EBox = styled(Box)`
  min-width: 8px;
  width: 8px;
  height: 8px;
`;
