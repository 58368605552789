import React from "react";
// import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Row, Column } from "@atoms";
import { CollectionContentItem } from "../CollectionContentItem";
import { CollectionThreeUpFragment } from "./__generated__/CollectionThreeUpFragment";

// == Types ================================================================
interface IProps {
  collection: CollectionThreeUpFragment;
  storefrontId?: string;
}
// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment CollectionThreeUpFragment on Collection {
      id
      assets {
        ...CollectionContentItemFragment
      }
    }
    ${CollectionContentItem.fragments.fields}
  `,
};
// == Component ============================================================

const CollectionThreeUp = ({ collection, storefrontId }: IProps) => {
  if (collection.assets.length < 3) return null;

  return (
    <Row flexWrap="wrap" justifyContent="center">
      {collection.assets.map((asset, index) => (
        <Column autoColumn={false} key={`${asset.image}`} mb={[3, 3, 0]} width={[1, 1, 1 / 3]}>
          <CollectionContentItem
            asset={asset}
            storefrontId={storefrontId}
            collectionId={collection.id}
          />
        </Column>
      ))}
    </Row>
  );
};

CollectionThreeUp.fragments = FRAGMENTS;

export default CollectionThreeUp;

// == Styles ===============================================================
