import React from "react";

interface IProps {
  value: string;
  setTiming: (timing: string) => void;
}

const timings = ["signup", "campaign"];

export function TimingSelect({ value, setTiming }: IProps) {
  return (
    <select
      className="form-control native"
      placeholder="operator"
      value={value}
      onChange={(event) => {
        setTiming(event.target.value);
      }}
    >
      <option value="">(choose)</option>
      {timings.map((timing) => (
        <option key={timing} value={timing}>
          {timing}
        </option>
      ))}
    </select>
  );
}
