import React from "react";
import gql from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import { useQuery } from "@apollo/react-hooks";
import { RouteComponentProps } from "@reach/router";
import { useStoreAppValue, useLoadingDelay } from "@hooks";
import { ErrorLoading } from "@organisms";
import { Loading } from "@components";
import {
  OrganizeMembersViewQuery as OrganizeMemberViewQueryGenerated,
  OrganizeMembersViewQueryVariables,
} from "./__generated__/OrganizeMembersViewQuery";
import OrganizeMembersView from "./OrganizeMembersView";

const ORGANIZE_MEMBERS_VIEW_QUERY = gql`
  query OrganizeMembersViewQuery($id: ID) {
    Campaign(id: $id) {
      id
      ...OrganizeMembersViewFragment
    }
  }
  ${OrganizeMembersView.fragment}
`;

export function OrganizeMembersViewQuery(_props: RouteComponentProps) {
  const { campaignId } = useStoreAppValue();
  const loadingDelayReady = useLoadingDelay();
  const { data, error, loading, refetch } = useQuery<
    OrganizeMemberViewQueryGenerated,
    OrganizeMembersViewQueryVariables
  >(ORGANIZE_MEMBERS_VIEW_QUERY, {
    variables: {
      id: campaignId,
    },
  });

  if (loading) return <Loading />;
  if (!loadingDelayReady) return null;

  const campaign = data?.Campaign;
  if (error || isEmpty(data) || !campaign) return <ErrorLoading />;

  return <OrganizeMembersView campaign={campaign} refetch={refetch} />;
}
