import React from "react";
import Axios from "axios";

interface IProps {
  value: string;
  setDepartment: (department: string) => void;
}

export function DepartmentsSelect({ value, setDepartment }: IProps) {
  const [ready, setReady] = React.useState(false);
  const [departments, setDepartments] = React.useState<string[]>([]);

  React.useEffect(() => {
    async function loadDepartments() {
      const response = await Axios.get("/admin/merchants/departments.json");
      setDepartments(response.data);
      setReady(true);
    }
    loadDepartments();
  }, []);

  if (!ready) return <span>Loading</span>;

  return (
    <select
      className="form-control native"
      placeholder="operator"
      value={value}
      onChange={(event) => {
        setDepartment(event.target.value);
      }}
    >
      <option value="">(choose)</option>
      {departments.map((department) => (
        <option key={department} value={department}>
          {department}
        </option>
      ))}
    </select>
  );
}
