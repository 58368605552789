import React from "react";
import { H2, Text, H3 } from "@atoms";
import { CenteredBoxLayout } from "@molecules";
import BeneficiaryTypeQuery from "../BeneficiaryType/BeneficiaryTypeQuery";
// import styled from "@emotion/styled";
// == Types ================================================================

interface IProps {}

// == Constants ============================================================

BeneficiaryIntro.defaultProps = {};

// == Component ============================================================

export default function BeneficiaryIntro(props: IProps) {
  return (
    <CenteredBoxLayout p={[4, 4, 5]}>
      <H2 mb={3} textAlign="center">
        Submit Team Payment Information
      </H2>
      <Text as="p" mb={3}>
        Let us know a bit more about your team and how you would like to receive your team earnings.
      </Text>
      {/* <H3 as="p" color="black" fontWeight="bold" mb={2}>
        Team Type
      </H3>
      <Text as="p" mb={3}>
        Teams associated with a club / league / school or a registered non-profit need to provide
        additional information but are eligible for exclusive offers.
      </Text> */}
      <BeneficiaryTypeQuery />
    </CenteredBoxLayout>
  );
}

// == Styles ===============================================================
