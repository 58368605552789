import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { oc } from "ts-optchain";
import { Text } from "@atoms";
import { useLoadingDelay } from "@hooks";
import { LoadingBox } from "@molecules";
import { MyTeamSidebarQuery as TMyTeamSidebarQuery } from "./__generated__/MyTeamSidebarQuery";
import MyTeamSidebar from "./MyTeamSidebar";
// import { OccasionListQuery as TOccasionListQuery } from "./__generated__/OccasionListQuery";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

const MY_TEAM_SIDEBAR_QUERY = gql`
  query MyTeamSidebarQuery {
    Campaign {
      id
      joinedFundraisers {
        id
        ...MyTeamSidebarFragment
      }
    }
  }
  ${MyTeamSidebar.fragments.fields}
`;

// == Component ============================================================

export const MyTeamSidebarQuery = () => {
  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery<TMyTeamSidebarQuery>(MY_TEAM_SIDEBAR_QUERY);

  if (error) {
    return <Text>{error.message}</Text>;
  }

  // TODO: add loading for this component
  if (!data || loading || !loadingDelayReady) return <LoadingBox />;
  const fundraisers = oc(data).Campaign.joinedFundraisers();
  if (!fundraisers) return null; // return error

  return <MyTeamSidebar fundraisers={fundraisers} />;
};

// == Styles ===============================================================
