import { useMemo } from "react";
import { RailsUrl } from "@services";
import "./OnboardingTodo.css";
import { useQuery } from "react-apollo";
import { FundsDistributionMethod } from "@types";
import { CAMPAIGN_CHECK_QUERY } from "./constants";

export interface IStepStatus {
  title: string;
  description: string;
  href: string;
  checked: boolean;
}

export const useOnboardingSteps = () => {
  const { data } = useQuery(CAMPAIGN_CHECK_QUERY);

  const stepsStatus = useMemo(() => {
    const isCap = data?.Viewer?.isCaptainable;
    const isPlayerGoal =
      data?.Viewer?.activeCampaign?.fundsDistributionMethod === FundsDistributionMethod.PLAYER;
    const isPersonal = !data?.Viewer?.activeCampaign?.id;

    if (isPersonal) return [];

    const isRosterReady = data?.Viewer?.activeCampaign?.filteredGroups?.length > 0;
    const isInviteReady = data?.Viewer?.activeCampaign?.joinedFundraisersCount > 1;
    const isShopReady = !!data?.Viewer?.activeFundraiser?.amountRaised?.centsAmount;

    if (isCap && isPlayerGoal && isRosterReady && isInviteReady && isShopReady) return [];
    if ((!isCap || !isPlayerGoal) && isInviteReady && isShopReady) return [];

    const rosterStep: IStepStatus | false = isCap &&
      isPlayerGoal && {
        checked: isRosterReady,
        description: "Add players to your team to increase participation and keep funds organized",
        href: RailsUrl.rosterUrl,
        title: "Setup roster",
      };
    const inviteStep: IStepStatus = {
      checked: isInviteReady,
      description: "To maximize your earnings, all your players have to sign up!",
      href: RailsUrl.inviteUrl,
      title: "Invite team",
    };
    const firstShopStep: IStepStatus = {
      checked: isShopReady,
      description: "Make your first purchase and get a $5 Bonus. ",
      href: RailsUrl.firstShopUrl,
      title: "Make your first purchase",
    };

    return [rosterStep, inviteStep, firstShopStep];
  }, [data]);

  return stepsStatus;
};
