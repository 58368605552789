import React from "react";
import { LinkModalFundraiserListQuery } from "@components/common/organisms/linkModalComponents/LinkModalFundraiserList/LinkModalFundraiserListQuery";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  campaign: $FixMe;
  setSelectedFundraiserId: (value: string) => void;
}

// == Constants ============================================================

FundsPageFundraiserTransferRecipientsList.defaultProps = {};

// == Component ============================================================

export default function FundsPageFundraiserTransferRecipientsList({
  setSelectedFundraiserId,
}: IProps) {
  return <LinkModalFundraiserListQuery onFundraiserSelect={setSelectedFundraiserId} />;
}

// == Styles ===============================================================
