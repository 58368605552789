import React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Text } from "@components";
import { useLoadingDelay } from "@hooks";
import { SwitchTeam } from "./SwitchTeam";
import { SwitchTeamLoading } from "./components/SwitchTeamLoading";

interface IProps {
  switchTeamUrl: string;
  personalUserUrl: string;
  editCampaignUrl: string;
  userFundsUrl: string;
  joinCampaignUrl: string;
  newCampaignUrl: string;
  campaignMembersUrl: string;
  rootUrl: string;
  environment: string;
}

const VIEWER_CAMPAIGNS_QUERY = gql`
  query ViewerCampaignsQuery {
    Viewer {
      id
      firstName
      lastName
      avatarImage
      activeStatus
      personalAccount {
        id
        continuityReason
        isAccessible
        transferrableFunds {
          currency
          outstandingAmount {
            formatted(round: 0)
            centsAmount
          }
        }
        continuityPrevCampaign {
          id
          name
          photoImage
          url
          isViewerOwner
          amountRaised {
            formatted(round: 0)
          }
          goal {
            formatted(round: 0)
          }
        }
        amountRaised {
          formatted(round: 0)
        }
      }
      activeCampaign {
        id
        name
        photoImage
        url
        isViewerOwner
        amountRaised {
          formatted(round: 0)
        }
        daysOrHoursLeftLabel
        goal {
          formatted(round: 0)
        }
      }
      runningCampaigns {
        id
        name
        photoImage
        url
        amountRaised {
          formatted(round: 0)
        }
        daysOrHoursLeftLabel
        goal {
          formatted(round: 0)
        }
      }
      endedCampaigns {
        id
        name
        photoImage
        url
        amountRaised {
          formatted(round: 0)
        }
        daysOrHoursLeftLabel
        goal {
          formatted(round: 0)
        }
      }
    }
  }
`;

export const SwitchTeamQuery = ({
  switchTeamUrl,
  personalUserUrl,
  editCampaignUrl,
  userFundsUrl,
  joinCampaignUrl,
  newCampaignUrl,
  campaignMembersUrl,
  rootUrl,
  environment,
}: IProps) => {
  const loadingDelayReady = useLoadingDelay();
  const { loading, data, error } = useQuery(VIEWER_CAMPAIGNS_QUERY);

  if (error) {
    return <Text>{error.message}</Text>;
  }

  if (!data || loading || !loadingDelayReady) return <SwitchTeamLoading />;
  const {
    firstName,
    lastName,
    avatarImage,
    activeStatus,
    activeCampaign,
    personalAccount,
    runningCampaigns,
    endedCampaigns,
  } = data?.Viewer;
  if (!data.Viewer) return null; // return error
  return (
    <SwitchTeam
      activeCampaign={activeCampaign}
      activeStatus={activeStatus}
      campaignMembersUrl={campaignMembersUrl}
      editCampaignUrl={editCampaignUrl}
      endedCampaigns={endedCampaigns}
      environment={environment}
      firstName={firstName}
      joinCampaignUrl={joinCampaignUrl}
      lastName={lastName}
      newCampaignUrl={newCampaignUrl}
      personalAccount={personalAccount}
      personalUserUrl={personalUserUrl}
      photoImage={avatarImage}
      rootUrl={rootUrl}
      runningCampaigns={runningCampaigns}
      switchTeamUrl={switchTeamUrl}
      userFundsUrl={userFundsUrl}
    />
  );
};
