import React from "react";
import ReactPlaceholder from "react-placeholder";
import { RectShape, TextRow, TextBlock } from "react-placeholder/lib/placeholders";
import styled from "@emotion/styled";
import { Box, Container } from "@atoms";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

const OccasionViewLoading = () => {
  const LoadingComponent = () => (
    <Container isMainContainer>
      <Box data-testid="loading-container" mb={4} pb="50%" position="relative">
        <EBanner color="#E0E0E0" />
      </Box>
      <Box mb={2}>
        <TextRow color="#E0E0E0" style={{ width: 200 }} />
      </Box>
      <TextBlock color="#E0E0E0" rows={4} />
    </Container>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1500}
      ready={false}
    >
      {null}
    </ReactPlaceholder>
  );
};

export default OccasionViewLoading;

// == Styles ===============================================================

const EBanner = styled(RectShape)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-right: 0 !important;
`;
