import React, { useEffect, useRef } from "react";
import { useField } from "formik";
import styled from "@emotion/styled";
import { Box, Text } from "@atoms";

// == Types ================================================================

interface IProps {
  label: string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  maxLength?: number;
}

// == Constants ============================================================

const DEFAULT_PROPS = {
  disabled: false,
  maxLength: null,
};

// == Component ============================================================
TextAreaField.defaultProps = DEFAULT_PROPS;

export default function TextAreaField(props: IProps) {
  const inputRef = useRef();
  useEffect(() => {
    Materialize.updateTextFields();
  }, []);
  const { label, disabled } = props;
  const [field, meta] = useField(props);

  return (
    <Box>
      <ELabel className="active" htmlFor={field.name}>
        {label}
      </ELabel>
      <ETextAreaContainer
        className={`input-field ${disabled ? "disabled" : ""} input-field--textarea`}
      >
        <ETextArea
          className="materialize-textarea"
          ref={inputRef}
          {...field}
          {...props}
          disabled={disabled}
          name={field.name}
        />
        {!!props.maxLength && (
          <Box paddingBottom={0} paddingRight={1} textAlign="right">
            <EMaxCountLabel fontSize={0} textAlign="right">
              {inputRef?.current?.value?.length || 0}/{props.maxLength}
            </EMaxCountLabel>
          </Box>
        )}
      </ETextAreaContainer>
      {meta.touched && meta.error ? <span className="standalone-error">{meta.error}</span> : null}
    </Box>
  );
}

// == Styles ===============================================================

const ETextArea = styled.textarea`
  height: 40px !important;
  padding-top: 0 !important;
  overflow-y: auto !important;
`;

const ETextAreaContainer = styled.div`
  padding-top: 10px !important;
`;

const ELabel = styled.label`
  margin-bottom: 0 !important;
`;

const EMaxCountLabel = styled(Text)``;
