import React from "react";
import ReactPlaceholder from "react-placeholder";
import { RectShape, RoundShape } from "react-placeholder/lib/placeholders";
import { Box, Container, Flex } from "@atoms";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

const FavoritesModalListLoading = (props: IProps) => {
  const mb = 3;
  const mt = 3;
  const mr = 3;
  const LoadingComponent = () => (
    <Container>
      <Flex data-testid="loading-container" mb={mb} mt={4}>
        <Box height={50} mr={mr} width={50}>
          <RoundShape color="#E0E0E0" style={{ width: 50 }} />
        </Box>
        <Box height={50} width="100%">
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 50 }} />
        </Box>
      </Flex>
      <Flex mb={mb} mt={mt}>
        <Box height={50} mr={mr} width={50}>
          <RoundShape color="#E0E0E0" style={{ width: 50 }} />
        </Box>
        <Box height={50} width="100%">
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 50 }} />
        </Box>
      </Flex>
      <Flex mb={4} mt={mt}>
        <Box height={50} mr={mr} width={50}>
          <RoundShape color="#E0E0E0" style={{ width: 50 }} />
        </Box>
        <Box height={50} width="100%">
          <RectShape color="#E0E0E0" style={{ width: "100%", height: 50 }} />
        </Box>
      </Flex>
    </Container>
  );

  return (
    <ReactPlaceholder
      showLoadingAnimation
      color="#E0E0E0"
      customPlaceholder={LoadingComponent()}
      delay={1500}
      ready={false}
    />
  );
};

export default FavoritesModalListLoading;

// == Styles ===============================================================
