import constants from "./constants";
import RailsUrl from "./RailsUrl";

export const WHITELISTED_DESTINATION_TYPES = [
  "URL",
  "Link",
  "StoreFront",
  "Collection",
  "Screen",
  "Brand",
  "Occasion",
];

export function destinationParser({ destination, campaignId = null, storefrontId, collectionId }) {
  if (typeof destination !== "string") return null;
  const [type, value] = destination.split("::");
  if (!value) return null;
  if (!WHITELISTED_DESTINATION_TYPES.includes(type)) return null;

  const trackingParams =
    (storefrontId || collectionId) &&
    `&${constants.storefrontParam}=${storefrontId}&${constants.collectionParam}=${collectionId}`;

  if (type === "Link") {
    return `?${constants.linkParam}=${value}${trackingParams}`;
  }
  if (type === "Brand") {
    return `?${constants.brandParam}=${value}${trackingParams}`;
  }
  if (type === "StoreFront") {
    return RailsUrl.storeUrl({ id: value, campaignId });
  }
  if (type === "Collection") {
    return RailsUrl.collectionUrl({ id: value, campaignId, storefrontId });
  }
  if (type === "Occasion") {
    return RailsUrl.occasionUrl(value);
  }
  if (type === "Screen") {
    const screenName = parseScreenDestination({ screen: value, campaignId });
    if (!screenName) return null;
    return screenName;
  }

  if (type === "URL") {
    if (value.includes("brands")) {
      return RailsUrl.brandsUrl({ campaignId });
    }
    return value;
  }

  return null;
}

function parseScreenDestination({
  screen,
  campaignId,
}: {
  screen: string;
  campaignId: string | null;
}) {
  // if (screen === "Store") return "/";
  // if (screen === "InviteTeam") return "/";
  // if (screen === "Share") return "/";
  if (screen === "EditTeam") return `/teams/${campaignId}/more/edit`;
  if (screen === "CloseTeamWizard") return `/teams/${campaignId}/close`;
  if (screen === "BeneficiaryCreate") return `/teams/${campaignId}/guide/beneficiary/intro`;
  return null;
}
