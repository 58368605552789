import React, { useState, useEffect, useCallback, useMemo } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import styled from "@emotion/styled";
import queryString from "query-string";
import { Breakpoint } from "react-socks";
import { BrandSearchListItem } from "@components/common/organisms/brandSearchListItemComponents";
import { useNearbyViewContext, useStoreAppValue } from "@hooks";
import { getPaginatedNodes } from "@utils";
import { Box, Fade, Button } from "@atoms";
import { constants } from "@services";
// == Types ================================================================

interface IProps {
  mapView?: boolean;
}

// == Constants ============================================================

NearbyMap.defaultProps = {
  mapView: false,
};

// == Component ============================================================

export default function NearbyMap({ mapView }: IProps) {
  const [nearbyViewState, nearbyViewDispatch] = useNearbyViewContext();
  const { currentUser } = useStoreAppValue();
  const { results, coordinates, brandHoveredId } = nearbyViewState;
  const [map, setMap] = useState(null);
  const places = useMemo(() => getPaginatedNodes(results), [results]);
  const [center, setCenter] = useState(
    coordinates ? { lat: coordinates.latitude, lng: coordinates.longitude } : null
  );
  const [zoom, setZoom] = useState(13);
  const [infoOpen, setInfoOpen] = useState(false);
  const [clickedMarker, setClickedMarker] = useState(null);
  const [canRedoSearch, setCanRedoSearch] = useState(false);
  const [brandListItem, setBrandListItem] = useState(null);
  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    maxZoom: 18,
    styles: [
      {
        featureType: "poi.business",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  const [coords, setCoords] = useState(null);
  const [findMe, setFindMe] = useState(false);
  const onGetCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setCoordinates);
      setFindMe(true);
    }
    return null;
  };

  const setCoordinates = (position) => {
    const lng = position.coords.longitude;
    const lat = position.coords.latitude;
    setCoords({ latitude: lat, longitude: lng });
    setCenter({ lat, lng });
    nearbyViewDispatch({
      type: "UPDATE_LOCATION",
      coordinates: { latitude: lat, longitude: lng, address: constants.CURRENT_LOCATION_TEXT },
    });
    return coords;
  };

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    if (!coords && currentUser && queryParams.locationFilter !== "ALL") {
      onGetCurrentPosition();
    }

    if (map && places.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      places.map((place) => {
        const latLng = { lat: place.latitude, lng: place.longitude };
        bounds.extend(latLng);
        return place.id;
      });
      map.fitBounds(bounds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places, map, mapView]);

  const onMarkerClick = (e, place) => {
    setClickedMarker(place.id);
    setBrandListItem(place);
  };

  const onDragEnd = useCallback(() => {
    if (map) {
      const newLat = map.center.lat();
      const newLng = map.center.lng();
      setCanRedoSearch(true);
      setCenter({ lat: newLat, lng: newLng });
    }
  }, [map]);

  const onClick = () => {
    setClickedMarker(null);
  };

  const onMapLoad = (map) => {
    setMap(map);
  };

  const onCloseButtonClicked = (e) => {
    e.preventDefault();
    setClickedMarker(null);
    setBrandListItem(null);
  };

  const onClickRedoSearch = () => {
    if (center) {
      nearbyViewDispatch({
        type: "UPDATE_LOCATION",
        coordinates: {
          latitude: center.lat,
          longitude: center.lng,
          address: constants.CURRENT_LOCATION_TEXT,
        },
      });
    }
    setCanRedoSearch(false);
    setBrandListItem(null);
  };

  return (
    <Box height="100%" position="relative" width="100%">
      <GoogleMap
        center={center}
        id="data-example"
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={options}
        zoom={zoom}
        onClick={onClick}
        onDragEnd={onDragEnd}
        onLoad={onMapLoad}
      >
        {places &&
          places.map((place) => {
            const focused = place.id === brandHoveredId || place.id === clickedMarker;
            const size = focused ? 48 : 36;

            let icon: any;
            if (place.sponsored) {
              icon = {
                url: `${place.brand.logoImage}&w=64&h=64&border=2,D9534F&border-radius=32,32,32,32&fit=fillmax&fill=solid&fill-color=FFFFFF&&bg=FFFFFF&pad=8`,
                size: new window.google.maps.Size(size, size),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(size / 2, size / 2),
                scaledSize: new window.google.maps.Size(size, size),
              };
            } else if (focused) {
              icon = "https://flipgive.twic.pics/images/locations/pin-primary-48.svg";
            } else {
              icon = "https://flipgive.twic.pics/images/locations/pin-red-32.svg";
            }

            return (
              <Box key={`${place.id}+${place.latitude}`}>
                <Marker
                  icon={icon}
                  position={{ lat: place.latitude, lng: place.longitude }}
                  // options={{
                  //   opacity: focused ? 1 : place.sponsored ? 0.9 : 0.6,
                  // }}
                  zIndex={focused ? 4 : place.sponsored ? 2 : 1}
                  onClick={(e) => onMarkerClick(e, place)}
                />
              </Box>
            );
          })}
      </GoogleMap>
      <Box bottom={0} left={0} position="absolute" right={0}>
        {canRedoSearch && (
          <Box pb={3} textAlign="center">
            <Button borderRadius="20px" onClick={onClickRedoSearch}>
              Redo Search In This Area
            </Button>
          </Box>
        )}
        {brandListItem && (
          <Fade show>
            <EBrandSearchListItem
              linkBrand
              showCloseButton
              address={brandListItem.address}
              brand={brandListItem.brand}
              locationId={brandListItem.id}
              p={3}
              onCloseButtonClicked={onCloseButtonClicked}
            />
          </Fade>
        )}
      </Box>
    </Box>
  );
}

// == Styles ===============================================================
const EBrandSearchListItem = styled(BrandSearchListItem)`
  background: white;
  width: 100%;
  &:hover {
    background: ${({ theme }) => `${theme.colors.lightPrimary}`};
  }
`;
