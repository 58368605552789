import { useEffect } from "react";
import gql from "graphql-tag";
import { constants, ErrorService } from "@services";
import { useToggle } from "./useToggle";
import { useNotifyPurchaseAnalyticsFragment } from "./__generated__/useNotifyPurchaseAnalyticsFragment";

// == Types ================================================================

// == Constants ============================================================

useNotifyPurchaseAnalytics.fragment = gql`
  fragment useNotifyPurchaseAnalyticsFragment on GiftcardIntent {
    id
    currency
    orderTotal {
      dollarsAmount
    }
    link {
      id
      name
    }
  }
`;

const ADWORDS_ID = "AW-975758655";

// == Functions ============================================================

function notifyFacebookAnalytics(intent: useNotifyPurchaseAnalyticsFragment, viewerId?: string) {
  if (constants.IS_DEV) return;
  if (typeof window.fbq !== "function") return;

  window.fbq("track", "Purchase", {
    content_ids: intent?.link?.id ? [intent.link.id] : [],
    value: intent.orderTotal?.dollarsAmount,
    currency: intent.currency,
    content_type: "product",
    content_name: intent?.link?.name,
    FlipGiveUserID: viewerId,
  });
}

function notifyGoogleAdwords(intent: useNotifyPurchaseAnalyticsFragment, viewerId?: string) {
  if (constants.IS_DEV) return;
  if (typeof window.gtag !== "function") return;

  window.gtag("event", "page_view", {
    send_to: ADWORDS_ID,
    user_id: viewerId,
    dynx_pagetype: "conversion",
    dynx_itemid: intent?.link?.id ? [intent.link.id] : [],
    dynx_totalvalue: intent.orderTotal?.dollarsAmount,
  });
}

// == Exports ==============================================================

export function useNotifyPurchaseAnalytics(
  giftcardIntent?: useNotifyPurchaseAnalyticsFragment,
  viewerId?: string
) {
  const [isCalled, toggleIsCalled] = useToggle(false);
  useEffect(() => {
    try {
      if (isCalled) return;
      if (constants.IS_DEV) return;
      if (!giftcardIntent) return;

      toggleIsCalled(true);
      notifyFacebookAnalytics(giftcardIntent, viewerId);
      notifyGoogleAdwords(giftcardIntent, viewerId);
    } catch (error) {
      if (constants.IS_DEV) console.error(error); // eslint-disable-line no-console
      ErrorService.error(error);
    }
  }, [giftcardIntent, isCalled, toggleIsCalled, viewerId]);
}
