import React from "react";
import gql from "graphql-tag";
import { Link, Location, LocationContext } from "@reach/router";
import { oc } from "ts-optchain";
import styled from "@emotion/styled";
import { BrandCircle } from "@organisms/brandCircleComponents/BrandCircle";
import { Box, Text, Flex, H3, IBoxProps, TouchableOpacity, TimesIcon } from "@atoms";
import { useFullStoryEvents, useUrlParams } from "@hooks";
import { renderIf, constants, RailsUrl, FullStoryEventNames } from "@services";
import { CustomCollectionsIds } from "@components/common/utils/customCollectionsIds";
import { useCookies } from "react-cookie";

// == Types ================================================================

type TLinkCategory = "ONLINE" | "GIFTCARDS" | "IN-STORE" | "REGISTER";

interface IProps extends IBoxProps {
  brand: $FixMe;
  linkBrand: boolean;
  distance?: string | null;
  address?: string;
  showCloseButton?: boolean;
  onCloseButtonClicked?: () => void;
  locationId?: string;
  location?: $FixMe;
  sponsored?: boolean;
  linkCategories: TLinkCategory[];
}

interface IBrandSearchListItemGivesProps {
  enabled: boolean;
  label: string;
  value: string;
}

// == Constants ============================================================
// FRAGMENT should use BrandCircleFragment
const FRAGMENTS = {
  fields: gql`
    fragment BrandSearchListItemFragment on Brand {
      id
      name
      logoImage
      location(coordinates: $coordinates) {
        id
        hasLinkedCard
        address
      }
      maxGiveLinks {
        shopOnline {
          id
          isVariableRate
          giveAmount
        }
        giftcard {
          id
          isVariableRate
          giveAmount
        }
        registerAndEarn {
          id
          isVariableRate
          giveAmount
        }
        inStore {
          id
          isVariableRate
          giveAmount
          positiveGiveAmount
          baselineGiveAmount
        }
      }
      merchant {
        id
        name
        department
      }
      ...BrandCircleFragment
    }
    ${BrandCircle.fragments.fields}
  `,
};

const DEFAULT_PROPS = {
  linkBrand: false,
  address: null,
  distance: null,
  showCloseButton: false,
  onCloseButtonClicked: null,
  sponsored: false,
  location: null,
  linkCategories: ["ONLINE", "GIFTCARDS", "IN-STORE", "REGISTER"],
};

// == Component ============================================================

const BrandSearchListItemGives = ({ enabled, label, value }: IBrandSearchListItemGivesProps) => {
  return (
    <>
      <Text color={enabled ? "primary" : "default"} display="block" fontSize={2}>
        {value}
      </Text>
      <Text fontSize={0}>{label}</Text>
    </>
  );
};

const BrandSearchItem = ({
  distance,
  address,
  brand,
  showCloseButton,
  onCloseButtonClicked,
  sponsored,
  location,
  linkCategories,
  ...rest
}: IProps) => {
  const department = oc(brand).merchant.department();

  return (
    <Flex justifyContent="space-between" {...rest}>
      <Flex>
        <Box maxWidth={100}>
          <BrandCircle brand={brand} imageWidth={40} pad={7} />
        </Box>
        <Box flex={1} ml={3}>
          <Flex>
            <H3>{brand.name}</H3>
            {sponsored && (
              <ESponsorText color="notification" fontSize={0} mx={2} px={2}>
                Sponsored
              </ESponsorText>
            )}
          </Flex>
          {renderIf(department)(<Text display="block">{department}</Text>)}
          <Text fontSize={2}>{address}</Text>
          <Flex mt={2}>
            {linkCategories.includes("ONLINE") && (
              <Box mr={3}>
                <BrandSearchListItemGives
                  enabled={!!brand.maxGiveLinks.shopOnline}
                  label={constants.SEARCH_FILTERS_TYPE_NAMES.ONLINE}
                  value={brand.maxGiveLinks.shopOnline?.giveAmount ?? "-"}
                />
              </Box>
            )}
            {linkCategories.includes("GIFTCARDS") && (
              <Box mr={3}>
                <BrandSearchListItemGives
                  enabled={!!brand.maxGiveLinks.giftcard}
                  label={constants.SEARCH_FILTERS_TYPE_NAMES.GIFTCARDS}
                  value={brand.maxGiveLinks.giftcard?.giveAmount ?? "-"}
                />
              </Box>
            )}

            {linkCategories.includes("REGISTER") && (
              <Box>
                <BrandSearchListItemGives
                  enabled={!!brand.maxGiveLinks.registerAndEarn?.giveAmount}
                  label={constants.SEARCH_FILTERS_TYPE_NAMES.REGISTER}
                  value={brand.maxGiveLinks.registerAndEarn?.giveAmount ?? "-"}
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
      {distance && (
        <Box pl={1}>
          <Text color="primary" fontSize={0}>
            {distance}
          </Text>
        </Box>
      )}
      {showCloseButton && (
        <Box pl={1}>
          <TouchableOpacity onClick={(e) => onCloseButtonClicked(e)}>
            <TimesIcon />
          </TouchableOpacity>
        </Box>
      )}
    </Flex>
  );
};

const BrandSearchListItem = ({
  brand,
  linkBrand,
  address,
  distance,
  showCloseButton,
  onCloseButtonClicked,
  locationId,
  sponsored,
  ...rest
}: IProps) => {
  const [cookies] = useCookies();

  const modalValue = useUrlParams("modal");
  const { sendFullStoryEvent } = useFullStoryEvents();

  if (linkBrand) {
    return (
      <Location>
        {({ location }: LocationContext) => {
          return (
            <ELink
              to={`${location.pathname}${RailsUrl.brandModalUrl({
                id: brand.id,
                locationId,
                collectionId: CustomCollectionsIds.search,
                storefrontId: cookies.storefront_id,
              })}`}
              onClick={() => {
                sendFullStoryEvent(FullStoryEventNames.searchResultClicked, {
                  brandName: brand.name,
                  collectionName: modalValue === "search" ? "search" : undefined,
                });
              }}
            >
              <BrandSearchItem
                address={address}
                brand={brand}
                distance={distance}
                showCloseButton={showCloseButton}
                sponsored={sponsored}
                onCloseButtonClicked={onCloseButtonClicked}
                {...rest}
              />
            </ELink>
          );
        }}
      </Location>
    );
  }

  return (
    <BrandSearchItem
      address={address}
      brand={brand}
      distance={distance}
      showCloseButton={showCloseButton}
      sponsored={sponsored}
      onCloseButtonClicked={onCloseButtonClicked}
      {...rest}
    />
  );
};

BrandSearchListItem.fragments = FRAGMENTS;
BrandSearchListItem.defaultProps = DEFAULT_PROPS;
export default BrandSearchListItem;

// == Styles ===============================================================

const ELink = styled(Link)`
  text-decoration: none;
`;

const ESponsorText = styled(Text)`
  border: ${({ theme }) => theme.borders.sponsor};
  border-radius: ${({ theme }) => `${theme.radii.modal}px`};
  display: flex;
  align-self: flex-start;
`;
