import React, { useState, useEffect, useRef } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import queryString from "query-string";
import styled from "@emotion/styled";
import { Box, Text, MapMarkerIcon, TouchableOpacity } from "@atoms";
import { theme } from "@styles";
import { useStoreAppValue, useNearbyViewContext } from "@hooks";
import { constants } from "@services";

// == Types ================================================================

interface IProps {
  initialAddress: string;
  onSelectCallback: (latitude: number, longitude: number, address: string) => void;
}

interface ILatLng {
  lat: number;
  lng: number;
}

// == Constants ============================================================

NearbyLocationSearch.defaultProps = {
  initialAddress: "",
};

const DEFAULT_SUGGESTIONS = [
  {
    description: constants.CURRENT_LOCATION_TEXT,
    active: false,
  },
  { description: constants.ALL_LOCATIONS_TEXT, active: false },
];
// == Component ============================================================

export default function NearbyLocationSearch({ onSelectCallback, initialAddress }: IProps) {
  const [nearbyViewState, nearbyViewDispatch] = useNearbyViewContext();
  const inputRef = useRef(null);
  const { coordinates, distance } = nearbyViewState;
  const { currency } = useStoreAppValue();
  const queryParams = queryString.parse(window.location.search);
  const initialState =
    queryParams.locationFilter === "ALL"
      ? constants.ALL_LOCATIONS_TEXT
      : constants.CURRENT_LOCATION_TEXT;
  const [address, setAddress] = useState(initialState);
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    if (queryParams.locationFilter === "ALL") {
      setAddress(constants.ALL_LOCATIONS_TEXT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.locationFilter]);

  useEffect(() => {
    // only want to update if the current distance is not set to all locations
    // do not want to update it if the address is already set to current location
    if (
      distance !== constants.DISTANCES_ENUM.ALL_LOCATIONS &&
      address !== constants.CURRENT_LOCATION_TEXT
    ) {
      setAddress(coordinates.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, distance]);

  const setCoordinates = (position) => {
    const lng = position.coords.longitude;
    const lat = position.coords.latitude;
    return nearbyViewDispatch({
      type: "UPDATE_LOCATION",
      coordinates: { latitude: lat, longitude: lng },
    });
  };

  const onGetCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setCoordinates, () => {
        nearbyViewDispatch({ type: "SET_DEFAULT_LOCATION" });
      });
    } else {
      nearbyViewDispatch({ type: "SET_DEFAULT_LOCATION" });
    }
  };

  const handleSelect = async (address: string) => {
    if (inputRef?.current) {
      inputRef.current.blur();
    }
    if (address === constants.ALL_LOCATIONS_TEXT) {
      nearbyViewDispatch({
        type: "UPDATE_LOCATION",
        coordinates,
        locationQuery: address,
      });
      setInputFocused(false);
      return setAddress(address);
    }
    if (address === constants.CURRENT_LOCATION_TEXT) {
      onGetCurrentPosition();
      setInputFocused(false);
      return setAddress(address);
    }
    const results = await geocodeByAddress(address);
    const latLng: ILatLng = await getLatLng(results[0]);
    setAddress(address);
    setInputFocused(false);
    onSelectCallback(latLng.lat, latLng.lng, address);
  };

  const options = {
    location: process.browser ? new window.google.maps.LatLng(43.710075, -79.391299) : null,
    radius: 3000,
    strictBounds: true,
    componentRestrictions: { country: currency === "USD" ? "us" : "ca" },
  };

  return (
    <>
      <PlacesAutocomplete
        searchOptions={options}
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <EPlacesContainer position="relative" width={[1]}>
            <EText color="primary">
              <MapMarkerIcon />
            </EText>
            <ELocationInput
              aria-label="Search Location"
              {...getInputProps({
                placeholder: "Search Location",
              })}
              ref={inputRef}
              onBlur={() => setInputFocused(false)}
              onFocus={() => setInputFocused(true)}
            />
            <EPlacesAutoComplete
              border={suggestions.length > 0 || inputFocused ? "silver" : "none"}
            >
              {(suggestions.length > 0 || inputFocused) &&
                DEFAULT_SUGGESTIONS.map((suggestion) => {
                  return (
                    <ELoadingView
                      bg="white"
                      key={suggestion.description}
                      py={1}
                      {...getSuggestionItemProps(suggestion)}
                    >
                      <Text pl={2}>{suggestion.description}</Text>
                    </ELoadingView>
                  );
                })}
              {loading && (
                <ELoadingView bg="white" pl={2} py={1}>
                  Loading...
                </ELoadingView>
              )}
              {suggestions.map((suggestion) => {
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: `${theme.colors.gray}`, cursor: "pointer" }
                  : { backgroundColor: `${theme.colors.white}`, cursor: "pointer" };
                return (
                  <Box
                    key={suggestion.id}
                    py={1}
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    <Box pl={2}>{suggestion.description}</Box>
                  </Box>
                );
              })}
            </EPlacesAutoComplete>
          </EPlacesContainer>
        )}
      </PlacesAutocomplete>
    </>
  );
}

// == Styles ===============================================================

const EPlacesContainer = styled(Box)`
  position: relative;
`;

const ELocationInput = styled.input`
  margin-bottom: 0 !important;
  height: 40px !important;
  border-radius: 0 !important;
  border-color: ${({ theme }) => theme.colors.gray} !important;
  padding-top: 0 !important;
  width: 50%;
  padding-left: 40px !important;
`;

const EPlacesAutoComplete = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
`;

const ELoadingView = styled(Box)`
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.gray} !important;
  }
`;

const EText = styled(Text)`
  position: absolute;
  top: 13px;
  left: 15px;
`;
