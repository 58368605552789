import React from "react";
import { subDays, startOfDay, endOfDay } from "date-fns";
import { DateRangeSentence } from "./DateRangeSentence";

interface IDisplayDatesProps {
  daysStart: number | null;
  daysStop: number | null;
}

export function DisplayDates({ daysStart, daysStop }: IDisplayDatesProps) {
  const now = new Date();

  let start = null;
  if (daysStart !== undefined && daysStart !== null && !Number.isNaN(daysStart)) {
    start = startOfDay(subDays(now, daysStart));
  }

  let stop = null;
  if (daysStop !== undefined && daysStop !== null && !Number.isNaN(daysStop)) {
    stop = endOfDay(subDays(now, daysStop));
  }

  return <DateRangeSentence now={now} start={start} stop={stop} />;
}
