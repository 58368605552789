import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import isEmpty from "lodash/isEmpty";
import { oc } from "ts-optchain";
import { BrandBlock } from "@organisms/brandBlockComponents/BrandBlock";
import { Box } from "@atoms";
import { CollectionBrandCarousel } from "@organisms";
import { constants } from "@services";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

const BRAND_LIST_FEAUTRED_BRANDS_QUERY = gql`
  query BrandListFeaturedBrandsQuery($id: ID!) {
    Collection(id: $id) {
      id
      brands(first: 5) {
        edges {
          cursor
          node {
            id
            ...BrandBlockFragment
          }
        }
      }
    }
  }
  ${BrandBlock.fragments.fields}
`;

// == Component ============================================================

export const BrandListFeaturedBrands = ({ storefrontId = "" }) => {
  const { loading, data } = useQuery(BRAND_LIST_FEAUTRED_BRANDS_QUERY, {
    variables: {
      id: constants.featuredBrandsCollectionId,
    },
  });

  if (!data || loading) return null;
  const collection = oc(data).Collection();
  if (!collection || isEmpty(collection.brands?.edges)) return null; // return error

  return (
    <Box my={5}>
      <CollectionBrandCarousel collection={collection} storefrontId={storefrontId} />
    </Box>
  );
};

// BrandListFeaturedBrands.fragments = FRAGMENTS;

// == Styles ===============================================================
