import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { format, endOfMonth } from "date-fns";
import { Button, Text } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { analytics, ErrorService, RailsUrl } from "@services";
import {
  CampaignUpdateMutation,
  CampaignUpdateMutationVariables,
} from "./__generated__/CampaignUpdateMutation";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  teamGoal: number | string;
  endDate: Date;
  numTeamMembers: number;
}

// == Constants ============================================================

OnboardingGoalSubmit.defaultProps = {};
const TOGGLE_FAVORITE_MUTATION = gql`
  mutation CampaignUpdateMutation($goal: Int, $deadline: String) {
    CampaignUpdate(goal: $goal, deadline: $deadline) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
      }
    }
  }
`;

const MUTATION_ERROR_MESSAGE = "There was an issue setting your goal";
const MIN_GOAL = 100;
const GOAL_MIN_MESSAGE = `Enter a goal above $${MIN_GOAL}`;

// == Component ============================================================

export function OnboardingGoalSubmit({ teamGoal, endDate, numTeamMembers }: IProps) {
  const { campaignId, isOwner } = useStoreAppValue();
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [toggleFavoriteBrand] = useMutation<
    CampaignUpdateMutation,
    CampaignUpdateMutationVariables
  >(TOGGLE_FAVORITE_MUTATION);

  const trackSetGoal = () => {
    analytics.track.onboardingSetGoal({ campaignId, size: numTeamMembers }, isOwner);
  };

  useEffect(() => {
    const isValidGoal = !Number.isNaN(teamGoal);
    if (isValidGoal && teamGoal < MIN_GOAL) {
      setErrorMsg(GOAL_MIN_MESSAGE);
    } else if (isValidGoal) {
      setErrorMsg("");
    }
  }, [teamGoal]);

  const onClick = () => {
    setSubmitting(true);
    trackSetGoal();
    setErrorMsg("");
    const deadline = format(endOfMonth(endDate), "yyyy-MM-dd");
    toggleFavoriteBrand({
      variables: {
        goal: teamGoal,
        deadline,
      },
    })
      .then(({ data }) => {
        const { CampaignUpdate } = data;
        if (CampaignUpdate.errors) {
          setErrorMsg(MUTATION_ERROR_MESSAGE);
          setSubmitting(false);
        } else {
          window.location.href = RailsUrl.setupTeamUrl(CampaignUpdate.id);
        }
      })
      .catch((e) => {
        ErrorService.error(e);
        setErrorMsg(MUTATION_ERROR_MESSAGE);
        setSubmitting(false);
      });
  };

  const hasGoal = !Number.isNaN(teamGoal) && teamGoal.length !== 0;

  const renderButtonText = () => {
    if (!hasGoal) return "Enter a goal...";
    if (isSubmitting) return "Updating...";
    return "Make This My Team Goal";
  };

  return (
    <>
      <Button
        borderRadius={0}
        disabled={isSubmitting || !hasGoal || errorMsg === GOAL_MIN_MESSAGE}
        py={3}
        width={1}
        onClick={onClick}
      >
        {renderButtonText()}
      </Button>
      {errorMsg && (
        <Text color="danger" display="block" pt={2} textAlign="center">
          {errorMsg}
        </Text>
      )}
    </>
  );
}

// == Styles ===============================================================
