import React from "react";
import gql from "graphql-tag";
import { MemberListItem } from "../MemberListItem";
import {
  MemberListGroupedFragment,
  MemberListGroupedFragment_groups,
  MemberListGroupedFragment_noGroupFundraisers,
  MemberListGroupedFragment_groups_paginatedFundraisers_edges_node,
} from "../MemberListGrouped/__generated__/MemberListGroupedFragment";

// import styled from "@emotion/styled";

// == Types ================================================================

interface IInviteModalData {
  open: boolean;
  template: string | null;
}
interface IProps {
  activeReassignmentMemberId: string | null;
  campaign: MemberListGroupedFragment;
  group?: MemberListGroupedFragment_groups | MemberListGroupedFragment_noGroupFundraisers;
  refetch: () => void;
  setActiveReassignmentMemberId: (value: string | null) => void;
  setInviteModalData: (value: IInviteModalData) => void;
}

interface IFundraiser {
  node: MemberListGroupedFragment_groups_paginatedFundraisers_edges_node | null;
}

// == Constants ============================================================

MemberList.fragments = gql`
  fragment MemberListFragmentNoGroup on CampaignNoGroupFundraisers {
    id
    name
    amountRaised {
      dollarsAmount
      formatted
    }
    paginatedFundraisers {
      edges {
        cursor
        node {
          id
          ...MemberListItemFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  fragment MemberListFragment on Group {
    id
    name
    amountRaised {
      dollarsAmount
      formatted
    }
    paginatedFundraisers {
      edges {
        cursor
        node {
          id
          ...MemberListItemFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${MemberListItem.fragment}
`;

MemberList.defaultProps = {};

// == Functions ============================================================

const fundraiserSorter = (fundraisers) => {
  return fundraisers?.sort(
    (a, b) => b.node.amountRaised.centsAmount - a.node.amountRaised.centsAmount
  );
};

// == Component ============================================================

export default function MemberList({
  activeReassignmentMemberId,
  campaign,
  group,
  refetch,
  setActiveReassignmentMemberId,
}: IProps) {
  const fundraisers = group?.paginatedFundraisers.edges;

  if (!fundraiserSorter(fundraisers)) return null;

  return (
    <>
      {fundraiserSorter(fundraisers).map(({ node: fundraiser }: IFundraiser) => {
        return (
          <MemberListItem
            activeReassignmentMemberId={activeReassignmentMemberId}
            campaign={campaign}
            fundraiser={fundraiser}
            group={group}
            key={fundraiser.id}
            refetch={refetch}
            setActiveReassignmentMemberId={setActiveReassignmentMemberId}
          />
        );
      })}
    </>
  );
}

// == Styles ===============================================================
