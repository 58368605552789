import React from "react";
import { Text } from "@atoms";
import { CollectionNearbyBrandsTitle } from "../CollectionNearbyBrandsTitle";
import { CollectionRenderFragment } from "../CollectionRender/__generated__/CollectionRenderFragment";
import CollectionNearbyBrands from "./CollectionNearbyBrands";
import { useNearbyBrands } from "@components";

// == Types ================================================================

interface IProps {
  collection: CollectionRenderFragment;
}

// == Constants ============================================================

// == Component ============================================================

export default function CollectionNearbyBrandsQuery({ collection }: IProps) {
  const { isLoading, brands, error, isAllowed, onShareGeo } = useNearbyBrands();

  if (error) return <Text>{error?.message}</Text>;
  if (isLoading) return null;
  if (!brands || brands.length === 0) return null;
  return (
    <>
      {!!collection.showTitle && (
        <CollectionNearbyBrandsTitle
          collection={collection}
          altText={!isAllowed && "Share my location"}
          altOnClick={onShareGeo}
        />
      )}
      <CollectionNearbyBrands nearbyBrands={brands} />
    </>
  );
}

// == Styles ===============================================================
