import gql from "graphql-tag";

export default gql`
  fragment ActivityFragment on Activity {
    id
    verb
    time
    utmCampaignId
    visibility
    commentsCount
    likesCount
    details {
      destination
      ctaText
      ctaUrl
      description
      photoImage
      photoSize
      quote
      youtubeId
    }
    actor {
      id
      name
      avatarImage
    }
    viewerHasLiked @skip(if: $publicView)
    comments @skip(if: $publicView) {
      id
      userId
      name
      avatarImage
      createdAt
      comment
    }
    data {
      link {
        id
        name
        giveSentence
        promoDescription
        promoLabel
        promoSecondaryLabel
        promoEndsAt
        image
      }
    }
  }
`;
