import React from "react";

const SvgBell = (props) => (
  // <svg height={25} viewBox="0 0 512 512" width={25} {...props}>
  //   <path
  //     className="bell_svg__active-path"
  //     d="M467.819 431.851l-36.651-61.056a181.486 181.486 0 01-25.835-93.312V224c0-82.325-67.008-149.333-149.333-149.333S106.667 141.675 106.667 224v53.483c0 32.875-8.939 65.131-25.835 93.312l-36.651 61.056a10.665 10.665 0 00-.149 10.731 10.704 10.704 0 009.301 5.419h405.333c3.84 0 7.403-2.069 9.301-5.419a10.665 10.665 0 00-.148-10.731zm-395.648-5.184l26.944-44.907A202.631 202.631 0 00128 277.483V224c0-70.592 57.408-128 128-128s128 57.408 128 128v53.483c0 36.736 9.984 72.789 28.864 104.277l26.965 44.907H72.171z"
  //     data-old_color="#ccc"
  //     data-original="#ccc"
  //     fill="#2AB7BB"
  //   />
  //   <path
  //     className="bell_svg__active-path"
  //     d="M256 0c-23.531 0-42.667 19.136-42.667 42.667v42.667C213.333 91.221 218.112 96 224 96s10.667-4.779 10.667-10.667V42.667c0-11.776 9.557-21.333 21.333-21.333s21.333 9.557 21.333 21.333v42.667C277.333 91.221 282.112 96 288 96s10.667-4.779 10.667-10.667V42.667C298.667 19.136 279.531 0 256 0zM302.165 431.936c-3.008-5.077-9.515-6.741-14.613-3.819-5.099 2.987-6.805 9.536-3.819 14.613 2.773 4.715 4.288 10.368 4.288 15.936 0 17.643-14.357 32-32 32s-32-14.357-32-32c0-5.568 1.515-11.221 4.288-15.936 2.965-5.099 1.259-11.627-3.819-14.613-5.141-2.923-11.627-1.259-14.613 3.819-4.715 8.064-7.211 17.301-7.211 26.731C202.667 488.085 226.581 512 256 512s53.333-23.915 53.376-53.333c0-9.43-2.496-18.667-7.211-26.731z"
  //     data-old_color="#ccc"
  //     data-original="#ccc"
  //     fill="#2AB7BB"
  //   />
  // </svg>
  <svg
    height={25}
    version="1.1"
    viewBox="0 0 140 140"
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
      <path
        className="bell_svg__active-path"
        d="M10,21.75a2.087,2.087,0,0,0,4.005,0"
        fill="none"
        stroke="#29b8bc"
        stroke-25="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="bell_svg__active-path"
        d="M12 3L12 0.75"
        fill="none"
        stroke="#29b8bc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        // className="bell_svg__active-path"
        d="M12,3a7.5,7.5,0,0,1,7.5,7.5c0,7.046,1.5,8.25,1.5,8.25H3s1.5-1.916,1.5-8.25A7.5,7.5,0,0,1,12,3Z"
        fill="none"
        stroke="#29b8bc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default SvgBell;
