import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import produce from "immer";
import { InView } from "react-intersection-observer";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Container, Box, Image, Row, Column, Text, H3 } from "@atoms";
import { pluralize } from "@services";
import { CollectionRender, COLLECTION_TOP_SPACING } from "@organisms";
import { StoreFrontViewFragment } from "./__generated__/StoreFrontViewFragment";
import { theme } from "@styles";
import styled from "@emotion/styled";
import { useCookies } from "react-cookie";

// == Types ================================================================

interface IProps {
  storefront: StoreFrontViewFragment;
  isHome: boolean;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment StoreFrontViewFragment on StoreFront {
      id
      name
      headerImage
      collectionsPaginated(first: 5) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            ...CollectionRenderFragment
          }
        }
      }
      contests {
        edges {
          cursor
          node {
            id
            current
            currency
            position
            goal
            type
            url
            background
            halfWidthBannerUrl
            fullWidthBannerUrlWeb
            fullWidthBannerUrlMobile
            participatingCampaigns
            leaderboard {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    ${CollectionRender.fragments.fields}
  `,
};

const DEFAULT_PROPS = {};

// == Placeholder

const PlaceholderRow = () => (
  <Row mb={4}>
    <Column width={[1 / 2, 1 / 2, 1 / 4]}>
      <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
    </Column>
    <Column width={[1 / 2, 1 / 2, 1 / 4]}>
      <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
    </Column>
    <Column width={[1 / 2, 1 / 2, 1 / 4]}>
      <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
    </Column>
    <Column width={[1 / 2, 1 / 2, 1 / 4]}>
      <RectShape color="#E0E0E0" style={{ width: "100%", height: 150, marginRight: 0 }} />
    </Column>
  </Row>
);

const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CollectionPlaceholder = () => (
  <ReactPlaceholder
    showLoadingAnimation
    color="#E0E0E0"
    customPlaceholder={PlaceholderRow()}
    ready={false}
  />
);

// == More collections

const STORE_FRONT_COLLECTIONS_QUERY = gql`
  query StoreFrontCollections($id: ID!, $afterCursor: String, $carouselItems: Int = 10) {
    StoreFront(id: $id) {
      id
      collectionsPaginated(first: 5, after: $afterCursor) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            ...CollectionRenderFragment
          }
        }
      }
    }
  }
  ${CollectionRender.fragments.fields}
`;

interface IStoreCollectionsProps {
  storeId: string;
  afterCursor: string;
}

const StoreCollections = ({ storeId, afterCursor }: IStoreCollectionsProps) => {
  const { loading, data, error, fetchMore } = useQuery(STORE_FRONT_COLLECTIONS_QUERY, {
    variables: { id: storeId, afterCursor },
  });

  if (loading) {
    return (
      <Box mt={COLLECTION_TOP_SPACING}>
        <CollectionPlaceholder />
      </Box>
    );
  }
  if (error || !data) return null;

  const { collectionsPaginated } = data.StoreFront;

  return (
    <>
      <Box mb={COLLECTION_TOP_SPACING} mt={COLLECTION_TOP_SPACING}>
        {collectionsPaginated.edges.map((edge, index) => {
          return (
            <CollectionRender
              collection={edge.node}
              key={edge.node.id}
              showTopSpacing={index > 0}
              storefrontId={storeId}
            />
          );
        })}
      </Box>

      {collectionsPaginated.pageInfo.hasNextPage ? (
        <InView
          as="div"
          rootMargin="100px 0px 0px 0px"
          onChange={(inView: boolean) => {
            if (inView) {
              fetchMore({
                query: STORE_FRONT_COLLECTIONS_QUERY,
                variables: {
                  id: storeId,
                  afterCursor: collectionsPaginated.pageInfo.endCursor,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return produce(previousResult, (draft) => {
                    draft.StoreFront.collectionsPaginated.pageInfo =
                      fetchMoreResult.StoreFront.collectionsPaginated.pageInfo;

                    draft.StoreFront.collectionsPaginated.edges = [
                      ...previousResult.StoreFront.collectionsPaginated.edges,
                      ...fetchMoreResult.StoreFront.collectionsPaginated.edges,
                    ];
                  });
                },
              });
            }
          }}
        >
          <CollectionPlaceholder />
        </InView>
      ) : null}
    </>
  );
};
const CONTEST_STRINGS = {
  background: "https://flipgive.twic.pics/images/contests/confetti-background.png",
  teal_figure: "https://flipgive.twic.pics/images/contests/teal_figure.png",
  black_figure: "https://flipgive.twic.pics/images/contests/black_figure.png",
  leaderboard_gold: "#F8C35B",
  leaderboard_silver: "#BAC3C8",
  leaderboard_bronze: "#CC875B",
};

const ContestRender = ({ contest }) => {
  const type = contest?.node?.type;
  const halfWidthBannerUrl = contest?.node?.halfWidthBannerUrl;
  const fullWidthBannerUrlWeb = contest?.node?.fullWidthBannerUrlWeb;
  const fullWidthBannerUrlMobile = contest?.node?.fullWidthBannerUrlMobile;
  const url = contest?.node?.url;

  //sanitize ALL contest fields to try to preserve the storefront if bad contest data comes in
  const failedLeaderboardContest =
    type === "Contest::FundsRaised" &&
    !contest?.node?.current &&
    !contest?.node?.participatingCampaigns &&
    !contest?.node?.url &&
    !contest?.node?.leaderboard &&
    contest?.node?.leaderboard.length === 0;

  const failedFundraisingContest =
    "Contest::FundraiserCount" &&
    !contest?.node?.current &&
    !contest?.node?.goal &&
    !contest?.node?.url;

  if (failedLeaderboardContest || failedFundraisingContest) return null;

  return (
    <Box mb={COLLECTION_TOP_SPACING} mt={COLLECTION_TOP_SPACING}>
      {
        <Row>
          <Column autoColumn={false} pb={3} width={1}>
            <Box style={{ cursor: "pointer" }} onClick={() => (window.location.href = url)}>
              {/* needs to get toggled to mobile on smaller bps */}
              <Box display={["block", "none", "none"]}>
                <Image src={fullWidthBannerUrlMobile} style={{ width: "100%" }} />
              </Box>
              <Box display={["none", "block", "block"]}>
                <Image src={fullWidthBannerUrlWeb} style={{ width: "100%" }} />
              </Box>
            </Box>
          </Column>
        </Row>
      }
      <Row flexWrap="wrap">
        <Column autoColumn={false} pb={3} width={[1, 1, 1 / 2]}>
          <Box style={{ cursor: "pointer" }} onClick={() => (window.location.href = url)}>
            <Image src={halfWidthBannerUrl} style={{ width: "100%" }} />
          </Box>
        </Column>
        {type === "Contest::FundraiserCount" && <ContestRow contest={contest} />}
        {type === "Contest::FundsRaised" && <ContestLeaderBoard contest={contest} />}
      </Row>
    </Box>
  );
};

const ContestLeaderBoard = ({ contest }) => {
  const background = contest?.node?.background;
  const current = contest?.node?.current;
  const leaderboard = contest?.node?.leaderboard;
  const participatingCampaigns = contest?.node?.participatingCampaigns;
  const url = contest?.node?.url;

  return (
    <Column pb={[3, 0]} width={[1, 1, 1 / 2]}>
      <Box
        style={{ position: "relative", cursor: "pointer" }}
        mb={3}
        onClick={() => (window.location.href = url)}
      >
        <Box>
          <Image src={background ?? CONTEST_STRINGS.background} style={{ width: "100%" }} />
        </Box>
        <Box
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Row
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%", marginLeft: 0, marginRight: 0 }}
          >
            <Column autoColumn={false} pb={[3, 0]} width={1 / 2}>
              <Box style={{ width: "100%" }}>
                <Text
                  display="block"
                  style={{ width: "100%", color: "black" }}
                  fontWeight="bold"
                  fontSize={["1.25rem", "1.4rem", "1.4rem"]}
                >
                  Your team is
                </Text>
                <Text
                  display="block"
                  style={{ width: "100%", color: theme.colors.primary }}
                  fontWeight="bold"
                  fontSize={["1.25rem", "1.4rem", "1.4rem"]}
                >
                  #{numberWithCommas(current)}{" "}
                  <Text style={{ width: "100%", color: "black" }}>
                    of {numberWithCommas(participatingCampaigns)}
                  </Text>
                </Text>
                <Text
                  display="block"
                  style={{ width: "100%", color: "black" }}
                  fontWeight="bold"
                  fontSize={["1.25rem", "1.4rem", "1.4rem"]}
                >
                  teams.
                </Text>
              </Box>
            </Column>
            <Column autoColumn={false} pb={[3, 0]} width={1 / 2}>
              <Text display="block" fontWeight="bold" style={{ color: "black" }}>
                Current Top Teams:
              </Text>
              {leaderboard &&
                leaderboard.edges.length > 0 &&
                leaderboard.edges.map((team, index) => {
                  if (index === 0)
                    return (
                      <FamilyFeudBanner
                        team={team.node}
                        backgroundColor={CONTEST_STRINGS.leaderboard_gold}
                        index={index}
                      />
                    );
                  if (index === 1)
                    return (
                      <FamilyFeudBanner
                        team={team.node}
                        backgroundColor={CONTEST_STRINGS.leaderboard_silver}
                        index={index}
                      />
                    );
                  if (index === 2)
                    return (
                      <FamilyFeudBanner
                        team={team.node}
                        backgroundColor={CONTEST_STRINGS.leaderboard_bronze}
                        index={index}
                      />
                    );
                })}
            </Column>
          </Row>
        </Box>
      </Box>
    </Column>
  );
};

const FamilyFeudBanner = ({ team, backgroundColor, index }) => {
  const breakpoints = {
    sm: 480,
    md: 768,
    lg: 970,
    xl: 1200,
  };

  const mq = Object.keys(breakpoints)
    .map((key) => [key, breakpoints[key]])
    .reduce((prev, [key, breakpoint]) => {
      prev[key] = `@media (min-width: ${breakpoint}px)`;
      return prev;
    });

  const EText = styled.div`
    ${mq["sm"]} {
      width: 
      max-width: 750px;
    }
    ${mq["md"]} {
      width: 110px;
      max-width: 960px;
    }
    ${mq["lg"]} {
      width: 150px;
      max-width: 1170px;
    }
    ${mq["xl"]} {
      width: 180px;
    }
  `;

  return (
    <Box mt={1} mb={1}>
      <Row
        flexWrap="nowrap"
        style={{
          backgroundColor,
          marginLeft: "0px",
          marginRight: "0px",
          borderRadius: "4px",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <Column
          autoColumn={false}
          width={1 / 6}
          style={{
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          <Text style={{ color: "black" }}>#{index + 1}</Text>
        </Column>
        <Column
          autoColumn={false}
          width={5 / 6}
          style={{
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          <EText
            style={{
              color: "white",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {team.name}
          </EText>
        </Column>
      </Row>
    </Box>
  );
};

const ContestRow = ({ contest }) => {
  const background = contest?.node?.background;
  const current = contest?.node?.current;
  const goal = contest?.node?.goal;
  const url = contest?.node?.url;

  const lowerTest = current === null ? 0 : current;

  const maxCurrent = lowerTest > goal ? goal : current;

  const remainingTeamMembersNeeded = goal - maxCurrent;

  const widthPer = 100 / goal;

  return (
    <Column autoColumn={false} pb={[3, 0]} width={[1, 1, 1 / 2]}>
      <Box
        style={{ position: "relative", cursor: "pointer" }}
        mb={3}
        onClick={() => (window.location.href = url)}
      >
        <Box>
          <Image src={background ?? CONTEST_STRINGS.background} style={{ width: "100%" }} />
        </Box>
        <Box
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            paddingTop: "5%",
          }}
        >
          <Box style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
            <Text
              display="block"
              style={{ width: "100%", color: "black" }}
              fontWeight="bold"
              fontSize={["1.25rem", "1.4rem", "1.4rem"]}
            >
              Your team has <span style={{ color: theme.colors.primary }}>{current}</span> new{" "}
              {pluralize("shopper", current, { showCount: false })}.{" "}
            </Text>
            <Text
              display="block"
              style={{ width: "100%", color: "black" }}
              fontWeight="bold"
              fontSize={["1.25rem", "1.4rem", "1.4rem"]}
            >
              <span style={{ color: theme.colors.primary }}>{remainingTeamMembersNeeded}</span> more
              to go!
            </Text>
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              transform: "translate(0%, 50%)",
            }}
          >
            <Row
              flexWrap="nowrap"
              style={{ width: ["90%", "80%", "80%"], marginLeft: "20px", marginRight: "20px" }}
            >
              {[...Array(current)].map((member, index) => (
                <Column autoColumn={false} pb={[3, 0]} width={`${widthPer}%`} px={0}>
                  <Image
                    key={index}
                    style={{
                      backgroundSize: "cover",
                      width: "35%",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={CONTEST_STRINGS.teal_figure}
                  />
                </Column>
              ))}
              {[...Array(remainingTeamMembersNeeded)].map((member, index) => (
                <Column autoColumn={false} pb={[3, 0]} width={`${widthPer}%`} px={0}>
                  <Image
                    key={index}
                    style={{
                      backgroundSize: "cover",
                      width: "35%",
                      textAlign: "center",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={CONTEST_STRINGS.black_figure}
                  />
                </Column>
              ))}
            </Row>
          </Box>
        </Box>
      </Box>
    </Column>
  );
};

// == Component ============================================================

const StoreFrontView = ({ storefront }: IProps) => {
  const { collectionsPaginated, id, contests } = storefront;
  const [_cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    if (id) {
      removeCookie("storefront_id");
      setCookie("storefront_id", id, { path: "/" });
    }
    return () => {
      removeCookie("storefront_id");
    };
  }, [id]);

  return (
    <Container isMainContainer pt={0}>
      {!!storefront.headerImage && (
        <Box mb={COLLECTION_TOP_SPACING}>
          <Image alt={`${storefront.name}-content-item`} src={storefront.headerImage} />
        </Box>
      )}
      {/* should now show on queried storefronts, since Ale has contests returning an empty array no matter what, we check length of returned contest */}
      {contests &&
        contests.edges.length > 0 &&
        contests.edges.map(
          (contest) =>
            contest.node &&
            contest.node.position === "TOP" && (
              <ContestRender contest={contest} key={contest.node.id}></ContestRender>
            )
        )}
      {collectionsPaginated?.edges?.map(
        (edge, index) =>
          edge && (
            <CollectionRender
              collection={edge.node}
              key={edge.node.id}
              showTopSpacing={index > 0}
              storefrontId={id}
            />
          )
      )}
      {collectionsPaginated.pageInfo.hasNextPage ? (
        <StoreCollections
          afterCursor={collectionsPaginated.pageInfo.endCursor}
          key={id}
          storeId={id}
          contests={contests}
        />
      ) : null}
      {contests &&
        contests.edges.length > 0 &&
        contests.edges.map(
          (contest) =>
            contest.node &&
            contest.node.position === "BOTTOM" && (
              <ContestRender contest={contest} key={contest.node.id}></ContestRender>
            )
        )}
    </Container>
  );
};

StoreFrontView.fragments = FRAGMENTS;
StoreFrontView.defaultProps = DEFAULT_PROPS;

export default StoreFrontView;

// == Styles ===============================================================
