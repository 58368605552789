import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { CardForm, Text, Image, Box, TouchableOpacity, Button } from "@components/common/atoms";
import { TextField, PrettyRadioOption } from "@molecules";
import { useStoreAppValue, useToggle } from "@hooks";
import { constants } from "@services";
// import { CHECKOUT_VIEW_QUERY } from "../CheckoutView/CheckoutViewQuery";
import { CheckoutRemoveCardModal } from "../CheckoutRemoveCardModal";
import { CheckoutViewStripeFields } from "../CheckoutViewStripeFields";

// == Types ================================================================

interface ISavedCardsValues {
  id: string;
  last4: string;
  expiryMonth: number;
  expiryYear: number;
}

interface IProps {
  postalCodeErrorMessage: string | null;
  dispatch: () => void;
  isValid: boolean;
  savedCards: ISavedCardsValues[];
  shouldSaveCard: boolean;
  forceSave: boolean;
}

interface IStripeForm extends IProps {
  setToken: (token: string) => void;
  stripe: $FixMe;
}

// == Constants ============================================================
CheckoutPaymentForm.defaultProps = {};

CheckoutPaymentForm.fragments = gql`
  fragment CheckoutPaymentFormFragment on Viewer {
    id
    stripeCards(currency: $currency) {
      id
      brand
      default
      last4
      expiryFormatted
      expiryMonth
      expiryYear
    }
  }
`;

const creditCardLogoURI = (creditCardType) => {
  switch (creditCardType) {
    case "Visa":
      return "visa";
    case "MasterCard":
      return "mastercard";
    case "American Express":
      return "american_express";
    default:
      return false;
  }
};

// == Component ============================================================

export default function CheckoutPaymentForm({
  dispatch,
  savedCards,
  postalCodeErrorMessage,
  isValid,
  shouldSaveCard,
  forceSave,
}: IStripeForm) {
  let defaultCardId = "";
  if (savedCards) {
    const defaultCard = savedCards.find((card) => card.default);
    if (defaultCard) defaultCardId = defaultCard.id;
  }

  const initialState = {
    cardId: defaultCardId,
    postalCode: "",
    shouldSaveCard,
  };

  const { currency, currentUser } = useStoreAppValue();
  const [addNewCard, setAddNewCard] = useState(savedCards.length === 0);
  const [editingForm, toggleEdit] = useToggle(false);
  const [removeCardModal, setRemoveCardModal] = useState();
  const [stateFormProps, setFormProps] = useState();

  const onAddNewCardClick = (formProps) => {
    const { setFieldValue } = formProps;
    dispatch({ type: "REMOVE_VALID_FORM", formName: "PAYMENT_FORM" });
    setFieldValue("cardId", "");
    setAddNewCard(true);
  };

  const onSaveNewCardClick = ({ token, saveCard }) => {
    const { setFieldValue } = stateFormProps;
    dispatch({ type: "ADD_NEW_CARD", token, shouldSaveCard: saveCard });
    dispatch({ type: "ADD_VALID_FORM", formName: "PAYMENT_FORM" });
    setFieldValue("cardId", token.id);
  };

  const onCardClick = (card, setFieldValue, submitForm) => {
    const {
      values: { cardId },
    } = stateFormProps;

    setAddNewCard(false);

    if (card.id !== cardId) {
      setFieldValue("postalCode", "");
      dispatch({ type: "SET_POSTAL_CODE", postalCode: "" });
    }

    if (card.id.includes("tok")) {
      dispatch({ type: "SET_CARD_ID", cardId: card.id });
      dispatch({ type: "ADD_VALID_FORM", formName: "PAYMENT_FORM" });
    } else {
      dispatch({ type: "REMOVE_VALID_FORM", formName: "PAYMENT_FORM" });
      submitForm();
    }
  };

  const openRemoveCardModal = (id) => setRemoveCardModal(id);
  const closeRemoveCardModal = () => {
    setRemoveCardModal("");
    toggleEdit();
  };

  const onEditClick = () => {
    toggleEdit();
  };

  return (
    <CardForm
      checkMark={isValid}
      editButtonText={editingForm ? "Done" : "Edit"}
      heading="Payment Method"
      showEditButton={savedCards.length > 0 && currentUser}
      onEditClick={onEditClick}
    >
      <Formik
        initialValues={initialState}
        validationSchema={Yup.object({
          postalCode: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          if (constants.POSTAL_ZIP_REGEX.test(values.postalCode)) {
            dispatch({ type: "SET_CARD_ID", cardId: values.cardId });
            dispatch({ type: "ADD_VALID_FORM", formName: "PAYMENT_FORM" });
            dispatch({ type: "SHOULD_SAVE_CARD", shouldSaveCard: values.saveCard });
            dispatch({ type: "SET_POSTAL_CODE", postalCode: values.postalCode });
          }
          setSubmitting(false);
        }}
      >
        {(formProps) => {
          const { submitForm, setFieldValue, values } = formProps;
          if (!stateFormProps) setFormProps(formProps);
          return (
            <Form className="ElementsApp">
              {savedCards.map((card) => {
                return (
                  <Box key={card.id} position="relative">
                    <PrettyRadioOption
                      activeComponent={
                        !card.id.includes("tok") && (
                          <EPostalContainerBox ml="55px" mt={2}>
                            <ETextField
                              customErrorText
                              errorMessage={postalCodeErrorMessage}
                              label={
                                currency === "USD"
                                  ? "Enter ZIP to Verify"
                                  : "Enter Postal Code to Verify"
                              }
                              maxLength={6}
                              name="postalCode"
                              placeholder=""
                              type="text"
                              onChange={(e) => {
                                const { value } = e.target;
                                if (constants.POSTAL_ZIP_REGEX.test(value)) {
                                  setFieldValue("postalCode", value);
                                  submitForm();
                                } else {
                                  dispatch({
                                    type: "REMOVE_VALID_FORM",
                                    formName: "PAYMENT_FORM",
                                  });
                                }
                              }}
                            />
                          </EPostalContainerBox>
                        )
                      }
                      label={`${card.brand} ending in ${card.last4}`}
                      leftContent={
                        <EImage
                          alt={card.brand}
                          src={`https://flipgive.twic.pics/images/site/linked-cards/${creditCardLogoURI(
                            card.brand
                          )}.png?twic=v1/refit-inside=200x200@center/background=fff`}
                        />
                      }
                      name="cardId"
                      // value and value passed to the on click have to match
                      secondaryLabel={`${card.expiryMonth}/${card.expiryYear
                        .toString()
                        .substr(-2)}`}
                      value={card.id}
                      onClick={() => onCardClick(card, setFieldValue, submitForm)}
                    />
                    {editingForm && (
                      <>
                        <ERemoveButtonContainer>
                          <ERemoveButton
                            variant="clear"
                            onClick={() => openRemoveCardModal(card.id)}
                          >
                            Remove
                          </ERemoveButton>
                        </ERemoveButtonContainer>
                        {removeCardModal === card.id && (
                          <CheckoutRemoveCardModal
                            card={card}
                            closeRemoveCardModal={closeRemoveCardModal}
                            currency={currency}
                            currentCardId={values.cardId}
                            dispatch={dispatch}
                            formProps={formProps}
                            removeCardModal={removeCardModal}
                            setFieldValue={setFieldValue}
                          />
                        )}
                      </>
                    )}
                  </Box>
                );
              })}
              {savedCards.length > 0 && (
                <TouchableOpacity
                  containerProps={{ style: { width: "100%" } }}
                  onTouch={() => onAddNewCardClick(formProps)}
                >
                  <Box mb={2} textAlign="center">
                    <Text color="primary">Add New Card</Text>
                  </Box>
                </TouchableOpacity>
              )}
            </Form>
          );
        }}
      </Formik>
      {(addNewCard || savedCards.length === 0) && (
        <CheckoutViewStripeFields
          forceSave={forceSave}
          setAddNewCard={setAddNewCard}
          onSaveNewCardClick={onSaveNewCardClick}
        />
      )}
    </CardForm>
  );
}

// == Styles ===============================================================

const EImage = styled(Image)`
  height: 40px;
  width: 40px;
`;
const ETextField = styled(TextField)`
  margin-bottom: 5px !important;
`;

const ERemoveButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 9px;
  background: white;
  border-width: 0;
  &:focus {
    background-color: white;
  }
`;

const EPostalContainerBox = styled(Box)`
  .input-field {
    width: 200px;
  }
`;

const ERemoveButtonContainer = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
