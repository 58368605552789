import React, { useMemo } from "react";
import gql from "graphql-tag";
import truncate from "lodash/truncate";
import { navigate } from "@reach/router";
import { format, parseISO } from "date-fns";
import { useBreakpoint } from "@hooks";
import { Flex, Box, Text, H3, TouchableOpacity } from "@atoms";
import { constants, analytics } from "@services";
import { BrandCircle } from "../../brandCircleComponents";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  linkPromotion: $FixMe;
  metaInfo?: {
    collectionName?: string;
    collectionId?: string;
    storefrontId?: string;
  };
}

// == Constants ============================================================

LinkPromotionBlock.fragment = gql`
  fragment LinkPromotionBlockFragment on LinkPromotion {
    id
    endsAt
    description
    displayDescription
    link {
      id
      giveSentence
      type
      defaultBrand {
        id
        ...BrandCircleFragment
      }
    }
  }
  ${BrandCircle.fragments.fields}
`;

LinkPromotionBlock.defaultProps = {};

// == Component ============================================================

export function LinkPromotionBlock({ linkPromotion, metaInfo }: IProps) {
  const breakpoint = useBreakpoint();
  const description = useMemo(() => {
    let length;
    switch (breakpoint) {
      case "xs":
        length = 100;
        break;
      case "sm":
        length = 50;
        break;
      case "md":
        length = 100;
        break;
      case "lg":
        length = 150;
        break;
      default:
        length = 24;
        break;
    }
    return truncate(linkPromotion.displayDescription, { length, separator: " " });
  }, [breakpoint, linkPromotion.displayDescription]);
  if (!linkPromotion.link) return null;

  const onClick = () => {
    analytics.track.clickOffer({
      promotionId: linkPromotion.id,
      analyticsLocation: "store",
      linkId: linkPromotion.link.id,
      linkType: linkPromotion.link.type,
      ...metaInfo,
    });
    navigate(
      `?${constants.linkParam}=${linkPromotion.link.id}&${constants.linkPromotionParam}=${linkPromotion.id}&${constants.collectionParam}=${metaInfo?.collectionId}&${constants.storefrontParam}=${metaInfo?.storefrontId}`
    );
  };

  return (
    <TouchableOpacity onTouch={onClick}>
      <Flex alignItems="flex-start">
        <BrandCircle
          brand={linkPromotion.link.defaultBrand}
          height={120}
          imageWidth={120}
          width={120}
        />
        <Box ml={3}>
          <H3
            dangerouslySetInnerHTML={{ __html: description }}
            fontSize={2}
            fontWeight="bold"
            lineHeight="title"
          />
          <Text color="primary" display="block" fontSize={2} mb={1} mt={2}>
            {linkPromotion.link.giveSentence}
          </Text>
          <Text textStyle="legal">Exp. {format(parseISO(linkPromotion.endsAt), "MM/dd/yyyy")}</Text>
        </Box>
      </Flex>
    </TouchableOpacity>
  );
}

// == Styles ===============================================================
