import React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NOTIFICATIONS_QUERY, NOTIFICATIONS_COUNT_QUERY } from "../queries";

const MARK_ALL_READ_MUTATION = gql`
  mutation MarkAllReadMutation {
    ActivityMarkAllNotificationsRead
  }
`;

const clickHandler = (e, markAllReadMutation) => {
  e.preventDefault();
  markAllReadMutation({
    refetchQueries: [
      { query: NOTIFICATIONS_QUERY, variables: { first: 25 } },
      { query: NOTIFICATIONS_COUNT_QUERY },
    ],
  });
};

const MarkAllRead = () => (
  <Mutation mutation={MARK_ALL_READ_MUTATION}>
    {(markAllReadMutation) => (
      <a
        className="block-text notifications-content__mark-read default"
        role="button"
        tabIndex={0}
        onClick={(e) => clickHandler(e, markAllReadMutation)}
      >
        Mark all as read
      </a>
    )}
  </Mutation>
);

export default MarkAllRead;
