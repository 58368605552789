import React from "react";
import { useQuery } from "react-apollo";
import { useCookies } from "react-cookie";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import { useStoreAppValue } from "@hooks";
// import styled from "@emotion/styled";
// import { BeneficiaryLoading } from "../BeneficiaryLoading";
import { RailsUrl } from "@services";
import { BeneficiaryLoading } from "../BeneficiaryLoading";
import {
  CampaignBeneficiaryQueryVariables,
  CampaignBeneficiaryQuery,
} from "./__generated__/CampaignBeneficiaryQuery";
import { BeneficiaryForm } from ".";

// == Types ================================================================

interface IProps {}

// == Constants ============================================================

BeneficiaryFormQuery.defaultProps = {};
export const CAMPAIGN_BENEFICIARY_QUERY = gql`
  query CampaignBeneficiaryQuery($id: ID) {
    Campaign(id: $id) {
      id
      currency
      category {
        id
      }
      beneficiary {
        id
        city
        contactName
        dateOfBirth
        email
        firstName
        hwUserToken
        hwUserVerificationStatus
        hwAccountInfo
        hwAccountToken
        id
        lastName
        legalName
        paymentType
        phoneNumber
        postalCode
        province
        street1
        street2
        taxId
        realTeamName
        teamName
        teamType
        website
        plaidAccountId
        plaidAccountInfo
        googlePlacesId
      }
      organization {
        id
        name
        city
        country
        legalName
        postalCode
        province
        street1
      }
    }
  }
`;

// == Component ============================================================

export default function BeneficiaryFormQuery(props: IProps) {
  const { campaignId } = useStoreAppValue();
  const [cookies] = useCookies(["beneficiaryType"]);

  const { data, loading } = useQuery<CampaignBeneficiaryQuery, CampaignBeneficiaryQueryVariables>(
    CAMPAIGN_BENEFICIARY_QUERY,
    {
      variables: {
        id: campaignId,
      },
      fetchPolicy: "no-cache",
      onCompleted: (resp) => {
        if (!cookies.beneficiaryType && !resp?.Campaign?.beneficiary) {
          navigate(RailsUrl.beneficiaryIntroUrl(campaignId), { replace: true });
        }
      },
    }
  );

  if (!data || loading) return <BeneficiaryLoading />;

  const beneficiary = data?.Campaign?.beneficiary;

  return <BeneficiaryForm beneficiary={beneficiary} />;
}

// == Styles ===============================================================
