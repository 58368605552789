import React from "react";
import styled from "@emotion/styled";
import { Box, Text, ButtonUnstyled } from "@atoms";

// == Types ================================================================

interface IProps extends HTMLButtonElement {
  text?: string;
  isActive: boolean;
}

// == Constants ============================================================

// == Component ============================================================

const LinkPill = ({ text, isActive, children, ...rest }: IProps) => {
  return (
    <ELinkPillContainer borderRadius="100px" isActive={isActive} {...rest}>
      {text ? <Text color={isActive ? "white" : "primary"}>{text}</Text> : children}
    </ELinkPillContainer>
  );
};

export default LinkPill;

// == Styles ===============================================================

const ELinkPillContainer = styled(ButtonUnstyled)`
  cursor: pointer;
  display: inline-block;
  padding: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  border-radius: 100px;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.white)};
  &:focus {
    background: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.white)};
  }
`;
