import React from "react";
// import styled from "@emotion/styled";
import { Link } from "@reach/router";
// import { ILink } from "@molecules";
import gql from "graphql-tag";
import { oc } from "ts-optchain";
import { destinationParser, canLinkWithRouter } from "@services";
import { useStoreAppValue } from "@hooks";
import { Box, Text, Heading, Image, TouchableOpacity } from "@atoms";
import { CollectionContentItemFragment } from "./__generated__/CollectionContentItemFragment";

// == Types ================================================================

interface IProps {
  onCollectionClick?: () => void;
  asset: CollectionContentItemFragment;
  storefrontId?: string;
  collectionId?: string;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment CollectionContentItemFragment on CollectionAsset {
      destination
      description
      title
      image
      asset {
        small
        large
        destination
      }
    }
  `,
};
const DEFAULT_PROPS = {
  textPosition: "center",
  height: "200px",
};

// == Component ============================================================

const CollectionContentItem = ({
  asset,
  onCollectionClick,
  storefrontId,
  collectionId,
}: IProps) => {
  const { campaignId, featureFlags } = useStoreAppValue();

  if (!asset) return null;
  const destination = oc(asset).destination();
  const bannerAsset = oc(asset).asset();
  const image = oc(asset).image();

  const imageSrc = (): string => {
    if (!!bannerAsset && !image) {
      return "";
    }
    if (window.innerWidth <= 768 && !!oc(bannerAsset).small() && image) {
      const small = oc(bannerAsset).small(image);
      return small;
    }
    if (window.innerWidth > 768 && !!oc(bannerAsset).large() && image) {
      const large = oc(bannerAsset).large(image);
      return large;
    }
    if (image) {
      return image;
    }
    return "";
  };

  let href = "#";
  let containerProps = { style: { width: "100%" } };
  let canLinkRouter = false;

  if (destination) {
    const newHref = destinationParser({ destination, campaignId, storefrontId, collectionId });
    if (newHref) {
      href = newHref;
      canLinkRouter = canLinkWithRouter(href, featureFlags);
      if (!canLinkRouter) {
        containerProps = { as: "a", href, type: null, ...containerProps };
      }
    }
  }
  const renderContent = () => {
    return (
      <Box>
        {!!imageSrc() && (
          <Image
            alt={`${imageSrc()}-content-item`}
            loading="lazy"
            src={imageSrc()}
            onClick={onCollectionClick}
          />
        )}
        <Box>
          {!!asset.title && (
            <Heading as="h3" fontSize={[2, 3]} mt={2}>
              {asset.title}
            </Heading>
          )}
          {!!asset.description && (
            <Text as="p" fontSize={[0, 1]} mt={1}>
              {asset.description}
            </Text>
          )}
        </Box>
      </Box>
    );
  };

  if (canLinkRouter) {
    return <Link to={href}>{renderContent()}</Link>;
  }
  return <TouchableOpacity containerProps={containerProps}>{renderContent()}</TouchableOpacity>;
};

CollectionContentItem.defaultProps = DEFAULT_PROPS;
CollectionContentItem.fragments = FRAGMENTS;

export default CollectionContentItem;

// == Styles ===============================================================
