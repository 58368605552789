import React, { ReactChild } from "react";
import { Box, IBoxProps, Text } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends IBoxProps {
  label?: string;
  children?: ReactChild;
}

// == Constants ============================================================

// == Component ============================================================

const LinkBlockPromoLabel = ({ label, children, ...rest }: IProps) => {
  return (
    <Box bg="linkPromo" borderRadius="0 5px 5px 0" pl={1} {...rest}>
      <Text ellipsis uppercase color="linkPromoText" display="block" fontSize={[0]} px={2} py={1}>
        {label || children}
      </Text>
    </Box>
  );
};

export default LinkBlockPromoLabel;

// == Styles ===============================================================
