class Settings {
  constructor() {
    this.environment = "production";
    this.apiUrl = "https://cached-api.flipgive.com";
    this.currentUser = null;
    this.siteId = null;
    this.campaignId = null;
  }

  setDefaultsFromProps = (props) => {
    this.environment = props.environment || this.environment;
    this.apiUrl = props.apiUrl || this.apiUrl;
    this.currentUser = props.currentUser;
    this.siteId = props.siteId;
    this.campaignId = props.campaignId;
  };
}

const settings = new Settings();
export default settings;
