import React from "react";
import gql from "graphql-tag";
import { Box, Flex, Text } from "@atoms";
import { OrganizationFundsDistributionMethod } from "@types";
import { FundsPageWithdrawalsChart } from "../FundsPageWithdrawalsChart";
import { FundsPageWithdrawalsLegend } from "../FundsPageWithdrawalsLegend";
import { FundsPageWithdrawalsButton } from "../FundsPageWithdrawalsButton";
import { FundsPageWithdrawalsList } from "../FundsPageWithdrawalsList";
import {
  FundsPageWithdrawalsFragment,
  FundsPageWithdrawalsFragment_payments,
} from "./__generated__/FundsPageWithdrawalsFragment";

// == Types ================================================================

interface IProps {
  campaign: FundsPageWithdrawalsFragment;
  withdrawalsList: FundsPageWithdrawalsFragment_payments;
}

// == Constants ============================================================

FundsPageWithdrawals.fragments = gql`
  fragment FundsPageWithdrawalsFragment on Campaign {
    id
    name
    hasBeneficiary
    handlesMoney
    isViewerOwner @include(if: $hasCurrentUser)
    organization {
      id
      name
      fundsDistributionMethod
    }
    ...FundsPageWithdrawalsChartFragment
    ...FundsPageWithdrawalsLegendFragment
    ...FundsPageWithdrawalsListFragment
    ...FundsPageWithdrawalsButtonFragment
  }
  ${FundsPageWithdrawalsChart.fragments}
  ${FundsPageWithdrawalsLegend.fragments}
  ${FundsPageWithdrawalsList.fragments}
  ${FundsPageWithdrawalsButton.fragments}
`;

// == Component ============================================================

export default function FundsPageWithdrawals({ campaign, withdrawalsList }: IProps) {
  return (
    <Box bg="lightBlueBg" mt={4}>
      <FundsPageWithdrawalsChart campaign={campaign} />
      <FundsPageWithdrawalsLegend campaign={campaign} />
      {!(
        campaign?.organization?.fundsDistributionMethod ===
        OrganizationFundsDistributionMethod.ORGANIZATION
      ) ? (
        <>
          <FundsPageWithdrawalsButton campaign={campaign} />
          <FundsPageWithdrawalsList campaign={campaign} withdrawalsList={withdrawalsList} />
        </>
      ) : (
        <Flex bg="white" flexDirection="column" mb={4}>
          <Box bg="lightBg" padding={3}>
            <Text fontSize={[1, 1, 2]}>
              All funds earned will be managed and distributed by{" "}
              {campaign?.organization?.name ?? "organization"}.
            </Text>
          </Box>
        </Flex>
      )}
    </Box>
  );
}

// == Styles ===============================================================
