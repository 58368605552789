import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesome } from "../../FontAwesome";
import { IFlexProps } from "../../Flex";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  containerProps?: IFlexProps;
  half?: boolean;
  outline?: boolean;
}
// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export const StarIcon = ({ half, outline, containerProps, ...rest }: IProps) => {
  let name: IconProp = "star";
  if (outline) {
    name = ["far", "star"];
  } else if (half) {
    name = "star-half-alt";
  }

  return <FontAwesome containerProps={containerProps} data-testid={name} icon={name} {...rest} />;
};

StarIcon.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
