import React from "react";
// import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Column, Row } from "@atoms";
import { CollectionContentItem } from "../CollectionContentItem";
import { CollectionTwoUpFragment } from "./__generated__/CollectionTwoUpFragment";

// == Types ================================================================
interface IProps {
  storefrontId?: string;
  collection: CollectionTwoUpFragment;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment CollectionTwoUpFragment on Collection {
      id
      assets {
        ...CollectionContentItemFragment
      }
    }
    ${CollectionContentItem.fragments.fields}
  `,
};

// == Component ============================================================

const CollectionTwoUp = ({ collection, storefrontId }: IProps) => {
  if (collection.assets.length < 2) return null;

  return (
    <Row flexDirection={["column", "column", "row"]} flexWrap="wrap" justifyContent="center">
      {collection.assets.map((asset, index) => (
        <Column autoColumn={false} key={`${asset.image}`} mb={[2, 2, 0]} width={[1, 1, 1 / 2]}>
          <CollectionContentItem asset={asset} storefrontId={storefrontId} />
        </Column>
      ))}
    </Row>
  );
};

CollectionTwoUp.fragments = FRAGMENTS;

export default CollectionTwoUp;

// == Styles ===============================================================
