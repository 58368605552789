import Pica from "pica/dist/pica";

const pica = new Pica();

export default class ImageResizer {
  constructor(file) {
    this.file = file;
    this.dataURL = null;
  }

  // returns a promise which resolves to a blob
  resize = async () => {
    // 1) convert to data url
    this.dataURL = await this.convertToDataUrl(this.file);
    // 2) create image
    const image = await this.buildImage(this.dataURL);
    // 3) resize image
    const result = await this.resizeImage(image);
    // 4) convert resize result to blob
    return pica.toBlob(result, "image/jpeg", 90); // promise
  };

  convertToDataUrl = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.readAsDataURL(file);
    });

  buildImage = (dataURL) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.src = dataURL;

      image.onload = () => {
        resolve(image);
      };
    });

  resizeImage = (image) =>
    new Promise((resolve, reject) => {
      const size = this.calculateAspectRatioFit(image.width, image.height, 2048, 2048);

      const canvas = document.createElement("canvas");
      canvas.width = size.width;
      canvas.height = size.height;

      pica.resize(image, canvas).then(() => {
        resolve(canvas);
      });
    });

  /**
   * Conserve aspect ratio of the orignal region. Useful when shrinking/enlarging
   * images to fit into a certain area.
   *
   * @param {Number} srcWidth width of source image
   * @param {Number} srcHeight height of source image
   * @param {Number} maxWidth maximum available width
   * @param {Number} maxHeight maximum available height
   * @return {Object} { width, height }
   */
  calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth * ratio, height: srcHeight * ratio };
  };
}
