import React, { useMemo } from "react";
import gql from "graphql-tag";
import produce from "immer";
import { useMutation } from "react-apollo";
import { ErrorService, constants, RailsUrl } from "@services";
import { Button, Text } from "@atoms";
import { IGiftcardReloadState, TGiftcardReloadDispatch } from "./types";
import {
  ReloadIntentCreateMutation,
  ReloadIntentCreateMutationVariables,
} from "./__generated__/ReloadIntentCreateMutation";
import { GiftcardReloadInputType, ReloadIntentCreateInput } from "@types";
import { useRecaptcha, useStoreAppValue } from "@hooks";
import { useFeatureFlag } from "@hooks/useFeatureFlag";
import {
  GiftcardRefillButtonMutation,
  GiftcardRefillButtonMutationVariables,
} from "./__generated__/GiftcardRefillButtonMutation";

// == Types ================================================================

interface IProps {
  state: IGiftcardReloadState;
  dispatch: TGiftcardReloadDispatch;
}

// == Constants ============================================================

const MARK_ALL_READ_MUTATION = gql`
  mutation GiftcardRefillButtonMutation($input: GiftcardReloadInputType!) {
    ViewerGiftcardReload(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Giftcard {
        id
        isReloadable
        balance {
          dollarsAmount
          formatted
        }
        value {
          formatted
          dollarsAmount
        }
        reloadInfo {
          isReloadingEnabled
          reloadPercentage
          stripeCardId
        }
        campaign {
          id
          name
        }
      }
    }
  }
`;

export const RELOAD_INTENT_CREATE_MUTATION = gql`
  mutation ReloadIntentCreateMutation($input: ReloadIntentCreateInput!) {
    ReloadIntentCreate(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on GiftcardIntent {
        id
        token
      }
    }
  }
`;

// == Component ============================================================
export function GiftcardRefillButton({ state, dispatch }: IProps) {
  const [updateGiftcardMutation, { loading, error }] = useMutation<
    GiftcardRefillButtonMutation,
    GiftcardRefillButtonMutationVariables
  >(MARK_ALL_READ_MUTATION, {
    onCompleted(resp) {
      if (resp?.ViewerGiftcardReload.__typename !== "MutationError") {
        dispatch({ type: "SUCCESSFUL_SAVE" });
      } else {
        dispatch({ type: "FAILED_SAVE" });
      }
    },
  });
  const { currentUser } = useStoreAppValue();
  const recaptchaAction = currentUser
    ? "CREATE_GIFTCARD_INTENT"
    : "CREATE_ANONYMOUS_GIFTCARD_INTENT";

  const getRecaptchaToken = useRecaptcha({ action: recaptchaAction });

  const [reloadIntentCreate, { loading: isReloading, error: reloadError }] = useMutation<
    ReloadIntentCreateMutation,
    ReloadIntentCreateMutationVariables
  >(RELOAD_INTENT_CREATE_MUTATION);

  const recaptchaToken = useMemo(() => {
    let recaptchaToken = "";
    getRecaptchaToken()
      .then((token) => {
        recaptchaToken = token as string;
      })
      .catch((error) => ErrorService.error(error));

    return recaptchaToken;
  }, [getRecaptchaToken]);

  const { isEnabled: isReloadEnabled } = useFeatureFlag("reload");

  const reloadWithoutFeatureFlag = async () => {
    try {
      const input = produce({} as GiftcardReloadInputType, (draft) => {
        draft.giftcardId = state.giftcard.id;
        draft.campaignId = state.selectedCampaignId;
        draft.isNotificationAllowed = state.isNotificationAllowed;
        if (state.manualReloadDollarsAmount) draft.amount = state.manualReloadDollarsAmount;
        if (state.stripeCardId) draft.stripeCardId = state.stripeCardId;
        if (state.stripeTokenId) draft.stripeTokenId = state.stripeTokenId;
      });

      const resp = await updateGiftcardMutation({
        variables: {
          input,
        },
      });
      const { data } = resp;
      if (data?.ViewerGiftcardReload.__typename === "MutationError") return;
      dispatch({
        type: "UPDATE_BALANCE",
        balanceFormatted: data?.ViewerGiftcardReload.balance?.formatted,
      });
    } catch (e) {
      ErrorService.error(e);
    }
  };

  const reloadWithFeatureFlag = async () => {
    try {
      const input = produce<{}, ReloadIntentCreateInput, void>({}, (draft) => {
        draft.giftcardId = state.giftcard.id;
        draft.devicePlatform = constants.PLATFORM;
        draft.campaignId = state.selectedCampaignId;
        if (recaptchaToken) draft.recaptchaToken = recaptchaToken as string;
        draft.reloadAmountCents = state.manualReloadDollarsAmount * 100;
      });

      const res = await reloadIntentCreate({
        variables: {
          input: input as ReloadIntentCreateInput,
        },
      });
      if (res.data?.ReloadIntentCreate.__typename === "MutationError") return;
      const token = res.data?.ReloadIntentCreate.token;
      if (token) window.location.href = RailsUrl.newCheckoutUrl(token);
    } catch (e) {
      ErrorService.error(e);
    }
  };

  const onSubmit = async () => {
    if (isReloadEnabled) {
      reloadWithFeatureFlag();
    } else {
      reloadWithoutFeatureFlag();
    }
  };

  return (
    <>
      <Button
        disabled={loading || state.manualReloadDollarsAmount === 0}
        width="100%"
        onClick={onSubmit}
      >
        {loading || isReloading ? "Reloading..." : "Reload Now"}
      </Button>
      {(error || reloadError || state.error) && (
        <Text color="danger" display="block" mt={1} textAlign="center">
          {state.error || error?.message}
        </Text>
      )}
    </>
  );
}
