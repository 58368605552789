/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Describes the current fundraising status of the user
 */
export enum ActiveStatusEnum {
  PERSONAL = "PERSONAL",
  TEAM = "TEAM",
  UNKNOWN = "UNKNOWN",
}

/**
 * Describes the team type for a beneficiary
 */
export enum BeneficiaryTeamType {
  CHARITY = "CHARITY",
  INDIVIDUAL = "INDIVIDUAL",
  ORGANIZATION = "ORGANIZATION",
  UNCATEGORIZED = "UNCATEGORIZED",
}

/**
 * Order by values for the brand directory.
 */
export enum BrandDirectoryOrderBy {
  GIVE_DESC = "GIVE_DESC",
  NAME_ASC = "NAME_ASC",
  NEWEST_DESC = "NEWEST_DESC",
  POPULARITY_DESC = "POPULARITY_DESC",
}

/**
 * Describes the brand name of a credit card.
 */
export enum CardTypeEnum {
  AMERICAN_EXPRESS = "AMERICAN_EXPRESS",
  MASTERCARD = "MASTERCARD",
  VISA = "VISA",
}

/**
 * Describes the style of a collection.
 */
export enum CollectionStyle {
  BANNER = "BANNER",
  BANNER_CAROUSEL = "BANNER_CAROUSEL",
  BRAND_CAROUSEL = "BRAND_CAROUSEL",
  CAROUSEL = "CAROUSEL",
  COLLAGE = "COLLAGE",
  CONTENT = "CONTENT",
  DEPARTMENT_GRID = "DEPARTMENT_GRID",
  FEATURED = "FEATURED",
  FOUR_UP = "FOUR_UP",
  GRID = "GRID",
  GRID_HALF_BANNER = "GRID_HALF_BANNER",
  GRID_QUARTER_BANNER = "GRID_QUARTER_BANNER",
  LINK_PROMOTIONS = "LINK_PROMOTIONS",
  NEARBY_BRANDS = "NEARBY_BRANDS",
  PLAYLIST = "PLAYLIST",
  SINGLE = "SINGLE",
  SPORTS_BRANDS = "SPORTS_BRANDS",
  STORE_FRONT = "STORE_FRONT",
  THREE_UP = "THREE_UP",
  TWO_UP = "TWO_UP",
}

/**
 * Describes the reason the user is in continuity
 */
export enum ContinuityReasonEnum {
  CHOICE = "CHOICE",
  CLOSED = "CLOSED",
  LEFT = "LEFT",
  NEW_USER = "NEW_USER",
  REMOVED = "REMOVED",
}

/**
 * What is the source of these coordinates?
 */
export enum CoordinatesSource {
  CAMPAIGN = "CAMPAIGN",
  CURRENT = "CURRENT",
  SEARCH = "SEARCH",
}

/**
 * Available countries.
 */
export enum Country {
  CANADA = "CANADA",
  USA = "USA",
}

/**
 * The list of currencies available.
 */
export enum Currency {
  CAD = "CAD",
  USD = "USD",
}

/**
 * Describes the type of a StoreFront, used specifically for querying the different default StoreFronts.
 */
export enum DefaultStoreFrontType {
  DEALS_DEFAULT = "DEALS_DEFAULT",
  FIRST_SHOP = "FIRST_SHOP",
  GIFT_CARDS_DEFAULT = "GIFT_CARDS_DEFAULT",
  HOME_DEFAULT = "HOME_DEFAULT",
  ONBOARDING_DEFAULT = "ONBOARDING_DEFAULT",
  STORE_DEFAULT = "STORE_DEFAULT",
  TEAM_STORE_DEFAULT = "TEAM_STORE_DEFAULT",
}

/**
 * Describes the roles of fundraiser
 */
export enum FundraiserRole {
  BUDGET_ADMIN = "BUDGET_ADMIN",
  BUDGET_TEAM_MEMBER = "BUDGET_TEAM_MEMBER",
  CO_CAPTAIN = "CO_CAPTAIN",
  OWNER = "OWNER",
  PLAYER_CONTACT = "PLAYER_CONTACT",
}

/**
 * How funds are to be distributed
 */
export enum FundsDistributionMethod {
  PLAYER = "PLAYER",
  TEAM = "TEAM",
  UNDECIDED = "UNDECIDED",
}

/**
 * How this denomination will be deliveried
 */
export enum GiftcardDenominationDeliveryMethod {
  DIGITAL = "DIGITAL",
  PHYSICAL = "PHYSICAL",
}

/**
 * Describes the available sort options for a giftcard
 */
export enum GiftcardSortEnum {
  BALANCE = "BALANCE",
  DATE = "DATE",
  NAME = "NAME",
}

/**
 * The type of a giftcard
 */
export enum GiftcardType {
  GIFTCARD = "GIFTCARD",
  RELOADABLE = "RELOADABLE",
  VOUCHER = "VOUCHER",
}

/**
 * Describes the category of an intent.
 */
export enum IntentCategoryEnum {
  ALL = "ALL",
  BONUS = "BONUS",
  DONATION = "DONATION",
  SHOPPING = "SHOPPING",
  TEAM = "TEAM",
  TRANSFER = "TRANSFER",
}

/**
 * Describes the type of filters available for intent.
 */
export enum IntentFilterByEnum {
  BUYS = "BUYS",
  PENDING = "PENDING",
  SHOPS = "SHOPS",
  SUPPORTED = "SUPPORTED",
}

/**
 * Describes the browser that was used to view an intent on mobile.
 */
export enum IntentMobileBrowserTypeEnum {
  APP_BUTTON = "APP_BUTTON",
  APP_CHROME = "APP_CHROME",
  APP_CHROME_ESCAPE = "APP_CHROME_ESCAPE",
  APP_FLIPGIVE_WEBVIEW = "APP_FLIPGIVE_WEBVIEW",
  APP_SAFARI = "APP_SAFARI",
  APP_SAFARI_ESCAPE = "APP_SAFARI_ESCAPE",
}

/**
 * Describes the type of an intent.
 */
export enum IntentTypeEnum {
  BUY = "BUY",
  DONATION = "DONATION",
  FLIPGIVECREDIT = "FLIPGIVECREDIT",
  FLIPGIVEINACTIVITYFEE = "FLIPGIVEINACTIVITYFEE",
  LINKEDCARDSALE = "LINKEDCARDSALE",
  RELOAD = "RELOAD",
  SHOP = "SHOP",
  TRANSFER = "TRANSFER",
}

/**
 * Describes the invite template you wish to see
 */
export enum InviteTemplateType {
  PARENT = "PARENT",
  REMINDER = "REMINDER",
  SUPPORTER = "SUPPORTER",
}

/**
 * A category of links.
 */
export enum LinkCategory {
  GIFT_CARDS = "GIFT_CARDS",
  REGISTER = "REGISTER",
  SHOP_IN_STORE = "SHOP_IN_STORE",
  SHOP_ONLINE = "SHOP_ONLINE",
}

/**
 * Describes the type of store link.
 */
export enum LinkType {
  AUTORELOADGIFTCARD = "AUTORELOADGIFTCARD",
  BOUNTYSHOP = "BOUNTYSHOP",
  BRAND = "BRAND",
  DEPARTMENT = "DEPARTMENT",
  EMPYR = "EMPYR",
  GIFTCARDOFFER = "GIFTCARDOFFER",
  GIFTCARDSHOP = "GIFTCARDSHOP",
  PRODUCT = "PRODUCT",
  RELOADABLEGIFTCARD = "RELOADABLEGIFTCARD",
  SHORTCUT = "SHORTCUT",
  TRAVELSHOP = "TRAVELSHOP",
  VOUCHER = "VOUCHER",
}

/**
 * Describes the department of a merchant.
 */
export enum MerchantDepartmentType {
  FASHION_BEAUTY = "FASHION_BEAUTY",
  FOOD_GROCERIES = "FOOD_GROCERIES",
  GADGETS_ELECTRONICS = "GADGETS_ELECTRONICS",
  GENERAL_RETAIL = "GENERAL_RETAIL",
  GIFTS_SPECIALTY = "GIFTS_SPECIALTY",
  HOME_OFFICE = "HOME_OFFICE",
  LOCAL = "LOCAL",
  RESTAURANT_DINING = "RESTAURANT_DINING",
  SERVICES_UTILITIES = "SERVICES_UTILITIES",
  SPORTS_OUTDOOR = "SPORTS_OUTDOOR",
  TRAVEL_ENTERTAINMENT = "TRAVEL_ENTERTAINMENT",
}

/**
 * Describes the status of missing claim.
 */
export enum MissingClaimStatusEnum {
  APPROVED = "APPROVED",
  AUTO_CLOSED = "AUTO_CLOSED",
  DENIED = "DENIED",
  PENDING = "PENDING",
}

/**
 * How funds are to be distributed at the organization level
 */
export enum OrganizationFundsDistributionMethod {
  ORGANIZATION = "ORGANIZATION",
  TEAM = "TEAM",
  UNDECIDED = "UNDECIDED",
}

/**
 * Describes the payment types that can be used for beneficiary
 */
export enum PaymentType {
  EMAIL = "EMAIL",
  HW_BANK_TRANSFER = "HW_BANK_TRANSFER",
  HW_CHECK = "HW_CHECK",
  POSTAGE = "POSTAGE",
}

/**
 * Describes the platform of a device.
 */
export enum PlatformEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
  WEB = "WEB",
  WEB_ANDROID = "WEB_ANDROID",
  WEB_EXTENSION = "WEB_EXTENSION",
  WEB_IOS = "WEB_IOS",
}

/**
 * The privacy level that describes how much information about an object is shared.
 */
export enum PrivacyLevel {
  ANONYMOUS = "ANONYMOUS",
  MASKED = "MASKED",
  NONE = "NONE",
}

/**
 * Attributes for creating a beneficiary for a campaign
 */
export interface BeneficiaryCreateInput {
  campaignId: string;
  teamType: BeneficiaryTeamType;
  legalName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  realTeamName: string;
  teamName?: string | null;
  website?: string | null;
  contactName?: string | null;
  phoneNumber: string;
  email: string;
  taxId?: string | null;
  street1: string;
  street2?: string | null;
  city: string;
  province: string;
  postalCode: string;
  country: Country;
  paymentType: PaymentType;
  plaidPublicToken?: string | null;
  plaidAccountId?: string | null;
  googlePlacesId?: string | null;
  plaidInstitutionName?: string | null;
  campaignAutoWithdrawAfterApproval?: boolean | null;
}

/**
 * Attributes for creating an organization via beneficiary submission
 */
export interface BeneficiaryOrganizationInput {
  name: string;
  legalName: string;
  street1: string;
  city: string;
  province: string;
  postalCode: string;
  country: Country;
  categoryId: string;
}

/**
 * Attributes for updating a beneficiary for a campaign
 */
export interface BeneficiaryUpdateInputType {
  id: string;
  teamType?: BeneficiaryTeamType | null;
  legalName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  realTeamName?: string | null;
  teamName?: string | null;
  website?: string | null;
  contactName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  taxId?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  province?: string | null;
  postalCode?: string | null;
  country?: Country | null;
  paymentType?: PaymentType | null;
  plaidPublicToken?: string | null;
  plaidAccountId?: string | null;
  googlePlacesId?: string | null;
}

/**
 * Attributes for updating a fundraiser roles
 */
export interface BudgetFundraiserUpdateRolesInput {
  id: string;
  roles: FundraiserRole[];
}

/**
 * Attributes for processing a gift card transaction
 */
export interface BuyProcessInput {
  shouldCreateAccount?: boolean | null;
  stripeCardId?: string | null;
  stripeVerificationCode?: string | null;
  stripeTokenId?: string | null;
  shouldSaveCard?: boolean | null;
  recaptchaToken?: string | null;
}

/**
 * Attributes for updating an gift card Intent
 */
export interface BuyUpdateInput {
  buyerName?: string | null;
  buyerEmail?: string | null;
  isAnonymous?: boolean | null;
  giftName?: string | null;
  giftEmail?: string | null;
  giftMessage?: string | null;
  deliverOn?: any | null;
  giftcardTemplateId?: string | null;
  shouldSaveAddress?: boolean | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  province?: string | null;
  postalCode?: string | null;
  country?: string | null;
  shippingOption?: string | null;
}

/**
 * Attributes for submitting a testimonial
 */
export interface CampaignTestimonialCreateInput {
  testimonial: string;
}

/**
 * Attributes for updating the captain of a team
 */
export interface CampaignUpdateCaptainInput {
  teamMemberId: string;
}

/**
 * UTM attributes for a campaign on creation.
 */
export interface CampaignUtmDataInput {
  utmType?: string | null;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmTerm?: string | null;
  utmContent?: string | null;
  utmChannel?: string | null;
  branchLinkId?: string | null;
  appsFlyerId?: string | null;
}

/**
 * Coordinates attributes
 */
export interface CoordinatesInput {
  latitude: number;
  longitude: number;
  source?: CoordinatesSource | null;
}

/**
 * Attributes for updating an event.
 */
export interface EventUpdateInput {
  eventId: string;
  name?: string | null;
  description?: string | null;
  brandId?: string | null;
  brandLocationId?: string | null;
  startsAt?: string | null;
  endsAt?: string | null;
}

/**
 * Attributes for creating a giftcard intent
 */
export interface GiftcardIntentCreateInput {
  linkId: string;
  brandId?: string | null;
  collectionId?: string | null;
  storefrontId?: string | null;
  labelPromotionId?: string | null;
  commissionPromotionId?: string | null;
  utmData?: CampaignUtmDataInput | null;
  isGift: boolean;
  isPhysical: boolean;
  amountCents: number;
  quantity: number;
  fundraiserId?: string | null;
  fundraiserIds?: string[] | null;
  promotionId?: string | null;
  reloadThresholdCents?: number | null;
  deviceAttributes: IntentDeviceAttributesInput;
  recaptchaToken?: string | null;
}

/**
 * Input for reloading a reloadable giftcard
 */
export interface GiftcardReloadInputType {
  giftcardId: string;
  campaignId?: string | null;
  stripeCardId?: string | null;
  stripeTokenId?: string | null;
  amount?: number | null;
  isNotificationAllowed: boolean;
}

/**
 * Attributes for creating an Intent
 */
export interface IntentCreateInput {
  linkId?: string | null;
  brandId?: string | null;
  intentType?: string | null;
  buyerName?: string | null;
  buyerEmail?: string | null;
  totalAmountCents?: number | null;
  collectionId?: string | null;
  storefrontId?: string | null;
  labelPromotionId?: string | null;
  commissionPromotionId?: string | null;
  utmData?: CampaignUtmDataInput | null;
  fundraiserId?: string | null;
  fundraiserIds?: string[] | null;
  locationId?: string | null;
  promotionId?: string | null;
  deviceAttributes?: IntentDeviceAttributesInput | null;
  devicePlatform?: PlatformEnum | null;
  deviceType?: string | null;
  deviceVersion?: string | null;
  deviceIpAddress?: string | null;
  deviceUserAgent?: string | null;
}

/**
 * Information about the device making the intent
 */
export interface IntentDeviceAttributesInput {
  devicePlatform: PlatformEnum;
  deviceType?: string | null;
  deviceVersion?: string | null;
  ipAddress?: string | null;
  userAgent?: string | null;
  mobileBrowserType?: IntentMobileBrowserTypeEnum | null;
}

/**
 * Attributes for leaving a campaign
 */
export interface LeaveCampaignInput {
  campaignId: string;
  userId: string;
}

/**
 * Attributes for creating a reload intent
 */
export interface ReloadIntentCreateInput {
  giftcardId: string;
  campaignId: string;
  reloadAmountCents: number;
  recaptchaToken?: string | null;
  devicePlatform: PlatformEnum;
}

/**
 * Input for updating a reloadable giftcard
 */
export interface ReloadableGiftcardUpdateInputType {
  giftcardId: string;
  isReloadingEnabled: boolean;
  reloadThresholdCents: number;
  campaignId?: string | null;
  stripeCardId?: string | null;
  stripeTokenId?: string | null;
}

/**
 * Attributes for adding multiple favorite brands
 */
export interface ViewerAddFavoriteBrandsInput {
  brandIds: string[];
}

/**
 * Input for answering a user onboarding question
 */
export interface ViewerAnswerOnboardingQuestionInput {
  questionID: string;
  answerID: string;
}

/**
 * Attributes for generating a payment breakdown/report
 */
export interface ViewerEmailPaymentBreakdownInput {
  paymentId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
