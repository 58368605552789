import React from "react";
import { Range } from "react-range";
import { Flex } from "../Flex";
import { Box } from "../Box";

// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  step?: number;
  min?: number;
  max?: number;
  values: number[];
  renderThumb?: $FixMe;
  renderTrack?: $FixMe;
  onChange?: (values: number[]) => void;
}

// == Constants ============================================================

RangeInput.defaultProps = {
  step: 1,
  min: 0,
  max: 100,
  onChange: () => null,
  renderThumb: null,
  renderTrack: null,
};

// == Component ============================================================

export function RangeInput({ step, min, max, values, onChange }: IProps) {
  return (
    <Flex alignItems="center" height={40}>
      <Range
        max={max}
        min={min}
        renderThumb={({ props }) => {
          return (
            <Box
              {...props}
              bg="primary"
              borderRadius="circle"
              height={25}
              style={{
                ...props.style,
              }}
              width={25}
            />
          );
        }}
        renderTrack={({ props, children }) => (
          <Box
            {...props}
            style={{
              ...props.style,
              height: "6px",
              width: "100%",
              backgroundColor: "#eee",
            }}
          >
            {children}
          </Box>
        )}
        step={step}
        values={values}
        onChange={onChange}
      />
    </Flex>
  );
}

// == Styles ===============================================================
