/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import gql from "graphql-tag";
import { useCookies } from "react-cookie";
import { useQuery, useMutation } from "react-apollo";
import LoadingTeamsSpinner from "./LoadingTeamsSpinner";
import FlipGiveTeamListItem from "./FlipGiveTeamListItem";
import FlipGiveTeamListItemNew from "./FlipGiveTeamListItemNew";
import { RailsUrl } from "@services";

const RUNNING_CAMPAIGNS_QUERY = gql`
  query RunningCampaignsQuery {
    Viewer {
      id
      runningCampaigns {
        id
        name
        photoImage
        amountRaised {
          formatted
        }
        goal {
          formatted
        }
        currency
        externalPartnerRef
        externalPartnerType
      }
    }
  }
`;

const CAMPAIGN_CREATE_MUTATION = gql`
  mutation CampaignCreateMutation(
    $name: String!
    $country: Country!
    $categoryId: ID!
    $landing: String
    $externalPartner: ExternalPartnerInput
    $postalCode: String
    $utmData: CampaignUtmDataInput
  ) {
    CampaignCreate(
      input: {
        name: $name
        country: $country
        categoryId: $categoryId
        landing: $landing
        externalPartner: $externalPartner
        postalCode: $postalCode
        utmData: $utmData
      }
    ) {
      ... on Campaign {
        id
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;

const SET_EXTERNAL_PARTNER_MUTATION = gql`
  mutation CampaignSetExternalPartnerMutation($id: ID!, $externalPartner: ExternalPartnerInput!) {
    CampaignSetExternalPartner(id: $id, externalPartner: $externalPartner) {
      ... on Campaign {
        id
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
`;

const filterCampaigns = (campaigns, currency) => {
  return campaigns
    .filter((campaign) => campaign.currency === currency)
    .filter((campaign) => !campaign.externalPartnerRef);
};

export default function FlipGiveTeams({ externalTeam, landing }) {
  const [selectedCampaign, setSelectedCampaign] = React.useState(null);
  const [createNewTeam, setCreateNewTeam] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [createCampaignMutation] = useMutation(CAMPAIGN_CREATE_MUTATION);
  const [setExternalPartnerMutation] = useMutation(SET_EXTERNAL_PARTNER_MUTATION);
  const [cookies] = useCookies(["utm_data"]);

  async function create() {
    const utmData = cookies.utm_data || {};
    await createCampaignMutation({
      variables: {
        name: externalTeam.name,
        country: externalTeam.country,
        categoryId: externalTeam.category.id,
        externalPartner: {
          id: externalTeam.id,
          type: externalTeam.type,
        },
        postalCode: externalTeam.postalCode,
        landing,
        utmData: {
          utmType: utmData.utm_type,
          utmSource: utmData.utm_source,
          utmMedium: utmData.utm_medium,
          utmCampaign: utmData.utm_campaign,
          utmTerm: utmData.utm_term,
          utmContent: utmData.utm_content,
          utmChannel: utmData.utm_channel,
          branchLinkId: utmData.branch_link_id,
          appsFlyerId: utmData.apps_flyer_id,
        },
      },
    });
    window.location.href = RailsUrl.newTeamsnapPasswordUrl();
  }

  async function connect() {
    await setExternalPartnerMutation({
      variables: {
        id: selectedCampaign.id,
        externalPartner: {
          id: externalTeam.id,
          type: externalTeam.type,
        },
      },
    });
    window.location.href = RailsUrl.newTeamsnapPasswordUrl();
  }

  const { loading, data } = useQuery(RUNNING_CAMPAIGNS_QUERY, {
    onCompleted: () => {
      const campaigns = filterCampaigns(data.Viewer.runningCampaigns, externalTeam.currency);

      if (campaigns.length === 0) {
        setSubmitting(true);
        create();
      }
    },
  });

  if (submitting) {
    return <LoadingTeamsSpinner text="Joining..." />;
  }
  if (loading) {
    return <LoadingTeamsSpinner />;
  }

  return (
    <div>
      <h1 className="centered mb-3">Are any of these the same team?</h1>
      <p className="centered mb-4">
        You have some existing teams on your FlipGive Account. Are any of these the same as{" "}
        <span className="color-link">{externalTeam.name}</span> on TeamSnap?
      </p>

      <ul>
        {filterCampaigns(data.Viewer.runningCampaigns, externalTeam.currency).map((campaign) => (
          <FlipGiveTeamListItem
            campaign={campaign}
            key={campaign.id}
            selectedFlipgiveTeam={selectedCampaign}
            setSelectedFlipgiveTeam={(newCampaign) => {
              setSelectedCampaign(newCampaign);
              setCreateNewTeam(false);
            }}
          />
        ))}

        <li className={createNewTeam ? "selected" : ""}>
          <FlipGiveTeamListItemNew
            isSelected={createNewTeam}
            setSelectedCreateNewTeam={() => {
              setSelectedCampaign(null);
              setCreateNewTeam(true);
            }}
            teamName={externalTeam.name}
          />
        </li>
      </ul>

      <a
        className={`${!createNewTeam && !selectedCampaign ? "disabled" : null} btn btn-block`}
        href="#"
        onClick={(event) => {
          event.preventDefault();
          if (!createNewTeam && !selectedCampaign) {
            return;
          }

          setSubmitting(true);
          if (createNewTeam) {
            create();
          } else if (selectedCampaign) {
            connect();
          }
        }}
      >
        Connect
      </a>
    </div>
  );
}
