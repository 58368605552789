import React from "react";
import queryString from "query-string";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import OnboardingWelcomeModal from "./OnboardingWelcomeModal";

// == Constants ============================================================

const ONBOARDING_WELCOME_MODAL_QUERY = gql`
  query OnboardingWelcomeModalQuery {
    Viewer {
      id
      ...OnboardingWelcomeModalFragment
    }
  }
  ${OnboardingWelcomeModal.fragment}
`;

// == Component ============================================================

export default function OnboardingWelcomeModalQuery() {
  const urlParams = queryString.parse(window.location.search);
  const canShowModal = urlParams.src === "onboarding" && urlParams.view === "first";
  const { loading, data, error } = useQuery(ONBOARDING_WELCOME_MODAL_QUERY, {
    skip: !canShowModal,
  });

  if (!canShowModal) return null;
  if (data && !loading && !error) {
    return <OnboardingWelcomeModal viewer={data.Viewer} />;
  }

  return null;
}
