import gql from "graphql-tag";
import { CheckoutPaymentForm } from "../CheckoutPaymentForm";

export const CHECKOUT_FRAGMENT = gql`
  fragment CheckoutFragment on GiftcardIntent {
    id
    buyerEmail
    buyerName
    createdAt
    isGift
    isPhysical
    token
    reloadThreshold {
      formatted
    }
    quantity
    reloadSentence
    deliveryEstimate
    shippingOptions {
      id
      name
    }
    giftcardTemplate {
      id
      backgroundImage
      giftcardImage
      isPhysical
      name
      previewImage
      providerCode
    }
    campaign {
      id
      name
    }
    link {
      id
      name
      image
      currency
      type
      reloadableGiftcardInfo {
        isManualReloadable
      }
    }
    gifting {
      name
      email
      message
      deliverOn
    }
    shipping {
      city
      country
      postalCode
      province
      street1
      street2
    }
    shippingOption {
      id
      name
    }
    unitPrice {
      centsAmount
      dollarsAmount(round: 2)
      formatted(round: 2)
    }
    orderTotal {
      centsAmount
      dollarsAmount(round: 2)
      formatted(round: 2)
    }
    giveAmount {
      centsAmount
      dollarsAmount(round: 2)
      formatted(round: 2)
    }
    shippingAmount {
      centsAmount
      dollarsAmount(round: 2)
      formatted(round: 2)
    }
  }
`;

export const CHECKOUT_VIEW_FRAGMENT = gql`
  fragment CheckoutViewFragment on Viewer {
    id
    ...CheckoutPaymentFormFragment
  }
  ${CheckoutPaymentForm.fragments}
`;

export const BUY_UPDATE_MUTATION = gql`
  mutation BuyUpdateMutation($id: ID!, $input: BuyUpdateInput!) {
    BuyUpdate(id: $id, input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on GiftcardIntent {
        id
        ...CheckoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;
