import React, { useState } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import Carousel from "@brainhubeu/react-carousel";
import styled from "@emotion/styled";
import { useStoreAppValue, useQueryParams } from "@hooks";
import { Box, Button, Flex, H1, Image, Text, TouchableOpacity } from "@atoms";
import { RailsUrl } from "@services";
import { OnboardingContainer } from "../components";
import "@brainhubeu/react-carousel/lib/style.css";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

OnboardingSplash.defaultProps = {};

const SPLASH_SCREENS = [
  {
    id: 0,
    src: "https://flipgive.twic.pics/images/onboarding/onboarding-splash1.svg",
    title: "Team Funding Made Easy",
    text: "Save Time with FlipGive. Earn cash back on things you're already buying.",
  },
  {
    id: 1,
    src: "https://flipgive.twic.pics/images/onboarding/no-fundraising.png",
    title: "No Product Selling",
    text: "Stop selling calendars, chocolate bars and cookie dough.",
  },
  {
    id: 2,
    src: "https://flipgive.twic.pics/images/onboarding/onboarding-splash3.svg",
    title: "100% Free To Use",
    text:
      "We make money from our retail partners, not from your team's effort. You keep 100% of the money your team earns.",
  },
];

// == Component ============================================================

export function OnboardingSplash(_props: RouteComponentProps) {
  const { campaignId } = useStoreAppValue();
  const [currentSlide, setCurrentSlide] = useState(0);
  const joinCodeParam = useQueryParams("joincode", null);
  const lastScreen = SPLASH_SCREENS.length - 1;

  const onClick = () => {
    if (currentSlide === lastScreen) {
      window.location.href = RailsUrl.joinTeamUrl(campaignId, joinCodeParam);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const onChange = (v) => {
    setCurrentSlide(v);
  };

  return (
    <OnboardingContainer>
      <Carousel value={currentSlide} onChange={onChange}>
        {SPLASH_SCREENS.map((screen) => {
          const { id, src, title, text } = screen;
          return (
            <Box key={id} px={4}>
              <Box>
                <Image src={src} width="100%" />
              </Box>
              <Box py={3} textAlign="center">
                <H1>{title}</H1>
                <Text as="p" display="block" p={2}>
                  {text}
                </Text>
              </Box>
            </Box>
          );
        })}
      </Carousel>
      <Box mx={4}>
        <Button width="100%" onClick={onClick}>
          {currentSlide === lastScreen ? "Get Started" : "Next"}
        </Button>
      </Box>
      <Flex alignItems="center" flexDirection="row" justifyContent="center" mt={4}>
        {SPLASH_SCREENS.map((dot) => {
          const isCurrentDot = dot.id === currentSlide;
          const onTouch = () => {
            setCurrentSlide(dot.id);
          };
          return (
            <TouchableOpacity key={dot.id} onTouch={onTouch}>
              <EDot dotOpacity={isCurrentDot} />
            </TouchableOpacity>
          );
        })}
      </Flex>
    </OnboardingContainer>
  );
}

// == Styles ===============================================================

const EDot = styled(Box)`
  margin: 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  opacity: ${(props) => (props.dotOpacity ? 1 : 0.5)};
`;
