import React from "react";
import { Box, Heading, Flex, TextUppercase, Text } from "@atoms";
import { CollectionRenderFragment } from "../CollectionRender/__generated__/CollectionRenderFragment";
import { Link } from "@reach/router";
import { RailsUrl } from "@services";
import { useStoreAppValue } from "@hooks";
import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  collection: CollectionRenderFragment;
  altText?: string;
  altOnClick?: () => void;
}

// == Constants ============================================================

// == Component ============================================================

// == Component ============================================================

const CollectionNearbyBrandsTitle = ({ collection, altText, altOnClick }: IProps) => {
  const { campaignId } = useStoreAppValue();

  return (
    <Flex mb={2} justifyContent="space-between">
      {!!collection.name && (
        <Flex width="80%">
          <Box maxWidth="55%" mr={2}>
            <Heading ellipsis fontSize={[2, 3]}>
              {collection.name}
            </Heading>
          </Box>
          {altText && (
            <EText as="p" display="inline-block" fontSize="1" ml={2} onClick={altOnClick}>
              {altText}
            </EText>
          )}
        </Flex>
      )}
      <Box>
        <Link to={RailsUrl.nearbyUrl({ campaignId })}>
          <TextUppercase fontSize={[0, 1]}>View All</TextUppercase>
        </Link>
      </Box>
    </Flex>
  );
};

export default CollectionNearbyBrandsTitle;

// == Styles ===============================================================

const EText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
