import React, { useState } from "react";
import { useCombobox } from "downshift";
import Axios from "axios";

interface IProps {
  merchantId: number | null;
  setMerchantId: (id: number | null) => void;
}

interface Merchant {
  id: number;
  name: string;
}

export function MerchantComboBox({ merchantId, setMerchantId }: IProps) {
  const [value, setValue] = useState("");
  const [ready, setReady] = useState(false);
  const [inputItems, setInputItems] = useState<Array<Merchant>>([]);

  React.useEffect(() => {
    if (!value || value.length <= 2) {
      setInputItems([]);
      return;
    }

    let source = Axios.CancelToken.source();
    const url = `/admin/merchants.json?q=${value}`;

    async function findMerchants() {
      try {
        const response = await Axios.get(url, {
          cancelToken: source.token,
        });
        setInputItems(
          response.data.slice(0, 10).map((merchant: Merchant) => {
            const { id, name } = merchant;
            return { id, name };
          })
        );
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log(`call for ${url} was cancelled`);
        } else {
          throw error;
        }
      }
    }
    findMerchants();

    return () => {
      source.cancel();
    };
  }, [value]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setValue(inputValue ? inputValue : "");
      if (inputValue === "") {
        setMerchantId(null);
      }
    },
    itemToString: (item) => (item ? item.name : ""),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setValue(selectedItem.name);
        setMerchantId(selectedItem.id);
      } else {
        setMerchantId(null);
      }
    },
  });

  React.useEffect(() => {
    if (ready) return;
    if (!merchantId) {
      setReady(true);
      return;
    }

    async function fetchMerchant() {
      const response = await Axios.get(`/admin/merchants/${merchantId}.json`);
      const { id, name }: Merchant = response.data;
      setInputValue(name);
      setInputItems([{ id, name }]);
      setReady(true);
    }

    fetchMerchant();
  }, [merchantId, ready]);

  if (!ready) return <span>Loading</span>;

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      <input {...getInputProps({ placeholder: "Search for merchant" })} className="form-control" />
      <ul
        {...getMenuProps()}
        style={{
          display: isOpen && value.length > 0 && inputItems.length > 0 ? "block" : "none",
          border: "1px solid #ccc",
          background: "white",
          listStyle: "none",
          margin: "0px",
          padding: "5px",
          position: "absolute",
          width: "250px",
          height: "auto",
          zIndex: 10,
        }}
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              style={highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.name} ({item.id})
            </li>
          ))}
      </ul>{" "}
      ({merchantId})
    </div>
  );
}
