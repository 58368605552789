import React from "react";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { Flex } from "@atoms";
import { groupsSorter } from "../../OrganizePlayersView";
import { PlayersListItem } from "../PlayersListItem";
import { AddPlayerInputForm } from "../AddPlayerInputForm";
import { PlayersListCampaignFragment } from "./__generated__/PlayersListCampaignFragment";
import { PlayersListViewerFragment } from "./__generated__/PlayersListViewerFragment";

// == Typings ============================================================

interface IProps {
  campaign: PlayersListCampaignFragment;
  viewer: PlayersListViewerFragment;
  selectedPlayerId?: string;
  setSelectedPlayerId: (id: string) => void;
  refetch: () => void;
}

// == Constants ============================================================

PlayersList.fragments = gql`
  fragment PlayersListViewerFragment on Viewer {
    id
    ...AddPlayerInputFormViewerFragment
  }

  fragment PlayersListCampaignFragment on Campaign {
    id
    noGroupFundraisers {
      id
      ...PlayersListItemNoGroupFragment
    }
    groups {
      id
      ...PlayersListItemGroupsFragment
    }
  }
  ${PlayersListItem.fragments}
  ${AddPlayerInputForm.fragments}
`;

// == Component ============================================================

export function PlayersList({
  campaign,
  selectedPlayerId,
  viewer,
  refetch,
  setSelectedPlayerId,
}: IProps) {
  // *** NOTE: variable name change - "group" TO "player" ***
  const players = groupsSorter(campaign?.groups, viewer?.id);
  return (
    <EPlayersList
      alignItems="center"
      flexDirection="column"
      justifyContent="flex-start"
      px={[1, 5]}
      py={2}
      width={1}
    >
      {players.map((player) => {
        const showInputForm = player.id === selectedPlayerId && player.id !== "NO_GROUP";
        return (
          <Flex alignItems="center" flexDirection="column" key={player.id} width={1}>
            <PlayersListItem
              key={player.id}
              player={player}
              refetch={refetch}
              setSelectedPlayerId={setSelectedPlayerId}
            />
            {showInputForm && (
              <AddPlayerInputForm
                player={player}
                refetch={refetch}
                setSelectedPlayerId={setSelectedPlayerId}
                viewer={viewer}
              />
            )}
          </Flex>
        );
      })}
    </EPlayersList>
  );
}

// == Styles ===============================================================

export const EPlayersList = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`;
