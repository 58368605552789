import React from "react";
import { ThemeProvider } from "emotion-theming";
import { isPast, isBefore } from "date-fns";
import Color from "color";

const primaryColorDefault = "#48B1Bf";
const txtColorDefault = "#666";
const purple = "#5E28FF";
const lightPurple = "#AE93FF";

export const themed = (key: string) => (props: { theme: typeof theme }) => props.theme[key]; // used for theming on atoms

export type TThemeFontNames = keyof typeof theme.fonts;
export type TThemeButtonVariants = keyof typeof theme.buttons;
export type TThemeTextStyles = keyof typeof theme.textStyles;

// PLEASE UPDATE BOTH BREAKPOITNS IF UPDATING
const BREAKPOINTS = ["576px", "768px", "992px", "1200px"];
export const REACT_SOCKS_BREAKPOINTS: [
  { xsmall: number },
  { small: number },
  { medium: number },
  { large: number },
  { xlarge: number }
] = [{ xsmall: 0 }, { small: 576 }, { medium: 768 }, { large: 992 }, { xlarge: 1200 }];

// const BREAKPOINT_KEYS = Object.keys(BREAKPOINTS);
const notification = "#FFB82E";
const notificationColor = "#000";
const transferColor = "#28A745";
const oct31 = new Date(2021, 10, 1); // nov 1st
const dec6 = new Date(2021, 10, 30); // nov 29th
const getLinkPromo = () => {
  if (isBefore(new Date(), oct31)) return "#f94916";
  if (isBefore(new Date(), dec6)) return "#414042";
  return notification;
};

const getLinkPromoColor = () => {
  if (isBefore(new Date(), oct31)) return "#fff";
  if (isBefore(new Date(), dec6)) return "#fff";
  return notificationColor;
};

const getLinkPromoHrefColor = () => {
  if (isBefore(new Date(), oct31)) return "#000";
  if (isBefore(new Date(), dec6)) return primaryColorDefault;
  return primaryColorDefault;
};

const linkPromo = getLinkPromo();
const linkPromoText = getLinkPromoColor();
const linkPromoHref = getLinkPromoHrefColor();
// https://styled-system.com/table FOR REFERENCE
export const generateTheme = (overrides?: { primary?: string }) => {
  const defaultTheme = { primary: primaryColorDefault, txtColor: txtColorDefault };
  const newTheme = { ...defaultTheme, ...overrides };
  const { primary, txtColor } = newTheme;
  const lightPrimaryBackground = Color(primary)
    .lighten(0.8)
    .hex();
  const danger = "#D9534F";
  return {
    breakpoints: BREAKPOINTS,
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
    colors: {
      primary,
      link: primary,
      placeholderColor: "#CCC",
      placeholderLightGrey: "#E0E0E0",
      success: "#28a745",
      danger: "#D9534F",
      lightPrimary: "#F8FDFD",
      darkerPrimary: "#27B7BA",
      transferColor,
      white: "#FFF",
      em: "#000", // to coincide with em from theme
      black: "#000",
      gray: "#CCC",
      grey: "#CCC",
      lightBg: "#FAFBFC",
      lighterPrimaryBg: "#F9FCFC",
      lightPrimaryBg: "#E0F6F6",
      lightBlueBg: "#EDF7F8",
      lightGrayBg: "#EEE",
      lightGreyBg: "#EEE",
      default: txtColor,
      notification,
      lightNotificationBackground: "#FDFADF",
      notificationColor,
      linkPromo,
      linkPromoText,
      linkPromoHref,
      subText: "#AAA",
      inputBorder: "#999",
      purple,
      lightPurple,
      type: {
        default: txtColor,
        heading: "#000",
        link: primary,
        h2: txtColor,
      },
      closeTeamLightBg: "#DFF1F0",
      closeTeamDarkBg: "#3BA6A2",
      closeTeamOrange: "#F58C27",
      orange: "#FF8C5E",
      components: {
        card: {
          borderColor: "#F3F3F3",
          formBorderColor: "#E7E7E7",
        },
      },
    },
    lineHeights: {
      solid: 1,
      title: 1.25,
      copy: 1.5,
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256],
    fonts: {
      main: "'CerebriSans', arial",
      serif: "Georgia",
      sans: "'CerebriSans', arial",
      mono: "Menlo, monospace",
    },
    shadows: {
      small: "0 0 4px rgba(0, 0, 0, .125)",
      large: "0 0 24px rgba(0, 0, 0, .125)",
      lightShadow: "0 1px 2px rgba(0,0,0,0.2)",
      modalBoxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
      bottomBoxShadow: "0 7px 5px -8px #bbbbbb",
      regularShadow: "0 2px 4px rgba(0,0,0,0.2)",
      topRegularShadow: "0 -2px 4px rgba(0,0,0,0.2)",
      floatingButton: "0 2px 4px rgba(0,0,0,0.6)",
    },
    textStyles: {
      legal: {
        fontSize: 12,
      },
      givesText: {
        fontWeight: 400,
        color: primary,
        fontSize: 20,
      },
      bulkShopText: {
        fontWeight: 400,
        color: purple,
        fontSize: 20,
      },
      blockText: {
        fontSize: 12,
        textTransform: "uppercase",
      },
    },
    buttons: {
      primary: {
        color: "#FFF",
        border: `1px solid ${primary}`,
        background: `linear-gradient(to right, ${Color(primary)
          .darken(0.1)
          .hex()} 0%, ${primary} 100%)`,
        "&:hover": {
          background: primary,
        },
      },
      highlight: {
        color: "#FFF",
        border: `1px solid #F2973D`,
        background: "#F2973D",
        "&:focus": {
          color: "#FFF",
          backgroundColor: `${Color("#F2973D").darken(0.2)}!important`,
        },
        "&:hover": {
          backgroundColor: `${Color("#F2973D").darken(0.1)}!important`,
        },
      },
      danger: {
        color: "#FFF",
        border: `1px solid ${danger}`,
        background: `linear-gradient(to right, ${Color(danger)
          .darken(0.1)
          .hex()} 0%, ${danger} 100%)`,
        "&:hover": {
          background: danger,
        },
      },
      clear: {
        color: primary,
        backgroundColor: "transparent",
        border: `1px solid ${primary}`,
        "&:hover": {
          // color: "#FFF",
          // backgroundColor: primary,
        },
      },
      whiteTransparent: {
        color: "#FFF",
        backgroundColor: "transparent",
        border: `1px solid #FFF`,
        "&:hover": {
          // color: "#FFF",
          // backgroundColor: primary,
        },
      },
      clearWhite: {
        color: primary,
        backgroundColor: "white",
        border: `1px solid ${primary}`,
        "&:hover": {
          // color: "#FFF",
          // backgroundColor: primary,
        },
      },
      clearDanger: {
        color: danger,
        backgroundColor: "transparent",
        border: `1px solid ${danger}`,
      },
      promo: {
        color: linkPromoText,
        backgroundColor: linkPromo,
        border: `1px solid ${linkPromo}`,
        "&:hover": {
          // color: "#FFF",
          // backgroundColor: primary,
        },
      },
      promoClear: {
        color: txtColor,
        backgroundColor: "white",
        border: `1px solid #CCC`,
        "&:focus": {
          // color: "#FFF",
          backgroundColor: "white",
        },
      },
      clearGrey: {
        color: txtColor,
        backgroundColor: "transparent",
        border: `1px solid #CCC`,
        "&:hover": {
          // color: "#CCC",
          // backgroundColor: primary,
        },
      },
      fadedButton: {
        color: primary,
        backgroundColor: lightPrimaryBackground,
        border: `1px solid ${primary}`,
        "&:focus": {
          // color: "#CCC",
          backgroundColor: lightPrimaryBackground,
        },
      },
      disabled: {
        color: "#FFF",
        backgroundColor: "#CCC",
        cursor: "not-allowed",
        border: `1px solid #CCC`,
        opacity: 0.6,
        "&:active": {
          color: "#FFF",
          backgroundColor: "#CCC",
        },
        "&:focus": {
          color: "#FFF",
          backgroundColor: "#CCC",
        },
      },
      disabledSolid: {
        color: "#FFF",
        backgroundColor: "#ccc",
        cursor: "not-allowed",
        border: `1px solid #ccc`,
        "&:active": {
          color: "#fff",
          backgroundColor: "#ccc",
        },
        "&:focus": {
          color: "#fff",
          backgroundColor: "#ccc",
        },
      },
      bulkShop: {
        color: "#FFF",
        border: `1px solid ${purple}`,
        background: `linear-gradient(to right, ${Color(purple)
          .darken(0.1)
          .hex()} 0%, ${purple} 100%)`,
        "&:hover": {
          background: purple,
        },
      },
    },
    collections: {
      content: {
        center: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        bottomLeft: {
          display: "flex",
          justifyContent: "flex-end",
        },
      },
    },
    typography: {
      h1Size: [4, 5, 6],
    },
    borders: {
      silver: "1px solid #ccc",
      lightSilver: "1px solid #ddd",
      sponsor: "1px solid #FFB82E",
      primary: `1px solid ${primary}`,
      input: "1px solid #999999",
      primaryCircle: `2px solid ${primary}`,
      fundsTabActive: `3px solid ${primary}`,
      fundsTabInActive: `3px solid transparent`,
      lightGrayDashed: `1px dashed #aaa`,
      lightGraySolid: "1px solid #aaa",
      card: `1px solid #F3F3F3`,
      avatarHighlight: `3px solid ${primary}`,
    },
    // sizes: custom sizes shared in the app
    sizes: {
      profileImageSize: "50px",
      containerWidth: 1140,
      modalWidth: 660,
      modalSmallWidth: 500,
    },
    radii: {
      giftcard: 4,
      mapImage: 4,
      input: 4,
      circle: "100%",
      modal: 4,
      default: 4,
      badge: 20,
    },
  };
};

export const theme = generateTheme();

// For use with enzyme in tests, we maye need to look at doing something like:
// https://github.com/styled-components/jest-styled-components/issues/191#issuecomment-508752557
// https://github.com/styled-components/styled-components/issues/1319
export const withThemeProvider = (children: React.ReactNode) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
