import React from "react";
import gql from "graphql-tag";
import { Box, IBoxProps, Text, H3, TouchableOpacity } from "@atoms";
import { renderIf } from "@services";
import { useToggle, useStoreAppValue } from "@hooks";
import { theme } from "@styles";
import { LinkModalHtmlTerms } from "../LinkModalHtmlTerms";
import { LinkModalTermsFragment } from "./__generated__/LinkModalTermsFragment";

// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps extends IBoxProps {
  link: LinkModalTermsFragment;
}

// == Constants ============================================================

const FRAGMENTS = {
  fields: gql`
    fragment LinkModalTermsFragment on Link {
      id
      exclusions
      exclusionGiftCardNotEligible
      exclusionPurchaseWithGiftCardNotEligible
      variableRates
      terms
      termsSummary
    }
  `,
};

// == Component ============================================================

const LinkModalTerms = ({ link, ...rest }: IProps) => {
  const [showFullTerms, toggleShowFullTerms] = useToggle(true);
  const {
    isBulkShop,
    exclusions,
    exclusionGiftCardNotEligible,
    exclusionPurchaseWithGiftCardNotEligible,
    variableRates,
    terms,
    termsSummary,
  } = link;

  const shouldShowExclusions = () => {
    return (
      !!exclusions || !!exclusionGiftCardNotEligible || !!exclusionPurchaseWithGiftCardNotEligible
    );
  };

  return (
    <Box {...rest}>
      {shouldShowExclusions() && (
        <Box mb={3}>
          <H3>Exclusions</H3>
          {!!exclusions && <LinkModalHtmlTerms source={exclusions} />}

          {!!exclusionGiftCardNotEligible && (
            <Text display="block" mt={1} textStyle="legal">
              Gift cards are not eligible for cash back.
            </Text>
          )}

          {!!exclusionPurchaseWithGiftCardNotEligible && (
            <Text display="block" mt={1} textStyle="legal">
              Purchases made with a gift card are not eligible for cash back.
            </Text>
          )}
        </Box>
      )}
      {renderIf(!!variableRates)(
        <Box mb={3}>
          <H3>Variable Cash Back Rates</H3>
          <LinkModalHtmlTerms source={variableRates} />
        </Box>
      )}
      {renderIf(!!terms || !!termsSummary)(
        <Box mb={3}>
          <H3>Terms & Conditions</H3>
          {renderIf(!!termsSummary)(
            <Text display="block" textStyle="legal">
              <LinkModalHtmlTerms source={termsSummary} />
            </Text>
          )}
          {renderIf(!!terms)(
            <>
              {renderIf(showFullTerms)(<LinkModalHtmlTerms source={terms} />)}
              <TouchableOpacity onTouch={toggleShowFullTerms}>
                <Text color="primary" display="block" mb={2} textStyle="legal">
                  {showFullTerms ? "Hide" : "See"} full terms & conditions
                </Text>
              </TouchableOpacity>
            </>
          )}
        </Box>
      )}
      {/* in case it comes back */}
      {/* <Box>
        <H3>To earn cash back:</H3>
        <ul className="text-tiny browser-default mb-0">
          <li>
            <Text>
              Only use coupon codes advertised here. The use of codes from other websites can void
              your cash back
            </Text>
          </li>
          <li>
            <Text>
              Do not pay with Loyalty Points or Rewards Cash. This will void your cash back
            </Text>
          </li>
        </ul>
      </Box> */}
    </Box>
  );
};

LinkModalTerms.fragments = FRAGMENTS;

// == Styles ===============================================================
export default LinkModalTerms;
