import React, { useState, useEffect, useRef } from "react";
import { useCombobox } from "downshift";
import styled from "@emotion/styled";
import debounce from "lodash/debounce";
import { Breakpoint } from "react-socks";
import { navigate } from "@reach/router";
import queryString from "query-string";
import { useNearbyViewContext, useStoreAppValue, useToggle, useWindowSize } from "@hooks";
import { constants } from "@services";
import { Box, SearchIcon, Text, Flex, FontAwesome, TouchableOpacity, H2 } from "@atoms";
import NearbySearchResultQuery from "../NearbySearchResult/NearbySearchResultQuery";
import { NearbyLocationSearch } from "../NearbyLocationSearch";
import { NearbyDepartmentSearch } from "../NearbyDepartmentSearch";
import { BrandListLinkTypeFilters } from "../../brandListComponents";
import { NearbyMap } from "../NearbyMap";

// == Types ================================================================

interface IProps {
  location: $FixMe;
}

// == Constants ============================================================

NearbySearch.defaultProps = {};

// == Component ============================================================

export default function NearbySearch({ location }: IProps) {
  const { currency } = useStoreAppValue();
  const debouncedOnStateChange = useRef(null);
  const [mapView, setMapView] = useToggle(false);
  const [nearbyViewState, nearbyViewDispatch] = useNearbyViewContext();
  const { department, departmentName } = nearbyViewState;
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const [brandName, setBrandName] = useState(null);
  const queryParams = queryString.parse(location.search);
  const params = Object.keys(queryParams);
  const { width } = useWindowSize();

  if (!debouncedOnStateChange.current) {
    debouncedOnStateChange.current = debounce((newInputValue) => {
      if (typeof newInputValue !== "undefined") {
        setInputValue(newInputValue);
        nearbyViewDispatch({ type: "UPDATE_QUERY", query: newInputValue });
        navigate(location.pathname);
      }
    }, 500);
  }

  useEffect(() => {
    if (params.includes("b")) {
      const brandName = queryParams.brand as string;
      setBrandName(brandName);
    }
    if (width > 768) {
      inputRef?.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { linkTypes } = nearbyViewState;

  const stateReducer = (state, actionAndChanges) => {
    switch (actionAndChanges.type) {
      case useCombobox.stateChangeTypes.InputChange:
        setBrandName(null);
        nearbyViewDispatch({ type: "START_SEARCHING" });
        return {
          ...actionAndChanges.changes,
          inputValue: actionAndChanges.changes.inputValue,
        };
      default:
        return actionAndChanges.changes;
    }
  };

  const { getInputProps, getComboboxProps } = useCombobox({
    items: [],
    onInputValueChange: ({ inputValue }) => {
      if (debouncedOnStateChange?.current) {
        debouncedOnStateChange.current(inputValue);
      }
    },
    stateReducer,
  });

  // can be in context provider
  const onSelectCallback = (latitude: number, longitude: number, address: string) => {
    nearbyViewDispatch({
      type: "UPDATE_LOCATION",
      coordinates: { latitude, longitude, address },
    });
  };

  const onDepartmentSelect = (department: string | null) => {
    nearbyViewDispatch({ type: "UPDATE_DEPARTMENT", department });
  };

  const onMapViewToggle = () => {
    setMapView(!mapView);
  };

  const searchInputValue = () => {
    if (department && inputValue === "") {
      return departmentName;
    }
    if (brandName) {
      return brandName;
    }
    return undefined;
  };

  const onResetForm = () => {
    setInputValue("");
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
    nearbyViewDispatch({ type: "RESET_SEARCH" });
  };

  return (
    <Flex flexDirection="column" height="100%" position="relative">
      <Box borderBottom="silver" pb={currency === "USD" ? 0 : 3} position="relative" pt={3} px={3}>
        <Flex>
          <Box flex={1}>
            <Box {...getComboboxProps()} position="relative">
              {inputValue === "" && (
                <NearbyDepartmentSearch onDepartmentSelect={onDepartmentSelect} />
              )}
              <EText color="primary">
                <SearchIcon />
              </EText>
              <ESearchInput
                placeholder={constants.SEARCH_PLACEHOLDER}
                {...getInputProps()}
                ref={inputRef}
                value={searchInputValue()}
              />
            </Box>
            <Flex>
              <NearbyLocationSearch onSelectCallback={onSelectCallback} />
            </Flex>
          </Box>
          <Breakpoint down small>
            <Flex alignItems="center" border="silver" borderLeft="none" height="100%" p={2}>
              <TouchableOpacity onTouch={onMapViewToggle}>
                <Text color="primary">{mapView ? "List" : "Map"}</Text>
              </TouchableOpacity>
            </Flex>
          </Breakpoint>
        </Flex>
        <BrandListLinkTypeFilters
          nearbySearch
          dispatch={nearbyViewDispatch}
          linkCategoryFilter={nearbyViewState.linkCategoryFilter}
          my={0}
          p={3}
          showRegister
        />
      </Box>
      <EBreakpoint down small>
        {mapView && <NearbyMap mapView />}
        <EResultsContainer display={mapView ? "none" : "block"}>
          <NearbySearchResultQuery location={location} onResetForm={onResetForm} />
        </EResultsContainer>
      </EBreakpoint>
      <EBreakpoint medium up>
        <EResultsContainer>
          <NearbySearchResultQuery location={location} onResetForm={onResetForm} />
        </EResultsContainer>
      </EBreakpoint>
    </Flex>
  );
}

// == Styles ===============================================================

const EResultsContainer = styled(Box)`
  background: white;
`;

const EText = styled(Text)`
  position: absolute;
  left: 15px;
  top: 13px;
`;

const ESearchInput = styled.input`
  margin-bottom: 0 !important;
  height: 40px !important;
  border-radius: 0 !important;
  border-color: #ccc !important;
  padding-top: 0 !important;
  border-bottom: 0 !important;
  padding-left: 40px !important;
`;

const EBreakpoint = styled(Breakpoint)`
  height: 100%;
  overflow-y: auto;
`;
