import React from "react";
import { Spinner, Box } from "@components";

const Loading = () => (
  <Box my={3}>
    <Spinner />
  </Box>
);

export default Loading;
