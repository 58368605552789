import React from "react";
import gql from "graphql-tag";
import { Box, Text, Flex } from "@atoms";
import { formatMoney } from "@services";
import { FundsPageWithdrawalsChartBarGraph } from "../FundsPageWithdrawalsChartBarGraph";
import {
  FundsPageWithdrawalsChartFragment,
  FundsPageWithdrawalsChartFragment_amountWithdrawnBreakdown,
} from "./__generated__/FundsPageWithdrawalsChartFragment";

// == Types ================================================================

interface IAmountBreakdown {
  amountWithdrawnBreakdown: FundsPageWithdrawalsChartFragment_amountWithdrawnBreakdown;
}
interface IProps {
  campaign: FundsPageWithdrawalsChartFragment;
}

// == Constants ============================================================

FundsPageWithdrawalsChart.fragments = gql`
  fragment FundsPageWithdrawalsChartFragment on Campaign {
    id
    name
    amountWithdrawnBreakdown @include(if: $isMember) {
      available {
        dollarsAmount
        formatted
      }
      withdrawn {
        dollarsAmount
      }
      pending {
        dollarsAmount
      }
      fees {
        dollarsAmount
      }
    }
    ...FundsPageWithdrawalsChartBarGraphFragment
  }
  ${FundsPageWithdrawalsChartBarGraph.fragments}
`;

// == Functions ============================================================

const totalMaker = (amountWithdrawnBreakdown: IAmountBreakdown) => {
  const {
    available: { dollarsAmount: availableDollarsAmount },
    withdrawn: { dollarsAmount: withdrawnDollarsAmount },
    pending: { dollarsAmount: pendingDollarsAmount },
    fees: { dollarsAmount: feesDollarsAmount },
  } = amountWithdrawnBreakdown;
  return formatMoney(
    +availableDollarsAmount + +withdrawnDollarsAmount + +pendingDollarsAmount + +feesDollarsAmount
  );
};

// == Component ============================================================

export default function FundsPageWithdrawalsChart({ campaign }: IProps) {
  const {
    amountWithdrawnBreakdown: {
      available: { formatted },
    },
  } = campaign;

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <Box paddingTop={[3, 4]}>
        <Text fontSize={[4, 6]}>{formatted}</Text>
      </Box>
      <Box paddingTop={[1, 2, 3]}>
        <Text color="primary" fontSize={[2, 2, 3]}>
          available of {totalMaker(campaign.amountWithdrawnBreakdown)} total
        </Text>
      </Box>
      <Box padding={[1, 2, 3]} width={0.8}>
        <FundsPageWithdrawalsChartBarGraph campaign={campaign} />
      </Box>
    </Flex>
  );
}

// == Styles ===============================================================
