import { HTMLAttributes, useMemo } from "react";
import { FontAwesome } from "@components/common";
import { useUserAgent } from "@hooks";
import { useCookies } from "react-cookie";

// == Types ================================================================

interface IProps {}
type TBrowserNames = "Chrome" | "Safari" | "Edge";

// == Constants ============================================================
const maxBannerData: Record<TBrowserNames, { browser: TBrowserNames; url: string }> = {
  Chrome: {
    browser: "Chrome",
    url: "https://chrome.google.com/webstore/detail/flipgive-max/aoljonbhacccfmnfknimhpldbikaeghl",
  },
  Safari: {
    browser: "Safari",
    url: "https://apps.apple.com/app/flipgive-max/id1571766989?mt=12",
  },
  Edge: {
    browser: "Edge",
    url:
      "https://microsoftedge.microsoft.com/addons/detail/flipgive-max/jngoheiedbmnfhhldpabngmmaipdngeb",
  },
};
// == Component ============================================================

export function MaxBanner(_props: IProps) {
  const [cookies, setCookie] = useCookies();
  const { maxBannerClosed } = cookies;

  const { isClosed, toggleIsClosed } = useMemo(() => {
    const isClosedValue = JSON.parse(maxBannerClosed ?? "false");
    return {
      isClosed: isClosedValue,
      toggleIsClosed: (value: boolean) => {
        setCookie("maxBannerClosed", JSON.stringify(value), { path: "/" });
      },
    };
  }, [maxBannerClosed]);
  const { isSafari, isChrome, isEdge, isMobile } = useUserAgent();
  const shouldHideMax = !isChrome && !isSafari && !isEdge;
  const isFundsTab = useMemo(() => {
    if (!window) return false;
    const url = window.location.href;
    return url.includes("/funds");
  }, []);

  const maxData = useMemo(() => {
    if (isChrome) {
      return maxBannerData.Chrome;
    }
    if (isSafari) {
      return maxBannerData.Safari;
    }
    if (isEdge) {
      return maxBannerData.Edge;
    }
    return null;
  }, [isChrome, isSafari, isEdge]);

  const goToDownloadLink = () => {
    window.open(maxData?.url);
  };

  if (isClosed || shouldHideMax || isMobile || isFundsTab) return null;
  return (
    <div style={styles.wrapper}>
      <div style={styles.innerWrapper}>
        <div style={styles.imgWrapper}>
          <img
            alt="max"
            height="120px"
            src="https://flipgive.twic.pics/images/max.png"
            width="200px"
          />
        </div>
        <p style={styles.mainText} className="text-xl text-gray-900 text-center ml-48 flex-1">
          <span style={styles.boldText}>Never</span> miss out on cash back with{" "}
          <span style={styles.boldText}>FlipGive Max!</span>
        </p>
        <button style={styles.ctaButton} type="button" onClick={goToDownloadLink}>
          Add to {maxData?.browser}
        </button>
        <FontAwesome
          icon={["fas", "times"]}
          style={{ cursor: "pointer" }}
          onClick={() => toggleIsClosed(true)}
        />
      </div>
    </div>
  );
}

// == Styles ===============================================================
const styles: Record<string, HTMLAttributes<HTMLDivElement>["style"]> = {
  wrapper: {
    backgroundColor: "#F9B634",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    height: "80px",
    zIndex: 5,
  },
  innerWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: 0,
    justifyContent: "space-between",
    maxWidth: "920px",
    margin: "0 auto",
  },
  imgWrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
    marginBottom: "-3px",
  },
  mainText: {
    fontSize: "1.25rem",
    color: "#1F2937",
    textAlign: "center",
    marginLeft: "12rem",
    flex: 1,
    marginTop: "20px",
  },
  boldText: {
    fontWeight: "bold",
  },
  ctaButton: {
    color: "#fff",
    backgroundColor: "#000",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "1rem",
    appearance: "none",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#000",
    borderRadius: "0.25rem",
    fontSize: "1rem",
  },
};
