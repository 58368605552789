import React from "react";
import gql from "graphql-tag";
import "@reach/menu-button/styles.css";
import { useMutation } from "react-apollo";
import { useStoreAppValue } from "@hooks";
import { Modal, Box, Flex, Text, TouchableOpacity, ChevronLeft, Button } from "@atoms";
import { FundraiserRole } from "@types";
import {
  BudgetFundraiserUpdateRoles,
  BudgetFundraiserUpdateRolesVariables,
} from "./__generated__/BudgetFundraiserUpdateRoles";

// == Types ================================================================

interface IProps {
  id: string;
  name: string;
  isBudgetAdmin: boolean;
  refetch: () => void;
  closeModal: () => void;
}

// == Constants ============================================================

const BUDGET_UPDATE_ROLES_MUTATION = gql`
  mutation BudgetFundraiserUpdateRoles($input: BudgetFundraiserUpdateRolesInput!) {
    BudgetFundraiserUpdateRoles(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on BudgetFundraiserUpdateRolesPayload {
        fundraiser {
          id
          roles
        }
      }
    }
  }
`;

// == Component ============================================================

export default function ReassignMember({ id, name, refetch, closeModal, isBudgetAdmin }: IProps) {
  const [updateBudgetRoles, { loading }] = useMutation<
    BudgetFundraiserUpdateRoles,
    BudgetFundraiserUpdateRolesVariables
  >(BUDGET_UPDATE_ROLES_MUTATION, {
    onCompleted: ({ BudgetFundraiserUpdateRoles }) => {
      if (BudgetFundraiserUpdateRoles.__typename === "MutationError") return;
      Materialize.toast(
        isBudgetAdmin ? "Access has been revoked" : "Access has been granted",
        4000,
        "rounded toast-success"
      );
      refetch();
      closeModal();
    },
  });

  const handleButtonClick = () => {
    updateBudgetRoles({
      variables: {
        input: {
          id,
          roles: isBudgetAdmin ? [] : [FundraiserRole.CAPTAINABLE],
        },
      },
    });
  };

  const primary = useStoreAppValue()?.theme?.color?.primary ?? "#27B7BA";
  const buttonText = isBudgetAdmin ? "Revoke Access" : "Grant Access";

  return (
    <Modal isOpen onDismiss={closeModal}>
      <Flex alignItems="center" flexDirection="column" justifyContent="center" padding={3}>
        <Flex alignSelf="flex-start" paddingBottom={[2, 3]}>
          <TouchableOpacity onTouch={closeModal}>
            <ChevronLeft color={primary} fontSize={[2, 3, 4]} />
          </TouchableOpacity>
          <Box paddingLeft={[2, 3]}>
            <Text fontWeight="bold">Budget Admin</Text>
          </Box>
        </Flex>
        <Flex alignItems="center" flexDirection="column" py={2} width={1}>
          <Box display="inline" textAlign="center">
            {isBudgetAdmin ? (
              <>
                <Text>Revoke</Text>
                <Text color="primary" fontWeight="bold">
                  {" "}
                  {name}'s{" "}
                </Text>
                <Text>administrative rights to view and edit team budget information.</Text>
              </>
            ) : (
              <>
                <Text>Give</Text>
                <Text color="primary" fontWeight="bold">
                  {" "}
                  {name}{" "}
                </Text>
                <Text>administrative rights to view and edit team budget information.</Text>
              </>
            )}
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" py={2}>
          <Button
            height={50}
            minWidth={250}
            variant={isBudgetAdmin ? "danger" : "primary"}
            onClick={handleButtonClick}
          >
            {loading ? "Processing..." : buttonText}
          </Button>
        </Flex>
        <Box py={2} textAlign="center" width={1}>
          <Button height={50} minWidth={250} variant="clear" onClick={closeModal}>
            <Text color="primary" fontSize={[1, 2]}>
              Cancel
            </Text>
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
}

// == Styles ===============================================================
