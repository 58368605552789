import React from "react";
import gql from "graphql-tag";
import produce from "immer";
import { useMutation } from "react-apollo";
import { ErrorService } from "@services";
import { Button, Text } from "@atoms";
import { IGiftcardReloadState, TGiftcardReloadDispatch } from "./types";

// == Types ================================================================

interface IProps {
  state: IGiftcardReloadState;
  dispatch: TGiftcardReloadDispatch;
}

// == Constants ============================================================

export const GIFTCARD_RELOADABLE_SAVE_BUTTON_MUTATION = gql`
  mutation GiftcardReloadSaveButtonMutation($input: ReloadableGiftcardUpdateInputType!) {
    ViewerReloadableGiftcardUpdate(input: $input) {
      ... on MutationError {
        errors {
          input
        }
      }
      ... on Giftcard {
        id
        isReloadable
        value {
          formatted
          dollarsAmount
        }
        reloadInfo {
          isReloadingEnabled
          reloadPercentage
          stripeCardId
        }
        campaign {
          id
          name
        }
      }
    }
  }
`;

// == Component ============================================================
export function GiftcardReloadSaveButton({ state, dispatch }: IProps) {
  const [updateGiftcardMutation, { loading, error }] = useMutation(
    GIFTCARD_RELOADABLE_SAVE_BUTTON_MUTATION,
    {
      onCompleted(resp) {
        if (resp?.ViewerReloadableGiftcardUpdate.__typename !== "MutationError") {
          dispatch({ type: "SUCCESSFUL_SAVE" });
        } else {
          dispatch({ type: "FAILED_SAVE" });
        }
      },
    }
  );
  const onSubmit = async () => {
    try {
      const input = produce({}, (draft) => {
        draft.giftcardId = state.giftcard.id;
        draft.isReloadingEnabled = state.isEnabled;
        draft.reloadThresholdCents = state.reloadThresholdCents;
        draft.campaignId = state.selectedCampaignId;
        if (state.stripeCardId) draft.stripeCardId = state.stripeCardId;
        if (state.stripeTokenId) draft.stripeTokenId = state.stripeTokenId;
      });

      await updateGiftcardMutation({
        variables: {
          input,
        },
      });
    } catch (e) {
      ErrorService.error(e);
    }
  };

  return (
    <>
      <Button disabled={loading || state.disabled} width="100%" onClick={onSubmit}>
        {loading ? "Saving..." : "Save"}
      </Button>
      {(error || state.error) && (
        <Text color="danger" display="block" mt={1} textAlign="center">
          {state.error || error?.message}
        </Text>
      )}
    </>
  );
}
