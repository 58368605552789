import React from "react";
import { Text } from "@atoms";
import { shape, string, bool } from "prop-types";
import { AvatarImage } from "@components";

const switchUrl = ({ title, switchTeamUrl, campaign, isClosed }) => {
  if (isClosed) {
    return campaign.url;
  }
  return `${switchTeamUrl}?set_default=${campaign.id}`;
};

const SwitchTeamListItem = ({ campaign, switchTeamUrl, title, showCheckmark, isClosed }) => {
  const renderClassName = () => {
    const rowHeaderClass = isClosed
      ? "switch-team__row-header switch-team__row-header--closed"
      : "switch-team__row-header";
    const rowClass = isClosed ? "switch-team__row switch-team__row--closed" : "switch-team__row";

    return title === "Header" ? rowHeaderClass : rowClass;
  };

  return (
    <a href={switchUrl({ title, switchTeamUrl, campaign, isClosed })}>
      <div className={renderClassName()}>
        <div className="switch-team__info-container">
          <AvatarImage
            alt={campaign.name}
            className="switch-team__image"
            name={campaign.name}
            src={campaign.photoImage}
          />
          <div className="switch-team__details">
            <div className="switch-team__name color-text">{campaign.name}</div>
            <div className="switch-team__goal text-tiny">
              {campaign.amountRaised.formatted} Earned
            </div>
            {campaign.daysOrHoursLeftLabel && (
              <Text color="grey" fontSize="0">
                {campaign.daysOrHoursLeftLabel}
              </Text>
            )}
          </div>
        </div>
        {showCheckmark ? <i className="material-icons d-flex align-items-center">check</i> : null}
        {isClosed && <div className="d-flex align-items-center">CLOSED</div>}
      </div>
    </a>
  );
};

export default SwitchTeamListItem;

SwitchTeamListItem.propTypes = {
  campaign: shape({}).isRequired,
  switchTeamUrl: string.isRequired,
  title: string.isRequired,
  showCheckmark: bool.isRequired,
  isClosed: bool,
};

SwitchTeamListItem.defaultProps = {
  isClosed: false,
};
