import React, { createContext, useContext, useReducer, Reducer, Dispatch } from "react";
import produce from "immer";
import { constants } from "@services";
import { LinkCategory } from "@types";

export interface INearbyVariables {
  coordinates: { latitude: number; longitude: number; address: string };
  initialCoordinates: { latitude: number; longitude: number; address: string };
  department?: { id: string; name: string } | null;
  query?: string;
  first: number;
  after?: string;
  distance?: number;
  currency?: string;
  brandId?: string | null;
  linkCategoryFilter: LinkCategory | null;
  linkTypes: string[];
  linkTypeFilter: string | null;

  results: [];
  departmentName: string | null;
  brandHoveredId?: string | null;
  locationQuery: string;
  location: {};
  searching: boolean;
}

type IReducerActions =
  | { type: "UPDATE_QUERY"; query: string }
  | {
      type: "UPDATE_LOCATION";
      coordinates: { latitude: number; longitude: number; address: string };
      locationQuery?: string;
    }
  | {
      type: "UPDATE_DEPARTMENT";
      department: { id: string; name: string } | null;
    }
  | {
      type: "SET_TYPE_FILTER";
      linkCategoryFilter: LinkCategory | null;
    }
  | {
      type: "UPDATE_RESULTS";
      results: any;
    }
  | {
      type: "UPDATE_BRAND_HOVERED_ID";
      brandHoveredId: string;
    }
  | { type: "REMOVE_BRAND_HOVERED_ID" }
  | { type: "RESET_SEARCH" }
  | { type: "SET_DEFAULT_LOCATION" }
  | { type: "START_SEARCHING" }
  | {
      type: "UPDATE_BRAND_ID_SEARCH";
      brandId: string;
      query: string;
    };

// this is only used in storybook or testing
const INITIAL_STATE = {
  coordinates: { latitude: 43, longitude: -79 },
  // department: $department,
  query: "",
  first: 100,
  // after: $after,
  distance: 50,
  // currency: $currency,
  // brandId: $brandId,
  linkTypes: ["GIFTCARDOFFER", "EMPYR"],
};

const reducer: Reducer<INearbyVariables, IReducerActions> = (state, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "UPDATE_QUERY": {
        draft.query = action.query;
        draft.department = null;
        draft.brandId = null;
        // draft.distance = constants.DISTANCES_ENUM.DEFAULT_DISTANCE;
        break;
      }
      case "RESET_SEARCH": {
        // This is set here because NearbySearchLocation looks at coordinates to update the input
        // and we want the address to say "Current Location"
        draft.coordinates = {
          ...state.initialCoordinates,
          address: constants.CURRENT_LOCATION_TEXT,
        };
        draft.locationQuery = constants.CURRENT_LOCATION_TEXT;
        draft.distance = constants.DISTANCES_ENUM.DEFAULT_DISTANCE;
        draft.query = "";
        draft.linkCategoryFilter = null;
        draft.brandId = null;
        break;
      }
      case "SET_DEFAULT_LOCATION": {
        draft.coordinates = state.initialCoordinates;
        draft.locationQuery = constants.CURRENT_LOCATION_TEXT;
        draft.distance = constants.DISTANCES_ENUM.DEFAULT_DISTANCE;
        break;
      }
      case "UPDATE_LOCATION": {
        draft.coordinates = action.coordinates;
        draft.distance =
          action.locationQuery === constants.ALL_LOCATIONS_TEXT
            ? constants.DISTANCES_ENUM.ALL_LOCATIONS
            : constants.DISTANCES_ENUM.DEFAULT_DISTANCE;
        break;
      }
      case "UPDATE_DEPARTMENT": {
        draft.department = action.department?.id;
        draft.departmentName = action.department?.name;
        break;
      }
      case "SET_TYPE_FILTER": {
        draft.linkCategoryFilter = action.linkCategoryFilter;
        break;
      }
      case "UPDATE_RESULTS": {
        draft.results = action.results;
        draft.searching = false;
        break;
      }
      case "UPDATE_BRAND_HOVERED_ID": {
        draft.brandHoveredId = action.brandHoveredId;
        break;
      }
      case "REMOVE_BRAND_HOVERED_ID": {
        draft.brandHoveredId = null;
        break;
      }
      case "UPDATE_BRAND_ID_SEARCH": {
        draft.brandId = action.brandId;
        draft.linkCategoryFilter = null;
        draft.query = action.query;
        draft.department = null;
        draft.distance = constants.DISTANCES_ENUM.ALL_LOCATIONS;
        break;
      }
      case "START_SEARCHING": {
        draft.searching = true;
        break;
      }
      default:
        break;
    }
  });
};

const StateContext = createContext<INearbyVariables | undefined>(undefined);
const StateDispatchContext = createContext<Dispatch<IReducerActions> | undefined>(undefined);

export function NearbyViewProvider({
  initialState,
  children,
}: {
  initialState: INearbyVariables;
  children: React.ReactNode;
}) {
  const [nearbyViewState, nearbyViewDispatch] = useReducer(reducer, initialState);

  return (
    <StateDispatchContext.Provider value={nearbyViewDispatch}>
      <StateContext.Provider value={nearbyViewState}>{children}</StateContext.Provider>
    </StateDispatchContext.Provider>
  );
}

export function useNearbyViewValue() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useNearbyViewValue must be used within a NearbyViewProvider");
  }
  return context;
}

export function useNearbyViewDispatch() {
  const context = useContext(StateDispatchContext);
  if (context === undefined) {
    throw new Error("useNearbyViewDispatch must be used within a NearbyViewProvider");
  }
  return context;
}

export const useNearbyViewContext = () => {
  return [useNearbyViewValue(), useNearbyViewDispatch()] as [
    INearbyVariables,
    Dispatch<IReducerActions>
  ];
};

// For use with enzyme in tests, we maye need to look at doing something like:
// https://github.com/styled-components/jest-styled-components/issues/191#issuecomment-508752557
// https://github.com/styled-components/styled-components/issues/1319
export const withNearbyViewProvider = (children: React.ReactNode) => (
  <NearbyViewProvider initialState={INITIAL_STATE}>{children}</NearbyViewProvider>
);
