import React from "react";
import gql from "graphql-tag";
import { RelatedLinkBlock } from "@organisms/linkBlockComponents/RelatedLinkBlock";
import { Text, Box, H3 } from "@atoms";
import { MultiItemCarousel } from "@molecules";
import { getPaginatedNodes } from "@utils";
import { LinkType } from "@types";
import { LinkModalBorder } from "../LinkModalBorder";
import { LinkModalRelatedLinksFragment } from "./__generated__/LinkModalRelatedLinksFragment";
// import styled from "@emotion/styled";

// == Types ================================================================

interface IProps {
  link: LinkModalRelatedLinksFragment;
}

// == Constants ============================================================

LinkModalRelatedLinks.defaultProps = {};

LinkModalRelatedLinks.fragment = gql`
  fragment LinkModalRelatedLinksFragment on Link {
    id
    type
    relatedLinks(first: 100, linkTypes: [DEPARTMENT]) {
      edges {
        cursor
        node {
          id
          squareImage
          ...RelatedLinkBlockFragment
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${RelatedLinkBlock.fragment}
`;
// == Component ============================================================

export function LinkModalRelatedLinks({ link, ...rest }: IProps) {
  if (![LinkType.TRAVELSHOP, LinkType.BRAND].includes(link.type)) return null;
  const relatedLinks = getPaginatedNodes(link.relatedLinks.edges);
  if (relatedLinks.length === 0) return null;
  return (
    <>
      <Box {...rest}>
        <H3 mb={2}>Departments</H3>
        <Box position="relative" px={[16]}>
          <MultiItemCarousel
            indent={[-9]}
            itemLength={relatedLinks.length}
            slidesPerPage={4}
            slidesPerScroll={4}
          >
            {relatedLinks.map((link, index) => (
              <Box alignSelf="flex-start" key={link.id} px={[1]} width="100%">
                <RelatedLinkBlock flush link={link} />
              </Box>
            ))}
          </MultiItemCarousel>
        </Box>
      </Box>
      <LinkModalBorder />
    </>
  );
}

// == Styles ===============================================================
