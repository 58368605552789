import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { oc } from "ts-optchain";
import isEmpty from "lodash/isEmpty";
import { useLoadingDelay } from "@hooks";
import { ErrorLoading } from "@organisms";
import FundsPagePayment from "./FundsPagePayment";

interface IProps {
  paymentId: string;
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

const FUNDS_PAGE_PAYMENT_QUERY = gql`
  query FundsPagePaymentQuery($id: ID!) {
    Campaign {
      id
      ...FundsPagePaymentFragment
    }
  }
  ${FundsPagePayment.fragments}
`;

export function FundsPagePaymentQuery({ paymentId, isModalOpen, setModalOpen }: IProps) {
  const loadingDelayReady = useLoadingDelay();

  const { data, error, loading } = useQuery(FUNDS_PAGE_PAYMENT_QUERY, {
    variables: {
      id: paymentId,
    },
  });

  if (loading || !loadingDelayReady) return null;
  if (error || isEmpty(data)) return <ErrorLoading />;

  const campaign = oc(data).Campaign();

  return (
    <FundsPagePayment campaign={campaign} isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
  );
}
