import React from "react";
import { Provider } from "mobx-react";
import { ApolloProvider } from "react-apollo";
import { ThemeProvider } from "emotion-theming";
import { Router, Match } from "@reach/router";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { REACT_SOCKS_BREAKPOINTS, generateTheme } from "@styles";
import { ErrorBoundary } from "@utils";
import { Settings, apolloClient, renderIf } from "@services";
// import { FlipgiveStore, TimelineStore } from "@stores";
import { StoreAppProvider, useStoreAppValue } from "@hooks";
// import { OccasionPage } from "./pages";
import { ShopSubTabs } from "@organisms";
import {
  OccasionView,
  Home,
  CollectionViewQuery,
  StoreFrontViewQuery,
  BrandListView,
  CheckoutViewQuery,
  CheckoutThanksViewQuery,
  MyActivityPage,
  DefaultStoreFrontViewQuery,
  TeamStoreFrontViewQuery,
  OnboardingRouter,
  FundsPageQuery,
  IntentLoadingViewMutation,
  OrganizeMembersViewQuery,
  OrganizePlayersViewQuery,
  NearbyView,
  OnboardingSplash,
  EventEditViewQuery,
  BeneficiaryRouter,
  GiftcardListPageQuery,
  DealsPage,
  CloseTeamReportPage,
} from "@pages";
import { RenderModals } from "./util";
import LocationProvider from "@hooks/context/LocationProvider/LocationProvider";
import { useFullStory } from "@hooks";
import { MaxBanner } from "@components/MaxBanner/MaxBanner";
import { SmartBanner } from "@components/SmartBanner/SmartBanner";

setDefaultBreakpoints(REACT_SOCKS_BREAKPOINTS);

const CampaignContainer = ({ children }) => {
  return <div>{children}</div>;
};

const CampaignRoot = ({ children, isCurrentUser }) => {
  const {
    featureFlags: { newCollection },
  } = useStoreAppValue();
  return (
    <Match path="/stories/*">
      {(props) =>
        props.match ? null : (
          <>
            {renderIf(!!newCollection) && <ShopSubTabs isCurrentUser={isCurrentUser} />}
            {children}
          </>
        )
      }
    </Match>
  );
};

const StoreApp = (props) => {
  const {
    campaignId,
    environment,
    incentiveAvailable,
    incentiveAmount,
    incentiveTimeLeft,
    primaryColor,
    featureFlags, // todo: remove featureFlags.reloadable
    currency,
    currentUser,
    fundraiserId,
    isMember,
    isOwner,
    isContinuity,
    stripeKey,
    mapLocation,
    siteName,
    gmapsKey,
    plaidKey,
    appUrl,
    recaptchaEnterpriseKey,
  } = props;
  Settings.setDefaultsFromProps(props);
  const theme = generateTheme({ primary: primaryColor });
  useFullStory(currentUser);
  const initialState = {
    featureFlags,
    campaignId,
    environment,
    incentiveAvailable,
    incentiveAmount,
    incentiveTimeLeft,
    currency,
    currentUser,
    fundraiserId,
    isMember,
    isOwner,
    isContinuity,
    stripeKey,
    mapLocation,
    theme,
    siteName,
    gmapsKey,
    plaidKey,
    activeStoreFrontType: null,
    appUrl,
    recaptchaEnterpriseKey,
  };

  // FlipgiveStore.setCurrentUser(props.currentUser);
  // FlipgiveStore.setCampaignId(props.campaignId);
  // TimelineStore.publicView = props.publicView;
  // TimelineStore.singleActivity = props.singleActivity || false;
  // TimelineStore.isPartner = props.isPartner;
  // TimelineStore.activityId = props.activityId;
  // TimelineStore.feed = props.feed.split(":")[0].toUpperCase();
  // TimelineStore.hideReactions = props.hideReactions || false;
  // console.log('is it loaded?')
  const client = apolloClient(Settings.apiUrl);
  return (
    <ErrorBoundary>
      <BreakpointProvider>
        <ApolloProvider client={client}>
          <Provider>
            <ThemeProvider theme={theme}>
              <StoreAppProvider initialState={initialState}>
                <LocationProvider>
                  <>
                    <SmartBanner appName="shop" />
                    <Router primary={false}>
                      {/* <Authentication>
                        <Authenticate path="/login" />
                        <Authenticate path="/signup" />
                        <JoinTeam path="/teams/join" />
                        <NewTeam path="/teams/new" />
                        <Onboarding path="/onboarding/questions/intro" />
                        <Onboarding path="/onboarding/questions/apparel" />
                        <Onboarding path="/onboarding/questions/equipment" />
                        <Onboarding path="/onboarding/questions/travel" />
                        <Onboarding path="/onboarding/questions/dining" />
                        <Onboarding path="/onboarding/questions/groceries" />
                        <Onboarding path="/onboarding/questions/success" />
                        <OnboardingBrands path="/onboarding/brands" />
                      </Authentication> */}
                      {!!featureFlags.newOnboarding && <OnboardingRouter path="onboarding/*" />}
                      <OnboardingSplash path="/teams/join" />
                      <IntentLoadingViewMutation runMutation path="/teams/:id/intents/new" />
                      <IntentLoadingViewMutation path="/teams/:id/intents/:intentId" />
                      <CampaignRoot isCurrentUser={!!currentUser} path="/">
                        <Home path="/" />
                        <OccasionView path="occasions/:id" />
                        <CollectionViewQuery path="collections/:id" />
                        <StoreFrontViewQuery isHome path="stores" />
                        <StoreFrontViewQuery path="stores/:id" />
                        <StoreFrontViewQuery isHome path="user/store" />
                        <BrandListView path="brands" />
                        {/* {nearbyTab && <NearbyView path="nearby" />} */}
                        {/* Campaign nested routes */}
                        {!!currentUser && (
                          <TeamStoreFrontViewQuery path="team-store" type="TEAM_STORE_DEFAULT" />
                        )}
                        {!!currentUser && (
                          <DefaultStoreFrontViewQuery path="deals-store" type="DEALS_DEFAULT" />
                        )}
                        {!!currentUser && (
                          <TeamStoreFrontViewQuery
                            path="teams/:campaignId/team-store"
                            type="TEAM_STORE_DEFAULT"
                          />
                        )}

                        <DefaultStoreFrontViewQuery
                          path="teams/:campaignId/deals-store"
                          type="DEALS_DEFAULT"
                        />

                        <DealsPage path="deals" />
                        <CampaignContainer path="teams/:campaignId">
                          {/* <StoreFrontViewQuery isHome path="/" /> */}
                          <Home isHome path="/" />
                          <Home isHome path="/home" />
                          <CollectionViewQuery path="collections/:id" />
                          <DefaultStoreFrontViewQuery path="stores" type="STORE_DEFAULT" />
                          <StoreFrontViewQuery path="stores/:id" />
                          <BrandListView path="brands" />
                          <DealsPage path="deals" />
                        </CampaignContainer>
                      </CampaignRoot>
                      <NearbyView path="teams/:id/nearby" />
                      <CloseTeamReportPage path="teams/:campaignId/report" />
                      <FundsPageQuery path="teams/:campaignId/funds" />
                      <OrganizeMembersViewQuery path="teams/:campaignId/members" />
                      <OrganizePlayersViewQuery path="teams/:campaignId/players" />
                      <CheckoutThanksViewQuery path="checkout/thanks" />
                      <CheckoutViewQuery path="checkout/:id" />
                      <MyActivityPage path="/user/activity" />
                      <NearbyView path="/user/nearby" />
                      <IntentLoadingViewMutation runMutation path="/intents/new" />
                      <IntentLoadingViewMutation path="/intents/:id" />
                      <EventEditViewQuery path="teams/:campaignId/events/:id/edit" />
                      <GiftcardListPageQuery path="user/purchases" />
                      <BeneficiaryRouter path="teams/:campaignId/guide/beneficiary/*" />
                    </Router>
                    <RenderModals campaignId={campaignId} />
                    <MaxBanner />
                  </>
                </LocationProvider>
              </StoreAppProvider>
            </ThemeProvider>
          </Provider>
        </ApolloProvider>
      </BreakpointProvider>
    </ErrorBoundary>
  );
};

export default StoreApp;
