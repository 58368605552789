import React from "react";
import styled from "@emotion/styled";
import { oc } from "ts-optchain";
import { Breakpoint } from "react-socks";
import gql from "graphql-tag";
import { parseISO } from "date-fns/fp";
import { format } from "date-fns";
import { Box, Text, Row, Link, Column } from "@atoms";
import { LabelPill } from "@molecules";
import { RailsUrl } from "@services";
import { IntentTypeEnum, LinkType } from "@types";
import { IntentCashbackStatus } from "../IntentCashbackStatus";
import { IntentListItemFragment } from "./__generated__/IntentListItemFragment";
// == Types ================================================================

interface IProps {
  intent: IntentListItemFragment;
}

// == Constants ============================================================
const FRAGMENTS = {
  fields: gql`
    fragment IntentListItemFragment on Intent {
      id
      intentType
      createdAt
      reference
      link {
        id
        type
      }
      merchant {
        id
        name
      }
      campaign {
        id
        name
      }
      fundraiser {
        id
        firstName
        lastName
      }
      buyer_name
      ...IntentCashbackStatusFragment
    }
    ${IntentCashbackStatus.fragments.fields}
  `,
};
IntentListItem.fragments = FRAGMENTS;

const giftCardNames = (linkType: LinkType) => {
  if (LinkType.GIFTCARDOFFER === linkType) return "Gift Card";
  if (LinkType.AUTORELOADGIFTCARD === linkType) return "Reloadable Gift Card";
  if (LinkType.VOUCHER === linkType) return "Voucher";
  return "Gift Card";
};

const shopNames = (linkType: LinkType) => {
  if (LinkType.GIFTCARDSHOP === linkType) return "Gift Card";
  return "Online";
};

const intentTypeName = (intentType: IntentTypeEnum, linkType: LinkType | null) => {
  // account for reload
  switch (intentType) {
    case IntentTypeEnum.BUY:
      return giftCardNames(linkType);
    case IntentTypeEnum.DONATION:
      return "Donation";
    case IntentTypeEnum.SHOP:
      return shopNames(linkType);
    case IntentTypeEnum.FLIPGIVECREDIT:
      return "Bonus";
    case IntentTypeEnum.TRANSFER:
      return "Transfer";
    case IntentTypeEnum.LINKEDCARDSALE:
      return "In-Store";
    case IntentTypeEnum.RELOAD:
      return "Reload";
    default:
      return null;
  }
};

// == Component ============================================================

export default function IntentListItem({ intent }: IProps) {
  const renderShoppingTripDetails = () => {
    if (intent.intentType === IntentTypeEnum.DONATION) {
      return intent.buyer_name;
    }
    if (intent.intentType === IntentTypeEnum.TRANSFER) {
      if (!intent.campaign && !intent.fundraiser) {
        return "Disburse to Personal Account";
      }
      return `${oc(intent).fundraiser.firstName()} ${oc(intent).fundraiser.lastName()}`;
    }
    return oc(intent).merchant.name("N/A");
  };

  return (
    <Box key={intent.id} pt={3}>
      <Box bg="gray" height="1px" mb={3} overflow="hidden" />
      <Breakpoint down small>
        <Row flexDirection="row" justifyContent="space-between">
          <Column>
            <Box mb={1}>
              <Text pr={2}>{renderShoppingTripDetails()}</Text>
              <LabelPill
                fontSize={0}
                text={intentTypeName(intent.intentType, intent?.link?.type)}
              />
            </Box>
            <Text display="block" fontSize={0}>
              {format(parseISO(intent.createdAt), "MMMM d, yyyy h:mma")}
            </Text>
            <Text display="block" fontSize={1}>
              Activity #{intent.reference}
            </Text>
            {!!intent.campaign && !!intent.fundraiser ? (
              // no campaign && no fundraiser means that it was a transfer to personal account
              <Link href={RailsUrl.campaignsUrl(intent.campaign.id)}>
                <Text color="primary" display="block">
                  {intent.campaign.name}
                </Text>
              </Link>
            ) : (
              <Text display="block">
                <Link href={RailsUrl.activatePersonalAccountUrl}>{intent.buyer_name}</Link>
              </Text>
            )}
          </Column>
          <EColumn>
            <IntentCashbackStatus intent={intent} />
          </EColumn>
        </Row>
      </Breakpoint>
      <Breakpoint medium up>
        <Row>
          <Column autoColumn={false} width={[2 / 12]}>
            <Text display="block" fontSize={1} lineHeight="title">
              {format(parseISO(intent.createdAt), "MMM d, yyyy")}
            </Text>
            <Text display="block" fontSize={1}>
              {format(parseISO(intent.createdAt), "h:mm a")}
            </Text>
          </Column>
          <Column autoColumn={false} width={[2 / 12]}>
            <Text display="block" fontSize={2}>
              {intent.reference}
            </Text>
          </Column>
          <Column autoColumn={false} width={[5 / 12]}>
            <Box mb={1}>
              <Text pr={2}>{renderShoppingTripDetails()}</Text>
              <LabelPill
                fontSize={0}
                text={intentTypeName(intent.intentType, intent?.link?.type)}
              />
            </Box>
            {intent.campaign ? (
              <Link href={RailsUrl.campaignsUrl(intent.campaign.id)}>
                <Text color="primary" display="block" fontSize={1}>
                  {intent.campaign.name}
                </Text>
              </Link>
            ) : (
              <Link href={RailsUrl.activatePersonalAccountUrl}>
                <Text color="primary" display="block" fontSize={1}>
                  {intent.buyer_name}
                </Text>
              </Link>
            )}
          </Column>
          <EColumn autoColumn={false} textAlign="right" width={[3 / 12]}>
            <IntentCashbackStatus intent={intent} />
          </EColumn>
        </Row>
      </Breakpoint>
    </Box>
  );
}

// == Styles ===============================================================

const EColumn = styled(Column)`
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;
