import React from "react";
import { Query } from "react-apollo";
import produce from "immer";
import { NOTIFICATIONS_QUERY } from "../queries";
import NotificationsList from "./NotificationsList";

export default class NotificationsListData extends React.Component {
  allowLoadMore = true;

  loadMoreNotifications = (fetchMore, beforeId) => {
    if (!this.allowLoadMore) {
      return;
    }

    fetchMore({
      query: NOTIFICATIONS_QUERY,
      variables: { first: 25, beforeId },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newNotifications = fetchMoreResult.Viewer.notifications;

        if (newNotifications.length === 0) {
          this.allowLoadMore = false;
        }

        return produce(previousResult, (draft) => {
          draft.Viewer.notifications = draft.Viewer.notifications.concat(newNotifications);
        });
      },
    });
  };

  render() {
    return (
      <Query fetchPolicy="cache-and-network" query={NOTIFICATIONS_QUERY} variables={{ first: 25 }}>
        {({ data, fetchMore, loading }) =>
          data && data.Viewer ? (
            <NotificationsList
              fetchMore={fetchMore}
              loading={loading}
              loadMoreNotifications={this.loadMoreNotifications}
              notifications={data.Viewer.notifications}
            />
          ) : null
        }
      </Query>
    );
  }
}
