import React from "react";
import { Heading, IHeadingProps } from "../Heading";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

const DEFAULT_PROPS = {};

// == Component ============================================================

export const H3 = (props: IHeadingProps) => {
  return <Heading as="h3" fontSize={3} fontWeight="normal" {...props} />;
};

H3.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
