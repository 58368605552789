import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { get } from "lodash";
import { ACTIVITIES_QUERY } from "../../queries";
import NewPost from "./NewPost";
import { FundraisersQuery, ActivityFragment } from "@queries";

const CREATEPOST_MUTATION = gql`
  mutation ActivityPostCreateMutation(
    $visibility: VisibilityType!
    $message: String
    $photoBase64: String
    $photoName: String
    $youtubeId: String
    $linkId: ID
    $publicView: Boolean = false
  ) {
    ActivityPostCreate(
      message: $message
      visibility: $visibility
      photoBase64: $photoBase64
      photoName: $photoName
      youtubeId: $youtubeId
      linkId: $linkId
    ) {
      ... on Activity {
        ...ActivityFragment
      }
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
    }
  }
  ${ActivityFragment}
`;

@inject("TimelineStore", "FlipgiveStore")
@observer
export default class TimelineNewPost extends React.Component {
  static propTypes = {
    TimelineStore: PropTypes.shape({
      addingPost: PropTypes.bool.isRequired,
      feed: PropTypes.string.isRequired,
    }).isRequired,
    FlipgiveStore: PropTypes.shape({
      current_user: PropTypes.shape({}),
    }).isRequired,
  };

  state = {
    message: "",
  };

  triggerCreatePost = (createPost, postData) => {
    const { TimelineStore } = this.props;

    createPost({
      variables: { ...postData },
      refetchQueries: [
        {
          query: ACTIVITIES_QUERY,
          variables: {
            feed: TimelineStore.feed,
            publicView: TimelineStore.publicView,
          },
        },
      ],
    });
  };

  render() {
    const { TimelineStore, FlipgiveStore } = this.props;

    return (
      <Query query={FundraisersQuery}>
        {({ data: fundraisersData }) => {
          const fundraisers = get(fundraisersData, "Campaign.joinedFundraisers", []);
          const users = fundraisers.map((fundraiser) => ({
            id: fundraiser.userId,
            display: fundraiser.name,
          }));
          const mentionUsers = [{ id: "team", display: "Team" }, ...users];

          return (
            <Mutation mutation={CREATEPOST_MUTATION}>
              {(createPost) => (
                <NewPost
                  addingPost={TimelineStore.addingPost}
                  campaignId={FlipgiveStore.campaign_id}
                  current_user={FlipgiveStore.current_user}
                  mentionUsers={mentionUsers}
                  message={this.state.message}
                  updateMessage={(message) => {
                    this.setState({
                      message,
                    });
                  }}
                  onBlur={() => {
                    TimelineStore.setWritingPost(false);
                  }}
                  onFocus={() => {
                    TimelineStore.setWritingPost(true);
                  }}
                  onNewPostSubmit={(postData) => {
                    this.triggerCreatePost(createPost, postData);
                  }}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}
