import React from "react";
import { Heading, IHeadingProps } from "../Heading";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// const DEFAULT_PROPS = {};

// == Component ============================================================

export const H1 = (props: IHeadingProps) => {
  return <Heading as="h1" fontSize={5} fontWeight="bold" {...props} />;
};

// H2.defaultProps = DEFAULT_PROPS;

// == Styles ===============================================================
