import React, { useState, useMemo } from "react";
import gql from "graphql-tag";
import uniq from "lodash/uniq";
import styled from "@emotion/styled";
import { Box, Flex, H1, Text } from "@atoms";
import { useStoreAppValue } from "@hooks";
import { analytics, spelling, pluralize } from "@services";
import OnboardingBrandsSearchResultsQuery from "./components/OnboardingBrandsSearchResults/OnboardingBrandsSearchResultsQuery";
import {
  OnboardingBrandsCollection,
  OnboardingBrandsSearch,
  OnboardingBrandsSubmit,
} from "./components";
import OnboardingBrandsGroup from "./components/OnboardingBrandsGroup/OnboardingBrandsGroup";
import { OnboardingBrandsSearchResultsQuery_BrandSearch_edges_node } from "./components/OnboardingBrandsSearchResults/__generated__/OnboardingBrandsSearchResultsQuery";
import { OnboardingBrandsFragment } from "./__generated__/OnboardingBrandsFragment";

// == Types ================================================================

type TExtraBrand = OnboardingBrandsSearchResultsQuery_BrandSearch_edges_node;
interface IProps {
  collections: OnboardingBrandsFragment[];
}

// == Constants ============================================================

OnboardingBrands.fragments = gql`
  fragment OnboardingBrandsFragment on Collection {
    id
    ...OnboardingBrandsCollectionFragment
  }
  ${OnboardingBrandsCollection.fragments}
`;

const MIN_BRANDS = 3;

// == Component ============================================================

export function OnboardingBrands({ collections }: IProps) {
  const { campaignId, isOwner, currency } = useStoreAppValue();
  const [inputValue, setInputValue] = useState<string>("");
  const [activeBrandIds, setActiveBrandIds] = useState<string[]>([]);
  const [extraBrands, setExtraBrands] = useState<TExtraBrand[]>([]);
  const trackBrandClick = (brandName: string) => {
    analytics.track.onboardingAddFavorite(
      {
        campaignId,
        brand: brandName,
      },
      isOwner
    );
  };

  const onBrandClick = (brandId: string, brand: TExtraBrand) => {
    if (activeBrandIds.includes(brandId)) {
      setActiveBrandIds(activeBrandIds.filter((id) => id !== brandId));
    } else {
      trackBrandClick(brand.name);
      setActiveBrandIds([...activeBrandIds, brandId]);
    }
  };

  const onExtraBrandClick = (brandId: string, brand: TExtraBrand) => {
    if (activeBrandIds.includes(brandId)) {
      setActiveBrandIds(activeBrandIds.filter((id) => id !== brandId));
      setExtraBrands(extraBrands.filter((b) => b.id !== brandId));
    } else {
      trackBrandClick(brand.name);
      setActiveBrandIds([...activeBrandIds, brandId]);
      setExtraBrands([...extraBrands, brand]);
    }
  };

  const combinedBrands = useMemo(() => {
    const extraBrandIds = extraBrands.map((brand) => brand.id);
    return uniq([...activeBrandIds, ...extraBrandIds]);
  }, [activeBrandIds, extraBrands]);

  return (
    <>
      <EFlex flexDirection="column" height="100%">
        <Box>
          <Box lineHeight="1" pb={2} px={4} textAlign="center">
            {activeBrandIds.length >= MIN_BRANDS ? (
              <H1 fontSize={["4", "5"]}>
                Add More Brands
                <Text color="em" display="block">
                  To Your{" "}
                  {spelling("favorites", {
                    country: `${currency === "USD" ? "USA" : "Canada"}`,
                    capitalize: true,
                    singular: false,
                  })}
                </Text>
              </H1>
            ) : (
              <H1 fontSize={["4", "5"]}>
                Add {MIN_BRANDS - activeBrandIds.length}{" "}
                {pluralize("Brand", MIN_BRANDS - activeBrandIds.length, { showCount: false })}{" "}
                <Text color="em" display="block">
                  To Your{" "}
                  {spelling("favorites", {
                    country: `${currency === "USD" ? "USA" : "Canada"}`,
                    capitalize: true,
                    singular: false,
                  })}
                </Text>
              </H1>
            )}
            <Text as="p" fontSize="0" lineHeight="copy" mb={2} mt={1}>
              Start your{" "}
              {spelling("favorites", {
                country: `${currency === "USD" ? "USA" : "Canada"}`,
                capitalize: false,
                singular: true,
              })}{" "}
              brands collection by choosing from the featured brands below or searching our full
              catalogue.
            </Text>
          </Box>
          <OnboardingBrandsSearch
            activeBrands={activeBrandIds}
            inputValue={inputValue}
            setInputValue={setInputValue}
            onBrandClick={onExtraBrandClick}
          />
        </Box>
        <EBrandsContainer flex="1" px={3}>
          {inputValue.length === 0 &&
            collections.map((collection) => {
              return (
                <OnboardingBrandsCollection
                  activeBrands={activeBrandIds}
                  collection={collection}
                  key={collection.id}
                  width="100%"
                  onBrandClick={onBrandClick}
                />
              );
            })}
          {inputValue.length === 0 && extraBrands.length > 0 && (
            <OnboardingBrandsGroup
              activeBrands={activeBrandIds}
              brands={extraBrands}
              name="Other Brands"
              onBrandClick={onExtraBrandClick}
            />
          )}
          {inputValue.length > 0 && (
            <OnboardingBrandsSearchResultsQuery
              activeBrands={activeBrandIds}
              inputValue={inputValue}
              onBrandClick={onExtraBrandClick}
            />
          )}
        </EBrandsContainer>

        <OnboardingBrandsSubmit brandIds={combinedBrands} minBrands={MIN_BRANDS} />
      </EFlex>
    </>
  );
}

// == Styles ===============================================================
// bottom button height
const EFlex = styled(Flex)`
  padding-bottom: 25px;
`;

const EBrandsContainer = styled(Box)`
  overflow-y: auto;
`;
