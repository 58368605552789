import React from "react";
import { Circle, Text, FontAwesome } from "@atoms";
// import styled from "@emotion/styled";

// == Types ================================================================

// interface IProps {}

// == Constants ============================================================

// == Component ============================================================

const CircleAddIcon = ({ ...rest }) => {
  return (
    <Circle {...rest} border="silver" data-testid="circle">
      <Text color="primary" fontSize={3}>
        <FontAwesome icon="plus" />
      </Text>
    </Circle>
  );
};

export default CircleAddIcon;

// == Styles ===============================================================
